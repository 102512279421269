import React from 'react';

const CanvasOutlinedIcon = (props) => {
  let stroke = props.stroke ? props.stroke : '#000';
  let fillOpacity = props.stroke ? 1 : 0.38;

  return (
    // <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path
    //     d="M12.7365 13.1398C11.9554 12.3588 11.9554 11.0924 12.7365 10.3114L21.2218 1.82609C22.0028 1.04504 23.2691 1.04504 24.0502 1.82609L32.5355 10.3114C33.3165 11.0924 33.3165 12.3588 32.5355 13.1398L24.0502 21.6251C23.2691 22.4061 22.0028 22.4061 21.2218 21.6251L12.7365 13.1398Z"
    //     fill={fill}
    //   />
    //   <path
    //     d="M0 11.7344L9.908 1.82637C10.689 1.04533 11.9554 1.04533 12.7364 1.82637L21.2217 10.3117C22.0028 11.0927 22.0028 12.359 21.2217 13.1401L12.7279 21.6339C11.9469 22.4149 10.6805 22.4149 9.89949 21.6339L0 11.7344Z"
    //     fill={fill}
    //   />
    //   <path
    //     fill-rule="evenodd"
    //     clip-rule="evenodd"
    //     d="M12.6434 10.4049C11.9131 11.1352 11.9131 12.3192 12.6434 13.0495L16.9779 17.384L21.3142 13.0478C22.0441 12.3179 22.0441 11.1345 21.3142 10.4046L16.9789 6.06934L12.6434 10.4049Z"
    //     fill="black"
    //     fill-opacity="0.32"
    //   />
    // </svg>

    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_11368_470)">
        <path
          d="M29.7333 14.0167V19V19.7666C29.7333 23.3808 29.7333 25.1878 28.6106 26.3106C27.4878 27.4333 25.6808 27.4333 22.0667 27.4333H15.9333C12.3192 27.4333 10.5122 27.4333 9.38942 26.3106C8.26666 25.1878 8.26666 23.3808 8.26666 19.7667V18.2333C8.26666 14.6192 8.26666 12.8122 9.38942 11.6894C10.5122 10.5667 12.3192 10.5667 15.9333 10.5667H19H22.0667"
          stroke={stroke}
          stroke-width="0.95"
          stroke-linecap="round"
        />
        <rect
          x="12.8666"
          y="25.1333"
          width="12.2667"
          height="0.766667"
          rx="0.383333"
          stroke={stroke}
          stroke-width="0.766667"
        />
        <path
          d="M25.6332 9.38227L26.1307 8.88484C26.9548 8.06068 28.2911 8.06068 29.1152 8.88484C29.9394 9.70901 29.9394 11.0452 29.1152 11.8694L28.6178 12.3668M25.6332 9.38227C25.6332 9.38227 25.6954 10.4393 26.6281 11.372C27.5608 12.3046 28.6178 12.3668 28.6178 12.3668M25.6332 9.38227L21.0602 13.9553C20.7504 14.2651 20.5956 14.42 20.4624 14.5907C20.3052 14.7922 20.1705 15.0101 20.0606 15.2407C19.9675 15.4362 19.8982 15.644 19.7597 16.0596L19.1727 17.8205M28.6178 12.3668L24.0447 16.9399C23.735 17.2496 23.5801 17.4045 23.4093 17.5377C23.2079 17.6948 22.99 17.8295 22.7593 17.9394C22.5638 18.0326 22.3561 18.1018 21.9405 18.2404L20.1796 18.8274M20.1796 18.8274L19.7491 18.9708C19.5446 19.039 19.3191 18.9858 19.1667 18.8334C19.0143 18.6809 18.9611 18.4555 19.0292 18.251L19.1727 17.8205M20.1796 18.8274L19.1727 17.8205"
          stroke={stroke}
          stroke-width="0.95"
        />
      </g>
      <defs>
        <clipPath id="clip0_11368_470">
          <rect width="23" height="23" fill="white" transform="translate(7.5 7.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CanvasOutlinedIcon;
