import React, { useState } from "react";
import helpSVG from "../../assets/img/help.svg";
import { makeStyles, Typography } from "@material-ui/core";

const Help_Center = "https://www.birdai.com/support/";
const Contact_Support = "";

const useStyles = makeStyles(() => ({
  profileDropdown: {
    paddingLeft: '0.5rem',
    borderRadius: '2rem',
    height: '100%',
    width: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  arrowBox: {
    marginRight: '0.5rem',
  },
  helpIconStyle: {
    '&:hover': {
      background: "#ddd",
      borderRadius: "50px",
      cursor: "pointer"
    }
  }
}));

const SupportMenu = ({ isSmall }) => {
  const classes = useStyles();

  const [TermsAndConditionsDialog, setTermsAndConditionsDialog] =
    useState(false);
  const [isOpenSupportMenu, setIsOpenSupportMenu] = useState(false);

  const handleClick = (event) => {
    window.open(Help_Center, '_blank');
  };

  const hideSupportMenu = () => {
    setIsOpenSupportMenu(false);
  };

  const showSupportMenu = () => {
    setIsOpenSupportMenu(true);
  };

  const HelpCenter = () => {
    window.open(Help_Center, "_blank");
  };

  const ContactSupport = () => {
    window.open(Contact_Support, "_blank");
  };

  const ContactSales = () => {
    setTermsAndConditionsDialog(true);
  };

  const toggleTermsPage = () => {
    setTermsAndConditionsDialog(false);
  };

  const menuItems = [
    {
      onClick: HelpCenter,
      title: "Help Center",
    },
    {
      onClick: ContactSupport,
      title: "Contact Support",
    },
    {
      onClick: ContactSales,
      title: "Legal Summary",
    },
  ];

  return (
    <div>
      <div onClick={handleClick} className={classes.helpIconStyle}>
        <img src={helpSVG} alt="help" style={{ width: `${isSmall ? "32px" : "24px"}` }} />
      </div>

      <div style={{ position: "absolute" }}>
        {/* <SupportMenu
          open={isOpenSupportMenu}
          onClose={hideSupportMenu}
          menuItems={menuItems}
        />

        <TermsAndConditions
          open={TermsAndConditionsDialog}
          termsPageClose={toggleTermsPage}
        /> */}
      </div>
    </div>
  );
};

export default SupportMenu;
