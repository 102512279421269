import React, { useState } from 'react';
import { useLastPageVisited } from '../../../../Utils/history/useLastPageVisited';
import { useZoomAuth } from '../../../../Utils/zoom/useZoomAuth';
import { ScheduleMeetingOptionsDialog } from './component';

export const ScheduleMeetingOptionsDialogContainer = (props) => {
  const {
    openManuallyScheduleDialog,
    openImportOutlookCalendarEventDialog,
    openSetupZoomAccountDialog,
  } = props;

  const { initiateZoomOAuthAuthorization, isZoomAuthenticated } = useZoomAuth();
  const { setSetupZoomAccountDialogAsLastVisited } = useLastPageVisited();

  const scheduleMeetingButtonLabel = 'Schedule a meeting';

  const onGoogleCalendarOptionClick = () => {};

  return (
    <ScheduleMeetingOptionsDialog
      initiateZoomOAuthAuthorization={initiateZoomOAuthAuthorization}
      isZoomAuthenticated={isZoomAuthenticated}
      onGoogleCalendarOptionClick={onGoogleCalendarOptionClick}
      onImportOutlookOptionCalendarClick={openImportOutlookCalendarEventDialog}
      onManuallyCreateMeetingOptionClick={openManuallyScheduleDialog}
      openSetupZoomAccountDialog={openSetupZoomAccountDialog}
      scheduleMeetingButtonLabel={scheduleMeetingButtonLabel}
      setSetupZoomAccountDialogAsLastVisited={setSetupZoomAccountDialogAsLastVisited}
    />
  );
};
