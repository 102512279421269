import { Dialog, makeStyles } from '@material-ui/core';
import React from 'react';
import Draggable from 'react-draggable';
import TimerIcon from '../../assets/icons/TimerIcon';

const useStyles = makeStyles(() => ({
  timerBtn: {
    height: '44px',
    width: '44px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #DADADA',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      background: '#DADADA',
    },
  },
}));

const draggableConfig = {
  handle: 'strong',
  defaultPosition: { x: 0, y: 0 },
};

const Timer = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.timerBtn}>
        <TimerIcon />
      </div>

      <div
        style={{
          position: 'fixed',
          bottom: '24rem',
          right: '3rem',
          zIndex: 9999,
        }}
      >
        <Draggable
        //TODO: required
        //  {...draggableConfig}
        >
          {/* 
           TODO: required
          <Dialog
                    // fullWidth={true}
                    // maxWidth={"sm"}
                    // className="SuccessDialogContainer"
                    open={true}
                //   onClose={this.closeLicenseSuccessDialog}
                >
                    Timer dialog
                </Dialog> */}
          <div>{/* Timer dialog*/}</div>
        </Draggable>
      </div>
    </>
  );
};

export default Timer;
