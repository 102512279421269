import React from 'react';
import { Button, DialogActions, Typography } from '@material-ui/core';
import maxLimitIcon from '../../../../assets/svg/maxLimitIcon.svg';
import useStyles from './component.style';
import StyledDialog from '../../../../UIComponents/modal/StyledDialog';

const MaxSimultaneousUsageLimitExhaustedDialog = (props) => {
  const classes = useStyles();
  const { isOpen, onClose, text, subText, buttonText, onClickHandler, message } = props;

  const okButton = (
    <DialogActions className={classes.warningBtn}>
      <Button
        variant="contained"
        color="primary"
        style={{ padding: '5px 36px' }}
        onClick={onClickHandler}
      >
        {buttonText}
      </Button>
    </DialogActions>
  );

  const displayMessage = (
    <div className={classes.messageContainer}>
      <a href="mailto:support@birdai.com" className={classes.supportBtn}>{message}</a>
    </div>
  );

  const upperContent = (
    <div>
      <div className={classes.logoContainer}>
        <img src={maxLimitIcon} alt="icon" />
      </div>
      <div className={classes.warningText}>{text}</div>
      <p className={classes.subText}>{subText}</p>
    </div>
  );

  const content = (
    <div className={classes.contentContainer}>
      {upperContent}
      {/* {okButton} */}
      {displayMessage}
    </div>
  );

  return (
    <div>
      <StyledDialog
        fullWidth={true}
        maxWidth={'sm'}
        className="overlay"
        open={isOpen}
        onClose={onClose}
        title="Upgrade Plan"
        hideFooter={true}
      >
        {content}
      </StyledDialog>
    </div>
  );
};

export default MaxSimultaneousUsageLimitExhaustedDialog;
