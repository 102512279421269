import { useState } from "react";

const useIsOpenListItems = () => {
  const [isOpenListItems, setIsOpenListItems] = useState(false);
  const showListItems = () => {
    setIsOpenListItems(true);
  };

  const hideListItems = () => {
    setIsOpenListItems(false);
  };

  const toggleListItems = () => {
    isOpenListItems ? hideListItems() : showListItems();
  };

  return { isOpenListItems, showListItems, hideListItems, toggleListItems };
};

export default useIsOpenListItems;
