/**
 * accepted only after converting it to pdf
 * check filetype in fileInfo object
 * The following values in the arrray are from filetype in fileInfo
 *
 * used to check the following:
 * 1. if import to canvas button should be displayed
 * 2. if we need to convert file to pdf
 */
export const acceptedFileTypes = [
  'document/pdf',
  'document/ppt',
  'document/pptx',
  'document/xlsx',
  'document/txt',
  'document/docx',
];

/**
 * the following file types needs to be converted to pdf
 * so that, it can be imported to canvas
 */
export const shouldConvertFileTypes = [
  'document/ppt',
  'document/pptx',
  'document/xlsx',
  'document/txt',
  'document/docx',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

/**
 * this is used on double clicking the widget, to decide if import document to canvas is supported
 */
export const importSupportedContentTypes = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
