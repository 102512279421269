import ACTION_TYPE from './type.action';

export const setTransformFactor = (factor) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.UPDATE_TRANSFORM_FACTOR,
    payload: factor,
  });
};

export const addStage = (stage) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.ADD_STAGE,
    payload: stage,
  });
};

export const changePresentationMode = (mode) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.CHANGE_PRESENTATION_MODE,
    payload: mode,
  });
};

/**
 * @param {{ userId: string, flowId: string, type: string }} payload
 * @returns
 */
export const storePresenterInfo = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.STORE_PRESENTER_INFO,
    payload,
  });
};

/**
 * @param {{ userId: string }} payload
 * @returns
 */
export const resetPresenterInfo = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.RESET_PRESENTER_INFO,
    payload,
  });
};

export const resetAllPresenterInfo = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.RESET_ALL_PRESENTER_INFO,
  });
};

export const createCanvasPopup = (mode) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SHOW_CREATE_CANVAS_POPUP,
    payload: mode,
  });
};

export const setCanvasScale = (scale) => (dispatch) =>
  dispatch({ type: ACTION_TYPE.SET_CANVAS_SCALE, payload: scale });

export const setCanvasPosition = (position) => (dispatch) =>
  dispatch({ type: ACTION_TYPE.SET_CANVAS_POSITION, payload: position });

export const setCurrentPageId = (pageId) => (dispatch) =>
  dispatch({ type: ACTION_TYPE.SET_CURRENT_PAGE_ID, payload: pageId });

export const setFullscreen = (enabled) => (dispatch) =>
  dispatch({ type: ACTION_TYPE.SET_FULLSCREEN, payload: enabled });
