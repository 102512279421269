import { makeStyles } from '@material-ui/core';

export const useScheduleMeetingOptionsStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  item: {
    cursor: 'pointer',
    display: 'flex',
    gap: '0.5rem',
  },
  itemIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '17rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },

  textColor: {
    color: "#000"
  }
}));
