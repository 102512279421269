import { Button, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import classes from './launcher.module.css';
import InfoIconOutLined from '../../assets/icons/InfoIconOutLined';
import keyIcon from '../../assets/svg/launcher/key.svg';

const MeetingRoomConnector = ({
  isDeviceConnected,
  disconnectDeviceHandler,
  pairToDevice,
  collectDeviceId,
  deviceName,
}) => {
  const [deviceId, setDeviceId] = useState(null);

  const handleDeviceId = (e) => {
    const deviceIdText = e.target.value;
    const capitalizedDeviceId = deviceIdText.toUpperCase();

    setDeviceId(capitalizedDeviceId);
    collectDeviceId(capitalizedDeviceId);
  };

  console.log('isDeviceConnected', isDeviceConnected);

  useEffect(() => {
    if (!isDeviceConnected) {
      setDeviceId('');
    }
  }, [isDeviceConnected]);

  const onEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (isDeviceConnected) {
        disconnectDeviceHandler();
      } else {
        pairToDevice();
      }
    }
  };

  return (
    <>
      <div className={classes.connectedRoomContainer}>
        <div className={classes.leftBoxLabel}>Connect to a Room</div>
        {/* <div className={classes.leftBoxText}>
          Start a meeting or cast your screen at comfort of your personal device.
        </div> */}

        <div className={classes.labelContainer}>
          <div className={classes.roomIdLabel}>
            {isDeviceConnected ? 'Connected to' : 'Enter Room ID'}
          </div>
          {!isDeviceConnected && (
            <Tooltip title="Enter the Room ID displayed on the Bird device" placement="right">
              <div className={classes.infoIcon}>
                <InfoIconOutLined color={'white'} />
              </div>
            </Tooltip>
          )}
        </div>

        <div className={classes.inputFieldContainer}>
          <TextField
            variant="outlined"
            value={isDeviceConnected ? deviceName : deviceId}
            onChange={handleDeviceId}
            placeholder="Room ID"
            className={classes.textFieldStyle}
            onKeyDown={onEnterKeyPress}
            style={{
              border: 'none',
              background: 'white',
              borderRadius: '8px',
              // padding: '4px 0',
              height: '39px',
            }}
            size="large"
            inputProps={{
              style: {
                // all: 'unset',
                // height: '44px',
                // padding: '4px 0px',
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '24px',
                background: 'white',
                color: 'black',
                width: '100%',
                borderRadius: '4px',
                height: '39px',
                boxSizing: 'border-box',
              },
              readOnly: isDeviceConnected,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={keyIcon} alt="key" />
                </InputAdornment>
              ),
            }}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={isDeviceConnected ? disconnectDeviceHandler : pairToDevice}
            className={classes.connectBtn}
            style={{ background: `${isDeviceConnected ? '#E75B5C' : '#335AFB'}` }}
          >
            {isDeviceConnected ? 'Disconnect' : 'Connect'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default MeetingRoomConnector;
