import { Button, Hidden, Menu, MenuItem, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ControllerSidebar from '../../Kneura-Web-Whiteboard/Controller/ControllerSidebar';
import ControllerTopbar from '../../Kneura-Web-Whiteboard/Controller/ControllerTopbar';
import classes from './spaces.module.css';
import createPluseSymbol from '../../assets/spaces/createPluseSymbol.svg';
import CustomCard from './card/CustomCard';
import Topbar from '../componentsFrame/Topbar';
import {
  getBoardInfo,
  getSpaceId,
  getWorkspaceId,
  setBoardInfo,
  setSortBy,
  setSpaceId,
  setSpaceName,
} from '../../Utils';
import { spacesService } from '../../services/spaces.service';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamsInfo } from '../../store/actions/teams.action';
import { showSpinner, hideSpinner } from '../../store/actions/notification.action';
import boardsService from '../../services/boards.service';
import { useHistory } from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CreateSpaceDialog from './container/CreateSpaceDialog';
import { useNotificationDispatcher } from '../../store/dispatcher/useNotificationDispatcher';
import RenameSpace from '../sideBar/Dialogs/RenameSpace';
import { activeSpaceInfo } from '../../store/actions/spaces.action';
import DeleteSpaceDialog from '../sideBar/Dialogs/DeleteSpaceDialog';
import MobileViewTopbar from '../../common/MobileViewTopbar/MobileViewTopbar';
import MobileViewFilters from '../../common/MobileViewTopbar/MobileViewFilters';
import { getCloudLicenseDetails } from '../../store/actions/license.action';

let canvasInfo = 0;

const Spaces = ({ userName, handleMobileSideMenuOpen }) => {
  const dispatch = useDispatch();
  const [spacesInfo, setSpacesInfo] = useState(0);
  const [totalSpacesCount, setTotalSpacesCount] = useState(0);
  const [filteredBoardData, setFilteredBoardData] = useState([]);
  const [test, setTest] = useState(0);
  const history = useHistory();
  const [sorterAnchorEl, setSorterAnchorEl] = React.useState(null);
  const sortSpacesOption = Boolean(sorterAnchorEl);
  const [sortType, setSortType] = useState('Name');

  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [renameBoardInput, setRenameBoardInput] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteBoardInput, setDeleteBoardInput] = useState('');
  const [parentName, setParentName] = useState('');

  const [renameBoardData, setRenameBoardData] = useState('');
  const [boardRenameErrorTextMessage, setBoardRenameErrorTextMessage] = useState('');

  const [deleteBoardData, setDeleteBoardData] = useState('');
  const isMobileView = useMediaQuery('(max-width: 800px)');

  const [boardName, setBoardName] = useState('');
  const [boardInput, setBoardInput] = useState(false);
  const [createBoardErrorTextMessage, setCreateBoardErrorTextMessage] = useState('');
  const [saveParentName, setSaveParentName] = useState('');

  const [isFiltersView, setIsFiltersView] = useState(false);
  const [filterStatus, setFilterStatus] = useState('All time');

  const cloudLicenseInfo = useSelector((state) => state.licenseReducer.cloudLicenseDetails);

  const {
    dispatchSetSnackbar,
    dispatchShowLicenseExhaustedDialog,
    dispatchShowSpinner,
    dispatchHideSpinner,
  } = useNotificationDispatcher();

  // const openWhiteBoardHandler = () => {
  //   setBoardInput(true);
  // };

  const openWhiteBoardHandler = () => {
    if (
      cloudLicenseInfo.spaces.isUnlimited ||
      filteredBoardData < cloudLicenseInfo.spaces.totalLimit
    ) {
      setBoardInput(true);
      // console.log('ParentId', saveParentId);
    } else {
      dispatchShowLicenseExhaustedDialog();
    }
  };

  const onEnterSearchText = (value) => {
    // let updatedList = this.state.initialboards;
    // updatedList = updatedList.filter(function (item) {
    //   return item.name.toLowerCase().indexOf(data.toLowerCase()) !== -1;
    // });
    // this.setState({ boards: updatedList });

    // setSearchText(value);
    if (value.length == 0) {
      setFilteredBoardData(test);
    } else {
      let data = test.filter((item) => {
        return item.name.toLowerCase().includes(value.toLowerCase());
      });
      setFilteredBoardData(data);
    }
  };

  const callGetSpaces = async () => {
    dispatchShowSpinner();

    let workspaceID = getWorkspaceId();
    const response = await spacesService.getSpaces(workspaceID);
    dispatch(getTeamsInfo(response.data.data.spaces));
    // TODO: need to refactor the code
    let boardData;
    if (response) {
      setSpacesInfo(response.data.data.spaces);
      setTotalSpacesCount(response.data.data.spaces.length);

      const spacesData = response.data.data.spaces;
      boardData = mergeSpaceChildren(spacesData);

      response.data.data.spaces.forEach((space) => {
        if (space.id.includes(getSpaceId())) {
          setSpaceName(space.name);
        }
      });

      setFilteredBoardData(boardData);
      setTest(boardData);
      // checkTeamAccess(response?.data?.data?.spaces);
    } else {
      setSpacesInfo(0);
    }
    dispatchHideSpinner();
  };

  const mergeSpaceChildren = (spacesData) => {
    return spacesData?.reduce(mergeChildrenReducer, []);
  };
  const mergeChildrenReducer = (a, b) => [...a, ...b.childrens];

  useEffect(() => {
    // dispatchShowSpinner();
    callGetSpaces();
    // dispatchHideSpinner();
    dispatch(getCloudLicenseDetails());
  }, []);

  const openSpaceHandler = async (child) => {
    console.log('child', child);
    dispatch(showSpinner());
    await setBoardInfoData(child.meta.classId);
    dispatch(hideSpinner());
    setSpaceId(child.parentId);
    // setSpaceName(item.name);
    setSpaceName(child.name || 'random');

    localStorage.setItem('from', child.parentId);
    // window.location.reload();
    history.push('/controller/Wbflow');
  };

  const setBoardInfoData = async (classID) => {
    const response = await boardsService.getBoards(classID);

    const classInfo = response.data.data;

    const { email, hardwareId, name, flowId, classId } = classInfo;
    canvasInfo = {
      email,
      hardwareId,
      name,
      flowId,
      classId,
    };
    setBoardInfo(canvasInfo);
  };

  const sortSpacesOptionClose = () => {
    setSorterAnchorEl(null);
  };

  const sortByName = () => {
    let updatedList = filteredBoardData;

    updatedList = updatedList.sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    // setSpacesInfo(updatedList);
    setFilteredBoardData(updatedList);

    setSorterAnchorEl(null);
    setSortType('Name');
    setSortBy('Name');
  };

  const sortSpacesByDate = () => {
    let updatedList = filteredBoardData;
    updatedList = updatedList.sort(function (a, b) {
      if (a.createdAt < b.createdAt) {
        return 1;
      }
      if (a.createdAt > b.createdAt) {
        return -1;
      }
      return 0;
    });
    // setSpacesInfo(updatedList);
    setFilteredBoardData(updatedList);

    setSorterAnchorEl(null);
    setSortType('Date Created');
    setSortBy('Date Created');
  };

  const sortSpacesByUpdated = () => {
    let updatedList = filteredBoardData;
    updatedList = updatedList.sort(function (a, b) {
      if (a.updatedAt < b.updatedAt) {
        return 1;
      }
      if (a.updatedAt > b.updatedAt) {
        return -1;
      }
      return 0;
    });
    // setSpacesInfo(updatedList);
    setFilteredBoardData(updatedList);

    setSorterAnchorEl(null);
    setSortType('Last Modified');
    setSortBy('Last Modified');
  };

  const sortSpaces = (event) => {
    setSorterAnchorEl(event.currentTarget);
  };

  // const renameBoard = (data) => {
  //   let { board, ParentName } = data;
  //   setRenameDialogOpen(true);
  //   setRenameBoardInput(board.name);
  //   setParentName(ParentName);
  //   setRenameBoardData(data.board);
  // };

  const handleClose = () => {
    setBoardInput(false);
    setRenameDialogOpen(false);
    setDeleteDialogOpen(false);
    // setRenameSpaceDialogOpen(false);
    // setDeleteSpaceDialogOpen(false);

    // setBoardMenu(boardMenu + 1);
  };

  const onEnterKeyPressCreateBoard = (e) => {
    if (e.key === 'Enter') {
      CreateBoard();
    }
  };

  const CreateBoard = async () => {
    try {
      if (boardName !== '') {
        setCreateBoardErrorTextMessage('');
        const res = await boardsService.createBoard(boardName, getSpaceId());
        setBoardName('');
        // setNcount((childCount) => childCount + 2);
        // childCountChange(nCount);
        handleClose();
        //Redirect to newly created space start
        openSpaceHandler(res.data.data);
        //Redirect to newly created space end
      } else {
        setCreateBoardErrorTextMessage('*Required');
      }
    } catch (error) {
      console.log('error', error.response.data.message);
      dispatchSetSnackbar({ message: error.response.data.message });
    }
  };

  const renameBoardHandler = (data) => {
    let { spaceInfo, ParentName } = data;

    setRenameDialogOpen(true);
    setRenameBoardInput(spaceInfo.name);

    setParentName(ParentName);

    setRenameBoardData(data.spaceInfo);
  };

  const handleRenameBoardInput = (event) => {
    setRenameBoardInput(event.target.value);
  };

  const onEnterKeyPressRenameBoard = (e) => {
    if (e.key === 'Enter') {
      reNameBoardClick();
    }
  };

  const reNameBoardClick = async () => {
    const flowID = renameBoardData.meta.flowId;
    const classID = renameBoardData.meta.classId;
    if (renameBoardInput !== '') {
      setBoardRenameErrorTextMessage('');
      await boardsService.renameBoard(flowID, classID, renameBoardInput);

      const boardInfo = getBoardInfo();
      console.log('boardInfo', boardInfo);

      if (classID == getBoardInfo().classId) {
        boardInfo.name = renameBoardInput;
        setBoardInfo(boardInfo);
        dispatch(activeSpaceInfo(boardInfo));
      }

      // childCountChange();
      setRenameDialogOpen(false);
      // setBoardMenu(boardMenu + 1);
      callGetSpaces();
    } else {
      setBoardRenameErrorTextMessage('*Required');
    }
  };

  const deleteBoardHandler = (data) => {
    setDeleteDialogOpen(true);
    setDeleteBoardInput(data.name);
    setDeleteBoardData(data);
  };

  const deleteBoardClick = async () => {
    const flowID = deleteBoardData.meta.flowId;
    const classID = deleteBoardData.meta.classId;

    await boardsService.deleteBoard(flowID, classID);
    // childCountChange();
    setDeleteDialogOpen(false);
    // setBoardMenu(boardMenu + 1);
    callGetSpaces();
  };

  const filtersViewHandler = () => {
    setIsFiltersView(true);
  };

  const closeFiltersViewHandler = () => {
    setIsFiltersView(false);
  };

  const sortByDataList = [
    { label: 'Name', value: 'Name', onClick: () => sortByName('Name') },
    {
      label: 'Date Created',
      value: 'Date Created',
      onClick: () => sortSpacesByDate('Date Created'),
    },
    {
      label: 'Last Modified',
      value: 'Last Modified',
      onClick: () => sortSpacesByUpdated('Last Modified'),
    },
  ];

  const duplicateSpaceHandler = async () => {
    if (
      cloudLicenseInfo.spaces.isUnlimited ||
      filteredBoardData.length < cloudLicenseInfo.spaces.totalLimit
    ) {
      const spaceID = getSpaceId();
      const { classId, flowId } = getBoardInfo();
      await boardsService.duplicateBoard(flowId, classId, spaceID);

      callGetSpaces();
    } else {
      dispatchShowLicenseExhaustedDialog();
    }
  };

  return (
    <>
      <Hidden smDown>
        <ControllerSidebar />
      </Hidden>
      <ControllerTopbar handleMobileSideMenuOpen={handleMobileSideMenuOpen} pageTitle="Canvas" />

      <div className={classes.launcherMainContainer}>
        <div className={classes.launcherMainContainerInner}>
          <section>
            {isMobileView ? (
              <MobileViewTopbar
                title={'Canvas'}
                onSearchTextChange={onEnterSearchText}
                showFilters={true}
                filtersViewHandler={filtersViewHandler}
              // createActionButton1={false}
              />
            ) : (
              <Topbar
                title={'Canvas'}
                onEnterSearchText={onEnterSearchText}
                shortButton={
                  <div>
                    {' '}
                    {/* <SortIcon
                fontSize="small"
                style={{
                  color: 'rgba(0, 0, 0, 0.6)',
                  marginLeft: '0.5rem',
                  cursor: 'pointer',
                }}
                onClick={sortSpaces}
              /> */}
                    <Button
                      variant="contained"
                      onClick={sortSpaces}
                      endIcon={<ArrowDropDownIcon style={{ color: '#335AFB' }} />}
                      className={classes.SortButton}
                    >
                      {sortType}
                    </Button>
                    {/* ////// */}
                    <Menu
                      id="sorter-menu"
                      anchorEl={sorterAnchorEl}
                      keepMounted
                      open={sortSpacesOption}
                      onClose={sortSpacesOptionClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      getContentAnchorEl={null}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <MenuItem
                        className={sortType === 'Name' ? 'activeSort' : ''}
                        onClick={() => sortByName()}
                      >
                        Name
                      </MenuItem>
                      <MenuItem
                        className={sortType === 'Last Modified' ? 'activeSort' : ''}
                        onClick={() => sortSpacesByUpdated()}
                      >
                        Last Modified
                      </MenuItem>
                      <MenuItem
                        className={sortType === 'Date Created' ? 'activeSort' : ''}
                        onClick={() => sortSpacesByDate()}
                      >
                        Date Created
                      </MenuItem>
                    </Menu>
                    {/* /////// */}
                  </div>
                }
              />
            )}
            <div className={classes.cardsContainer}>
              <div className={classes.createLessonWrap} onClick={openWhiteBoardHandler}>
                <img
                  src={createPluseSymbol}
                  style={{
                    width: '38px',
                    height: '38px',
                  }}
                  alt="Create Assessment"
                />
                <div className={classes.createBtnText}>
                  <div className={classes.createLessonTitle}>New Canvas</div>
                </div>
              </div>

              {/* <div> */}
              {filteredBoardData.map((item, index) => (
                <CustomCard
                  key={index}
                  spaceInfo={item}
                  openSpace={openSpaceHandler}
                  renameBoard={renameBoardHandler}
                  deleteBoard={deleteBoardHandler}
                  DuplicateBoard={duplicateSpaceHandler}
                />
              ))}
              {/* </div> */}
            </div>
          </section>
        </div>
      </div>

      <CreateSpaceDialog
        boardInput={boardInput}
        handleClose={handleClose}
        boardName={boardName}
        setBoardName={setBoardName}
        onEnterKeyPressCreateBoard={onEnterKeyPressCreateBoard}
        createBoardErrorTextMessage={createBoardErrorTextMessage}
        saveParentName={saveParentName}
        CreateBoard={CreateBoard}
      />

      <RenameSpace
        renameDialogOpen={renameDialogOpen}
        handleClose={handleClose}
        renameBoardInput={renameBoardInput}
        handleRenameBoardInput={handleRenameBoardInput}
        onEnterKeyPressRenameBoard={onEnterKeyPressRenameBoard}
        boardRenameErrorTextMessage={boardRenameErrorTextMessage}
        parentName={parentName}
        reNameBoardClick={reNameBoardClick}
      />

      <DeleteSpaceDialog
        deleteDialogOpen={deleteDialogOpen}
        handleClose={handleClose}
        deleteBoardInput={deleteBoardInput}
        deleteBoardClick={deleteBoardClick}
      />

      <MobileViewFilters
        open={isFiltersView}
        onClose={closeFiltersViewHandler}
        sortByData={sortByDataList}
        // showFilterBy={true}
        // filterByData={filterByData}
        activeSortState={sortType}
      // activeFilterState={filterStatus}
      />
    </>
  );
};

export default Spaces;
