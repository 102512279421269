import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  IconButton,
  TextField,
  Divider,
  Grid,
  styled,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {
  Add as ZoomInIcon,
  Remove as ZoomOutIcon,
  KeyboardArrowDown as ArrowDownIcon,
  Check as CheckIcon,
} from '@material-ui/icons';

const StyledTextField = styled(TextField)({
  width: '100%',
  '& fieldset': { border: '1px solid #DADADA !important' },
  '& input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& .MuiInputBase-root': {
    fontSize: '12px',
    lineHeight: '14px',
  },
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
    padding: 4,
  },
});

const ZoomToolbarContainer = styled(Grid)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  width: 'fit-content',
  backgroundColor: '#fff',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid #DADADA',
  '& button': {
    borderRadius: 0,
    borderRight: '1px solid #DADADA',
    height: '29px',
    '&:last-child': {
      borderRight: 'none',
    },
  },
}));

const ZoomMenu = styled('div')(({ show, theme }) => ({
  display: show ? 'block' : 'none',
  position: 'absolute',
  top: '100%',
  left: '0',
  width: '130px',
  padding: theme.spacing(),
  paddingLeft: theme.spacing() * 3.5,
  backgroundColor: '#fff',
  boxShadow: '0px 0px 4px 0px #00000040',
  marginTop: theme.spacing(),
  '& .MuiDivider-root': {
    marginLeft: -theme.spacing() * 3.5,
    marginRight: -theme.spacing(),
  },
  '&::after': {
    content: "''",
    position: 'absolute',
    bottom: '100%',
    left: '50%',
    marginLeft: '-5px',
    borderWidth: '5px',
    borderStyle: 'solid',
    borderColor: 'transparent transparent #fff transparent',
  },
  '&>*[role="button"]': {
    cursor: 'pointer',
    lineHeight: '20px',
  },
  '&>*': {
    marginBottom: theme.spacing(0.5),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  '& .MuiSvgIcon-root': {
    fontSize: '16px !important',
  },
  '@media (max-width: 600px)': {
    width: '120px',
  },
}));

const ZoomToolbar = ({
  zoomRate,
  applyZoom,
  zoomToFit,
  showMiniMap,
  toggleMiniMap,
  defaultScale,
  maxScale,
  maxZoomLevel,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isSmallMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const isPortrait = useMediaQuery('(orientation: portrait)');
  const { scale } = useSelector((state) => state.canvasReducer);
  const [menuVisibility, setMenuVisibility] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(Math.round(scale * 100));
  const zoomInputRef = useRef();
  const timerRef = useRef();

  useEffect(() => {
    const updatedZoomLevel = Math.round(scale * 100);
    setZoomLevel(updatedZoomLevel);
  }, [scale]);

  const zoomOptions = useMemo(() => {
    const scaleList = new Set();
    scaleList.add(1);
    scaleList.add(Math.round(defaultScale / 2));
    scaleList.add(defaultScale);
    scaleList.add(defaultScale * 2);
    scaleList.add(defaultScale * 5);
    scaleList.add(defaultScale * 10);
    scaleList.add(maxScale);
    const options = [...scaleList]
      .filter((scale) => scale >= 1 && scale <= maxScale)
      .sort((a, b) => b - a)
      .map((scale, index) => (
        <Typography
          key={index}
          role="button"
          variant="body2"
          onClick={() => applyZoom(scale, 0.5)}
          style={{ fontSize: isMobile ? '10px' : '12px' }}
        >
          Zoom to {Math.round((scale / defaultScale) * 100)}%
        </Typography>
      ));
    return options;
  }, [defaultScale, maxScale, isMobile, applyZoom]);

  const zoomIn = () => {
    let newScale = scale * zoomRate;
    newScale = Math.round(newScale * 20) * 0.05;
    applyZoom(newScale);
  };

  const zoomOut = () => {
    let newScale = scale / zoomRate;
    newScale = Math.round(newScale * 20) * 0.05;
    applyZoom(newScale);
  };

  const applyZoomLevel = useCallback(
    (e) => {
      clearTimeout(timerRef.current);
      let value = +e.target.value * defaultScale;
      if (value < 0) return;
      if (value > maxZoomLevel * defaultScale) value = maxZoomLevel * defaultScale;
      setZoomLevel(value);
      const updatedScale = +(value / 100).toFixed(2);
      timerRef.current = setTimeout(() => applyZoom(updatedScale, 0.5), 1000);
    },
    [applyZoom, defaultScale, maxZoomLevel],
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (zoomLevel < 100) setZoomLevel(100);
    }, 1000);
    return () => clearTimeout(timer);
  }, [zoomLevel]);

  const showMenu = () => setMenuVisibility(!menuVisibility);

  const hideMenu = useCallback(
    (event) => {
      const isZoomInput = event.target === zoomInputRef.current;
      !isZoomInput && menuVisibility && setMenuVisibility(false);
    },
    [menuVisibility],
  );

  const visualZoomLevel = Math.round(zoomLevel / defaultScale);

  useEffect(() => {
    document.addEventListener('click', hideMenu);
    return () => {
      document.removeEventListener('click', hideMenu);
      clearTimeout(timerRef.current);
    };
  }, [hideMenu]);

  useEffect(() => {
    menuVisibility && zoomInputRef.current && zoomInputRef.current.focus();
  }, [menuVisibility]);

  return (
    <ZoomToolbarContainer
      style={{
        position: `${isMobile ? 'absolute' : 'relative'}`,
        top: `${isMobile ? (isPortrait ? '109px' : '66px') : '0'}`,
        right: `${isMobile ? (isPortrait ? '12px' : '12px') : '0'}`,
      }}
    >
      <ZoomMenu
        show={menuVisibility}
        style={{
          left: `${isMobile ? 'unset' : '0px'}`,
          right: `${isMobile ? '0' : 'unset'}`,
        }}
      >
        {!isMobile && (
          <>
            <StyledTextField
              variant="outlined"
              type="number"
              size="small"
              inputRef={zoomInputRef}
              InputProps={{ endAdornment: '%' }}
              value={visualZoomLevel.toString()}
              onChange={applyZoomLevel}
            />
            <Divider />
          </>
        )}
        <Typography
          role="button"
          variant="body2"
          onClick={zoomToFit}
          style={{ fontSize: isMobile ? '10px' : '12px' }}
        >
          Zoom to fit
        </Typography>
        {zoomOptions}
        <Divider />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            cursor: 'pointer',
            marginLeft: -20,
          }}
          role="button"
          onClick={toggleMiniMap}
        >
          <CheckIcon style={{ visibility: showMiniMap ? 'visible' : 'hidden' }} />
          <Typography variant="body2" style={{ fontSize: isMobile ? '10px' : '12px' }}>
            Pin Map
          </Typography>
        </div>
      </ZoomMenu>
      {!isMobile && (
        <IconButton onClick={zoomOut} size="small">
          <ZoomOutIcon style={{ color: 'black', fontSize: '20px' }} />
        </IconButton>
      )}
      <Button
        variant="text"
        endIcon={<ArrowDownIcon />}
        size="small"
        onClick={showMenu}
        style={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: isMobile ? '12px' : '13px',
          lineHeight: '14px',
        }}
      >
        {visualZoomLevel + '%'}
      </Button>
      {!isMobile && (
        <IconButton onClick={zoomIn} size="small">
          <ZoomInIcon style={{ color: 'black', fontSize: '20px' }} />
        </IconButton>
      )}
    </ZoomToolbarContainer>
  );
};

export default ZoomToolbar;
