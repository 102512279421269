import { useQuery } from '@tanstack/react-query';

import BaseService from './base.service';
import { WHITEBOARD_CHAT_URLS, getUserId } from '../Utils';

const startWhiteboardChat = async ({
  queryKey: [_key, elementId, type, contentUrl, text, role, lessonName, userId],
}) => {
  const url = WHITEBOARD_CHAT_URLS.START_CHAT;

  return new BaseService().makePostRequest(
    url,
    { elementId, type, contentUrl, text, role, userId, lessonName },
    { customMessage: `Failed to create the chat` },
  );
};

export const useStartWhiteboardChat = (
  { elementId, type, contentUrl, role = 'TEACHER', lessonName, text },
  options,
) => {
  const userId = getUserId();
  return useQuery(
    ['startWhiteboardChat', elementId, type, contentUrl, text, role, lessonName, userId],
    {
      queryFn: startWhiteboardChat,
      enabled: !!elementId,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      ...options,
    },
  );
};
