import React from 'react';
import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyledHeaderStyles } from './component.style';

export const StyledHeader = (props) => {
  const { title, onClose } = props;
  const classes = useStyledHeaderStyles();

  const titleContent = (
    <div className={classes.textContainer}>
      <Typography className={classes.title} noWrap>
        {title}
      </Typography>
    </div>
  );

  const closeIconContent = (
    <div className={classes.closeIconContainer}>
      <CloseIcon onClick={onClose} />
    </div>
  );

  return (
    <div className={[classes.dialogStyledHeader, classes.bgColor].join(' ')}>
      {titleContent}
      {closeIconContent}
    </div>
  );
};
