import { useRef } from "react"

const useFileSelectorUserProfile = ({ formik }) => {
  const fileSelectorRef = useRef(null)

  const selectFile = (e) => {
    e.preventDefault();
    console.log('hello');
    fileSelectorRef.current.click();
  };

  const setFile = (file) => {
    formik.setFieldValue('file', file);
  }

  const setProfileImage = (profileImage) => {
    formik.setFieldValue('profileImage', profileImage);
  }

  return { fileSelectorRef, selectFile, setFile, setProfileImage }
}

export default useFileSelectorUserProfile;