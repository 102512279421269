import Konva from 'konva';
import _ from 'lodash';
import positioning from '../Kneura-Web-Whiteboard/IWB/utils/positioning';

export const haveIntersection = (area1, area2) =>
  !(
    area2.x > area1.x + area1.width ||
    area2.x + area2.width < area1.x ||
    area2.y > area1.y + area1.height ||
    area2.y + area2.height < area1.y
  );

export const centerElement = ({ pointerPosition, width, height, maxFit = 0.8 }, canvasBoard) => {
  const fillPage = maxFit === 1;
  const { width: stageWidth, height: stageHeight } = canvasBoard.size;
  const { x: stageX, y: stageY } = canvasBoard.position;
  const whiteboardScale = canvasBoard.scale;
  const isHorizontal = width / stageWidth > height / stageHeight;
  const visibleStageWidth = stageWidth / whiteboardScale;
  const visibleStageHeight = stageHeight / whiteboardScale;
  const maxWidth = visibleStageWidth * maxFit;
  const maxHeight = visibleStageHeight * maxFit;
  const maxScale = isHorizontal ? maxWidth / width : maxHeight / height;
  const scaleRatio = isHorizontal ? width / maxWidth : height / maxHeight;
  const scale = scaleRatio >= 1 ? maxScale : fillPage ? maxScale : 1;
  let x = -stageX / whiteboardScale + visibleStageWidth / 2 - (width * scale) / 2;
  let y = -stageY / whiteboardScale + visibleStageHeight / 2 - (height * scale) / 2;

  if (pointerPosition) {
    x = pointerPosition.x - (width * scale) / 2;
    y = pointerPosition.y - (height * scale) / 2;
  }

  return { x, y, width, height, scale: { x: scale, y: scale } };
};

const generatePositions = ({
  rowsCount,
  columnsCount,
  startPosition,
  paddingX = 0,
  paddingY = 0,
  cellWidth,
  cellHeight,
  gapWidth,
  gapHeight,
}) => {
  const positionsMatrix = [...Array(rowsCount)]
    .map((_, rowIndex) => [...Array(columnsCount)].map((_, columnIndex) => [rowIndex, columnIndex]))
    .flat();
  const { x: startX, y: startY } = startPosition;
  const positions = positionsMatrix.map(([rowIndex, columnIndex]) => ({
    x: startX + paddingX + columnIndex * (cellWidth + gapWidth),
    y: startY + paddingY + rowIndex * (cellHeight + gapHeight),
  }));
  return positions;
};

export const positionElement = async (
  ...[args, { canvasBoard, mainStage, currentPage, selectedPageIndex }]
) => {
  try {
    let {
      pointerPosition,
      width,
      height,
      maxFit = 0.8,
      rows = 2,
      columns = 3,
      gapX = 0.01,
      gapY = 0.01,
      count = undefined,
      index = undefined,
    } = args;
    const { width: stageWidth, height: stageHeight } = canvasBoard.size;
    const { defaultScale } = canvasBoard.props;
    let rowsCount = rows,
      columnsCount = columns;
    if (count) {
      if (maxFit === 1) {
        await canvasBoard.pagination.addPage(selectedPageIndex + 1);
        if (defaultScale !== 1) await canvasBoard.applyZoom(1, 0);
        return centerElement({ pointerPosition, width, height, maxFit }, canvasBoard);
      }
      if (!index) await canvasBoard.applyZoom(1, 0.5);
      const singleAreaWidth = defaultScale < 10 ? stageWidth : stageWidth / defaultScale;
      const singleAreaHeight = defaultScale < 10 ? stageHeight : stageHeight / defaultScale;
      const gapWidth = gapX * singleAreaWidth;
      const gapHeight = gapY * singleAreaHeight;
      const cellWidth = (singleAreaWidth - (columns - 1) * gapWidth) / columns;
      const cellHeight = (singleAreaHeight - (rows - 1) * gapHeight) / rows;
      if (!index) {
        canvasBoard.oldBoundaries = { scale: canvasBoard.scale, position: canvasBoard.position };
        if (defaultScale >= 10) {
          rowsCount = Math.ceil(count / columnsCount);
          const fullAreaHeight = cellHeight * rowsCount + gapHeight * (rowsCount - 1);
          const insertDocContainer = new Konva.Rect({
            x: 0,
            y: 0,
            fill: '#00f7',
            width: singleAreaWidth,
            height: fullAreaHeight > 150 ? fullAreaHeight : 150,
            shadowColor: 'black',
            shadowBlur: 10,
            shadowOffsetX: 10,
            shadowOffsetY: 10,
            shadowOpacity: 0.2,
            cornerRadius: 4,
          });
          insertDocContainer.on('mouseenter', function () {
            mainStage.container().style.cursor = 'move';
          });
          insertDocContainer.on('mouseleave', function () {
            mainStage.container().style.cursor = 'default';
          });
          const insertDocTitle = new Konva.Text({
            text: 'Drag me',
            x: 0,
            y: 8,
            width: singleAreaWidth,
            align: 'center',
            fill: '#fff',
            fontSize: 20 * canvasBoard.props.transformFactor,
            fontFamily: 'poppins',
            fontStyle: 'bold',
          });
          insertDocTitle.on('mouseenter', function () {
            mainStage.container().style.cursor = 'move';
          });
          insertDocTitle.on('mouseleave', function () {
            mainStage.container().style.cursor = 'default';
          });
          const insertButton = new Konva.Label({
            x: 0,
            y: insertDocContainer.height() / 2 - 15,
          });
          insertButton.add(
            new Konva.Tag({
              fill: '#fff',
              lineJoin: 'round',
            }),
          );
          insertButton.add(
            new Konva.Text({
              text: 'Insert here',
              width: singleAreaWidth,
              align: 'center',
              fontFamily: 'Poppins',
              fontSize: 16 * canvasBoard.props.transformFactor,
              padding: 5,
              fill: '#40b',
            }),
          );
          insertButton.on('mouseenter', function () {
            mainStage.container().style.cursor = 'pointer';
          });
          insertButton.on('mouseleave', function () {
            mainStage.container().style.cursor = 'default';
          });
          insertButton.on('click tap', () => {
            canvasBoard.insertionPoint = insertDocGroup.position();
            canvasBoard.insertDocumentCallback(true);
          });
          const cancelButton = new Konva.Label({
            x: 0,
            y: insertDocContainer.height() - 40,
          });
          cancelButton.add(
            new Konva.Tag({
              fill: '#fff5',
              lineJoin: 'round',
            }),
          );
          cancelButton.add(
            new Konva.Text({
              text: 'Cancel',
              width: singleAreaWidth,
              align: 'center',
              fontFamily: 'Poppins',
              fontSize: 16 * canvasBoard.props.transformFactor,
              padding: 5,
              fill: '#fff',
            }),
          );
          cancelButton.on('mouseenter', function () {
            mainStage.container().style.cursor = 'pointer';
          });
          cancelButton.on('mouseleave', function () {
            mainStage.container().style.cursor = 'default';
          });
          cancelButton.on('click tap', () => {
            mainStage.container().style.cursor = 'default';
            canvasBoard.insertDocumentCallback(false);
          });
          const backdrop = new Konva.Rect({
            x: 0,
            y: 0,
            ...canvasBoard.size,
            fill: '#000',
            opacity: 0.2,
          });
          const insertDocLayer = new Konva.Layer({ name: 'insertDocLayer' });
          const insertDocGroup = new Konva.Group({ draggable: true, x: 100, y: 100 });
          insertDocLayer.on(
            'mousedown pointerdown touchstart dragstart',
            (e) => (e.cancelBubble = true),
          );
          insertDocGroup.add(insertDocContainer, insertDocTitle, insertButton, cancelButton);
          insertDocLayer.add(backdrop, insertDocGroup);
          mainStage.add(insertDocLayer);
          const confirmInsert = () =>
            new Promise((resolve) => {
              canvasBoard.insertDocumentCallback = (val) => {
                insertDocLayer.destroy();
                resolve(val);
              };
            });
          canvasBoard.props.hideSpinner();
          canvasBoard.proceed = await confirmInsert();
          if (!canvasBoard.proceed) {
            await applyZoomAndPosition({
              ...canvasBoard.oldBoundaries,
              mainStage,
              canvasBoard,
              animationDuration: 0.5,
            });
            return;
          }
          canvasBoard.props.showSpinner();
        }
        canvasBoard.positions = generatePositions({
          startPosition: canvasBoard.insertionPoint || { x: 0, y: 0 },
          columnsCount,
          rowsCount,
          cellWidth,
          cellHeight,
          gapWidth,
          gapHeight,
        });
      }
      if (defaultScale < 10 && !(index % (rowsCount * columnsCount))) {
        await canvasBoard.pagination.addPage(selectedPageIndex + 1);
        await canvasBoard.applyZoom(1, 0);
      }
      const widthRatio = width / cellWidth;
      const heightRatio = height / cellHeight;
      const scale =
        widthRatio > 1 || heightRatio > 1
          ? widthRatio > heightRatio
            ? 1 / widthRatio
            : 1 / heightRatio
          : 1;
      if (index + 1 === count && defaultScale >= 10) {
        let { x, y } = canvasBoard.insertionPoint;
        x = -x * defaultScale;
        y = -y * defaultScale;
        await applyZoomAndPosition({
          position: { x, y },
          scale: defaultScale,
          mainStage,
          canvasBoard,
          animationDuration: 0.5,
        });
      }
      const positionIndex = defaultScale >= 10 ? index : index % (rowsCount * columnsCount);
      return {
        ...canvasBoard.positions[positionIndex],
        width,
        height,
        scale: { x: scale, y: scale },
      };
    }
    const { x: stageX, y: stageY } = canvasBoard.position;
    const whiteboardScale = canvasBoard.scale;
    const visibleStageWidth = stageWidth / whiteboardScale;
    const visibleStageHeight = stageHeight / whiteboardScale;
    const absoluteStageX = -stageX / whiteboardScale;
    const absoluteStageY = -stageY / whiteboardScale;
    const paddingX = ((1 - maxFit) * visibleStageWidth) / 2;
    const paddingY = ((1 - maxFit) * visibleStageHeight) / 2;
    const gapWidth = gapX * visibleStageWidth;
    const gapHeight = gapY * visibleStageHeight;
    const cellWidth = (maxFit * visibleStageWidth - (columnsCount - 1) * gapWidth) / columnsCount;
    const cellHeight = (maxFit * visibleStageHeight - (rowsCount - 1) * gapHeight) / rowsCount;
    const widthRatio = width / cellWidth;
    const heightRatio = height / cellHeight;
    const scale =
      widthRatio > 1 || heightRatio > 1
        ? widthRatio > heightRatio
          ? 1 / widthRatio
          : 1 / heightRatio
        : 1;
    let position = { x: 0, y: 0 };
    if (pointerPosition) {
      position = {
        x: pointerPosition.x - (width * scale) / 2,
        y: pointerPosition.y - (height * scale) / 2,
      };
    } else {
      const positions = generatePositions({
        startPosition: { x: absoluteStageX, y: absoluteStageY },
        columnsCount,
        rowsCount,
        paddingX,
        paddingY,
        cellWidth,
        gapHeight,
        gapWidth,
        cellHeight,
      });
      const randomPosition = positions[Math.floor(Math.random() * rowsCount * columnsCount)];
      /**@type {Konva.Layer} */
      const elementsLayer = currentPage;
      const emptyPosition = positions.find((position) => {
        const absolutePosition = {
          x: (position.x - absoluteStageX) * whiteboardScale + 1,
          y: (position.y - absoluteStageY) * whiteboardScale + 1,
        };
        let element = elementsLayer.getIntersection(absolutePosition);
        if (element instanceof Konva.Image) return false;
        element = elementsLayer.getIntersection({
          x: absolutePosition.x + cellWidth * whiteboardScale - 1,
          y: absolutePosition.y + cellHeight * whiteboardScale - 1,
        });
        if (element instanceof Konva.Image) return false;
        return true;
      });
      position = emptyPosition || randomPosition;
    }
    return { ...position, width, height, scale: { x: scale, y: scale } };
  } catch (error) {
    console.error(error);
    canvasBoard.props.hideSpinner();
  }
};

export const resetZoom = ({ canvasBoard, mainStage, animationDuration = canvasBoard ? 0.5 : 0 }) =>
  new Promise((resolve, reject) => {
    try {
      if (canvasBoard) {
        if (canvasBoard.zoomAnimationPlaying) return resolve();
        canvasBoard.zoomAnimationPlaying = true;
      }
      mainStage.to({
        x: 0,
        y: 0,
        scaleX: 1,
        scaleY: 1,
        duration: animationDuration,
        onFinish: () => {
          if (canvasBoard) {
            canvasBoard.applyZoomEffects(1, { x: 0, y: 0 });
            canvasBoard.zoomAnimationPlaying = false;
          }
          resolve();
        },
      });
    } catch (error) {
      reject(error);
    }
  });

export const zoomToFit = ({
  canvasBoard,
  elementsLayer,
  mainStage,
  animationDuration = canvasBoard ? 0.5 : 0,
  maxScale = canvasBoard ? canvasBoard.props.maxScale : 20,
}) =>
  new Promise(async (resolve, reject) => {
    try {
      if (canvasBoard && canvasBoard.zoomAnimationPlaying) return resolve({});
      const nodes = elementsLayer.getChildren();
      const xArray = nodes
        .map((node) => {
          if (node instanceof Konva.Transformer) return null;
          const { x, width } = node.getClientRect({ relativeTo: mainStage });
          return [x, x + width];
        })
        .filter((node) => node !== null)
        .flat();
      const yArray = nodes
        .map((node) => {
          if (node instanceof Konva.Transformer) return null;
          const { y, height } = node.getClientRect({ relativeTo: mainStage });
          return [y, y + height];
        })
        .filter((node) => node !== null)
        .flat();
      const minX = Math.min(...xArray);
      const minY = Math.min(...yArray);
      const maxX = Math.max(...xArray);
      const maxY = Math.max(...yArray);
      const fitWidth = maxX - minX;
      const fitHeight = maxY - minY;
      const stageWidth = mainStage.width();
      const stageHeight = mainStage.height();
      const scaleX = stageWidth / fitWidth;
      const scaleY = stageHeight / fitHeight;
      let scale = Math.min(scaleX, scaleY);
      scale = scale > maxScale ? maxScale : scale;
      const x = -minX * scale + (stageWidth - fitWidth * scale) / 2;
      const y = -minY * scale + (stageHeight - fitHeight * scale) / 2;
      if (scale < 1) {
        await resetZoom({ canvasBoard, mainStage });
        return resolve({ scale: 1, position: { x: 0, y: 0 } });
      }
      const position = limitPosition({ scale, position: { x, y }, mainStage });
      if (canvasBoard) canvasBoard.zoomAnimationPlaying = true;
      mainStage.to({
        ...position,
        scaleX: scale,
        scaleY: scale,
        duration: animationDuration,
        onFinish: () => {
          if (canvasBoard) {
            canvasBoard.applyZoomEffects(scale, position);
            canvasBoard.zoomAnimationPlaying = false;
          }
          return resolve({ scale, position });
        },
      });
    } catch (error) {
      reject(error);
    }
  });

export const limitPosition = ({ scale, position, mainStage }) => {
  const { width: stageWidth, height: stageHeight } = mainStage.size();
  let minX = -(stageWidth * (scale - 1));
  let minY = -(stageHeight * (scale - 1));
  if (position.x > 0) position.x = 0;
  if (position.y > 0) position.y = 0;
  if (position.x < minX) position.x = minX;
  if (position.y < minY) position.y = minY;
  return position;
};

export const applyZoomAndPosition = ({
  mainStage,
  scale,
  position,
  animationDuration = 0,
  sync = true,
  minScale = 1,
  maxScale = 20,
  canvasBoard,
}) =>
  new Promise((resolve, reject) => {
    try {
      if (canvasBoard) {
        if (canvasBoard.zoomAnimationPlaying) return resolve();
        canvasBoard.zoomAnimationPlaying = true;
      }
      if (scale) {
        if (scale < minScale) scale = 1;
        if (scale > maxScale) scale = maxScale;
      }
      if (position) position = limitPosition({ scale, position, mainStage });
      mainStage.to({
        ...(position ? position : {}),
        ...(scale ? { scaleX: scale, scaleY: scale } : {}),
        duration: animationDuration,
        onFinish: () => {
          if (canvasBoard) {
            canvasBoard.applyZoomEffects(scale, position, sync);
            canvasBoard.zoomAnimationPlaying = false;
          }
          resolve(true);
        },
      });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });

let drawItemsHandlers = [],
  runningHandler = Promise.resolve();

const checkProgress = async ({
  itemCounter,
  itemsLength,
  imageCount,
  loadedImageCount,
  callback,
}) => {
  if (itemCounter === itemsLength && imageCount === loadedImageCount) {
    const lastHandler = drawItemsHandlers.pop();
    if (drawItemsHandlers.length && lastHandler) {
      drawItemsHandlers = [];
      await lastHandler();
    }
    callback(true);
  }
};

export const drawItems = async ({
  items,
  pageLayouts,
  pageIds,
  selectedPageId,
  canvasBoard,
  disableCORS = false,
}) => {
  if (!items.length) return;
  const handler = () =>
    new Promise(async (resolve, reject) => {
      try {
        const imageCount = items.filter(({ type }) => type === 'image').length;
        const itemsLength = items.length;
        let itemCounter = 0,
          loadedImageCount = 0;
        pageLayouts.forEach((pageLayout) => pageLayout.destroyChildren());
        canvasBoard.setStateSynchronous({ videos: [] });
        if (!itemsLength) return resolve(true);
        items.sort((a, b) => {
          if (!a.zIndex || !b.zIndex) return 0;
          return a.zIndex > b.zIndex ? 1 : -1;
        });
        let largestIndex = items[itemsLength - 1].zIndex;
        canvasBoard.setState({ zIndex: largestIndex });
        for (const item of items) {
          itemCounter++;
          const pageLayoutOfItem = pageLayouts[pageIds.indexOf(item.pageId)];
          if (!pageLayoutOfItem) {
            if (item.type === 'image') loadedImageCount++;
            await checkProgress({
              itemCounter,
              itemsLength,
              imageCount,
              loadedImageCount,
              callback: resolve,
            });
            continue;
          }
          const retrievedBounds = positioning.retrieve(item.elementBounds.x, item.elementBounds.y);
          item.elementBounds.x = retrievedBounds.x;
          item.elementBounds.y = retrievedBounds.y;
          const retrievedSizes = positioning.retrieve(
            item.elementBounds.width,
            item.elementBounds.height,
          );
          item.elementBounds.width = retrievedSizes.x;
          item.elementBounds.height = retrievedSizes.y;
          if (item.type === 'polyline') {
            if (item.stroke) {
              const pointsList = item.stroke
                .trim()
                .split(' ')
                .map((point) => positioning.retrieve(+point, null).x);
              item.stroke = pointsList.join(' ');
            }
            const points = item.stroke.split(' ').map((n) => +n);
            if (points.length === 2) points.push(points[0], points[1], points[0] + 0.01, points[1]);
            if (points.length === 4) points.push(points[2] + 0.01, points[3]);
            var simpleLine = new Konva.Line({
              x: +item.elementBounds.x,
              y: +item.elementBounds.y,
              stroke: item.elementColor,
              strokeWidth: +item.elementSize * canvasBoard.props.transformFactor,
              width: +item.elementBounds.width,
              height: +item.elementBounds.height,
              scaleX: +item.elementScale?.x || 1,
              scaleY: +item.elementScale?.y || 1,
              lineJoin: 'round',
              lineCap: 'round',
              bezier: !item.isShape,
              id: item.elementId,
              opacity: item.elementOpacity,
              tension: item.isShape ? 0 : 0.4,
              points,
              rotationSnaps: [0, 90, 180, 270],
            });
            rotateAroundCenter(simpleLine, item.elementAngle);
            canvasBoard.setHitStrokeWidth(simpleLine);
            pageLayoutOfItem.add(simpleLine);
            canvasBoard.addLineTransformer(simpleLine, item);
          } else if (item.type === 'brush') {
            const brushObj = JSON.parse(JSON.stringify(item));
            canvasBoard.drawBrushObject(brushObj);
          } else if (item.type === 'image') {
            if (!item.s3Url) {
              loadedImageCount++;
              await checkProgress({
                itemCounter,
                itemsLength,
                imageCount,
                loadedImageCount,
                callback: resolve,
              });
              continue;
            }
            /** show loading image, till image is loaded */
            const loadingImage = new Image();
            let imagePlaceHolder;
            loadingImage.src = '/image.png';
            const dummyImagePromise = new Promise((resolve) => {
              loadingImage.onload = () => {
                imagePlaceHolder = new Konva.Image({
                  ...item.elementBounds,
                  width: 100,
                  height: 100,
                  scale: { x: 1 / canvasBoard.scale, y: 1 / canvasBoard.scale },
                  image: loadingImage,
                });
                pageLayoutOfItem.add(imagePlaceHolder);
                resolve('resolved');
              };
            });
            const image = new Image();
            if (disableCORS) image.crossOrigin = 'Anonymous';
            // eslint-disable-next-line no-loop-func
            image.onload = async () => {
              const imagePlot = new Konva.Image({
                x: +item.elementBounds.x,
                y: +item.elementBounds.y,
                width: +item.elementBounds.width,
                height: +item.elementBounds.height,
                id: item.elementId,
                image,
                scaleX: item.elementScale?.x || 1,
                scaleY: item.elementScale?.y || 1,
              });
              rotateAroundCenter(imagePlot, item.elementAngle);
              dummyImagePromise.then(() => imagePlaceHolder.destroy());
              pageLayoutOfItem.add(imagePlot);
              canvasBoard.addImageTransformer(imagePlot, item);
              loadedImageCount++;
              await checkProgress({
                itemCounter,
                itemsLength,
                imageCount,
                loadedImageCount,
                callback: resolve,
              });
            };
            // eslint-disable-next-line no-loop-func
            image.onerror = async () => {
              loadedImageCount++;
              canvasBoard.removeElement(pageLayoutOfItem, item.elementId);
              await checkProgress({
                itemCounter,
                itemsLength,
                imageCount,
                loadedImageCount,
                callback: resolve,
              });
            };
            image.src = item.s3Url;
          } else if (item.type === 'document') {
            if (!item.filetype) item.filetype = item.fileFormat;
            const typeOfDocument = item.fileFormat.split('/')[1];
            if (
              ['pdf', 'docx', 'xlsx', 'doc', 'xls', 'csv', 'txt', 'ppt', 'pptx'].includes(
                typeOfDocument,
              )
            ) {
              await canvasBoard.addDocument(item);
            } else if (typeOfDocument === 'mp4') {
              canvasBoard.addDocumentVideo(item);
            }
          } else if (item.type === 'video') {
            await canvasBoard.renderVideoIcon(item);
            canvasBoard.setState({ videos: [...canvasBoard.state.videos, item] });
          } else if (item.type === 'youtube') {
            await canvasBoard.renderVideoIcon(item);
            canvasBoard.setState({ videos: [...canvasBoard.state.videos, item] });
            let pageIndex = pageIds.indexOf(item.pageId);
            canvasBoard.setState({
              mediaLinks: [
                ...canvasBoard.state.mediaLinks,
                { pageNo: pageIndex, fileName: 'Youtube Link ', url: item.s3Url },
              ],
            });
          } else if (item.type === 'text') {
            canvasBoard.addText(item, true, true, false);
          }
          await checkProgress({
            itemCounter,
            itemsLength,
            imageCount,
            loadedImageCount,
            callback: resolve,
          });
        }
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  drawItemsHandlers.push(handler);
  if (drawItemsHandlers.length === 1) {
    runningHandler = await handler();
  } else {
    await runningHandler;
  }
};

const rotatePoint = ({ x, y }, rad) => {
  const rcos = Math.cos(rad);
  const rsin = Math.sin(rad);
  return { x: x * rcos - y * rsin, y: y * rcos + x * rsin };
};

// will work for shapes with top-left origin, like rectangle
export const rotateAroundCenter = (node, rotation) => {
  //current rotation origin (0, 0) relative to desired origin - center (node.width()/2, node.height()/2)
  const topLeft = {
    x: -(node.width() * node.scaleX()) / 2,
    y: -(node.height() * node.scaleY()) / 2,
  };
  const current = rotatePoint(topLeft, Konva.getAngle(node.rotation()));
  const rotated = rotatePoint(topLeft, Konva.getAngle(rotation));
  const dx = rotated.x - current.x,
    dy = rotated.y - current.y;

  node.rotation(rotation);
  node.x(node.x() + dx);
  node.y(node.y() + dy);
};
