import { makeStyles } from '@material-ui/core';

export const useMeetingCardStyles = makeStyles(() => ({
  container: {
    margin: '25px',
  },
  detailsContainer: {
    display: 'flex',
    marginBottom: '15px',
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px',
  },
  schedulerBtn: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    color: '#335AFB',
    cursor: 'pointer',
  },
  horizontalLine: {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
    border: '0.5px solid #E0E0E0',
  },
  topbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    marginBottom: '9px',
  },
  rightColumn: {
    display: 'flex',
    gap: '0.5rem',
  },
  noMeetingText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '25.3926px',
    lineHeight: '29px',
    color: 'rgba(0, 0, 0, 0.24)',
    marginLeft: '15px',
  },
  emptyContainer: {
    display: 'flex',
    margin: 'auto',
  },
  datePickerContainer: {
    display: 'grid',
    gridTemplateColumns: '33% 33% 33%',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  subText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '0.01em',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  timeText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  todaySchedule: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    color: '#666666',
  },
  timeContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  listScheduleSelect: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  selectScheduleFilterItem: {
    cursor: 'pointer',
  },
  selectScheduleFilterList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  actionsContainer: {
    marginLeft: '50px',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
}));
