import { makeStyles } from '@material-ui/core';

export const useHuddleButtonStyles = makeStyles(() => ({
  huddleButton: {
    textTransform: 'none',
    boxShadow: 'none',
    "&:hover": {
      boxShadow: 'none',
    },
  },

  huddleButtonOff: {
    textTransform: 'none',
    boxShadow: 'none',
    "&:hover": {
      boxShadow: 'none',
      backgroundColor: `rgba(0, 0, 0, 0.04) !important`
    },
  }
}));
