import ACTION_TYPES from "../actions/type.action";

/**
 * @type {null|{
 *  creator_name: string,
 *  meeting_title: string,
 *  meeting_link: string,
 *  meeting_password: string,
 *  meeting_time: string,
 *  meeting_duration: number,
 *  meeting_link: string,
 * }}
 */
const initialState = {
    meetingSchedule: {
        creator_name: "",
        meeting_duration:0,
        meeting_id: "",
        meeting_link: "",
        meeting_password: "",
        meeting_time:0,
        meeting_title: ""
    }
}

/**
 * @param {initialState} state
 * @param {{
 *  type: string,
 *  payload: initialState
 * }} action
 * @returns {initialState}
 */
export default (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case ACTION_TYPES.UPDATE_MEETING_SCHEDULE:
            return { ...state,meetingSchedule: payload }
        default:
            return { ...state }
    }
}