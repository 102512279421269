import config from "../../config";

// // STAGE
export default {
  flowURL: `${config.CANVAS_URL}/`,
  flow_id: "7fc572df-98ba-429c-9963-5e65faed02d9",
  class_id: "EPVJ9D",
  endpointURL: (token, flowId) =>
    `${config.BIRDAI_SERVER_URL}/?token=${token}&flowId=${flowId}`,
  userName: "sooraj",
  userID: "12345",
  className: "science",
  width: 1280,
  height: 720,
  flowItemURL: `${config.BIRDAI_SERVER_URL}/class/findOne?flowId=`,
  uploadURL: `${config.BIRDAI_SERVER_URL}/class/upload`,
  uploadBackgroundImage: `${config.BIRDAI_SERVER_URL}/class/uploadBackground`,
  getImagesURL: `${config.BIRDAI_SERVER_URL}/webutil/bingsearchimage`,
  getVideosURL:
    "https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=20&order=viewCount&safeSearch=strict&type=video&videoEmbeddable=true&key=AIzaSyCjx2_rs5n5bZWcom9Oa2rGwVWpd-I2hno&q=",
  getWebPageURL: (title) =>
    `https://en.wikipedia.org/w/api.php?format=json&action=query&prop=extracts&redirects=true&origin=*&titles=${title}`,
};

// // LOCAL
// export default {
//   flowURL: "http://localhost:7771/",
//   flow_id: "7fc572df-98ba-429c-9963-5e65faed02d9",
//   class_id: "EPVJ9D",
//   endpointURL: (token, flowId) =>
//     `http://localhost:7771/?token=${token}&flowId=${flowId}`,
//   userName: "sooraj",
//   userID: "12345",
//   className: "science",
//   width: 1280,
//   height: 720,
//   flowItemURL: "http://localhost:7771/class/findOne?flowId=",
//   uploadURL: "http://localhost:7771/class/upload",
//   getImagesURL: "https://academy.einsos.com/search/getResults",
//   getVideosURL:
//     "https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=20&order=viewCount&safeSearch=strict&type=video&videoEmbeddable=true&key=AIzaSyCjx2_rs5n5bZWcom9Oa2rGwVWpd-I2hno&q=",
//   getWebPageURL: (title) =>
//     `https://en.wikipedia.org/w/api.php?format=json&action=query&prop=extracts&redirects=true&origin=*&titles=${title}`,
// };
