import React from 'react';

const VideoOnIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 9H24C24.825 9 25.5 9.675 25.5 10.5V15.75L31.5 9.75V26.25L25.5 20.25V25.5C25.5 26.325 24.825 27 24 27H6C5.175 27 4.5 26.325 4.5 25.5V10.5C4.5 9.675 5.175 9 6 9ZM22.5 24V12H7.5V24H22.5Z"
        fill="white"
      />
    </svg>
  );
};

export default VideoOnIcon;
