import React from 'react';
import { EventSubjectContainer } from '../event-subject/container';
import { EventTimeInfoContainer } from '../event-time-info/container';
import { EventTypeContainer } from '../event-type/container';
import {
  EventUserProfileContainer,
  EventUserProfileNameContainer,
} from '../event-user-profile/container';

export const EventDetail = (props) => {
  const { eventInfo } = props;

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '12px',
          gap: '15px',
        }}
      >
        <EventUserProfileContainer eventInfo={eventInfo} />
        <div>
          <EventUserProfileNameContainer eventInfo={eventInfo} />
          <EventSubjectContainer eventInfo={eventInfo} />
          <EventTimeInfoContainer eventInfo={eventInfo} />
        </div>
        <EventTypeContainer eventInfo={eventInfo} />
      </div>
    </>
  );
};
