import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@material-ui/core';
import Konva from 'konva';

const PreviewContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(8),
  right: theme.spacing(1.6),
  border: '1px solid grey',
  opacity: 0.6,
  pointerEvents: 'none',
}));

/**
 *
 * @param {{mainStage?:Konva.Stage, updateInterval:number, background: any}} param0
 */
const MiniMap = ({ mainStage, updateInterval, background }) => {
  const {
    scale,
    position: { x, y },
  } = useSelector((state) => state.canvasReducer);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const previewContainerRef = useRef();
  const previewStageRef = useRef();
  const previewLayerRef = useRef();
  const backgroundPreviewRef = useRef();
  const zoomIndicatorRef = useRef();

  const addBackgroundPreview = useCallback((layers) => {
    if (!previewStageRef.current) return;
    const backgroundLayer = layers.find((layer) => layer.name() === 'background');
    if (!backgroundLayer) return;
    backgroundPreviewRef.current && backgroundPreviewRef.current.destroy();
    backgroundPreviewRef.current = backgroundLayer.clone({ listening: false });
    previewStageRef.current.add(backgroundPreviewRef.current);
  }, []);

  const renderZoomIndicator = useCallback(() => {
    if (!previewStageRef.current || !mainStage) return;
    if (scale === 1) {
      zoomIndicatorRef.current && zoomIndicatorRef.current.destroy();
      zoomIndicatorRef.current = undefined;
      return;
    }
    const { width: stageWidth, height: stageHeight } = mainStage.size();
    const indicatorWidth = stageWidth / scale;
    const indicatorHeight = stageHeight / scale;
    const zoomIndicator = new Konva.Rect({
      x: -x / scale,
      y: -y / scale,
      width: indicatorWidth,
      height: indicatorHeight,
      stroke: 'red',
      strokeWidth: 4,
      fill: '#fc03',
    });
    zoomIndicatorRef.current && zoomIndicatorRef.current.destroy();
    zoomIndicatorRef.current = new Konva.Layer({ listening: false, name: 'zoom-indicator' });
    zoomIndicatorRef.current.add(zoomIndicator);
    previewStageRef.current.add(zoomIndicatorRef.current);
    zoomIndicatorRef.current.moveToTop();
  }, [mainStage, x, y, scale]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!mainStage) return;
      const { width, height } = mainStage.size();
      setWidth(width / 5);
      setHeight(height / 5);
      if (!previewStageRef.current) {
        previewStageRef.current = new Konva.Stage({
          container: previewContainerRef.current,
          width: width / 5,
          height: height / 5,
          scaleX: 1 / 5,
          scaleY: 1 / 5,
        });
      }
      const layers = mainStage.getChildren();
      if (!backgroundPreviewRef.current) addBackgroundPreview(layers);
      const mainLayer = layers.find((layer) => layer.name() === 'page');
      if (!mainLayer) return;
      previewLayerRef.current && previewLayerRef.current.destroy();
      previewLayerRef.current = mainLayer.clone({ listening: false });
      previewStageRef.current.add(previewLayerRef.current);
      !zoomIndicatorRef.current && renderZoomIndicator();
      zoomIndicatorRef.current && zoomIndicatorRef.current.moveToTop();
    }, updateInterval);
    return () => clearInterval(timer);
  }, [mainStage, updateInterval, addBackgroundPreview, renderZoomIndicator]);

  useEffect(() => {
    if (!mainStage) return;
    const layers = mainStage.getChildren();
    addBackgroundPreview(layers);
  }, [background, mainStage, addBackgroundPreview]);

  useEffect(() => {
    if (!width || !height) return;
    if (!previewStageRef.current) return;
    previewStageRef.current.size({ width, height });
  }, [width, height]);

  useEffect(() => renderZoomIndicator(), [renderZoomIndicator]);

  return <PreviewContainer ref={previewContainerRef} />;
};

export default MiniMap;
