import { createTheme } from '@material-ui/core/styles';

export const BBB_THEME_COLOR = '#06172a';
export const PRIMARY_MAIN_COLOR = '#335AFB';
export const PRIMARY_LIGHT_COLOR = '#335AFB66';
export const ORANGE_PRIMARY_COLOR = '#EB5142';
export const RED_COLOR = '#E75B5C';
export const GREEN_COLOR = '#198F51';
export const WHITE = '#FFF';
export const BBB_BLUE_BUTTON_COLOR = '#1170d7';

export default createTheme({
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        '&$selected': {
          color: PRIMARY_MAIN_COLOR,
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: PRIMARY_MAIN_COLOR,
      },
    },
  },
  palette: {
    primary: {
      main: PRIMARY_MAIN_COLOR,
    },
  },
  typography: {
    fontFamily: 'Roboto',
    h1: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    h4: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '0.75rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '0.625rem',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '0.5rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
  },
});
