import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useMeetingOnPairedDevice } from '../launch-meeting-button/useMeetingOnPairedDevice';
import HostMeetingDialog from '../../dialogs/HostMeetingDialog';
import { useDialogDispatcher } from '../../../../store/dispatcher/useDialogDispatcher';
import { sendRemoteStartMessageToDevice } from '../../../../Utils/sockets/auth-user-launcher';

export const EventDescriptionContainer = (props) => {
  const { description, isDeviceConnected } = props;
  const { launchMeetingHandler } = useMeetingOnPairedDevice();
  const [isHostMeetingDialogOpen, setIsHostMeetingDialogOpen] = useState(false);
  const [meetingLink, setMeetingLink] = useState('');
  const { dispatchSetIsOpenRemoteControlDialog } = useDialogDispatcher();

  const onMeetingStartedOnDevice = () => {
    setIsHostMeetingDialogOpen(true);
  };

  const closeHostDialogHandler = () => {
    setIsHostMeetingDialogOpen(false);
  };

  const launchMeetingHere = () => {
    window.open(meetingLink, '_blank');
    closeHostDialogHandler();
  };

  useEffect(() => {
    const listener = (e) => {
      e.preventDefault();
      // TODO: differentiate between meeting and other links
      // launch meeting only if it's a meeting link
      launchMeetingHandler({
        subject: e.target.innerText,
        link: e.target.href,
        isDeviceConnected,
      });
      setMeetingLink(e.target.href);

      onMeetingStartedOnDevice();
    };
    const nodes = document.querySelectorAll('#event-description-root a');
    nodes.forEach((node) => {
      node.addEventListener('click', listener);
    });

    return () => {
      nodes.forEach((node) => {
        node.removeEventListener('click', listener);
      });
    };
  }, []);

  const handleOpenRemoteControlDialog = () => {
    closeHostDialogHandler();
    sendRemoteStartMessageToDevice();
  };

  return (
    <div id="event-description-root">
      {ReactHtmlParser(description)}

      <HostMeetingDialog
        open={isHostMeetingDialogOpen}
        onClose={closeHostDialogHandler}
        // launchMeetingHere={launchMeetingHere}
        handleOpenRemoteControlDialog={handleOpenRemoteControlDialog}
      />
    </div>
  );
};
