import { useDispatch } from 'react-redux';
import { resetSnackbar, setSnackbar } from '../../store/actions/notification.action';

const useSnackbarGlobal = () => {
  const dispatch = useDispatch();

  /**
   *
   * payload : {message: ""}
   *
   * @param {*} payload
   */
  const snackbarShowMessage = (payload) => {
    dispatch(setSnackbar(payload));
  };

  const hideSnackbar = () => {
    dispatch(resetSnackbar());
  };

  return {
    snackbarShowMessage,
    hideSnackbar,
  };
};

export default useSnackbarGlobal;
