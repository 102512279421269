import React from 'react';
// import useNotificationDispatcher from '../../../store/dispatcher/useNotificationDispatcher';
import { useNotificationDispatcher } from '../../../../store/dispatcher/useNotificationDispatcher';

import useNotificationSelectors from '../../../../store/selector/useNotificationSelectors';
// import { checkIsAdmin } from '../../../utils/authentication-access';
import FeatureLockedDialogComponent from './FeatureLockedDialogComponent';

const FeatureLockedDialogContainer = (props) => {
  const { featureLocked } = useNotificationSelectors();
  // const headerTitle = checkIsAdmin() ? 'Update Plan' : 'Feature Locked';
  const headerTitle = 'Upgrade Plan';

  const { dispatchHideFeatureLockedDialog } = useNotificationDispatcher();

  const onClose = () => {
    dispatchHideFeatureLockedDialog();
  };

  return (
    <FeatureLockedDialogComponent
      isOpen={featureLocked.isDialogOpen}
      // featureLocked.isDialogOpen
      feature={featureLocked.feature}
      onClose={onClose}
      headerTitle={headerTitle}
    />
  );
};

export default FeatureLockedDialogContainer;
