import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider, Tab, Tabs, Typography } from '@material-ui/core';
import { DoneIcon } from '@material-ui/icons';

import { MyCloud } from '../../MyCloud/MyCloud';
import Images from '../../../IWB/assets/img/imagesearch.svg';
import Videos from '../../../IWB/assets/img/videosearch.svg';
import '../Modal/KsarModal.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ overflowY: 'scroll', flex: 1, height: '100%' }}
      className="scrollBarStyle"
    >
      <Box p={3} style={{ height: '100%', paddingTop: '1vh', paddingBottom: '1vh' }}>
        {children}
      </Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '39vh',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      height: 'unset',
      minHeight: '39vh',
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  // labelContainer: {
  //   width: "auto",
  //   padding: 0
  // },
  iconLabelWrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    columnGap: 10,
    justifyItems: 'center',
    alignItems: 'center',
  },
  labelIcon: {
    minHeight: 25,
  },
  tabroot: {
    minWidth: 100,
    margin: '7px 10px',
    padding: '5px 15px',
    textTransform: 'initial',
    fontFamily: 'Poppins',
  },
}));

export function SplitView({ searchValue, canvasImage, canvasVideo, canvasDocument, canvasText }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (_, newValue) => setValue(newValue);

  const typesMap = ['images', 'videos'];

  return (
    <>
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          style={{ overflow: 'unset' }}
          value={value}
          onChange={handleChange}
          className={classes.tabs}
          TabIndicatorProps={{ color: '#fff0' }}
        >
          <Tab
            classes={{
              wrapper: classes.iconLabelWrapper,
              labelContainer: classes.labelContainer,
              labelIcon: classes.labelIcon,
              root: classes.tabroot,
            }}
            label="Images"
            {...a11yProps(0)}
            icon={<img src={Images} alt="Images" id="tabIcons" />}
          />
          <Tab
            label="Videos"
            {...a11yProps(1)}
            classes={{
              wrapper: classes.iconLabelWrapper,
              labelContainer: classes.labelContainer,
              labelIcon: classes.labelIcon,
              root: classes.tabroot,
            }}
            icon={<img src={Videos} alt="Videos" id="tabIcons" />}
          />
        </Tabs>
        <TabPanel value={value} index={value}>
          <MyCloud
            type={typesMap[value]}
            searchValue={searchValue}
            canvasImage={canvasImage}
            canvasVideo={canvasVideo}
          />
        </TabPanel>
      </div>
      <div style={{ width: '100%' }}>
        <Divider />
        <Typography
          style={{ padding: '15px 10px', color: '#888', fontSize: '12px', textAlign: 'center' }}
        >
          Only select content that you have confirmed that you have license to use.
        </Typography>
      </div>
    </>
  );
}
