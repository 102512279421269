import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, Typography, makeStyles } from '@material-ui/core';

import { backIcon } from '../../../assets/icons';
import { useLayoutDetails } from '../../../context/layout-context';

const useStyles = ({ show }) =>
  makeStyles({
    topbarRoot: {
      display: show ? 'flex' : 'none',
      alignItems: 'center',
      height: 64,
      padding: '0px 24px',
      backgroundColor: '#FFF',
      flexShrink: 0,
      borderBottom: '1px solid #E4E4E5',
      '& .topbar-title': {
        fontSize: 14,
        maxWidth: '100%',
      },
    },
  });

const Topbar = ({ show }) => {
  const history = useHistory();
  const classes = useStyles({ show })();
  const {
    layoutDetails: { headerTitle },
  } = useLayoutDetails();

  const backHandler = useCallback(() => {
    history.replace('/controller/learn-ai/notes');
  }, [history]);

  return (
    <div className={classes.topbarRoot}>
      <IconButton size="small" onClick={backHandler}>
        <img src={backIcon} alt="back" />
      </IconButton>
      <Typography variant="h1" className="topbar-title" noWrap>
        {headerTitle}
      </Typography>
    </div>
  );
};

export default Topbar;
