import React from 'react';
import { StyledFooter } from './component';

export const StyledFooterContainer = (props) => {
  const {
    closeText,
    disabledSubmitButton,
    footerContent,
    onClose,
    onCloseFooter,
    onSubmit,
    showFooterCloseButton,
    submitText,
  } = props;
  const cancelText = closeText ? closeText : 'Cancel';
  const submitTextContent = submitText ? submitText : 'Save';
  const onClickCancel = onCloseFooter ? onCloseFooter : onClose;

  return (
    <StyledFooter
      cancelText={cancelText}
      disabledSubmitButton={disabledSubmitButton}
      footerContent={footerContent}
      onClickCancel={onClickCancel}
      onSubmit={onSubmit}
      showFooterCloseButton={showFooterCloseButton}
      submitTextContent={submitTextContent}
    />
  );
};
