import React from 'react';
import { SuccessDialogComponent } from './component';

export const SuccessDialog = (props) => {
  const { open, headingText, descriptionText, onClickNext, ...rest } = props;

  return (
    <SuccessDialogComponent
      {...rest}
      open={open}
      headingText={headingText}
      descriptionText={descriptionText}
      onClickNext={onClickNext}
    />
  );
};
