import ACTION_TYPES from '../actions/type.action';

const initialState = {
  isAuthenticated: false,
  me: null,
};

const zoomReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPES.SET_ZOOM_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: payload,
      };
    case ACTION_TYPES.SET_ZOOM_ME:
      return {
        ...state,
        me: payload,
      };
    default:
      return { ...state };
  }
};

export default zoomReducer;
