import React from 'react';

const EraserTool = (props) => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <g clipPath="url(#clip1)">
        <path
          d="M9 17.9998L9 31.2809L9 49.5621L29 49.5621L29 31.2809L29 17.9998C29 16.8952 28.1046 15.9998 27 15.9998L11 15.9998C9.89543 15.9998 9 16.8952 9 17.9998Z"
          fill="#F9BF2C"
        />
        <path
          d="M9 18L9 22.8296L9 31L29 31L29 22.8296L29 18C29 16.8954 28.1046 16 27 16L11 16C9.89543 16 9 16.8954 9 18Z"
          fill="white"
        />
        <path
          d="M11 8L11 10.5531L11 16L27 16L27 10.5531L27 8C27 6.89543 26.1046 6 25 6L13 6C11.8954 6 11 6.89543 11 8Z"
          fill="#EBA29E"
        />
        <path
          d="M29 22.5L29 31.281L19 31.281L19 16L29 16L29 22.5Z"
          fill="url(#paint0_linear)"
          fillOpacity="0.8"
        />
        <path
          d="M9 22.5L9 31.281L19 31.281L19 16L9 16L9 22.5Z"
          fill="url(#paint1_linear)"
          fillOpacity="0.8"
        />
        <path d="M19 31H29V49.2812H19L19 31Z" fill="url(#paint2_linear)" fillOpacity="0.8" />
        <path d="M9 49.2812V31H19V49.2812H9Z" fill="url(#paint3_linear)" fillOpacity="0.8" />
        <path
          d="M9 17.9998L9 31.2809L9 49.5621L29 49.5621L29 31.2809L29 17.9998C29 16.8952 28.1046 15.9998 27 15.9998L11 15.9998C9.89543 15.9998 9 16.8952 9 17.9998Z"
          stroke="black"
        />
        <path d="M9 31.4687H29" stroke="black" />
        <path
          d="M11 8L11 10.5531L11 16L27 16L27 10.5531L27 8C27 6.89543 26.1046 6 25 6L13 6C11.8954 6 11 6.89543 11 8Z"
          stroke="black"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="22.125"
        y1="20.7341"
        x2="19"
        y2="20.7341"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DDDDDD" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="15.875"
        y1="20.7341"
        x2="19"
        y2="20.7341"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DDDDDD" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="21.5"
        y1="21.5645"
        x2="19"
        y2="21.5645"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.2" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="16.5"
        y1="21.5645"
        x2="19"
        y2="21.5645"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.2" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="38" height="38" fill="white" />
      </clipPath>
      <clipPath id="clip1">
        <rect width="24" height="43" fill="white" transform="translate(7 4)" />
      </clipPath>
    </defs>
  </svg>
);

export default EraserTool;
