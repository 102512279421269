import React from 'react';
import { ContentContainer } from './component.style';

export const StyledFullPage = (props) => {
  const { isOpen, ...rest } = props;

  if (!isOpen) {
    return <></>;
  }

  return <ContentContainer {...rest}>{props.children}</ContentContainer>;
};
