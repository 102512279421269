import React from 'react';
import { makeStyles } from '@material-ui/core';

import { CHAT_TYPES, PERSONA_TYPES } from '../../../constants';
import { PDFDetails, AgentList, LanguageSelect, PdfButton, YoutubeButton } from '../index';
import AIChat from './ai-chat';
import UserChat from './user-chat';

const useStyles = makeStyles((theme) => ({
  root: {
    '--scroll-bar-color': 'rgba(0, 0, 0, 0)',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    overflow: 'auto',
    transition: '--scroll-bar-color .5s',
    '&::-webkit-scrollbar-thumb': {
      background: 'var(--scroll-bar-color)',
    },
    '&:hover, &:focus, &:active': {
      '--scroll-bar-color': 'rgba(0, 0, 0, 0.3)',
    },
    [theme.breakpoints.down('sm')]: {
      '--scroll-bar-color': 'rgba(0, 0, 0, 0.3)',
    },
  },
  personaContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 12,
    marginBottom: -16,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
}));

export const ChatBox = ({
  chatList,
  isLoading,
  onQuestionClick,
  persona,
  onPersonaChange,
  isPdfThread,
  language,
  onLanguageChange,
  onYoutubeSubmit,
  onAddToLesson,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!isPdfThread && !persona && (
        <div className={classes.personaContainer}>
          <LanguageSelect language={language} onChange={onLanguageChange} />
          <PdfButton />
          <YoutubeButton onSubmit={onYoutubeSubmit} />
          <AgentList agent={persona || PERSONA_TYPES.GENERAL} onClick={onPersonaChange} mini />
        </div>
      )}
      {chatList.map(({ type, ...otherProps }, index) =>
        type === CHAT_TYPES.USER ? (
          <UserChat key={index} {...otherProps} />
        ) : type === CHAT_TYPES.PDF ? (
          <PDFDetails key={index} onQuestionClick={onQuestionClick} {...otherProps} />
        ) : type === CHAT_TYPES.YOUTUBE ? (
          <PDFDetails key={index} onQuestionClick={onQuestionClick} {...otherProps} isYoutube />
        ) : (
          <AIChat
            key={index}
            persona={persona}
            onAddToLesson={onAddToLesson}
            isLoading={isLoading}
            {...otherProps}
          />
        ),
      )}
    </div>
  );
};

export default ChatBox;
