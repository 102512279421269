import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import FiltersIcon from './FiltersIcon';
import { makeStyles } from '@material-ui/core';
import CreateBtn from './CreateBtn';
import Search from '../Search/Search';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // padding: '1rem 0',
    paddingBottom: '1rem',
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  title: {
    color: '#202020',
    fontFamily: 'Cairo',
    fontSize: '20.999px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    width: '400px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '@media (max-width: 450px)': {
      width: '260px',
    },
  },
  createActionButtonContainer: {
    // padding: '0 1rem',
    paddingBottom: '1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
}));

const MobileViewTopbar = ({
  title,
  onSearchTextChange,
  searchText,
  showFilters,
  filtersViewHandler,

  createActionButton1,
  createActionButton1Label,
  createActionClickAction,

  createActionButton2,
  createActionButton2Label,
  createActionClick2Action,

  hideIcon,
}) => {
  const [searchMode, setSearchMode] = useState(false);
  const classes = useStyles();

  const closeSearch = () => {
    onSearchTextChange('');
    setSearchMode(false);
  };

  const searchModeHandler = () => {
    setSearchMode(true);
  };

  return (
    <div>
      <>
        {searchMode ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '15px 0',
              gap: '10px',
              paddingTop: '10px',
            }}
          >
            <Search
              // searchText={searchText}
              onEnterSearchText={onSearchTextChange}
              background={'rgba(255, 255, 255, 0.60)'}
              height={'36px'}
            />
            <CloseIcon onClick={closeSearch} />
          </div>
        ) : (
          <>
            <div className={classes.container}>
              <div className={classes.title}>{title}</div>

              <div className={classes.rightContainer}>
                {onSearchTextChange && (
                  <SearchIcon
                    fontSize="medium"
                    style={{
                      color: 'rgba(0, 0, 0, 0.6)',
                      marginLeft: '0.5rem',
                      cursor: 'pointer',
                      width: '28px',
                      height: '28px',
                    }}
                    onClick={searchModeHandler}
                  />
                )}
                {showFilters && (
                  <div onClick={filtersViewHandler}>
                    <FiltersIcon />
                  </div>
                )}
              </div>
            </div>

            {createActionButton1 && (
              <div className={classes.createActionButtonContainer}>
                <CreateBtn
                  hideIcon={hideIcon}
                  createActionButton1Label={createActionButton1Label}
                  createActionClickAction={createActionClickAction}
                />
                {createActionButton2 && (
                  <CreateBtn
                    createActionButton1Label={createActionButton2Label}
                    createActionClickAction={createActionClick2Action}
                  />
                )}
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default MobileViewTopbar;
