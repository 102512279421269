import React, { useState } from 'react';
import { TextField } from '@material-ui/core';

import { StyledDialog } from './Dialog/index';

export const InputDialog = ({
  dialogTitle,
  inputName,
  inputLabel,
  submitText,
  initialValue = '',
  emptyValueErrorMessage = 'Name is required',
  onClose,
  onSubmit,
}) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState('');

  const handleNameChange = (e) => {
    if (e.target.value) setError('');
    setValue(e.target.value);
  };

  const submitHandler = () => {
    if (!value.trim()) return setError(emptyValueErrorMessage);
    onSubmit(value);
    onClose();
  };

  return (
    <StyledDialog
      open={true}
      onClose={onClose}
      onSubmit={submitHandler}
      title={dialogTitle}
      submitText={submitText}
    >
      <label
        htmlFor="i-value"
        style={{ display: 'block', fontSize: 12, fontWeight: 500, marginBottom: 4 }}
      >
        {inputLabel}
      </label>
      <TextField
        id="i-value"
        name={inputName}
        variant="outlined"
        value={value}
        onChange={handleNameChange}
        error={!!error}
        helperText={error}
        style={{ width: '100%' }}
        inputRef={(ref) => ref && ref.focus()}
        onKeyUp={(e) => e.key === 'Enter' && submitHandler()}
      />
    </StyledDialog>
  );
};
