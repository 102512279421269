import React, { useEffect } from 'react';
import ZoomMtgEmbedded from '@zoomus/websdk/embedded';
import { getSignature } from '../../../../../Utils/zoom';
import { getZoomMeetingUrlParameters } from '../../../../../Web-Ai-Canvas/utils/url';
import { getUserName } from '../../../../../Utils/authentication-access';
import config from '../../../../../config';
import { useNotificationDispatcher } from '../../../../../store/dispatcher/useNotificationDispatcher';
import zoomService from '../../../../../services/zoom.service';

export const ZoomComponentViewContainer = (props) => {
  const { meetingUrl, count } = props;
  const sdkKey = config.ZOOM_SDK_KEY;
  const role = 1;
  const meetingUrlParams = getZoomMeetingUrlParameters(meetingUrl);
  const meetingNumber = meetingUrlParams.meetingNumber;
  const password = meetingUrlParams.pwd;
  const userEmail = '';
  const registrantToken = '';
  const client = ZoomMtgEmbedded.createClient();
  const { dispatchSetSnackbar } = useNotificationDispatcher();

  const initMeetingClient = (client) => {
    console.log('zoom:initMeetingClient');
    let meetingSDKElement = document.getElementById('meetingSDKElement');
    client.init({
      debug: true,
      zoomAppRoot: meetingSDKElement,
      language: 'en-US',
      customize: {
        meetingInfo: [
          'topic',
          'host',
          'mn',
          'pwd',
          'telPwd',
          'invite',
          'participant',
          'dc',
          'enctype',
        ],
      },
    });
  };

  const joinClient = async ({ client, signature, zak }) => {
    console.log('join client');
    try {
      await client.join({
        sdkKey: sdkKey,
        signature: signature,
        meetingNumber: meetingNumber,
        password: password,
        userName: getUserName(),
        userEmail: userEmail,
        tk: registrantToken,
        zak,
        role,
      });
    } catch (error) {
      dispatchSetSnackbar({ message: error.reason });
    }
  };

  useEffect(() => {
    const init = async () => {
      initMeetingClient(client);
    };
    init();
  }, []);

  useEffect(() => {
    const handler = async () => {
      if (!meetingUrl) {
        return;
      }

      const res2 = await zoomService.getZAK();
      const signature = await getSignature({ meetingNumber, role: res2 ? role : 0 });
      const zak = res2?.data?.token;
      joinClient({ client, signature, zak });
    };

    handler();
  }, [count, meetingUrl]);

  return (
    <div style={{ bottom: '3rem', right: '3rem', position: 'fixed' }} id="meetingSDKElement">
      {/* Zoom Meeting SDK Component View Rendered Here */}
    </div>
  );
};
