import React from 'react';
import { Card, Typography } from '@material-ui/core';
import { useHuddleInvitationStyles } from './component.style';

export const HuddleInvitation = (props) => {
  const classes = useHuddleInvitationStyles();
  const {
    title,
    hideHuddleInvitation,
    joinHuddle,
    joinNowLabel,
    joinLaterLabel,
  } = props;

  const joinNowButton = (
    <Typography color="primary" className={classes.button} onClick={joinHuddle}>
      {joinNowLabel}
    </Typography>
  );

  const joinLaterButton = (
    <Typography className={classes.button} onClick={hideHuddleInvitation}>
      {joinLaterLabel}
    </Typography>
  );

  return (
    <Card className={classes.container}>
      <Typography>{title}</Typography>
      <div className={classes.buttonContainer}>
        {joinNowButton}
        {joinLaterButton}
      </div>
    </Card>
  );
};
