import { makeStyles } from '@material-ui/core';

export const useUnauthenticatedAccountStyles = makeStyles((theme) => ({
  msContentContainer: {
    height: '380px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    margin: '30% auto',
    justifyContent: 'center',
    alignItems: 'center',
    width: '22rem',
  },
  connectToCalendarText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    padding: '0 30px',
  },
  iconContainerStyle: {
    borderRadius: '4px',
    padding: '16px 21px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    cursor: 'pointer',
    background: 'rgba(255, 255, 255, 0.1)',
    border: '1px solid #F0F0F0',
    backdropFilter: 'blur(50px)',
  },
  iconTextStyle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    marginTop: '8px',
  },
}));
