import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';

const StyledButton = ({
  defaultBorder,
  fullHeight,
  lg,
  sm,
  marginLeft,
  marginTop,
  mdWidth,
  ml,
  upper,
  spinnerSize,
  spinnerStyle,
  containerStyle = {},
  ...props
}) => {
  if (fullHeight) {
    containerStyle = {
      ...containerStyle,
      height: '100%',
    };
  }

  if (lg) {
    containerStyle = {
      ...containerStyle,
      height: '3.375rem',
    };
  }

  if (sm) {
    containerStyle = {
      ...containerStyle,
      height: '2.5rem',
    };
  }

  if (ml) {
    containerStyle = {
      ...containerStyle,
      marginLeft: '0.5rem',
    };
  }

  if (mdWidth) {
    containerStyle = {
      ...containerStyle,
      width: '12rem',
    };
  }

  if (marginTop) {
    containerStyle = { ...containerStyle, marginTop: '1rem' };
  }

  if (marginLeft === 'sm') {
    containerStyle = { ...containerStyle, marginLeft: '0.5rem' };
  }

  if (defaultBorder) {
    containerStyle = { ...containerStyle, borderRadius: '0.25rem' };
  }

  const displaySpinner = <CircularProgress size={spinnerSize} style={spinnerStyle} />;

  const displayContent = props.isLoading ? displaySpinner : props.children;

  return (
    <Button className={props.className} {...props} style={containerStyle}>
      {displayContent}
    </Button>
  );
};

export default StyledButton;
