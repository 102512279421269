import React from 'react';
import PublishedBlue from '../svg/PublishedBlueIcon.svg';

const PublishedOrangeIcon = () => {
  return (
    <>
      <img src={PublishedBlue} alt="publishedicon" />
    </>
  );
};

export default PublishedOrangeIcon;
