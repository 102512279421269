import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

import { pdfIcon } from '../../assets/icons';

const useStyles = ({ mini }) =>
  makeStyles((theme) => ({
    pdfButtonRoot: {
      '& .MuiButton-root': {
        gap: mini ? 4 : 8,
        height: 36,
        borderRadius: 16,
        border: mini ? undefined : `1px solid ${theme.palette.primary.main}`,
        fontSize: mini ? 11 : 14,
        fontWeight: 400,
        color: '#767676',
      },
    },
  }));

export const PdfButton = ({ mini }) => {
  const classes = useStyles({ mini })();

  return (
    <label htmlFor="pdf-query-file" className={classes.pdfButtonRoot}>
      <Button
        variant={mini ? 'text' : 'outlined'}
        component="span"
        startIcon={
          <img src={pdfIcon} alt="upload pdf" width={mini ? 28 : 20} height={mini ? 28 : 20} />
        }
      >
        {mini ? 'PDF' : 'Analyse PDF'}
      </Button>
    </label>
  );
};
