import { makeStyles } from '@material-ui/core';

export const useDocumentSelectImportDialogStyles = makeStyles(() => ({
  footerContainer: {
    display: 'flex',
    gap: '0.5rem',
    justifyContent: 'flex-end',
    flex: 1,
  },
}));
