import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useDeviceCodeDialogStyles = makeStyles(() => ({
  container: {
    padding: '1rem',
    width: '17.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.7rem',
  },
  infoContainer: {
    display: 'flex',
    padding: '0.7rem',
    gap: '1rem',
    backgroundColor: grey[300],
    alignItems: 'center',
  },
  formContainer: {
    width: '70%',
  },
  lableStyle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    paddingBottom: '4px',
    display: "flex",
    alignItems: 'center',
    gap: '5px'
  },
  pairBtn: {
    height: '53px',
  },

  castSpaceTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '18px',
    color: 'rgba(0, 0, 0, 0.87)',
  },

  infoIcon: {
    height: "12px",
    cursor: "pointer"
  }
}));
