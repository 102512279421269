import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { EVENTS } from '../../../constant';
import { useNotificationDispatcher } from '../../../../store/dispatcher/useNotificationDispatcher';

// import broadcastService from '../../../store/services/broadcast.service';
// import licenseService from '../../../store/services/license.service';
// import moveToCloud from '../../../utils/auth/loginFromWebsite/moveToCloud';

// import {
//   checkIsAdmin,
//   getInstituteKneuraId,
//   getKneuraId,
// } from '../../../utils/authentication-access';
// import useLicense from '../../../store/selectors/useLicenseSelector';
import FeatureLockedComponent from './FeatureLockedComponent';
import PubSub from 'pubsub-js';
import useSnackbarGlobal from '../../../../Utils/message/useSnackbarGlobal';

const FeatureLockedContainer = (props) => {
  let { feature, text } = props;
  // const isWebview = useSelector((state) => state.webview.isWebview);
  const [isAdmin, setIsAdmin] = useState(false);
  const { dispatchHideFeatureLockedDialog, dispatchShowSpinner } = useNotificationDispatcher();
  // const { licenseDetails } = useLicense();
  const { snackbarShowMessage } = useSnackbarGlobal();

  // useEffect(() => {
  //   let checkIsAdminResponse = checkIsAdmin();
  //   setIsAdmin(checkIsAdminResponse);
  // }, []);

  if (!text) {
    text = `Feature unavailable in the current plan`;
  }

  const upgradeText = 'Upgrade Plan';
  const tryFreeText = 'Try free for 30 days';
  const contactUsText = 'Contact Us';

  const subText = <>Upgrade plan to get access to this and other advanced features.</>;

  const message = (
    <>
      contact:{' '}
      <span style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 700 }}>
        support@birdai.com
      </span>
    </>
  );

  const upgradeButtonOnClick = () => {
    onClickHandler();
  };

  const onClickHandler = () => {
    // if (checkIsWebview()) {
    //   const userKneuraId = getKneuraId();
    //   const instituteKneuraId = getInstituteKneuraId();
    //   broadcastService.sendOpenLicensePlansWebviewMessage(userKneuraId, instituteKneuraId, feature);
    //   dispatchHideFeatureLockedDialog();
    //   dispatchShowSpinner();
    // } else {
    //   dispatchHideFeatureLockedDialog();
    //   moveToCloud();
    // }
  };

  const contactUsClick = async () => {
    // if (checkIsWebview()) {
    //   const userKneuraId = getKneuraId();
    //   const instituteKneuraId = getInstituteKneuraId();
    //   broadcastService.sendOpenContactSalesWebviewMessage(userKneuraId, instituteKneuraId, feature);
    //   dispatchHideFeatureLockedDialog();
    //   dispatchShowSpinner();
    // } else {
    //   dispatchHideFeatureLockedDialog();
    //   window.open('https://cloud.brightclass.com/Contactsales');
    // }
  };

  const onClose = () => {
    dispatchHideFeatureLockedDialog();
  };

  return (
    <FeatureLockedComponent
      // isAdmin={isAdmin}
      // isTrialAlreadyUsed={isTrialAlreadyUsed}
      text={text}
      subText={subText}
      message={message}
      upgradeText={upgradeText}
      tryFreeText={tryFreeText}
      contactUsText={contactUsText}
      upgradeButtonOnClick={upgradeButtonOnClick}
      // tryFreeOnClick={tryFreeOnClick}
      contactUsClick={contactUsClick}
      onClose={onClose}
    />
  );
};

export default FeatureLockedContainer;
