import ACTION_TYPE from './type.action';

export const setFileInfo = (fileInfo) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_FILE_INFO,
    payload: {
      fileInfo,
    },
  });
};

export const resetFileInfo = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.RESET_FILE_INFO,
    payload: {
      fileInfo: undefined,
    },
  });
};

export const setDocInfo = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_DOC_INFO,
    payload,
  });
};

export const resetDocInfo = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.RESET_DOC_INFO,
    payload: {
      docInfo: undefined,
    },
  });
};
