import { styled } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export const ContentContainer = styled('div')({
  borderBottom: '1px solid #ccc',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1rem 0',
  position: 'relative',
});

export const DisplayCloseIcon = styled(CloseIcon)({
  position: 'absolute',
  right: '1rem',
  cursor: 'pointer',
});
