import { styled } from '@material-ui/core';

export const ContentContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTop: '1px solid #999999',
  padding: '0.5rem',
  position: 'sticky',
  bottom: 0,
  backgroundColor: '#FFFFFF',
});

export const MoreButton = styled('div')({
  borderRadius: '50%',
  width: '1.5rem',
  height: '1.5rem',
  display: 'flex',
  marginRight: '0.5rem',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  border: '1px solid #000000',
  '&:hover': {
    backgroundColor: '#e5e5e5',
  },
});

export const Section = styled('div')({
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'center',
});
