import React from 'react';

const VolumeOnIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 5.28998V3.22998C18.01 4.13998 21 7.71998 21 12C21 16.28 18.01 19.86 14 20.77V18.71C16.89 17.85 19 15.17 19 12C19 8.82998 16.89 6.14998 14 5.28998ZM3 15V8.99998H7L12 3.99998V20L7 15H3ZM10 15.17V8.82998L7.83 11H5V13H7.83L10 15.17ZM16.5 12C16.5 10.23 15.48 8.70998 14 7.96998V16.02C15.48 15.29 16.5 13.77 16.5 12Z"
        fill="white"
      />
    </svg>
  );
};

export default VolumeOnIcon;
