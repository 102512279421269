import { getUserId } from '../../Utils';
import {
  addNote,
  addSource,
  deleteNote,
  toggleAllNoteSources,
  updateNote,
  updateSource,
} from '../../Utils/smart-notes';
import { SMART_NOTES_ACTION_TYPES } from '../actions/type.action';

const filterUserNotes = (notes) => {
  const userId = getUserId();
  return notes.filter((note) => (note.userId = userId));
};

const initialState = [];

const smartNotesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SMART_NOTES_ACTION_TYPES.SET_NOTES:
      return payload;
    case SMART_NOTES_ACTION_TYPES.ADD_NOTE: {
      const notes = addNote(payload);
      return filterUserNotes(notes);
    }
    case SMART_NOTES_ACTION_TYPES.DELETE_NOTE: {
      const notes = deleteNote(payload);
      return filterUserNotes(notes);
    }
    case SMART_NOTES_ACTION_TYPES.UPDATE_NOTE: {
      const notes = updateNote(payload);
      return filterUserNotes(notes);
    }
    case SMART_NOTES_ACTION_TYPES.ADD_SOURCE_TO_NOTE: {
      const notes = addSource(payload);
      return filterUserNotes(notes);
    }
    case SMART_NOTES_ACTION_TYPES.UPDATE_SOURCE: {
      const { noteId, id, ...sourceDetails } = payload;
      const notes = updateSource({ noteId, id, ...sourceDetails });
      return filterUserNotes(notes);
    }
    case SMART_NOTES_ACTION_TYPES.SELECT_SOURCE: {
      const { noteId, id } = payload;
      const notes = updateSource({ noteId, id, selected: true });
      return filterUserNotes(notes);
    }
    case SMART_NOTES_ACTION_TYPES.DESELECT_SOURCE: {
      const { noteId, id } = payload;
      const notes = updateSource({ noteId, id, selected: false });
      return filterUserNotes(notes);
    }
    case SMART_NOTES_ACTION_TYPES.TOGGLE_ALL_NOTE_SOURCES: {
      const { noteId, selected } = payload;
      const notes = toggleAllNoteSources({ noteId, selected });
      return filterUserNotes(notes);
    }
    default:
      return state;
  }
};

export default smartNotesReducer;
