import ACTION_TYPES from "../actions/type.action";
import _ from "lodash";

const initialState = {
  licenseDetails: {},
  cloudLicenseDetails: {},
  // licensePaymentReminderDetails: {},
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.GET_LICENSE_DETAILS:
      return { ...state, licenseDetails: _.cloneDeep(payload) };
    case ACTION_TYPES.GET_CLOUD_LICENSE_DETAILS:
      return { ...state, cloudLicenseDetails: _.cloneDeep(payload) };
    // case ACTION_TYPES.GET_LICENSE_PAYMENT_REMINDER_DETAILS:
    // return { ...state, licensePaymentReminderDetails: _.cloneDeep(payload) };
    default:
      return state;
  }
};

export default reducer;
