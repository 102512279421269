import React from 'react';

const CanvasContainedIcon = (props) => {
  let fill = props.fill ? props.fill : '#000';
  let fillOpacity = props.fill ? 1 : 0.38;

  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_11395_87)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.4624 14.5908C20.5956 14.42 20.7504 14.2651 21.0602 13.9554L24.4992 10.5164C26.7591 10.5672 28.0923 10.7751 29.0403 11.6267C29.0951 11.6759 29.1487 11.7266 29.201 11.7785C29.1735 11.8095 29.1449 11.8398 29.1152 11.8694L24.0447 16.9399C23.735 17.2497 23.5801 17.4046 23.4094 17.5377C23.2079 17.6949 22.99 17.8296 22.7594 17.9395C22.5639 18.0326 22.3561 18.1019 21.9406 18.2404L21.9405 18.2404L20.1796 18.8274L19.7491 18.9709C19.7234 18.9794 19.6974 18.9861 19.6713 18.9909C19.4891 19.0241 19.3 18.9667 19.1667 18.8334C19.0334 18.7001 18.976 18.511 19.0093 18.3288C19.014 18.3027 19.0207 18.2767 19.0292 18.251L19.7597 16.0596C19.8982 15.644 19.9675 15.4363 20.0606 15.2408C20.1706 15.0102 20.3053 14.7922 20.4624 14.5908ZM29.7889 12.5393C29.7882 12.5399 29.7876 12.5406 29.787 12.5412L24.7165 17.6117L24.6791 17.6491C24.4052 17.9231 24.2113 18.1171 23.9936 18.2868C23.7378 18.4864 23.461 18.6575 23.1681 18.7971C22.9189 18.9158 22.6586 19.0025 22.2911 19.1249L22.2911 19.1249L22.2409 19.1417L20.48 19.7287L20.0495 19.8721C19.5037 20.0541 18.9018 19.912 18.495 19.5051C18.0881 19.0983 17.946 18.4965 18.128 17.9506L18.8584 15.7592L18.8752 15.709L18.8752 15.709C18.9976 15.3415 19.0843 15.0812 19.2031 14.8321C19.3427 14.5392 19.5137 14.2623 19.7133 14.0065C19.8831 13.7888 20.077 13.5949 20.351 13.321L20.3884 13.2836L23.1707 10.5014C22.8014 10.5 22.4117 10.5 22 10.5H16C12.1427 10.5 10.214 10.5 8.95972 11.6267C8.84283 11.7317 8.73165 11.8428 8.62666 11.9597C7.5 13.214 7.5 15.1427 7.5 19C7.5 22.8573 7.5 24.786 8.62666 26.0403C8.73165 26.1572 8.84283 26.2683 8.95972 26.3733C10.214 27.5 12.1427 27.5 16 27.5H22C25.8573 27.5 27.786 27.5 29.0403 26.3733C29.1572 26.2683 29.2683 26.1572 29.3733 26.0403C30.5 24.786 30.5 22.8573 30.5 19C30.5 15.6912 30.5 13.8015 29.7889 12.5393ZM12.62 25.105C12.62 24.7267 12.9267 24.42 13.305 24.42H24.705C25.0833 24.42 25.39 24.7267 25.39 25.105C25.39 25.4834 25.0833 25.79 24.705 25.79H13.305C12.9267 25.79 12.62 25.4834 12.62 25.105Z"
          fill={fill}
        />
        <path
          d="M25.6332 9.38227L26.1307 8.88484C26.9548 8.06068 28.2911 8.06068 29.1152 8.88484C29.9394 9.70901 29.9394 11.0452 29.1152 11.8694L28.6178 12.3668L24.0447 16.9399C23.735 17.2496 23.5801 17.4045 23.4093 17.5377C23.2079 17.6948 22.99 17.8295 22.7593 17.9394C22.5638 18.0326 22.3561 18.1018 21.9405 18.2404L20.1796 18.8274L19.7491 18.9708C19.5446 19.039 19.3191 18.9858 19.1667 18.8334C19.0143 18.6809 18.9611 18.4555 19.0292 18.251L19.1727 17.8205L19.7597 16.0596C19.8982 15.644 19.9675 15.4362 20.0606 15.2407C20.1705 15.0101 20.3052 14.7922 20.4624 14.5907C20.5956 14.42 20.7504 14.2651 21.0602 13.9553L25.6332 9.38227Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_11395_87">
          <rect width="23" height="23" fill="white" transform="translate(7.5 7.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CanvasContainedIcon;
