import React from 'react';

export const ZoomSmall = (props) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5852 16.0239H5.50072C2.76269 16.0239 0.542969 13.8042 0.542969 11.0662V4.98167C0.542969 2.24364 2.76269 0.0239258 5.50072 0.0239258H11.5852C14.3232 0.0239258 16.543 2.24364 16.543 4.98167V11.0662C16.543 13.8042 14.3232 16.0239 11.5852 16.0239ZM12.6717 10.6156C12.5538 10.6156 12.44 10.5734 12.3506 10.4968L11.3051 9.60079C11.0555 9.38671 10.9117 9.07414 10.9117 8.74513V7.30288C10.9117 6.97386 11.0555 6.66152 11.3051 6.44744L12.3506 5.55144C12.44 5.47482 12.5538 5.43268 12.6717 5.43268C12.9441 5.43268 13.1652 5.65352 13.1652 5.9262V10.1223C13.1652 10.3947 12.9444 10.6156 12.6717 10.6156ZM5.72754 10.6158H9.33317C9.83097 10.6158 10.2346 10.2122 10.2346 9.71437V7.23549C10.2346 6.23989 9.42737 5.43268 8.43176 5.43268H4.82613C4.32832 5.43268 3.92472 5.83628 3.92472 6.33409V8.81296C3.92472 9.80857 4.73193 10.6158 5.72754 10.6158Z"
        fill="black"
      />
    </svg>
  );
};
