import { Button, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useEventInfoStyles } from './component.style';
import Icon from '@mdi/react';
import { mdiCalendar } from '@mdi/js';
import { getEndTime, getStartTime } from '../../../../../Utils/microsoft/outlook-event';
import { EventDateTimeInfoContainer } from '../event-date-time-info/container';

/**
 * if non recurring, show startDate
 * if recurring, endsAt or NeverEnds
 * @param {*} props
 * @returns
 */
export const EventInfo = (props) => {
  const { eventInfo, importEvent, isRecurring, endsAt, outlookImportedEventsIds } = props;
  const classes = useEventInfoStyles();
  const { isAllDay } = eventInfo;

  const startTime = getStartTime(eventInfo);
  const endTime = getEndTime(eventInfo);

  const displaySubject = <Typography variant="h4">{eventInfo.subject}</Typography>;

  const displayNextDateTime = (
    <Typography style={{ fontSize: '0.75rem', fontWeight: 400 }}>
      {moment(eventInfo.nextStartDateTime).format('YYYY-MM-DD')}
    </Typography>
  );

  const isImportDisabled = outlookImportedEventsIds.includes(eventInfo.id);

  const importButton = (
    <Button variant="outlined" color="primary" onClick={importEvent} disabled={isImportDisabled}>
      Import
    </Button>
  );

  const displayDateAndStartEndTime = (
    <EventDateTimeInfoContainer
      isRecurring={isRecurring}
      nextStartDateTime={eventInfo.nextStartDateTime}
      startTime={startTime}
      endTime={endTime}
      endsAt={endsAt}
      isAllDay={isAllDay}
    />
  );

  const leftSection = (
    <div className={classes.groupContainer}>
      <Icon path={mdiCalendar} size="2rem" color={'rgba(0, 0, 0, 0.6)'} />
      <div>
        {displaySubject}
        {displayDateAndStartEndTime}
        {/* {displayNextDateTime} */}
        {/* {displayEndsAt} */}
      </div>
    </div>
  );

  const rightSection = <div>{importButton}</div>;

  return (
    <div className={classes.container}>
      {leftSection}
      {rightSection}
    </div>
  );
};
