import {
  generalAgentIcon,
  devilsAdvocateAgentIcon,
  lifeMateAgentIcon,
  learnMateAgentIcon,
  masteryMateAgentIcon,
  quizIcon,
  improveIcon,
  elaborateIcon,
  summariseIcon,
} from '../assets/icons';

export const teacherTypes = {
  GENERAL: 'General',
  CBSE: 'CBSE',
  ICSE: 'ICSE',
  COMPUTER: 'COMPUTER',
  CAT: 'CAT',
  JEE: 'JEE',
  GMAT: 'GMAT',
  GRE: 'GRE',
  TOEFL: 'TOEFL',
};

export const teacherTypeMap = {
  [teacherTypes.GENERAL]: 'General teacher',
  [teacherTypes.CBSE]: 'CBSE Teacher',
  [teacherTypes.ICSE]: 'ICSE Teacher',
  [teacherTypes.COMPUTER]: 'Computer Teacher',
  [teacherTypes.CAT]: 'CAT Teacher',
  [teacherTypes.JEE]: 'JEE Teacher',
  [teacherTypes.GMAT]: 'GMAT Teacher',
  [teacherTypes.GRE]: 'GRE Teacher',
  [teacherTypes.TOEFL]: 'TOEFL Teacher',
};

export const QUERY_ACTION_TYPES = {
  SUMMARIZE: { title: 'Summarize', iconSrc: summariseIcon },
  APPLY_MY_KNOWLEDGE: { title: 'Apply My Knowledge', iconSrc: improveIcon },
  EXPLAIN_MORE: { title: 'Explain More', iconSrc: elaborateIcon },
  QUIZ_ME: { title: 'Quiz Me', iconSrc: quizIcon },
};

export const PERSONA_TYPES = {
  GENERAL: 'GENERAL',
  DEVILS_ADVOCATE: 'DEVILS_ADVOCATE',
  LEARN_MATE: 'LEARN_MATE',
  LIFE_MATE: 'LIFE_MATE',
  MASTERY_MATE: 'MASTERY_MATE',
};

export const PERSONA_TYPE_MAP = {
  [PERSONA_TYPES.GENERAL]: 'General',
  [PERSONA_TYPES.DEVILS_ADVOCATE]: "Devil's Advocate",
  [PERSONA_TYPES.LEARN_MATE]: 'Learn Mate',
  [PERSONA_TYPES.LIFE_MATE]: 'Life Mate',
  [PERSONA_TYPES.MASTERY_MATE]: 'Mastery Mate',
};

export const PERSONA_DETAILS = {
  [PERSONA_TYPES.GENERAL]: {
    title: 'General Teacher',
    description: '',
    icon: generalAgentIcon,
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%), #C579C9',
  },
  [PERSONA_TYPES.DEVILS_ADVOCATE]: {
    title: "Devil's advocate",
    description: 'AI helps you think critically',
    icon: devilsAdvocateAgentIcon,
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #0094E1',
  },
  [PERSONA_TYPES.LIFE_MATE]: {
    title: 'LifeMate',
    description: 'AI acting as a Friendship Coach',
    icon: lifeMateAgentIcon,
    background: '#FFC900',
  },
  [PERSONA_TYPES.LEARN_MATE]: {
    title: 'LearnMate',
    description: 'AI acting as your student',
    icon: learnMateAgentIcon,
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #FF518F',
  },
  [PERSONA_TYPES.MASTERY_MATE]: {
    title: 'MasteryMate',
    description: 'AI acting as a quiz master',
    icon: masteryMateAgentIcon,
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #258620',
  },
};

export const LANGUAGES = {
  af: 'Afrikaans',
  sq: 'Albanian',
  am: 'Amharic',
  ar: 'Arabic',
  hy: 'Armenian',
  as: 'Assamese',
  az: 'Azerbaijani',
  ba: 'Bashkir',
  eu: 'Basque',
  be: 'Belarusian',
  bn: 'Bengali',
  bs: 'Bosnian',
  br: 'Breton',
  bg: 'Bulgarian',
  ca: 'Catalan',
  zh: 'Chinese',
  hr: 'Croatian',
  cs: 'Czech',
  da: 'Danish',
  nl: 'Dutch',
  en: 'English',
  et: 'Estonian',
  fo: 'Faroese',
  fi: 'Finnish',
  fr: 'French',
  gl: 'Galician',
  ka: 'Georgian',
  de: 'German',
  el: 'Greek',
  gu: 'Gujarati',
  ht: 'haitian creole',
  ha: 'Hausa',
  haw: 'Hawaiian',
  he: 'Hebrew',
  hi: 'Hindi',
  hu: 'Hungarian',
  is: 'Icelandic',
  id: 'Indonesian',
  it: 'Italian',
  ja: 'Japanese',
  jw: 'Javanese',
  kn: 'Kannada',
  kk: 'Kazakh',
  km: 'Khmer',
  ko: 'Korean',
  lo: 'Lao',
  la: 'Latin',
  lv: 'Latvian',
  ln: 'Lingala',
  lt: 'Lithuanian',
  lb: 'Luxembourgish',
  mk: 'Macedonian',
  mg: 'Malagasy',
  ms: 'Malay',
  ml: 'Malayalam',
  mt: 'Maltese',
  mi: 'Maori',
  mr: 'Marathi',
  mn: 'Mongolian',
  my: 'Myanmar',
  ne: 'Nepali',
  no: 'Norwegian',
  nn: 'Nynorsk',
  oc: 'Occitan',
  ps: 'Pashto',
  fa: 'Persian',
  pl: 'Polish',
  pt: 'Portuguese',
  pa: 'Punjabi',
  ro: 'Romanian',
  ru: 'Russian',
  sa: 'Sanskrit',
  sr: 'Serbian',
  sn: 'Shona',
  sd: 'Sindhi',
  si: 'Sinhala',
  sk: 'Slovak',
  sl: 'Slovenian',
  so: 'Somali',
  es: 'Spanish',
  su: 'Sundanese',
  sw: 'Swahili',
  sv: 'Swedish',
  tl: 'Tagalog',
  tg: 'Tajik',
  ta: 'Tamil',
  tt: 'Tatar',
  te: 'Telugu',
  th: 'Thai',
  bo: 'Tibetan',
  tr: 'Turkish',
  tk: 'Turkmen',
  uk: 'Ukrainian',
  ur: 'Urdu',
  uz: 'Uzbek',
  vi: 'Vietnamese',
  cy: 'Welsh',
  yi: 'Yiddish',
  yo: 'Yoruba',
};

export const CHAT_TYPES = {
  USER: 'user',
  PDF: 'PDF',
  YOUTUBE: 'YOUTUBE_TRANSCRIPT',
};
