import React from 'react';
import { useDialogDispatcher } from '../../../../store/dispatcher/useDialogDispatcher';
import { useDialogSelector } from '../../../../store/selector/useDialogSelector';
import { JoinRequestSentDialog } from '../../../invites/join-request-sent-dialog/container';
import { DialogGroupContainer } from '../../../../Kneura-Web-Whiteboard/IWB/Layout/dialog-group/container';
import LicenseLimitExhaustedDialogContainer from '../../../license-upgrade/dialog/license-limit-exhausted-dialog/container';
import FeatureLockedDialogContainer from '../../../license-upgrade/dialog/FeatureLockedDialog/FeatureLockedDialogContainer';
import MaxSimultaneousUsageLimitExhaustedDialogContainer from '../../../license-upgrade/dialog/max-simultaneous-exhausted-dialog/container';
import DeviceLicenseExhaustedDialogContainer from '../../../license-upgrade/dialog/license-expired/container';
import HuddleSessionLimitExhaustedDialogContainer from '../../../license-upgrade/dialog/HuddleSessionLimitReachedDialog/container';

export const GlobalDialogContainer = (props) => {
  const { isOpenJoinRequestSentDialog } = useDialogSelector();
  const { dispatchSetIsOpenJoinRequestSentDialog } = useDialogDispatcher();

  const onClickJoinRequestSent = () => {
    dispatchSetIsOpenJoinRequestSentDialog(false);
  };

  return (
    <>
      <JoinRequestSentDialog
        open={isOpenJoinRequestSentDialog}
        onClickNext={onClickJoinRequestSent}
      />
      <DialogGroupContainer />
      <LicenseLimitExhaustedDialogContainer />
      <FeatureLockedDialogContainer />
      <MaxSimultaneousUsageLimitExhaustedDialogContainer />
      <DeviceLicenseExhaustedDialogContainer />
      <HuddleSessionLimitExhaustedDialogContainer />
    </>
  );
};
