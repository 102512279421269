import React from 'react';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';

import appleIcon from '../../../../assets/icons/apple.svg';
import microsoftIcon from '../../../../assets/icons/microsoft.svg';
import windowsLogo from '../../../../assets/icons/windows.svg';
import birdIcon from '../../../../assets/icons/bird.svg';
import { StyledDialogContainer } from '../../../../components/common/dialog/styled-dialog/container';

const useDescriptionStyles = makeStyles({
  root: {
    flex: '1',
    '& .i-description-title': { fontSize: 14 },
    '& .i-description-content': { fontSize: 14 },
    '& ul': {
      listStyleType: 'disc',
      padding: '0px 24px',
      marginTop: 0,
      '& li': { padding: 'unset' },
    },
  },
});

const Description = ({ items, heading }) => {
  const classes = useDescriptionStyles();
  return (
    <div className={classes.root}>
      <Typography className="i-description-title">{heading}</Typography>
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            <Typography className="i-description-content">{item}</Typography>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Bold = ({ children }) => <span style={{ fontWeight: 'bold' }}>{children}</span>;

const items = [
  {
    icon: appleIcon,
    title: 'AirPlay',
    description: (
      <Description
        heading="For macOS and iOS users,"
        items={[
          'Connect your device to the same network',
          <>
            Use the <Bold>{'Screen Mirroring'}</Bold> option
          </>,
          <>
            Select <Bold>{`<Room ID>`}</Bold> to cast your device
          </>,
        ]}
      />
    ),
  },
  {
    icon: microsoftIcon,
    title: 'Miracast',
    description: (
      <Description
        heading="For Windows users,"
        items={[
          'Connect your device to the same network',
          <>
            Press
            <Bold>
              {` Windows `}
              <img src={windowsLogo} alt="windows" style={{ width: 16, height: 16 }} />
              {` + k `}
            </Bold>
            on your keyboard or use the "Connect to a Wireless Display" option
          </>,
          <>
            Select <Bold>{`<Room ID>`}</Bold> to cast your device
          </>,
        ]}
      />
    ),
  },
  {
    icon: birdIcon,
    title: 'BirdCast',
    description: (
      <Description
        heading="For Android, macOS & Windows users,"
        items={[
          <>
            Download the <Bold>BirdCast</Bold> application
          </>,
          'Instantly cast your device using the application',
        ]}
      />
    ),
    action: (
      <Button
        variant="contained"
        href="https://www.birdai.com/downloads/"
        target="_blank"
        style={{ borderRadius: 4, textTransform: 'none', fontWeight: '600' }}
        color="primary"
      >
        Get BirdCast App
      </Button>
    ),
  },
];

const Item = ({ icon, title, description, action }) => (
  <Box
    style={{
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #0000001A',
      borderRadius: 16,
      padding: 24,
      width: '270px',
      height: '300px',
    }}
  >
    <Box style={{ display: 'flex', gap: 12, marginBottom: 12 }}>
      <img src={icon} alt={title} />
      <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>{title}</Typography>
    </Box>
    {description}
    {action}
  </Box>
);

const LocalScreenShareDialog = ({ open, onClose }) => {
  return (
    <StyledDialogContainer
      open={open}
      onClose={onClose}
      title="Local Screen Share"
      hideFooter
      fullWidth={false}
      maxWidth={false}
    >
      <Box style={{ display: 'flex', gap: 12, flexWrap: 'wrap', justifyContent: 'center' }}>
        {items.map(({ title, ...props }) => (
          <Item key={title} title={title} {...props} />
        ))}
      </Box>
    </StyledDialogContainer>
  );
};

export default LocalScreenShareDialog;
