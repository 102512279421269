import React from 'react';

const MyTeamIcon = (props) => {
  let fill = props.fill ? props.fill : '#000';
  let fillOpacity = props.fill ? 1 : 0.38;

  return (
    // <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path
    //     d="M13.7365 19.728C12.9554 18.9469 12.9554 17.6806 13.7365 16.8996L22.2218 8.41429C23.0028 7.63324 24.2691 7.63324 25.0502 8.41429L33.5355 16.8996C34.3165 17.6806 34.3165 18.9469 33.5355 19.728L25.0502 28.2133C24.2691 28.9943 23.0028 28.9943 22.2218 28.2133L13.7365 19.728Z"
    //     fill={fill}
    //   />
    //   <path
    //     d="M1 18.3222L10.908 8.4142C11.689 7.63316 12.9554 7.63316 13.7364 8.4142L22.2217 16.8995C23.0028 17.6805 23.0028 18.9469 22.2217 19.7279L13.7279 28.2217C12.9469 29.0027 11.6805 29.0027 10.8995 28.2217L1 18.3222Z"
    //     fill={fill}
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M13.6434 16.9925C12.9132 17.7228 12.9132 18.9068 13.6434 19.6371L17.978 23.9716L22.3143 19.6353C23.0442 18.9055 23.0442 17.7221 22.3143 16.9922L17.979 12.6569L13.6434 16.9925Z"
    //     fill="black"
    //     fillOpacity="0.32"
    //   />
    // </svg>

    <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7365 13.1398C11.9554 12.3588 11.9554 11.0924 12.7365 10.3114L21.2218 1.82609C22.0028 1.04504 23.2691 1.04504 24.0502 1.82609L32.5355 10.3114C33.3165 11.0924 33.3165 12.3588 32.5355 13.1398L24.0502 21.6251C23.2691 22.4061 22.0028 22.4061 21.2218 21.6251L12.7365 13.1398Z"
        fill={fill}
      />
      <path
        d="M0 11.7344L9.908 1.82637C10.689 1.04533 11.9554 1.04533 12.7364 1.82637L21.2217 10.3117C22.0028 11.0927 22.0028 12.359 21.2217 13.1401L12.7279 21.6339C11.9469 22.4149 10.6805 22.4149 9.89949 21.6339L0 11.7344Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6434 10.4049C11.9131 11.1352 11.9131 12.3192 12.6434 13.0495L16.9779 17.384L21.3142 13.0478C22.0441 12.3179 22.0441 11.1345 21.3142 10.4046L16.9789 6.06934L12.6434 10.4049Z"
        fill="black"
        fill-opacity="0.32"
      />
    </svg>
  );
};

export default MyTeamIcon;
