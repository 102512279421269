import React from 'react';
import { BBBIframe } from './component';
import { useHuddleSelector } from '../../../../store/selector/useHuddleSelector';

export const BBBIframeContainer = (props) => {
  const { isFullScreen, onChangeFullScreen, isMinimalizedUI, isContentUIDisplayed } = props;
  let { bbbUrl } = useHuddleSelector();

  return (
    <BBBIframe
      bbbUrl={bbbUrl}
      isContentUIDisplayed={isContentUIDisplayed}
      isFullScreen={isFullScreen}
      isMinimalizedUI={isMinimalizedUI}
      onChangeFullScreen={onChangeFullScreen}
    />
  );
};
