import React from 'react';
import { Button, DialogActions } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyledFooterStyles } from './component.style';

export const StyledFooter = (props) => {
  const {
    cancelText,
    disabledSubmitButton,
    onClickCancel,
    onSubmit,
    showFooterCloseButton,
    submitTextContent,
    footerContent,
  } = props;
  const { t } = useTranslation();
  const classes = useStyledFooterStyles();

  const content = (
    <div className={classes.actions}>
      {onClickCancel && (
        <Button
          variant="outlined"
          style={{ border: '1px solid rgba(0, 0, 0, 0.38)', color: 'rgba(0, 0, 0, 0.38)' }}
          onClick={onClickCancel}
        >
          {t(cancelText)}
        </Button>
      )}
      {onSubmit && showFooterCloseButton && (
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          style={{ marginLeft: '0.5rem' }}
          disabled={disabledSubmitButton}
        >
          {t(submitTextContent)}
        </Button>
      )}
    </div>
  );

  return (
    <div className={classes.footer}>
      <div className={classes.container}>{footerContent ? footerContent : content}</div>
    </div>
  );
};

StyledFooter.defaultProps = {
  showFooterCloseButton: true,
};
