import { useDispatch } from 'react-redux';
import { setZoomIsAuthenticated, setZoomMe } from '../actions/zoom.action';

export const useZoomDispatcher = () => {
  const dispatch = useDispatch();

  /**
   * @param {boolean} payload
   */
  const dispatchSetZoomIsAuthenticated = (payload) => {
    dispatch(setZoomIsAuthenticated(payload));
  };

  /**
   * @param {boolean} payload
   */
  const dispatchSetZoomMe = (payload) => {
    dispatch(setZoomMe(payload));
  };

  return {
    dispatchSetZoomIsAuthenticated,
    dispatchSetZoomMe,
  };
};
