import { Button, Switch } from '@material-ui/core';
import React from 'react';
import { FEATURE_FLAGS } from '../../../../constants/feature-flags.constants';
import { ScreenShareToolIcon } from '../../../../assets/icons/ScreenShareToolIcon';
import { PRIMARY_LIGHT_COLOR } from '../../../../theme/base-theme';
import { useDisplayControlsStyles } from './component.style';
import { useSelector } from 'react-redux';
import { useNotificationDispatcher } from '../../../../store/dispatcher/useNotificationDispatcher';
import { getCanvasCode, getWorkspaceId } from '../../../../Utils/authentication-access';
import boardsService from '../../../../services/boards.service';

export const ScreenshareDisplayControls = (props) => {
  const {
    getUserMedia,
    handleSwitchChange,
    isMobile,
    isScreenShared,
    isViewOnly,
    publish,
    stopScreenshare,
    switchState,
    onClickMute,
    onClickUnmute,
    isMuted,
    checkIsAllowedToStream,
  } = props;
  const classes = useDisplayControlsStyles();
  const cloudLicenseInfo = useSelector((state) => state.licenseReducer.cloudLicenseDetails);
  const { dispatchShowFeatureLockedDialog } = useNotificationDispatcher();

  const screenshare = async () => {
    console.log(cloudLicenseInfo, 'cloudLicenseInfo');
    if (
      cloudLicenseInfo &&
      cloudLicenseInfo.spacesScreenshare &&
      !cloudLicenseInfo.spacesScreenshare.enabled
    ) {
      dispatchShowFeatureLockedDialog();
      return;
    }

    try {
      let workspaceId = getWorkspaceId();
      if (!workspaceId) {
        /**
         * TODO: optimize this extra API call
         */
        workspaceId = await boardsService.getWorkspaceId(getCanvasCode());
      }
      await checkIsAllowedToStream({ workspaceId });
      await getUserMedia({ source: 'screenshare' });
      publish({ source: 'screenshare', useExistingStream: true, forceH264: true });
    } catch (error) {
      console.log('error', error);
    }
  };

  const screenshareButton = (
    <Button
      variant="outlined"
      onClick={screenshare}
      style={{
        width: isMobile ? '64px' : '85px',
        padding: isMobile ? 0 : undefined,
        border: 'none',
        lineHeight: '0.75',
      }}
      size={isMobile ? 'small' : 'medium'}
    >
      <div>
        <ScreenShareToolIcon />
        <div className={classes.buttonText}>Screenshare</div>
      </div>
    </Button>
  );

  const stopScreenshareButton = (
    <Button
      variant="outlined"
      onClick={stopScreenshare}
      style={{
        width: 'auto',
        padding: isMobile ? 0 : undefined,
        border: 'none',
        lineHeight: '0.75',
        backgroundColor: PRIMARY_LIGHT_COLOR,
      }}
      size={isMobile ? 'small' : 'medium'}
    >
      <div>
        <ScreenShareToolIcon />
        <div className={classes.buttonText}>Stop Screenshare</div>
      </div>
    </Button>
  );

  const huddleButton = (
    <Switch
      checked={switchState.checkedA}
      onChange={handleSwitchChange}
      name="checkedA"
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    />
  );

  const displayHuddleBUtton = FEATURE_FLAGS.audioCallInASpace.isEnabled && huddleButton;

  const MuteButton = (
    <Button variant="outlined" color="primary" onClick={onClickMute}>
      Mute
    </Button>
  );

  const UnMuteButton = (
    <Button variant="outlined" color="primary" onClick={onClickUnmute}>
      Unmute
    </Button>
  );

  const micButton = isMuted ? UnMuteButton : MuteButton;

  const displayControls = (
    <div>
      {!isScreenShared ? screenshareButton : stopScreenshareButton}
      {displayHuddleBUtton}
      {switchState.checkedA && micButton}
    </div>
  );

  if (isViewOnly) {
    return <></>;
  }

  return <>{displayControls}</>;
};
