


import ACTION_TYPES from '../actions/type.action';

const initialState = {
    activeSpace: '',
};

const activeSpaceReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case ACTION_TYPES.ACTIVE_SPACE_INFO:
            return {
                ...state,
                activeSpace: payload,
            };
        default:
            return state;
    }
};

export default activeSpaceReducer;
