import React from 'react';
import useInvitesDispatcher from '../../../store/dispatcher/useInvitesDispatcher';
import { SidebarWorkspaceGroupComponent } from './component';

export const SidebarWorkspaceGroupContainer = (props) => {
  const { popupState, ...rest } = props;
  const { dispatchShowJoinWorkspaceInviteDialog } = useInvitesDispatcher();

  const onClickInviteToWorkspace = () => {
    /** open invite workspace dialog */
    dispatchShowJoinWorkspaceInviteDialog();

    /** close dialog */
    popupState.setOpen(false);
  };
  const inviteToWorkspaceLabel = 'Invite to workspace';

  return (
    <SidebarWorkspaceGroupComponent
      inviteToWorkspaceLabel={inviteToWorkspaceLabel}
      onClickInviteToWorkspace={onClickInviteToWorkspace}
      popupState={popupState}
      {...rest}
    />
  );
};
