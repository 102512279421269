import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { mdiContentCopy } from '@mdi/js';
import Icon from '@mdi/react';

import AgentAvatar from '../agent-avatar';
import { PERSONA_DETAILS, PERSONA_TYPES } from '../../../../constants/smart-notes.constants';
import StyledButton from './styled-button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'start',
    gap: 12,
    padding: '20px 40px',
    backgroundColor: '#FFEBE266',
    border: '1px solid #FF5C0B33',
    borderWidth: '1px 0px',
    '& .i-avatar': {
      height: 36,
      width: 36,
    },
    '& .i-content': {
      gridArea: 'content',
      fontSize: 14,
      whiteSpace: 'pre-line',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  copyContentButton: {
    backgroundColor: '#EEE',
    color: '#767676',
    fontSize: '0.625rem',
    width: 'fit-content',
    padding: '0.25rem 0.5rem 0.25rem 0.75rem',
  },
}));

const AIChat = ({ persona = PERSONA_TYPES.GENERAL, content, isLoading }) => {
  const containerRef = useRef();
  const classes = useStyles();

  const scrollIntoView = useCallback((ref) => {
    if (ref) {
      ref.scrollIntoView();
      containerRef.current = ref;
    }
  }, []);

  useEffect(() => {
    containerRef.current.scrollIntoView(false);
  }, [content]);

  const onClickCopyContent = () => {
    navigator.clipboard.writeText(content);
  };

  return (
    <>
      <div className={classes.root} ref={scrollIntoView}>
        <AgentAvatar {...PERSONA_DETAILS[persona]} />
        <div className={classes.content}>
          <Typography className="i-content" component="pre">
            {content}
          </Typography>
          {!isLoading && (
            <StyledButton
              defaultBorder
              className={classes.copyContentButton}
              startIcon={<Icon path={mdiContentCopy} size={0.5} />}
              onClick={onClickCopyContent}
            >
              Copy
            </StyledButton>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(AIChat);
