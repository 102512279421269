import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, makeStyles, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import classes from './searchbox.css';
import Searchicon from '../../../assets/spaces/Searchicon.svg';

// import '../../../../assets/css/color.css';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: 0,
    '& .MuiInputBase-root': {
      paddingLeft: '10px',
      height: 52,
      borderRadius: 25,
      background: 'var(--White)',
      '@media (min-width: 801px)': {
        width: '268px',
      },
    },
  },
}));

const SearchBox = (props) => {
  const data = props.allAssessment;
  const [searchText, setSearchText] = useState('');
  const [searchFieldBtn, setSearchFieldBtn] = useState(false);
  const [updatedSuggestions, setUpdatedSuggestions] = useState([]);
  const styledClasses = useStyles();

  const clearSearchFieldHandler = () => {
    setSearchText('');
  };

  useEffect(() => {
    clearSearchFieldHandler();
  }, [props.clearSearchField]);

  return (
    <>
      <TextField
        classes={{ root: styledClasses.textField }}
        id="usernamesearch"
        variant="outlined"
        placeholder={'Search here...'}
        className={classes.SearchField}
        value={searchText}
        onChange={(event) => {
          setSearchText(event.target.value);
          props.onEnterSearchText(event.target.value);
        }}
        onClick={() => {
          setSearchFieldBtn(true);
        }}
        InputProps={{
          autoComplete: 'off',
          endAdornment: (
            <InputAdornment position="end">
              {searchFieldBtn && searchText ? (
                <IconButton
                  size="small"
                // onClick={() => {
                //   setSearchText("");
                //   props.onEnterSearchText("");
                //   setSearchFieldBtn(false);
                // }}
                >
                  <CloseIcon
                    onClick={() => {
                      setSearchText('');
                      props.onEnterSearchText('');
                      setSearchFieldBtn(true);
                    }}
                  />
                </IconButton>
              ) : (
                <img src={Searchicon} style={{ paddingRight: '15px' }} alt="Create Assessment" />
              )}
            </InputAdornment>
          ),
          style: {},
        }}
      />
    </>
  );
};

export default SearchBox;
