import React from 'react';
import {
  Card,
  ClickAwayListener,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import DisplaySelectedFile from '../../authenticationPages/DisplaySelectedFile';
import { getBoardInfo } from '../../../Utils/authentication-access';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '0.5rem',
    position: 'absolute',
    top: '-70px',
    left: '40px',
    width: 'max-content',
    marginBottom: '15px',
    padding: '26px 20px',
    paddingBottom: '12px',
    width: 'max-content',
  },
  nameText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#000000',
    float: 'left',
  },
  emailText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '12px',
    color: 'rgba(0, 0, 0, 0.4)',
  },
}));

const ProfileMenu = (props) => {
  const classes = useStyles();

  const ListItemLink = (props) => {
    return <ListItem button component="a" {...props} />;
  };

  const { open, onClose, menuItems } = props;

  if (!open) {
    return <></>;
  }

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Card className={classes.container}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <div>
            <DisplaySelectedFile name="institute profile image" src={props.picture} size={24} />
          </div>
          <div>
            <div className={classes.nameText}> {props.userName}</div>
            <div className={classes.emailText}>{props.userEmail}</div>
          </div>
        </div>
        <List>
          {menuItems.map((menuItem) => (
            <ListItem
              button
              key={menuItem.title}
              onClick={menuItem.onClick}
              style={{ padding: '0' }}
            >
              <ListItemIcon style={{ minWidth: '32px' }}>{menuItem.icon}</ListItemIcon>
              <ListItemText primary={menuItem.title} />
            </ListItem>
          ))}
        </List>
      </Card>
    </ClickAwayListener>
  );
};

export default ProfileMenu;
