import React from 'react';
import { Button, DialogActions, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    paddingRight: '1rem',
    paddingLeft: '1.5rem',
    paddingBottom: '0.5rem',
  },
  actions: {},
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
}));

const StyledFooter = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { onClose, onCloseFooter, showFooterCloseButton, closeText, onSubmit, submitText, disabledSubmitButton } = props;
  const cancelText = closeText ? closeText : 'Cancel';
  const submitTextContet = submitText ? submitText : 'Save';
  const onClickCancel = onCloseFooter ? onCloseFooter : onClose;

  return (
    <div className={classes.footer}>
      <DialogActions>
        <div className={classes.container}>
          {props.children ? props.children : <div />}
          <div className={classes.actions}>
            {onClose && (
              <Button variant="outlined" color="primary" onClick={onClickCancel}>
                {t(cancelText)}
              </Button>
            )}
            {onSubmit && showFooterCloseButton && (
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmit}
                style={{ marginLeft: '0.5rem' }}
                disabled={disabledSubmitButton}
              >
                {t(submitTextContet)}
              </Button>
            )}
          </div>
        </div>
      </DialogActions>
    </div>
  );
};

StyledFooter.defaultProps = {
  showFooterCloseButton: true
}

export default StyledFooter;
