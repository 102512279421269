import config from '../config';

/**
 * use this when feature is enabled only on development environments
 * @returns
 */
const isEnabledOnDevelopment = () => {
  return config.ENVIRONMENT === 'development' || config.ENVIRONMENT === 'development.local';
};

/**
 * use this when feature is enabled for all environments
 * @returns
 */
const isEnabled = () => {
  return true;
};

const isEnabledOnlyOnLocal = () => {
  return config.ENVIRONMENT === 'development.local';
};

export const FEATURE_LIST = {
  audioCallInASpace: 'audioCallInASpace',
  googleOAuthForAuthentication: 'googleOAuthForAuthentication',
  msTeams: 'msTeams',
  oAuthForAuthentication: 'oAuthForAuthentication',
  outlookCalendar: 'outlookCalendar',
  remoteCall: 'remoteCall',
  screenshareInASpace: 'screenshareInASpace',
  zoomCall: 'zoomCall',
};

export const FEATURE_FLAGS = {
  [FEATURE_LIST.outlookCalendar]: {
    isEnabled: isEnabled(),
  },
  [FEATURE_LIST.zoomCall]: {
    isEnabled: isEnabledOnDevelopment(),
  },
  [FEATURE_LIST.remoteCall]: {
    isEnabled: isEnabled(),
  },
  [FEATURE_LIST.msTeams]: {
    isEnabled: isEnabledOnlyOnLocal(),
  },
  [FEATURE_LIST.googleOAuthForAuthentication]: {
    isEnabled: isEnabledOnDevelopment(),
  },
  [FEATURE_LIST.oAuthForAuthentication]: {
    isEnabled: isEnabledOnDevelopment(),
  },
  [FEATURE_LIST.screenshareInASpace]: {
    isEnabled: isEnabled(),
  },
  [FEATURE_LIST.audioCallInASpace]: {
    isEnabled: isEnabledOnlyOnLocal(),
  },
};
