import React from 'react';

const Airdock = (props) => {
  const fill = props.fill ? props.fill : '#000';
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.768311" y="0.952148" width="12.4633" height="12.0954" rx="4" fill="#335AFB" />
      <path
        d="M7.05794 12.8959C9.8235 12.8959 11.2063 12.8959 12.0654 12.0367C12.9246 11.1776 12.9246 9.79479 12.9246 7.02923V6.14924M7.93794 1.1626H7.05794C4.29238 1.1626 2.9096 1.1626 2.05046 2.02175C1.4792 2.593 1.28778 3.39576 1.22363 4.68258"
        stroke="black"
        stroke-linecap="round"
      />
      <circle cx="11.1646" cy="2.92259" r="1.75999" fill="white" stroke="black" />
      <circle cx="3.24238" cy="11.136" r="1.75999" fill="white" stroke="black" />
    </svg>
  );
};

export default Airdock;
