import { Button, Tooltip } from '@material-ui/core';
import React from 'react';
import { useVideoCallLinksButtonStyles } from './component.style';
import { VideoCallLinksDialogContainer } from './video-call-links-dialog/container';
import VideoCallSidebarContainer from './video-call-sidebar/container';

export const VideoCallLinks = (props) => {
  const classes = useVideoCallLinksButtonStyles();
  const {
    icon,
    label,
    color,
    backgroundColor,
    onClick,
    isVideoCallDialogOpen,
    closeDialog,
    isMobile,
    isSmallMobile,
  } = props;

  const dialog = isVideoCallDialogOpen ? (
    <VideoCallLinksDialogContainer closeDialog={closeDialog} isOpen={isVideoCallDialogOpen} />
  ) : (
    <></>
  );

  return (
    <div className={classes.container}>
      <Tooltip title="Meetings">
        <Button
          className={classes.button}
          variant="contained"
          style={{
            backgroundColor,
            color,
            width: isSmallMobile ? '35px' : '85px',
            padding: isMobile ? 0 : undefined,
            border: 'none',
            minWidth: isSmallMobile ? '35px' : 'auto',
          }}
          onClick={onClick}
          size={isMobile ? 'small' : 'medium'}
        >
          <div
            style={{
              lineHeight: '6px',
            }}
          >
            {icon}
            {!isSmallMobile && (
              <div
                style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '18px',
                  color: '#000000',
                  textTransform: 'none',
                  border: 'none',
                }}
              >
                {label}
              </div>
            )}
          </div>
        </Button>
      </Tooltip>
      <VideoCallSidebarContainer closeSidebar={closeDialog} isSidebarOpen={isVideoCallDialogOpen} />
    </div>
  );
};
