import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMouseSend } from '../../hooks/mouseSend';
import { MouseCursor } from '../mouse-cursor/mouse-cursor';
import { useMouseUsers } from '../../hooks/mouseUsers';

export const MouseCursorContainer = () => {
  const usersMouseData = useMouseUsers();
  const currentPageId = useSelector((state) => state.canvasReducer.currentPageId);

  useMouseSend();

  return (
    <>
      {usersMouseData
        .filter(({ pageId }) => pageId === currentPageId)
        .map((userMouseData) => (
          <MouseCursor userMouseData={userMouseData} key={userMouseData.userID} />
        ))}
    </>
  );
};
