/**
 * # Summary
 * ### Action class
 * - to store action
 * 
 * ### ELement map
 * - BTree collection of all elements
 * 
 * ### makeUndo function
 * - returns undo action for every actions
 * 
 * ### abstract functions
 * - abstract function build on makeUndo fuctions
 * - handles elementMap
 * - returns undo, redo actions
 * 
 * ### Socket Wrapper/ undoRedoSocketWrapper
 * - update undoredo stack, and undoredo ptr
 * - class backs the passed sendSocketMessageFunction with redo as message
 */

import { cloneDeep } from "lodash"

/**
 * Strict class to store operable actions
 */
class Action {
    /**
     * @type {('page-add' | 'page-delete' | 'item-changed' | 'manipulation' | 'remove' | 'bg-changed' | 'prop-changed' | 'polyline')}
     */
    type
    sockPayload
    /**
     * @param {('page-add' | 'page-delete' | 'item-changed' | 'manipulation' | 'remove' | 'bg-changed' | 'prop-changed' | 'polyline' | 'image' | 'video' | 'text')} type 
     * @param {*} sockPayload 
     */
    constructor(type, sockPayload) {
        this.type = type
        this.sockPayload = cloneDeep(sockPayload)
    }
}

/**
 * @type {{undo:Action, redo:Action}[]}
 */
var actionStack = []
var actionPtr = 0

const clearActionStack = () => {
    actionStack = []
    actionPtr = 0
}

/**
 * @param {Action} undoAction
 * @param {Action} redoAction
 */
const addAction = (undoAction, redoAction) => {
    // snip off the actions after current postion in actionStack
    if (actionPtr < actionStack.length)
        actionStack = actionStack.slice(0, actionPtr)

    // update action stack and actionPtr
    actionStack.push({ undo: undoAction, redo: redoAction })
    actionPtr = actionStack.length
    // console.table([...elementMap.values()])
    // console.log(actionStack);
}

const getUndo = () => {
    if (actionPtr > 0) {
        const { undo } = cloneDeep(actionStack[--actionPtr])
        return undo
    }
    return null
}
const getRedo = () => {
    if (actionPtr < actionStack.length) {
        const { redo } = cloneDeep(actionStack[actionPtr++])
        return redo
    }
    return null
}

/**
 * ### Collections of all items in canvas class
 * @type {Map<string, { 
 *  type:('page-add' | 'page-delete' | 'item-changed' | 'manipulation' | 'remove' | 'bg-changed' | 'prop-changed' | 'polyline'), 
 *  elementId: string,
 *  elementBounds: { x: number, y: number },
 * }>}
 */
export const elementMap = new Map()

/**
 * @param {[]} items 
 */
const setElementMap = (items) => {
    items.forEach(item => {
        elementMap.set(
            item.elementId,
            cloneDeep({
                ...elementMap.get(item.elementId),
                ...item
            })
        )
    })
}

/**
 * @param {{
 *  type: 'manipulation',
 *  elementIds: string[],
 *  endBounds: { x: number, y: number }[],
 *  endScales: { x: number, y: number }[],
 *  angles: number[]
 * }} message
 */
const parseItemsToUpdate = (message) => {
    var itemsToUpdate = []
    for (const i in message.elementIds) {
        const elementId = message.elementIds[i]
        const element = elementMap.get(elementId);
        let endBounds = message.endBounds[i];
        /**
         * when socket message of type manipulation is received
         * if padding of equal to elementSize is required to avoid stroke shifting issues in polyline.
         * which is due to the value equal to element which is substracted on drawing the polyline.
         */
        if (element.elementType === 'polyline') {
            const padding = element.elementSize
            endBounds = {
                x: message.endBounds[i].x + padding,
                y: message.endBounds[i].y + padding
            }
        } 
        const elementBounds = endBounds;
        const elementScale = message.endScales[i]
        const elementAngle = message.angles[i]
        itemsToUpdate.push({
            elementId,
            elementBounds: cloneDeep({
                ...(elementMap.get(elementId).elementBounds),
                ...elementBounds
            }),
            elementScale,
            elementAngle
        })
    }
    return itemsToUpdate
}

/**
 * @param {{
 *  type: 'manipulation',
 *  elementIds: string[],
 *  endBounds: { x: number, y: number }[],
 *  endScales: { x: number, y: number }[],
 *  angles: number[]
 * }} message
 */
const manipulateElementMap = (message) => {
    const itemsToUpdate = parseItemsToUpdate(message)
    setElementMap(cloneDeep(itemsToUpdate))
    // console.table("itemsToUpdate", itemsToUpdate)
    // console.table([...elementMap.values()].map(e => e.elementBounds))
}

/**
 * @description **`type`** = `page-add`
 * @param {{
 *  pageId: string
 * }} actionPayload
 */
const makeUndoPageAdd = (actionPayload) => new Action(
    'page-delete',
    {
        type: 'page-delete',
        pageId: actionPayload.pageId
    }
)

/**
 * @param {{
 *  classId: string
 *  pageId: string
 * }} actionPayload
 */
const makeUndoPageRemove = (actionPayload) => new Action(
    'page-add',
    {
        type: 'page-add',
        clasId: actionPayload.classId,
        pageId: actionPayload.pageId
    }
)

/**
 * @description **`type`** = `polyline`
 * @param {{
 *  pageId: string
 *  classId: string
 *  elementId: string
 *  stroke: string
 * }} actionPayload
 */
const makeUndoPolyline = (actionPayload) => new Action(
    'remove',
    {
        type: 'remove',
        pageId: actionPayload.pageId,
        classId: actionPayload.classId,
        elementId: actionPayload.elementId,
        flowId: actionPayload.flowId,
    }
)

/**
 * @description **`type`** = `remove` where elementId points to a `polyline` item
 * @param {{
 *  elementId: string
 *  elementType: string
 * }} actionPayload
 */
const makeUndoRemove = (actionPayload) => {
    const element = cloneDeep(elementMap.get(actionPayload.elementId))
    return new Action(
        element.elementType,
        {
            ...element,
            type: element.elementType,
        }
    )
}

function abstractItemDelete(message) {
    const undo = makeUndoRemove(cloneDeep(message))
    const redo = new Action('remove', message)
    return { undo, redo }
}

/**
 * @param {{ elementId: string }} actionPayload
 */
function abstractAddPolyLine(actionPayload) {
    const undo = makeUndoPolyline(actionPayload)
    const redo = new Action('polyline', actionPayload)
    elementMap.set(actionPayload.elementId, actionPayload)
    return { undo, redo }
}

/**
 * @param {{ elementId: string }} actionPayload
 */
function abstractStrokeErase(actionPayload) {
    const element = cloneDeep(elementMap.get(actionPayload.elementId))
    const undo = (element.type === 'polyline')
        ? makeUndoRemove(actionPayload)
        : null
    const redo = new Action('remove', actionPayload)
    elementMap.delete(actionPayload.elementId)
    return { undo, redo }
}

/**
 * @description **`type`** = `item-changed`
 * @param {{
 *  type: 'item-changed'
 *  elementId: string,
 * }} actionPayload
 */
const makeUndoItemChanged = (actionPayload) => {
    const currentItem = cloneDeep(elementMap.get(actionPayload.elementId))
    var framedPayload = {}
    Object.keys(actionPayload).forEach(key => {
        framedPayload[key] = currentItem[key]
    })
    return new Action(
        'item-changed',
        {
            framedPayload,
            type: 'item-changed'
        }
    )
}

/**
 * @description **`type`** = `manipulation`
 * @param {{
 *  type: 'manipulation',
 *  classId: string,
 *  flowId: string,
 *  elementIds: string[],
 *  endBounds: { x: number, y: number }[],
 *  endScales: { x: number, y: number }[],
 *  angles: number[]
 * }} message
 */
const makeUndoManipulation = (message) => {
    const elementIds = message.elementIds
    var endBounds = []
    var endScales = []
    var angles = []
    // Iterate over all elementIds
    elementIds.forEach((elementId) => {
        // Element data of each elementId
        const {
            elementBounds, elementScale, elementAngle
        } = cloneDeep(elementMap.get(elementId))
        // push element data to endBounds, endScales and angles collection
        endBounds.push(elementBounds)
        endScales.push(elementScale)
        angles.push(elementAngle)
    })
    return {
        ...message,
        endBounds,
        endScales,
        angles
    }
}

/**
 * @description **`type`** = `manipulation`
 */
function abstractManipulation(message) {
    const undo = new Action('manipulation', makeUndoManipulation(message))
    manipulateElementMap(message)
    const redo = new Action('manipulation', message)
    return { undo, redo }
}

/**
 * @param {string} elementId 
 * @param {string} s3Url remote file public URL
 */
function abstractAddImage(elementId, s3Url) {
    setElementMap([{
        elementId, s3Url
    }])
    const redo = new Action('image', cloneDeep(elementMap.get(elementId)))
    // `makeUndoPolyline` can be used, as all we need is classId, flowId and elementId
    const undo = makeUndoPolyline(cloneDeep(redo.sockPayload))
    return { undo, redo }
}

function abstractAddDocument(message) {
    setElementMap([message])
    const redo = new Action('document', cloneDeep(elementMap.get(message.elementId)))
    const undo = makeUndoPolyline(cloneDeep(redo.sockPayload))
    return { undo, redo }
}

function abstractAddText(message) {
    setElementMap([message])
    const redo = new Action('text', cloneDeep(elementMap.get(message.elementId)))
    const undo = makeUndoPolyline(cloneDeep(redo.sockPayload))
    return { undo, redo }
}

function abstractAddVideo(message) {
    setElementMap([message])
    const redo = new Action('video', cloneDeep(elementMap.get(message.elementId)))
    const undo = makeUndoPolyline(cloneDeep(redo.sockPayload))
    return { undo, redo }
}

function abstractAddYoutube(message) {
    setElementMap([message])
    const redo = new Action('youtube', cloneDeep(elementMap.get(message.elementId)))
    const undo = makeUndoPolyline(cloneDeep(redo.sockPayload))
    return { undo, redo }
}

/**
 * @param {(message: any) => void} sendSocketMessageFunction
 * @param {Action} redoAction use the current playload for socket
 * @param {Action} undoAction pass the current playload via makeUndo functions
 */
const undoRedoSocketWrapper = (
    sendSocketMessageFunction,
    redoAction,
    undoAction
) => {
    sendSocketMessageFunction(redoAction.sockPayload)
    addAction(undoAction, redoAction)
}


export {
    setElementMap, manipulateElementMap, addAction,
    makeUndoPageAdd, makeUndoPageRemove,
    abstractAddPolyLine, abstractStrokeErase,
    makeUndoItemChanged, abstractManipulation, abstractItemDelete,
    abstractAddImage, abstractAddDocument, abstractAddVideo,
    abstractAddText, abstractAddYoutube,
    undoRedoSocketWrapper,
    getUndo, getRedo, clearActionStack
}

// tests...... tests...... tests...... tests...... tests...... tests......
// uncomment the lines below and execute
// node src/Web-Ai-Canvas/utils/redoUndo.js

// const { deepStrictEqual, strictEqual } = require("assert")

// const itemSamplePolyline1 = JSON.parse("{\"elementId\":\"1a032ce3-1a85-40a2-8a55-31b3d695e18b\",\"elementBounds\":{\"x\":107.50007629394531,\"y\":163.3333396911621,\"width\":29.166641235351562,\"height\":80.00001907348633},\"elementScale\":{\"x\":1,\"y\":1},\"elementType\":\"polyline\",\"type\":\"polyline\",\"pageId\":\"CNNTV2\",\"elementAngle\":0,\"elementColor\":\"#3075D2\",\"elementSize\":4,\"fill\":{\"filled\":false,\"color\":\"\",\"opacity\":1},\"isShape\":false,\"stroke\":\"24.166641235351562 0 11.666641235351562 24.16666030883789 3.3333206176757812 47.50001907348633 0 66.66666030883789 5 80.00001907348633 15 78.33333969116211 29.166641235351562 67.50001907348633\",\"elementOpacity\":1,\"creatorId\":\"user1@mail.com\",\"creatorName\":\"\",\"timestamp\":1614926917768,\"socketId\":\"\",\"classId\":\"EB8H17\",\"flowId\":\"7553fef2-6848-42ca-8b42-34706949f68a\",\"zIndex\":1,\"isLocked\":false,\"groupId\":\"\",\"groupingEnabled\":false}")
// const itemSamplePolyline2 = JSON.parse("{\"elementId\":\"5342d91e-aa72-498f-89a0-8732f751f7b4\",\"elementBounds\":{\"x\":190.0000762939453,\"y\":195,\"width\":16.666641235351562,\"height\":88.33335876464844},\"elementScale\":{\"x\":1,\"y\":1},\"elementType\":\"polyline\",\"type\":\"polyline\",\"pageId\":\"CNNTV2\",\"elementAngle\":0,\"elementColor\":\"#3075D2\",\"elementSize\":4,\"fill\":{\"filled\":false,\"color\":\"\",\"opacity\":1},\"isShape\":false,\"stroke\":\"12.5 0 10.833320617675781 10.833358764648438 5 39.16667938232422 0 65.83335876464844 1.6666412353515625 82.5 11.666641235351562 88.33335876464844 16.666641235351562 87.5\",\"elementOpacity\":1,\"creatorId\":\"user1@mail.com\",\"creatorName\":\"\",\"timestamp\":1614926917768,\"socketId\":\"\",\"classId\":\"EB8H17\",\"flowId\":\"7553fef2-6848-42ca-8b42-34706949f68a\",\"zIndex\":1,\"isLocked\":false,\"groupId\":\"\",\"groupingEnabled\":false}")
// const itemSamplePolyline3 = JSON.parse("{\"elementId\":\"5342d91e-aa72-498f-89a0-8732f751f7b5\",\"elementBounds\":{\"x\":190.0000762939453,\"y\":195,\"width\":16.666641235351562,\"height\":88.33335876464844},\"elementScale\":{\"x\":1,\"y\":1},\"elementType\":\"polyline\",\"type\":\"polyline\",\"pageId\":\"CNNTV2\",\"elementAngle\":0,\"elementColor\":\"#3075D2\",\"elementSize\":4,\"fill\":{\"filled\":false,\"color\":\"\",\"opacity\":1},\"isShape\":false,\"stroke\":\"12.5 0 10.833320617675781 10.833358764648438 5 39.16667938232422 0 65.83335876464844 1.6666412353515625 82.5 11.666641235351562 88.33335876464844 16.666641235351562 87.5\",\"elementOpacity\":1,\"creatorId\":\"user1@mail.com\",\"creatorName\":\"\",\"timestamp\":1614926917768,\"socketId\":\"\",\"classId\":\"EB8H17\",\"flowId\":\"7553fef2-6848-42ca-8b42-34706949f68a\",\"zIndex\":1,\"isLocked\":false,\"groupId\":\"\",\"groupingEnabled\":false}")

// function test1() {
//     console.log("Test1 begin ....");

//     console.log("Check undoRedoSocketWrapper()")

//     deepStrictEqual(actionStack, [])
//     strictEqual(actionPtr, 0)

//     // event for creating a polyline
//     undoRedoSocketWrapper(
//         () => { },
//         itemSamplePolyline1,
//         makeUndoPolyline(itemSamplePolyline1)
//     )

//     deepStrictEqual(
//         actionStack[0], {
//         undo: new Action('remove', {
//             type: 'remove',
//             pageId: itemSamplePolyline1.pageId,
//             classId: itemSamplePolyline1.classId,
//             elementId: itemSamplePolyline1.elementId,
//         }),
//         redo: itemSamplePolyline1
//     }
//     )
//     strictEqual(actionPtr, 1)

//     // event for creating another polyline
//     undoRedoSocketWrapper(
//         () => { },
//         itemSamplePolyline2,
//         makeUndoPolyline(itemSamplePolyline2)
//     )
//     deepStrictEqual(
//         actionStack[1], {
//         undo: new Action('remove', {
//             type: 'remove',
//             pageId: itemSamplePolyline2.pageId,
//             classId: itemSamplePolyline2.classId,
//             elementId: itemSamplePolyline2.elementId,
//         }),
//         redo: itemSamplePolyline2
//     }
//     )
//     strictEqual(actionPtr, 2)

//     console.log("Test1 complete ....");
// }
// function test2() {
//     console.log("Test2 begin ....")

//     console.log("Testing abstract functions")

//     {
//         const expectedUndo = makeUndoPolyline(itemSamplePolyline1)
//         const { undo, redo } = abstractAddPolyLine(itemSamplePolyline1)
//         deepStrictEqual(
//             elementMap.get(itemSamplePolyline1.elementId),
//             itemSamplePolyline1
//         )
//         deepStrictEqual(redo.sockPayload, itemSamplePolyline1)
//         deepStrictEqual(undo, expectedUndo)
//     }
//     {
//         const expectedUndo = makeUndoPolyline(itemSamplePolyline2)
//         const { undo, redo } = abstractAddPolyLine(itemSamplePolyline2)

//         deepStrictEqual(
//             elementMap.get(itemSamplePolyline2.elementId),
//             itemSamplePolyline2
//         )
//         deepStrictEqual(redo.sockPayload, itemSamplePolyline2)
//         deepStrictEqual(undo, expectedUndo)
//     }
//     {
//         const expectedUndo = makeUndoRemovePolyLine(itemSamplePolyline1)
//         const { undo, redo } = abstractStrokeErase(itemSamplePolyline1)
//         strictEqual(
//             elementMap.get(itemSamplePolyline1.elementId),
//             undefined
//         )
//         deepStrictEqual(redo.sockPayload, itemSamplePolyline1)
//         deepStrictEqual(undo, expectedUndo)
//     }

//     console.log("Test2 complete ....")
// }
// function test3() {
//     console.log("Test3 begin ....")

//     console.log("Testing abstract functions, with sock wrapper")

//     const actionPtrLock = actionPtr
//     const actionStackLock = [...actionStack]

//     {
//         const { undo, redo } = abstractAddPolyLine(itemSamplePolyline1)
//         undoRedoSocketWrapper(
//             (message) => {
//                 deepStrictEqual(message, redo.sockPayload)
//             }, redo, undo
//         )
//         strictEqual(actionPtr, actionPtrLock + 1)
//         strictEqual(actionStack.length, actionStackLock.length + 1)
//         deepStrictEqual(
//             actionStack[actionStackLock.length - 1],
//             actionStackLock[actionStackLock.length - 1]
//         )
//         deepStrictEqual(
//             actionStack[actionStack.length - 1],
//             { redo, undo }
//         )
//         deepStrictEqual(
//             elementMap.get(itemSamplePolyline1.elementId),
//             redo.sockPayload
//         )
//     }
//     {
//         const { undo, redo } = abstractAddPolyLine(itemSamplePolyline3)
//         undoRedoSocketWrapper(
//             (message) => {
//                 deepStrictEqual(message, redo.sockPayload)
//             }, redo, undo
//         )
//         strictEqual(actionPtr, actionPtrLock + 2)
//         strictEqual(actionStack.length, actionStackLock.length + 2)
//         deepStrictEqual(
//             actionStack[actionStack.length - 1],
//             { redo, undo }
//         )
//         deepStrictEqual(
//             elementMap.get(itemSamplePolyline3.elementId),
//             redo.sockPayload
//         )
//     }


//     console.log("Test3 complete ....")
// }

// test1()
// test2()
// test3()