import React from 'react';
import classes from './dateAndTimeContainer.module.css';
import moment from 'moment';

const DateAndTimeContainer = () => {
  return (
    <div className={classes.dateTimeFrame}>
      <h2>{moment().format('hh:mm a')}</h2>
      <h4>{moment().format('dddd, DD MMMM YYYY')}</h4>
    </div>
  );
};

export default DateAndTimeContainer;
