import { isValidHttpUrl } from '../../../Utils/url';

const validateScheduleManuallyForm = (values) => {
  const errors = {};
  if (values.title === '' || values.title.trim().length === 0) {
    errors.title = 'Please enter Title';
  } else if (values.link === '') {
    errors.link = 'Please enter link';
  } else if (!isValidHttpUrl(values.link)) {
    errors.link = 'Please enter valid URL';
  }
  return errors;
};

export default validateScheduleManuallyForm;
