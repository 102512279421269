export const EVENTS = {
  UNPAIR_CLASS: 'UNPAIR_CLASS',
  BOARD_CHANGED: 'BOARD_CHANGED',
  WORK_SPACE_CHANGED: 'WORK_SPACE_CHANGED',
  SCREENSHARE_STARTED: 'screenshare-started',
  SCREENSHARE_ENDED: 'screenshare-ended',
  IMAGES_TO_CANVAS: 'IMAGES_TO_CANVAS',
  DISCONNECT_PAIRED_DEVICE: 'DISCONNECT_PAIRED_DEVICE',
  SCREEN_SHARE_STOPPED_FROM_DEVICE: 'SCREEN_SHARE_STOPPED_FROM_DEVICE',
  LOGOUT: 'LOGOUT',
  TYPE_START_PRESENTATION: 'start-presentation',
  TYPE_STOP_PRESENTATION: 'end-presentation',
  PAIR_CLASS: 'PAIR_CLASS',
  HUDDLE_STARTED: 'HUDDLE_STARTED',
  HUDDLE_ENDED: 'HUDDLE_ENDED',
  LEFT_HUDDLE: 'LEFT_HUDDLE',
  JOIN_ZOOM_MEETING: 'JOIN_ZOOM_MEETING',
  GET_SCHEDULES: 'GET_SCHEDULES',
  ADDED_TO_SPACE: 'ADDED_TO_SPACE',
  REMOVED_FROM_SPACE: 'REMOVED_FROM_SPACE',
  JOIN_SPACE_REQUEST_ACCEPTED: 'JOIN_SPACE_REQUEST_ACCEPTED',
  JOIN_WORKSPACE_REQUEST_ACCEPTED: 'JOIN_WORKSPACE_REQUEST_ACCEPTED',
  SET_PAGE_ZOOM: 'set-page-zoom',
  PAIR_SUCCESSFUL: 'PAIR_SUCCESSFUL',
  UNPAIR_DEVICE: 'UNPAIR_DEVICE',
  REMOVED_FROM_WORKSPACE: 'REMOVED_FROM_WORKSPACE',
  SCREENSHARE_STOPPED: 'SCREENSHARE_STOPPED',

  SET_NOTIFICATION_TOKEN: 'SET_NOTIFICATION_TOKEN',
  LICENSE_LIMIT_EXHAUSTED: 'LICENSE_LIMIT_EXHAUSTED',
  FEATURE_NOT_ACTIVE_TRIAL_NOT_USED: 'FEATURE_NOT_ACTIVE_TRIAL_NOT_USED',
  FEATURE_NOT_ACTIVE_TRIAL_ALREADY_USED: 'FEATURE_NOT_ACTIVE_TRIAL_ALREADY_USED',
  TRIAL_ACTIVATED: 'TRIAL_ACTIVATED',

  SIMULTANEOUS_LIMIT_EXHAUSTED: 'SIMULTANEOUS_LIMIT_EXHAUSTED',
  FEATURE_LIMIT_EXHAUSTED: 'FEATURE_LIMIT_EXHAUSTED',
  FEATURE_LOCKED: 'FEATURE_LOCKED',
  SESSION_LIMIT_EXHAUSTED: 'SESSION_LIMIT_EXHAUSTED',

  OPEN_REMOTE_CONTROLLER: 'OPEN_REMOTE_CONTROLLER',
};
