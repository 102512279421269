import { makeStyles } from '@material-ui/core';

export const useSidebarItemStyles = makeStyles(() => ({
  workspaceOptions: {
    cursor: 'pointer',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.15968px',
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'block',
  },
}));
