import React, { createContext, useCallback, useContext, useState } from 'react';

export const defaultHeaderTitle = 'Learn with Bright AI';

const initialState = {
  layoutDetails: { sidebarChildren: null, headerTitle: 'Bright AI', sidebarCollapsed: false },
  setLayoutDetails: ({ sidebarChildren, headerTitle, sidebarCollapsed }) => {},
};

const layoutContext = createContext(initialState);

const LayoutContext = ({ children }) => {
  const [layoutDetails, setLayoutDetails] = useState(initialState.layoutDetails);

  const setDetails = useCallback((details) => {
    setLayoutDetails((prevState) => ({ ...prevState, ...details }));
  }, []);

  return (
    <layoutContext.Provider value={{ layoutDetails, setLayoutDetails: setDetails }}>
      {children}
    </layoutContext.Provider>
  );
};

export const useLayoutDetails = () => {
  const { layoutDetails, setLayoutDetails } = useContext(layoutContext);
  return { layoutDetails, setLayoutDetails };
};

export default LayoutContext;
