let screenLock;

function isScreenLockSupported() {
  return 'wakeLock' in navigator;
}

export const checkIsWakeLocked = () => {
  return screenLock;
};

export const getScreenLock = async () => {
  if (isScreenLockSupported()) {
    let lock;

    try {
      lock = await navigator.wakeLock.request('screen');
    } catch (err) {
      console.log(err.name, err.message);
    }
    return lock;
  }
};

export const releaseWakeLock = () => {
  if (typeof screenLock !== 'undefined' && screenLock != null) {
    screenLock.release().then(() => {
      console.log('Lock released 🎈');
      screenLock = null;
    });
  }
};

export const initWakeLock = async () => {
  console.log('Wake Lock initialized 🎈');
  screenLock = await getScreenLock();
  document.addEventListener('visibilitychange', async () => {
    if (screenLock !== null && document.visibilityState === 'visible') {
      screenLock = await getScreenLock();
    }
  });
};
