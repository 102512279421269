import React from 'react';
import { DisplayViewOptions } from './component';

export const DisplayViewOptionsContainer = (props) => {
  const {
    isFullScreen,
    onChangeFullScreen,
    showDraggableOption,
    showFullScreenOption,
    showHideOrShowContentUIButtonOption,
    isContentUIDisplayed,
    setIsContentUIDisplayed,
  } = props;

  const displayContentUI = () => {
    setIsContentUIDisplayed(true);
  };

  const hideContentUI = () => {
    setIsContentUIDisplayed(false);
  };

  return (
    <DisplayViewOptions
      isFullScreen={isFullScreen}
      showFullScreen={onChangeFullScreen}
      showDraggableOption={showDraggableOption}
      showFullScreenOption={showFullScreenOption}
      showHideOrShowContentUIButtonOption={showHideOrShowContentUIButtonOption}
      isContentUIDisplayed={isContentUIDisplayed}
      setIsContentUIDisplayed={setIsContentUIDisplayed}
      displayContentUI={displayContentUI}
      hideContentUI={hideContentUI}
    />
  );
};
