import React from 'react';
import { Box } from '@material-ui/core';

import { StyledDialogContainer } from '../../../../components/common/dialog/styled-dialog/container';
import localShareIcon from '../../../../assets/img/local-screen-share.svg';
import onlineShareIcon from '../../../../assets/img/online-screen-share.svg';
import ScreenShareMethod from './screen-share-method';

const ImageIcon = ({ src, alt }) => <img src={src} alt={alt} />;
const LocalIcon = <ImageIcon src={localShareIcon} alt="local-screen-share" />;
const OnlineIcon = <ImageIcon src={onlineShareIcon} alt="online-screen-share" />;

const ScreenShareMethodsDialog = ({ open, onClose, onLocalShareClick, onOnlineShareClick }) => {
  return (
    <StyledDialogContainer
      open={open}
      onClose={onClose}
      title="Choose the method to mirror your screen"
      hideFooter
      fullWidth={false}
      maxWidth={false}
    >
      <Box p={2} style={{ display: 'flex', flexWrap: 'wrap', gap: 36 }}>
        <ScreenShareMethod
          icon={LocalIcon}
          title="Local screen share"
          description="Seamlessly share screen locally on same network."
          onClick={onLocalShareClick}
        />
        <ScreenShareMethod
          icon={OnlineIcon}
          title="Online screen share"
          description="Effortless online screen sharing for content sharing."
          onClick={onOnlineShareClick}
        />
      </Box>
    </StyledDialogContainer>
  );
};

export default ScreenShareMethodsDialog;
