import microsoftGraphService from '../../services/microsoft-graph.service';
import { useMicrosoftDispatcher } from '../../store/dispatcher/useMicrosoftDispatcher';

export const useMicrosoftTeams = () => {
  const { dispatchSetMicrosoftCommunicationIdentity, dispatchSetMicrosoftTeamsMeeting } =
    useMicrosoftDispatcher();

  /**
   *
   * @param {{ meetingUrl }} data
   */
  const startTeamsMeeting = async (data) => {
    const { meetingUrl } = data;
    try {
      const communicationIdentity = await microsoftGraphService.getCommunicationIdentity();

      /** set the teams meeting url */
      dispatchSetMicrosoftTeamsMeeting({ url: meetingUrl });

      /** set the communication identity */
      dispatchSetMicrosoftCommunicationIdentity(communicationIdentity);
    } catch (err) {
      console.log(err.response.data.message || err.message);
    }
  };

  return {
    startTeamsMeeting,
  };
};
