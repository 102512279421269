import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { useLaunchMeetingButtonStyles } from './component.style';
import { useMeetingOnPairedDevice } from './useMeetingOnPairedDevice';
import HostMeetingDialog from '../../dialogs/HostMeetingDialog';
import { useDialogDispatcher } from '../../../../store/dispatcher/useDialogDispatcher';
import { sendRemoteStartMessageToDevice } from '../../../../Utils/sockets/auth-user-launcher';

export const LaunchMeetingButtonContainer = (props) => {
  const { eventInfo, isDeviceConnected, openCalendarDetailsDialog } = props;

  const { launchMeetingHandler } = useMeetingOnPairedDevice();
  const classes = useLaunchMeetingButtonStyles();
  const [isHostMeetingDialogOpen, setIsHostMeetingDialogOpen] = useState(false);
  const { dispatchSetIsOpenRemoteControlDialog } = useDialogDispatcher();

  const onMeetingStartedOnDevice = () => {
    setIsHostMeetingDialogOpen(true);
  };

  const closeHostDialogHandler = () => {
    setIsHostMeetingDialogOpen(false);
  };

  const launchMeetingHere = () => {
    window.open(eventInfo.onlineMeeting?.joinUrl, '_blank');
    closeHostDialogHandler();
  };

  const handleOpenRemoteControlDialog = () => {
    closeHostDialogHandler();
    sendRemoteStartMessageToDevice();
  };

  const launchMeetingButton = (
    <Button
      color="primary"
      variant="contained"
      disabled={!eventInfo.onlineMeeting?.joinUrl && props.isOpen}
      className={classes.launchMeetingButton}
      onClick={
        eventInfo.onlineMeeting?.joinUrl
          ? () => {
              launchMeetingHandler({
                subject: eventInfo.subject,
                link: eventInfo.onlineMeeting?.joinUrl,
                isDeviceConnected,
                onMeetingStartedOnDevice,
              });
            }
          : openCalendarDetailsDialog
      }
    >
      Launch meeting
    </Button>
  );

  return (
    <>
      {launchMeetingButton}
      <HostMeetingDialog
        open={isHostMeetingDialogOpen}
        onClose={closeHostDialogHandler}
        // link={eventInfo.onlineMeeting?.joinUrl}
        // launchMeetingHere={launchMeetingHere}
        handleOpenRemoteControlDialog={handleOpenRemoteControlDialog}
      />
    </>
  );
};
