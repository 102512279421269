import { Typography } from '@material-ui/core';
import React from 'react';
import { ContentContainer, DisplayCloseIcon } from './component.style';

export const Heading = (props) => {
  const { title, onClose } = props;
  return (
    <ContentContainer>
      <Typography variant="h2">{title}</Typography>
      <DisplayCloseIcon onClick={onClose} />
    </ContentContainer>
  );
};
