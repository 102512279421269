import React from 'react';
import { useEventTimeInfoStyles } from './component.style';

export const EventTimeInfo = (props) => {
  const classes = useEventTimeInfoStyles();
  const { startDate, endDate, timeReminder } = props;

  const periodText = `${startDate} - ${endDate}`;
  const data = [periodText, timeReminder];

  return <div className={classes.container}>{data}</div>;
};
