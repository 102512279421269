/**
 * try refetching the chunks when failed to be loaded for a no. of retries.
 *
 * @param {*} importFn
 * @param {*} retries
 * @param {*} interval
 * @returns
 */
// importRetry.js
const importRetry = async (importFn, retries = 2, interval = 1000) => {
  try {
    return await importFn();
  } catch (error) {
    console.log('IMPORT_FAILED', error);
    if (retries) {
      await wait(interval);
      return importRetry(importFn, retries - 1, interval);
    } else {
      throw new Error(error);
    }
  }
};
const wait = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export default importRetry;
