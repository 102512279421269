import { makeStyles } from '@material-ui/core';

export const useLaunchMeetingButtonStyles = makeStyles((theme) => ({
  launchMeetingButton: {
    textTransform: 'none',
    height: '28px',
    cursor: 'pointer',
    width: '118px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#FFFFFF',
    background: 'transparent',
    border: '1px solid #fff',
    whiteSpace: 'nowrap',
    '&:hover': {
      background: 'rgba(255,255,255,0.1)',
    },
  },
}));
