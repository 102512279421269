import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';

import { PERSONA_DETAILS } from '../../../constants/self-learn.constants';
import AgentCard from './agent-card';

const useStyles = ({ mini }) =>
  makeStyles((theme) => ({
    agentListRoot: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gap: mini ? 4 : 12,
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 1fr',
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
      },
    },
  }));

const { GENERAL, ...noGeneral } = PERSONA_DETAILS;

export const AgentList = ({ onClick, mini, agent }) => {
  const agents = useMemo(() => (mini ? PERSONA_DETAILS : noGeneral), [mini]);
  const classes = useStyles({ mini })();

  return (
    <div className={classes.agentListRoot}>
      {Object.entries(agents).map(([name, agentDetails]) => (
        <AgentCard
          key={name}
          name={name}
          mini={mini}
          onClick={onClick}
          {...agentDetails}
          isActive={agent === name}
        />
      ))}
    </div>
  );
};

export default AgentList;
