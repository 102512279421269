import React, { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import Source from './source';
import SourceInput from './source-input';
import { toggleAllNoteSources } from '../../../../store/actions/smart-notes.action';
import { SOURCE_STATUS } from '../../../../constants/smart-notes.constants';
import { ROUTES } from '../../../../constants/routes';

export const BlueCheckbox = withStyles({
  root: {
    color: '#335AFB',
    '&$checked': {
      color: '#335AFB',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  sourceListRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100dvh - 92px)',
    [theme.breakpoints.up('md')]: {
      width: 'calc(50% - 8px)',
      height: '100%',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    paddingBottom: 12,
    '& .i-back-icon': {
      borderRadius: 12,
      border: '1px solid #000',
    },
    '& .i-title': {
      flex: 1,
      fontSize: 12,
      fontWeight: 700,
    },
  },
  sourceList: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    overflow: 'auto',
  },
  sourceListLoader: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const SourceList = ({ title = 'Notebook', items, onUpload, onSend, loading }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { noteId } = useParams();
  const classes = useStyles();
  const notes = useSelector((state) => state.smartNotes);

  const inputSendHandler = useCallback(({ query }) => onSend(query), [onSend]);

  const toggleAllHandler = useCallback(
    (e, selected) => {
      dispatch(toggleAllNoteSources({ noteId, selected }));
    },
    [dispatch, noteId],
  );

  const readySources = useMemo(
    () =>
      notes
        .find((note) => note.id === noteId)
        ?.sources.filter((source) => source.status === SOURCE_STATUS.COMPLETE),
    [noteId, notes],
  );

  const allSourcesSelected = useMemo(
    () => readySources?.length && readySources.every(({ selected }) => selected),
    [readySources],
  );

  const uploadHandler = useCallback(
    (e) => {
      onUpload(e.target.files[0]);
    },
    [onUpload],
  );

  const backHandler = useCallback(() => {
    history.replace(ROUTES.SMART_NOTES_NOTEBOOKS);
  }, [history]);

  return (
    <Box className={classes.sourceListRoot}>
      <Box className={classes.header}>
        <IconButton size="small" onClick={backHandler}>
          <ArrowBack className="i-back-icon" />
        </IconButton>
        <Typography className="i-title">{title}</Typography>
        <FormControlLabel
          control={
            <BlueCheckbox
              checked={allSourcesSelected}
              onChange={toggleAllHandler}
              disabled={!readySources?.length}
            />
          }
          label="Select All"
          labelPlacement="start"
        />
      </Box>
      {loading ? (
        <Box className={classes.sourceListLoader}>
          <CircularProgress />
        </Box>
      ) : (
        <Box className={classes.sourceList}>
          {items.map(({ id, ...sourceDetails }) => (
            <Source key={id} noteId={noteId} id={id} {...sourceDetails} />
          ))}
        </Box>
      )}
      <SourceInput uploadHandler={uploadHandler} loading={loading} onSend={inputSendHandler} />
    </Box>
  );
};

export default SourceList;
