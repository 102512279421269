import React from 'react'
import SecureityIcon from '../../../../assets/icons/SecureityIcon'
import './CustomToast.css';

const CustomToast = () => {
  return (
    <div className='toastMain'>
      <div> <SecureityIcon /> </div>
      <div className='toastTextContainer'>
        <div className='toastTitle'>
          Your Space is secure
        </div>
        <div className='toastPara'>
          We take your data privacy seriously, which is why we've used SSL 256bit encryption to
          keep your information safe.
        </div>
      </div>
    </div>
  )
}

export default CustomToast