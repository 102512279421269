import React from 'react';

const HomeOutlinedIcon = (props) => {
  let stroke = props.stroke ? props.stroke : '#000';
  let fillOpacity = props.stroke ? 1 : 0.38;

  return (
    // <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path
    //     d="M12.7365 13.1398C11.9554 12.3588 11.9554 11.0924 12.7365 10.3114L21.2218 1.82609C22.0028 1.04504 23.2691 1.04504 24.0502 1.82609L32.5355 10.3114C33.3165 11.0924 33.3165 12.3588 32.5355 13.1398L24.0502 21.6251C23.2691 22.4061 22.0028 22.4061 21.2218 21.6251L12.7365 13.1398Z"
    //     fill={fill}
    //   />
    //   <path
    //     d="M0 11.7344L9.908 1.82637C10.689 1.04533 11.9554 1.04533 12.7364 1.82637L21.2217 10.3117C22.0028 11.0927 22.0028 12.359 21.2217 13.1401L12.7279 21.6339C11.9469 22.4149 10.6805 22.4149 9.89949 21.6339L0 11.7344Z"
    //     fill={fill}
    //   />
    //   <path
    //     fill-rule="evenodd"
    //     clip-rule="evenodd"
    //     d="M12.6434 10.4049C11.9131 11.1352 11.9131 12.3192 12.6434 13.0495L16.9779 17.384L21.3142 13.0478C22.0441 12.3179 22.0441 11.1345 21.3142 10.4046L16.9789 6.06934L12.6434 10.4049Z"
    //     fill="black"
    //     fill-opacity="0.32"
    //   />
    // </svg>

    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.375 19.4922C7.375 16.7746 7.375 15.4159 7.99155 14.2895C8.6081 13.1631 9.7345 12.464 11.9873 11.0659L14.3623 9.59191C16.7436 8.11397 17.9343 7.375 19.25 7.375C20.5657 7.375 21.7564 8.11397 24.1377 9.59191L26.5127 11.0659C28.7655 12.464 29.8919 13.1631 30.5084 14.2895C31.125 15.4159 31.125 16.7746 31.125 19.4922V21.2984C31.125 25.9307 31.125 28.2468 29.7338 29.6859C28.3425 31.125 26.1033 31.125 21.625 31.125H16.875C12.3967 31.125 10.1575 31.125 8.76624 29.6859C7.375 28.2468 7.375 25.9307 7.375 21.2984V19.4922Z"
        stroke={stroke}
      />
      <path d="M22.8125 26.375H15.6875" stroke={stroke} stroke-linecap="round" />
    </svg>
  );
};

export default HomeOutlinedIcon;
