import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { StyledDialogContainer } from '../../../common/dialog/styled-dialog/container';
import { useSetupZoomDialogStyles } from './component.style';

export const SetupZoomDialog = (props) => {
  const {
    isOpenDialog,
    closeDialog,
    title,
    accountEmail,
    isZoomAuthenticated,
    authorizeZoom,
    revokeAccess,
    replaceAccount,
  } = props;
  const classes = useSetupZoomDialogStyles();

  const authenticatedText = (
    <Typography>
      Connected to:
      <span style={{ fontWeight: 'bold' }}> {accountEmail}</span>
    </Typography>
  );
  const unauthenticatedText = <Typography>Not authenticated</Typography>;
  const displayText = isZoomAuthenticated ? authenticatedText : unauthenticatedText;

  const content = <>{displayText}</>;

  const authorizeButton = (
    <Button variant="contained" color="primary" onClick={authorizeZoom}>
      Authorize
    </Button>
  );

  const unauthorizedFooterContent = <div>{authorizeButton}</div>;

  const revokeAccessButton = (
    <Button variant="outlined" color="primary" onClick={revokeAccess}>
      REVOKE ACCESS
    </Button>
  );
  const replaceAccountButton = (
    <Button variant="outlined" color="primary" onClick={replaceAccount}>
      REPLACE ACCOUNT
    </Button>
  );

  const authorizedFooterContent = (
    <div className={classes.unauthorizedFooterContent}>
      {revokeAccessButton}
      {replaceAccountButton}
    </div>
  );

  const footerContent = (
    <div className={classes.footerContent}>
      {isZoomAuthenticated ? authorizedFooterContent : unauthorizedFooterContent}
    </div>
  );

  return (
    <StyledDialogContainer
      open={isOpenDialog}
      onClose={closeDialog}
      title={title}
      footerContent={footerContent}
    >
      {content}
    </StyledDialogContainer>
  );
};
