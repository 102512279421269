import React, { useEffect, useState } from 'react';
import { spacesService } from '../../../services/spaces.service';
import { useNotificationDispatcher } from '../../../store/dispatcher/useNotificationDispatcher';
import {
  getBirdDeviceId,
  getCanvasCode,
  getDeviceIds,
  setDeviceIds,
} from '../../../Utils/authentication-access';
import { DeviceCodeDialog } from './component';
import { APP } from '../../../constants/app.constants';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomToast from './custom/CustomToast';
import './custom/CustomToast.css';

export const DeviceCodeDialogContainer = (props) => {
  const { isOpen, setIsPaired, onClose, hideDeviceCodeDialog, deviceCodeDialogPopupState } = props;
  const [deviceCode, setDeviceCode] = useState('');
  const { dispatchSetSnackbar } = useNotificationDispatcher();
  const title = `Connect to a Bird Device`;
  const knowMoreText = `Know more about casting your space`;
  const submitText = 'Connect';
  const activeDeviceId = useSelector((state) => state.deviceReducer.deviceId);
  const cloudLicenseInfo = useSelector((state) => state.licenseReducer.cloudLicenseDetails);
  const { dispatchShowFeatureLockedDialog } = useNotificationDispatcher();

  const pairHandler = async () => {
    if (cloudLicenseInfo.spaces.connectRoom) {
      const devicesIds = getDeviceIds();
      let indexValue;
      if (devicesIds !== null) {
        indexValue = devicesIds.findIndex((d) => d === deviceCode);
      }
      console.log('indexValue', indexValue);

      if (devicesIds == undefined || null) {
        setDeviceIds([deviceCode]);
      } else {
        indexValue = devicesIds.findIndex((d) => d === deviceCode);
        if (indexValue < 0) {
          let newDeviceIdList = [...devicesIds, deviceCode];
          setDeviceIds(newDeviceIdList);
        }
      }

      if (deviceCode) {
        try {
          const payload = {
            classId: getCanvasCode(),
            deviceCode,
          };
          await spacesService.pairClass(payload);
          hideDeviceCodeDialog();
          deviceCodeDialogPopupState.setOpen(false);
          showToastMessage();
          props.canvasBoard.handleZoomSync(true);
        } catch (error) {
          if (error && error.response && error.response.data && error.response.data.message) {
            dispatchSetSnackbar({ message: error.response.data.message });
          }
          console.error(`error: ${error.message}`);
        }
      } else {
        dispatchSetSnackbar({ message: 'deviceCode should not be empty' });
      }
    } else {
      dispatchShowFeatureLockedDialog();
    }
  };

  useEffect(() => {
    setDeviceCode(activeDeviceId);
    if (!activeDeviceId) {
      setDeviceCode(getBirdDeviceId());
    }
  }, [activeDeviceId]);

  const showToastMessage = () => {
    toast(<CustomToast />, {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
      className: 'toast-message',
      closeButton: false,
    });
  };

  return (
    <>
      <DeviceCodeDialog
        isOpen={isOpen}
        title={title}
        onClose={onClose}
        submitText={submitText}
        onSubmit={pairHandler}
        deviceCode={deviceCode}
        setDeviceCode={setDeviceCode}
        knowMoreText={knowMoreText}
        deviceCodeDialogPopupState={deviceCodeDialogPopupState}
      />

      <ToastContainer />
    </>
  );
};
