import { makeStyles, styled } from '@material-ui/core';
import { BBB_BLUE_BUTTON_COLOR } from '../../../../theme/base-theme';

export const useBBBViewerStyles = makeStyles(() => ({
  bbb: {
    zIndex: 99999999,
  },
  bbbContents: {
    width: '25rem',
    height: '20rem',
    backgroundColor: 'black',
    borderColor: 'black',
    borderRadius: '1rem',
    borderTopLeftRadius: 0,
    borderWidth: 0,
  },
  emptyContent: {
    backgroundColor: 'black',
  },
}));

export const CloseButtonContainer = styled('div')({
  position: 'fixed',
  right: '1.2rem',
  bottom: '0.75rem',
  cursor: 'pointer',
  width: '2.875rem',
  backgroundColor: BBB_BLUE_BUTTON_COLOR,
  height: '2.875rem',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
