import { useMutation } from '@tanstack/react-query';

import { baseService } from './base.service';
import { SMART_NOTES, getUserId } from '../Utils';

const uploadSource = async ({ file }) => {
  const userID = getUserId();
  const url = SMART_NOTES.upload(userID);
  const formData = new FormData();
  formData.append('uploaded_file', file);
  const response = await baseService.makePostRequest(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    customMessage: 'Failed to upload the file',
  });
  return response;
};

export const useUploadSource = (noteId, options) => {
  return useMutation({
    mutationFn: uploadSource,
    ...options,
  });
};

const getUploadStatus = async ({ id }) => {
  const url = SMART_NOTES.getUploadStatus(id);
  const response = await baseService.makeGetRequest(url, {
    customMessage: 'Failed to get the upload status',
  });
  return response;
};

export const useGetUploadStatus = (options) => {
  return useMutation({
    mutationFn: getUploadStatus,
    ...options,
  });
};

const ask = async ({ noteId, query, sourceIds }) => {
  const url = SMART_NOTES.chat({ noteId, query });
  const response = await baseService.makePostRequest(
    url,
    { sourceIds },
    {
      customMessage: 'Failed to query the ML',
    },
  );
  return response;
};

export const useAsk = (options) => {
  return useMutation({
    mutationFn: ask,
    ...options,
  });
};
