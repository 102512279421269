import React, { useState } from 'react';

import { DisplayVideo } from './component';

export const defaultDisplayWidth = 640;
export const defaultDisplayHeight = 400;

export const DisplayVideoContainer = React.forwardRef((props, ref) => {
  const { id, showRemoteVideo, isViewOnly, videoCount, stream } = props;
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const [isContentUIDisplayed, setIsContentUIDisplayed] = React.useState(true);
  const [videoAspectRatio, setVideoAspectRatio] = useState(1);
  const [displaySize, setDisplaySize] = useState({
    width: defaultDisplayWidth,
    height: defaultDisplayHeight,
  });
  const [backupDisplaySize, setBackupDisplaySize] = useState({
    width: defaultDisplayWidth,
    height: defaultDisplayHeight,
  });

  function handleMetadataLoaded() {
    const aspectRatio = ref.current.videoWidth / ref.current.videoHeight;
    setVideoAspectRatio(aspectRatio);

    const width = defaultDisplayWidth;
    setDisplaySize({ width, height: width / aspectRatio });
  }

  const onResizeStop = (e, direction, ref, delta, position) => {
    const newWidth = ref.style.width;
    const newHeight = ref.style.height;

    if (newWidth / newHeight !== videoAspectRatio) {
      if (direction.includes('left') || direction.includes('right')) {
        const width = parseInt(newWidth);
        const height = parseInt(newWidth) / videoAspectRatio;
        setDisplaySize({ width, height });
      } else {
        const width = parseInt(newHeight) * videoAspectRatio;
        const height = parseInt(newHeight);
        setDisplaySize({ width, height });
      }
    } else {
      const width = parseInt(newWidth);
      const height = parseInt(newHeight);
      setDisplaySize({ width, height });
    }
  };

  const showFullScreen = () => {
    setIsFullScreen(true);
  };

  const hideFullScreen = () => {
    setIsFullScreen(false);
  };

  const onChangeFullScreen = (_isFullScreen) => {
    console.log('onChangeFullScreen', _isFullScreen);
    if (_isFullScreen) {
      return showFullScreen();
    }
    return hideFullScreen();
  };

  const hideDisplay = () => {
    setBackupDisplaySize({ ...displaySize });
    setDisplaySize({ width: 0, height: 0 });
    setIsContentUIDisplayed(false);
  };

  const showDisplay = () => {
    setDisplaySize({ ...backupDisplaySize });
    setIsContentUIDisplayed(true);
  };

  const toggleDisplay = () => {
    if (isContentUIDisplayed) {
      return hideDisplay();
    }
    return showDisplay();
  };

  return (
    <DisplayVideo
      displaySize={displaySize}
      handleMetadataLoaded={handleMetadataLoaded}
      hideDisplay={hideDisplay}
      id={id}
      isContentUIDisplayed={isContentUIDisplayed}
      isFullScreen={isFullScreen}
      isViewOnly={isViewOnly}
      onChangeFullScreen={onChangeFullScreen}
      onResizeStop={onResizeStop}
      ref={ref}
      toggleDisplay={toggleDisplay}
      setIsFullScreen={setIsFullScreen}
      showDisplay={showDisplay}
      showRemoteVideo={showRemoteVideo}
      stream={stream}
      videoCount={videoCount}
    />
  );
});
