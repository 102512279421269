export const lastVisitedStates = {
  VIDEO_CALL_SIDEBAR_IS_OPEN: 'VIDEO_CALL_SIDEBAR_IS_OPEN',
  IMPORT_OUTLOOK_EVENTS_DIALOG_IS_OPEN: 'IMPORT_OUTLOOK_EVENTS_DIALOG_IS_OPEN',
  NAVIGATION_SIDEBAR_DIALOG_IS_OPEN: 'NAVIGATION_SIDEBAR_DIALOG_IS_OPEN',
  ZOOM_GOTO_MEETING_DIALOG_IS_OPEN: 'ZOOM_GOTO_MEETING_DIALOG_IS_OPEN',
  ZOOM_COMPONENT_VIEW_IS_OPEN: 'ZOOM_COMPONENT_VIEW_IS_OPEN',
  START_MICROSOFT_TEAMS_CALL: 'START_MICROSOFT_TEAMS_CALL',
  MICROSOFT_OAUTH_LOGIN: 'MICROSOFT_OAUTH_LOGIN',
  MICROSOFT_OAUTH_SIGNUP: 'MICROSOFT_OAUTH_SIGNUP',
  MICROSOFT_OAUTH_LINK_WITH_DEVICE: 'MICROSOFT_OAUTH_LINK_WITH_DEVICE',
  JOIN_SPACE_REQUEST_USING_MICROSOFT_OAUTH: 'JOIN_SPACE_REQUEST_USING_MICROSOFT_OAUTH',
  JOIN_WORKSPACE_REQUEST_USING_MICROSOFT_OAUTH: 'JOIN_WORKSPACE_REQUEST_USING_MICROSOFT_OAUTH',
  JOIN_REQUEST_SENT_DIALOG_IS_OPEN: 'JOIN_REQUEST_SENT_DIALOG_IS_OPEN',
};

export const importOutlookEventDialogStates = [
  { type: lastVisitedStates.IMPORT_OUTLOOK_EVENTS_DIALOG_IS_OPEN, isOpen: true },
  { type: lastVisitedStates.VIDEO_CALL_SIDEBAR_IS_OPEN, isOpen: true },
  { type: lastVisitedStates.NAVIGATION_SIDEBAR_DIALOG_IS_OPEN, isOpen: false },
];

export const personalCalendarStates = [
  { type: lastVisitedStates.VIDEO_CALL_SIDEBAR_IS_OPEN, isOpen: true },
  { type: lastVisitedStates.NAVIGATION_SIDEBAR_DIALOG_IS_OPEN, isOpen: false },
];

/**
 *
 * @param {{ spaceId: string }} data
 * @returns
 */
export const constructJoinSpaceRequestStates = (data) => {
  const { spaceId } = data;
  return [
    { type: lastVisitedStates.JOIN_SPACE_REQUEST_USING_MICROSOFT_OAUTH, spaceId },
    { type: lastVisitedStates.JOIN_REQUEST_SENT_DIALOG_IS_OPEN, isOpen: true },
  ];
};

/**
 *
 * @param {{ workspaceId: string }} data
 * @returns
 */
export const constructJoinWorkspaceRequestStates = (data) => {
  const { workspaceId } = data;
  return [
    {
      type: lastVisitedStates.JOIN_WORKSPACE_REQUEST_USING_MICROSOFT_OAUTH,
      workspaceId,
    },
    { type: lastVisitedStates.JOIN_REQUEST_SENT_DIALOG_IS_OPEN, isOpen: true },
  ];
};

export const setupZoomAccountDialogStates = [
  { type: lastVisitedStates.VIDEO_CALL_SIDEBAR_IS_OPEN, isOpen: true },
  { type: lastVisitedStates.NAVIGATION_SIDEBAR_DIALOG_IS_OPEN, isOpen: false },
];

export const microsoftOAuthLoginStates = [{ type: lastVisitedStates.MICROSOFT_OAUTH_LOGIN }];

export const microsoftOAuthSignupStates = [{ type: lastVisitedStates.MICROSOFT_OAUTH_SIGNUP }];

export const microsoftOAuthLinkWithDeviceStates = [
  { type: lastVisitedStates.MICROSOFT_OAUTH_LINK_WITH_DEVICE },
];

/**
 *
 * @param {{ meetingUrl }} data
 * @returns
 */
export const getZoomGoToMeetingDialogStates = (data) => {
  const { meetingUrl } = data;
  return [
    { type: lastVisitedStates.ZOOM_COMPONENT_VIEW_IS_OPEN, isOpen: true, meetingUrl },
    { type: lastVisitedStates.NAVIGATION_SIDEBAR_DIALOG_IS_OPEN, isOpen: false },
  ];
};

/**
 *
 * @param {{ meetingUrl: string }} data
 * @returns
 */
export const getSignInAndJoinMicrosoftTeamsStates = (data) => {
  const { meetingUrl } = data;
  return [{ type: lastVisitedStates.START_MICROSOFT_TEAMS_CALL, meetingUrl }];
};
