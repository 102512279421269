import { Typography } from '@material-ui/core';
import React from 'react';
import { CopyLinkContainer } from '../../../UIComponents/display/copy-link/container';
import { useShareLinkContentStyles } from './component.style';

export const ShareLinkContent = (props) => {
  const { headingText, link } = props;
  let { variant } = props;
  const classes = useShareLinkContentStyles();

  if (!variant) {
    variant = 'h4';
  }

  return (
    <div className={classes.container}>
      <Typography variant={variant}>{headingText}</Typography>
      <div>
        <CopyLinkContainer link={link} />
      </div>
    </div>
  );
};
