export const MESSAGES = {
  FILE: {
    PLEASE_SELECT_FILE: 'Please select file to upload',
  },
  EMAIL: {
    VERIFICATION: {
      SUCCESS: 'Email successfully verified',
      FAILED: 'Failed to verify email',
    },
  },
  RECORDS: {
    NOT_FOUND: 'Sorry, no matching records found',
  },
  INSTITUTE_INVITE: {
    INSTITUTE_INVITE_SENT: 'Institute joining request sent',
    ALREADY_PART_OF_INSTITUTE: 'You are already part of the institute',
  },
  CLASS_INVITE: {
    INVITE_SENT: 'Class joining request sent',
    ALREADY_ENROLLED: 'You are already part of the class',
  },
  CRUD: {
    CREATE: {
      SUCCESSFUL: 'Added successfully',
    },
    UPDATE: {
      SUCCESSFUL: 'Updated successfully',
    },
    DELETE: {
      SUCCESSFUL: 'Deleted succussfully',
    },
  },
};
