import React from 'react';
import { StyledDialogContainer } from '../../../common/dialog/styled-dialog/container';

const DeleteDialog = (props) => {
  const { openDialog, closeDeleteDialog, onSubmit, meetingName } = props;
  const title = 'Delete meeting?';
  const submitText = 'Delete';
  const confirmText = `Are you sure you want to delete ${meetingName}?`;
  const warningText = `(You can’t undo this action once deleted)`;

  return (
    <StyledDialogContainer
      open={openDialog}
      title={title}
      onClose={closeDeleteDialog}
      submitText={submitText}
      onCloseFooter={closeDeleteDialog}
      onSubmit={onSubmit}
    >
      <div>
        {confirmText} <br />
        <span style={{ color: '#B00020' }}>{warningText}</span>
      </div>
    </StyledDialogContainer>
  );
};

export default DeleteDialog;
