import { Typography } from '@material-ui/core';
import React from 'react';
import { useSidebarItemStyles } from './component.style';

export const SidebarItemComponent = (props) => {
  const { onClick } = props;
  const classes = useSidebarItemStyles();

  return (
    <Typography variant="div" className={classes.workspaceOptions} onClick={onClick}>
      {props.children}
    </Typography>
  );
};
