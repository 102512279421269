import { makeStyles } from '@material-ui/core';

export const usePdfPagesGalleryStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    minHeight: '17rem',
    justifyContent: 'space-between',
  },
  navigatorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
  },
  navigatorControls: {
    position: 'relative',
  },
  prevButton: {
    cursor: 'pointer',
    position: 'absolute',
    left: '-2rem',
    top: '-0.125rem',
  },
  nextButton: {
    cursor: 'pointer',
    position: 'absolute',
    left: '2rem',
    top: '-0.125rem',
  },
  originalSizeContainer: {
    visibility: 'hidden',
    position: 'fixed',
  },
  loading: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minHeight: '17rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
