import React from "react";

const ShapesTool = props => (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="21.9312" y="8" width="11.411" height="12.0884" transform="rotate(45 21.9312 8)" fill={props.fill} stroke="black"/>
    <rect x="8" y="12.998" width="12" height="12" fill={props.fill} stroke="black"/>
    <rect x="15" y="16.998" width="13" height="13" rx="6.5" fill={props.fill} stroke="black"/>
    </svg>
    
  );

  export default ShapesTool