import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Box, Hidden, makeStyles } from '@material-ui/core';

import { useAsk, useGetUploadStatus, useUploadSource } from '../../../services/smart-notes';
import { SOURCE_STATUS } from '../../../constants/smart-notes.constants';
import { addSourceToNote, updateSource } from '../../../store/actions/smart-notes.action';
import ControllerSidebar from '../../../Kneura-Web-Whiteboard/Controller/ControllerSidebar';
import ControllerTopbar from '../../../Kneura-Web-Whiteboard/Controller/ControllerTopbar';
import SourceList from '../../../components/smart-notes/chat/source-list';
import MessageList from '../../../components/smart-notes/chat/message-list';

const useStyles = makeStyles((theme) => ({
  chatPageRoot: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 20,
    padding: '20px 28px',
    backgroundColor: '#66FDCF1F',
    [theme.breakpoints.up('md')]: {
      gap: 16,
      width: 'calc(100vw - 59px)',
      height: 'calc(100dvh - 52px)',
      left: 59,
    },
  },
}));

const ChatPage = ({ handleMobileSideMenuOpen }) => {
  const dispatch = useDispatch();
  const { noteId } = useParams();
  const notes = useSelector((state) => state.smartNotes);
  const classes = useStyles();
  const mounted = useRef();
  const [chatList, setChatList] = useState([]);

  const selectedNote = useMemo(() => notes.find((note) => note.id === noteId), [noteId, notes]);

  const sources = useMemo(() => (selectedNote ? selectedNote.sources : []), [selectedNote]);

  const selectedSourcesIds = useMemo(
    () => sources.filter(({ selected }) => selected).map(({ id }) => id),
    [sources],
  );

  const { mutateAsync: uploadSource, isLoading: isUploadLoading } = useUploadSource(noteId, {
    onSuccess: ({ uniqueId }, { file }) => {
      dispatch(
        addSourceToNote({
          noteId,
          id: uniqueId,
          title: file.name,
          status: SOURCE_STATUS.IN_PROGRESS,
          selected: false,
        }),
      );
      getUploadStatusPooled(uniqueId);
    },
  });

  const uploadSourceHandler = useCallback(
    async (file) => await uploadSource({ file }),
    [uploadSource],
  );

  const sourceSendHandler = async (text) => {
    const textFile = new File([text], text.slice(0, 30) + (text.length > 30 ? '....txt' : '.txt'));
    await uploadSourceHandler(textFile);
  };

  const { mutateAsync: getUploadStatus } = useGetUploadStatus(noteId);

  const { mutateAsync: ask, isLoading: isQueryInProgress } = useAsk();

  const askHandler = useCallback(
    async ({ query }) => {
      if (isQueryInProgress) return;
      const queryTimestamp = moment(new Date()).format('h:mm a, D MMM');
      setChatList((prevChatList) => {
        return [
          ...prevChatList,
          {
            type: 'user',
            timestamp: queryTimestamp,
            content: query,
          },
        ];
      });
      const response = await ask({ query, noteId, sourceIds: selectedSourcesIds });
      const responseTimestamp = moment(new Date()).format('h:mm a, D MMM');
      setChatList((prevChatList) => {
        return [
          ...prevChatList,
          {
            type: 'ai',
            timestamp: responseTimestamp,
            content: response,
          },
        ];
      });
    },
    [ask, isQueryInProgress, noteId, selectedSourcesIds],
  );

  const getUploadStatusPooled = useCallback(
    (sourceId) => {
      setTimeout(async () => {
        const { status } = await getUploadStatus({ id: sourceId });
        if (status === SOURCE_STATUS.IN_PROGRESS) {
          return getUploadStatusPooled(sourceId);
        } else {
          dispatch(updateSource({ noteId, id: sourceId, status }));
        }
      }, 10000);
    },
    [dispatch, getUploadStatus, noteId],
  );

  useEffect(() => {
    if (!selectedNote) return;
    if (mounted.current) return;
    mounted.current = true;
    sources.forEach(({ id, status }) => {
      if (status === SOURCE_STATUS.IN_PROGRESS) {
        getUploadStatusPooled(id);
      }
    });
  }, [getUploadStatusPooled, selectedNote, sources]);

  return (
    <>
      <Hidden smDown>
        <ControllerSidebar />
      </Hidden>
      <ControllerTopbar handleMobileSideMenuOpen={handleMobileSideMenuOpen} />
      <Box className={classes.chatPageRoot}>
        <SourceList
          title={selectedNote?.name}
          items={sources}
          onUpload={uploadSourceHandler}
          onSend={sourceSendHandler}
          loading={isUploadLoading}
        />
        <MessageList
          onAsk={askHandler}
          chatList={chatList}
          disabledQueryInput={!selectedSourcesIds.length}
          loading={isQueryInProgress}
        />
      </Box>
    </>
  );
};

export default ChatPage;
