import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { NOTEBOOKS, getUserId } from '../Utils';
import { baseService } from './base.service';

const roles = { TEACHER: 'TEACHER', LEARNER: 'LEARNER' };

const getNotebooks = async ({ queryKey: [_key, userId] }) => {
  const url = NOTEBOOKS.getNotebooks();
  const response = await baseService.makeGetRequest(url, {
    params: { userId },
    customMessage: 'Failed to get the notebooks from the API',
  });
  return response;
};

export const useGetNotebooks = (options) => {
  const userId = getUserId();
  return useQuery(['getNotebooks', userId], {
    queryFn: getNotebooks,
    refetchOnReconnect: false,
    ...options,
  });
};

const getNotebook = async ({ queryKey: [_key, notebookId] }) => {
  const url = NOTEBOOKS.getNotebook(notebookId);
  const response = await baseService.makeGetRequest(url, {
    customMessage: 'Failed to get the notebook details from the API',
  });
  return response;
};

export const useGetNotebook = (notebookId, options) => {
  return useQuery(['getNotebook', notebookId], {
    queryFn: getNotebook,
    refetchOnReconnect: false,
    enabled: !!notebookId,
    ...options,
  });
};

const addNotebook = async (name) => {
  const url = NOTEBOOKS.addNotebook();
  const userId = getUserId();
  const response = await baseService.makePostRequest(
    url,
    { userId, name, role: roles.TEACHER },
    { customMessage: 'Failed to add the notebook' },
  );
  return response;
};

export const useAddNotebook = (options) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addNotebook,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getNotebooks'] }),
    ...options,
  });
};

const renameNotebook = async ({ id, newName }) => {
  const url = NOTEBOOKS.renameNotebook(id);
  await baseService.makePostRequest(
    url,
    { newName },
    { customMessage: 'Failed to rename the notebook' },
  );
};

export const useRenameNotebook = (options) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: renameNotebook,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getNotebooks'] }),
    ...options,
  });
};

const deleteNotebook = async (id) => {
  const url = NOTEBOOKS.deleteNotebook(id);
  await baseService.makeDeleteRequest(url, 'Failed to delete the notebook');
};

export const useDeleteNotebook = (options) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteNotebook,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getNotebooks'] }),
    ...options,
  });
};

const getUserThreads = async ({ queryKey: [_key, userId] }) => {
  const url = NOTEBOOKS.getUserThreads();
  const response = await baseService.makeGetRequest(url, {
    params: { userId },
    customMessage: 'Failed to get the notebook threads of the user from the API',
  });
  return response;
};

export const useGetUserThreads = (options) => {
  const userId = getUserId();
  return useQuery(['getUserThreads', userId], {
    queryFn: getUserThreads,
    refetchOnReconnect: false,
    enabled: !!userId,
    ...options,
  });
};

const getNotebookThreads = async ({ queryKey: [_key, notebookId] }) => {
  const url = NOTEBOOKS.getNotebookThreads(notebookId);
  const response = await baseService.makeGetRequest(url, {
    customMessage: 'Failed to get the notebook threads from the API',
  });
  return response;
};

export const useGetNotebookThreads = (notebookId, options) =>
  useQuery(['getNotebookThreads', notebookId], {
    queryFn: getNotebookThreads,
    refetchOnReconnect: false,
    enabled: !!notebookId && notebookId !== 'undefined',
    ...options,
  });

const getThreadDetails = async ({ queryKey: [_key, threadId] }) => {
  const url = NOTEBOOKS.getThreadDetails(threadId);
  const response = await baseService.makeGetRequest(url, {
    customMessage: 'Failed to get the thread details from the API',
  });
  return response;
};

export const useGetThreadDetails = (threadId, options) =>
  useQuery(['getThreadDetails', threadId], {
    queryFn: getThreadDetails,
    refetchOnReconnect: false,
    enabled: !!threadId,
    ...options,
  });

const addThread = async (details) => {
  const url = NOTEBOOKS.addThread();
  const userId = getUserId();
  const response = await baseService.makePostRequest(
    url,
    {
      userId,
      ...details,
      role: roles.TEACHER,
    },
    {
      customMessage: 'Failed to add the thread',
    },
  );
  return response;
};

export const useAddThread = (options) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addThread,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getNotebookThreads'] }),
    ...options,
  });
};

const renameThread = async ({ id, newName }) => {
  const url = NOTEBOOKS.renameThread(id);
  await baseService.makePutRequest(
    url,
    { newName },
    { customElements: 'Failed to rename the thread' },
  );
};

export const useRenameThread = (options) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: renameThread,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getNotebookThreads'] }),
    ...options,
  });
};

const deleteThread = async (id) => {
  const url = NOTEBOOKS.deleteThread(id);
  await baseService.makeDeleteRequest(url, { customMessage: 'Failed to delete the thread' });
};

export const useDeleteThread = (options) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteThread,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getNotebookThreads'] }),
    ...options,
  });
};

const moveThread = async ({ threadId, notebookId }) => {
  const url = NOTEBOOKS.moveThread();
  const response = await baseService.makePostRequest(
    url,
    { threadId, notebookId },
    { customMessage: 'Failed to move the thread to the specified notebook' },
  );
  return response;
};

export const useMoveThread = (options) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: moveThread,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getNotebookThreads'] }),
    ...options,
  });
};

const moveThreadToNewNotebook = async ({ threadId, notebookName }) => {
  const userId = getUserId();
  const url = NOTEBOOKS.moveThreadToNewNotebook();
  const response = await baseService.makePostRequest(
    url,
    { threadId, notebookName, userId, role: roles.TEACHER },
    { customMessage: 'Failed to move the thread to the new notebook' },
  );
  return response;
};

export const useMoveThreadToNewNotebook = (options) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: moveThreadToNewNotebook,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getNotebookThreads'] }),
    ...options,
  });
};
