import React from 'react';
import { Button, Typography, makeStyles } from '@material-ui/core';

const useStyles = ({ disabled }) =>
  makeStyles({
    root: {
      pointerEvents: disabled ? 'none' : 'all',
      backgroundColor: '#EEEEEE',
      padding: '4px 8px',
      border: 'none',
      textTransform: 'none',
      '& .MuiButton-startIcon': {
        opacity: disabled ? 0.4 : 1,
        marginRight: 4,
        width: 12,
        height: 12,
      },
      '& .MuiTypography-root': {
        opacity: disabled ? 0.4 : 1,
        fontSize: 10,
        color: '#00000099',
      },
    },
  });

const ActionButton = ({ onClick, iconSrc, alt, title, disabled }) => {
  const classes = useStyles({ disabled })();

  return (
    <Button
      onClick={onClick}
      className={classes.root}
      variant="outlined"
      startIcon={<img src={iconSrc} alt={alt} />}
      disableRipple
    >
      <Typography>{title}</Typography>
    </Button>
  );
};

export default ActionButton;
