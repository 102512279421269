const { makeStyles, styled } = require('@material-ui/core');

export const useDisplayVideoStyles = makeStyles((theme) => ({}));

export const VideoContainer = styled('div')((props) => ({
  position: 'relative',
  width: props.isContentUIDisplayed ? 'initial' : 2,
  height: props.isContentUIDisplayed ? 'initial' : 2,
}));

export const ControlsContainer = styled('div')({
  position: 'absolute',
  bottom: 0,
  left: 0,
});

export const CloseButtonContainer = styled('div')({
  position: 'fixed',
  right: '1rem',
  bottom: '1rem',
  cursor: 'pointer',
  width: '2.875rem',
  backgroundColor: '#FFFFFF',
  height: '2.875rem',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
