import React, { useEffect, useMemo, useRef } from 'react';
import { Hidden, Menu, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import { useState } from 'react';
import PubSub from 'pubsub-js';
import deviceService from '../../services/device.service';
import { useNotificationDispatcher } from '../../store/dispatcher/useNotificationDispatcher';
import CalendarEvent from './CalendarEvent';
import microsoftGraphService from '../../services/microsoft-graph.service';
import moment from 'moment';
import Loader from './Loader';
import BirdAiLogoWithName from '../../assets/icons/birdAiLogoWithName';
import classes from './launcher.module.css';
import { useMicrosoftAuth } from '../../Utils/microsoft/useMicrosoftAuth';
import { useLastPageVisited } from '../../Utils/history/useLastPageVisited';
import OutlookIcon from '../../assets/icons/outlookIcon';
import { EVENTS } from '../../constants/events';
import socketIOClient from 'socket.io-client';
import { BIRDAI_SERVER_URL } from '../../config';
import {
  getToken,
  getUserName,
  setBirdDeviceId,
  setRoomName,
} from '../../Utils/authentication-access';
import MeetingRoomConnector from './MeetingRoomConnector';
import LauncherFeatures from './LauncherFeatures';
import ControllerSidebar from './ControllerSidebar';
import { Refresh } from '@material-ui/icons';
import ControllerTopbar from './ControllerTopbar';
import calendarService from '../../services/calendar.service';
import { useDispatch } from 'react-redux';
import { activeDeviceId } from '../../store/actions/device.action';
import { sendMessageToDevice, socket, initSocket } from '../../Utils/sockets/auth-user-launcher';
import useTabActive from './common/useTabActive';
import DeviceDetector from 'device-detector-js';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { getDeviceLicenseDetails } from '../../store/actions/license.action';
import { showMaxSimultaneousUsageLimitExhaustedDialog } from '../../store/actions/notification.action';
import { useDialogDispatcher } from '../../store/dispatcher/useDialogDispatcher';
import TransparentFrame from './frame/TransparentFrame';
import Avatar from 'react-avatar';
import DefaultUserLogo from '../../assets/svg/DefaultUserLogo.svg';
import DefaultUserLogoWhiteLayout from '../../assets/svg/DefaultUserLogoWhiteLayout.svg';

import Greetings from './frame/Greetings';
import DateAndTimeContainer from './dateTimeContianer/DateAndTimeContainer';
import birdaiLabelIcon from '../../assets/svg/launcher/birdaiLabelIcon.svg';

const Launcher = ({ userName, handleMobileSideMenuOpen }) => {
  const [deviceId, setDeviceId] = useState(null);
  const { dispatchSetSnackbar } = useNotificationDispatcher();
  const [events, setEvents] = useState([]);
  const [openSpinner, setOpenSpinner] = useState(false);
  const titleRef = useRef();
  const [isDeviceConnected, setIsDeviceConnected] = useState(false);
  const [deviceName, setDeviceName] = useState('device Name');
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { initiateMicrosoftOAuthAuthorization, isMicrosoftAuthenticated, revokeAccess } =
    useMicrosoftAuth();
  const { setImportOutlookEventDialogAsLastVisitedforController } = useLastPageVisited();
  const [yesterdayEvents, setYesterdayEvents] = useState([]);
  const [todayEvents, setTodayEvents] = useState([]);
  const [tomorrowEvents, setTomorrowEvents] = useState([]);
  const yesterdayDate = moment().subtract(1, 'days').format('DD MMMM');
  const todayDate = moment().format('DD MMMM');
  const tomorrowDate = moment().add(1, 'days').format('DD MMMM');
  const dispatch = useDispatch();
  const { dispatchSetIsOpenRemoteControlDialog } = useDialogDispatcher();

  const isTabActive = useTabActive();

  const deviceDetector = new DeviceDetector();
  const device = deviceDetector.parse(navigator.userAgent);
  const [anchorEl, setAnchorEl] = useState(null);
  const boardOptionOpen = Boolean(anchorEl);
  const isSmallScreen = useMediaQuery('(max-width: 800px)');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseboardOptions = () => {
    setAnchorEl(null);
  };

  useEffect(() => { }, []);

  const handleConnect = (data) => {
    console.log('socket connect', data);
  };

  const handleConnectError = (data) => {
    console.log('socket connect_error', data);
  };

  const handleDisconnect = (data) => {
    console.log('socket disconnect', data);
    setIsDeviceConnected(false);
  };

  useEffect(() => {
    const init = async () => {
      await initSocket();
      socket.on('connect', handleConnect);
      socket.on('connect_error', handleConnectError);
      socket.on('disconnect', handleDisconnect);
      socket.on('message', getMessage);
    };
    init();

    return () => {
      socket.off('connect_error', getMessage);
      socket.off('connect', getMessage);
      socket.off('disconnect', getMessage);
      socket.off('message', getMessage);
    };
  }, []);

  const getMessage = (msg) => {
    console.log('message came', msg.type);
    if (msg.type === EVENTS.PAIR_SUCCESSFUL) {
      setIsDeviceConnected(true);
      setDeviceName(msg.deviceName);
      dispatchSetSnackbar({ message: 'Device connected' });
    }
    if (msg.type === EVENTS.UNPAIR_DEVICE) {
      setIsDeviceConnected(false);
      setDeviceName('');
      setDeviceId('');
      dispatchSetSnackbar({ message: 'Device disconnected' });
    }
    if (msg.type === EVENTS.SCREENSHARE_STOPPED) {
      PubSub.publish(EVENTS.SCREEN_SHARE_STOPPED_FROM_DEVICE);
    }
    if (msg.type === EVENTS.OPEN_REMOTE_CONTROLLER) {
      console.log('open remote controller');
      openRemoteControllerDialog();
    }
  };

  const openRemoteControllerDialog = () => {
    dispatchSetIsOpenRemoteControlDialog({
      isOpen: true,
      isAnonymousUser: false,
    });
  };

  const pairToDevice = async () => {
    if (deviceId) {
      try {
        const response = await deviceService.sendDeviceID(deviceId);
        dispatchSetSnackbar({ message: 'Device connect request sent' });
        dispatch(activeDeviceId(deviceId));
        setBirdDeviceId(deviceId);
        dispatch(getDeviceLicenseDetails(response.data.hardwareId));
      } catch (error) {
        console.log('deviceId error', error.response.data.message);
        dispatchSetSnackbar({ message: error.response.data.message });

        if (error.response.data.type === 'SIMULTANEOUS_LIMIT_EXHAUSTED') {
          dispatch(showMaxSimultaneousUsageLimitExhaustedDialog());
        }
      }
    } else {
      dispatchSetSnackbar({ message: 'Enter device Id' });
    }
  };

  //TODO: hide for feature Imp (undo if all event cal is required)
  // const fetchCalendarData = async () => {
  //   if (isMicrosoftAuthenticated) {
  //     try {
  //       setOpenSpinner(true);

  //       const events = await microsoftGraphService.getMyUpcomingCalendarEvents();

  //       setEvents(events);
  //       setOpenSpinner(false);
  //     } catch (error) {
  //       console.log('error', error);
  //       setOpenSpinner(false);
  //     }
  //   }
  // };

  const fetchCalendarInfo = async () => {
    setOpenSpinner(false);
    if (isMicrosoftAuthenticated) {
      try {
        setOpenSpinner(true);
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        const res = await calendarService.getCalendarInfo(timeZone);
        setYesterdayEvents(res.data.data.yesterday);
        setTodayEvents(res.data.data.today);
        setTomorrowEvents(res.data.data.tomorrow);
        setOpenSpinner(false);
        handleCloseboardOptions();
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  useEffect(() => {
    getPairDeviceStatusHandler();
    fetchCalendarInfo();
  }, [isMicrosoftAuthenticated]);

  const countHandler = (todayEvents) => {
    const remainingEvents = todayEvents.filter((item) => {
      const dateNow = new Date();
      const scheduleZone = new Date(item.start.dateTime + 'Z');
      let dueDateOn = scheduleZone.toISOString();
      return moment(moment(dateNow).format()).isSameOrBefore(moment(moment(dueDateOn).format()));
    });
    return remainingEvents.length
      ? `${remainingEvents.length} meetings later today`
      : 'No upcoming meetings today';
  };

  const todayRemainingEvents = useMemo(() => {
    const todayDate = moment().format('YYYY-MM-DD');
    const todayEvents = events.filter((eachEvent) => {
      const scheduleZone = new Date(eachEvent.start.dateTime + 'Z');
      let date = scheduleZone.toISOString();
      let scheduleTime = moment(date).format('YYYY-MM-DD');
      return scheduleTime === todayDate;
    });
    return countHandler(todayEvents);
  }, [events]);

  const enableRef = () => {
    const upComingEvent = todayEvents.filter((eachEvent) => {
      const dateNow = new Date();
      const scheduleZone = new Date(eachEvent.start.dateTime + 'Z');
      let dueDateOn = scheduleZone.toISOString();
      return moment(moment(dateNow).format()).isSameOrBefore(moment(moment(scheduleZone).format()));
    });
    const addFlag = upComingEvent[0];

    if (addFlag?.iCalUId == undefined) {
      const upComingEvent = tomorrowEvents.filter((eachEvent) => {
        const dateNow = new Date();
        const scheduleZone = new Date(eachEvent.start.dateTime + 'Z');
        let dueDateOn = scheduleZone.toISOString();
        return moment(moment(dateNow).format()).isSameOrBefore(
          moment(moment(scheduleZone).format()),
        );
      });
      const addFlag = upComingEvent[0];
      return addFlag?.iCalUId;
    }

    return addFlag?.iCalUId;
  };

  const moveUpcomingEventUp = (iCalUId) => {
    titleRef.current.scrollIntoView({ top: '-20px', behavior: 'smooth' });
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const cleanupDisconnectedDevice = () => {
    dispatchSetSnackbar({ message: 'Device disconnected' });
    setIsDeviceConnected(false);
    setDeviceName('');
    setDeviceId('');
  };

  const disconnectDeviceHandler = async () => {
    try {
      PubSub.publish(EVENTS.DISCONNECT_PAIRED_DEVICE);

      /** TODO: refactor this */
      await delay(1000);
      await deviceService.unPairDevice();
      cleanupDisconnectedDevice();
    } catch (error) {
      console.log('deviceId error', error.response.data.message);
      dispatchSetSnackbar({ message: error.response.data.message });
    }
  };

  const getPairDeviceStatusHandler = async () => {
    try {
      const response = await deviceService.pairedDeviceStatus();
      setIsDeviceConnected(response.data.message === 'CONNECTED');
      setDeviceName(response.data.data.deviceName);
      setRoomName(response.data.data.deviceName);
    } catch (error) {
      console.log('error', error);
    }
  };

  const launchMeetingHandler = (eventName, eventLink) => {
    if (isDeviceConnected) {
      startMeetingOnDeviceHandler(eventName, eventLink);
    } else {
      window.open(eventLink, '_blank');
    }
  };

  const startMeetingOnDeviceHandler = async (eventName, eventLink) => {
    try {
      const response = await deviceService.startMeetingOnDevice(eventName, eventLink);
      dispatchSetSnackbar({ message: 'Meeting started on Bird device' });
    } catch (error) {
      console.log('error', error);
      dispatchSetSnackbar({ message: 'event not able to start in device' });
    }
  };

  const MicrosoftAuthenticatedHandler = () => {
    if (isMicrosoftAuthenticated) {
      fetchCalendarInfo();
    } else {
      setImportOutlookEventDialogAsLastVisitedforController();
      initiateMicrosoftOAuthAuthorization();
    }
  };

  useEffect(() => {
    const init = async () => {
      if (device.os.name == 'iOS') {
        try {
          const response = await deviceService.pairedDeviceStatus();
          const { message } = response.data;
          if (message === 'DISCONNECTED') {
            cleanupDisconnectedDevice();
          }
        } catch (err) {
          console.log('err', err);
        }
      }
    };
    init();
  }, [isTabActive]);

  const revokeAccessHandler = () => {
    handleCloseboardOptions();
    revokeAccess();
  };

  const noUpcomingEvents = <div className={classes.noEventsText}> No meetings </div>;
  return (
    <>
      {/* /** top bar */}
      <Hidden smDown>
        <ControllerSidebar />
      </Hidden>
      <ControllerTopbar handleMobileSideMenuOpen={handleMobileSideMenuOpen} pageTitle="Home" />

      <div className={classes.launcherMainContainer}>
        <div className={classes.boxContainer}>
          {/* //Left box */}
          <div className={classes.leftBoxContainer}>
            <div className={classes.leftBoxContainerInner}>
              <TransparentFrame
                label={'Connect your device'}
                frameWidth={'331px'}
                frameHeight={isSmallScreen ? '100%' : '414px'}
              >
                <div className={classes.classInfoMainContainer}>
                  <div className={classes.instituteDetailsStyle}>
                    <div className={classes.largeScaleDp}>
                      <Avatar
                        name={getUserName()}
                        round={true}
                        size="54"
                        src={DefaultUserLogoWhiteLayout}
                      // style={{ background: "#FCAF41" }}
                      />

                      <div>
                        <Greetings />
                        <div className={classes.nameStyleLarge}>{getUserName()}</div>
                      </div>
                    </div>
                  </div>

                  <div className={classes.instituteDetailsStyle}>
                    <MeetingRoomConnector
                      isDeviceConnected={isDeviceConnected}
                      disconnectDeviceHandler={disconnectDeviceHandler}
                      pairToDevice={pairToDevice}
                      collectDeviceId={setDeviceId}
                      deviceName={deviceName}
                    />

                    <LauncherFeatures
                      isDeviceConnected={isDeviceConnected}
                      deviceId={deviceId}
                      sendMessageToDevice={sendMessageToDevice}
                      socket={socket}
                    />
                  </div>
                </div>
              </TransparentFrame>
            </div>
          </div>
          {/* right box */}

          <div
          //  className={classes.rightBoxContainer}
          >
            <DateAndTimeContainer />
            <TransparentFrame
              label={'My Schedule'}
              frameWidth={'331px'}
              frameHeight={isSmallScreen ? '100%' : '309px'}
              rightBtn={
                <MoreHorizIcon
                  fontSize="small"
                  style={{
                    color: 'white',
                    cursor: 'pointer',
                    borderRadius: '25px',
                  }}
                  onClick={handleClick}
                />
              }
            >
              {/* <div className={classes.calendarLabel}>
              <div>My Schedule</div>

              <MoreHorizIcon
                fontSize="small"
                style={{
                  color: 'white',
                  cursor: 'pointer',
                  border: '1.5px solid white',
                  borderRadius: '25px',
                }}
                onClick={handleClick}
              />*/}
              <Menu
                id={0}
                anchorEl={anchorEl}
                keepMounted
                open={boardOptionOpen}
                onClose={handleCloseboardOptions}
                PaperProps={{
                  style: {
                    maxHeight: 48 * 4.5,
                    width: '160px',
                  },
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                getContentAnchorEl={null}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem
                  className="textColor"
                  onClick={revokeAccessHandler}
                  disabled={!isMicrosoftAuthenticated}
                >
                  Revoke Access
                </MenuItem>
                <MenuItem
                  className="textColor"
                  onClick={fetchCalendarInfo}
                  disabled={!isMicrosoftAuthenticated}
                >
                  Refresh
                </MenuItem>
              </Menu>
              {/* </div>  */}

              {isMicrosoftAuthenticated ? (
                <div
                  style={{
                    height: '278px',
                    padding: '0 8px',
                    paddingTop: '5px',
                    overflow: `${isMicrosoftAuthenticated ? 'auto' : 'hidden'}`,
                  }}
                  className={classes.eventsContainer}
                >
                  <div className={classes.eventDate}>
                    {yesterdayDate} <span className={classes.eventDay}>Yesterday</span>
                  </div>

                  <>
                    {yesterdayEvents.length ? (
                      yesterdayEvents.map((eventInfo) => {
                        return (
                          <CalendarEvent
                            key={eventInfo.iCalUId}
                            eventInfo={eventInfo}
                            launchMeetingHandler={launchMeetingHandler}
                            isDeviceConnected={isDeviceConnected}
                          />
                        );
                      })
                    ) : (
                      <>{noUpcomingEvents}</>
                    )}
                  </>

                  <div className={classes.eventDate}>
                    {todayDate} <span className={classes.eventDay}>Today</span>
                  </div>
                  <>
                    {todayEvents.length ? (
                      todayEvents.map((eventInfo) => {
                        return (
                          <>
                            <div
                              key={eventInfo.iCalUId}
                              ref={(ref) => {
                                if (matches && ref) {
                                  titleRef.current = ref;
                                  if (enableRef() === eventInfo.iCalUId) {
                                    moveUpcomingEventUp(eventInfo.iCalUId);
                                  }
                                }
                              }}
                            ></div>
                            <CalendarEvent
                              key={eventInfo.iCalUId}
                              eventInfo={eventInfo}
                              launchMeetingHandler={launchMeetingHandler}
                              isDeviceConnected={isDeviceConnected}
                            />
                          </>
                        );
                      })
                    ) : (
                      <>{noUpcomingEvents}</>
                    )}
                  </>
                  <div className={classes.eventDate}>
                    {tomorrowDate} <span className={classes.eventDay}>Tomorrow</span>
                  </div>
                  <>
                    {tomorrowEvents.length ? (
                      tomorrowEvents.map((eventInfo) => {
                        return (
                          <>
                            <div
                              key={eventInfo.iCalUId}
                              ref={(ref) => {
                                if (matches && ref) {
                                  titleRef.current = ref;
                                  if (enableRef() === eventInfo.iCalUId) {
                                    moveUpcomingEventUp(eventInfo.iCalUId);
                                  }
                                }
                              }}
                            />
                            <CalendarEvent
                              key={eventInfo.iCalUId}
                              eventInfo={eventInfo}
                              launchMeetingHandler={launchMeetingHandler}
                              isDeviceConnected={isDeviceConnected}
                            />
                          </>
                        );
                      })
                    ) : (
                      <>{noUpcomingEvents}</>
                    )}
                  </>
                </div>
              ) : (
                <div className={classes.msContentContainer}>
                  <div className={classes.connectToCalendarText}>
                    Connect a calendar to view meetings and start meetings
                  </div>
                  <div
                    className={classes.iconContainerStyle}
                    onClick={MicrosoftAuthenticatedHandler}
                  >
                    <OutlookIcon />
                    <div className={classes.iconTextStyle}>Exchange Calendar</div>
                  </div>
                </div>
              )}

              {/* 
            TODO: Required
            {isMicrosoftAuthenticated && (
              <div
                style={{
                  position: `${events.length <= 1 ? 'fixed' : 'sticky'}`,
                  bottom: '0',
                }}
                className={classes.rightBoxFooterContainer}
              >
                <div className={classes.RefreshText} onClick={fetchCalendarData}>
                  Refresh
                </div>
                <div className={classes.todayEventsCountStyle}>{todayRemainingEvents}</div>
              </div>
            )} */}
              <Loader openSpinner={openSpinner} />
            </TransparentFrame>
          </div>

          <div className={classes.aiLogo}>
            <img src={birdaiLabelIcon} alt="logo" />
          </div>
        </div>
        {/* //Footer */}
        {/* //TODO : required */}
        {/* <footer className={classes.footerContainer}>
          <div className={classes.footerText}>
            <div>powered by</div>
            <div>
              <BirdAiLogoWithName />
            </div>
          </div>
        </footer> */}
      </div>
    </>
  );
};

export default Launcher;
