import React from 'react';
import { CalendarDetailsDialog } from './component';

export const CalendarDetailsDialogContainer = (props) => {
  const title = 'Description';
  const { isOpen, close, eventInfo, isDeviceConnected } = props;

  return (
    <CalendarDetailsDialog
      close={close}
      eventInfo={eventInfo}
      isDeviceConnected={isDeviceConnected}
      isOpen={isOpen}
      title={title}
    />
  );
};
