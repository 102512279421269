import {
  Button,
  Dialog,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import { useWorkspaceDialogStyle } from './useWorkspaceDialogStyle';

const WorkspaceDialog = ({
  open,
  closeDialog,
  inputText,
  onChangeInputText,
  createNewWorkspace,
  errorTextMessage,
}) => {
  const classes = useWorkspaceDialogStyle();
  const isMobileView = useMediaQuery('(max-width:600px)');

  const onEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      createNewWorkspace(inputText);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeDialog}
        fullWidth={true}
        maxWidth={'sm'}
        PaperProps={{
          style: {
            borderRadius: 16,
          },
        }}
      >
        <div className={classes.dialogHeader}>
          <div className={classes.headerName}> Create a new workspace</div>
          <IconButton
            edge="end"
            color="inherit"
            style={{ marginRight: 10, cursor: 'pointer' }}
            onClick={closeDialog}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div style={{ margin: '29px' }}>
          <Typography variant="h6" component="div" className={classes.labelText}>
            Name your workspace
          </Typography>
          <TextField
            autoFocus
            id="name"
            type="name"
            style={{ width: `${isMobileView ? '100%' : '423px'}` }}
            value={inputText}
            variant="outlined"
            onChange={(event) => onChangeInputText(event)}
            onKeyDown={onEnterKeyPress}
            helperText={errorTextMessage}
          />
        </div>

        <div className={classes.footerContainer}>
          <Button onClick={closeDialog} className={classes.cancelButton}>
            {' '}
            CANCEL
          </Button>

          <Button className={classes.saveButton} onClick={() => createNewWorkspace(inputText)}>
            SAVE
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default WorkspaceDialog;
