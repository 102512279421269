import { makeStyles, styled } from '@material-ui/core';

export const useDisplayViewOptionsStyle = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    right: 0,
    zIndex: 9999,
    [theme.breakpoints.down('md')]: {
      right: 'unset',
      left: 0,
    },
  },
  contentContainer: {
    position: 'relative',
    height: '100%',
    bottom: '50px',
    [theme.breakpoints.down('md')]: {
      position: 'static',
      bottom: 'unset',
    },
  },
  draggableContainer: {
    position: 'fixed',
    bottom: '24rem',
    right: '3rem',
    zIndex: 9999,
    [theme.breakpoints.down('md')]: {
      top: '8px',
      right: 'unset',
      bottom: 'unset',
      left: '40px',
    },
  },
  viewOptionsLayout: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem',
    height: 'fit-content',
    justifyContent: 'center',
  },
  /**
   * @deprecated
   * TODO: this needs to be removed and replaced with the styled component below
   */
  viewOptionsPosition: {
    backgroundColor: 'black',
    borderBottomLeftRadius: '0.5rem',
    borderTopLeftRadius: '0.5rem',
    bottom: 0,
    height: 'fit-content',
    left: '-2rem',
    padding: '0.5rem 0',
    position: 'absolute',
    top: 0,
    width: '2rem',
  },
  fullScreenButton: {
    backgroundColor: 'white',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.5rem',
    height: '1.5rem',
    margin: '0.25rem',
    cursor: 'pointer',
  },
}));

/**
 * note:
 * The following is a quick fix
 * the top property as been handle to avoid/solve flickering of the view options box
 * we might to improve the implementation to fix the flickering.
 */
export const ViewOptionsPosition = styled('div')((props) => ({
  backgroundColor: 'black',
  borderBottomLeftRadius: '0.5rem',
  borderTopLeftRadius: '0.5rem',
  bottom: 0,
  height: 'fit-content',
  left: '-2rem',
  padding: '0.5rem 0',
  position: 'absolute',
  top: props.isContentUIDisplayed ? 0 : -2,
  width: '2rem',
}));

/** if props.cursor, then cursor is props.cursor else pointer */
export const ControlItem = styled('strong')((props) => ({
  cursor: props.cursor ?? 'pointer',
  display: 'flex',
}));
