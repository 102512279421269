import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import InfoIconWhite from '../../assets/icons/InfoIconWhite';
import StyledButton from '../../UIComponents/input/StyledButton';
import { CalendarDetailsDialogContainer } from './calendar/calendar-details-dialog/container';
import { EventDetailContainer } from './calendar/event-detail/container';
import { LaunchMeetingButtonContainer } from './calendar/launch-meeting-button/container';
import classes from './launcher.module.css';

const CalendarEvent = ({ eventInfo, launchMeetingHandler, isDeviceConnected }) => {
  const [isCalendarDetailsDialogOpen, setIsCalendarDetailsDialogOpen] = useState(false);

  const closeCalendarDetailsDialog = () => {
    setIsCalendarDetailsDialogOpen(false);
  };

  const openCalendarDetailsDialog = () => {
    setIsCalendarDetailsDialogOpen(true);
  };

  const descriptionButton = (
    <div onClick={openCalendarDetailsDialog} className={classes.infoBtn}>
      {/* <InfoIconWhite /> */}
      Meeting info
    </div>
  );

  const calendarDetailsDialogContainer = (
    <CalendarDetailsDialogContainer
      isOpen={isCalendarDetailsDialogOpen}
      close={closeCalendarDetailsDialog}
      eventInfo={eventInfo}
      isDeviceConnected={isDeviceConnected}
    />
  );

  const launchMeetingButton = (
    <LaunchMeetingButtonContainer
      eventInfo={eventInfo}
      isDeviceConnected={isDeviceConnected}
      openCalendarDetailsDialog={openCalendarDetailsDialog}
    />
  );

  return (
    <div
      style={{
        margin: '12px 0',
      }}
    >
      <div
        style={{
          // border: '1px solid #FFFFFF',
          borderRadius: '4px',
          padding: '12px 16px',
          marginBottom: '12px',
          color: '#fff',
          background: '#FFFFFF1A',
        }}
      >
        <EventDetailContainer eventInfo={eventInfo} />
        <div style={{ display: 'flex', alignItems: 'center', gap: '14px' }}>
          {launchMeetingButton}
          {descriptionButton}
        </div>
      </div>
      {calendarDetailsDialogContainer}
    </div>
  );
};

export default CalendarEvent;
