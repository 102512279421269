import React from 'react';
import { BottomBar } from './component';
import { useMicrosoftAuth } from '../../../../../../Utils/microsoft/useMicrosoftAuth';
import { useGetOutlookEvents } from '../../../import-outlook-event-dialog/use-get-outlook-events';

export const BottomBarContainer = (props) => {
  const { refetchOutlookEvents } = props;
  const { revokeAccess } = useMicrosoftAuth();
  const { openImportOutlookCalendarEventDialog } = useGetOutlookEvents();
  const allEventsLabel = 'All Events';
  const refreshLabel = 'Refresh';

  const menuItems = [
    {
      label: 'Revoke Access',
      onClick: () => {
        revokeAccess();
      },
    },
  ];

  return (
    <BottomBar
      refetchOutlookEvents={refetchOutlookEvents}
      menuItems={menuItems}
      refreshLabel={refreshLabel}
      allEventsLabel={allEventsLabel}
      openImportOutlookCalendarEventDialog={openImportOutlookCalendarEventDialog}
    />
  );
};
