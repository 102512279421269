import { Button } from '@material-ui/core';
import React from 'react';
import { StyledDialogContainer } from '../../common/dialog/styled-dialog/container';
import { useDocumentSelectImportDialogStyles } from './component.style';

export const DocumentSelectImportDialog = (props) => {
  const {
    closeDialog,
    description,
    dialogTitle,
    importAndExpandButtonLabel,
    importButtonLabel,
    isOpen,
    onImportAndExpandClick,
    onJustImportClick,
  } = props;
  const classes = useDocumentSelectImportDialogStyles();

  const footerContent = (
    <div className={classes.footerContainer}>
      <Button variant="contained" onClick={onJustImportClick}>
        {importButtonLabel}
      </Button>
      <Button variant="contained" color="primary" onClick={onImportAndExpandClick}>
        {importAndExpandButtonLabel}
      </Button>
    </div>
  );

  return (
    <StyledDialogContainer
      onClose={closeDialog}
      footerContent={footerContent}
      open={isOpen}
      title={dialogTitle}
    >
      <div className={classes.container}>{description}</div>
    </StyledDialogContainer>
  );
};
