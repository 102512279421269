import { makeStyles } from '@material-ui/core';

export const usePairBoardButtonStyles = makeStyles(() => ({
  huddleButton: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    textTransform: 'none',
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important"
    }
  },

  unpairHuddleButton: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    textTransform: 'none',
    "&:hover": {
      // backgroundColor: "rgba(0, 0, 0, 0.04) !important"
    }
  },
}));
