import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

import { birdAIIcon } from '../../assets/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    margin: '128px auto 40px',
    [theme.breakpoints.down('md')]: {
      margin: '24px auto 24px',
    },
    '& img': {
      width: 40,
      height: 40,
    },
    '& h1': {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
  },
}));

export const Header = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src={birdAIIcon} alt="bird AI assist" />
      <Typography variant="h1">Bird AI</Typography>
    </div>
  );
};

export default Header;
