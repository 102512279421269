import React, { useEffect, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import NotesPage from './notebooks';
import ChatPage from './chat';
import { setNotes } from '../../store/actions/smart-notes.action';
import { getUserNotes } from '../../Utils/smart-notes';

const SmartNotesPage = ({ handleMobileSideMenuOpen }) => {
  const dispatch = useDispatch();

  useMemo(() => {
    const userNotes = getUserNotes();
    dispatch(setNotes(userNotes));
  }, [dispatch]);

  return (
    <Switch>
      <Route
        path={'/controller/smart-notes/notebooks'}
        render={() => <NotesPage handleMobileSideMenuOpen={handleMobileSideMenuOpen} />}
        exact
      />
      <Route
        path={'/controller/smart-notes/notebooks/:noteId'}
        render={() => <ChatPage handleMobileSideMenuOpen={handleMobileSideMenuOpen} />}
        exact
      />
    </Switch>
  );
};

export default SmartNotesPage;
