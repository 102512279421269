import { makeStyles } from '@material-ui/core';

export const useScheduleFilterDialogStyles = makeStyles((theme) => ({
  listScheduleSelect: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  selectScheduleFilterItem: {
    cursor: 'pointer',
    color: '#000'
  },
  selectScheduleFilterList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
}));
