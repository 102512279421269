import React from 'react';

import { Route, Switch } from 'react-router-dom';
import NotebooksPage from '../notebooks';
import SelfLearnPage from '../self-learn/index.jsx';
import LayoutContext from '../../context/layout-context.jsx';

const LearnAIPage = ({ handleMobileSideMenuOpen }) => {
  return (
    <LayoutContext>
      <Switch>
        <Route
          path={'/controller/learn-ai/notes'}
          render={() => <NotebooksPage handleMobileSideMenuOpen={handleMobileSideMenuOpen} />}
          exact
        />
        <Route
          path={'/controller/learn-ai/chats/new-thread/:notebookId'}
          render={() => (
            <SelfLearnPage newThread key={1} handleMobileSideMenuOpen={handleMobileSideMenuOpen} />
          )}
          exact
        />
        <Route
          path={'/controller/learn-ai/chats/new-thread'}
          render={() => (
            <SelfLearnPage newThread key={2} handleMobileSideMenuOpen={handleMobileSideMenuOpen} />
          )}
          exact
        />
        <Route
          path={'/controller/learn-ai/chats/:notebookId/:threadId'}
          render={() => (
            <SelfLearnPage key={3} handleMobileSideMenuOpen={handleMobileSideMenuOpen} />
          )}
          exact
        />
        <Route
          path={'/controller/learn-ai/chats'}
          render={() => (
            <SelfLearnPage key={4} handleMobileSideMenuOpen={handleMobileSideMenuOpen} />
          )}
        />
      </Switch>
    </LayoutContext>
  );
};

export default LearnAIPage;
