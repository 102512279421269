import React, { useCallback, useMemo } from 'react';
import Color from 'color';
import { Avatar, Typography, makeStyles } from '@material-ui/core';

// import { stringToHSLColor } from '../../../utils/common-utils';
// import { useGetUserInfo } from '../../../services/user.service';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    padding: '0px 40px',
    '& .i-chat-content': {
      '& pre': {
        fontFamily: 'inherit',
        color: '#888888',
        fontSize: 14,
        margin: 0,
        whiteSpace: 'pre-line',
      },
    },
  },
});

const UserChat = ({ content }) => {
  const classes = useStyles();

  const scrollIntoView = useCallback((ref) => ref && ref.scrollIntoView(), []);

  // const { data: userInfo } = useGetUserInfo();

  // const fullNameAbbr = useMemo(
  //   () =>
  //     (userInfo?.data?.first_name?.charAt(0) || '') + (userInfo?.data?.last_name?.charAt(0) || ''),
  //   [userInfo],
  // );

  // const avatarColor = useMemo(() => {
  //   const color = new Color(stringToHSLColor(fullNameAbbr));
  //   return color.desaturate(0.3).darken(0.2).hex();
  // }, [fullNameAbbr]);

  if (!content) {
    return <></>;
  }

  return (
    <div className={classes.root} ref={scrollIntoView}>
      <Avatar
        // alt={userInfo?.data?.first_name}
        // src={userInfo?.data?.user_photo}
        // style={{ borderRadius: 8, backgroundColor: avatarColor, width: 36, height: 36 }}
        style={{ borderRadius: 8, width: 36, height: 36 }}
      >
        {/* {fullNameAbbr} */}
      </Avatar>
      <Typography className="i-chat-content">
        <pre>{content}</pre>
      </Typography>
    </div>
  );
};

export default React.memo(UserChat);
