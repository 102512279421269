import ACTION_TYPES from './type.action';

/**
 *
 * @param {boolean} payload
 * @returns
 */
export const setIsScreenshared = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_IS_SCREENSHARED,
    payload,
  });
};
