import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import { addNote, updateNote, deleteNote } from '../../../../store/actions/smart-notes.action';
import NotebookCard from './notebook-card';
import NewNotebook from './new-notebook';

const useStyles = makeStyles((theme) => ({
  notebookListRoot: {
    display: 'grid',
    maxWidth: theme.breakpoints.values.lg,
    margin: 'auto',
    gridTemplateColumns: '1fr',
    gap: 24,
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    },
  },
}));

const NotebookList = () => {
  const dispatch = useDispatch();
  const notes = useSelector((state) => state.smartNotes);
  const classes = useStyles();

  const createNoteHandler = useCallback((name) => dispatch(addNote({ name })), [dispatch]);

  const renameNoteHandler = useCallback(
    ({ id, name }) => dispatch(updateNote({ id, name })),
    [dispatch],
  );

  const deleteNoteHandler = useCallback((id) => dispatch(deleteNote(id)), [dispatch]);

  return (
    <div className={classes.notebookListRoot}>
      <NewNotebook onCreate={createNoteHandler} />
      {notes.map(({ id, name }) => (
        <NotebookCard
          key={id}
          id={id}
          title={name}
          onRename={renameNoteHandler}
          onDelete={deleteNoteHandler}
        />
      ))}
    </div>
  );
};

export default NotebookList;
