import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Dialog, IconButton } from '@material-ui/core';
import { useDialogStyle } from '../../../components/manage/commonFiles/DialogBox.styles';
import StyledDialog from '../../../UIComponents/modal/StyledDialog';
import { StyledDialogContainer } from '../../../components/common/dialog/styled-dialog/container';

const HostMeetingDialog = ({ open, onClose, handleOpenRemoteControlDialog }) => {
  const classes = useDialogStyle();

  const text = `Meeting has been launched. Bird Device has joined as guest. Use Remote Control mode to operate your Bird device from here.`;

  const centerContent = (
    <div className={classes.containerText} style={{ padding: '5px 20px' }}>
      {text}
    </div>
  );

  const launchMeetingButton = (
    <Button
      className={classes.saveBtn}
      style={{ width: 'auto', padding: '0 14px' }}
      onClick={handleOpenRemoteControlDialog}
    >
      SWITCH TO REMOTE CONTROL
    </Button>
  );

  const footerContent = (
    <div
      style={{
        width: '100%',
        textAlign: 'right',
      }}
    >
      {launchMeetingButton}
    </div>
  );

  return (
    <div>
      <StyledDialogContainer
        open={open}
        onClose={onClose}
        title="Control your Meeting"
        footerContent={footerContent}
      >
        {centerContent}
      </StyledDialogContainer>
    </div>
  );
};

export default HostMeetingDialog;
