import React, { useState } from 'react';
import { schedulesService } from '../../../../../services/schedules.service';
import { useNotificationDispatcher } from '../../../../../store/dispatcher/useNotificationDispatcher';
import MeetingCard from './component';
import { EVENTS } from '../../../../../constants/events';
import PubSub from 'pubsub-js';
import { JoinAsHostDialogContainer } from '../join-as-host-dialog/container';
import { useZoomSelector } from '../../../../../store/selector/useZoomSelector';
import { useZoomAuth } from '../../../../../Utils/zoom/useZoomAuth';
import { useLastPageVisited } from '../../../../../Utils/history/useLastPageVisited';
import { FEATURE_FLAGS } from '../../../../../constants/feature-flags.constants';
import { useMicrosoftAuth } from '../../../../../Utils/microsoft/useMicrosoftAuth';
import { VideoCallUserSelectionDialogContainer } from '../../microsoft/components/video-call-user-selection-dialog/container';
import zoomService from '../../../../../services/zoom.service';
import { ExternalJoinAsHostDialogContainer } from '../external-join-as-host-dialog/container';
import { spacesService } from '../../../../../services/spaces.service';
import { getCanvasCode } from '../../../../../Utils/authentication-access';
import { constructQueryParams } from '../../../../../Utils/url';
import config from '../../../../../config';
import { useMicrosoftTeams } from '../../../../../Utils/microsoft/useMicrosoftTeams';
import {
  checkIsMeetingDisabled,
  getMeetingURL,
} from '../../../../../Utils/microsoft/outlook-event';
import { useSchedulesDispatcher } from '../../../../../store/dispatcher/useSchedulesDispatcher';

const MeetingCardContainer = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { isAuthenticated } = useZoomSelector();
  const { isMicrosoftAuthenticated } = useMicrosoftAuth();
  const { initiateZoomOAuthAuthorization } = useZoomAuth();
  const { setZoomGoToMeetingDialogAsLastVisited } = useLastPageVisited();
  const [isOpenJoinAsHostDialog, setIsOpenJoinAsHostDialog] = useState(false);
  const [isOpenVideoCallUserSelectionDialog, setIsOpenVideoCallUserSelectionDialog] =
    useState(false);
  const [isOpenExternalJoinAsHostDialog, setIsOpenExternalJoinAsHostDialog] = useState(false);
  const [isGoToMeetingButtonLoading, setIsGoToMeetingButtonLoading] = useState(false);
  const { dispatchShowSpinner, dispatchHideSpinner, dispatchSetSnackbar } =
    useNotificationDispatcher();
  const { startTeamsMeeting } = useMicrosoftTeams();
  const { dispatchRemoveOutlookImportedEvent } = useSchedulesDispatcher();
  const {
    schedule,
    getSchedulesHandler,
    enableEditSchedule,
    checkIsAllScheduleFilter,
    closeSidebar,
    importToSpace,
    disableOptions,
    dateTimeFormat,
    id,
  } = props;
  const joinGoToMeetingHereMessage = 'Meeting will be started on paired device shortly';

  const openDeleteDialog = () => {
    setOpenDialog(true);
  };
  const closeDeleteDialog = () => {
    setOpenDialog(false);
  };

  const openJoinAsHostDialog = () => {
    setIsOpenJoinAsHostDialog(true);
  };

  const closeJoinAsHostDialog = () => {
    setIsOpenJoinAsHostDialog(false);
  };

  const openVideoCallUserSelectionDialog = () => {
    setIsOpenVideoCallUserSelectionDialog(true);
  };

  const closeVideoCallUserSelectionDialog = () => {
    setIsOpenVideoCallUserSelectionDialog(false);
  };

  const closeExternalJoinAsHostDialog = () => {
    setIsOpenExternalJoinAsHostDialog(false);
  };

  const openExternalJoinAsHostDialog = () => {
    setIsOpenExternalJoinAsHostDialog(true);
  };

  const openZoomMeetingInComponentView = async () => {
    PubSub.publish(EVENTS.JOIN_ZOOM_MEETING, {
      data: { meetingUrl: getMeetingURL(schedule) },
    });
  };

  const isMicrosoftTeamsLink = (link) => {
    return (
      link.startsWith('https://teams.live.com') ||
      link.startsWith('https://teams.microsoft.com/l/meetup-join')
    );
  };

  /**
   * zoom,
   * microsoft teams
   * else open in new tab
   */
  const checkMeetingLinkType = (link) => {
    if (!link) {
      return;
    }

    if (link.includes('zoom.us')) {
      return 'ZOOM_MEETING';
    }

    if (isMicrosoftTeamsLink(link)) {
      return 'MICROSOFT_TEAMS_MEETING';
    }
  };

  const openLinkInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const openMicrosoftTeamsMeetingHandler = () => {
    /**
     * if Microsoft OAuth authenticated, join the meeting as authenticated user
     * else, ask user to continue as guest or authenticated user
     */
    if (isMicrosoftAuthenticated) {
      /** implement this when microsoft */
      // alert('Joining Teams Call for Authenticated Microsoft User is not yet implemented');
      startTeamsMeeting({ meetingUrl: getMeetingURL(schedule) });
    } else {
      openVideoCallUserSelectionDialog();
    }
  };

  /**
   * join as participant on the web
   */
  const joinAsParticipant = () => {
    if (FEATURE_FLAGS.zoomCall.isEnabled) {
      openZoomMeetingInComponentView();
    } else {
      openLinkInNewTab(getMeetingURL(schedule));
    }
    closeSidebar();
    closeJoinAsHostDialog();
  };

  /** notify join as participant on paired device */
  const notifyJoinAsParticipantOnPairedDevice = () => {
    const role = 0;
    const meetingLink = generateZoomExternalMeetingLink({
      meetingLink: getMeetingURL(schedule),
      role,
    });
    // zoomService.joinAsParticipantOnPairedDevice({ meetingLink });
    schedulesService.joinOnPairedDevice({ meetingLink });
  };

  /**
   * join as participant on paired device
   */
  const joinAsParticipantOnPairedDevice = () => {
    notifyJoinAsParticipantOnPairedDevice();
    dispatchSetSnackbar({ message: joinGoToMeetingHereMessage });
    closeSidebar();
    closeJoinAsHostDialog();
  };

  /**
   * generate a zoom external link, which can be used as a
   * - webview on android or windows
   * - on a new tab in browser
   * @param {*} data
   * @returns
   */
  const generateZoomExternalMeetingLink = (data) => {
    return data.meetingLink; /** TODO: remove this when Zoom SDK is in production */

    const { meetingLink, role } = data;
    const { meetingNumber, pwd } = zoomService.extractValuesFromUrl(meetingLink);
    const queryString = constructQueryParams({ meetingNumber, pwd, role });
    return `${config.APP_URL}/external-meetings/zoom?${queryString}`;
  };

  /**
   * notify the paired to join a zoom call as host
   */
  const notifyJoinAsHostOnPairedDevice = () => {
    const role = isAuthenticated ? 1 : 0;
    const meetingLink = generateZoomExternalMeetingLink({
      meetingLink: getMeetingURL(schedule),
      role,
    });
    // zoomService.joinAsHostOnPairedDevice({ meetingLink });
    schedulesService.joinOnPairedDevice({ meetingLink });
  };

  /** join as host on the web */
  const joinAsHost = async () => {
    if (isAuthenticated) {
      openZoomMeetingInComponentView();
      closeSidebar();
    } else {
      setZoomGoToMeetingDialogAsLastVisited({ meetingUrl: getMeetingURL(schedule) });
      initiateZoomOAuthAuthorization();
    }
  };

  /** go to meeting for general url on the same device */
  const joinGoToMeetingHere = () => {
    openLinkInNewTab(getMeetingURL(schedule));
    closeExternalJoinAsHostDialog();
    closeSidebar();
  };

  /** go to meeting for general url on the paired device */
  const joinGoToMeetingOnPairedDevice = () => {
    schedulesService.joinOnPairedDevice({ meetingLink: getMeetingURL(schedule) });
    dispatchSetSnackbar({ message: joinGoToMeetingHereMessage });
    closeExternalJoinAsHostDialog();
    closeSidebar();
  };

  /**
   * join as host on paired device
   */
  const joinAsHostOnPairedDevice = async () => {
    if (isAuthenticated) {
      notifyJoinAsHostOnPairedDevice();
      dispatchSetSnackbar({ message: joinGoToMeetingHereMessage });
      closeSidebar();
    } else {
      setZoomGoToMeetingDialogAsLastVisited({ meetingUrl: getMeetingURL(schedule) });
      initiateZoomOAuthAuthorization();
    }
  };

  /**
   * handle when go to meeting button is clicked
   */
  const goToMeeting = async () => {
    const meetingLink = getMeetingURL(schedule);
    const meetingType = checkMeetingLinkType(meetingLink);

    setIsGoToMeetingButtonLoading(true);
    const classId = getCanvasCode();
    const isPaired = await spacesService.isPaired(classId);

    switch (meetingType) {
      case 'ZOOM_MEETING':
        /**
         * A:
         * if (isPaired and remoteCall is enabled) open openJoinAsHostDialog
         * irrespective of zoomcall is enabled/disable, show openJoinAsHostDialog
         *
         * B:
         * if (is not paired)
         *  if zoomCall is not enabled
         *    openLinkInNewTab
         *
         *  if zoom call is enabled
         *    if isAuthenticated
         *      openZoomMeetingInComponentView
         *    else
         *      openJoinAsHostDialog
         *
         * if ()
         *
         * else
         * openJoinAsHostDialog
         */
        if (isPaired && FEATURE_FLAGS.remoteCall.isEnabled) {
          openJoinAsHostDialog();
          break;
        }

        if (!FEATURE_FLAGS.zoomCall.isEnabled) {
          openLinkInNewTab(meetingLink);
          break;
        }

        if (isAuthenticated) {
          openZoomMeetingInComponentView();
          closeSidebar();
        } else {
          openJoinAsHostDialog();
        }
        break;
      case 'MICROSOFT_TEAMS_MEETING':
        if (FEATURE_FLAGS.remoteCall.isEnabled && isPaired) {
          openExternalJoinAsHostDialog();
          break;
        }

        if (!FEATURE_FLAGS.msTeams.isEnabled) {
          openLinkInNewTab(meetingLink);
          break;
        }

        openMicrosoftTeamsMeetingHandler(meetingLink);
        break;
      default:
        if (FEATURE_FLAGS.remoteCall.isEnabled && isPaired) {
          openExternalJoinAsHostDialog();
          break;
        }

        joinGoToMeetingHere();
        break;
    }
    setIsGoToMeetingButtonLoading(false);
  };

  /**
   * delete schedule/meeting
   */
  const onSubmit = async () => {
    console.log('clicked');
    try {
      dispatchShowSpinner();
      await schedulesService.delete(schedule.id);
      dispatchRemoveOutlookImportedEvent({ id: schedule.meta.id });
      getSchedulesHandler();
      dispatchHideSpinner();
    } catch (error) {
      console.error('delete schedule error:', error);
      throw error;
    }
  };

  const isGoToMeetingButtonDisabled = checkIsMeetingDisabled(schedule);

  const dialogs = (
    <>
      <JoinAsHostDialogContainer
        isOpenDialog={isOpenJoinAsHostDialog}
        closeDialog={closeJoinAsHostDialog}
        joinAsParticipant={joinAsParticipant}
        joinAsParticipantOnPairedDevice={joinAsParticipantOnPairedDevice}
        joinAsHost={joinAsHost}
        joinAsHostOnPairedDevice={joinAsHostOnPairedDevice}
      />
      <ExternalJoinAsHostDialogContainer
        isOpenDialog={isOpenExternalJoinAsHostDialog}
        closeDialog={closeExternalJoinAsHostDialog}
        joinHere={joinGoToMeetingHere}
        joinOnPairedDevice={joinGoToMeetingOnPairedDevice}
      />
      <VideoCallUserSelectionDialogContainer
        schedule={schedule}
        isOpenDialog={isOpenVideoCallUserSelectionDialog}
        closeDialog={closeVideoCallUserSelectionDialog}
      />
    </>
  );

  return (
    <>
      <MeetingCard
        id={id}
        checkIsAllScheduleFilter={checkIsAllScheduleFilter}
        closeDeleteDialog={closeDeleteDialog}
        disableOptions={disableOptions}
        enableEditSchedule={enableEditSchedule}
        getSchedulesHandler={getSchedulesHandler}
        goToMeeting={goToMeeting}
        importToSpace={importToSpace}
        isGoToMeetingButtonDisabled={isGoToMeetingButtonDisabled}
        isGoToMeetingButtonLoading={isGoToMeetingButtonLoading}
        onSubmit={onSubmit}
        openDeleteDialog={openDeleteDialog}
        openDialog={openDialog}
        schedule={schedule}
        dateTimeFormat={dateTimeFormat}
      />
      {dialogs}
    </>
  );
};

export default MeetingCardContainer;
