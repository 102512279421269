import React from 'react';
import EmptyMessageLogo from '../../../assets/img/emptyMessageLogo.svg';
import classes from './empty-message.module.css';

const EmptyMessage = ({ size, text }) => (
  <div className={size == 'small' ? classes.EmptyMsgGridSmall : classes.EmptyMsgGrid}>
    <img src={EmptyMessageLogo} style={{ width: size === 'small' ? 100 : 200 }} />
    <div className={classes.MsgText}>{text}</div>
  </div>
);

export default EmptyMessage;
