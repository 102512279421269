import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Typography, makeStyles } from '@material-ui/core';

import { CHAT_TYPES, PERSONA_DETAILS } from '../../../constants';
import AgentCard from '../agent-list/agent-card';
import NotebookCard from './notebook-card';

const useStyles = makeStyles((theme) => ({
  threadCardRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 52,
    maxHeight: 110,
    overflow: 'hidden',
    textDecoration: 'none',
    paddingBottom: 4,
    flexShrink: 0,
    '& .i-title': {
      flexShrink: 0,
      maxHeight: 42,
      overflow: 'hidden',
      color: '#00000099',
      fontSize: 14,
      fontWeight: 500,
      textAlign: 'justify',
    },
    '& .i-answer': {
      fontSize: 14,
      color: '#00000066',
      flex: 1,
      maxHeight: 42,
      overflow: 'hidden',
    },
    '&:hover': {
      '&>.i-title': { color: theme.palette.primary.main },
    },
  },
}));

const ThreadCard = ({ name, id, notebookId, notebookName, messages, persona }) => {
  const classes = useStyles();

  const firstAnswer = useMemo(
    () =>
      messages.find(
        ({ question, type }) => question || [CHAT_TYPES.PDF, CHAT_TYPES.YOUTUBE].includes(type),
      )?.completion,
    [messages],
  );

  return (
    <Link to={`/controller/learn-ai/chats/${notebookId}/${id}`} className={classes.threadCardRoot}>
      <Typography className="i-title">{name}</Typography>
      <Typography className="i-answer">{firstAnswer}</Typography>
      <div style={{ display: 'flex', gap: 4, alignSelf: 'end' }}>
        <NotebookCard title={notebookName} />
        <AgentCard {...PERSONA_DETAILS[persona]} micro />
      </div>
    </Link>
  );
};

export default ThreadCard;
