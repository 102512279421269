import { makeStyles } from '@material-ui/core';

export const useEventTimeInfoStyles = makeStyles(() => ({
  container: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
  },
}));
