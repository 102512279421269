/**
 * @param {number} r
 * @param {number} g
 * @param {number} b
 */
function rgb2hex(r, g, b) {
    return ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}

/**
 * @param {string} userId
 */
export function getColorCode(userId) {
    // sum of ascii value of all character
    const sum = userId
        .split('')
        .reduce((a, b) => a + b.charCodeAt(0), 0)
    return "#" + rgb2hex(
        sum % 256,
        Math.floor(sum / 256) % 256,
        Math.floor(sum / 256 ** 2) % 256
    )
}