import React, { useEffect } from 'react';
import { useHuddleDispatcher } from '../../../store/dispatcher/useHuddleDispatcher';
import { useHuddleSelector } from '../../../store/selector/useHuddleSelector';
import { HuddleInvitation } from './component';
import { getCanvasCode } from '../../../Utils/authentication-access';
import { registerEvent } from '../../../store/service/socket.service';
import store from '../../../store';
import { EVENTS } from '../../../constants/events';

export const HuddleInvitationContainer = (props) => {
  const { updateToolAreaOnHuddleWindowOpen } = props;
  const { isHuddleInvitationOpen, user } = useHuddleSelector();
  const {
    dispatchHideHuddleInvitation,
    dispatchStartHuddle,
    dispatchSetHuddleOnline,
    dispatchHuddleEnded,
    dispatchHuddleStarted,
    dispatchShowHuddleInvitation,
    dispatchSetHuddleUserOffline,
  } = useHuddleDispatcher();
  const title = 'Huddle going on. Do you want to join?';
  const joinNowLabel = 'JOIN NOW';
  const joinLaterLabel = 'LATER';

  /**
   * this function is required,
   * because the callback doesn't have access to the user object returned from selector
   * @returns
   */
  const checkUserIsOnline = () => {
    const huddleUser = store.getState().huddle.user;
    return huddleUser.isOnline;
  };

  const handleHuddleInvitation = () => {
    console.log('registered, HUDDLE_INVITATION');
    registerEvent({
      type: 'HUDDLE_INVITATION',
      callback: (value) => {
        console.log('HUDDLE_INVITATION:', value);
        /**
         * Note:
         * The following extra check has been added because,
         * broadcast to other socket is not handled across different sockets
         * extra check of !user.isOnline is added to show huddle invitation to users who has not started or entered the huddle
         * TODO:
         * the following extra check (!user.isOnline) can be remove, if broadcast to other sockets is handled in huddle-server and socket-server
         */
        if (!checkUserIsOnline()) {
          console.log('showing huddle invitation since user has not started/entered huddle');
          dispatchSetHuddleOnline();
          dispatchShowHuddleInvitation();
        }
      },
    });
  };

  /**
   * use case:
   * when user click leave huddle in the BBB window
   * huddle should end for the current user
   */
  const handleLeftHuddleEvent = () => {
    console.log(`registered, ${EVENTS.LEFT_HUDDLE}`);
    registerEvent({
      type: EVENTS.LEFT_HUDDLE,
      callback: (value) => {
        console.log(`${EVENTS.LEFT_HUDDLE}:`, value);
        dispatchSetHuddleUserOffline();
      },
    });
  };

  const handleHuddleEndedEvent = () => {
    console.log('registered, HUDDLE_ENDED');
    registerEvent({
      type: 'HUDDLE_ENDED',
      callback: (value) => {
        console.log('HUDDLE_ENDED:', value);
        dispatchHuddleEnded();
      },
    });
  };

  const handleHuddleStartedEvent = () => {
    console.log(`registered, ${EVENTS.HUDDLE_STARTED}`);
    registerEvent({
      type: EVENTS.HUDDLE_STARTED,
      callback: (value) => {
        console.log(`${EVENTS.HUDDLE_STARTED}:`, value);
        dispatchHuddleStarted();
      },
    });
  };

  useEffect(() => {
    handleHuddleInvitation();
    handleHuddleStartedEvent();
    handleHuddleEndedEvent();
    handleLeftHuddleEvent();
  }, []);

  if (!isHuddleInvitationOpen) {
    return <></>;
  }

  const joinHuddle = () => {
    const canvasCode = getCanvasCode();
    dispatchStartHuddle({ canvasCode });
    updateToolAreaOnHuddleWindowOpen && updateToolAreaOnHuddleWindowOpen();
  };

  return (
    <HuddleInvitation
      title={title}
      hideHuddleInvitation={dispatchHideHuddleInvitation}
      joinNowLabel={joinNowLabel}
      joinLaterLabel={joinLaterLabel}
      joinHuddle={joinHuddle}
    />
  );
};
