import { useSelector } from 'react-redux';

export const useHuddleSelector = () => {
  const bbbUrl = useSelector((state) => state.huddle.url);
  const isHuddleOnline = useSelector((state) => state.huddle.isOnline);
  const user = useSelector(state => state.huddle.user);
  const isHuddleInvitationOpen = useSelector((state) => state.huddle.isHuddleInvitationOpen);
  const isViewOpen = useSelector((state) => state.huddle.isViewOpen);
  const huddleDuration = useSelector((state) => state.huddle.huddleDuration);
  return { bbbUrl, isHuddleOnline, user, isHuddleInvitationOpen, isViewOpen, huddleDuration };
};
