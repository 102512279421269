import ACTION_TYPES from './type.action';

export const reloadSpaces = (count) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.FETCH_SPACELIST,
    payload: count,
  });
};

export const activeTeamMembersInfo = (activeTeamMembers) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ACTIVE_TEAM_MEMBERS_COUNT,
    payload: activeTeamMembers,
  });
};


export const activeSpaceInfo = (activeSpace) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ACTIVE_SPACE_INFO,
    payload: activeSpace,
  });
};