import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { emitEvent } from '../store/service/socket.service';

export const useMouseSend = () => {
  const { x, y, pageId } = useSelector((state) => state.mouseReducer.mousePosition);

  useEffect(() => {
    emitEvent({
      type: 'update-cursor',
      data: {
        mousePosX: x,
        mousePosY: y,
      },
      pageId,
    });
  }, [x, y, pageId]);
};
