import React from 'react';

export const ScreenShareToolIcon = (props) => {
  let { color } = props;
  let fill = 'white';
  let fillInvert = 'black';
  if (color === 'white') {
    fill = 'white';
    fillInvert = 'black';
  }

  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4901 6.52051C10.0364 6.52048 9.74784 6.52046 9.49568 6.54529C7.05255 6.78592 5.11963 8.71884 4.879 11.162C4.85416 11.4141 4.85419 11.7027 4.85422 12.1564L4.85422 12.208C4.85422 12.6567 5.21799 13.0205 5.66672 13.0205C6.11545 13.0205 6.47922 12.6567 6.47922 12.208C6.47922 11.6856 6.48021 11.4834 6.49618 11.3212C6.66082 9.64963 7.98334 8.32711 9.65495 8.16247C9.81708 8.1465 10.0194 8.14551 10.5417 8.14551H18.6667C20.7324 8.14551 22.2 8.14724 23.3133 8.29692C24.4032 8.44346 25.0312 8.71826 25.4897 9.17674C25.9481 9.63522 26.2229 10.2632 26.3695 11.3531C26.5192 12.4664 26.5209 13.934 26.5209 15.9997C26.5209 18.0654 26.5192 19.533 26.3695 20.6463C26.2229 21.7362 25.9481 22.3641 25.4897 22.8226C25.0312 23.2811 24.4032 23.5559 23.3133 23.7024C22.2 23.8521 20.7324 23.8538 18.6667 23.8538C18.218 23.8538 17.8542 24.2176 17.8542 24.6663C17.8542 25.1151 18.218 25.4788 18.6667 25.4788H18.7278C20.7187 25.4789 22.2957 25.4789 23.5298 25.3129C24.8 25.1422 25.828 24.7824 26.6387 23.9717C27.4494 23.1609 27.8092 22.1329 27.98 20.8628C28.1459 19.6286 28.1459 18.0517 28.1459 16.0608V15.9386C28.1459 13.9477 28.1459 12.3707 27.98 11.1366C27.8092 9.86645 27.4494 8.83842 26.6387 8.02769C25.828 7.21697 24.8 6.85717 23.5298 6.68641C22.2957 6.52048 20.7187 6.5205 18.7278 6.52051L10.4901 6.52051Z"
        fill="black"
      />
      <path
        d="M5.66672 14.1038C5.21799 14.1038 4.85422 14.4676 4.85422 14.9163C4.85422 15.3651 5.21799 15.7288 5.66672 15.7288C10.6028 15.7288 14.6042 19.7303 14.6042 24.6663C14.6042 25.1151 14.968 25.4788 15.4167 25.4788C15.8655 25.4788 16.2292 25.1151 16.2292 24.6663C16.2292 18.8328 11.5002 14.1038 5.66672 14.1038Z"
        fill="black"
      />
      <path
        d="M4.85422 18.1663C4.85422 17.7176 5.21799 17.3538 5.66672 17.3538C9.70531 17.3538 12.9792 20.6278 12.9792 24.6663C12.9792 25.1151 12.6155 25.4788 12.1667 25.4788C11.718 25.4788 11.3542 25.1151 11.3542 24.6663C11.3542 21.5252 8.80784 18.9788 5.66672 18.9788C5.21799 18.9788 4.85422 18.6151 4.85422 18.1663Z"
        fill="black"
      />
      <path
        d="M5.66672 20.6038C5.21799 20.6038 4.85422 20.9676 4.85422 21.4163C4.85422 21.8651 5.21799 22.2288 5.66672 22.2288C7.01292 22.2288 8.10422 23.3202 8.10422 24.6663C8.10422 25.1151 8.46799 25.4788 8.91672 25.4788C9.36545 25.4788 9.72922 25.1151 9.72922 24.6663C9.72922 22.4227 7.91038 20.6038 5.66672 20.6038Z"
        fill="black"
      />
    </svg>
  );
};
