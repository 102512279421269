import React from "react";
import { Route } from "react-router-dom";
import { getToken } from "./authentication-access";
import RedirectToWebsite from "./RedirectToWebsite";

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={
      props =>
        getToken() ? <Component {...props} {...rest} /> : <RedirectToWebsite {...props} />

      // <Redirect to={{ pathname: "/", state: { from: props.location } }} />
    }
  />
);
