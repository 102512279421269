import React from "react";
import StrokeEraseImg from '../assets/img/stroke_erase.svg';
import ClearAllImg from '../assets/img/canvas_clear.svg';


class EraseSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }

        }
 

      

  render() {
   
   

    return (
      <div className="erase-popup">
       <div className="erase-grid" >
           <div></div>
           <div className="strokeeraser-grid" onClick={this.props.strokeErase}>
           <img src={StrokeEraseImg}></img>
           <div className="title" > Stroke Eraser</div>
           </div>
           <img className="cursor-pointer" src={ClearAllImg} onClick={this.props.clearPage}></img>

       </div>
      </div>
    );
 }
}


export default EraseSelect;
