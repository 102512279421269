import ACTION_TYPES from '../actions/type.action';

const initialState = {
  outlookImportedEvents: [],
};

const schedulesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPES.SET_OUTLOOK_IMPORTED_EVENTS:
      return {
        ...state,
        outlookImportedEvents: payload,
      };
    case ACTION_TYPES.RESET_OUTLOOK_IMPORTED_EVENTS:
      return {
        ...state,
        outlookImportedEvents: [],
      };
    case ACTION_TYPES.ADD_OUTLOOK_IMPORTED_EVENT:
      return {
        ...state,
        outlookImportedEvents: [...state.outlookImportedEvents, { ...payload }],
      };
    case ACTION_TYPES.REMOVE_OUTLOOK_IMPORTED_EVENT:
      return {
        ...state,
        outlookImportedEvents: state.outlookImportedEvents.filter(
          (event) => event.id !== payload.id,
        ),
      };

    default:
      return state;
  }
};

export default schedulesReducer;
