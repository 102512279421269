import BaseService from './base.service';
import { BIRDAI_SERVER_URL } from '../config';
import { post, getWithHeader, put, del, get } from '../Utils/api';
import { MEMBER, SPACES } from '../Utils/url-builder';
class SpacesService extends BaseService {
  constructor() {
    super(`${BIRDAI_SERVER_URL}/spaces`);
  }

  async getPairedStatus(classId) {
    return this.makeGetRequest(`/paired-status/${classId}`);
  }

  async isPaired(classId) {
    const pairedStatusData = await this.getPairedStatus(classId);
    const {
      data: { pairedStatus },
    } = pairedStatusData;
    return pairedStatus;
  }

  /**
   * @param {{ classId: string, hardware: string }} data
   * @returns
   */
  async pairClass(data) {
    const url = `/pair-class`;
    return this.makePostRequest(url, data);
  }

  /**
   *
   * @param {{ classId: string, hardware: string }} data
   * @returns
   */
  async unpairClass(data) {
    const url = `/unpair-class`;
    return this.makePostRequest(url, data);
  }

  createSpace = async (spaceName, getworkspaceID) => {
    try {
      const url = SPACES.postSpaces();
      const response = await post(url, {
        name: spaceName,
        workspaceId: getworkspaceID,
      });

      return response;
    } catch (error) {
      console.log('create space error', error);
      throw error;
    }
  };

  getSpaces = async (getworkspaceID) => {
    try {
      const fetchSpaceUrl = SPACES.fetchSpaces(getworkspaceID);
      const response = await getWithHeader(fetchSpaceUrl);
      return response;
    } catch (error) {
      console.log('get spaces error', error);
    }
  };

  renameSpace = async (spaceID, renameSpaceBoardInput) => {
    try {
      let url = SPACES.renameSpaces();
      const response = await put(url, {
        spaceId: spaceID,
        name: renameSpaceBoardInput,
      });
      return response;
    } catch (error) {
      console.log('rename board error', error);
    }
  };

  deleteSpace = async (spaceID) => {
    try {
      let url = SPACES.deleteSpaces();
      const response = await del(url, { spaceId: spaceID });
      return response;
    } catch (error) {
      console.log('delete board error', error);
    }
  };

  membersList = async (spaceID) => {
    try {
      let url = MEMBER.spaceMemberslist(spaceID);
      const response = await get(url);
      return response;
    } catch (error) {
      console.log('members list error', error);
    }
  };

  removeMemberFromSpace = async (userID, spaceID) => {
    try {
      let url = MEMBER.removeMemberAccess();
      const response = await del(url, { spaceId: spaceID, userIds: userID });
      return response;
    } catch (error) {
      console.log('remove members from team error', error);
    }
  };

  addMembersToSpace = async (spaceID, userData) => {
    try {
      let url = MEMBER.addMemberToSpace();
      const response = await put(url, { spaceId: spaceID, data: userData });
      return response;
    } catch (error) {
      console.log('add members to team error', error);
    }
  };

  getSingleSpaceInfo = async (spaceId) => {
    try {
      const url = SPACES.singleSpaceInfo(spaceId);
      return await get(url);
    } catch (error) {
      console.log('single Team Info error', error);
      throw error;
    }
  };
}

export const spacesService = new SpacesService();
