import React from 'react';
import PromptRevokeAccessDialog from './component';

const PromptRevokeAccessDialogContainer = (props) => {
  const title = 'Revoke Access';
  const { isOpenDialog, closeDialog, onClickRevokeAccess } = props;

  return (
    <PromptRevokeAccessDialog
      isOpenDialog={isOpenDialog}
      closeDialog={closeDialog}
      title={title}
      onClickRevokeAccess={onClickRevokeAccess}
    />
  );
};

export default PromptRevokeAccessDialogContainer;
