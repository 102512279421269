import React from 'react';
import { ScreenshareDisplayContentContainer } from '../../../../../components/webrtc/screenshare/display-content-container/container';

export const LayoutScreenshareContentContainer = (props) => {
  const { mediaClient } = props;

  /**
   * Screenshare display
   * The start/stop screenshare button is displayed on the whiteboard page
   * Check in the Layout Component for the start/stop buttons
   *
   * Note:
   * the following components are separated
   * - the screenshare display
   * - The start/stop button
   * The reason is that is we select whiteboard option from toolbar
   * since, the start/stop button is hidden
   * the screenshare display gets hidden
   * hence, the screenshare display is separated as a fix for this issue
   */
  const displayContentContainer = (
    <ScreenshareDisplayContentContainer
      isViewOnly={mediaClient.isViewOnly}
      localSubscribedData={mediaClient.localSubscribedData}
      showRemoteVideo={mediaClient.showRemoteVideo}
      subscribedData={mediaClient.subscribedData}
    />
  );

  return <>{displayContentContainer}</>;
};
