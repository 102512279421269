import React from 'react';
import { makeStyles } from '@material-ui/core';
import StyledDialog from '../../../../UIComponents/modal/StyledDialog';

import FeatureLockedContainer from '../FeatureLocked/FeatureLockedContainer';

const useStyles = makeStyles((theme) => ({}));

const FeatureLockedDialogComponent = (props) => {
  const classes = useStyles();
  const { isOpen, onClose, headerTitle, feature } = props;

  return (
    <div>
      <StyledDialog
        fullWidth={true}
        maxWidth={'sm'}
        className="overlay"
        open={isOpen}
        onClose={onClose}
        title={headerTitle}
        hideFooter={true}
      >
        <FeatureLockedContainer feature={feature} />
      </StyledDialog>
    </div>
  );
};

export default FeatureLockedDialogComponent;
