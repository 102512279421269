import { HUDDLE_SERVER_URL } from '../config';
import { post } from '../Utils/api';
import { getCanvasCode, getToken } from '../Utils/authentication-access';
import { HUDDLE } from '../Utils/url-builder';
import BaseService from './base.service';

class HuddleService extends BaseService {
  constructor() {
    super(HUDDLE_SERVER_URL);
  }

  /**
   *
   * @param {{ canvasCode: string }} param0
   * @returns
   */
  async start({ canvasCode }) {
    const path = '/huddle/start';
    const data = {
      canvasCode,
      accessToken: getToken(),
    };
    return this.makePostRequest(path, data);
  }

  /**
   *
   * @param {{ canvasCode: string }} param0
   * @returns
   */
  async getStatus({ canvasCode }) {
    const path = `/huddle/${canvasCode}/status`;
    return this.makeGetRequest(path);
  }

  async startOnPairedDevice() {
    const url = HUDDLE.startOnPairedDevice();
    const classId = getCanvasCode();
    const data = {
      classId,
    };
    return post(url, data);
  }
}

const huddleService = new HuddleService();
export default huddleService;
