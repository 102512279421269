import { makeStyles, styled } from '@material-ui/core';

export const useCalendarSelectionStyle = makeStyles(() => ({
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px',
  },
  horizontalLine: {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
    border: '0.5px solid #E0E0E0',
  },
}));

export const ContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '30rem',
  zIndex: 1,
});

export const Tabs = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 1.25rem',
});
