import React from 'react';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { ViewOptionsPosition, ControlItem, useDisplayViewOptionsStyle } from './component.style';

export const DisplayViewOptions = (props) => {
  const classes = useDisplayViewOptionsStyle();

  const {
    bbbWindowWidth,
    closeBBBViewer,
    isFullScreen,
    isUserOnline,
    isViewOpen,
    onChangeFullScreen,
    openBBBViewer,
    showFullScreen,
    draggableConfig,
    showDraggableOption,
    showFullScreenOption,
    isMinimalizedUI,
    isContentUIDisplayed,
    displayMediumUI,
    displayMinimalUI,
    displayContentUI,
    hideContentUI,
    showHideOrShowContentUIButtonOption,
    showMinimalizedOrMediumUIButtonOption,
  } = props;

  const displayFullScreenButton = showFullScreenOption ? (
    <div className={classes.fullScreenButton}>
      <FullscreenIcon onClick={showFullScreen} />
    </div>
  ) : (
    <></>
  );

  const draggableHandleButton = showDraggableOption ? (
    <ControlItem className="cursor drag-handle" cursor="move">
      <DragIndicatorIcon style={{ color: 'white' }} />
    </ControlItem>
  ) : (
    <></>
  );

  const displayMinimalizeUIButton = (
    <ControlItem className="cursor huddle-control">
      <KeyboardArrowUpIcon style={{ color: 'white' }} onClick={displayMinimalUI} />
    </ControlItem>
  );

  const displayMediumUIButton = (
    <ControlItem className="cursor huddle-control">
      <KeyboardArrowDownIcon style={{ color: 'white' }} onClick={displayMediumUI} />
    </ControlItem>
  );

  const minimalizedOrMediumUIButton = isMinimalizedUI
    ? displayMediumUIButton
    : displayMinimalizeUIButton;

  const hideContentUIButton = (
    <ControlItem className="cursor huddle-control">
      <VisibilityIcon style={{ color: 'white' }} onClick={hideContentUI} />
    </ControlItem>
  );

  const showContentUIButton = (
    <ControlItem className="cursor huddle-control">
      <VisibilityOffIcon style={{ color: 'white' }} onClick={displayContentUI} />
    </ControlItem>
  );

  const hideOrShowContentUIButton = isContentUIDisplayed
    ? hideContentUIButton
    : showContentUIButton;

  let customViewOptionsPositionStyle = {};

  if (!isContentUIDisplayed) {
    customViewOptionsPositionStyle = {
      borderRadius: '0.5rem',
    };
  }

  return (
    <ViewOptionsPosition
      isContentUIDisplayed={isContentUIDisplayed}
      style={customViewOptionsPositionStyle}
    >
      <div className={classes.viewOptionsLayout}>
        {draggableHandleButton}
        {displayFullScreenButton}
        {showHideOrShowContentUIButtonOption && hideOrShowContentUIButton}
        {showMinimalizedOrMediumUIButtonOption && minimalizedOrMediumUIButton}
      </div>
    </ViewOptionsPosition>
  );
};
