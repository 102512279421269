import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';

const Loader = ({ openSpinner }) => {
  return (
    <>
      <Backdrop
        style={{
          position: 'fixed',
          zIndex: 9999,
        }}
        open={openSpinner}
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default Loader;
