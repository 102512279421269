import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  makeStyles,
} from '@material-ui/core';
import { teacherTypeMap } from '../../../constants';

const useStyles = makeStyles({
  teacherList: {
    width: 210,
    '& > .MuiFormLabel-root': {
      paddingBottom: 12,
      fontSize: 14,
      fontWeight: 700,
      color: '#00000061',
    },
    '& .MuiFormGroup-root': {
      gap: 6,
      '& .MuiFormControlLabel-root': {
        margin: 0,
        border: '1px solid #E4E4E5',
        borderRadius: 4,
        padding: '0 8px',
        minWidth: 216,
        '&:hover': {
          backgroundColor: '#0000000a',
        },
        '& span': {
          fontSize: 14,
          fontWeight: 700,
        },
      },
    },
  },
  otherTeachersButton: {
    fontSize: 14,
    fontWeight: 700,
    padding: 8,
    border: '1px solid #E4E4E5',
  },
});

export const TeacherList = ({ teacherType, onTeacherTypeChange }) => {
  const classes = useStyles();
  const [hideOtherTeachers, setHideOtherTeachers] = useState(true);

  const showOtherTeachers = () => setHideOtherTeachers(false);
  const handleChangeTeacherType = (e) => onTeacherTypeChange(e.target.value);

  return (
    <FormControl className={classes.teacherList}>
      <FormLabel>Learn From</FormLabel>
      <RadioGroup name="teacher-type" value={teacherType} onChange={handleChangeTeacherType}>
        {Object.entries(teacherTypeMap)
          .slice(0, hideOtherTeachers ? 4 : undefined)
          .map(([type, label]) => (
            <FormControlLabel
              key={type}
              value={type}
              label={label}
              control={<Radio color="primary" />}
            />
          ))}
        {hideOtherTeachers && (
          <Button
            variant="outlined"
            onClick={showOtherTeachers}
            className={classes.otherTeachersButton}
            endIcon={<img src="/icons/arrow-down.svg" alt="arrow-down" />}
          >
            Select Other Teachers
          </Button>
        )}
      </RadioGroup>
    </FormControl>
  );
};

export default TeacherList;
