import React from 'react';
import { DocumentSelectImportDialog } from './component';

export const DocumentSelectImportDialogContainer = (props) => {
  const { onJustImportClick, onImportAndExpandClick, isOpen, closeDialog } = props;
  const dialogTitle = 'Import file';
  const description = 'Do you want to view the the document or import the document to canvas';
  const importButtonLabel = 'VIEW';
  const importAndExpandButtonLabel = 'IMPORT AND EXPAND';

  return (
    <DocumentSelectImportDialog
      dialogTitle={dialogTitle}
      description={description}
      isOpen={isOpen}
      closeDialog={closeDialog}
      importAndExpandButtonLabel={importAndExpandButtonLabel}
      importButtonLabel={importButtonLabel}
      onImportAndExpandClick={onImportAndExpandClick}
      onJustImportClick={onJustImportClick}
    />
  );
};
