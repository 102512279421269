import React from 'react';

const ShareScreenIcon = (props) => {
  let fill = props.fill ? props.fill : '#000';
  let fillOpacity = props.fill ? 1 : 0.38;

  return (
    // <svg width="66" height="67" viewBox="0 0 66 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M20.0351 49.232H12.6025C9.5233 49.232 7.02795 46.7971 7.02795 43.7931V20.2225C7.02795 17.218 9.5233 14.7832 12.6025 14.7832H53.4814C56.5601 14.7832 59.0559 17.218 59.0559 20.2225V43.7931C59.0559 46.7971 56.5601 49.232 53.4814 49.232H46.0488V50.6463C46.0488 52.8692 44.2019 54.6713 41.9217 54.6713H24.1611C21.8814 54.6713 20.0343 52.8692 20.0343 50.6463L20.0351 49.232ZM23.7513 49.232V50.6463C23.7513 50.8659 23.9352 51.0453 24.1619 51.0453H41.9224C42.1491 51.0453 42.3335 50.8659 42.3335 50.6463V49.232H23.7513ZM33.9091 27.7902L37.551 32.6461C37.8384 33.0292 38.3819 33.1069 38.765 32.8195C39.1481 32.5322 39.2258 31.9887 38.9384 31.6056L35.123 26.5184C34.0825 25.131 32.0014 25.131 30.9608 26.5184L27.1454 31.6056C26.8581 31.9887 26.9357 32.5322 27.3189 32.8195C27.702 33.1069 28.2455 33.0292 28.5328 32.6461L32.1748 27.7902V39.0629C32.1748 39.5418 32.563 39.93 33.0419 39.93C33.5208 39.93 33.9091 39.5418 33.9091 39.0629V27.7902Z"
    //     fill={fill}
    //   />
    // </svg>

    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.6667 29.1573C32.1631 28.916 32.5949 28.6118 32.9783 28.2283C34.8333 26.3734 34.8333 23.3878 34.8333 17.4167C34.8333 11.4455 34.8333 8.45998 32.9783 6.60499C31.1234 4.75 28.1378 4.75 22.1667 4.75H15.8333C9.86221 4.75 6.87665 4.75 5.02166 6.60499C3.16667 8.45998 3.16667 11.4455 3.16667 17.4167C3.16667 23.3878 3.16667 26.3734 5.02166 28.2283C5.40511 28.6118 5.83687 28.916 6.33333 29.1573"
        stroke="white"
        stroke-linecap="round"
      />
      <path
        d="M15.7535 25.4129C17.2276 23.9387 17.9647 23.2016 18.8748 23.1537C18.958 23.1493 19.0414 23.1493 19.1246 23.1537C20.0347 23.2016 20.7718 23.9387 22.2459 25.4129C25.4535 28.6205 27.0573 30.2243 26.6083 31.608C26.5697 31.7269 26.5218 31.8426 26.465 31.954C25.804 33.25 23.5359 33.25 18.9997 33.25C14.4635 33.25 12.1954 33.25 11.5344 31.954C11.4776 31.8426 11.4297 31.7269 11.3911 31.608C10.9421 30.2243 12.5459 28.6205 15.7535 25.4129Z"
        stroke="white"
      />
    </svg>
  );
};

export default ShareScreenIcon;
