import React from 'react';
import { SidebarGroup } from '../sidebar-group/container';
import { SidebarItem } from '../sidebar-item/container';

export const SidebarWorkspaceGroupComponent = (props) => {
  const { onClickInviteToWorkspace, inviteToWorkspaceLabel } = props;

  return (
    <>
      <SidebarGroup>
        <SidebarItem onClick={onClickInviteToWorkspace}>{inviteToWorkspaceLabel}</SidebarItem>
      </SidebarGroup>
    </>
  );
};
