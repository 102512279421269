import React, { useCallback, useEffect, useRef } from 'react';
import { Button, Typography, makeStyles } from '@material-ui/core';

import AgentAvatar from '../agent-avatar';
import { PERSONA_DETAILS } from '../../../constants';
import { FileCopyOutlined as FileCopyICon } from '@material-ui/icons';

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '36px 1fr',
    justifyItems: 'start',
    gap: 12,
    padding: '20px 8px',
    backgroundColor: '#EAEEFF66',
    border: '1px solid #335AFB33',
    borderWidth: '1px 0px',
    '& .i-avatar': {
      height: 36,
      width: 36,
    },
    '& .i-content': {
      fontSize: 14,
      whiteSpace: 'pre-line',
    },
    '& .i-copy-button': {
      gridColumnEnd: 'span 2',
      marginLeft: 36,
      fontSize: 10,
      backgroundColor: '#EEE',
      color: '#767676',
    },
  },
});

const AIChat = ({ persona, content, isLoading, onAddToLesson }) => {
  const containerRef = useRef();
  const classes = useStyles();

  const scrollIntoView = useCallback((ref) => {
    if (ref) {
      ref.scrollIntoView();
      containerRef.current = ref;
    }
  }, []);

  useEffect(() => {
    containerRef.current.scrollIntoView(false);
  }, [content]);

  const onCopyToLesson = useCallback(() => onAddToLesson && onAddToLesson(content), []);

  return (
    <div className={classes.root} ref={scrollIntoView}>
      <AgentAvatar {...PERSONA_DETAILS[persona]} />
      <Typography className="i-content" component="pre">
        {content}
      </Typography>
      {!isLoading && (
        <Button className="i-copy-button" startIcon={<FileCopyICon />} onClick={onCopyToLesson}>
          Copy to Lesson
        </Button>
      )}
    </div>
  );
};

export default React.memo(AIChat);
