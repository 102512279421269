import React, { useCallback, useState } from 'react';
import { Button, CircularProgress, TextField, Typography, makeStyles } from '@material-ui/core';

import { PERSONA_DETAILS, PERSONA_TYPES, QUERY_ACTION_TYPES } from '../../../constants';
import PrimaryActionButton from './primary-action-button';
import SecondaryActionButton from './secondary-action-button';
import { uploadIcon } from '../../../assets/icons';
import AgentAvatar from '../agent-avatar';
// import StopButton from './stop-button';

const useStyles = ({ chatExpanded, loading }) =>
  makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      columnGap: 16,
      rowGap: 4,
      marginBottom: chatExpanded ? 0 : 16,
      marginTop: chatExpanded ? 8 : 0,
      borderRadius: chatExpanded ? undefined : 8,
      border: chatExpanded ? undefined : '1px solid #335AFB',
      padding: chatExpanded ? undefined : '20px 24px',
      pointerEvents: loading ? 'none' : undefined,
      '& .avatar-container': {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        '& .MuiTypography-root': {
          fontSize: 14,
          color: '#767676',
        },
      },
    },
    container: {
      position: 'relative',
      flexGrow: 1,
      flexShrink: 0,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      '& .textField-container': {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        borderRadius: 8,
        border: chatExpanded ? `1px solid ${theme.palette.primary.main}` : 'none',
        padding: chatExpanded ? '12px 24px' : undefined,
        backgroundColor: '#fff',
        position: 'relative',
        zIndex: 1,
        '& .MuiButton-root': {
          boxShadow: 'none',
          height: 32,
          borderRadius: 16,
        },
      },
      '& .i-loader': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 30,
        height: 30,
        zIndex: 1,
      },
    },
    queryInput: {
      width: '100%',
      marginBottom: 0,
      '& .MuiInputBase-root': {
        backgroundColor: '#fff',
      },
      '& fieldset': { borderColor: theme.palette.primary.main },
    },
    primaryActionsContainer: {
      width: '100%',
      height: 28,
      marginTop: -4,
      position: 'relative',
      pointerEvents: loading ? 'none' : 'all',
      display: chatExpanded ? 'flex' : 'none',
      [theme.breakpoints.down('md')]: { display: 'flex' },
      alignItems: 'center',
      background:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #335AFB',
      color: theme.palette.primary.main,
      borderRadius: 4,
      '& .MuiButtonBase-root': {
        height: 'calc(100% - 8px)',
        padding: '0 8px',
        fontSize: 10,
      },
    },
    secondaryActionsContainer: {
      display: chatExpanded ? 'flex' : 'none',
      pointerEvents: loading ? 'none' : 'all',
      flexWrap: 'wrap',
      alignItems: 'center',
      gap: 8,
      width: '100%',
      '& .i-clear-chat': {
        fontSize: 10,
        padding: '4px 8px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      [theme.breakpoints.down('sm')]: {
        gap: 4,
      },
    },
    ripple: {
      position: 'absolute',
      borderRadius: '50%',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      width: 0,
      height: 0,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      animation: '$ripple 1.5s infinite',
    },
    '@keyframes ripple': {
      '0%': {
        width: 0,
        height: 0,
        opacity: 1,
      },
      '50%': {
        width: '200%',
        height: '200%',
        opacity: 0,
      },
      '100%': {
        width: '200%',
        height: '200%',
        opacity: 0,
      },
    },
  }));

export const QueryInput = ({
  onSend,
  onAction,
  actionsEnabled,
  pdfUploadHandler,
  loading,
  chatExpanded,
  chatStarted,
  disabled,
  uploadPdfEnabled,
  language,
}) => {
  const classes = useStyles({
    chatExpanded,
    loading,
  })();
  const [query, setQuery] = useState('');

  const queryChangeHandler = useCallback((e) => setQuery(e.target.value), []);

  const sendHandler = useCallback(() => {
    onSend({ query });
    setQuery('');
  }, [onSend, query]);

  const keyDownHandler = useCallback(
    (e) => {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        if (!disabled && !loading && query) sendHandler();
      }
    },
    [disabled, loading, query, sendHandler],
  );

  return (
    <div className={classes.root}>
      {!chatExpanded && (
        <div className="avatar-container">
          <AgentAvatar {...PERSONA_DETAILS[PERSONA_TYPES.GENERAL]} />
          <Typography>{PERSONA_DETAILS[PERSONA_TYPES.GENERAL].title}</Typography>
        </div>
      )}
      <div className={classes.secondaryActionsContainer}>
        {Object.entries(QUERY_ACTION_TYPES).map(([action, { title, iconSrc }]) => (
          <SecondaryActionButton
            key={action}
            onClick={() => onAction(action)}
            iconSrc={iconSrc}
            title={title}
            alt={title}
            disabled={!actionsEnabled || window.location.toString().includes('new-thread')}
          />
        ))}
        {/* {loading && <StopButton />} */}
      </div>
      <div className={classes.container}>
        <div className="textField-container">
          <TextField
            className={classes.queryInput}
            onKeyDown={keyDownHandler}
            multiline
            maxRows={3}
            placeholder={chatStarted ? 'Ask follow-up...' : 'How can I assist you today?'}
            value={query}
            onChange={queryChangeHandler}
            InputProps={{ disableUnderline: true }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={loading || !query ? undefined : sendHandler}
            style={{ cursor: loading ? 'wait' : undefined }}
            disableRipple
            disabled={!query || loading || disabled}
          >
            Send
          </Button>
        </div>
        {uploadPdfEnabled && (
          <div className={classes.primaryActionsContainer}>
            <label htmlFor="pdf-query-file">
              <PrimaryActionButton
                iconSrc={uploadIcon}
                alt="upload"
                title="Upload PDF"
                component="span"
                small
                disabled={!uploadPdfEnabled}
              />
            </label>
          </div>
        )}
        {loading && (
          <div className="i-loader">
            <CircularProgress size={30} />
          </div>
        )}
      </div>
      <input
        id="pdf-query-file"
        type="file"
        accept="application/pdf"
        style={{ display: 'none' }}
        onChange={pdfUploadHandler}
      />
    </div>
  );
};

export default React.memo(QueryInput);
