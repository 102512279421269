export const iconTypes = {
  PDF: 'document/pdf',
  PDF2: 'application/pdf',
  EXCEL: 'document/xlsx',
  EXCEL2: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PPT: 'document/ppt',
  PPT2: 'application/vnd.ms-powerpoint',
  PPTX: 'document/pptx',
  PPTX2: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  YOUTUBE: 'youtube',
  VIDEO: 'video',
};
class IconClass {
  constructor(_height = 120, _width = 120) {
    this.height = _height;
    this.width = _width;
    this.fileNameLength = 13;
  }
  generateIcons(type, obj) {
    if (type.includes(iconTypes.VIDEO)) {
      return this.videoIcon({ ...obj });
    } else {
      switch (type) {
        case iconTypes.PDF:
        case iconTypes.PDF2:
          return this.pdfIcon(obj);
        case iconTypes.EXCEL:
        case iconTypes.EXCEL2:
          return this.excelIcon(obj);
        case iconTypes.PPT:
        case iconTypes.PPT2:
        case iconTypes.PPTX:
        case iconTypes.PPTX2:
          return this.pptIcon(obj);
        case iconTypes.YOUTUBE:
          return this.videoIcon({ ...obj });
        default:
          return this.documentIcon(obj);
      }
    }
  }
  pdfIcon = ({ fileName = 'document', height, width }) => {
    fileName =
      fileName.length > this.fileNameLength
        ? fileName.slice(0, this.fileNameLength) + '...'
        : fileName;
    return `<svg xmlns="http://www.w3.org/2000/svg" width="${width || this.width}" height="${
      height || this.height
    }" viewBox="0 0 128 128" fill="none">
  <g filter="url(#filter0_d)">
    <rect x="4" y="3" width="120" height="120" fill="#C4C4C4"></rect>
    <rect x="4" y="83" width="120" height="40" fill="white"></rect>
    <rect x="4" y="3" width="120" height="80" fill="#F9F9F9"></rect>
    <path d="M38.2715 8.69501H74.7054L94.017 27.9316V73.0169C94.017 75.386 92.096 77.305 89.7289 77.305H38.2715C35.9044 77.305 33.9834 75.386 33.9834 73.0169V12.9831C33.9834 10.614 35.9046 8.69501 38.2715 8.69501Z" fill="#E2574C" fill-opacity="0.75"></path>
    <path d="M93.9546 27.9909H79.0083C76.6412 27.9909 74.7202 26.0699 74.7202 23.7028V8.73724L93.9546 27.9909Z" fill="#B53629" fill-opacity="0.25"></path>
    <path d="M51.5388 46.8855V51.5783H49.0133V38.9327H53.6165C55.1092 38.9327 56.2316 39.291 56.9839 40.0074C57.7481 40.7239 58.1302 41.6971 58.1302 42.927C58.1302 44.1331 57.7362 45.0943 56.9481 45.8108C56.1719 46.5272 55.0614 46.8855 53.6165 46.8855H51.5388ZM53.3479 44.8615C54.8285 44.8615 55.5689 44.2166 55.5689 42.927C55.5689 42.2941 55.3957 41.8105 55.0495 41.4762C54.7032 41.1418 54.136 40.9747 53.3479 40.9747H51.5388V44.8615H53.3479Z" fill="white"></path>
    <path d="M70.8938 45.2376C70.8938 46.4914 70.6311 47.596 70.1057 48.5512C69.5803 49.5065 68.816 50.2528 67.813 50.7902C66.8219 51.3156 65.6397 51.5783 64.2665 51.5783H59.7707V38.9327H64.2665C65.6397 38.9327 66.8219 39.1954 67.813 39.7208C68.816 40.2343 69.5803 40.9687 70.1057 41.924C70.6311 42.8673 70.8938 43.9719 70.8938 45.2376ZM64.0516 49.3214C65.4128 49.3214 66.4636 48.9632 67.204 48.2467C67.9563 47.5303 68.3324 46.5272 68.3324 45.2376C68.3324 43.936 67.9563 42.927 67.204 42.2106C66.4636 41.4941 65.4128 41.1359 64.0516 41.1359H62.2962V49.3214H64.0516Z" fill="white"></path>
    <path d="M80.6298 38.9327V40.9388H75.3101V44.3241H79.573V46.2586H75.3101V51.5783H72.7846V38.9327H80.6298Z" fill="white"></path>
  </g>
  <defs>
    <filter id="filter0_d" x="0" y="0" width="128" height="128" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
      <feOffset dy="1"></feOffset>
      <feGaussianBlur stdDeviation="2"></feGaussianBlur>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
    </filter>
  </defs>
  <g>
  <text x="10" y="110" fill="black" font-weight="bold">${fileName}</text>
  </g>
  </svg>`;
  };
  excelIcon({ fileName, height, width }) {
    fileName =
      fileName.length > this.fileNameLength
        ? fileName.slice(0, this.fileNameLength) + '...'
        : fileName;
    return `
    <svg width="${width || this.width}" height="${
      height || this.height
    }" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.328125" y="80" width="119.706" height="40" fill="white"/>
        <rect x="0.623047" width="120" height="80" fill="#F9F9F9"/>
        <path d="M34.6577 5.92218H71.0023L90.2666 25.1588V70.2441C90.2666 72.6132 88.3503 74.5322 85.989 74.5322H34.6577C32.2964 74.5322 30.3801 72.6132 30.3801 70.2441V10.2103C30.3801 7.84116 32.2966 5.92218 34.6577 5.92218Z" fill="#4D9E69" fill-opacity="0.75"/>
        <path d="M90.2043 25.2187H75.2947C72.9334 25.2187 71.0171 23.2977 71.0171 20.9306V5.96503L90.2043 25.2187Z" fill="#18793A" fill-opacity="0.25"/>
        <path d="M53.7555 48.8051L50.8535 44.3957L48.2801 48.8051H45.3417L49.3934 42.2002L45.2322 35.8881H48.2801L51.1273 40.206L53.6642 35.8881H56.6026L52.5691 42.3832L56.8034 48.8051H53.7555Z" fill="white"/>
        <path d="M60.7676 46.8291H65.0931V48.8051H58.1942V35.8881H60.7676V46.8291Z" fill="white"/>
        <path d="M75.4765 45.2739C75.4765 45.9326 75.3122 46.5425 74.9837 47.1035C74.6552 47.6524 74.1685 48.0976 73.5236 48.4392C72.8787 48.7685 72.0939 48.9331 71.1692 48.9331C70.2566 48.9331 69.4414 48.7807 68.7235 48.4757C68.0057 48.1586 67.4277 47.7073 66.9897 47.1218C66.5638 46.5364 66.3205 45.8533 66.2596 45.0727H68.9973C69.0581 45.5972 69.265 46.0363 69.6178 46.39C69.9829 46.7315 70.4635 46.9023 71.0597 46.9023C71.5951 46.9023 72.0027 46.762 72.2825 46.4815C72.5745 46.1887 72.7205 45.8167 72.7205 45.3654C72.7205 44.9629 72.6049 44.6336 72.3738 44.3774C72.1426 44.1091 71.8506 43.8956 71.4977 43.7371C71.1449 43.5785 70.6582 43.3956 70.0376 43.1882C69.2346 42.9199 68.5775 42.6576 68.0665 42.4015C67.5555 42.1331 67.1174 41.7489 66.7524 41.2488C66.3996 40.7487 66.2231 40.0962 66.2231 39.2912C66.2231 38.1812 66.6186 37.3091 67.4095 36.6748C68.2125 36.0284 69.2711 35.7051 70.5852 35.7051C71.9479 35.7051 73.0308 36.0345 73.8339 36.6931C74.6491 37.3518 75.1114 38.23 75.2209 39.3277H72.4468C72.3738 38.8642 72.1791 38.4861 71.8627 38.1934C71.5464 37.8885 71.1144 37.736 70.5669 37.736C70.0924 37.736 69.7091 37.8641 69.4171 38.1202C69.1251 38.3642 68.9791 38.724 68.9791 39.1997C68.9791 39.5778 69.0886 39.8949 69.3076 40.1511C69.5388 40.395 69.8247 40.5963 70.1654 40.7548C70.5182 40.9134 70.9928 41.0903 71.589 41.2854C72.4164 41.5782 73.0856 41.8526 73.5966 42.1087C74.1198 42.3649 74.5639 42.7491 74.9289 43.2614C75.2939 43.7737 75.4765 44.4445 75.4765 45.2739Z" fill="white"/>
        <text x="10" y="110" fill="black" font-weight="bold">${fileName}</text>
    </svg>
`;
  }
  documentIcon({ fileName, height, width }) {
    fileName =
      fileName.length > this.fileNameLength
        ? fileName.slice(0, this.fileNameLength) + '...'
        : fileName;
    return `
    <svg width="${width || this.width}" height="${
      height || this.height
    }" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="1.32666" y="80" width="119.592" height="40" fill="white"/>
<rect x="0.918945" width="120" height="80" fill="#F9F9F9"/>
<path d="M35.074 5.92218H71.3842L90.6301 25.1588V70.2441C90.6301 72.6132 88.7157 74.5322 86.3566 74.5322H35.074C32.715 74.5322 30.8005 72.6132 30.8005 70.2441V10.2103C30.8005 7.84116 32.7152 5.92218 35.074 5.92218Z" fill="#5C8CE7" fill-opacity="0.75"/>
<path d="M90.5679 25.2187H75.6724C73.3134 25.2187 71.3989 23.2977 71.3989 20.9306V5.96503L90.5679 25.2187Z" fill="#2258BC" fill-opacity="0.25"/>
<path d="M52.2578 42.3285C52.2578 43.6092 51.9903 44.7374 51.4555 45.7132C50.9206 46.689 50.1427 47.4514 49.1216 48.0002C48.1126 48.5369 46.9092 48.8053 45.5113 48.8053H40.9346V35.8883H45.5113C46.9092 35.8883 48.1126 36.1566 49.1216 36.6933C50.1427 37.2178 50.9206 37.9679 51.4555 38.9437C51.9903 39.9073 52.2578 41.0356 52.2578 42.3285ZM45.2925 46.5C46.6782 46.5 47.7479 46.134 48.5016 45.4022C49.2674 44.6704 49.6503 43.6458 49.6503 42.3285C49.6503 40.999 49.2674 39.9683 48.5016 39.2365C47.7479 38.5046 46.6782 38.1387 45.2925 38.1387H43.5055V46.5H45.2925Z" fill="white"/>
<path d="M60.218 35.7053C61.4214 35.7053 62.5094 35.9859 63.4818 36.5469C64.4665 37.108 65.2384 37.8947 65.7975 38.9071C66.3689 39.9073 66.6545 41.0417 66.6545 42.3102C66.6545 43.5787 66.3689 44.7192 65.7975 45.7315C65.2384 46.7439 64.4665 47.5306 63.4818 48.0917C62.5094 48.6528 61.4214 48.9333 60.218 48.9333C59.0145 48.9333 57.9205 48.6528 56.9359 48.0917C55.9634 47.5306 55.1915 46.7439 54.6202 45.7315C54.061 44.7192 53.7814 43.5787 53.7814 42.3102C53.7814 41.0417 54.061 39.9073 54.6202 38.9071C55.1915 37.8947 55.9634 37.108 56.9359 36.5469C57.9205 35.9859 59.0145 35.7053 60.218 35.7053ZM60.218 38.0838C59.4643 38.0838 58.8018 38.2546 58.2305 38.5961C57.6592 38.9376 57.2094 39.4316 56.8812 40.0781C56.5651 40.7123 56.4071 41.4564 56.4071 42.3102C56.4071 43.164 56.5651 43.9141 56.8812 44.5606C57.2094 45.1948 57.6592 45.6827 58.2305 46.0243C58.8018 46.3658 59.4643 46.5366 60.218 46.5366C60.9716 46.5366 61.6341 46.3658 62.2055 46.0243C62.7768 45.6827 63.2205 45.1948 63.5365 44.5606C63.8647 43.9141 64.0289 43.164 64.0289 42.3102C64.0289 41.4564 63.8647 40.7123 63.5365 40.0781C63.2205 39.4316 62.7768 38.9376 62.2055 38.5961C61.6341 38.2546 60.9716 38.0838 60.218 38.0838Z" fill="white"/>
<path d="M74.4046 35.7602C76.0092 35.7602 77.3403 36.1749 78.3978 37.0043C79.4554 37.8338 80.1543 38.962 80.4947 40.3891H77.7961C77.5165 39.6938 77.0789 39.145 76.4833 38.7425C75.8998 38.3399 75.1947 38.1387 74.3681 38.1387C73.6874 38.1387 73.0735 38.3095 72.5265 38.651C71.9795 38.9925 71.5541 39.4804 71.2502 40.1147C70.9463 40.7489 70.7943 41.493 70.7943 42.3468C70.7943 43.1884 70.9463 43.9263 71.2502 44.5606C71.5541 45.1948 71.9795 45.6827 72.5265 46.0243C73.0735 46.3658 73.6874 46.5366 74.3681 46.5366C75.1947 46.5366 75.8998 46.3353 76.4833 45.9328C77.0789 45.5303 77.5165 44.9814 77.7961 44.2861H80.4947C80.1543 45.7132 79.4554 46.8415 78.3978 47.6709C77.3403 48.5003 76.0092 48.915 74.4046 48.915C73.1769 48.915 72.0889 48.6406 71.1408 48.0917C70.2048 47.5306 69.4754 46.75 68.9527 45.7498C68.4421 44.7496 68.1869 43.6153 68.1869 42.3468C68.1869 41.066 68.4421 39.9256 68.9527 38.9254C69.4754 37.9252 70.2048 37.1507 71.1408 36.6018C72.0768 36.0407 73.1647 35.7602 74.4046 35.7602Z" fill="white"/>
 <text x="10" y="110" fill="black" font-weight="bold">${fileName}</text>
</svg>
`;
  }
  pptIcon({ fileName, height, width }) {
    fileName =
      fileName.length > this.fileNameLength
        ? fileName.slice(0, this.fileNameLength) + '...'
        : fileName;
    return `
   <svg width="${width || this.width}" height="${
      height || this.height
    }" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.328125" y="80" width="119.625" height="40" fill="white"/>
<rect x="0.702881" width="120" height="80" fill="#F9F9F9"/>
<path d="M34.5315 5.92218H70.8517L90.1029 25.1588V70.2441C90.1029 72.6132 88.188 74.5322 85.8283 74.5322H34.5315C32.1718 74.5322 30.2568 72.6132 30.2568 70.2441V10.2103C30.2568 7.84116 32.172 5.92218 34.5315 5.92218Z" fill="#E5B542" fill-opacity="0.75"/>
<path d="M90.0408 25.2187H75.1411C72.7814 25.2187 70.8665 23.2977 70.8665 20.9306V5.96503L90.0408 25.2187Z" fill="#C49219" fill-opacity="0.25"/>
<path d="M47.8778 44.0111V48.8046H45.3062V35.8876H49.9935C51.5134 35.8876 52.6564 36.2536 53.4224 36.9854C54.2006 37.7172 54.5897 38.7113 54.5897 39.9676C54.5897 41.1996 54.1885 42.1815 53.386 42.9133C52.5956 43.6451 51.4648 44.0111 49.9935 44.0111H47.8778ZM49.72 41.9436C51.2277 41.9436 51.9816 41.285 51.9816 39.9676C51.9816 39.3212 51.8053 38.8272 51.4526 38.4857C51.1 38.1441 50.5225 37.9734 49.72 37.9734H47.8778V41.9436H49.72Z" fill="white"/>
<path d="M58.8318 44.0111V48.8046H56.2602V35.8876H60.9475C62.4674 35.8876 63.6104 36.2536 64.3764 36.9854C65.1546 37.7172 65.5437 38.7113 65.5437 39.9676C65.5437 41.1996 65.1425 42.1815 64.34 42.9133C63.5496 43.6451 62.4188 44.0111 60.9475 44.0111H58.8318ZM60.674 41.9436C62.1817 41.9436 62.9356 41.285 62.9356 39.9676C62.9356 39.3212 62.7593 38.8272 62.4066 38.4857C62.054 38.1441 61.4765 37.9734 60.674 37.9734H58.8318V41.9436H60.674Z" fill="white"/>
<path d="M75.9688 35.8876V37.9368H72.4122V48.8046H69.8405V37.9368H66.3022V35.8876H75.9688Z" fill="white"/>
 <text x="10" y="110" fill="black" font-weight="bold">${fileName}</text>
</svg>

`;
  }
  videoIcon({ fileName = 'video', height, width }) {
    return `<svg width="${width || this.width}" height="${
      height || this.height
    }" viewBox="0 0 400 226" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="40" width="400" height="185" fill="#DF7676" fill-opacity="0.5"/>
<path d="M200.5 91C178.132 91 160 109.132 160 131.5C160 153.868 178.132 172 200.5 172C222.868 172 241 153.868 241 131.5C241 109.132 222.868 91 200.5 91ZM189.563 148.247V114.753L218.558 131.5L189.563 148.247Z" fill="white" fill-opacity="0.4"/>
<path d="M189.633 148.26L218.517 131.5L189.633 114.74V148.26Z" fill="#ED7777"/>
<path d="M0.00622559 2.00964C0.00622559 0.905073 0.901656 0.00964355 2.00623 0.00964355H398C399.105 0.00964355 400 0.905074 400 2.00964V40.0113H0.00622559V2.00964Z" fill="#34495E" fill-opacity="0.75"/>
</svg>

`;
  }
}
export default IconClass;
