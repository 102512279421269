import { Button } from '@material-ui/core';
import React from 'react';
import { StyledDialogContainer } from '../../../../common/dialog/styled-dialog/container';
import { useExternalJoinAsHostDialogStyles } from './component.style';

export const ExternalJoinAsHostDialog = (props) => {
  const { isOpenDialog, closeDialog, title, joinHere, joinOnPairedDevice } = props;
  const classes = useExternalJoinAsHostDialogStyles();

  const displayHereButton = (
    <Button variant="outlined" color="primary" onClick={joinHere}>
      Here
    </Button>
  );

  const displayPairedDeviceButton = (
    <Button variant="outlined" color="primary" onClick={joinOnPairedDevice}>
      Paired device
    </Button>
  );

  return (
    <StyledDialogContainer open={isOpenDialog} onClose={closeDialog} title={title} hideFooter>
      <div className={classes.buttonsContainer}>
        {displayHereButton}
        {displayPairedDeviceButton}
      </div>
    </StyledDialogContainer>
  );
};
