import React from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { StyledDialogContainer } from '../../../common/dialog/styled-dialog/container';
import { useScheduleMeetingDialogContainerStyles } from './component.style';
import { Zoom, Webex, GoogleMeet, MicrosoftTeam } from '../../../../assets/icons';

export const ScheduleMeetingDialog = (props) => {
  const classes = useScheduleMeetingDialogContainerStyles();
  const { isOpenDialog, closeDialog, onSubmit, formik, setStartTime, setEndTime } = props;

  const topbar = (
    <div className={classes.topbar}>
      <Typography>A notification will be sent to all team members.</Typography>
      <div className={classes.rightColumn}>
        <MicrosoftTeam />
        <GoogleMeet />
        <Zoom />
        <Webex />
      </div>
    </div>
  );

  return (
    <StyledDialogContainer
      open={isOpenDialog}
      title={'Schedule a meeting'}
      onClose={closeDialog}
      onCloseFooter={closeDialog}
      closeText={'CANCEL'}
      // submitText={submitText}
      onSubmit={onSubmit}
    >
      <div>
        {topbar}
        <div>
          <div style={{ marginBottom: '8px' }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Meeting Name"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={!!formik.errors.title}
              helperText={formik.errors.title}
            />
          </div>
          <div style={{ marginBottom: '8px' }}>
            <TextField
              fullWidth
              label="Meeting Link"
              variant="outlined"
              name="link"
              value={formik.values.link}
              onChange={formik.handleChange}
              error={!!formik.errors.link}
              helperText={formik.errors.link}
            />
          </div>
          <div className={classes.datePickerContainer}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="From"
                hideTabs={false}
                value={new Date(formik.values.startTime)}
                onChange={(value) => {
                  setStartTime(value);
                }}
                inputVariant="outlined"
                format="D-MM-YYYY, hh:mm A"
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} variant="outlined" />}
                label="To"
                hideTabs={false}
                value={new Date(formik.values.endTime)}
                onChange={setEndTime}
                inputVariant="outlined"
                format="D-MM-YYYY, hh:mm A"
                minDate={new Date(formik.values.startTime) || new Date()}
              />
            </MuiPickersUtilsProvider>

            <FormControl variant="outlined" className="selectdropdown">
              <InputLabel>Ocurrence</InputLabel>
              <Select
                value={formik.values.occurence}
                onChange={formik.handleChange}
                MenuProps={{ style: { zIndex: '99999999' } }}
                label="Ocurrence"
                name="occurence"
              >
                <MenuItem value={'NONE'}>Does not repeat</MenuItem>
                <MenuItem value={'DAILY'}>Daily</MenuItem>
                <MenuItem value={'WEEKLY'}>Weekly</MenuItem>
                <MenuItem value={'WEEKDAY'}>Every Weekday</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    </StyledDialogContainer>
  );
};
