import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useNotificationDispatcher } from '../../../../store/dispatcher/useNotificationDispatcher';
import HuddleSessionLimitExhaustedDialog from './component';
import { useHuddleSelector } from '../../../../store/selector/useHuddleSelector';

const HuddleSessionLimitExhaustedDialogContainer = (props) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const isOpen = useSelector(
    (state) => state.notification.HuddleSessionLimitExhausted.isDialogOpen,
  );
  // const isWebview = useSelector((state) => state.webview.isWebview);
  const { dispatchHideHuddleSessionExhaustedDialog, dispatchShowSpinner } =
    useNotificationDispatcher();
  const history = useHistory();
  const { huddleDuration } = useHuddleSelector();

  const text = `${huddleDuration} minute huddle limit reached`;
  const subText = isAdmin
    ? `Need more time? Paid plan provides unlimited huddle and more features.`
    : `Need more time? Paid plan provides unlimited huddle and more features.`;
  const buttonText = isAdmin ? `Upgrade Plan` : `Ok`;
  const message = (
    <>
      contact:{' '}
      <span style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 700 }}>
        support@birdai.com
      </span>
    </>
  );

  // useEffect(() => {
  //   let roles = getUserRole();
  //   if (roles) {
  //     const isAdminVal = roles.split(',').includes('admin');
  //     setIsAdmin(isAdminVal);
  //   } else {
  //     setIsAdmin(false);
  //   }
  // }, [isOpen]);

  const onClose = () => {
    dispatchHideHuddleSessionExhaustedDialog();
  };

  const onClickHandler = () => {
    // if (isAdmin) {
    //   onAdminClickHandler();
    // } else {
    //   onNonAdminClickHandler();
    // }
  };

  // const onAdminClickHandler = () => {
  //   if (checkIsWebview()) {
  //     const userKneuraId = getKneuraId();
  //     const instituteKneuraId = getInstituteKneuraId();
  //     broadcastService.sendOpenLicensePlansWebviewLEMessage(userKneuraId, instituteKneuraId);
  //     dispatchHideLicenseExhaustedDialog();
  //     dispatchShowSpinner();
  //   } else {
  //     dispatchHideLicenseExhaustedDialog();
  //     history.push('/UpgradePlan');
  //   }
  // };

  // const checkIsWebview = () => {
  //   return isWebview;
  // };

  // const onNonAdminClickHandler = () => {
  //   onClose();
  // };

  return (
    <HuddleSessionLimitExhaustedDialog
      isOpen={isOpen}
      onClose={onClose}
      text={text}
      subText={subText}
      buttonText={buttonText}
      onClickHandler={onClickHandler}
      message={message}
    />
  );
};

export default HuddleSessionLimitExhaustedDialogContainer;
