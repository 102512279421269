import { makeStyles } from '@material-ui/core';
import topCurveSVG from '../../../assets/svg/topCurve.svg';

export const useSuccessDialogStyles = makeStyles((theme) => ({
  container: {
    height: '20.625rem',
    padding: '0 3rem',
    backgroundImage: `url(${topCurveSVG})`,
    backgroundRepeat: 'no-repeat',
    borderRadius: '1rem',
    backgroundPosition: 'center 0',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '7rem',
    paddingLeft: '5.5rem',
    paddingRight: '5.5rem',
  },
  paperWidthSm: {
    maxWidth: '43.625rem',
    borderRadius: '1rem',
  },
  label: {
    fontSize: '1.25rem',
    fontWeight: '500',
    margin: '5px 0 12px',
  },
  message: {
    opacity: 0.6,
    marginBottom: '3rem',
  },
  button: {
    height: '3.375rem',
    width: '20rem',
  },
}));
