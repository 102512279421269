import React from 'react';
import { SuccessDialog } from '../../../UIComponents/dialog';

export const JoinRequestSentDialogComponent = (props) => {
  const { open, onClickNext, headingText, descriptionText, ...rest } = props;

  return (
    <SuccessDialog
      {...rest}
      open={open}
      headingText={headingText}
      descriptionText={descriptionText}
      onClickNext={onClickNext}
    />
  );
};
