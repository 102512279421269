import React, { Component } from 'react';
import { LinearProgress } from '@material-ui/core';

export default class IframeGoogleDocs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
    this.iframeLoaded = this.iframeLoaded.bind(this);
    this.iframeRef = React.createRef();
  }

  iframeLoaded() {
    this.setState({ loaded: true });
  }

  updateIframeSrc() {
    this.iframeRef.current.src = this.props.url;
  }

  checkIframeStatus = () => {
    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      try {
        const iframe = this.iframeRef.current;
        const iframeDoc = iframe.contentDocument || iframe.contentWindow.Document;
        if (!iframeDoc.body.innerHTML.length) {
          this.iframeRef.current.src = this.props.url;
          this.checkIframeStatus();
        } else {
          clearTimeout(this.timer);
        }
      } catch (error) {
        console.error(error);
        clearTimeout(this.timer);
      }
    }, 3000);
  };

  componentDidMount() {
    this.checkIframeStatus();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const style = this.props.style ? this.props.style : { width: '60vw', height: '80vh' };
    return (
      <>
        {!this.state.loaded && <LinearProgress />}
        <iframe
          style={style}
          title={this.props.title || ''}
          onLoad={this.iframeLoaded}
          onError={this.updateIframeSrc}
          ref={this.iframeRef}
          src={this.props.url}
        />
      </>
    );
  }
}
