import config from '../config';
import { getCanvasCode } from '../Utils/authentication-access';
import BaseService from './base.service';

class ZoomService extends BaseService {
  constructor() {
    const url = config.BIRDAI_SERVER_URL;
    super(url);
  }

  extractMeetingNumber(url) {
    return url.split('?')[0].split('/').pop();
  }

  extractMeetingPassword(url) {
    const queryString = `?${url.split('?')[1]}`;
    const queryParams = new URLSearchParams(queryString);
    return queryParams.get('pwd');
  }

  /**
   * extract meetingNumber and password from zoom url
   * https://zoom.us/j/abc?pwd=qwer
   * e.g return response
   * {
   *  meetingNumber: 'abc'
   *  password: 'qwer'
   * }
   *
   */
  extractValuesFromUrl(url) {
    const meetingNumber = this.extractMeetingNumber(url);
    const pwd = this.extractMeetingPassword(url);
    return {
      meetingNumber,
      pwd,
    };
  }

  /**
   *
   * @param {{code: string}} param0
   * @returns
   */
  async getAccessToken({ code }) {
    const path = '/zoom/get-access-token';
    const data = {
      code,
    };
    return this.makePostRequest(path, data);
  }

  async getZAK() {
    const path = '/zoom/get-zak';
    try {
      return await this.makeGetRequest(path);
    } catch (error) {
      console.log(error.message);
    }
  }

  async getMe() {
    const path = '/zoom/me';
    try {
      const res = await this.makeGetRequest(path);
      return res.data;
    } catch (error) {
      console.log(error.message);
    }
  }

  async hasAuthorizedOAuth() {
    try {
      const path = '/zoom/has-authorized-oauth';
      const res = await this.makeGetRequest(path);
      return res.data.hasAuthorizedOAuth;
    } catch (error) {
      return false;
    }
  }

  async revokeAuthorization() {
    try {
      const path = '/zoom/revoke-authorization';
      return this.makePostRequest(path);
    } catch (error) {
      console.log(`error: ${error.message}`);
    }
  }

  async joinAsHostOnPairedDevice(dataInfo) {
    try {
      const { meetingLink } = dataInfo;
      const path = '/zoom/notify-join-as-host-on-paired-device';
      const classId = getCanvasCode();
      const data = {
        classId,
        meetingLink,
      };
      return this.makePostRequest(path, data);
    } catch (error) {
      console.log(`error: ${error.message}`);
    }
  }

  async joinAsParticipantOnPairedDevice(dataInfo) {
    try {
      const { meetingLink } = dataInfo;
      const path = '/zoom/notify-join-as-participant-on-paired-device';
      const classId = getCanvasCode();
      const data = {
        classId,
        meetingLink,
      };
      return this.makePostRequest(path, data);
    } catch (error) {
      console.log(`error: ${error.message}`);
    }
  }
}

const zoomService = new ZoomService();
export default zoomService;
