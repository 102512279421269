import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import PubSub from 'pubsub-js';
import { getCanvasCode, getWorkspaceId } from '../../../Utils/authentication-access';
import { schedulesService } from '../../../services/schedules.service';
import { useNotificationDispatcher } from '../../../store/dispatcher/useNotificationDispatcher';
import { SCHEDULE_FILTER } from '../../../constants/schedules';
import { VideoCallSidebar } from './component';
import { EVENTS } from '../../../constants/events';
import { useDialogSelector } from '../../../store/selector/useDialogSelector';
import { useDialogDispatcher } from '../../../store/dispatcher/useDialogDispatcher';
import { useMicrosoftAuth } from '../../../Utils/microsoft/useMicrosoftAuth';
import { useLastPageVisited } from '../../../Utils/history/useLastPageVisited';
import validateScheduleManuallyForm from './validate-schedule-manually-form';
import { useCalendarSelection } from './calendar-selection/use-calendar-selection';

const VideoCallSidebarContainer = (props) => {
  const [isOpenDialog, setOpenDialog] = useState(false);
  const { isOpenImportOutlookEventsDialog } = useDialogSelector();
  const { dispatchSetIsOpenImportOutlookEventsDialog } = useDialogDispatcher();
  const { dispatchHideSpinner, dispatchShowSpinner, dispatchSetSnackbar } =
    useNotificationDispatcher();
  const [schedules, setSchedules] = useState([]);
  const [scheduleFilter, setScheduleFilter] = useState(SCHEDULE_FILTER.TODAY);
  const [loading, setLoading] = useState(false);
  const [editSchedule, setEditSchedule] = useState();
  const { initiateMicrosoftOAuthAuthorization, isMicrosoftAuthenticated } = useMicrosoftAuth();
  const { setImportOutlookEventDialogAsLastVisited } = useLastPageVisited();
  const [isZoomSetupDialogOpen, setIsZoomSetupDialogOpen] = useState(false);
  const { selectedCalendarTab, handleSelectedCalendarTab } = useCalendarSelection();

  const openSetupZoomAccountDialog = () => {
    setIsZoomSetupDialogOpen(true);
  };

  const closeSetupZoomAccountDialog = () => {
    setIsZoomSetupDialogOpen(false);
  };

  const showMessageEndTimeShouldBeAfterStartTIme = () => {
    dispatchSetSnackbar({ message: 'End time should be after Start time' });
  };

  const { isSidebarOpen, closeSidebar } = props;

  const getSchedulesHandler = useCallback(async () => {
    let payload = {
      workspaceId: getWorkspaceId(),
      classId: getCanvasCode(),
      filter: scheduleFilter,
    };

    if (scheduleFilter === SCHEDULE_FILTER.TODAY) {
      payload.startDateTime = moment()
        .startOf('day')
        .toISOString(); /** date and time of starat of day */
      payload.endDateTime = moment().endOf('day').toISOString(); /** date and time of end of day */
    }

    setLoading(true);
    const response = await schedulesService.getAll(payload);
    setLoading(false);
    if (response?.data?.data?.schedules) {
      const sortedSchedules = response?.data?.data?.schedules;
      setSchedules(sortedSchedules);
    }
  }, [scheduleFilter]);

  useEffect(() => {
    const getSchedulesSubscriber = (_msg, _data) => {
      getSchedulesHandler();
    };
    const getSchedulesToken = PubSub.subscribe(EVENTS.GET_SCHEDULES, getSchedulesSubscriber);
    return () => {
      PubSub.unsubscribe(getSchedulesToken);
      console.log('clearing PAIR_CLASS and UNPAIR_CLASS event listener');
    };
  }, [getSchedulesHandler]);

  const init = async () => {
    getSchedulesHandler();
  };

  useEffect(() => {
    if (isSidebarOpen) {
      init();
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    getSchedulesHandler();
  }, [getSchedulesHandler]);

  const checkIsAllScheduleFilter = () => {
    return scheduleFilter === SCHEDULE_FILTER.UPCOMING;
  };

  const handleSubmit = async (values) => {
    try {
      const payload = { ...values };
      dispatchShowSpinner();
      if (!editSchedule) {
        await schedulesService.create(payload);
        dispatchSetSnackbar({ message: 'Imported sucessfully' });
      } else {
        await schedulesService.edit({ ...payload, scheduleId: editSchedule.id });
        dispatchSetSnackbar({ message: 'Updated sucessfully' });
      }
      getSchedulesHandler();
      dispatchHideSpinner();
      closeDialog();
    } catch (error) {
      console.error('error', error);
      dispatchHideSpinner();
    }
  };

  /**
   * the date picker hold datetime object in date object
   * hence it's stored in another variable
   * on submit, ISO datestring is extracted from date object.
   */
  const formik = useFormik({
    initialValues: {
      workspaceId: getWorkspaceId(),
      classId: getCanvasCode(),
      title: '',
      description: '',
      link: '',
      startTime: moment().toISOString(),
      endTime: moment().add(60, 'minutes').toISOString(),
      occurence: 'NONE',
    },
    validate: validateScheduleManuallyForm,
    onSubmit: async (values, { resetForm }) => {
      await handleSubmit(values);
      resetForm();
    },
  });

  const openDialog = () => {
    setOpenDialog(true);
  };

  const openImportOutlookCalendarEventDialog = () => {
    if (isMicrosoftAuthenticated) {
      dispatchSetIsOpenImportOutlookEventsDialog(true);
    } else {
      setImportOutlookEventDialogAsLastVisited();
      initiateMicrosoftOAuthAuthorization();
    }
  };

  const closeDialog = () => {
    setOpenDialog(false);
    disableEditSchedule();
    formik.resetForm();
  };

  const closeImportOutlookEventDialog = () => {
    dispatchSetIsOpenImportOutlookEventsDialog(false);
  };

  const onSubmit = () => {
    if (!formik.isValid) {
      return;
    }
    formik.handleSubmit();
  };

  const setTodaysScheduleFilter = () => {
    setScheduleFilter(SCHEDULE_FILTER.TODAY);
  };

  const setUpcomingScheduleFilter = () => {
    setScheduleFilter(SCHEDULE_FILTER.UPCOMING);
  };

  const enableEditSchedule = ({ schedule }) => {
    setEditSchedule(schedule);
    formik.setFieldValue('title', schedule.title);
    formik.setFieldValue('description', schedule.description);
    formik.setFieldValue('link', schedule.link);
    formik.setFieldValue('startTime', schedule.startTime);
    formik.setFieldValue('endTime', schedule.endTime);
    formik.setFieldValue('occurence', schedule.occurence);

    openDialog();

    /**
     * validate the form after the new values are added
     * validation is not triggered after setFieldValue.
     * Hence, using a timeout
     */
    setTimeout(() => {
      formik.validateForm();
    }, 0);
  };

  const disableEditSchedule = () => {
    setEditSchedule();
  };

  const setStartTime = (value) => {
    formik.setFieldValue('startTime', value.toISOString());
    formik.setFieldValue('endTime', value.clone().add(60, 'minutes').toISOString());
  };

  const setEndTime = (value) => {
    if (moment(formik.values.startTime).isAfter(value)) {
      showMessageEndTimeShouldBeAfterStartTIme();
      return;
    }
    formik.setFieldValue('endTime', value.toISOString());
  };

  return (
    <VideoCallSidebar
      checkIsAllScheduleFilter={checkIsAllScheduleFilter}
      closeDialog={closeDialog}
      closeImportOutlookEventDialog={closeImportOutlookEventDialog}
      closeSidebar={closeSidebar}
      enableEditSchedule={enableEditSchedule}
      formik={formik}
      getSchedulesHandler={getSchedulesHandler}
      isOpenDialog={isOpenDialog}
      isOpenImportOutlookEventDialog={isOpenImportOutlookEventsDialog}
      isSidebarOpen={isSidebarOpen}
      loading={loading}
      onSubmit={onSubmit}
      openDialog={openDialog}
      openImportOutlookCalendarEventDialog={openImportOutlookCalendarEventDialog}
      scheduleFilter={scheduleFilter}
      schedules={schedules}
      setEndTime={setEndTime}
      setStartTime={setStartTime}
      setTodaysScheduleFilter={setTodaysScheduleFilter}
      setUpcomingScheduleFilter={setUpcomingScheduleFilter}
      isZoomSetupDialogOpen={isZoomSetupDialogOpen}
      closeSetupZoomAccountDialog={closeSetupZoomAccountDialog}
      openSetupZoomAccountDialog={openSetupZoomAccountDialog}
      handleSelectedCalendarTab={handleSelectedCalendarTab}
      selectedCalendarTab={selectedCalendarTab}
    />
  );
};

export default VideoCallSidebarContainer;
