import React, { useEffect } from 'react';
import FullScreen from 'react-full-screen';
import { initWakeLock, releaseWakeLock } from '../../../../Utils/wakeLock';
import { CloseButtonContainer, useBBBViewerStyles } from './component.style';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

const DisplayIframe = (props) => {
  const { bbbUrl, isFullScreen, isMinimalizedUI, isContentUIDisplayed } = props;
  const classes = useBBBViewerStyles();

  let bbbContentStyle = {};

  const constructNonFullScreenStyle = () => {
    if (!isContentUIDisplayed) {
      return { ...bbbContentStyle, visibility: 'hidden' };
    }

    if (isContentUIDisplayed && isMinimalizedUI) {
      return { ...bbbContentStyle, height: '10rem', borderBottomLeftRadius: 0 };
    }

    return {};
  };

  if (isFullScreen) {
    bbbContentStyle = { ...bbbContentStyle, width: '100%', height: '100vh', borderRadius: 0 };
  } else {
    bbbContentStyle = constructNonFullScreenStyle();
  }

  return (
    <iframe
      title="bbb iframe"
      allow="microphone; camera; display-capture"
      src={bbbUrl}
      className={classes.bbbContents}
      style={bbbContentStyle}
    ></iframe>
  );
};

const DisplayIframeContainer = (props) => {
  const { bbbUrl, isFullScreen, isMinimalizedUI, isContentUIDisplayed } = props;

  useEffect(() => {
    initWakeLock();
    return () => {
      releaseWakeLock();
    };
  }, []);

  return (
    <DisplayIframe
      bbbUrl={bbbUrl}
      isFullScreen={isFullScreen}
      isMinimalizedUI={isMinimalizedUI}
      isContentUIDisplayed={isContentUIDisplayed}
    />
  );
};

export const BBBIframe = (props) => {
  const classes = useBBBViewerStyles();
  const { bbbUrl, isFullScreen, onChangeFullScreen, isMinimalizedUI, isContentUIDisplayed } = props;

  const displayEmptyContent = <div className={classes.emptyContent}></div>;
  const displayBBBContent = (
    <DisplayIframeContainer
      bbbUrl={bbbUrl}
      isFullScreen={isFullScreen}
      onChangeFullScreen={onChangeFullScreen}
      isMinimalizedUI={isMinimalizedUI}
      isContentUIDisplayed={isContentUIDisplayed}
    />
  );

  const displayContent = () => {
    return bbbUrl ? displayBBBContent : displayEmptyContent;
  };

  const onExitFullscreen = () => {
    onChangeFullScreen(false);
  };

  const displayCloseButton = isFullScreen ? (
    <CloseButtonContainer onClick={onExitFullscreen}>
      <FullscreenExitIcon style={{ color: '#FFFFFF' }} />
    </CloseButtonContainer>
  ) : (
    <></>
  );

  return (
    <FullScreen enabled={isFullScreen} onChange={onChangeFullScreen}>
      <div
        className={classes.bbb}
        style={{
          visibility: bbbUrl ? 'visible' : 'hidden',
          display: 'flex',
          maxWidth: isFullScreen ? 'unset' : '80vw',
          maxHeight: isFullScreen ? 'unset' : '80vh',
        }}
      >
        {displayContent()}
        {displayCloseButton}
      </div>
    </FullScreen>
  );
};
