import React from 'react';
import { ButtonBase, Typography, lighten, makeStyles } from '@material-ui/core';
import { BookmarkBorder as BookmarkBorderIcon } from '@material-ui/icons';

import { stringToHSLColor } from '../../../../Utils/common-utils';

const useStyles = ({ title }) => {
  const primaryColor = lighten(stringToHSLColor(title), 0.5);

  return makeStyles({
    notebookCardRoot: {
      gap: 8,
      width: '100%',
      minHeight: 36,
      borderRadius: 4,
      border: '1px solid #0000001F',
      '& .MuiTypography-root': {
        width: '100%',
        maxHeight: '100%',
        color: '#1D1F23',
        fontSize: 10,
        fontWeight: 500,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'justify',
      },
    },
    notebookIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px 0px 0px 4px',
      backgroundColor: primaryColor,
      alignSelf: 'stretch',
      width: 44,
      '& .MuiSvgIcon-root': {
        fill: '#00000042',
      },
    },
  });
};

const NotebookCard = ({ id, title, onClick }) => {
  const classes = useStyles({ title })();

  const clickHandler = () => onClick(id);

  return (
    <ButtonBase onClick={clickHandler} className={classes.notebookCardRoot} disableRipple>
      <div className={classes.notebookIcon}>
        <BookmarkBorderIcon />
      </div>
      <Typography>{title}</Typography>
    </ButtonBase>
  );
};

export default NotebookCard;
