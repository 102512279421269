import {
  Button,
  Dialog,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { put } from '../../../Utils/api';
import { getToken } from '../../../Utils/authentication-access';
import { AUTHENTICATION } from '../../../Utils/url-builder';
import DisplaySelectedFile from '../../authenticationPages/DisplaySelectedFile';
import CloseIcon from '@material-ui/icons/Close';
import userService from '../../../services/user.service';
import useFileSelectorUserProfile from './useFileSelectorUserProfile';
import FileSelectorUserProfile from './FileSelectorUserProfile';
import classescss from './profileDialog.module.css';
const useStyles = makeStyles((theme) => ({
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
  },
  btnContainer: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    flex: '0 0 auto',
    display: 'flex',
    padding: '12px',
    paddingBottom: '21px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  cancelBtn: {
    width: '100px',
    height: '36px',
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.38)',
  },
  saveBtn: {
    marginLeft: '5px',
    width: '100px',
    height: '36px',
    background: '#335AFB',
    boxShadow: '0px 0.3px 0.5px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    color: 'white',
  },
  headerContainer: {
    padding: ' 2% 2%',
    display: 'grid',
    gridTemplateColumns: '90% 10%',
    alignItems: 'center',
    minHeight: '30px',
    borderBottom: '1px solid rgba(0,0,0,0.12)',
  },
  headerText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '28px',
    textAlign: 'center',
    marginLeft: '50px',
    color: 'rgba(0, 0, 0)',
  },
  emailLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.38)',
  },
}));

const ProfileDialog = (props) => {
  const classes = useStyles();
  const { userName, picture, open, onClose, onSubmit, callUserDetails } = props;
  const theme = useTheme();
  const mobileViewMatches = useMediaQuery(theme.breakpoints.up('md'));

  const validations = Yup.object({
    name: Yup.string().required('*Required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      profileImage: '',
      file: undefined,
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: validations,
  });

  const handleSubmit = async (values) => {
    submitForm(values);

    console.log('submited successfully');
  };

  const { fileSelectorRef, selectFile, setFile, setProfileImage } = useFileSelectorUserProfile({
    formik,
  });

  const submitForm = async (values) => {
    let formData = objectToFormData(values);
    const response = await userService.editUserDetails(formData);

    if (response.status == 200 || 201) callUserDetails();
    onClose();
  };

  const objectToFormData = (values) => {
    const formData = new FormData();
    if (values.file) {
      formData.append('profileImage', values.file);
    }

    formData.append('name', values.name);

    return formData;
  };

  useEffect(() => {
    setFormValues();
  }, [userName]);

  const setFormValues = () => {
    formik.setFieldValue('name', userName);
    // if (picture) formik.setFieldValue('profileImage', picture);
  };

  let src = '';
  if (formik.values.profileImage) {
    src = formik.values.profileImage;
  } else if (picture) {
    src = picture;
  }

  const onEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth={true}
        PaperProps={{
          style: {
            borderRadius: 16,
          },
        }}
      >
        <div className={classes.headerContainer}>
          <div className={classes.headerText}> Profile</div>
          <IconButton
            edge="end"
            color="inherit"
            style={{ marginRight: 10, cursor: 'pointer' }}
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className={classescss.contentContainer}>
            <div className={classes.profileContainer}>
              <DisplaySelectedFile
                name="institute profile image"
                src={src}
                size={!mobileViewMatches && 54}
              />
              <Typography
                className={classes.link}
                color="primary"
                style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                onClick={selectFile}
              >
                Edit
              </Typography>
              <FileSelectorUserProfile
                fileSelectorRef={fileSelectorRef}
                setFile={setFile}
                setProfileImage={setProfileImage}
              />
            </div>
            <TextField
              autoFocus
              id="name"
              name="name"
              variant="outlined"
              type="text"
              placeholder="Full name"
              value={formik.values.name}
              onChange={formik.handleChange}
              helperText={formik.errors.name}
              style={{ width: `${mobileViewMatches ? '423px' : '100%'}` }}
              onKeyDown={onEnterKeyPress}
            />
            <div className={classes.emailLabel}>{props.userEmail}</div>
          </div>

          <div className={classes.btnContainer}>
            <Button onClick={onClose} className={classes.cancelBtn}>
              CANCEL
            </Button>

            <Button
              onClick={formik.handleSubmit}
              className={classes.saveBtn}
              style={{ background: '#335AFB' }}
            >
              SAVE
            </Button>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default ProfileDialog;
