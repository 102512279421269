import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  keySentenceListRoot: {
    '& .i-sentences-title': {
      fontSize: 14,
      fontWeight: 700,
    },
    '& *::marker': {
      color: '#536CF9',
    },
    '& .i-key-sentence': {
      fontSize: 14,
    },
  },
});

const KeySentenceList = ({ list }) => {
  const classes = useStyles();

  return (
    <div className={classes.keySentenceListRoot}>
      <Typography className="i-sentences-title">PDF key sentences:</Typography>
      <ol>
        {list.map((sentence, index) => (
          <li key={index}>
            <Typography className="i-key-sentence">{sentence}</Typography>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default KeySentenceList;
