import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DisplaySelectedFile from '../../authenticationPages/DisplaySelectedFile';
import { useTheme } from '@material-ui/styles';
import ProfileMenu from './ProfileMenu';
import { clearUserData } from '../../../Utils/authentication-access';
import { useHistory } from 'react-router';
import ProfileDialog from './ProfileDialog';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import deviceService from '../../../services/device.service';
import { useNotificationDispatcher } from '../../../store/dispatcher/useNotificationDispatcher';
import { useAuth } from '../../../Utils/auth/useAuth';

const useStyles = makeStyles((theme) => ({
  profileDropdown: {
    borderRadius: '2rem',
    backgroundColor: 'rgba(0, 0, 0, 0.06);',
    height: '100%',
    width: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  arrowBox: {
    marginRight: '0.5rem',
  },
}));

const ProfileDropdown = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [username, setUserName] = useState('user');
  const [userProfile, setUserProfile] = useState();
  const name = props.name;
  const [isOpenProfileMenu, setIsOpenProfileMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const { handleLogout } = useAuth();
  const [userProfileDialog, setUserProfileDialog] = useState(false);
  const { dispatchSetSnackbar } = useNotificationDispatcher();

  const onClickLogout = async () => {
    await handleLogout();
    handleClose();
  };

  const disconnectDeviceHandler = async () => {
    try {
      const response = await deviceService.unPairDevice();
      dispatchSetSnackbar({ message: 'Device disconnected' });
    } catch (error) {
      console.log('deviceId error', error.response.data.message);
      dispatchSetSnackbar({ message: error.response.data.message });
    }
    return;
  };

  useEffect(() => {
    setUserName(props.profileData.name);
    setUserProfile(props.profileData.imageUrl);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showProfileMenu = () => {
    setIsOpenProfileMenu(true);
  };

  const hideProfileMenu = () => {
    setIsOpenProfileMenu(false);
  };

  const handleClick = (event) => {
    // setAnchorEl(event.currentTarget);
    showProfileMenu();
  };

  const onClickProfile = () => {
    setUserProfileDialog(true);
    handleClose();
    props.callUserDetails();
  };

  const onCloseUserProfileDialog = () => {
    setUserProfileDialog(false);
  };

  const objectToFormData = (values) => {
    const formData = new FormData();
    if (values.file) {
      formData.append('profileImage', values.file);
    }
    formData.append('name', values.fullName);

    return formData;
  };

  const onSubmitUserProfileDialog = async (values) => {
    // props.showSpinner();
    // try {
    //   // const url = ADMIN.updateAdminInfo();
    //   // const url = USER.updateUserInfo(getKneuraId());
    //   let formData = objectToFormData(values);
    //   await baseService.makePutRequest(url, formData);
    //   onCloseUserProfileDialog();
    //   fetchUserProfile();
    //   props.hideSpinner();
    // } catch (err) {
    //   props.hideSpinner();
    // }

    // try{
    //   const url = AUTHENTICATION.userProfile();
    //   let

    // }catch(error) {
    //   console.log("error",error)
    // }

    console.log('profile ', props.profileData.name);
    console.log('picture ', props.profileData.imageUrl);
    console.log('profile dialog submit');
  };

  const menuItems = [
    // {
    //   onClick: onClickSwitchInstitute,
    //   title: 'Switch Institute',
    // },
    {
      onClick: onClickProfile,
      title: 'Profile',
      icon: <AccountCircleOutlinedIcon fontSize="small" style={{ color: 'rgba(0, 0, 0, 0.4)' }} />,
    },
    {
      onClick: onClickLogout,
      title: 'Logout',
      icon: <ExitToAppOutlinedIcon fontSize="small" style={{ color: 'rgba(0, 0, 0, 0.4)' }} />,
    },
  ];

  return (
    <>
      <div className={classes.profileDropdown} onClick={handleClick}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
          }}
        >
          <DisplaySelectedFile name="user profile picture" src={props.profileData.imageUrl} />
          {/* <div
            style={{
              marginLeft: "0.5rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
  
            <span style={{ color: "rgb(0, 0, 0)" }}>
              {props.profileData.name}
            </span>
          </div> */}
        </div>
      </div>

      <ProfileMenu
        open={isOpenProfileMenu}
        onClose={hideProfileMenu}
        menuItems={menuItems}
        userName={props.profileData.name}
        picture={props.profileData.imageUrl}
        userEmail={props.profileData.email}
      />
      <ProfileDialog
        userName={props.profileData.name}
        picture={props.profileData.imageUrl}
        // value={profileData.first_name}
        open={userProfileDialog}
        onClose={onCloseUserProfileDialog}
        onSubmit={onSubmitUserProfileDialog}
        callUserDetails={props.callUserDetails}
        userEmail={props.profileData.email}
      />
    </>
  );
};

export default ProfileDropdown;
