import React from 'react';
import { ContentContainer } from './component.style';
import { LocalVideoContainer } from '../local-video-container/container';
import { DisplayVideoContainer } from '../display-video/container';

export const ScreenshareDisplayContent = (props) => {
  const {
    isViewOnly,
    localSubscribedData,
    onlyVideoFilter,
    screenshareCount,
    showRemoteVideo,
    subscribedData,
  } = props;

  const localVideo = (
    <video
      id="local_video"
      controls
      autoPlay
      playsInline
      width={300}
      height={200}
      style={{}}
    ></video>
  );

  const displayLocalVideoContainer = (
    <LocalVideoContainer localSubscribedData={localSubscribedData} />
  );

  const onlyVideoSubscribedData = subscribedData.filter(onlyVideoFilter);

  const videoCount = onlyVideoSubscribedData.length;

  const itemCount = screenshareCount;

  const getMaxNoItemsInARow = () => {
    if (itemCount === 1) {
      return 1;
    } else if (itemCount <= 4) {
      return 2;
    } else if (itemCount <= 9) {
      return 3;
    } else if (itemCount <= 16) {
      return 4;
    } else if (itemCount <= 25) {
      return 5;
    }
  };

  const remoteVideoContainer = onlyVideoSubscribedData.map(({ producerId, kind, ref, stream }) => {
    const id = `${producerId}-${kind}`;
    const displayVideoContainer = (
      <DisplayVideoContainer
        key={id}
        id={id}
        ref={ref}
        videoCount={videoCount}
        showRemoteVideo={showRemoteVideo}
        isViewOnly={isViewOnly}
        stream={stream}
      />
    );

    if (!isViewOnly) {
      return displayVideoContainer;
    }

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#000000',
        }}
        key={id}
      >
        {displayVideoContainer}
      </div>
    );
  });

  const groupSize = getMaxNoItemsInARow();
  const getGroupedRemoteVideoContainer = () => {
    const groups = [];
    for (let i = 0; i < remoteVideoContainer.length; i += groupSize) {
      groups.push(remoteVideoContainer.slice(i, i + groupSize));
    }
    return groups;
  };

  /**
   * Group the remote video container into rows
   * Each row will have number of items equal to groupSize
   */
  const groupedRemoteVideoContainer = getGroupedRemoteVideoContainer().map((group, i) => {
    const itemWidth = 100 / groupSize;
    const diffItems = groupSize - group.length;
    const diffItemsWidth = diffItems * itemWidth;
    const horizontalPadding = `calc(${diffItemsWidth}%/2)`;
    const height = window.innerHeight / groupSize - 4;

    return (
      <div
        key={i}
        style={{
          display: 'flex',
          gap: '0.25rem',
          flex: 1,
          padding: `0 ${horizontalPadding}`,
          height,
        }}
      >
        {group}
      </div>
    );
  });

  const displayRemoteVideoContainer = isViewOnly ? (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        pointerEvents: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gridGap: '0.25rem',
        backgroundColor: '#010126',
      }}
    >
      {groupedRemoteVideoContainer}
    </div>
  ) : (
    <>{remoteVideoContainer}</>
  );

  const audioContainer = <div id="audio-container"></div>;

  return (
    <ContentContainer>
      {false && localVideo}
      {displayLocalVideoContainer}
      {displayRemoteVideoContainer}
      {/* {controls} */}
      {/* <Button onClick={connect}>Connect</Button> */}
      {audioContainer}
      {/* <Button onClick={subscribe}>subscribe</Button> */}
    </ContentContainer>
  );
};
