import BaseService from './base.service';
import { BIRDAI_SERVER_URL } from '../config';
import { put, get } from '../Utils/api';

class VideoCallLinksService extends BaseService {
  constructor() {
    super(`${BIRDAI_SERVER_URL}/spaces`);
  }

  saveLink = async ({ classId, link }) => {
    try {
      const url = `${this.baseUrl}/video-call-links`;
      return await put(url, {
        classId,
        link,
      });
    } catch (error) {
      console.log('create space error', error);
      throw error;
    }
  };

  getLink = async (classId) => {
    try {
      const url = `${this.baseUrl}/video-call-links?classId=${classId}`;
      return await get(url);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

export const videoCallLinksService = new VideoCallLinksService();
