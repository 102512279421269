import { Card, Dialog, Typography } from '@material-ui/core';
import React from 'react';
import StyledButton from '../../input/StyledButton';
import { useSuccessDialogStyles } from './component.style';

export const SuccessDialogComponent = (props) => {
  const { open, headingText, descriptionText, onClickNext, nextButtonLabel, ...rest } = props;
  const classes = useSuccessDialogStyles();

  const heading = <Typography className={classes.label}>{headingText}</Typography>;

  const description = (
    <Typography variant="body2" className={classes.message}>
      {descriptionText}
    </Typography>
  );

  const content = <>{description}</>;

  const nextButton = (
    <StyledButton
      roundedBorder
      variant="contained"
      color="primary"
      className={classes.button}
      onClick={onClickNext}
    >
      {nextButtonLabel || 'Done'}
    </StyledButton>
  );

  return (
    <Dialog
      {...rest}
      classes={{ paperWidthSm: classes.paperWidthSm, root: classes.root }}
      onClose={() => {}}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Card className={classes.container}>
        <div className={classes.content}>
          {heading}
          {content}
          {nextButton}
        </div>
      </Card>
    </Dialog>
  );
};
