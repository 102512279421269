export function generateUUID() {
  var d = new Date().getTime();
  if (Date.now) {
    d = Date.now(); //high-precision timer
  }
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

export function generateShortId() {
  return require('randomstring').generate({
    length: 6,
    charset: 'alphanumeric',
    capitalization: 'uppercase',
    readable: true,
  });
}

export const customBoundRect = (pointArr) => {
  const { xMin: x, yMin: y, width, height } = minMaxPositions(pointArr);
  return { x, y, width, height };
};

export const minMaxPositions = (pointArr = []) => {
  const xArray = [];
  const yArray = [];
  pointArr.forEach((point, index) => (index % 2 ? yArray.push(point) : xArray.push(point)));
  const xMin = Math.min(...xArray) || 0;
  const xMax = Math.max(...xArray) || 0;
  const yMin = Math.min(...yArray) || 0;
  const yMax = Math.max(...yArray) || 0;
  const width = xMax - xMin;
  const height = yMax - yMin;
  return { xMin, xMax, yMin, yMax, width, height };
};

export function getChildrenIds(konvalayerObj) {
  var idArr = [];
  konvalayerObj.getChildren(function (node) {
    idArr.push(node.getAttr('id'));
  });
  return idArr;
}

export function getLengthBetweenPoints(x1, y1, x2, y2) {
  return parseFloat(Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1)).toFixed(2));
}

const getCanvasDimension = () => {
  const defaultCanvasWidth = 1280;
  const defaultCanvasHeight = 720;

  const canvasElement = document.querySelector('.convasKonva canvas');

  const canvasElementCurrentWidth = canvasElement?.style?.width?.split('px')[0];
  const canvasElementCurrentHeight = canvasElement?.style?.height?.split('px')[0];

  if (canvasElementCurrentHeight && canvasElementCurrentWidth) {
    return {
      canvasWidth: canvasElementCurrentWidth,
      canvasHeight: canvasElementCurrentHeight,
    };
  } else {
    return {
      canvasWidth: defaultCanvasWidth,
      canvasHeight: defaultCanvasHeight,
    };
  }
};

export function aspectRatioCalculate(
  width = 350,
  height = 197,
  standardWidth = 300,
  standardHeight = 300,
  options = {},
) {
  // let standardHeight = 300,
  //   standardWidth = 300;
  const { canvasWidth, canvasHeight } = getCanvasDimension();
  /**
   * todo: we need to use the real device/canvas width and height
   * currently, the width and height of canvas is fixed.
   * if width and height of canvas is different for different screen size,
   * we might need to calculate the device aspect ratio accordingly
   */
  const canvasAspectRatio = canvasWidth / canvasHeight;
  if (options.scale === 'MAX') {
    const imageAspectRatio = width / height;

    console.log({ canvasHeight, canvasWidth, imageAspectRatio, canvasAspectRatio, width, height });
    if (imageAspectRatio >= canvasAspectRatio) {
      const newHeight = canvasWidth / imageAspectRatio;
      return {
        width: canvasWidth,
        height: newHeight,
        x: 0,
        y: Math.floor((canvasHeight - newHeight) / 2),
      };
    } else {
      const newWidth = canvasHeight * imageAspectRatio;
      return {
        width: newWidth,
        height: canvasHeight,
        x: Math.floor((canvasWidth - newWidth) / 2),
        y: 0,
      };
    }
  }

  if (height > width) {
    const newHeight = standardHeight * (height / width);
    height = newHeight;
    width = standardWidth;
    const x = Math.floor((canvasWidth - standardWidth) / 2);
    const y = Math.floor((canvasHeight - newHeight) / 2);
    return { width: width, height: height, x, y };
  } else {
    const newWidth = standardWidth * (width / height);
    width = newWidth;
    height = standardHeight;
    const x = Math.floor((canvasWidth - newWidth) / 2);
    const y = Math.floor((canvasHeight - standardHeight) / 2);
    return { width: width, height: height, x, y };
  }
}

/**
 * get the data to render the loader in the center of the image (on loading)
 * @param {*} param0
 * @returns
 */
export const getLoaderDisplayData = ({
  x,
  y,
  imageWidth = 200,
  imageHeight = 200,
  loaderWidth = 100,
  loaderHeight = 100,
}) => {
  return {
    x: x + Math.floor(imageWidth / 2) - Math.floor(loaderWidth / 2),
    y: y + Math.floor(imageHeight / 2) - Math.floor(loaderHeight / 2),
    width: loaderWidth,
    height: loaderHeight,
  };
};
