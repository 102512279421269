import { get, post } from '../Utils/api';
import {
  getAnonymousUserToken,
  getToken,
  getUserId,
  getWorkspaceId,
} from '../Utils/authentication-access';
import { DEVICE_PAIR } from '../Utils/url-builder';
import { isDeviceConnected } from '../store/actions/device.action';
import store from '../store';
import { showDeviceLicenseExhaustedDialog } from '../store/actions/notification.action';

class DeviceService {
  sendDeviceID = async (deviceId, token) => {
    try {
      const url = DEVICE_PAIR.sendDeviceId();
      const res = await post(url, {
        deviceCode: deviceId,
        userId: getUserId(),
        workspaceId: getWorkspaceId(),
      });
      this.deviceActiveStatus(true);
      return res;
    } catch (error) {
      console.log('error', error.response);
      if (error.response?.data?.type === 'DEVICE_LICENSE_EXPIRED') {
        this.showDeviceLicenseExpiredDialog();
      }
      throw error;
    }
  };

  sendDeviceIdOfAnonymousUser = async (deviceId, token) => {
    try {
      const url = DEVICE_PAIR.sendDeviceId();
      return await post(url, { deviceCode: deviceId, userId: getUserId() }, { token: token });
    } catch (error) {
      console.log('error', error);
      console.log('error', error.response);
      if (error.response?.data?.type === 'DEVICE_LICENSE_EXPIRED') {
        this.showDeviceLicenseExpiredDialog();
      }
      throw error;
    }
  };

  unPairDevice = async () => {
    try {
      const url = DEVICE_PAIR.unPairDevice();
      const res = await post(url);
      this.deviceActiveStatus(false);
      return res;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  unPairDeviceOfAnonymousUser = async (token) => {
    try {
      const url = DEVICE_PAIR.unPairDevice();
      return await post(url, {}, { token });
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  pairedDeviceStatus = async () => {
    try {
      const url = DEVICE_PAIR.pairedDeviceStatus();
      const token = getToken() ? getToken() : getAnonymousUserToken();
      return await get(url, { token });
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  anonymousUserPairedDeviceStatus = async (token) => {
    try {
      const url = DEVICE_PAIR.pairedDeviceStatus();
      return await get(url, { token });
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  startMeetingOnDevice = async (meetingName, meetingLink) => {
    try {
      const url = DEVICE_PAIR.startMeetingOnDevice();
      return await post(url, { meetingName, meetingLink });
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  anonymousUserStartMeetingOnDevice = async (meetingName, meetingLink, token) => {
    try {
      const url = DEVICE_PAIR.startMeetingOnDevice();
      return await post(url, { meetingName, meetingLink }, { token });
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  sendScreenshareStartedInfoToDevice = async (payload, token) => {
    try {
      const url = DEVICE_PAIR.screenshareStarted();
      return await post(url, payload, { token });
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  sendMeetingCommands = async (meetingCommand, additionalParameters) => {
    try {
      const url = DEVICE_PAIR.sendMeetingCommand();
      return await post(url, {
        meetingCommand: meetingCommand,
        additionalParameters: additionalParameters,
      });
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  sendScreenshareStoppedInfoToDevice = async (payload, token) => {
    try {
      const url = DEVICE_PAIR.screenshareStopped();
      return await post(url, payload, { token });
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  deviceActiveStatus(status) {
    store.dispatch(isDeviceConnected(status));
  }

  showDeviceLicenseExpiredDialog() {
    store.dispatch(showDeviceLicenseExhaustedDialog());
  }

}

const deviceService = new DeviceService();
export default deviceService;
