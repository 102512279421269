import React, { useEffect } from 'react';
import { Card, ClickAwayListener, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CloseIcon from '@material-ui/icons/Close';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  list: {
    position: 'absolute',
    width: '100%',
    zIndex: 999,
    marginTop: '0.25rem',
    borderRadius: '0.25rem',
    maxHeight: '10rem',
    overflow: 'scroll',
  },
  listItem: {
    padding: '0.5rem 0.5rem',
    cursor: 'pointer',
    '&:hover': {
      background: grey[100],
    },
  },
}));

const CountryList = (props) => {
  const { countries, onItemClick } = props;
  const classes = useStyles();

  return (
    <div onClick={() => {}}>
      {countries.map((country) => (
        <div
          className={classes.listItem}
          onClick={() => {
            onItemClick(country.name);
          }}
        >
          {country.name}
        </div>
      ))}
    </div>
  );
};

const List = (props) => {
  const { countries, onItemClick, isListOpen } = props;
  const classes = useStyles();

  useEffect(() => {
    console.log('countries here', countries);
  }, [countries]);

  if (!countries) {
    return <></>;
  }

  if (!isListOpen) {
    return <></>;
  }

  return (
    <Card className={classes.list}>
      <CountryList countries={countries} onItemClick={onItemClick} />
    </Card>
  );
};

const StyledAutocomplete = (props) => {
  const classes = useStyles();
  const {
    value,
    onChange,
    error,
    helperText,
    showCloseIcon,
    countries,
    onItemClick,
    isListOpen,
    onFocusSearchField,
    onBlurSearchField,
    containerStyle,
  } = props;
  const renderClose = showCloseIcon ? <CloseIcon /> : <></>;
  const renderArrowIcon = isListOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;

  return (
    <ClickAwayListener onClickAway={onBlurSearchField}>
      <div className={classes.container} style={{ ...containerStyle }}>
        <TextField
          classes={{ root: classes.textFieldRoot }}
          id="fullName"
          name="fullName"
          placeholder="Country"
          variant="outlined"
          fullWidth
          value={value}
          onChange={onChange}
          size="small"
          error={error}
          helperText={error ? helperText : ''}
          onFocus={onFocusSearchField}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {renderClose}
                {renderArrowIcon}
              </InputAdornment>
            ),
          }}
        />
        <List countries={countries} onItemClick={onItemClick} isListOpen={isListOpen} />
      </div>
    </ClickAwayListener>
  );
};

export default StyledAutocomplete;
