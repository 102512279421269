import React, { useMemo } from 'react';
import moment from 'moment';
import { Typography, makeStyles } from '@material-ui/core';

import ThreadCard from './thread-card';
import { chatIcon } from '../../../assets/icons';

const useStyles = makeStyles((theme) => ({
  recentThreadsRoot: {
    height: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    paddingBottom: 16,
    marginBottom: 16,
    borderBottom: '1px solid #E4E4E5',
    '& .i-chat-icon': {
      width: 24,
      height: 24,
      mask: `url(${chatIcon}) no-repeat center`,
      '-webkit-mask': `url(${chatIcon}) no-repeat center`,
      backgroundColor: '#787878',
    },
    '& .i-title': {
      fontSize: 16,
      color: '#767676',
    },
    '& .i-description': {
      fontSize: 10,
      color: '#919191',
      marginBottom: 12,
    },
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    height: 'calc(100% - 60px)',
    overflow: 'auto',
    '&>a': {
      borderBottom: '1px solid #E4E4E5',
    },
    [theme.breakpoints.down('md')]: {
      height: 'initial',
    },
  },
}));

export const RecentThreads = ({ list = [], notebookList = [] }) => {
  const classes = useStyles();

  const sortedByDateList = useMemo(
    () =>
      list
        .sort(({ updatedAt: updatedAtA }, { updatedAt: updatedAtB }) =>
          moment(updatedAtA) > moment(updatedAtB) ? -1 : 1,
        )
        .slice(0, 10)
        .map((details) => {
          const notebookName = notebookList.find(({ id }) => id === details.notebookId)?.name || '';
          return { ...details, notebookName };
        }),
    [list, notebookList],
  );

  return (
    <div className={classes.recentThreadsRoot}>
      <div className={classes.header}>
        <span className="i-chat-icon" />
        <Typography className="i-title">Recent Chats</Typography>
      </div>
      <div className={classes.cardsContainer}>
        {sortedByDateList.map(({ id, ...threadDetails }) => (
          <ThreadCard key={id} id={id} {...threadDetails} />
        ))}
      </div>
    </div>
  );
};
