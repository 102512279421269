import React from 'react';
import { Typography, TextField, Dialog, Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const DeleteSpaceDialog = ({
  deleteDialogOpen,
  handleClose,
  deleteBoardInput,
  deleteBoardClick,
}) => {
  return (
    <div>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'sm'}
        PaperProps={{
          style: {
            borderRadius: 16,
          },
        }}
      >
        <div
          style={{
            padding: ' 2% 2%',
            display: 'grid',
            gridTemplateColumns: '90% 10%',
            alignItems: 'center',
            minHeight: '30px',
            borderBottom: '1px solid rgba(0,0,0,0.12)',
          }}
        >
          <div
            style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '28px',
              textAlign: 'center',
              marginLeft: '50px',
              color: 'rgba(0, 0, 0)',
            }}
          >
            Delete Whiteboard?
          </div>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{ marginRight: 10, cursor: 'pointer' }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div style={{ margin: '29px', marginBottom: '59px' }}>
          <div className="deleteContentText">
            <div>Are you sure you want to delete {deleteBoardInput}?</div>
            <div className="deleteTextRed">(You can’t undo this action once deleted)</div>
          </div>
        </div>

        <div
          style={{
            borderTop: '1px solid rgba(0,0,0,0.12)',
            flex: '0 0 auto',
            display: 'flex',
            padding: '12px',
            paddingBottom: '21px',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={handleClose}
            style={{
              width: '100px',
              height: '36px',
              borderRadius: '4px',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              color: 'rgba(0, 0, 0, 0.38)',
            }}
          >
            CANCEL
          </Button>

          <Button
            style={{
              marginLeft: '5px',
              width: '100px',
              height: '36px',
              background: '#335AFB',
              boxShadow: '0px 0.3px 0.5px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.2)',
              borderRadius: '4px',
              color: 'white',
            }}
            onClick={deleteBoardClick}
          >
            DELETE
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default DeleteSpaceDialog;
