import React from 'react';
import { useUnauthenticatedAccountStyles } from './component.style';
import OutlookIcon from '../../../../../../../assets/icons/outlookIcon';

export const UnauthenticatedAccount = (props) => {
  const { microsoftAuthenticatedHandler } = props;
  const classes = useUnauthenticatedAccountStyles();

  return (
    <div className={classes.msContentContainer}>
      <div className={classes.connectToCalendarText}>
        Connect a calendar to view meetings and start meetings
      </div>
      <div className={classes.iconContainerStyle} onClick={microsoftAuthenticatedHandler}>
        <OutlookIcon />
        <div className={classes.iconTextStyle}>Exchange Calendar</div>
      </div>
    </div>
  );
};
