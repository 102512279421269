import React from 'react';
import { Card, Checkbox, makeStyles, MenuItem, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  listItemContainer: {
    display: 'flex',
    padding: '0.5rem 0',
    cursor: 'pointer',
  },
  emailField: {
    fontSize: 12,
    color: '#222222',
    opacity: 0.5,
  },
  container: {
    marginTop: '0.25rem',
    maxHeight: '11rem',
    overflow: 'auto',
    position: 'absolute',
    width: '93.5%',
    zIndex: 9,
  },
  noContentContainer: {
    height: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
}));

const ListItems = (props) => {
  const classes = useStyles();
  const { items, selectedList, onlistItemClick } = props;

  let content = items.map((option) => (
    <MenuItem
      key={option.user.email}
      onClick={() => {
        onlistItemClick(option);
      }}
    >
      <div>
        <Checkbox
          checked={selectedList.map((item) => item.user.id).includes(option.user.id)}
          color="primary"
        />
        <span style={{ marginLeft: 5 }}>{option.user.name}</span>
        <div className={classes.emailField}>{option.user.email}</div>
      </div>
    </MenuItem>
  ));

  const noContentMessage = (
    <div className={classes.noContentContainer}>
      <Typography> Not available</Typography>
    </div>
  );

  const displayContent = items.length ? content : noContentMessage;

  return displayContent;
};

export default ListItems;
