import React from 'react';
import msOfficeTeamsIconWhite from '../../../../assets/svg/msOfficeTeamsIconWhite.svg';
import outlookIconWhite from '../../../../assets/svg/outlookIconWhite.svg';

export const EventTypeContainer = (props) => {
  const { eventInfo } = props;

  const imageHandler = () => {
    if (eventInfo.onlineMeeting?.joinUrl.toLowerCase().includes('teams')) {
      return msOfficeTeamsIconWhite;
    } else {
      return outlookIconWhite;
    }
  };

  return (
    <div style={{ marginBottom: 'auto' }}>
      <img src={imageHandler()} alt="img" width="24px" />
    </div>
  );
};
