import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SSE } from 'sse.js';

import { baseService } from './base.service';
import { SELF_LEARN } from '../Utils';
import { getUserId } from '../Utils/authentication-access';

const roles = { TEACHER: 'TEACHER', LEARNER: 'LEARNER' };

const uploadPdf = async ({ file, notebookId, threadId }) => {
  const url = SELF_LEARN.pdfUpload();
  const userId = getUserId();
  const formData = new FormData();
  formData.append('userId', userId);
  formData.append('file', file);
  formData.append('role', roles.TEACHER);
  notebookId && formData.append('notebookId', notebookId);
  threadId && formData.append('threadId', threadId);
  const response = await baseService.makePostRequest(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    customMessage: 'Failed to upload the pdf',
  });
  return response;
};

export const useUploadPdf = (options) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: uploadPdf,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getNotebookThreads'] }),
    ...options,
  });
};

export const useAsk = () => {
  const [isLoading, setIsLoading] = useState(false);

  const ask = useCallback(
    ({ query, action, threadId, teacher, onStart, onMessage, onFinish, onError }) => {
      setIsLoading(true);
      onStart && onStart();
      const url = SELF_LEARN.ask();
      const userId = getUserId();
      let isInProgress = false,
        progressTimer;

      const eventSource = new SSE(url, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        payload: JSON.stringify({ userId, threadId, query, action, teacher, role: roles.TEACHER }),
      });

      const startProgressTimer = () => {
        clearTimeout(progressTimer);
        progressTimer = setTimeout(() => {
          if (!isInProgress) {
            setIsLoading(false);
            eventSource.close();
            const error = new Error("Didn't receive a response in a timely manner");
            console.error('[Ask]', error);
            onError && onError(error);
          }
          isInProgress = false;
        }, 30000);
      };

      startProgressTimer();

      eventSource.addEventListener('error', (error) => {
        clearTimeout(progressTimer);
        setIsLoading(false);
        console.error('[Ask]', error);
        onError && onError(error);
      });

      eventSource.addEventListener('message', (e) => {
        startProgressTimer();
        if (e.data === '[DONE]') {
          console.log('[Ask] Done');
          clearTimeout(progressTimer);
          setIsLoading(false);
          onFinish && onFinish();
          return;
        }
        const message = JSON.parse(e.data).choices[0]?.delta?.content;
        message && onMessage && onMessage(message);
      });

      eventSource.stream();
    },
    [],
  );

  return { ask, isLoading };
};

export const useQueryWelcomeMessage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const queryWelcomeMessage = useCallback(
    ({ persona, language, onStart, onMessage, onFinish, onError }) => {
      setIsLoading(true);
      onStart && onStart();
      const url = SELF_LEARN.welcomeMessage();
      const userId = getUserId();
      let isInProgress = false,
        progressTimer;

      const eventSource = new SSE(url, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        payload: JSON.stringify({ userId, role: roles.TEACHER, persona, language }),
      });

      const startProgressTimer = () => {
        clearTimeout(progressTimer);
        progressTimer = setTimeout(() => {
          if (!isInProgress) {
            setIsLoading(false);
            eventSource.close();
            const error = new Error("Didn't receive a response in a timely manner");
            console.error('[Ask]', error);
            onError && onError(error);
          }
          isInProgress = false;
        }, 30000);
      };

      startProgressTimer();

      eventSource.addEventListener('error', (error) => {
        clearTimeout(progressTimer);
        setIsLoading(false);
        console.error('[Welcome Message]', error);
        onError && onError(error);
      });

      eventSource.addEventListener('message', (e) => {
        startProgressTimer();
        if (e.data === '[DONE]') {
          console.log('[Welcome Message] Done');
          clearTimeout(progressTimer);
          setIsLoading(false);
          onFinish && onFinish();
          return;
        }
        const message = JSON.parse(e.data).choices[0]?.delta?.content;
        message && onMessage && onMessage(message);
      });

      eventSource.stream();
    },
    [],
  );

  return { queryWelcomeMessage, isLoading };
};

const CreateYoutubeChat = async ({ youtubeVideoLink, notebookId }) => {
  const url = SELF_LEARN.createYoutubeChat();
  const userId = getUserId();
  const response = await baseService.makePostRequest(
    url,
    {
      youtubeVideoLink,
      notebookId,
      userId,
      role: roles.TEACHER,
    },
    { customMessage: 'Failed to create the YouTube chat' },
  );
  return response;
};

export const useCreateYoutubeChat = (options) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: CreateYoutubeChat,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getNotebookThreads'] }),
    ...options,
  });
};
