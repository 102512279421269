import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { useMediaQuery } from '@material-ui/core';

const Search = (props) => {
  const [searchText, setSearchText] = useState('');
  const isMobileView = useMediaQuery('(max-width:600px)');

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          background: `${props.background ? props.background : 'rgba(0, 0, 0, 0.12)'}`,
          borderRadius: '4px',
          display: 'flex',
          alignItems: 'center',
          height: `${props.height ? props.height : '31px'}`,
          fontSize: '16px',
        }}
      >
        <SearchIcon style={{
          //  paddingLeft: '10px',
          width: '50px'
        }} />
        <input
          style={{
            border: 'none',
            width: `${isMobileView ? '225px' : '100%'}`,
            outline: 'none',
            background: 'rgba(0, 0, 0, 0.004)',
            fontSize: '16px',
          }}
          type="text"
          // placeholder={props.searchText || "Search here..."}
          placeholder={"Search here..."}
          value={searchText}
          onChange={(event) => {
            setSearchText(event.target.value);
            props.onEnterSearchText(event.target.value);
          }}
        />
        {/* //TODO if required in further */}
        {/* <span style={{ padding: "24px", color: "#335AFB" }}>SEARCH</span> */}
      </div>
    </div>
  );
};

export default Search;
