import ACTION_TYPES from '../actions/type.action';

/**
 * communicationIdentity = {
 *   communicationUserId: '',
 *   accessToken: '',
 * }
 */
const initialState = {
  isAuthenticated: false,
  me: null,
  teamsMeeting: {
    url: '',
  },
  communicationIdentity: null,
  outlookEvents: [],
  outlookEventsDayWise: null,
};

const microsoftReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPES.SET_MICROSOFT_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: payload,
      };
    case ACTION_TYPES.SET_MICROSOFT_ME:
      return {
        ...state,
        me: payload,
      };
    case ACTION_TYPES.SET_MICROSOFT_TEAMS_MEETING:
      return {
        ...state,
        teamsMeeting: { ...payload },
      };
    case ACTION_TYPES.RESET_MICROSOFT_TEAMS_MEETING:
      return {
        ...state,
        teamsMeeting: {
          url: '',
        },
      };
    case ACTION_TYPES.SET_MICROSOFT_COMMUNICATION_IDENTITY:
      return {
        ...state,
        communicationIdentity: payload,
      };

    case ACTION_TYPES.RESET_MICROSOFT_COMMUNICATION_IDENTITY:
      return {
        ...state,
        communicationIdentity: null,
      };

    case ACTION_TYPES.SET_OUTLOOK_EVENTS:
      return {
        ...state,
        outlookEvents: [...payload.outlookEvents],
      };

    case ACTION_TYPES.SET_OUTLOOK_EVENTS_DAYWISE:
      return {
        ...state,
        outlookEventsDayWise: payload,
      };

    default:
      return { ...state };
  }
};

export default microsoftReducer;
