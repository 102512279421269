import React from 'react';

const NoContentIcon = () => {
  return (
    <>
      <svg
        width="46"
        height="58"
        viewBox="0 0 46 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="23.1146" cy="22.5448" r="22.4691" fill="black" fillOpacity="0.12" />
        <rect
          x="14.2993"
          y="22.0869"
          width="21.9977"
          height="30.7968"
          rx="2.19977"
          transform="rotate(2.5 14.2993 22.0869)"
          fill="#EEEEEE"
          stroke="#999999"
          strokeWidth="0.628507"
        />
        <rect
          x="1.22176"
          y="20.2446"
          width="23.2547"
          height="32.0538"
          rx="2.82828"
          transform="rotate(-7.5 1.22176 20.2446)"
          fill="#FAFAFA"
          stroke="#999999"
          strokeWidth="0.628506"
        />
        <path
          d="M1.90249 23.0077C1.72126 21.6311 2.69028 20.3682 4.06686 20.187L21.5145 17.89C22.8911 17.7087 24.1539 18.6778 24.3351 20.0543L24.9504 24.7278L2.51776 27.6811L1.90249 23.0077Z"
          fill="#EEEEEE"
        />
        <rect
          x="28.8861"
          y="18.5044"
          width="0.94276"
          height="3.14253"
          rx="0.47138"
          transform="rotate(82.5 28.8861 18.5044)"
          fill="#999999"
        />
        <rect
          x="20.2761"
          y="13.2983"
          width="0.94276"
          height="3.14253"
          rx="0.47138"
          transform="rotate(-7.5 20.2761 13.2983)"
          fill="#999999"
        />
        <rect
          x="25.8"
          y="14.1562"
          width="0.94276"
          height="3.14253"
          rx="0.47138"
          transform="rotate(37.5 25.8 14.1562)"
          fill="#999999"
        />
        <rect
          x="4.86298"
          y="28.6406"
          width="18.2267"
          height="1.25701"
          rx="0.628506"
          transform="rotate(-7.5 4.86298 28.6406)"
          fill="#DCDCDC"
        />
        <rect
          x="5.68359"
          y="34.8716"
          width="18.2267"
          height="1.25701"
          rx="0.628506"
          transform="rotate(-7.5 5.68359 34.8716)"
          fill="#DCDCDC"
        />
        <rect
          x="6.50354"
          y="41.103"
          width="18.2267"
          height="1.25701"
          rx="0.628506"
          transform="rotate(-7.5 6.50354 41.103)"
          fill="#DCDCDC"
        />
        <rect
          x="5.19128"
          y="31.1328"
          width="6.59932"
          height="1.25701"
          rx="0.628506"
          transform="rotate(-7.5 5.19128 31.1328)"
          fill="#EEEEEE"
        />
        <rect
          x="6.01129"
          y="37.3643"
          width="6.59932"
          height="1.25701"
          rx="0.628506"
          transform="rotate(-7.5 6.01129 37.3643)"
          fill="#EEEEEE"
        />
        <rect
          x="6.83197"
          y="43.5957"
          width="6.59932"
          height="1.25701"
          rx="0.628506"
          transform="rotate(-7.5 6.83197 43.5957)"
          fill="#EEEEEE"
        />
      </svg>
    </>
  );
};

export default NoContentIcon;
