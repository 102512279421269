import { makeStyles } from '@material-ui/core';

export const useVideoCallLinksContentStyles = makeStyles(() => ({
  container: {
    padding: '1rem',
    width: '24rem',
  },
  topbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rightColumn: {
    display: 'flex',
    gap: '0.5rem',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    paddingTop: '1.5rem',
  },
  displayLink: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '1rem',
  },
  icon: {
    cursor: 'pointer',
  },
  button: {
    width: '8rem',
  },
  linkText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));
