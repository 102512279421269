import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Button,
  Hidden,
  makeStyles,
  Snackbar,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';

import { PairBoardContainer } from '../../../components/pair-board/container';
import NewShareDialog from '../ShareDialog/NewShareDialog/NewShareDialog';
import { checkIsAuthUser, getSpaceId } from '../../../Utils/authentication-access';
import { activeTeamMembersInfo } from '../../../store/actions/spaces.action';
import { spacesService } from '../../../services/spaces.service';
import ShareMembers from '../../../assets/icons/ShareMembers';
import './PublishClassRight.css';
import PublishclassNew from './publishclassNew';
import SupportMenu from '../../../components/sideBar/SupportMenu';

const useStyles = makeStyles({
  buttonsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
    width: '100%',
    height: '100%',
    // padding: '4px 10px',
  },
  contentButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  shareBtnStyle: {
    textTransform: 'none',
    height: '31px',
    boxShadow: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '12px',
  },
  popupBoxStyle: {
    width: '300px',
  },
  activeMembersCounter: {
    background: 'black',
    color: 'white',
    borderRadius: '50%',
    height: '25px',
    width: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '8.00401px',
  },
  imageTagStyle: {
    width: '25px',
    height: '25px',
  },
  activeUserImgContainer: {
    display: 'flex',
    gap: '4px',
  },
});

const PublishClassRight = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isSmallMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isPortrait = useMediaQuery('(orientation: portrait)');

  const classes = useStyles({ isMobile });
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [openErrMsg, setOpenErrMsg] = useState(false);
  const activeMembers = useSelector((state) => state.shareSpaceReducer.activeTeamMembers);
  const dispatch = useDispatch();
  const activeUsersInfo = useSelector((state) => state.userlistReducer);
  const colorPicker = ['#673bb7', '#ff5722', '#e91e63', '#4caf50', '46876B', 'EEA346'];

  const openDialog = () => {
    setOpenShareDialog(true);
  };

  const closeShareDialog = () => {
    setOpenShareDialog(false);
  };

  const isAuthUser = checkIsAuthUser();

  useEffect(() => {
    const activeTeamMembers = async () => {
      let spaceId = getSpaceId();
      const response = await spacesService.membersList(spaceId);
      const members = response?.data.data.members;
      dispatch(activeTeamMembersInfo(members));
    };
    if (isAuthUser) {
      activeTeamMembers();
    }
  }, []);

  const allActiveUsers = () => {
    let activeUsersNames = activeUsersInfo.map((item) => {
      return item.userName;
    });
    activeUsersNames = activeUsersNames.slice(4);
    return activeUsersNames.toString().split(',').join(', ');
  };

  return (
    <>
      <div
        className={classes.buttonsContainer}
        style={{ gap: (isMobile && isPortrait) || (isMobile && !isPortrait) ? '0' : '8px' }}
      >
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
          }}
        >
          {isAuthUser && <PublishclassNew />}
        </div>
        {!isPortrait && activeUsersInfo.length > 4 && (
          <div>
            <Tooltip title={allActiveUsers()}>
              <div className={classes.activeMembersCounter}>+ {activeUsersInfo.length - 4}</div>
            </Tooltip>
          </div>
        )}
        {!isMobile && activeUsersInfo.length > 0 && (
          <div className={classes.activeUserImgContainer}>
            {activeUsersInfo.map(
              (item, index) =>
                index < 4 && (
                  <Tooltip title={item.userName} key={item.userId}>
                    {item.imageUrl ? (
                      <Avatar
                        src={item.imageUrl}
                        className={classes.imageTagStyle}
                        alt={item.userName}
                      />
                    ) : (
                      <Avatar
                        className={classes.imageTagStyle}
                        style={{
                          fontSize: '12px',
                          background: `${colorPicker[index]}`,
                        }}
                      >
                        {item.userName.charAt(0).toUpperCase()}
                      </Avatar>
                    )}
                  </Tooltip>
                ),
            )}
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: isSmallMobile ? 'end' : 'space-between',
            gap: '8px',
            order: isMobile && isPortrait ? 2 : 0,
            flexGrow: isMobile && isPortrait ? (isAuthUser ? 1 : 'unset') : 0,
            borderTop: isSmallMobile && isAuthUser ? '1px solid' : 'none',
            paddingTop: isSmallMobile ? '8px' : '0',
            paddingBottom: isSmallMobile ? '8px' : '0',
            marginRight: isMobile && !isAuthUser ? '8px' : 0,
          }}
        >
          <Button
            className={classes.shareBtnStyle}
            variant="contained"
            color="primary"
            startIcon={<ShareMembers />}
            onClick={openDialog}
            size={isMobile ? 'small' : 'medium'}
          >
            {isAuthUser ? `${activeMembers.length} Members` : 'Share'}
          </Button>
          <props.exportPresentation />
          {isAuthUser && <PairBoardContainer canvasBoard={props.canvasBoard} />}
          {props.zoomToolbar}
        </div>
        {/* {!(isMobile && !isPortrait) && ( */}
        <div
          style={{
            order: isMobile && isPortrait ? 1 : 0,
            paddingRight: isMobile ? '10px' : '0',
          }}
        >
          <props.exportBackground />
        </div>
        {/* )} */}
        <Hidden smDown>
          <div style={{ paddingRight: '10px' }}>
            <SupportMenu isSmall={true} />
          </div>
        </Hidden>
      </div>
      <NewShareDialog open={openShareDialog} closeDialog={closeShareDialog} isMobile={isMobile} />
      <Snackbar
        message={errMsg}
        open={openErrMsg}
        onClose={() => setOpenErrMsg(false)}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </>
  );
};

export default PublishClassRight;
