import React from 'react';
import { styled, Box, Button, Typography } from '@material-ui/core';

const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const Title = styled(Typography)({
  fontWeight: '500',
  color: '#000000DE',
});

const Description = styled(Typography)({
  fontSize: 12,
  color: '#00000099',
  textAlign: 'center',
});

const StyledButton = styled(Button)({
  padding: 24,
  border: '1px solid #0000001A',
  borderRadius: 12,
  backgroundColor: '#fff !important',
  '&:hover': {
    borderColor: '#335AFB',
  },
});

const ScreenShareMethod = ({ title, description, icon, onClick }) => (
  <StyledContainer>
    <StyledButton variant="outlined" onClick={onClick}>
      {icon}
    </StyledButton>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </StyledContainer>
);

export default ScreenShareMethod;
