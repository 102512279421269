import React from 'react';
import { UnauthenticatedAccount } from './component';
import { useMicrosoftAuth } from '../../../../../../../Utils/microsoft/useMicrosoftAuth';
import { useLastPageVisited } from '../../../../../../../Utils/history/useLastPageVisited';

export const UnauthenticatedAccountContainer = () => {
  const { initiateMicrosoftOAuthAuthorization } = useMicrosoftAuth();
  const { setPersonalCalendarAsLastVisited } = useLastPageVisited();

  const microsoftAuthenticatedHandler = () => {
    setPersonalCalendarAsLastVisited();
    initiateMicrosoftOAuthAuthorization();
  };

  return <UnauthenticatedAccount microsoftAuthenticatedHandler={microsoftAuthenticatedHandler} />;
};
