import React, { useCallback, useEffect, useState } from 'react';
import MainSideBarItem from './MainSideBarItem';
import MyTeamIcon from '../../assets/icons/myTeamIcon';
import ControllerIcon from '../../assets/icons/controllerIcon';
import { Divider, makeStyles } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import userService from '../../services/user.service';
import { setUserId } from '../../Utils/authentication-access';
import logoTeamhub from '../../assets/img/logoTeamhub.svg';
import birdaiLogo from '../../assets/svg/birdaiLogo.svg';
import ProfileDropdown from '../../components/sideBar/UserProfile/ProfileDropdown';
import SupportMenu from '../../components/sideBar/SupportMenu';
import { HelpWhiteIcon } from '../../assets/icons/HelpWhiteIcon';
import notesIcon from '../../assets/icons/smart-notes';
import gradientBackground from '../../assets/svg/gradient-lines-blur.svg';
import SelfLearnIcon from '../../assets/icons/self-learn';
import HomeOutlinedIcon from '../../assets/icons/sidebar/homeOutlinedIcon';
import HomeContainedIcon from '../../assets/icons/sidebar/homeContainedIcon';
import CanvasContainedIcon from '../../assets/icons/sidebar/canvasContainedIcon';
import CanvasOutlinedIcon from '../../assets/icons/sidebar/canvasOutlinedIcon';
import FilesContainedIcon from '../../assets/icons/sidebar/filesContainedIcon';
import FilesOutlinedIcon from '../../assets/icons/sidebar/filesOutlinedIcon';
import BirdaiLogo from '../../assets/icons/sidebar/birdAILogo';
import { ENVIRONMENT } from '../../config';

const Help_Center = 'https://www.birdai.com/support/';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: '100',
  },
  secondContainer: {
    display: 'flex',
    height: '100%',
  },
  contentDev: {
    backgroundColor: '#EDEFF1',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    height: 'calc(100% - 5rem)',
  },
  loadingGrid: {
    height: '100%',
    width: '100%',
    display: 'grid',
    gridRowGap: '10px',
    gridTemplateRows: 'auto auto',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
  },
  controllerSidebarMain: {
    display: 'flex',
    width: '60px',
    height: '100%',
    zIndex: '9998',
    position: 'absolute',
    boxShadow: '2px 2px .3px rgba(0, 0, 0, .1), 0 2px 2px rgba(0, 0, 0, .2)',
  },
  controllerSidebarSubContainer: {
    width: '60px',
    textAlign: 'center',
    height: '100%',
    position: 'fixed',
    // top: '52px',
    background: '#fff',
  },

  controllerLauncherSidebarSubContainer: {
    width: '60px',
    textAlign: 'center',
    height: '100%',
    position: 'fixed',
    background: 'rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(87.9545px)',
  },

  mobileMenuFooter: {
    display: 'grid',
    gridGap: '27px',
    position: 'absolute',
    bottom: 0,
    left: '24px',
  },
  brandLogo: {
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  helpIcon: {
    cursor: 'pointer',
  },
  controllerSidebarGradient: {
    backgroundImage: `url(${gradientBackground})`,
    backgroundSize: 'cover',
  },
  divider: {
    background: '#ccc',
    width: '48px',
    margin: 'auto !important',
  },
}));

const ControllerSidebar = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [iconMyTeams, setIconMyTeams] = useState(false);
  const [iconController, setIconController] = useState(false);
  const [iconFiles, setIconFiles] = useState(false);
  const [isLearnAIActive, setIsLearnAIActive] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isNotesActive, setIsNotesActive] = useState(false);
  const isLauncherScreen = window.location.href.includes('launcher');
  const isNotesPage = location.pathname.startsWith('/controller/smart-notes');
  const isLearnAIPage = location.pathname.startsWith('/controller/learn-ai');

  const defaultPagesActive = useCallback(() => {
    setIconMyTeams(false);
    setIconController(false);
    setIsNotesActive(false);
    setIsLearnAIActive(false);
    setIconFiles(false);
  }, [setIconMyTeams, setIconController]);

  const iconMyTeamsClick = useCallback(() => {
    defaultPagesActive();
    setIconMyTeams(true);
    // history.push('/controller/Wbflow');
    history.push('/controller/spaces');
  }, [defaultPagesActive, history]);

  const iconControllerClick = useCallback(() => {
    defaultPagesActive();
    setIconController(true);
    history.push('/controller/launcher');
  }, [defaultPagesActive, history]);

  const smartNotesClickHandler = useCallback(() => {
    defaultPagesActive();
    setIsNotesActive(true);
    history.push('/controller/smart-notes/notebooks');
  }, [defaultPagesActive, history]);

  const notesClickHandler = useCallback(() => {
    defaultPagesActive();
    setIsLearnAIActive(true);
    history.push('/controller/learn-ai/notes');
  }, [defaultPagesActive, history]);

  const iconFilesClick = useCallback(() => {
    defaultPagesActive();
    setIconFiles(true);
    history.push('/controller/files');
  }, [defaultPagesActive, history]);

  const setOption = useCallback(() => {
    switch (location.pathname) {
      // case '/controller/Wbflow':
      case '/controller/spaces':
        iconMyTeamsClick();
        break;
      case '/controller/launcher':
        iconControllerClick();
        break;
      case '/controller/files':
        iconFilesClick();
        break;
      default:
        break;
    }
    if (location.pathname.startsWith('/controller/smart-notes')) {
      defaultPagesActive();
      setIsNotesActive(true);
    }
    if (location.pathname.startsWith('/controller/learn-ai')) {
      defaultPagesActive();
      setIsLearnAIActive(true);
    }
  }, [location.pathname, iconMyTeamsClick, iconControllerClick, defaultPagesActive]);

  const getUserDetails = useCallback(async () => {
    const response = await userService.getUserDetails();
    setUserData(response?.data?.user);
    setUserId(response?.data?.user.id);
    return response?.data?.user;
  }, []);

  useEffect(() => {
    setOption();
    getUserDetails();
  }, [getUserDetails, setOption]);

  const handleClick = (event) => {
    window.open(Help_Center, '_blank');
  };

  return (
    <>
      {/* /** side bar */}

      <div className={classes.controllerSidebarMain}>
        <div
          className={
            isLauncherScreen
              ? classes.controllerLauncherSidebarSubContainer
              : classes.controllerSidebarSubContainer
          }
        >
          <div className={classes.brandLogo}>
            {/* <img src={birdaiLogo} alt="logo" /> */}
            <BirdaiLogo
              fill={location.pathname === '/controller/launcher' ? '#fff' : 'rgba(51, 90, 251, 1)'}
            />
          </div>
          {location.pathname === '/controller/launcher' ? (
            ''
          ) : (
            <Divider className={classes.divider} />
          )}

          <MainSideBarItem
            // Icon={HomeIcon}
            icon={
              iconController ? (
                <HomeContainedIcon fill="#fff" />
              ) : (
                <HomeOutlinedIcon stroke="#000" />
              )
            }
            title="Home"
            active={iconController}
            onClick={iconControllerClick}
          />
          <MainSideBarItem
            // Icon={MyTeamIcon}

            icon={
              location.pathname === '/controller/launcher' ? (
                <CanvasOutlinedIcon stroke="#fff" />
              ) : iconMyTeams ? (
                <CanvasContainedIcon fill=" rgba(51, 90, 251, 1)" />
              ) : (
                <CanvasOutlinedIcon stroke="#000" />
              )
            }
            iconController
            title="Canvas"
            active={iconMyTeams}
            onClick={iconMyTeamsClick}
          />

          {ENVIRONMENT === 'development' && (
            <MainSideBarItem
              icon={
                location.pathname === '/controller/launcher' ? (
                  <FilesOutlinedIcon stroke="#fff" />
                ) : iconFiles ? (
                  <FilesContainedIcon fill=" rgba(51, 90, 251, 1)" />
                ) : (
                  <FilesOutlinedIcon stroke="#000" />
                )
              }
              iconController
              title="Files"
              active={iconFiles}
              onClick={iconFilesClick}
            />
          )}

          {/* 
             INFO: Note Sidebar
          <MainSideBarItem
            // Icon={notesIcon}
            icon={
              location.pathname === '/controller/launcher' ? (
                <FilesOutlinedIcon stroke="#fff" />
              ) : isNotesActive ? (
                <FilesContainedIcon fill=" rgba(51, 90, 251, 1)" />
              ) : (
                <FilesOutlinedIcon stroke="#000" />
              )
            }
            title="Note"
            active={isNotesActive}
            onClick={smartNotesClickHandler}
            style={{ marginTop: 6 }}
          /> */}

          {/* <MainSideBarItem
            Icon={SelfLearnIcon}
            title="Notes"
            active={isLearnAIActive}
            onClick={notesClickHandler}
            style={{ marginTop: 10 }}
          />  */}

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '14.5px',
              position: 'fixed',
              bottom: '21px',
              left: '14px',
            }}
          >
            {isLauncherScreen ? (
              <div onClick={handleClick} className={classes.helpIcon}>
                <HelpWhiteIcon color={'white'} width={32} />
              </div>
            ) : (
              <div>
                <SupportMenu isSmall={true} />
              </div>
            )}
            <div>
              {userData && (
                <ProfileDropdown profileData={userData} callUserDetails={getUserDetails} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ControllerSidebar);
