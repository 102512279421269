import React, { useEffect, useState } from 'react';
import classes from './launcher.module.css';
import Grid from '@material-ui/core/Grid';
import InstantMeetingIcon from '../../assets/icons/instantMeetingIcon';
import DropFileIcon from '../../assets/icons/dropFileIcon';
import DropLinkIcon from '../../assets/icons/dropLinkIcon';
import PlusIcon from '../../assets/icons/plusIcon';
import StartInstantMeetingDialog from './dialogs/StartInstantMeetingDialog';
import deviceService from '../../services/device.service';
import { useNotificationDispatcher } from '../../store/dispatcher/useNotificationDispatcher';
import {
  getAnonymousUserToken,
  setInstantMeetingLink,
  setInstantMeetingName,
} from '../../Utils/authentication-access';
import { authService } from '../../services/auth.service';
import { useHistory } from 'react-router';
import { ScreenshareContainer } from './options/screenshare/container';
import { useMediaQuery, useTheme } from '@material-ui/core';
import HostMeetingDialog from './dialogs/HostMeetingDialog';
import { RemoteControlDialogContainer } from './remote-control-dialog/container';
import { useStyledDialog } from '../../components/common/dialog/styled-dialog/use-styled-dialog';
import { RemoteControllerIcon } from '../../assets/icons';
import { useDialogDispatcher } from '../../store/dispatcher/useDialogDispatcher';
import { isMobile } from 'react-device-detect';
import DeviceDetector from 'device-detector-js';
import { useDispatch, useSelector } from 'react-redux';

import { EVENTS } from '../../constants/events';
import { showMaxSimultaneousUsageLimitExhaustedDialog } from '../../store/actions/notification.action';
import { useDialogSelector } from '../../store/selector/useDialogSelector';
import BirdAssistanceIcon from '../../assets/icons/birdAssistanceIcon';
import NewWhiteboardIcon from '../../assets/icons/newWhiteboardIcon';

const LauncherFeatures = ({
  socket,
  isDeviceConnected,
  isLoginScreen,
  deviceId,
  sendMessageToDevice,
}) => {
  const [isInstantMeeting, setIsInstantMeeting] = useState(false);
  const [meetingName, setMeetingName] = useState('');
  const [meetingLink, setMeetingLink] = useState('');
  const { dispatchSetSnackbar, dispatchShowFeatureLockedDialog } = useNotificationDispatcher();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [isHostMeetingDialogOpen, setIsHostMeetingDialogOpen] = useState(false);
  const { dispatchSetIsOpenRemoteControlDialog } = useDialogDispatcher();
  const deviceDetector = new DeviceDetector();
  const device = deviceDetector.parse(navigator.userAgent);
  const isTablet = device.device.type.includes('tablet');
  const updatedCount = useSelector((state) => state.licenseReducer.licenseDetails);
  const dispatch = useDispatch();
  const { remoteControlDialog } = useDialogSelector();

  useEffect(() => {
    if (!isDeviceConnected) {
      dispatchSetIsOpenRemoteControlDialog({
        isOpen: false,
        isAnonymousUser: true,
      });
    }
  }, [isDeviceConnected]);

  useEffect(() => {
    const init = async () => {
      if (socket) {
        socket.on('exception', getLicenseCheck);
      }
    };
    init();

    return () => {
      if (socket) {
        socket.off('exception', getLicenseCheck);
      }
    };
  }, []);

  const getLicenseCheck = (msg) => {
    console.log('license came', msg.type);
    if (msg.error.type === EVENTS.SIMULTANEOUS_LIMIT_EXHAUSTED) {
      dispatch(showMaxSimultaneousUsageLimitExhaustedDialog());
    }
    if (msg.error.type === EVENTS.FEATURE_LOCKED) {
      dispatchShowFeatureLockedDialog({ feature: 'random' });
    }
  };

  const onMeetingStartedOnDevice = () => {
    setIsHostMeetingDialogOpen(true);
  };

  const closeHostDialogHandler = () => {
    setIsHostMeetingDialogOpen(false);
  };

  const launchMeetingHere = () => {
    window.open(meetingLink, '_blank');
    closeHostDialogHandler();
  };

  const instantMeetingHandler = () => {
    if (updatedCount.data.joinMeeting.enabled) {
      setIsInstantMeeting(true);
    } else {
      dispatchShowFeatureLockedDialog({ feature: 'room-controller' });
    }
  };

  const closeInstantMeetingHandler = () => {
    console.log('ended');
    setIsInstantMeeting(false);
  };

  const openRemoteControllerDialog = () => {
    dispatchSetIsOpenRemoteControlDialog({
      isOpen: true,
      isAnonymousUser: !!isLoginScreen,
    });
  };

  const handleOpenRemoteControlDialog = () => {
    closeHostDialogHandler();
    sendRemoteStartMessageToDevice();
  };

  const sendRemoteStartMessageToDevice = () => {
    sendMessageToDevice('REMOTE_CONTROL', {
      type: 'REMOTE_START',
    });
  };

  const getAccessTokenHandler = async () => {
    try {
      const response = await authService.anonymousUser();
      return response.data.data.token;
    } catch (error) {
      console.log('error', error);
    }
  };

  const sendLinkHandler = async () => {
    setInstantMeetingLink(meetingLink);
    setInstantMeetingName(meetingName);
    if (isLoginScreen) {
      /**
       * anonymous user
       */
      let accessToken = getAnonymousUserToken();
      if (accessToken) {
        console.log('accessToken', accessToken);
        try {
          const response = await deviceService.anonymousUserStartMeetingOnDevice(
            meetingName,
            meetingLink,
            accessToken,
          );
          dispatchSetSnackbar({ message: 'Meeting started on Bird device' });
          setIsInstantMeeting(false);
          //TODO : FEATURE IMPLEMENTATION (meeting controles page redirection)
          // history.push('/live-meeting');
          onMeetingStartedOnDevice();
        } catch (error) {
          console.log('error', error);
          dispatchSetSnackbar({ message: error.response.data.message });
        }
      }
    } else {
      /**
       * auth user
       */
      try {
        const response = await deviceService.startMeetingOnDevice(meetingName, meetingLink);
        dispatchSetSnackbar({ message: 'Meeting started on Bird device' });
        setIsInstantMeeting(false);
        //TODO : FEATURE IMPLEMENTATION (meeting controles page redirection)
        // history.push('/live-meeting');
        onMeetingStartedOnDevice();
      } catch (error) {
        console.log('error', error);
        dispatchSetSnackbar({ message: error.response.data.message });
      }
    }
  };

  return (
    <>
      <div
        className={
          isDeviceConnected ? classes.featuresContainerActive : classes.featuresContainerDisable
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={6} sm={4}>
            <div className={classes.smallBox} onClick={instantMeetingHandler}>
              <InstantMeetingIcon fill={false ? '#335AFB' : '#fff'} />

              <div className={classes.smallBoxText}>Start Meeting</div>
            </div>
          </Grid>
          {/* <Grid item xs={6} sm={4} className={isDeviceConnected ? classes.disable : classes.active}>
            <div className={classes.smallBox}>
              <div>
                <PlusIcon fill={false ? '#335AFB' : '#fff'} />
              </div>
              <div className={classes.smallBoxText}>Start instant space</div>
            </div>
          </Grid> */}

          <Grid
            item
            xs={6}
            sm={4}
            // className={
            //   isDeviceConnected
            //     ? isMobile || isTablet
            //       ? classes.disable
            //       : ''
            //     : isMobile || isTablet
            //     ? classes.active
            //     : ''
            // }
            className={isDeviceConnected ? classes.disable : classes.active}
            style={{ pointerEvents: 'none' }}
          >
            <ScreenshareContainer
              isDeviceConnected={isDeviceConnected}
              roomId={`device-${deviceId}`}
              onlyOutgoing={true}
              isMobile={isMobile}
            />
          </Grid>

          {/* TODO: hiding this for just now, just to make the UI layout in grid and since this is not in used yet */}
          {/* <Grid item xs={6} sm={4} className={isDeviceConnected ? classes.disable : classes.active}>
            <div className={classes.smallBox}>
              <DropLinkIcon fill={false ? '#335AFB' : '#fff'} />
              <div className={classes.smallBoxText}>Drop link</div>
            </div>
          </Grid> */}
          <Grid item xs={6} sm={4}>
            <div className={classes.smallBox} onClick={handleOpenRemoteControlDialog}>
              <RemoteControllerIcon fill={false ? '#335AFB' : '#fff'} />
              <div className={classes.smallBoxText}>Remote Controller</div>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} className={isDeviceConnected ? classes.disable : classes.active}>
            <div className={classes.smallBox}>
              <DropFileIcon fill={false ? '#335AFB' : '#fff'} />
              <div className={classes.smallBoxText}>Drop file </div>
            </div>
          </Grid>

          <Grid item xs={6} sm={4} className={isDeviceConnected ? classes.disable : classes.active}>
            <div className={classes.smallBox}>
              <BirdAssistanceIcon fill={false ? '#335AFB' : '#fff'} />
              <div className={classes.smallBoxText}>Bird Assistance </div>
            </div>
          </Grid>

          <Grid item xs={6} sm={4} className={isDeviceConnected ? classes.disable : classes.active}>
            <div className={classes.smallBox}>
              <NewWhiteboardIcon fill={false ? '#335AFB' : '#fff'} />
              <div className={classes.smallBoxText}>New Whiteboard </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <StartInstantMeetingDialog
        open={isInstantMeeting}
        closeHandler={closeInstantMeetingHandler}
        meetingNameHandler={setMeetingName}
        meetingName={meetingName}
        meetingLinkHandler={setMeetingLink}
        meetingLink={meetingLink}
        sendLinkHandler={sendLinkHandler}
      />

      <HostMeetingDialog
        open={isHostMeetingDialogOpen}
        onClose={closeHostDialogHandler}
        // launchMeetingHere={launchMeetingHere}
        handleOpenRemoteControlDialog={handleOpenRemoteControlDialog}
      />
    </>
  );
};

export default LauncherFeatures;
