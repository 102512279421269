import React from 'react';
import { StyledDialog } from './component';

export const StyledDialogContainer = (props) => {
  const { onClose, contextStyle, ...rest } = props;
  return (
    <StyledDialog {...rest} onClose={onClose} contextStyle={{ minWidth: '40rem', ...contextStyle }}>
      {props.children}
    </StyledDialog>
  );
};
