import ACTION_TYPES from '../actions/type.action';

const DEFAULT_DURATION = 2000;
const DEFAULT_SNACKBAR_STATE = {
  message: '',
  isOpen: false,
  duration: DEFAULT_DURATION,
};

const initialState = {
  licenseExhausted: {
    isDialogOpen: false,
    feature: '',
  },
  featureLocked: {
    isDialogOpen: false,
    feature: '',
  },
  maxSimultaneousLicenseExhausted: {
    isDialogOpen: false,
    feature: '',
  },
  DeviceLicenseExhausted: {
    isDialogOpen: false,
    feature: '',
  },
  HuddleSessionLimitExhausted: {
    isDialogOpen: false,
    feature: '',
  },

  notificationCount: 0,
  notifications: [],
  overlaySpinner: false,
  snackbar: DEFAULT_SNACKBAR_STATE,
};

export const notificationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPES.SET_SNACKBAR:
      return {
        ...state,
        snackbar: {
          message: payload.message,
          isOpen: true,
          duration: payload.duration || DEFAULT_DURATION,
        },
      };
    case ACTION_TYPES.RESET_SNACKBAR:
      return {
        ...state,
        snackbar: DEFAULT_SNACKBAR_STATE,
      };
    case ACTION_TYPES.SHOW_SPINNER:
      return {
        ...state,
        overlaySpinner: true,
      };
    case ACTION_TYPES.HIDE_SPINNER:
      return {
        ...state,
        overlaySpinner: false,
      };
    case ACTION_TYPES.SHOW_LICENSE_EXHAUSTED_DIALOG:
      return {
        ...state,
        licenseExhausted: {
          isDialogOpen: true,
          ...payload,
        },
      };
    case ACTION_TYPES.HIDE_LICENSE_EXHAUSTED_DIALOG:
      return {
        ...state,
        licenseExhausted: {
          isDialogOpen: false,
          feature: '',
        },
      };
    case ACTION_TYPES.SHOW_FEATURE_LOCKED:
      return {
        ...state,
        featureLocked: {
          isDialogOpen: true,
          ...payload,
        },
      };
    case ACTION_TYPES.HIDE_FEATURE_LOCKED:
      return {
        ...state,
        featureLocked: {
          isDialogOpen: false,
          feature: '',
        },
      };
    case ACTION_TYPES.SHOW_MAX_SIMULTANEOUS_USAGE_LIMIT_EXHAUSTED_DIALOG:
      return {
        ...state,
        maxSimultaneousLicenseExhausted: {
          isDialogOpen: true,
          ...payload,
        },
      };
    case ACTION_TYPES.HIDE_MAX_SIMULTANEOUS_USAGE_LIMIT_EXHAUSTED_DIALOG:
      return {
        ...state,
        maxSimultaneousLicenseExhausted: {
          isDialogOpen: false,
          feature: '',
        },
      };

    case ACTION_TYPES.SHOW_DEVICE_LICENSE_EXHAUSTED_DIALOG:
      return {
        ...state,
        DeviceLicenseExhausted: {
          isDialogOpen: true,
          ...payload,
        },
      };
    case ACTION_TYPES.HIDE_DEVICE_LICENSE_EXHAUSTED_DIALOG:
      return {
        ...state,
        DeviceLicenseExhausted: {
          isDialogOpen: false,
          feature: '',
        },
      };

    case ACTION_TYPES.SHOW_HUDDLE_SESSION_EXHAUSTED_DIALOG:
      return {
        ...state,
        HuddleSessionLimitExhausted: {
          isDialogOpen: true,
          ...payload,
        },
      };
    case ACTION_TYPES.HIDE_HUDDLE_SESSION_EXHAUSTED_DIALOG:
      return {
        ...state,
        HuddleSessionLimitExhausted: {
          isDialogOpen: false,
          feature: '',
        },
      };

    default:
      return state;
  }
};
