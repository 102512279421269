import React from 'react';
import Color from 'color';
import { Typography, makeStyles } from '@material-ui/core';

import { stringToHSLColor } from '../../../Utils/common-utils';

const useStyles = ({ title }) =>
  makeStyles(() => {
    let primaryColor = new Color(stringToHSLColor(title));
    primaryColor = primaryColor.desaturate(0.6).lighten(0.3).hex();
    return {
      notebookCardRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 2,
        padding: 4,
        borderRadius: 4,
        backgroundColor: '#EAEEFF',
        '& .i-icon': {
          width: 16,
          height: 16,
          borderRadius: 4,
          backgroundColor: primaryColor,
        },
        '& .i-title': {
          fontSize: 10,
          color: '#767676',
          textAlign: 'left',
        },
      },
    };
  });

const NotebookCard = ({ title }) => {
  const classes = useStyles({ title })();

  return (
    <div className={classes.notebookCardRoot}>
      <div className="i-icon" />
      <Typography className="i-title" gutterBottom={false}>
        {title}
      </Typography>
    </div>
  );
};

export default NotebookCard;
