import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';

import { InputDialog } from '../dialogs';
import { betaIcon, youtubeIcon } from '../../assets/icons';

const useStyles = ({ mini }) =>
  makeStyles((theme) => ({
    youtubeButtonRoot: {
      gap: mini ? 4 : 8,
      height: 36,
      borderRadius: '16px !important',
      border: mini ? undefined : `1px solid ${theme.palette.primary.main}`,
      fontSize: mini ? 11 : 14,
      fontWeight: 400,
      color: '#767676',
    },
  }));

export const YoutubeButton = ({ mini, onSubmit }) => {
  const classes = useStyles({ mini })();
  const [showInputDialog, setShowInputDialog] = useState(false);

  const toggleInputDialog = () => setShowInputDialog(!showInputDialog);

  return (
    <>
      <Button
        className={classes.youtubeButtonRoot}
        variant={mini ? 'text' : 'outlined'}
        startIcon={
          <img src={youtubeIcon} alt="YouTube" width={mini ? 28 : 20} height={mini ? 28 : 20} />
        }
        endIcon={
          <div
            style={{
              width: 39,
              height: 14,
              borderRadius: 7,
              backgroundColor: '#6B9F27',
              backgroundImage: `url(${betaIcon})`,
              backgroundSize: '19px 6px',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        }
        onClick={toggleInputDialog}
      >
        {mini ? 'YouTube' : 'Analyse YouTube'}
      </Button>
      {showInputDialog && (
        <InputDialog
          dialogTitle="Analyse YouTube Videos"
          inputName="youtube-url"
          inputLabel="YouTube video URL"
          submitText="ANALYSE"
          emptyValueErrorMessage="Provide a valid URL"
          onSubmit={onSubmit}
          onClose={toggleInputDialog}
        />
      )}
    </>
  );
};
