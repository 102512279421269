import ACTION_TYPE from './type.action';

/**
 *
 * @param {boolean} payload
 * @returns
 */
export const setZoomIsAuthenticated = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_ZOOM_IS_AUTHENTICATED,
    payload,
  });
};

/**
 *
 * @param {boolean} payload
 * @returns
 */
export const setZoomMe = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_ZOOM_ME,
    payload,
  });
};
