import React, { useCallback } from 'react';
import { Box, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import { BlueCheckbox } from '.';
import { SOURCE_STATUS } from '../../../../constants/smart-notes.constants';
import { useDispatch } from 'react-redux';
import { updateSource } from '../../../../store/actions/smart-notes.action';

const useStyles = ({ status }) =>
  makeStyles({
    resourceRoot: {
      display: 'flex',
      alignItems: 'start',
      gap: 12,
      minHeight: 120,
      padding: 8,
      backgroundColor: '#fff',
      '& .i-thumbnail': {
        height: '100%',
        maxWidth: 160,
        objectFit: 'contain',
      },
      '& .i-content': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        fontSize: 14,
        height: '100%',
        '& .i-title': {
          fontWeight: 700,
        },
        '& .i-description': {
          display: '-webkit-box',
          '-webkit-line-clamp': 3,
          '-webkit-box-orient': 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
  });

const Source = ({ noteId, id, thumbnailUrl, title, content, selected, status }) => {
  const dispatch = useDispatch();
  const classes = useStyles({ status })();

  const selectHandler = useCallback(() => {
    dispatch(updateSource({ noteId, id, selected: !selected }));
  }, [dispatch, id, noteId, selected]);

  if (status === SOURCE_STATUS.FAILED) return;

  return (
    <Box className={classes.resourceRoot}>
      {thumbnailUrl && <img src={thumbnailUrl} alt="Thumbnail" className="i-thumbnail" />}
      <Box className="i-content">
        <Typography className="i-title">{title}</Typography>
        {status === SOURCE_STATUS.COMPLETE ? (
          <Typography className="i-description">{content}</Typography>
        ) : (
          <Box
            style={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
      <BlueCheckbox
        checked={selected}
        onChange={selectHandler}
        disabled={status !== SOURCE_STATUS.COMPLETE}
      />
    </Box>
  );
};

export default Source;
