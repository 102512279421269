import React from 'react';
import { SidebarItemComponent } from './component';

export const SidebarItem = (props) => {
  const { onClick, ...rest } = props;
  return (
    <SidebarItemComponent onClick={onClick} {...rest}>
      {props.children}
    </SidebarItemComponent>
  );
};
