import ACTION_TYPE from "./type.action";

export const getallUser = (users) => async dispatch => {
  dispatch({
    type: ACTION_TYPE.GETALL_USER,
    payload: users
  });
};

export const addUser = user => async dispatch => {
  dispatch({
    type: ACTION_TYPE.ADD_USER,
    payload: user
  });
};

export const removeUser = user => async dispatch => {
  dispatch({
    type: ACTION_TYPE.REMOVE_USER,
    payload: user
  });
};

