
import { makeStyles } from "@material-ui/core";



export const useWorkspaceDialogStyle = makeStyles(() => ({
  dialogHeader: {
    padding: ' 2% 2%',
    display: 'grid',
    gridTemplateColumns: '90% 10%',
    alignItems: 'center',
    minHeight: '30px',
    borderBottom: '1px solid rgba(0,0,0,0.12)',
  },
  headerName: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '28px',
    textAlign: 'center',
    marginLeft: '50px',
    color: 'rgba(0, 0, 0)',
  },
  closeIcon: {
    textAlign: 'center',
    cursor: 'pointer',
    marginTop: '2px',
  },
  labelText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '16px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  footerContainer: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    flex: '0 0 auto',
    display: 'flex',
    padding: '12px',
    paddingBottom: '21px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    width: '100px',
    height: '36px',
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.38)',
  },
  saveButton: {
    marginLeft: '5px',
    width: '100px',
    height: '36px',
    background: '#335AFB',
    boxShadow: '0px 0.3px 0.5px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    color: 'white',
  },
}));