import React, { useState } from 'react';
import { ButtonBase, Typography, makeStyles } from '@material-ui/core';

import { AddIcon } from '../../../../assets/icons';
import { InputDialog } from '../../../dialogs';

const useStyles = makeStyles({
  NewNotebookRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16,
    minHeight: 180,
    borderRadius: 6,
    padding: 12,
    cursor: 'pointer',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.40) 100%), #335AFB',
    '& .i-label': {
      fontSize: 14,
      fontWeight: 700,
    },
    '&:hover': {
      filter: 'brightness(0.9)',
    },
  },
});

const NewNotebook = ({ onCreate }) => {
  const classes = useStyles();
  const [showNewNotebookDialog, setShowNewNotebookDialog] = useState(false);

  const toggleNewNotebookDialog = () => setShowNewNotebookDialog(!showNewNotebookDialog);

  return (
    <>
      {showNewNotebookDialog && (
        <InputDialog
          dialogTitle="Create Notebook"
          inputLabel="Notebook Name"
          submitText="CREATE"
          onSubmit={onCreate}
          onClose={toggleNewNotebookDialog}
        />
      )}
      <ButtonBase className={classes.NewNotebookRoot} onClick={toggleNewNotebookDialog}>
        <img src={AddIcon} alt="Add" />
        <Typography className="i-label">New Note</Typography>
      </ButtonBase>
    </>
  );
};

export default NewNotebook;
