import { useState, useEffect } from 'react';
import _ from 'lodash';
import { useMouseReceive } from './mouseReceive';
import { useUserLeft } from './user-left-socket';

export const useMouseUsers = () => {
  const [usersMouseData, setUsersMouseData] = useState([]);
  const userMouseData = useMouseReceive();
  const leftSocketID = useUserLeft();

  useEffect(() => {
    if (!leftSocketID) return;
    setUsersMouseData((usersMouse) =>
      usersMouse.filter((userMouse) => userMouse.userID !== leftSocketID),
    );
  }, [leftSocketID]);

  useEffect(() => {
    if (!userMouseData.userID) return;
    setUsersMouseData((previousUsersMouseData) => {
      const index = _.findIndex(previousUsersMouseData, { userID: userMouseData.userID });
      const updatedUsersMouseData = [...previousUsersMouseData];
      if (index === -1) {
        updatedUsersMouseData.push(userMouseData);
        return updatedUsersMouseData;
      }
      updatedUsersMouseData.splice(index, 1, { ...userMouseData });
      return updatedUsersMouseData;
    });
  }, [userMouseData]);

  return usersMouseData;
};
