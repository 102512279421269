import React, { Component } from 'react';

import { KsarModal } from '../UiComponents/Modal/KsarModal';
import { SplitView } from '../UiComponents/SplitView/SplitView';

export class KsarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
    };
  }

  setSearchValue = (searchValue) => this.setState({ searchValue });

  render() {
    return (
      <KsarModal
        setSearchValue={this.setSearchValue}
        searchValue={this.state.searchValue}
        toggleModal={this.props.toggleModal}
        open={this.props.open}
      >
        <SplitView
          searchValue={this.state.searchValue}
          canvasImage={this.props.canvasImage}
          canvasVideo={this.props.canvasVideo}
          canvasDocument={this.props.canvasDocument}
          canvasText={(data, dimensionsObj) => this.props.canvasText(data, dimensionsObj)}
        />
      </KsarModal>
    );
  }
}
