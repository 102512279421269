import React, { useCallback } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import KeywordList from './keyword-list';
import Summary from './summary';
import KeySentenceList from './key-sentence-list';
import QuestionList from './question-list';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    padding: '0px 8px',
    '& .i-timestamp': {
      fontSize: 10,
    },
    '& .i-content': {
      display: 'flex',
      flexDirection: 'column',
      gap: 32,
    },
  },
}));

export const PDFDetails = ({
  timestamp,
  summary,
  keywords = [],
  keySentences = [],
  questions = [],
  onQuestionClick,
  isYoutube,
}) => {
  const classes = useStyles();

  const scrollIntoView = useCallback((ref) => {
    if (ref) ref.scrollIntoView();
  }, []);

  if (!keywords.length && !keySentences.length && !questions.length && !summary) return null;

  return (
    <div className={classes.root} ref={scrollIntoView}>
      <Typography className="i-timestamp">{timestamp}</Typography>
      <div className="i-content">
        {!!keywords.length && <KeywordList list={keywords} isYoutube={isYoutube} />}
        {summary && <Summary>{summary}</Summary>}
        {!!keySentences.length && <KeySentenceList list={keySentences} />}
        {!!questions.length && <QuestionList list={questions} onQuestionClick={onQuestionClick} />}
      </div>
    </div>
  );
};

export default React.memo(PDFDetails);
