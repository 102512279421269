import ACTION_TYPES from './type.action';

export const activeDeviceId = (deviceId) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.PAIRED_DEVICE_ID,
    payload: deviceId,
  });
};

export const isDeviceConnected = (deviceStatus) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.DEVICE_CONNECTED,
    payload: deviceStatus,
  });
};