import BaseService from './base.service';
import { BIRDAI_SERVER_URL } from '../config';
import { get, del, post, put } from '../Utils/api';
import { getCanvasCode } from '../Utils/authentication-access';
import { constructQueryParams } from '../Utils/url';

class AuthService extends BaseService {
  constructor() {
    super(BIRDAI_SERVER_URL);
  }

  /**
   *
   * @param {{ code: string, provider: string }} payload
   * @returns
   */
  async oAuthLogin(payload) {
    try {
      const url = `${this.baseUrl}/auth/oauth-login`;
      return await post(url, payload);
    } catch (error) {
      console.error('schedules:create error', error);
      throw error;
    }
  }

  /**
   *
   * @param {{ code: string, provider: string }} payload
   * @returns
   */
  async oAuthSignup(payload) {
    try {
      const url = `${this.baseUrl}/auth/oauth-signup`;
      return await post(url, payload);
    } catch (error) {
      console.error('schedules:create error', error);
      throw error;
    }
  }

  async anonymousUser() {
    try {
      const url = `${this.baseUrl}/user/login`;
      return await post(url, { userName: 'SOME_USERNAME' });
    } catch (error) {
      console.log('error', error);
    }
  }

  /**
   *
   * @param {{
   *   code: string,
   *   encryptedHardwareId: string
   *   provider: 'MICROSOFT'
   * }} payload
   * @returns
   */
  async oAuthLinkWithDevice(payload) {
    try {
      const url = `${this.baseUrl}/auth/oauth-link-with-device`;
      return await post(url, payload);
    } catch (error) {
      console.log('error', error);
    }
  }
}

export const authService = new AuthService();
