import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { StyledDialogContainer } from '../../../common/dialog/styled-dialog/container';
import { StyledSpinner } from '../../../common/spinner/styled-spinner';
import {
  AuthenticatedFooterContent,
  AuthenticatedFooterContentRight,
  useImportOutlookEventStyles,
} from './component.style';
import { EventInfoContainer } from './event-info/container';
import { mdiRefresh } from '@mdi/js';
import StyledButton from '../../../../UIComponents/input/StyledButton';
import CachedIcon from '@material-ui/icons/Cached';

export const ImportOutlookEventDialog = (props) => {
  const {
    closeDialog,
    events,
    isHydrated,
    isMicrosoftOAuthAuthorized,
    isOpenDialog,
    isLoading,
    onClick,
    replaceAccountButtonLabel,
    revokeAccessButtonLabel,
    title,
    replaceAccount,
    onClickRevokeAccess,
    refetchOutlookEvents,
  } = props;
  const classes = useImportOutlookEventStyles();

  const authorizeMicrosoftOAuthButton = (
    <div>
      <Typography>OAuth Authentication is required to access outlook calendar events</Typography>
      <Button color="primary" variant="outlined" onClick={onClick}>
        Authorize OAuth
      </Button>
    </div>
  );

  const displayEmptyEvents = <Typography>No outlook calendar events found</Typography>;

  const displayEventsHandler = events.length
    ? events.map((eventInfo) => <EventInfoContainer eventInfo={eventInfo} />)
    : displayEmptyEvents;

  const displayLoading = (
    <div className={classes.spinner}>
      <StyledSpinner />
    </div>
  );

  const displayEvents =
    !isLoading && isHydrated ? (
      <div className={classes.eventsContainer}>{displayEventsHandler}</div>
    ) : (
      displayLoading
    );

  const refetchOutlookEventsIcon = <CachedIcon />;

  const refetchOutlookEventsButton = (
    <StyledButton
      variant="outlined"
      onClick={refetchOutlookEvents}
      className={classes.button}
      icon={refetchOutlookEventsIcon}
    >
      REFRESH
    </StyledButton>
  );

  const revokeAccessButton = (
    <Button variant="outlined" onClick={onClickRevokeAccess} className={classes.button}>
      {revokeAccessButtonLabel}
    </Button>
  );

  /**
   * hiding for now:
   */
  const replaceAccountButton = false ? (
    <Button variant="outlined" onClick={replaceAccount} className={classes.button}>
      {replaceAccountButtonLabel}
    </Button>
  ) : (
    <></>
  );

  const authenticatedFooterContent = (
    <AuthenticatedFooterContent>
      {refetchOutlookEventsButton}
      <AuthenticatedFooterContentRight>
        {revokeAccessButton}
        {replaceAccountButton}
      </AuthenticatedFooterContentRight>
    </AuthenticatedFooterContent>
  );

  const unauthenticatedFooterContent = <div>Not Authenticated</div>;

  const footerContent = (
    <div className={classes.footerContainer}>
      {isMicrosoftOAuthAuthorized ? authenticatedFooterContent : unauthenticatedFooterContent}
    </div>
  );

  return (
    <StyledDialogContainer
      open={isOpenDialog}
      onClose={closeDialog}
      title={title}
      footerContent={footerContent}
      containerStyle={{ height: '20rem' }}
    >
      {isHydrated && !isMicrosoftOAuthAuthorized && authorizeMicrosoftOAuthButton}
      {displayEvents}
    </StyledDialogContainer>
  );
};
