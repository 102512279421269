import ACTION_TYPE from './type.action';

/**
 *
 * @param {boolean} payload
 * @returns
 */
export const setMicrosoftIsAuthenticated = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_MICROSOFT_IS_AUTHENTICATED,
    payload,
  });
};

/**
 *
 * @param {boolean} payload
 * @returns
 */
export const setMicrosoftMe = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_MICROSOFT_ME,
    payload,
  });
};

/**
 *
 * @param {{ url: string }} payload
 * @returns
 */
export const setMicrosoftTeamsMeeeting = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_MICROSOFT_TEAMS_MEETING,
    payload,
  });
};

/**
 *
 * @returns
 */
export const resetMicrosoftTeamsMeeting = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.RESET_MICROSOFT_TEAMS_MEETING,
  });
};

/**
 *
 * @param {{ communicationUserId: string, accessToken: string }} payload
 * @returns
 */
export const setMicrosoftCommunicationIdentity = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_MICROSOFT_COMMUNICATION_IDENTITY,
    payload,
  });
};

/**
 *
 * @returns
 */
export const resetMicrosoftCommunicationIdentity = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.RESET_MICROSOFT_COMMUNICATION_IDENTITY,
  });
};

/**
 *
 * @param {{ outlookEvents: [] }} payload
 * @returns
 */
export const setOutlookEvents = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_OUTLOOK_EVENTS,
    payload,
  });
};

/**
 *
 * @param {{
 *  yesterday: [],
 *  today: [],
 *  tomorrow: [],
 * }} payload
 * @returns
 */
export const setOutlookEventsDaywise = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_OUTLOOK_EVENTS_DAYWISE,
    payload,
  });
};
