import React from 'react';
import classes from './pageLayout.module.css';

const PageLayout = ({ children }) => {
  return (
    <div className={classes.launcherMainContainer}>
      <div className={classes.launcherMainContainerInner}>{children}</div>
    </div>
  )
}

export default PageLayout