import React from 'react';
import { getWorkspaceName } from '../../../../Utils/authentication-access';
import { InviteWorkspaceDialog } from './component';

export const InviteWorkspaceDialogContainer = (props) => {
  const { open, onClose } = props;
  const workspaceName = getWorkspaceName();
  const title = `Invite to ${workspaceName}`;

  return <InviteWorkspaceDialog open={open} onClose={onClose} title={title} hideFooter />;
};
