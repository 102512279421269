import React from 'react';

export const PendingRequestIcon = (props) => {
  let fill = props.fill ? props.fill : '#000';
  let fillOpacity = props.fill ? 1 : 0.38;

  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#a)">
        <path
          d="M9.27 10.969a5.444 5.444 0 0 1 7.21-2.7 5.444 5.444 0 0 1 2.7 7.211 5.43 5.43 0 0 1-4.955 3.19A5.46 5.46 0 0 1 9.3 15.558H1.778v-1.556c.047-.886.653-1.61 1.82-2.193 1.167-.584 2.63-.887 4.403-.918.444 0 .864.039 1.268.078ZM8 3.112c.871.023 1.603.326 2.186.91.584.583.871 1.315.871 2.201 0 .887-.287 1.618-.87 2.202-.584.583-1.316.871-2.187.871-.871 0-1.602-.288-2.186-.871-.583-.584-.871-1.315-.871-2.202 0-.886.288-1.618.871-2.201.584-.584 1.315-.887 2.186-.91Zm6.224 14.002a3.89 3.89 0 1 0 0-7.778 3.89 3.89 0 0 0 0 7.778Zm-.778-6.223h1.167v2.194l1.898 1.096-.584 1.012-2.481-1.432v-2.87Z"
          fill={fill}
          fillOpacity={fillOpacity}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(.846 .477)" d="M0 0h18.667v18.667H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
