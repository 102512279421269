

import ACTION_TYPES from '../actions/type.action';

const initialState = {
  teamsInfo: [],
};

const teamsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPES.TEAMS_INFO:
      return {
        ...state,
        teamsInfo: payload,
      };
    default:
      return state;
  }
};

export default teamsReducer;
