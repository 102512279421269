import React from 'react';
import { Popper, Dialog, DialogActions, Hidden, ClickAwayListener } from '@material-ui/core';
import ColorSelect from './colourpopup';
import EraseSelect from './erasepopup';
import ShapesSelect from './shapespopup';
import closeLogo from '../assets/img/closeIcon.svg';
import PenTool from '../ToolBarIcons/PenIcon';
import HighLighterTool from '../ToolBarIcons/HighlighterIcon';
import EraserTool from '../ToolBarIcons/EraserIcon';
import SelectTool from '../ToolBarIcons/SelectIcon';
import ShapesTool from '../ToolBarIcons/ShapeSelectIcon';
import TextTool from '../ToolBarIcons/TextIcon';
import './toolbar.scss';

const activeActionToCursorMap = {
  text: 'text',
  zoom: 'zoom-in',
};

class toolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectPen: '#000000',
      brushSize: 2,
      selectedpensize: 4,
      selectedHighlightersize: 30,
      selectedColor: '#000000',
      selectHighlighter: '#f9dc4b',
      selectEraser: '',
      selectShapes: '',
      selectText: '',
      colorsopen: false,
      coloranchorEl: null,
      eraseopen: false,
      eraseanchorEl: null,
      shapesopen: false,
      shapesanchorEl: null,
      trashdialogopen: false,
      textEditorDialogOpen: false,
      isbold: false,
      isitalic: false,
      isunderlined: false,
      activeAction: {
        pen: false,
        select: true,
        eraser: false,
        file: false,
        highlighter: false,
        shape: false,
        text: false,
      },
      customEditor: {
        value: '',
        bold: false,
        underline: false,
        italic: false,
        fontSize: 16,
        update: false,
      },
    };
  }
  componentDidUpdate = (prevProps) => {
    const activeAction = Object.keys(this.state.activeAction).find(
      (key) => this.state.activeAction[key],
    );
    activeAction && this.props.canvas.setCursor(activeActionToCursorMap[activeAction] || 'auto');

    if (typeof this.props.inactivateAll === 'undefined') {
      return;
    }
    if (this.props.inactivateAll === prevProps.inactivateAll) {
      return;
    }
    if (this.props.inactivateAll === false) {
      return this.setActiveTool('select');
    }

    this.inactivateAll();
  };

  async componentDidMount() {
    let self = this;
    if (typeof this.props.inactivateAll === 'undefined') {
      return;
    }
    if (this.props.inactivateAll === false) {
      return;
    }
    this.inactivateAll();
  }

  inactivateAll = () => {
    this.props.activateAction({
      eraser: false,
      file: false,
      highlighter: false,
      pen: false,
      select: false,
      shape: false,
      text: false,
    });
  };

  setCanvasBoard = (canvasBoardContext) => (this.canvasboard = canvasBoardContext);

  setActiveTool = (selectedTool) => {
    const updatedActiveAction = { ...this.state.activeAction };
    Object.keys(this.state.activeAction).forEach((key) => {
      updatedActiveAction[key] = false;
    });
    updatedActiveAction[selectedTool] = true;
    this.setState({ activeAction: updatedActiveAction });
    this.props.activateAction(updatedActiveAction);
  };

  selectedColor = (color) => {
    this.setState({ selectedColor: color, colorsopen: false });
    if (this.state.activeAction.pen) {
      this.setState({ selectPen: color });
      this.props.selectColor(color);
    } else if (this.state.activeAction.highlighter) {
      this.setState({ selectHighlighter: color });
      this.props.selectColor(color);
    }
  };

  selectedSize = (size) => {
    this.setState({ brushSize: size });
    if (this.state.activeAction.pen) {
      this.setState({ selectedpensize: size });
      this.props.selectBrushSize(size);
    } else if (this.state.activeAction.highlighter) {
      this.setState({ selectedHighlightersize: size });
      this.props.selectBrushSize(size);
    }
    setTimeout(
      function () {
        this.setState({ colorsopen: false });
      }.bind(this),
      1000,
    );
  };

  penClick = (event) => {
    this.setActiveTool('pen');
    this.setState((prevState) => ({
      activeAction: { ...prevState.activeAction, pen: true },
    }));
    this.props.selectPen();
    this.props.selectColor(this.state.selectPen);
    this.props.selectBrushSize(this.state.selectedpensize);
    this.setState({ brushSize: this.state.selectedpensize });
    this.openColorSelect(event);
  };

  highlighterClick = (event) => {
    this.setActiveTool('highlighter');
    this.setState((prevState) => ({
      activeAction: { ...prevState.activeAction, highlighter: true },
    }));
    this.props.selectHighlighter();
    this.props.selectColor(this.state.selectHighlighter);
    this.props.selectBrushSize(this.state.selectedHighlightersize);
    this.setState({ brushSize: this.state.selectedHighlightersize });
    this.openColorSelect(event);
  };

  eraserClick = (event) => {
    this.setActiveTool('eraser');
    this.setState((prevState) => ({
      activeAction: { ...prevState.activeAction, eraser: true },
    }));
    this.setState({ eraseopen: !this.state.eraseopen });
    this.setState({ eraseanchorEl: event.currentTarget });
  };

  selectClick = () => {
    this.setActiveTool('select');
    this.setState((prevState) => ({
      activeAction: { ...prevState.activeAction, select: true },
    }));
  };

  textClick = () => {
    this.setActiveTool('text');
    this.setState((prevState) => ({
      activeAction: { ...prevState.activeAction, text: true },
    }));
    this.props.canvas.setCursor('text');
    this.props.disableShortCutKey();
  };

  shapesClick = (event) => {
    this.setActiveTool('shape');
    this.setState((prevState) => ({
      activeAction: { ...prevState.activeAction, shape: true },
    }));
    this.props.selectColor(this.state.selectPen);
    this.props.selectBrushSize(this.state.selectedpensize);
    this.setState({ brushSize: this.state.selectedpensize });
    this.props.selectShape('line');
    this.setState({ shapesopen: !this.state.shapesopen });
    this.setState({ shapesanchorEl: event.currentTarget });
  };

  openColorSelect = (event) => {
    this.setState({ colorsopen: !this.state.colorsopen });
    this.setState({ coloranchorEl: event.currentTarget });
  };

  strokeEraseSelect = () => {
    this.updateAction('eraser');
    this.setState({ eraseopen: false });
  };

  clearAll = () => {
    this.setState({ trashdialogopen: true, eraseopen: false });
  };

  confirmTrash = () => {
    this.props.deleteAll();
    this.setState({ trashdialogopen: false });
  };

  trashDialogClose = () => {
    this.setState({ trashdialogopen: false });
  };

  updateAction = () => {
    this.props.activateAction(this.state.activeAction);
  };

  selectShape = (shape) => {
    this.props.selectShape(shape);
    this.setState({ shapesopen: false });
  };

  textEditorDialogClose = () => {
    this.setState(
      {
        textEditorDialogOpen: false,
        customEditor: {
          value: '',
          bold: false,
          underline: false,
          italic: false,
          fontSize: 16,
          update: false,
        },
        activeAction: { select: true },
      },
      () => this.updateAction(),
    );
    this.props.enableShortCutKey();
  };

  handleCustomEditorValue = (event) => {
    let customEditor = { ...this.state.customEditor };
    customEditor.value = event.target.value;
    this.setState({ customEditor });
  };

  handleCustomEditorBold = () => {
    let customEditor = { ...this.state.customEditor };
    customEditor.bold = !customEditor.bold;
    this.setState({ customEditor });
    this.setState({ isbold: customEditor.bold });
  };

  handleCustomEditorItalic = () => {
    let customEditor = { ...this.state.customEditor };
    customEditor.italic = !customEditor.italic;
    this.setState({ customEditor });
    this.setState({ isitalic: customEditor.italic });
  };

  handleCustomEditorFontsize = (event) => {
    let customEditor = { ...this.state.customEditor };
    customEditor.fontSize = event.target.value;
    this.setState({ customEditor });
  };

  handleCustomEditorUnderline = () => {
    let customEditor = { ...this.state.customEditor };
    customEditor.underline = !customEditor.underline;
    this.setState({ customEditor });
    this.setState({ isunderlined: customEditor.underline });
  };
  handleCustomEditor = (doc) => {
    this.setState({ customEditor: doc });
    this.setState({ isunderlined: false, isbold: false, isitalic: false });
  };

  handleClose = (event) => {
    if (this.coloranchorEl.current && this.coloranchorEl.current.contains(event.target)) {
      return;
    }

    this.setState({ colorsopen: false });
  };

  render() {
    return (
      <div className="toolbarWrap">
        <div className="toolbar-content">
          <div
            className="toolbar-content-items"
            onClick={this.penClick}
            style={{
              backgroundColor: this.state.activeAction.pen
                ? 'rgba(51, 90, 251, 0.4)'
                : 'transparent',
              height: '40px',
              border: '1px solid #DADADA',
              borderRadius: '4px',
            }}
          >
            <PenTool fill={this.state.selectPen} />
          </div>
          <div
            className="toolbar-content-items"
            onClick={this.highlighterClick}
            style={{
              backgroundColor: this.state.activeAction.highlighter
                ? 'rgba(51, 90, 251, 0.4)'
                : 'transparent',
              height: '40px',
              border: '1px solid #DADADA',
              borderRadius: '4px',
            }}
          >
            <HighLighterTool fill={this.state.selectHighlighter} />
          </div>
          <div
            className="toolbar-content-items"
            onClick={this.eraserClick}
            style={{
              backgroundColor: this.state.activeAction.eraser
                ? 'rgba(51, 90, 251, 0.4)'
                : 'transparent',
              height: '40px',
              border: '1px solid #DADADA',
              borderRadius: '4px',
            }}
          >
            <EraserTool fill={this.state.activeAction.eraser ? '#FFFFFF' : '#DAB1E4'} />
          </div>

          {/*  INFO : pagination moved to counter bottomBar
          {this.props.pagination} */}
          <div
            className="toolbar-content-items"
            onClick={this.selectClick}
            style={{
              backgroundColor: this.state.activeAction.select
                ? 'rgba(51, 90, 251, 0.4)'
                : 'transparent',
              height: '40px',
              border: '1px solid #DADADA',
              borderRadius: '4px',
              display: 'flex',
              margin: 'auto',
              alignItems: 'center',
              justifyContent: 'center',
              width: '40px',
            }}
          >
            <SelectTool fill={this.state.activeAction.select ? '#FFFFFF' : '#DDDDDD'} />
          </div>

          <div
            className="toolbar-content-items"
            onClick={this.textClick}
            style={{
              backgroundColor: this.state.activeAction.text
                ? 'rgba(51, 90, 251, 0.4)'
                : 'transparent',
              height: '40px',
              border: '1px solid #DADADA',
              borderRadius: '4px',
            }}
          >
            <TextTool fill={this.state.activeAction.text ? '#FFFFFF' : '#DDDDDD'} />
          </div>
          <Hidden xsDown>
            <div
              className="toolbar-content-items"
              onClick={this.shapesClick}
              style={{
                backgroundColor: this.state.activeAction.shape
                  ? 'rgba(51, 90, 251, 0.4)'
                  : 'transparent',
                height: '40px',
                border: '1px solid #DADADA',
                borderRadius: '4px',
              }}
            >
              <ShapesTool fill={this.state.activeAction.shape ? '#FFFFFF' : '#DDDDDD'} />
            </div>
          </Hidden>
        </div>
        <Popper
          open={this.state.colorsopen}
          anchorEl={this.state.coloranchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          disablePortal
        >
          <ClickAwayListener
            onClickAway={() => this.setState({ colorsopen: false })}
            mouseEvent="onMouseDown"
          >
            <ColorSelect
              optionType={this.state.activeAction.highlighter ? 'highlighter' : undefined}
              selectedcolor={this.selectedColor}
              selectedBrushSize={this.selectedSize}
              brushsize={this.state.brushSize}
            />
          </ClickAwayListener>
        </Popper>
        <Popper
          open={this.state.eraseopen}
          anchorEl={this.state.eraseanchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          disablePortal
        >
          <ClickAwayListener
            onClickAway={() => this.setState({ eraseopen: false })}
            mouseEvent="onMouseDown"
          >
            <EraseSelect strokeErase={this.strokeEraseSelect} clearPage={this.clearAll} />
          </ClickAwayListener>
        </Popper>
        <Popper
          open={this.state.shapesopen}
          anchorEl={this.state.shapesanchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          disablePortal
        >
          <ClickAwayListener
            onClickAway={() => this.setState({ shapesopen: false })}
            mouseEvent="onMouseDown"
          >
            <ShapesSelect selectShape={this.selectShape} />
          </ClickAwayListener>
        </Popper>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          className="overlay"
          open={this.state.trashdialogopen}
          onClose={this.trashDialogClose}
        >
          <div className="dialog-modal-header bg-primary">
            <div></div>
            <div className="text-center">
              <img
                className="close-icon hover"
                onClick={this.trashDialogClose}
                src={closeLogo}
                alt="Close"
              />
            </div>
          </div>
          <div className="simplemodal-card">
            <div className="modal-title">
              <b>Clear Page</b>
            </div>
            <p>
              Are you sure you want to Clear this <b>Page</b>?
            </p>
            <p>You can't undo this action once cleared.</p>
            <DialogActions>
              <button className="btn-button-blank" onClick={this.trashDialogClose}>
                Cancel
              </button>
              <button className="btn-button" onClick={this.confirmTrash}>
                Clear
              </button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    );
  }
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
    },
  },
};

export default toolbar;
