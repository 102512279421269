import React, { useEffect, useState } from 'react';
import { Button, Popover, TextField, Tooltip, Typography } from '@material-ui/core';
import { useDeviceCodeDialogStyles } from './component.style';
import { bindPopover } from 'material-ui-popup-state';
import InfoIcon from '@material-ui/icons/Info';
import { APP } from '../../../constants/app.constants';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { getDeviceIds } from '../../../Utils/authentication-access';
import InfoIconOutLined from '../../../assets/icons/InfoIconOutLined';

const filter = createFilterOptions();

export const DeviceCodeDialog = (props) => {
  const {
    deviceCode,
    onSubmit,
    setDeviceCode,
    submitText,
    title,
    knowMoreText,
    deviceCodeDialogPopupState,
  } = props;
  const classes = useDeviceCodeDialogStyles();
  const [value, setValue] = React.useState(null);

  const onChange = (e) => {
    setDeviceCode(e.target.value.toUpperCase());
  };

  const onEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  console.log('getDeviceIds', getDeviceIds());
  return (
    <Popover
      {...bindPopover(deviceCodeDialogPopupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      style={{
        top: '0.5rem',
      }}
      PaperProps={{
        style: {
          boxShadow: '0px 2.52185px 2.52185px rgba(0, 0, 0, 0.25)',
          borderRadius: '5.04371px',
        },
      }}
    >
      <div className={classes.container}>
        <Typography variant="h2" className={classes.castSpaceTitle}>
          {title}
        </Typography>
        {/* TODO : feature implementation
        <div className={classes.infoContainer}>
          <InfoIcon />
          <Typography variant="h5">{knowMoreText}</Typography>
        </div> */}
        <div>
          <div className={classes.lableStyle}>
            <div>Enter the Room ID </div>

            <Tooltip title="Enter the Room ID displayed on the Bird device" placement="right">
              <div className={classes.infoIcon}>
                <InfoIconOutLined />
              </div>
            </Tooltip>
          </div>

          {/* {/** INFO: commented TextFiled dut to new Auto Complete filed added , uncomment if Textfield required  */}
          <TextField
            variant="outlined"
            value={deviceCode}
            onChange={onChange}
            fullWidth
            placeholder="Room ID"
            onKeyDown={onEnterKeyPress}
          />

          {/* INFO: need fix
          
          <Autocomplete
            value={deviceCode}
            fullWidth
            onKeyDown={onEnterKeyPress}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                setDeviceCode(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={getDeviceIds()}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              return option;
            }}
            renderOption={(option) => option}
            freeSolo
            renderInput={(params) => <TextField {...params} variant="outlined" />}
          /> */}
        </div>
        <Button variant="contained" color="primary" onClick={onSubmit} className={classes.pairBtn}>
          {submitText}
        </Button>
      </div>
    </Popover>
  );
};

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
];
