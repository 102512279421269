import { makeStyles } from '@material-ui/core';

export const useNewShareDialogStyle = makeStyles(() => ({
  dialogHeader: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: '10% 80% 10%'
  },
  headerName: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '28px',
    textAlign: 'center',
    marginLeft: '50px',
    color: 'rgba(0, 0, 0)',
  },
  closeIcon: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  toggleContainer: {
    display: 'flex',
    borderBottom: '1px solid rgba(0,0,0,0.12)',
    padding: '0px 26px',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  activeScreen: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.87)',
    borderBottom: '1px solid #335AFB',
    padding: '8px 15px',
  },
  unactiveScreen: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.4)',
    padding: '8px 15px',
    cursor: 'pointer',
  },
  innerContainer: {
    padding: '15px 20px',
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    overflow: 'auto',
  },
  infoContainer: {
    background: '#EAEAEA',
    padding: '10px 10px',
  },
  subtitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  addBtn: {
    marginLeft: '5px',
    width: '100px',
    height: '36px',
    background: '#335AFB',
    borderRadius: '4px',
    color: 'white',
    marginTop: '5px',
    '&:hover': {
      background: '#335AFB',
      boxShadow: '0px 0.3px 0.5px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.2)',
    },
  },

  disableBtn: {
    marginLeft: '5px',
    width: '100px',
    height: '36px',
    background: '#335AFB',
    boxShadow: '0px 0.3px 0.5px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    color: 'white',
    marginTop: '5px',
    opacity: '0.4',
  },

  membersCount: {
    color: 'black',
    background: ' rgba(0, 0, 0, 0.12)',
    borderRadius: '20px',
    padding: '5px 10px',
  },
  shareCanvasContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  shareCanvasContent: {
    display: 'flex',
    gap: '1rem',
  },
  addMemberContainer: {},
  membersSelect: {
    padding: '12px 32px 12px 12px',
  },
}));
