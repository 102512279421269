import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import defaultInstitute from '../../../../assets/svg/institute.svg';
import cssclasses from './PlanUpgrade.module.css';
import { disable } from 'mixpanel-browser';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const Steps = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = props.steps;

  const isStepOptional = (step) => {
    return false;
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const getStepContent = (step) => {
    return props.steps;
  };
  const handleNext = () => {
    console.log('GOING FORWARD');
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <div
        className={classes.root}
        style={
          {
            // width: "675px"
          }
        }
      >
        <Stepper
          activeStep={activeStep}
          style={{
            padding: '2% 2% 2% 12%',
            display: 'none',
          }}
          connector={<></>}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label.name} {...stepProps}>
                <StepLabel {...labelProps}>{label.name}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {props.heading ? (
          <div className={cssclasses.instituteGrid}>
            {' '}
            {props.imgurl ? (
              <img style={{ width: '50px' }} src={props.imgurl} />
            ) : (
              <img style={{ width: '50px' }} src={defaultInstitute} />
            )}
            {props.heading}
          </div>
        ) : (
          ''
        )}
        <div
          style={{
            marginTop: '2%',
          }}
        >
          {props.steps ? props.steps[activeStep].component(handleNext, handleBack) : ''}
        </div>
      </div>
    </>
  );
};

export default Steps;
