import { combineReducers } from 'redux';
import userlistReducer from './userlist.reducer';
import mouseReducer from './mouse.reducer';
import toolsReducer from './tools.reducer';
import canvasReducer from './canvas.reducer';
import elementReducer from './element.reducer';
import undoredoReducer from './undoredo.reducer';
import meetingScheduleReducer from './meetingSchedule.reducer';
import huddleReducer from './huddle.reducer';
import { notificationReducer } from './notification.reducer';
import spaceReducer from './spaces.reducer';
import documentImportReducer from './documentImport.reducer';
import { invitesReducer } from './invites.reducer';
import microsoftReducer from './microsoft.reducer';
import zoomReducer from './zoom.reducer';
import dialogReducer from './dialog.reducer';
import shareSpaceReducer from './shareSpace.reducer';
import schedulesReducer from './schedules.reducer';
import activeSpaceReducer from './activeSpace.reducer';
import teamsReducer from './teams.reducer';
import mediaServerReducer from './media-server.reducer';
import deviceReducer from './device.reducer';
import deviceStatusReducer from './deviceStatus.reducer';
import licenseReducer from './license.reducer';
import adminReducer from './admin.reducer';
import smartNotesReducer from './smart-notes';
import selfLearnReducer from './self-learn.reducer';

const combinedReducer = combineReducers({
  userlistReducer,
  mouseReducer,
  toolsReducer,
  canvasReducer,
  dialog: dialogReducer,
  elementReducer,
  microsoft: microsoftReducer,
  zoom: zoomReducer,
  undoredoReducer,
  meetingScheduleReducer,
  huddle: huddleReducer,
  mediaServer: mediaServerReducer,
  notification: notificationReducer,
  spaceReducer,
  schedules: schedulesReducer,
  documentImport: documentImportReducer,
  invites: invitesReducer,
  shareSpaceReducer,
  activeSpaceReducer,
  teamsReducer,
  deviceReducer,
  licenseReducer,
  adminReducer,
  deviceStatusReducer,
  smartNotes: smartNotesReducer,
  selfLearn: selfLearnReducer,
});
export default combinedReducer;
