import React from 'react';

const FilesContainedIcon = (props) => {
  let fill = props.fill ? props.fill : '#000';
  let fillOpacity = props.fill ? 1 : 0.38;

  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.91406 15.1786C7.91406 13.3796 9.37246 11.9212 11.1715 11.9212H26.8316C28.6306 11.9212 30.089 13.3796 30.089 15.1786V27.1429C30.089 28.9419 28.6306 30.4003 26.8316 30.4003H11.1715C9.37246 30.4003 7.91406 28.9419 7.91406 27.1429V15.1786ZM22.4734 16.5679C22.4734 16.1368 22.124 15.7874 21.693 15.7874H12.4836C12.0525 15.7874 11.7031 16.1368 11.7031 16.5679C11.7031 16.999 12.0525 17.3483 12.4836 17.3483H21.693C22.124 17.3483 22.4734 16.999 22.4734 16.5679ZM12.4836 20.4204H18.6231C19.0542 20.4204 19.4036 20.071 19.4036 19.64C19.4036 19.209 19.0542 18.8595 18.6231 18.8595H12.4836C12.0525 18.8595 11.7031 19.209 11.7031 19.64C11.7031 20.071 12.0525 20.4204 12.4836 20.4204ZM15.5534 23.4886H12.4836C12.0525 23.4886 11.7031 23.1392 11.7031 22.7081C11.7031 22.2771 12.0525 21.9277 12.4836 21.9277H15.5534C15.9845 21.9277 16.3339 22.2771 16.3339 22.7081C16.3339 23.1392 15.9845 23.4886 15.5534 23.4886Z"
        fill={fill}
      />
      <path
        d="M13.2871 8.52224C13.2871 8.01195 13.7008 7.59828 14.2111 7.59828H23.4506C23.9609 7.59828 24.3746 8.01195 24.3746 8.52224C24.3746 9.03252 23.9609 9.44619 23.4506 9.44619H14.2111C13.7008 9.44619 13.2871 9.03252 13.2871 8.52224Z"
        fill={fill}
      />
    </svg>
  );
};

export default FilesContainedIcon;
