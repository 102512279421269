import ACTION_TYPES from '../actions/type.action';

const initialState = {
  pendingRequests: [],
  isJoinWorkspaceInviteDialogOpen: false,
  isJoinSpaceInviteDialogOpen: false,
};

export const invitesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_PENDING_REQUESTS:
      return {
        ...state,
        pendingRequests: action.payload,
      };
    case ACTION_TYPES.REMOVE_INVITE:
      return {
        ...state,
        pendingRequests: state.pendingRequests.filter(
          (pendingRequest) => pendingRequest.id !== action.payload,
        ),
      };
    case ACTION_TYPES.REMOVE_INVITE_BY_IDS:
      /**
       * TODO: get the acceptedPendingRequestIds and filter them out.
       * accepts both workspace/space requests
       */
      return {
        ...state,
        pendingRequests: [],
      };
    case ACTION_TYPES.ADD_INVITE:
      return {
        ...state,
        pendingRequests: [...state.pendingRequests, action.payload],
      };
    case ACTION_TYPES.SHOW_JOIN_WORKSPACE_INVITE_DIALOG:
      return {
        ...state,
        isJoinWorkspaceInviteDialogOpen: true,
      };
    case ACTION_TYPES.HIDE_JOIN_WORKSPACE_INVITE_DIALOG:
      return {
        ...state,
        isJoinWorkspaceInviteDialogOpen: false,
      };
    case ACTION_TYPES.SHOW_JOIN_SPACE_INVITE_DIALOG:
      return {
        ...state,
        isJoinSpaceInviteDialogOpen: true,
      };
    case ACTION_TYPES.HIDE_JOIN_SPACE_INVITE_DIALOG:
      return {
        ...state,
        isJoinSpaceInviteDialogOpen: false,
      };
    default:
      return state;
  }
};
