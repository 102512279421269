import { makeStyles } from '@material-ui/core';

export const useEventUserProfileStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    marginBottom: '4px',
    gap: '0.5rem',
  },
  eventOrganizerName: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '11px',
    display: 'flex',
    alignItems: 'center',
  },
}));
