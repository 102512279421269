import React from 'react';
import MeetingCardContainer from './meeting-card/container';
import { ContentContainer } from './component.style';

export const Meetings = (props) => {
  const {
    checkIsAllScheduleFilter,
    closeSidebar,
    enableEditSchedule,
    getSchedulesHandler,
    schedules,
    importToSpace,
    disableOptions,
    dateTimeFormat,
  } = props;

  const content = schedules.map((schedule) => (
    <MeetingCardContainer
      key={schedule.id}
      checkIsAllScheduleFilter={checkIsAllScheduleFilter}
      closeSidebar={closeSidebar}
      enableEditSchedule={enableEditSchedule}
      getSchedulesHandler={getSchedulesHandler}
      schedule={schedule}
      importToSpace={importToSpace}
      disableOptions={disableOptions}
      dateTimeFormat={dateTimeFormat}
    />
  ));

  return <ContentContainer>{content}</ContentContainer>;
};
