import { useHistory } from 'react-router';
import {
  importOutlookEventDialogStates,
  lastVisitedStates,
  getZoomGoToMeetingDialogStates,
  setupZoomAccountDialogStates,
  getSignInAndJoinMicrosoftTeamsStates,
  microsoftOAuthLoginStates,
  microsoftOAuthSignupStates,
  constructJoinSpaceRequestStates,
  constructJoinWorkspaceRequestStates,
  personalCalendarStates,
  microsoftOAuthLinkWithDeviceStates,
} from '../../constants/last-visited-state.constants';
import { authService } from '../../services/auth.service';
import inviteService from '../../services/invites.service';
import { useDialogDispatcher } from '../../store/dispatcher/useDialogDispatcher';
import { useNotificationDispatcher } from '../../store/dispatcher/useNotificationDispatcher';
import { useAuth } from '../auth/useAuth';
import { useMicrosoftTeams } from '../microsoft/useMicrosoftTeams';
import { useURL } from '../url/useURL';
import { getEncryptedHardwareId, popEncryptedHardwareId } from '../authentication-access';

export const useLastPageVisited = () => {
  const history = useHistory();
  const { dispatchSetSnackbar } = useNotificationDispatcher();

  const { hydrateLogin } = useAuth();
  const { getParam } = useURL();
  const {
    dispatchSetIsOpenImportOutlookEventsDialog,
    dispatchSetIsOpenVideoCallSidebarDialog,
    dispatchSetIsOpenNavigationSidebarDialog,
    dispatchSetIsOpenJoinRequestSentDialog,
  } = useDialogDispatcher();
  const { startTeamsMeeting } = useMicrosoftTeams();

  /**
   *
   * @returns {{ from: string, states: [{id: string}] }}
   */
  const getLastVisitedPageInfo = () => {
    return JSON.parse(sessionStorage.getItem('lastVisitedPage'));
  };

  const checkIsMicrosoftOAuthLogin = () => {
    const lastVisitedPageInfo = getLastVisitedPageInfo();
    const lastVisitedPageState = lastVisitedPageInfo.states.find(
      (state) => state.type === lastVisitedStates.MICROSOFT_OAUTH_LOGIN,
    );
    return !!lastVisitedPageState;
  };

  const checkIsMicrosoftOAuthSignup = () => {
    const lastVisitedPageInfo = getLastVisitedPageInfo();
    const lastVisitedPageState = lastVisitedPageInfo.states.find(
      (state) => state.type === lastVisitedStates.MICROSOFT_OAUTH_SIGNUP,
    );
    return !!lastVisitedPageState;
  };

  const checkIsMicrosoftOAuthLoginOrSignup = () => {
    const lastVisitedPageInfo = getLastVisitedPageInfo();
    const lastVisitedPageState = lastVisitedPageInfo.states.find((state) =>
      [lastVisitedStates.MICROSOFT_OAUTH_LOGIN, lastVisitedStates.MICROSOFT_OAUTH_SIGNUP].includes(
        state.type,
      ),
    );
    return !!lastVisitedPageState;
  };

  /**
   *
   * @param {{ lastVisitedPageInfo }} data
   * @returns
   */
  const checkIsMicrosoftOAuth = (data) => {
    const lastVisitedPageInfo =
      data && data.lastVisitedPageInfo ? data.lastVisitedPageInfo : getLastVisitedPageInfo();
    const lastVisitedPageState = lastVisitedPageInfo.states.find((state) =>
      [
        lastVisitedStates.MICROSOFT_OAUTH_LOGIN,
        lastVisitedStates.MICROSOFT_OAUTH_SIGNUP,
        lastVisitedStates.MICROSOFT_OAUTH_LINK_WITH_DEVICE,
        lastVisitedStates.JOIN_SPACE_REQUEST_USING_MICROSOFT_OAUTH,
        lastVisitedStates.JOIN_WORKSPACE_REQUEST_USING_MICROSOFT_OAUTH,
      ].includes(state.type),
    );
    return !!lastVisitedPageState;
  };

  /**
   *
   * @param {{ from: string, states: [string] }} payload
   * @returns
   */
  const setLastVisitedPageInfo = (payload) => {
    return sessionStorage.setItem('lastVisitedPage', JSON.stringify(payload));
  };

  /**
   *
   * @param {*} stateInfo
   */
  const microsoftOAuthLogin = async () => {
    const code = getParam('code');
    try {
      const response = await authService.oAuthLogin({ code, provider: 'MICROSOFT' });
      const { status } = response;
      const { accessToken, userName } = response.data.data;
      const payload = { accessToken, userName, statusCode: status };
      hydrateLogin(payload);
    } catch (error) {
      console.log(error.message);
      const message = error.response.data.message || error.message;
      dispatchSetSnackbar({ message });
      window.location = '/';
    }
  };

  const joinSpaceRequestUsingMicrosoftOAuth = async (stateInfo) => {
    const code = getParam('code');
    const spaceId = stateInfo.spaceId;

    try {
      const response = await inviteService.createAndSendJoinSpaceRequestUsingOAuth({
        code,
        provider: 'MICROSOFT',
        spaceId,
      });
      const { status } = response;
      const { accessToken, userName } = response.data.data;
      const payload = { accessToken, userName, statusCode: status };
      hydrateLogin(payload);
    } catch (error) {
      console.log(error.message);
      const message = error.response.data.message || error.message;
      dispatchSetSnackbar({ message });
      window.location = '/';
    }
  };

  const joinWorkspaceRequestUsingMicrosoftOAuth = async (stateInfo) => {
    const code = getParam('code');
    const workspaceId = stateInfo.workspaceId;
    try {
      const response = await inviteService.createAndSendJoinWorkspaceRequestUsingOAuth({
        code,
        provider: 'MICROSOFT',
        workspaceId,
      });
      const { status } = response;
      const { accessToken, userName } = response.data.data;
      const payload = { accessToken, userName, statusCode: status };
      hydrateLogin(payload);
    } catch (error) {
      console.log(error.message);
      const message = error.response.data.message || error.message;
      dispatchSetSnackbar({ message });
      window.location = '/';
    }
  };

  /**
   *
   * @param {*} stateInfo
   */
  const microsoftOAuthSignup = async () => {
    const code = getParam('code');
    try {
      const response = await authService.oAuthSignup({ code, provider: 'MICROSOFT' });
      const { status } = response;
      const { accessToken, userName } = response.data.data;
      const payload = { accessToken, userName, statusCode: status };
      hydrateLogin(payload);
    } catch (error) {
      console.log(error.message);
      const message = error.response.data.message || error.message;
      dispatchSetSnackbar({ message });
      window.location = '/';
    }
  };

  const microsoftOAuthLinkWithDevice = async () => {
    const code = getParam('code');
    const encryptedHardwareId = popEncryptedHardwareId();
    try {
      await authService.oAuthLinkWithDevice({ code, encryptedHardwareId, provider: 'MICROSOFT' });
    } catch (error) {
      console.log(error.message);
      const message = error.response.data.message || error.message;
      dispatchSetSnackbar({ message });
      window.location = '/';
    }
  };

  const handleJoinRequestSentDialogIsOpen = async (stateInfo) => {
    dispatchSetIsOpenJoinRequestSentDialog(stateInfo.isOpen);
  };

  const handleLastVisitedState = (stateInfo) => {
    switch (stateInfo.type) {
      case lastVisitedStates.VIDEO_CALL_SIDEBAR_IS_OPEN:
        dispatchSetIsOpenVideoCallSidebarDialog(stateInfo.isOpen);
        break;
      case lastVisitedStates.IMPORT_OUTLOOK_EVENTS_DIALOG_IS_OPEN:
        dispatchSetIsOpenImportOutlookEventsDialog(stateInfo.isOpen);
        break;
      case lastVisitedStates.NAVIGATION_SIDEBAR_DIALOG_IS_OPEN:
        dispatchSetIsOpenNavigationSidebarDialog(stateInfo.isOpen);
        break;
      case lastVisitedStates.START_MICROSOFT_TEAMS_CALL:
        startTeamsMeeting({ meetingUrl: stateInfo.meetingUrl });
        break;
      case lastVisitedStates.MICROSOFT_OAUTH_LOGIN:
        microsoftOAuthLogin();
        break;
      case lastVisitedStates.MICROSOFT_OAUTH_SIGNUP:
        microsoftOAuthSignup();
        break;
      case lastVisitedStates.MICROSOFT_OAUTH_LINK_WITH_DEVICE:
        microsoftOAuthLinkWithDevice();
        break;
      case lastVisitedStates.JOIN_SPACE_REQUEST_USING_MICROSOFT_OAUTH:
        joinSpaceRequestUsingMicrosoftOAuth(stateInfo);
        break;
      case lastVisitedStates.JOIN_WORKSPACE_REQUEST_USING_MICROSOFT_OAUTH:
        joinWorkspaceRequestUsingMicrosoftOAuth(stateInfo);
        break;
      case lastVisitedStates.JOIN_REQUEST_SENT_DIALOG_IS_OPEN:
        handleJoinRequestSentDialogIsOpen(stateInfo);
        break;
      default:
        break;
    }
  };

  /**
   *
   * @param {[{id: string}]} states
   */
  const handleLastVisitedStates = (states) => {
    states.forEach((stateInfo) => handleLastVisitedState(stateInfo));
  };

  const resetLastVisitedPage = () => {
    sessionStorage.removeItem('lastVisitedPage');
  };

  /**
   * use case:
   * user click on authorize and start meeting
   * user is redirected to zoom authorization page
   * now on redirecting back to teamhub
   * meetingUrl will be used to open zoom component view,
   *
   * @param {{ lastVisitedPageInfo: any }} data
   *
   * @returns
   */
  const constructLocation = (data) => {
    const lastVisitedPage =
      data && data.lastVisitedPageInfo ? data.lastVisitedPageInfo : getLastVisitedPageInfo();

    if (!lastVisitedPage || !lastVisitedPage.from || !lastVisitedPage.states) {
      console.log('Proper lastVisitedPage info not found', lastVisitedPage);
      return {
        pathname: '/',
        state: undefined,
      };
    }

    const { from, states } = lastVisitedPage;

    const zoomMeetingState = states.find(
      (stateInfo) => stateInfo.type === lastVisitedStates.ZOOM_COMPONENT_VIEW_IS_OPEN,
    );

    const locationState = zoomMeetingState
      ? { zoom: { meetingUrl: zoomMeetingState.meetingUrl } }
      : undefined;

    return {
      pathname: from,
      state: locationState,
    };
  };

  const gotoLastVisitedPage = () => {
    const lastVisitedPageInfo = getLastVisitedPageInfo();
    const isMicrosoftOAuth = checkIsMicrosoftOAuth({ lastVisitedPageInfo });
    const { states } = lastVisitedPageInfo;
    handleLastVisitedStates(states);

    /**
     * In Oauth login/signup (wherever Microsoft OAuth is in use), redirection will be handled in the
     * hydrateLogin which is called by microsoftOAuthLogin or microsoftOAuthSignup in handleLastVisitedStates
     * hence just remove the lastVisitedPage data from storage (sessionStorage).
     */
    if (!isMicrosoftOAuth) {
      const locationState = constructLocation({ lastVisitedPageInfo });
      resetLastVisitedPage();
      history.replace(locationState);
    } else {
      resetLastVisitedPage();
    }
  };

  const setPersonalCalendarAsLastVisited = () => {
    const payload = {
      from: '/controller/Wbflow',
      states: personalCalendarStates,
    };
    setLastVisitedPageInfo(payload);
  };

  const setImportOutlookEventDialogAsLastVisited = () => {
    const payload = {
      from: '/controller/Wbflow',
      states: importOutlookEventDialogStates,
    };
    setLastVisitedPageInfo(payload);
  };

  const setImportOutlookEventDialogAsLastVisitedforController = () => {
    const payload = {
      from: '/controller/launcher',
      states: [],
    };
    setLastVisitedPageInfo(payload);
  };

  /**
   *
   * @param {{ spaceId: string }} data
   */
  const setJoinSpaceRequestAsLastVisited = (data) => {
    const payload = {
      from: '/controller/Wbflow',
      states: constructJoinSpaceRequestStates(data),
    };
    setLastVisitedPageInfo(payload);
  };

  /**
   *
   * @param {{ workspaceId: string }} data
   */
  const setJoinWorkspaceRequestAsLastVisited = (data) => {
    const payload = {
      from: '/controller/Wbflow',
      states: constructJoinWorkspaceRequestStates(data),
    };
    setLastVisitedPageInfo(payload);
  };

  const setMicrosoftLoginAsLastVisited = () => {
    const payload = {
      from: '/',
      states: microsoftOAuthLoginStates,
    };
    setLastVisitedPageInfo(payload);
  };

  const setMicrosoftSignUpAsLastVisited = () => {
    const payload = {
      from: '/',
      states: microsoftOAuthSignupStates,
    };
    setLastVisitedPageInfo(payload);
  };

  const setMicrosoftLinkWithDeviceAsLastVisited = () => {
    const payload = {
      from: '/',
      states: microsoftOAuthLinkWithDeviceStates,
    };
    setLastVisitedPageInfo(payload);
  };

  /**
   *
   * @param {{ meetingUrl }} data
   */
  const setZoomGoToMeetingDialogAsLastVisited = (data) => {
    const payload = {
      from: '/controller/Wbflow',
      states: getZoomGoToMeetingDialogStates(data),
    };
    setLastVisitedPageInfo(payload);
  };

  const setSetupZoomAccountDialogAsLastVisited = () => {
    const payload = {
      from: '/controller/Wbflow',
      states: setupZoomAccountDialogStates,
    };
    setLastVisitedPageInfo(payload);
  };

  /**
   *
   * @param {{ meetingUrl: string }} data
   */
  const setSignInAndJoinMicrosoftTeamsAsLastVisisted = (data) => {
    const payload = {
      from: '/controller/Wbflow',
      states: getSignInAndJoinMicrosoftTeamsStates(data),
    };
    setLastVisitedPageInfo(payload);
  };

  return {
    checkIsMicrosoftOAuth,
    checkIsMicrosoftOAuthLogin,
    checkIsMicrosoftOAuthLoginOrSignup,
    checkIsMicrosoftOAuthSignup,
    getLastVisitedPageInfo,
    gotoLastVisitedPage,
    microsoftOAuthLinkWithDevice,
    setImportOutlookEventDialogAsLastVisited,
    setImportOutlookEventDialogAsLastVisitedforController,
    setJoinSpaceRequestAsLastVisited,
    setJoinWorkspaceRequestAsLastVisited,
    setLastVisitedPageInfo,
    setMicrosoftLinkWithDeviceAsLastVisited,
    setMicrosoftLoginAsLastVisited,
    setMicrosoftSignUpAsLastVisited,
    setPersonalCalendarAsLastVisited,
    setSetupZoomAccountDialogAsLastVisited,
    setSignInAndJoinMicrosoftTeamsAsLastVisisted,
    setZoomGoToMeetingDialogAsLastVisited,
  };
};
