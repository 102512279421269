import Konva from 'konva';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

/**@param {{stage: Konva.Stage, stageSize: {width: number,height: number}, gridGap?: number, renderFlag: boolean, show: boolean}} props */
const WhiteboardGrid = (props) => {
  const isEffectInProgress = useRef(false);
  const timerRef = useRef();
  const callbackRef = useRef();
  const {
    stage,
    stageSize: { width, height },
    gridGap = 20,
    /** Flag to force rendering of the grid layout while whiteboard is loaded initially
     * or when user has changed the page */
    renderFlag,
    show,
  } = props;
  const {
    scale,
    position: { x: stageX, y: stageY },
  } = useSelector((state) => state.canvasReducer);

  const constructGridLine = ({ width = 0, height = 0, isParent = false }) => {
    const stroke = isParent ? '#b4b4b433' : '#bcbcbc20';
    return new Konva.Rect({
      x: 0,
      y: 0,
      width,
      height,
      strokeWidth: 1,
      stroke,
      strokeScaleEnabled: false,
    });
  };

  // For rendering the grid layout initially and on zoom level change if needed
  useEffect(() => {
    callbackRef.current = () => {
      isEffectInProgress.current = false;
      if (!stage) return;
      if (!show) {
        const oldGridLayer = stage.findOne('#grid-layer');
        if (oldGridLayer) oldGridLayer.destroy();
        return;
      }
      const backgroundLayer = stage.findOne('.background');
      // Only add the grid layer, if the background layer is already added
      if (!backgroundLayer) return;
      /** Adding certain steps for updating grid only when the zoom level is doubled while zooming in
       * or the opposite while zooming out */
      let step = 1,
        sqr = 1;
      while (true) {
        sqr *= 2;
        if (sqr > scale) break;
        step = sqr;
      }
      /**@type {Konva.Layer} */
      const oldGridLayer = stage.findOne('#grid-layer');
      if (oldGridLayer) oldGridLayer.destroy();
      const gridLayer = new Konva.Layer({ id: 'grid-layer' });
      const parentGap = gridGap * 5;
      const parentRowsCount = Math.ceil(height / parentGap) * step;
      const parentColumnsCount = Math.ceil(width / parentGap) * step;
      const baseParentRow = constructGridLine({ width, isParent: true });
      const baseParentColumn = constructGridLine({ height, isParent: true });
      const baseRow = constructGridLine({ width });
      const baseColumn = constructGridLine({ height });
      /** These variables are for adding grid lines only to the visible viewport of the whiteboard
       * to increase the grid rendering performance */
      const startX = -stageX / scale - parentGap / step;
      const startY = -stageY / scale - parentGap / step;
      const endX = startX + width / scale + parentGap / step;
      const endY = startY + height / scale + parentGap / step;
      [...Array(parentRowsCount)].forEach((_, parentRowIndex) => {
        const y = (parentRowIndex * parentGap) / step;
        if (y < startY || y > endY) return;
        const parentRowClone = baseParentRow.clone({ y });
        gridLayer.add(parentRowClone);
        [...Array(4)].forEach((_, rowIndex) => {
          const baseRowClone = baseRow.clone({
            y: y + ((rowIndex + 1) * gridGap) / step,
          });
          gridLayer.add(baseRowClone);
        });
      });
      [...Array(parentColumnsCount)].forEach((_, parentColumnIndex) => {
        const x = (parentColumnIndex * parentGap) / step;
        if (x < startX || x > endX) return;
        const columnClone = baseParentColumn.clone({ x });
        gridLayer.add(columnClone);
        [...Array(4)].forEach((_, columnIndex) => {
          const baseColumnClone = baseColumn.clone({
            x: x + ((columnIndex + 1) * gridGap) / step,
          });
          gridLayer.add(baseColumnClone);
        });
      });
      stage.add(gridLayer);
      gridLayer.zIndex(1);
    };
    /** Throttling the grid render effect and making sure that the last effect is the one that will
     * always be called, by saving the last effect as a callback in the callbackRef and running it
     * once only every 500ms*/
    if (isEffectInProgress.current) return;
    isEffectInProgress.current = true;
    timerRef.current = setTimeout(() => callbackRef.current(), 500);
  }, [stage, scale, width, height, stageX, stageY, gridGap, show, renderFlag]);

  useEffect(() => () => timerRef.current && clearTimeout(timerRef.current), []);

  return <></>;
};

export default WhiteboardGrid;
