import React from 'react';

const BirdaiLogo = (props) => {
  let fill = props.fill ? props.fill : '#000';
  let fillOpacity = props.fill ? 1 : 0.38;

  return (
    <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.9997 13.6975C14.0786 13.6975 16.5745 11.2022 16.5745 8.12419C16.5745 5.04614 14.0786 2.55088 10.9997 2.55088C7.92081 2.55088 5.42489 5.04614 5.42489 8.12419C5.42489 11.2022 7.92081 13.6975 10.9997 13.6975ZM10.9997 16.1745C15.4469 16.1745 19.0522 12.5703 19.0522 8.12419C19.0522 3.67811 15.4469 0.0738525 10.9997 0.0738525C6.55242 0.0738525 2.9472 3.67811 2.9472 8.12419C2.9472 12.5703 6.55242 16.1745 10.9997 16.1745Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.1372 18.4322C21.5854 18.9591 21.5215 19.7494 20.9945 20.1975C18.2183 22.5579 14.7054 23.8761 11.0613 23.9251C7.41724 23.9741 3.8702 22.7507 1.0315 20.4658C0.492647 20.0321 0.407522 19.2438 0.841366 18.7051C1.27521 18.1664 2.06374 18.0813 2.60259 18.515C4.9871 20.4343 7.96662 21.462 11.0277 21.4208C14.0887 21.3797 17.0395 20.2723 19.3715 18.2896C19.8985 17.8415 20.689 17.9054 21.1372 18.4322Z"
        fill={fill}
      />
    </svg>
  );
};

export default BirdaiLogo;
