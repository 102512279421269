import { useCallback, useState } from 'react';
import PubSub from 'pubsub-js';
import { useMicrosoftDispatcher } from '../../../../../../store/dispatcher/useMicrosoftDispatcher';
import { useMicrosoftAuth } from '../../../../../../Utils/microsoft/useMicrosoftAuth';
import calendarService from '../../../../../../services/calendar.service';
import { useMicrosoftSelector } from '../../../../../../store/selector/useMicrosoftSelector';
import {
  getCanvasCode,
  getFlowId,
  getWorkspaceId,
} from '../../../../../../Utils/authentication-access';
import { schedulesService } from '../../../../../../services/schedules.service';
import { EVENTS } from '../../../../../../constants/events';
import { getEndTime, getStartTime } from '../../../../../../Utils/microsoft/outlook-event';
import { useSchedulesDispatcher } from '../../../../../../store/dispatcher/useSchedulesDispatcher';
import { useNotificationDispatcher } from '../../../../../../store/dispatcher/useNotificationDispatcher';

export const usePersonalCalendar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { dispatchSetOutlookEventsDayWise } = useMicrosoftDispatcher();
  const { outlookEventsDayWise } = useMicrosoftSelector();
  const { isMicrosoftAuthenticated } = useMicrosoftAuth();
  const { dispatchAddOutlookImportedEvent } = useSchedulesDispatcher();
  const { dispatchSetSnackbar } = useNotificationDispatcher();
  const { dispatchSetOutlookImportedEvents } = useSchedulesDispatcher();

  const importedToSpaceMessage = 'Event imported to space';

  const fetchOutlookImportedEventsIds = useCallback(async () => {
    try {
      const flowId = getFlowId();
      const outlookImportedEventsIds = await schedulesService.getOutlookImportedEventsIds({
        flowId,
      });
      dispatchSetOutlookImportedEvents(outlookImportedEventsIds);
    } catch (error) {
      console.log('error', error);
    }
  }, []);

  const fetchCalendarInfo = useCallback(
    /**
     *
     * @param {{
     *  forceRefetch: boolean
     * }} options
     */
    async (options = {}) => {
      const { forceRefetch } = options;
      if (isMicrosoftAuthenticated && (forceRefetch || !outlookEventsDayWise)) {
        try {
          setIsLoading(true);
          const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
          fetchOutlookImportedEventsIds();
          const res = await calendarService.getCalendarInfo(timeZone);
          dispatchSetOutlookEventsDayWise(res.data.data);
          setIsLoading(false);
        } catch (error) {
          console.log('error', error);
          setIsLoading(false);
        }
      }
    },
    [isMicrosoftAuthenticated],
  );

  const refetchCalendarInfo = useCallback(() => {
    fetchCalendarInfo({ forceRefetch: true });
  }, [fetchCalendarInfo]);

  const getOccurence = (eventInfo) => {
    if (eventInfo?.occurence) {
      return eventInfo?.occurence;
    }

    switch (eventInfo?.recurrence?.pattern?.type) {
      case 'daily':
        return 'DAILY';
      case 'weekly':
        return 'WEEKLY';
      case 'absoluteMonthly':
        return 'MONTHLY';
      case 'absoluteYearly':
        return 'YEARLY';
      default:
        return 'NONE';
    }
  };

  const getLink = (eventInfo) => {
    const joinUrl = eventInfo?.onlineMeeting?.joinUrl;
    return joinUrl ? joinUrl : '';
  };

  const importEventToSpace = async (eventInfo) => {
    const payload = {
      workspaceId: getWorkspaceId(),
      classId: getCanvasCode(),
      title: eventInfo.subject,
      description: '',
      link: getLink(eventInfo),
      startTime: getStartTime(eventInfo),
      endTime: getEndTime(eventInfo),
      occurence: getOccurence(eventInfo),
      isAllDay: eventInfo.isAllDay,
      meta: {
        type: 'OUTLOOK',
        id: eventInfo.id,
        iCalUId: eventInfo.iCalUId,
      },
    };
    await schedulesService.create(payload);
    PubSub.publish(EVENTS.GET_SCHEDULES);
    dispatchAddOutlookImportedEvent({ id: eventInfo.id, iCalUId: eventInfo.iCalUId });
    dispatchSetSnackbar({ message: importedToSpaceMessage });
  };

  return {
    isLoading,
    importEventToSpace,
    fetchCalendarInfo,
    refetchCalendarInfo,
  };
};
