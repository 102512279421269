import React from 'react';
import Switch from '@material-ui/core/Switch';
import { usePdfPagesGalleryFooterStyles } from './component.style';
import { Button, Typography, TextField, useMediaQuery } from '@material-ui/core';

export const Footer = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const classes = usePdfPagesGalleryFooterStyles();
  const {
    firstPageInputLabel,
    fullscreenSwitchLabel,
    importDocButtonLabel,
    importDocs,
    lastPageInputLabel,
    pageRange,
    setFirstPageRange,
    setLastPageRange,
    shouldBeFullscreen,
    toggleShouldBeFullscreen,
  } = props;

  const onChangeFirstPage = (event, isBlur) => {
    setFirstPageRange(event.target.value, isBlur);
  };

  const onChangeLastPage = (event, isBlur) => {
    setLastPageRange(event.target.value, isBlur);
  };

  const fullscreenSwitch = (
    <div
      className={classes.fullscreenSwitchContainer}
      style={isMobile && isPortrait ? { width: '100%' } : {}}
    >
      <Switch
        checked={shouldBeFullscreen}
        name="shouldBeFullscreen"
        color="primary"
        onChange={toggleShouldBeFullscreen}
      />
      <Typography variant="h5">{fullscreenSwitchLabel}</Typography>
    </div>
  );

  const pageRangeInputContainer = (
    <>
      <div className={classes.pageRangeInput}>
        <Typography variant="h5">{firstPageInputLabel}</Typography>
        <TextField
          variant="outlined"
          type="number"
          value={pageRange.firstPage}
          style={{ width: '4rem' }}
          size="small"
          onChange={onChangeFirstPage}
          onBlur={(e) => onChangeFirstPage(e, true)}
        />
      </div>
      <div className={classes.pageRangeInput}>
        <Typography variant="h5">{lastPageInputLabel}</Typography>
        <TextField
          variant="outlined"
          type="number"
          value={pageRange.lastPage}
          style={{ width: '4rem' }}
          size="small"
          onChange={onChangeLastPage}
          onBlur={(e) => onChangeLastPage(e, true)}
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        className={classes.importDocButtonLabel}
        style={isMobile && isPortrait ? { width: '100%' } : {}}
        onClick={importDocs}
      >
        {importDocButtonLabel}
      </Button>
    </>
  );

  return (
    <div className={classes.container}>
      {fullscreenSwitch}
      {pageRangeInputContainer}
    </div>
  );
};
