import { INVITES } from '../Utils/url-builder';
import { get, post } from '../Utils/api';

class InviteService {
  canSendJoinSpaceRequest = async (classCode, email) => {
    const url = INVITES.canSendJoinSpaceRequest(classCode, email);
    return get(url);
  };

  canSendJoinWorkspaceRequest = async (workspaceId, email) => {
    const url = INVITES.canSendJoinWorkspaceRequest(workspaceId, email);
    return get(url);
  };

  createAndSendJoinSpaceRequest = async (payload) => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const url = INVITES.createAndSendJoinSpaceRequest();
    return post(url, payload, config);
  };

  /**
   *
   * @param {{ code: string, spaceId: string, provider: string }} payload
   * @returns
   */
  createAndSendJoinSpaceRequestUsingOAuth = async (payload) => {
    const url = INVITES.createAndSendJoinSpaceRequestUsingOAuth();
    return post(url, payload);
  };

  createAndSendJoinWorkspaceRequest = async (payload) => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const url = INVITES.createAndSendJoinWorkspaceRequest();
    return post(url, payload, config);
  };

  /**
   *
   * @param {{ code: string, workspaceId: string, provider: string }} payload
   * @returns
   */
  createAndSendJoinWorkspaceRequestUsingOAuth = async (payload) => {
    const url = INVITES.createAndSendJoinWorkspaceRequestUsingOAuth();
    return post(url, payload);
  };

  sendJoinSpaceRequest = async (payload) => {
    const url = INVITES.sendJoinSpaceRequest();
    return post(url, payload);
  };

  sendJoinWorkspaceRequest = async (payload) => {
    const url = INVITES.sendJoinWorkspaceRequest();
    return post(url, payload);
  };

  accept = async (joinRequestId) => {
    let url = INVITES.accept();
    const payload = { joinRequestId };
    return post(url, payload);
  };

  reject = async (joinRequestId) => {
    let url = INVITES.reject();
    const payload = { joinRequestId };
    return post(url, payload);
  };

  acceptAll = async (workspaceId) => {
    let url = INVITES.acceptAll();
    const payload = { workspaceId };
    return post(url, payload);
  };

  getAllPendingRequests = async (workspaceId) => {
    let url = INVITES.getAllPendingRequests(workspaceId);
    return get(url);
  };
}

const inviteService = new InviteService();
export default inviteService;
