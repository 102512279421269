import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMediaClient } from '../../../../components/webrtc/screenshare/useMediaClient';
import deviceService from '../../../../services/device.service';
import {
  getAnonymousUserToken,
  getIsScreenshareImproveExperienceSuggestionDisabled,
  getToken,
  getWorkspaceId,
} from '../../../../Utils/authentication-access';
import config from '../../../../config';
import { v4 } from 'uuid';
import { ScreenshareTileButton } from './component';
import DeviceDetector from 'device-detector-js';
import { useNotificationDispatcher } from '../../../../store/dispatcher/useNotificationDispatcher';
import ScreenShareMethodsDialog from '../../dialogs/screen-share-methods-dialog';
import OnlineScreenShareDialog from '../../dialogs/ScreenShareExperienceDialog';
import LocalScreenShareDialog from '../../dialogs/local-screen-share-dialog';
/**
 * This is the container for screenshare in Launcher
 *
 * @param {{ isDeviceConnected, roomId, onlyOutgoing }} props
 */
export const ScreenshareContainer = (props) => {
  const [isScreenShareMethodsOpen, setIsScreenShareMethodsOpen] = useState(false);
  const [isLocalShareDialogOpen, setIsLocalShareDialogOpen] = useState(false);
  // const roomId = useMemo(() => v4(), []);
  const isConnectionInitiated = useRef(false);
  const { isDeviceConnected, roomId, isMobile } = props;

  const deviceDetector = new DeviceDetector();
  const device = deviceDetector.parse(navigator.userAgent);
  const isTablet = device.device.type.includes('tablet');
  const { dispatchSetSnackbar } = useNotificationDispatcher();
  const [isOnlineShareDialogOpen, setIsOnlineShareDialogOpen] = useState(false);
  const { dispatchShowSimultaneousLimitExhaustedDialog, dispatchShowFeatureLockedDialog } =
    useNotificationDispatcher();

  const sendScreenshareStoppedInfoToDevice = async () => {
    try {
      let accessToken = getAccessToken();
      const payload = {
        type: 'SCREENSHARE_STOPPED',
        roomId,
      };
      await deviceService.sendScreenshareStoppedInfoToDevice(payload, accessToken);
    } catch (error) {
      console.log('error', error);
    }
  };

  const {
    isScreenShared,
    checkIsAllowedToStream,
    publish,
    getUserMedia,
    stopScreenshare,
    connect,
  } = useMediaClient({
    roomId,
    willHandleConnection: true,
    onlyOutgoing: props.onlyOutgoing,
  });

  /**
   * if device is connected and connection is not initiated, then
   * connect to screenshare
   *
   */
  useEffect(() => {
    if (isDeviceConnected && !isConnectionInitiated.current) {
      connect();
      isConnectionInitiated.current = true;
    }
  }, [isDeviceConnected]);

  const handleStopScreenshare = async () => {
    stopScreenshare();
  };

  /**
   * if device is disconnected, stop screenshare
   */
  useEffect(() => {
    if (!isDeviceConnected && isScreenShared) {
      handleStopScreenshare();
    }
  }, [isDeviceConnected, isScreenShared]);

  const label = isScreenShared ? 'Stop sharing' : 'Share screen';

  /**
   *
   * @param {{ roomId: string }} data
   */
  const constructRoomUrl = (data) => {
    return `${config.APP_URL}/huddle?isViewOnly=true&roomId=${data.roomId}`;
  };

  const getAccessToken = () => {
    let accessToken = getToken();
    if (!accessToken) {
      accessToken = getAnonymousUserToken();
    }
    return accessToken;
  };

  const sendScreenshareStartedInfoToDevice = async () => {
    try {
      let accessToken = getAccessToken();
      const url = constructRoomUrl({ roomId });
      const payload = {
        type: 'SCREENSHARE_STARTED',
        url,
        roomId,
      };
      await deviceService.sendScreenshareStartedInfoToDevice(payload, accessToken);
    } catch (error) {
      console.log('error', error);
    }
  };

  /**
   * Start screenshare from Launcher
   */
  const handleStartScreenshare = async () => {
    try {
      await publish({ source: 'screenshare', useExistingStream: true, forceVP8: true });
    } catch (error) {
      console.log('error', error);
    }
  };

  const continueClickHandler = async () => {
    setIsOnlineShareDialogOpen(false);
    await getUserMedia({ source: 'screenshare' });
    handleStartScreenshare();
  };

  const openScreenShareMethodsDialog = () => {
    isScreenShared ? handleStopScreenshare() : setIsScreenShareMethodsOpen(true);
  };
  const closeScreenShareMethodsDialog = () => setIsScreenShareMethodsOpen(false);

  const openOnlineShareDialog = async () => {
    try {
      await checkIsAllowedToStream();
    } catch (err) {
      console.log(err);
      return;
    }

    closeScreenShareMethodsDialog();

    if (device.client.name === 'Safari') {
      dispatchSetSnackbar({
        message: `Use macOS v13 to use screen sharing on Safari or use another browser`,
      });
    }

    const checked = getIsScreenshareImproveExperienceSuggestionDisabled();
    if (checked) {
      await getUserMedia({ source: 'screenshare' });
      handleStartScreenshare();
    } else {
      setIsOnlineShareDialogOpen(true);
    }
  };

  const closeOnlineShareDialog = () => setIsOnlineShareDialogOpen(false);

  const openLocalScreenShareDialog = () => {
    closeScreenShareMethodsDialog();
    setIsLocalShareDialogOpen(true);
  };

  const closeLocalScreenShareDialog = () => setIsLocalShareDialogOpen(false);

  return (
    <>
      <ScreenshareTileButton
        onClick={isMobile || isTablet ? '' : openScreenShareMethodsDialog}
        label={label}
      />
      <ScreenShareMethodsDialog
        open={isScreenShareMethodsOpen}
        onClose={closeScreenShareMethodsDialog}
        onOnlineShareClick={openOnlineShareDialog}
        onLocalShareClick={openLocalScreenShareDialog}
      />
      <OnlineScreenShareDialog
        open={isOnlineShareDialogOpen}
        onClose={closeOnlineShareDialog}
        continueClickHandler={continueClickHandler}
      />
      <LocalScreenShareDialog open={isLocalShareDialogOpen} onClose={closeLocalScreenShareDialog} />
    </>
  );
};
