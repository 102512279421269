import React from 'react';
import { EventUserProfile, EventUserProfileName } from './component';

export const EventUserProfileContainer = (props) => {
  const { eventInfo } = props;

  const eventOrganizerName = eventInfo?.organizer?.emailAddress?.name;

  return <EventUserProfile eventOrganizerName={eventOrganizerName} />;
};

export const EventUserProfileNameContainer = (props) => {
  const { eventInfo } = props;

  const eventOrganizerName = eventInfo?.organizer?.emailAddress?.name;

  return <EventUserProfileName eventOrganizerName={eventOrganizerName} />;
};
