import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSpring, animated } from 'react-spring';
import styles from './mouse-cursor.module.css';

export const MouseCursor = ({
  userMouseData: { mousePosX, mousePosY, userName, color, userID },
}) => {
  const { scale, position, transformFactor } = useSelector((state) => state.canvasReducer);

  const mousePosition = useMemo(() => {
    const { x, y } = position;
    const relativeCanvasLeft = -x / scale / transformFactor;
    const relativeCanvasTop = -y / scale / transformFactor;
    const mouseLeft = (mousePosX - relativeCanvasLeft) * scale * transformFactor;
    const mouseTop = (mousePosY - relativeCanvasTop) * scale * transformFactor;
    return { left: mouseLeft, top: mouseTop };
  }, [scale, position, transformFactor, mousePosX, mousePosY]);

  const animationStyle = useSpring(mousePosition);

  return (
    <animated.div key={userID} className={styles.cursorContainer} style={animationStyle}>
      <svg
        style={{ color: 'red', fill: 'red' }}
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={color}
          d="M16.0039 7.11092L0.941444 0.565936C0.76485 0.489217 0.55935 0.528279 0.423193 0.664404C0.287068 0.80056 0.248006 1.00612 0.324725 1.18268L6.86973 16.2452C6.94441 16.417 7.11379 16.5271 7.29951 16.5271C7.30835 16.5271 7.31726 16.5268 7.3261 16.5263C7.52213 16.5152 7.69048 16.3832 7.74788 16.1954L9.15138 11.6065C9.47604 10.5449 10.3037 9.71729 11.3653 9.39261L15.9542 7.98911C16.1419 7.93167 16.274 7.76333 16.2851 7.56733C16.2962 7.37133 16.184 7.18917 16.0039 7.11092Z"
        />
      </svg>
      <div className={styles.userName} style={{ backgroundColor: color }}>
        {userName}
      </div>
    </animated.div>
  );
};
