import React from 'react';
import { Paper } from '@material-ui/core';

import undoIcon from '../assets/img/UndoAbled.svg';
import redoIcon from '../assets/img/RedoAbled.svg';
import './toolbar.scss';

class UndoRedoLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  undo = () => {
    this.props.performUndo();
  };

  redo = () => {
    this.props.performRedo();
  };

  render() {
    const { undoEnabled, redoEnabled } = this.props;
    return (
      <div className="undoredoWrap">
        <Paper className="undoredo-content" elevation={3}>
          <div
            className={`undoredo-content-item${undoEnabled ? '' : ' disabled'}`}
            onClick={this.undo}
          >
            <img alt="undo" src={undoIcon} />
          </div>
          <div className="undoredo-divide"></div>
          <div
            className={`undoredo-content-item${redoEnabled ? '' : ' disabled'}`}
            onClick={this.redo}
          >
            <img alt="redo" src={redoIcon} />
          </div>
        </Paper>
      </div>
    );
  }
}

export default UndoRedoLayout;
