import React from 'react';
import { Footer } from './component';

export const FooterContainer = (props) => {
  const {
    pageRange,
    setFirstPageRange,
    setLastPageRange,
    shouldBeFullscreen,
    toggleShouldBeFullscreen,
    importDocs,
  } = props;

  const firstPageInputLabel = 'First Page:';
  const fullscreenSwitchLabel = 'Add page as fullscreen';
  const importDocButtonLabel = 'Import Doc';
  const lastPageInputLabel = 'Last Page:';

  return (
    <Footer
      firstPageInputLabel={firstPageInputLabel}
      fullscreenSwitchLabel={fullscreenSwitchLabel}
      importDocButtonLabel={importDocButtonLabel}
      lastPageInputLabel={lastPageInputLabel}
      setFirstPageRange={setFirstPageRange}
      pageRange={pageRange}
      setLastPageRange={setLastPageRange}
      shouldBeFullscreen={shouldBeFullscreen}
      toggleShouldBeFullscreen={toggleShouldBeFullscreen}
      importDocs={importDocs}
    />
  );
};
