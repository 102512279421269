import { Button } from '@material-ui/core';
import React from 'react';
import StyledButton from '../../../../../../UIComponents/input/StyledButton';
import { StyledDialogContainer } from '../../../../../common/dialog/styled-dialog/container';
import { useVideoCallUserSelectionDialogStyles } from './component.style';

const VideoCallUserSelectionDialog = (props) => {
  const {
    closeDialog,
    disableJoinAsGuestButton,
    disableJoinAsMicrosoftUserButton,
    isOpenDialog,
    joinAsGuest,
    joinAsGuestButtonTitle,
    joinAsMicrosoftUser,
    joinAsMicrosoftUserButtonTitle,
    title,
  } = props;
  const classes = useVideoCallUserSelectionDialogStyles();

  const joinAsGuestButton = (
    <StyledButton
      variant="outlined"
      color="primary"
      onClick={joinAsGuest}
      disabled={disableJoinAsGuestButton}
      loading={disableJoinAsGuestButton}
    >
      {joinAsGuestButtonTitle}
    </StyledButton>
  );
  
  const joinAsMicrosoftUserButton = (
    <StyledButton
      variant="outlined"
      color="primary"
      onClick={joinAsMicrosoftUser}
      disabled={disableJoinAsMicrosoftUserButton}
      loading={disableJoinAsMicrosoftUserButton}
    >
      {joinAsMicrosoftUserButtonTitle}
    </StyledButton>
  );

  return (
    <StyledDialogContainer open={isOpenDialog} onClose={closeDialog} title={title} hideFooter>
      <div className={classes.actionsContainer}>
        {joinAsGuestButton}
        {joinAsMicrosoftUserButton}
      </div>
    </StyledDialogContainer>
  );
};

export default VideoCallUserSelectionDialog;
