import ACTION_TYPES from '../actions/type.action';

const initialState = {
  importOutlookEventDialog: {
    isOpen: false,
  },
  videoCallSidebarDialog: {
    isOpen: false,
  },
  navigationSidebarDialog: {
    isOpen: true,
  },
  joinAsHostDialog: {
    isOpen: true,
  },
  joinRequestSentDialog: {
    isOpen: false,
  },
  remoteControlDialog: {
    isOpen: false,
    isAnonymousUser: true,
  },
};

const dialogReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPES.SET_IS_OPEN_IMPORT_OUTLOOK_EVENTS_DIALOG:
      return {
        ...state,
        importOutlookEventDialog: {
          isOpen: payload,
        },
      };
    case ACTION_TYPES.SET_IS_OPEN_VIDEO_CALL_SIDEBAR_DIALOG:
      return {
        ...state,
        videoCallSidebarDialog: {
          isOpen: payload,
        },
      };
    case ACTION_TYPES.SET_IS_OPEN_NAVIGATION_SIDEBAR_DIALOG:
      return {
        ...state,
        navigationSidebarDialog: {
          isOpen: payload,
        },
      };
    case ACTION_TYPES.SET_IS_OPEN_JOIN_AS_HOST_DIALOG:
      return {
        ...state,
        joinAsHostDialog: {
          isOpen: payload,
        },
      };
    case ACTION_TYPES.SET_IS_OPEN_JOIN_REQUEST_SENT_DIALOG:
      return {
        ...state,
        joinRequestSentDialog: {
          isOpen: payload,
        },
      };
    case ACTION_TYPES.SET_IS_REMOTE_CONTROL_DIALOG_OPEN:
      return {
        ...state,
        remoteControlDialog: {
          ...payload,
        },
      };
    default:
      return { ...state };
  }
};

export default dialogReducer;
