import ACTION_TYPES from '../actions/type.action';
import { manipulateElementMap, setElementMap } from '../../Web-Ai-Canvas/utils/redoUndo';
import { cloneDeep } from 'lodash';

const initialState = {
	elements: [],
	pageCollection: [],
	backGroundCollection: [],
	selectedPageId: '',
	newElement: {},
	imageElement: [],
	totalPages: 1,
	pageNumber: 1,
	groupCollection: []
};

const reducer = (state = initialState, action) => {
	const { type, payload } = cloneDeep(action);

	switch (type) {
		case ACTION_TYPES.LOAD_ELEMENTS: {
			setElementMap(payload);
			return {
				...state,
				elements: JSON.parse(JSON.stringify(payload))
			};
		}
		case ACTION_TYPES.ADD_NEW_ELEMENT: {
			if (
				payload.type === 'polyline' ||
				payload.type === 'image' ||
				payload.type === 'document' ||
				payload.type === 'video' ||
				payload.type === 'youtube' ||
				payload.type === 'text'
			)
				setElementMap([ payload ]);
			else if (payload.type === 'manipulation') {
				manipulateElementMap(payload);
			}
			return {
				...state,
				newElement: payload
			};
		}
		case ACTION_TYPES.ADD_IMAGE_ELEMENT: {
			setElementMap([ payload ]);
			return {
				...state,
				imageElement: payload
			};
		}
		case ACTION_TYPES.LOAD_BACK_GROUND_COLLECTION:
			return {
				...state,
				backGroundCollection: payload,
			};
		case ACTION_TYPES.LOAD_PAGE_COLLECTION:
			let pages = payload.length;
			return {
				...state,
				pageCollection: payload,
				totalPages: pages
			};

		case ACTION_TYPES.LOAD_GROUP_COLLECTION:
			return {
				...state,
				groupCollection: payload
			};

		case ACTION_TYPES.UPDATE_SELECTED_PAGE:
			let pageIndex = state.pageCollection.indexOf(payload);
			return {
				...state,
				selectedPageId: payload,
				pageNumber: pageIndex + 1
			};

		default:
			return { ...state };
	}
};

export default reducer;
