import { getBoardInfo, getFlowId, getUserName } from '../../Utils/authentication-access';

// todo socket io client can be registered here
let socket = null;
let methodsToRegister = [];

// todo these method to register only required till we move socket connection here
export const initSocket = (_socket) => {
  socket = _socket;
  if (methodsToRegister.length === 0) {
    return;
  }
  for (const methodToRegister of methodsToRegister) {
    const { type, method } = methodToRegister;
    registerEvent({ type, callback: method });
  }
  methodsToRegister = [];
};

export const emitEvent = ({ type, data, pageId }) => {
  if (socket === null) {
    return;
  }
  const flowId = getFlowId();
  const userName = getUserName();
  socket.emit('message', JSON.stringify({ type, userName, flowId, pageId, ...data }));
};

/**
 * TODO: create a generic or dedicated method for sending event
 * @param {*} messageObj
 * @returns
 */
export const sendSocketMessage = (messageObj) => {
  if (socket === null) {
    console.log('socket is null');
    return;
  }
  console.log('sending socket message');
  socket.emit('message', JSON.stringify(messageObj));
};

// TODO THIS IS NOT REQUIRED IF SOCKET CONNECTION IS MOVED HERE
const registerMethod = ({ type, method }) => {
  methodsToRegister.push({ type, method });
};

export const registerEvent = ({ type, callback }) => {
  if (socket === null) {
    return registerMethod({ type, method: callback });
  }

  socket.on('message', (data) => {
    if (data.type === type) {
      return callback(data);
    }
  });
};

export const resetSocket = () => {
  socket = null;
};
