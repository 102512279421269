import { useEffect, useMemo } from 'react';
import { useUpdateCursorSocket } from './update-cursor-socket';
import { getColorCode } from '../Utils/getColorCode';

export const useMouseReceive = () => {
  const socketData = useUpdateCursorSocket();

  const userMouseData = useMemo(() => {
    const { userName, userID } = socketData;
    const updatedUserName = userName.length > 10 ? `${userName.substr(0, 8)}..` : userName;
    return {
      ...socketData,
      color: userID ? getColorCode(userID) : '#000000',
      userName: updatedUserName,
    };
  }, [socketData]);

  return userMouseData;
};
