import React, { useState } from 'react';
import { Button, CircularProgress, TextField, makeStyles } from '@material-ui/core';

import ActionButton from './action-button';
import { SOURCES_ACTIONS } from '../../../../../constants/smart-notes.constants';

const useStyles = ({ loading }) =>
  makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      columnGap: 16,
      rowGap: 4,
      marginBottom: 0,
      marginTop: 8,
    },
    container: {
      position: 'relative',
      flexGrow: 1,
      flexShrink: 0,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      '& .textField-container': {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        borderRadius: 8,
        border: `1px solid ${theme.palette.primary.main}`,
        padding: '12px 24px',
        backgroundColor: '#fff',
        position: 'relative',
        zIndex: 1,
        '& .MuiButton-root': {
          boxShadow: 'none',
          height: 32,
          borderRadius: 16,
        },
      },
    },
    queryInput: {
      width: '100%',
      marginBottom: 0,
      '& .MuiInputBase-root': {
        backgroundColor: '#fff',
      },
      '& fieldset': { borderColor: theme.palette.primary.main },
    },
    sendButton: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      '&:hover': {
        backgroundColor: '#ed572e',
      },
    },
    actionsContainer: {
      display: 'flex',
      pointerEvents: loading ? 'none' : 'all',
      flexWrap: 'wrap',
      alignItems: 'center',
      gap: 8,
      width: '100%',
      '& .i-clear-chat': {
        fontSize: 10,
        padding: '4px 8px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      [theme.breakpoints.down('sm')]: {
        gap: 4,
      },
    },
  }));

export const SourceInput = ({ onSend, onAction, loading, uploadHandler, disabled }) => {
  const classes = useStyles({
    loading,
  })();
  const [query, setQuery] = useState('');

  const queryChangeHandler = (e) => setQuery(e.target.value);

  const sendHandler = () => {
    if (!onSend) return;
    onSend({ query });
    setQuery('');
  };

  const keyDownHandler = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      if (!disabled && !loading && query) sendHandler();
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.actionsContainer}>
        {Object.entries(SOURCES_ACTIONS).map(([action, props]) => (
          <ActionButton
            key={action}
            onClick={() => onAction && onAction(action)}
            onChange={uploadHandler}
            alt={props.title}
            {...props}
          />
        ))}
      </div>
      <div className={classes.container}>
        <div className="textField-container">
          <TextField
            className={classes.queryInput}
            onKeyDown={keyDownHandler}
            multiline
            maxRows={3}
            placeholder="Take a note..."
            value={query}
            onChange={queryChangeHandler}
            InputProps={{ disableUnderline: true }}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={
              loading ? <CircularProgress size={16} style={{ color: '#fff' }} /> : undefined
            }
            onClick={loading ? undefined : sendHandler}
            style={{ cursor: loading ? 'wait' : undefined }}
            disabled={!query}
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SourceInput);
