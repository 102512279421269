import { useDispatch } from 'react-redux';
import { setIsScreenshared } from '../actions/media-server.action';

const useMediaServerDispatcher = () => {
  const dispatch = useDispatch();

  const dispatchSetScreenshareStarted = () => {
    dispatch(setIsScreenshared(true));
  };

  const dispatchSetScreenshareStopped = () => {
    dispatch(setIsScreenshared(false));
  };

  return {
    dispatchSetScreenshareStarted,
    dispatchSetScreenshareStopped,
  };
};

export default useMediaServerDispatcher;
