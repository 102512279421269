import React, { Component } from 'react';
import { debounce } from 'lodash';
import { Grid } from '@material-ui/core';

import { post, get } from '../../IWB/utils/api';
import Config from '../../IWB/config';
import classes from './MyCloud.module.css';
import KsarLoaderGif from '../../IWB/assets/img/ksarLoader.gif';
import EmptyMessageComponent from '../../../components/custom-components/EmptyMessageComponent/empty-Message';

export class MyCloud extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myCloudImages: [],
      myCloudVideos: [],
      showLoader: false,
    };
    this.getCloudResources = debounce(this.getCloudResources, 500);
  }

  getCloudResources = async (searchValue) => {
    if (!searchValue)
      return this.setState({ showLoader: false, myCloudImages: [], myCloudVideos: [] });
    this.setState({ showLoader: true });
    await this.getImages(searchValue);
    await this.getMyCloudVideos(searchValue);
    this.setState({ showLoader: false });
  };

  async componentDidUpdate(prevProps) {
    const { searchValue } = this.props;
    if (searchValue !== prevProps.searchValue) {
      await this.getCloudResources(searchValue);
    }
  }

  fetchImageSize = (url) => {
    return new Promise(async (resolve, reject) => {
      try {
        let img = new Image();
        img.src = url;
        let width = 300,
          height = 197;
        img.onload = function () {
          width = img.width;
          height = img.height;
          return resolve({ width, height });
        };
        img.onerror = function () {
          return resolve({ width, height });
        };
      } catch (error) {
        return reject(error);
      }
    });
  };

  uploadContentOnCanvas = async (event, type, contentUrl, contentDetails) => {
    const { height, width } = contentDetails;
    event.preventDefault();
    let contentObj = {
      x: event.type === 'dragend' ? event.clientX : undefined,
      y: event.type === 'dragend' ? event.clientY : undefined,
      height,
      width,
      contentUrl,
      contentDetails,
    };
    if (type === 'image') {
      if (!height || !width) {
        const { width, height } = await this.fetchImageSize(contentUrl);
        contentObj.width = width;
        contentObj.height = height;
      }
      this.props.canvasImage(contentObj);
    } else if (type === 'video') {
      this.props.canvasVideo(contentObj);
    }
  };

  getImages = async (value) => {
    try {
      const response = await post(Config.getImagesURL, { searchTerm: value });
      if (response.status === 200) this.setState({ myCloudImages: response.data.result });
    } catch (error) {
      console.error(error);
    }
  };

  getMyCloudVideos = async (value) => {
    try {
      const response = await get(Config.getVideosURL + value);
      if (response.status === 200) this.setState({ myCloudVideos: response.data.items });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const EmptyComponent = (
      <div className={classes.noContentMyCloud}>
        <EmptyMessageComponent size="small" text="No Content Found" />
      </div>
    );
    const myCloudImages = this.state.myCloudImages.map((image, index) => (
      <Grid item xs={12} sm={4} md={3} lg={4} key={index}>
        <img
          src={image.thumbnail.thumbnailUrl}
          width={'100%'}
          height={'100%'}
          className={classes.KsarElementBox}
          onClick={(e) => this.uploadContentOnCanvas(e, 'image', image.contentUrl, image)}
          draggable={true}
          onDragEndCapture={(e) => this.uploadContentOnCanvas(e, 'image', image.contentUrl, image)}
          alt=""
        />
      </Grid>
    ));
    const myCloudVideos = this.state.myCloudVideos.map((video, index) => {
      let resObj = {};
      if (
        video.id &&
        video.id.videoId &&
        video.snippet &&
        video.snippet.thumbnails &&
        video.snippet.thumbnails.default.url
      ) {
        resObj['url'] = `https://www.youtube.com/watch?v=${video.id.videoId}`;
        resObj['title'] = encodeURIComponent(video.snippet.title);
        resObj['thumbnail'] = video.snippet.thumbnails.default.url;
        resObj = JSON.stringify(resObj);
      }
      return (
        <Grid item xs={12} sm={4} md={3} lg={4} key={index}>
          {video &&
            video.snippet &&
            video.snippet.thumbnails &&
            video.snippet.thumbnails.default.url && (
              <img
                src={video.snippet.thumbnails.default.url}
                width={'100%'}
                height={'100%'}
                className={classes.KsarElementBox}
                onClick={(e) => this.uploadContentOnCanvas(e, 'video', resObj, video)}
                onDragEndCapture={(e) => this.uploadContentOnCanvas(e, 'video', resObj, video)}
                draggable={true}
                alt=""
              />
            )}
        </Grid>
      );
    });

    return this.state.showLoader ? (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          marginTop: '4%',
        }}
      >
        <img src={KsarLoaderGif} style={{ width: '120px' }} alt="alt" />
        <p>Getting your personalized content</p>
      </div>
    ) : (
      <Grid container style={{ height: '100%' }} spacing={2}>
        {this.props.searchValue &&
          (this.props.type === 'images'
            ? myCloudImages.length
              ? myCloudImages
              : EmptyComponent
            : myCloudVideos.length
            ? myCloudVideos
            : EmptyComponent)}
      </Grid>
    );
  }
}
