import React, { useEffect, useState } from 'react';
import { useMicrosoftSelector } from '../../../../store/selector/useMicrosoftSelector';
import { useLastPageVisited } from '../../../../Utils/history/useLastPageVisited';
import { useMicrosoftAuth } from '../../../../Utils/microsoft/useMicrosoftAuth';
import PromptRevokeAccessDialogContainer from '../prompt-revoke-access-dialog/container';
import { ImportOutlookEventDialog } from './component';
import { useGetOutlookEvents } from './use-get-outlook-events';

export const ImportOutlookEventDialogContainer = (props) => {
  const { isOpenDialog, closeDialog } = props;
  const { revokeAccess, initiateMicrosoftOAuthAuthorization, getMicrosoftAuthEmail } =
    useMicrosoftAuth();

  const { setImportOutlookEventDialogAsLastVisited } = useLastPageVisited();
  const { isAuthenticated, outlookEvents } = useMicrosoftSelector();
  const { getOutlookEvents, isHydrated, isLoading } = useGetOutlookEvents();

  const [isPromptRevokeAccessDialogOpen, setIsPromptRevokeAccessDialogOpen] = useState(false);

  const userEmail = getMicrosoftAuthEmail();

  const maxEmailCharacter = 25;

  const getEmailEllipsis = (email) => {
    return email && email.length > maxEmailCharacter
      ? `${email.substr(0, maxEmailCharacter - 3)}...`
      : email;
  };

  const emailEllipsis = getEmailEllipsis(userEmail);

  const title = `Outlook Calendar (${emailEllipsis})`;
  const revokeAccessButtonLabel = 'REVOKE ACCESS';
  const replaceAccountButtonLabel = 'REPLACE ACCOUNT';

  useEffect(() => {
    if (isOpenDialog) {
      getOutlookEvents();
    }
  }, [isAuthenticated, isOpenDialog]);

  /**
   * recurrence of outlook events
   * - null
   * - daily
   * - weekly
   * - absoluteMonthly
   * - absoluteYearly
   */
  const onClick = (options = {}) => {
    setImportOutlookEventDialogAsLastVisited();
    initiateMicrosoftOAuthAuthorization(options);
  };

  const onClickRevokeAccess = () => {
    setIsPromptRevokeAccessDialogOpen(true);
  };

  const closePromptRevokeAccessDialog = () => {
    setIsPromptRevokeAccessDialogOpen(false);
  };

  const replaceAccount = () => {
    const options = {
      replaceAccount: true,
    };
    onClick(options);
  };

  const refetchOutlookEvents = () => {
    getOutlookEvents({ forceRefresh: true });
  };

  return (
    <>
      <ImportOutlookEventDialog
        closeDialog={closeDialog}
        events={outlookEvents}
        isHydrated={isHydrated}
        isLoading={isLoading}
        isMicrosoftOAuthAuthorized={isAuthenticated}
        isOpenDialog={isOpenDialog}
        onClick={onClick}
        onClickRevokeAccess={onClickRevokeAccess}
        refetchOutlookEvents={refetchOutlookEvents}
        replaceAccount={replaceAccount}
        replaceAccountButtonLabel={replaceAccountButtonLabel}
        revokeAccessButtonLabel={revokeAccessButtonLabel}
        title={title}
      />
      <PromptRevokeAccessDialogContainer
        isOpenDialog={isPromptRevokeAccessDialogOpen}
        closeDialog={closePromptRevokeAccessDialog}
        onClickRevokeAccess={() => {
          revokeAccess();
          closeDialog();
          closePromptRevokeAccessDialog();
        }}
      />
    </>
  );
};
