import ACTION_TYPES from '../actions/type.action';

const initialState = {
  activeTeamMembers: [],
};

const shareSpaceReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPES.ACTIVE_TEAM_MEMBERS_COUNT:
      return {
        activeTeamMembers: payload,
      };
    default:
      return state;
  }
};

export default shareSpaceReducer;
