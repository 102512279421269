import { makeStyles } from '@material-ui/core';

export const useDocumentActionDialogStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  actionContainer: {
    display: 'flex',
    gap: '0.5rem',
    justifyContent: 'flex-end',
    flex: 1,
  },
}));
