import React, { useEffect } from 'react';
import {
  BackgroundContent,
  ContentContainer,
  EventCaptureSection,
  ImageContentContainer,
  InteractionArea,
} from './component.style';
import { StyledFullPageContainer } from '../../../components/common/page/full-page/container';
import { HeadingContainer } from './heading/container';
import { RemoteControllerPageIcon } from '../../../assets/icons/RemoteControllerPage';
import { Typography } from '@material-ui/core';
import StyledButton from '../../../UIComponents/input/StyledButton';
import { isMobile } from 'react-device-detect';
import { isTablet } from '../../IWB/utils/device';
import { KeyboardContainer } from './keyboard/container';
import { useWakeLock } from '../../IWB/utils/display/use-wake-lock';

export const RemoteControlDialog = React.forwardRef((props, eventCaptureSectionRef) => {
  const {
    dialogTitle,
    initializedClientXY,
    isOpen,
    keyboardValue,
    label,
    mousePosition,
    onClick,
    onClose,
    onContextMenu,
    onKeyDown,
    onLeftButtonHandleContextMenu,
    onLeftButtonTouchEnd,
    onLeftButtonTouchStart,
    onLeftClick,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onRightButtonHandleContextMenu,
    onRightClick,
    onTouchEnd,
    onTouchMove,
    onTouchStart,
    onWheel,
    sendRemoteStartMessageToDevice,
  } = props;

  useEffect(() => {
    if (eventCaptureSectionRef.current) {
      eventCaptureSectionRef.current.focus();
      initializedClientXY();
    }
    // sendRemoteStartMessageToDevice();
  }, []);

  useWakeLock();

  const imageContent = (
    <ImageContentContainer>
      <RemoteControllerPageIcon />
    </ImageContentContainer>
  );
  const content = imageContent;
  const displayMousePosition = false && (
    <p>
      Current mouse position: ({mousePosition.x}, {mousePosition.y})
    </p>
  );

  const displayDescription = (
    <Typography variant="h2" align="justify" style={{ flex: 1 }}>
      {label}
    </Typography>
  );

  const shouldShowCustomButton = isMobile | isTablet;

  const leftActionButton = (
    <StyledButton
      variant="contained"
      fullWidth
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onClick={onLeftClick}
      onTouchStart={onLeftButtonTouchStart}
      onTouchEnd={onLeftButtonTouchEnd}
      onContextMenu={onLeftButtonHandleContextMenu}
      lg
    >
      Left Click
    </StyledButton>
  );

  const rightActionButton = (
    <StyledButton
      variant="contained"
      fullWidth
      onClick={onRightClick}
      lg
      onContextMenu={onRightButtonHandleContextMenu}
    >
      Right Click
    </StyledButton>
  );

  const keyboardButton = <KeyboardContainer handleChange={onKeyDown} value={keyboardValue} />;

  const displayActions = shouldShowCustomButton ? (
    <div style={{ display: 'flex', position: 'sticky', bottom: 0, zIndex: 999999999 }}>
      {leftActionButton}
      {rightActionButton}
      {keyboardButton}
    </div>
  ) : (
    <></>
  );

  return (
    <StyledFullPageContainer
      title={dialogTitle}
      isOpen={isOpen}
      maxWidth="md"
      id="remote-controller-container"
    >
      <ContentContainer>
        <HeadingContainer onClose={onClose} />
        <EventCaptureSection
          onClick={onClick}
          onContextMenu={onContextMenu}
          onKeyDown={onKeyDown}
          onMouseDownCapture={onMouseDown}
          onMouseMoveCapture={onMouseMove}
          onMouseUpCapture={onMouseUp}
          onTouchEnd={onTouchEnd}
          onTouchMove={onTouchMove}
          onTouchStart={onTouchStart}
          onWheel={onWheel}
          ref={eventCaptureSectionRef}
          tabIndex="0"
        >
          <InteractionArea />
          <BackgroundContent>
            <div style={{ position: 'relative' }}>
              {content}
              {displayDescription}
            </div>
          </BackgroundContent>
        </EventCaptureSection>
        {displayActions}
        {displayMousePosition}
      </ContentContainer>
    </StyledFullPageContainer>
  );
});

RemoteControlDialog.displayName = 'RemoteControlDialog';
