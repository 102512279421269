import { Box } from '@material-ui/core';
import React from 'react';
import { useSidebarGroupStyles } from './component.style';

export const SidebarGroupComponent = (props) => {
  const classes = useSidebarGroupStyles();
  return (
    <Box p={2} className={classes.container}>
      {props.children}
    </Box>
  );
};
