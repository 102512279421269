const { makeStyles } = require('@material-ui/core');

export const useStyledHeaderStyles = makeStyles((theme) => ({
  bgColor: {},
  dialogStyledHeader: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 15px',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: 'calc(100% - 40px)',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  closeIconContainer: {
    cursor: 'pointer',
    padding: 8,
    lineHeight: 0,
  },
}));
