import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Color from 'color';
import { Typography, makeStyles, IconButton, List, ListItem, Popover } from '@material-ui/core';
import { MoreHoriz as MoreHorizIcon } from '@material-ui/icons';

import { InputDialog, DeleteDialog } from '../../dialogs';
import { stringToHSLColor } from '../../../Utils/common-utils';
import { notebookIcon2 } from '../../../assets/icons';

const useStyles = ({ name, disabled }) => {
  let primaryColor = new Color(stringToHSLColor(name));
  const hoverColor = primaryColor.desaturate(0.6).hex();
  primaryColor = primaryColor.desaturate(0.6).lighten(0.3).hex();
  return makeStyles({
    notebookRoot: {
      opacity: disabled ? '0.4' : undefined,
      pointerEvents: disabled ? 'none' : 'all',
      width: '100%',
      height: 240,
      borderRadius: 12,
      backgroundColor: '#FFF',
      border: '1px solid #E7E7E7',
      textDecoration: 'none',
      overflow: 'hidden',
      '& .i-notebook-icon': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 180,
        backgroundColor: primaryColor,
      },
      '&:hover': {
        '& .i-notebook-icon': {
          backgroundColor: hoverColor,
        },
      },
    },
    notebookDetails: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 12,
      '& .i-notebook-title': {
        fontSize: 14,
        color: '#00000099',
        textWrap: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& .i-timestamp': {
        fontSize: 10,
        color: '#00000068',
        textWrap: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    actionsDialogPaper: {
      borderRadius: 8,
      '& .MuiListItem-root': {
        fontWeight: 500,
      },
    },
  });
};

const Notebook = ({ id, name, isDefaultNotebook, threads, onRename, onDelete, disabled }) => {
  const classes = useStyles({ name, disabled })();
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [showRenameDialog, setShowRenameDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleActionsClick = (event) => {
    event.preventDefault();
    setActionsAnchorEl(event.currentTarget);
  };
  const handleActionsClose = (e) => {
    e && e.preventDefault();
    setActionsAnchorEl(null);
  };

  const toggleRenameDialog = (e) => {
    handleActionsClose(e);
    setShowRenameDialog(!showRenameDialog);
  };

  const toggleDeleteDialog = (e) => {
    handleActionsClose(e);
    setShowDeleteDialog(!showDeleteDialog);
  };

  const handleRename = (newName) => onRename({ id, newName });

  const handleDelete = () => onDelete(id);

  const sortedThreads = useMemo(
    () =>
      threads.sort(({ updatedAt: updatedAtA }, { updatedAt: updatedAtB }) =>
        moment(updatedAtA) > moment(updatedAtB) ? -1 : 1,
      ),
    [threads],
  );

  return (
    <>
      <Link
        to={
          sortedThreads?.[0]?.id
            ? `/controller/learn-ai/chats/${id}/${sortedThreads[0].id}?showThreads=true`
            : `/controller/learn-ai/chats/new-thread/${id}`
        }
        className={classes.notebookRoot}
      >
        <div className="i-notebook-icon">
          <img src={notebookIcon2} alt="notebook" width={90} height={93} />
        </div>
        <div className={classes.notebookDetails}>
          <div style={{ overflow: 'hidden' }}>
            <Typography className="i-notebook-title">{name}</Typography>
            <Typography className="i-timestamp">{name}</Typography>
          </div>
          <IconButton
            onClick={handleActionsClick}
            size="small"
            disableRipple
            disabled={isDefaultNotebook}
          >
            <MoreHorizIcon htmlColor={isDefaultNotebook ? undefined : '#000'} />
          </IconButton>
        </div>
      </Link>
      <Popover
        id="simple-menu"
        anchorEl={actionsAnchorEl}
        open={!!actionsAnchorEl}
        onClose={handleActionsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.actionsDialogPaper }}
      >
        <List>
          <ListItem button onClick={toggleRenameDialog}>
            Rename
          </ListItem>
          <ListItem button onClick={toggleDeleteDialog}>
            Delete
          </ListItem>
        </List>
      </Popover>
      {showRenameDialog && (
        <InputDialog
          onClose={toggleRenameDialog}
          onSubmit={handleRename}
          initialValue={name}
          dialogTitle="Rename Notebook"
          inputLabel="Notebook Name"
          submitText="RENAME"
        />
      )}
      {showDeleteDialog && (
        <DeleteDialog
          onClose={toggleDeleteDialog}
          onDelete={handleDelete}
          name={name}
          dialogTitle="Delete Notebook"
        />
      )}
    </>
  );
};

export default Notebook;
