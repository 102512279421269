import React from 'react'
import './responsive.css'

const ZoomDocumentation = () => {
    return (
        <div>
            <div className='containerMargin'>

                <h1 style={{ textAlign: "center" }}>How to use: Zoom App on TeamHub</h1>

                <h3>If user wants to start a Zoom meeting as a host, then user needs to authorize Zoom OAuth
                </h3>

                <div> <b>Steps:</b></div>

                <ol>
                    <li> Go to top bar</li>
                    <li> Click on meetings</li>
                    <li> Click on schedule a meeting. </li>
                    <li>A pop up will appear, Click on Setup Zoom Account </li>
                    <li>The user will be redirected to the Zoom OAuth page </li>
                    <li>Once the user is authorized, follow the usage section </li>
                </ol>

                <div> <b>Usage: </b></div>
                <h3>Follow the following steps:</h3>

                <h5> 1) New Zoom Meeting:</h5>
                <ol>
                    <li>New Zoom Meeting:</li>
                    <li>Click on “Meetings” </li>
                    <li> A right sidebar will open up</li>
                    <li> Click on schedule a meeting. </li>
                    <li> A pop will appear, click on “Create a meeting”</li>
                    <li> Enter the details: meeting name, zoom meeting link, time, occurrence</li>
                    <li> Click on “Save”</li>
                    <li>Now a meeting will be listed </li>
                    <li> Click on “Go To meeting” button</li>
                    <li> A Zoom meeting will be started</li>
                </ol>

                <h5> {"2) Existing Zoom Meeting:"}</h5>

                <ol>
                    <li> Go to top bar</li>
                    <li>Click on “Meetings” </li>
                    <li> A right sidebar will open up</li>
                    <li>Select a meeting with a Zoom meeting URL</li>
                    <li>Click on “Go To Meeting”.</li>
                </ol>

                <h4>Disconnect the App:</h4>
                <p>If user has authorized Zoom OAuth and wants to disconnect from TeamHub</p>

                <div> <b>Steps:</b></div>

                <ol>
                    <li>Go to top bar </li>
                    <li> Click on meetings</li>
                    <li> A right sidebar will open up</li>
                    <li> Click on schedule a meeting.</li>
                    <li> A pop up will appear, click on Setup Zoom Account</li>
                    <li> Click on revoke</li>
                    <li> Now, all the data stored for the user will be removed</li>
                </ol>
            </div>
        </div>
    )
}

export default ZoomDocumentation