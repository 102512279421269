import React from "react";
import WorkspaceDefaultLogo from "../../assets/svg/institute.svg";
import { useHistory } from "react-router-dom";
import {
  getWorkspaceId,
  setBoardInfo,
  setSpaceId,
  setSpaceName,
  setWorkspaceId,
  setWorkspaceImg,
  setWorkspaceName,
} from "../../Utils/authentication-access";
import { spacesService } from '../../services/spaces.service';
import PubSub from 'pubsub-js';
import { EVENTS } from '../../constants/events';
import boardsService from "../../services/boards.service";
import Avatar from "react-avatar";
import { makeStyles, MenuItem } from "@material-ui/core";


const useStyles = makeStyles(() => ({
  activeWorkspace: {
    padding: '2px',
    background: "rgba(51, 90, 251, 0.2)"
  },
  rounded: {
    borderRadius: "6px"
  },
  textColor: {
    color: "#000"
  }
}))

const WorkspaceIcon = ({ singleWorkspace }) => {
  const classes = useStyles();
  const history = useHistory();
  const enableWorkspace = async () => {
    const { id, name, imageUrl } = singleWorkspace;
    setWorkspaceId(id);
    setWorkspaceName(name);
    setWorkspaceImg(imageUrl);
    let info = null;
    let classID = null;

    let getworkspaceID = getWorkspaceId();

    const getSpacesresponse = await spacesService.getSpaces(getworkspaceID);

    info = getSpacesresponse.data.data.spaces;

    let infoData;
    if (info[0] && info[0].childrens[0]) {
      infoData = info[0].childrens[0].meta;
      setSpaceId(info[0].id);
      setSpaceName(info[0].name);
    }

    classID = infoData.classId;

    if (getSpacesresponse.status == 201 || 200) {
      const response = await boardsService.getBoards(classID);

      const classInfo = response.data.data;

      const { email, hardwareId, name, flowId, classId } = classInfo;
      let canvasInfo = {
        email,
        hardwareId,
        name,
        flowId,
        classId,
      };

      setBoardInfo(canvasInfo);
    }

    window.location.reload();
    // TODO it's required to refresh page without reload
    // PubSub.publish(EVENTS.WORK_SPACE_CHANGED, {});

    history.push("/Wbflow");
  };

  return (
    <>

      <MenuItem
        onClick={enableWorkspace}
        style={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          height: '52px',
          cursor: 'pointer',
          padding: "6px 16px"
        }}
        className={singleWorkspace.id === getWorkspaceId() && classes.activeWorkspace}
      >
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <Avatar
            className={classes.rounded}
            variant="rounded"
            name={'user Dp'}
            size="32"
            src={singleWorkspace.imageUrl || WorkspaceDefaultLogo}

          />
          <div className={classes.textColor}> {singleWorkspace.name}</div>
        </div>
      </MenuItem>
    </>
  );
};

export default WorkspaceIcon;
