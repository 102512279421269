import ACTION_TYPES from '../actions/type.action';

const initialState = [];

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPES.GETALL_USER: {
      const updatedState = state.map((oldUser) => {
        const updatedDetails = payload.find(({ userId }) => userId === oldUser.userId);
        return updatedDetails || oldUser;
      });
      const newUsers = payload.filter(
        ({ userId }) => !state.some((user) => user.userId === userId),
      );
      return [...updatedState, ...newUsers];
    }
    case ACTION_TYPES.ADD_USER: {
      const updatedState = [...state];
      const userIndex = state.findIndex(({ userId }) => userId === payload.userId);
      if (userIndex > -1) {
        if (payload.type !== 'login') updatedState[userIndex] = payload;
      } else {
        updatedState.push(payload);
      }
      return updatedState;
    }
    case ACTION_TYPES.REMOVE_USER:
      return state.filter((user) => user.userId !== payload.userId);
    default:
      return state;
  }
};

export default reducer;
