import { Component } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import React from 'react';
import BG_DARK from '../../assets/img/bg-dark.svg';
import BG_GREEN from '../../assets/img/bg-green.svg';
import BG_MATH from '../../assets/img/bg-math.svg';
import BG_LIGHT from '../../assets/img/bg-light.svg';
import BG_ENGLISH from '../../assets/img/bg-english.svg';
import BG_LINES from '../../assets/img/bg-line.svg';
import BG_DARK_ICON from '../../assets/img/bg-dark-icon.svg';
import BG_CUSTOM_ICON from '../../assets/img/bg-custom.svg';
import BG_GREEN_ICON from '../../assets/img/bg-green-icon.svg';
import BG_MATH_ICON from '../../assets/img/bg-math-icon.svg';
import BG_ENGLISH_ICON from '../../assets/img/bg-english-icon.svg';
import BG_LIGHT_ICON from '../../assets/img/bg-light-icon.svg';
import BG_LINES_ICONS from '../../assets/img/bg-line-icon.svg';
import BackgroundIcon from './BackgroundIcon';
import Axios from 'axios';
import { getBoardInfo, getusername } from '../../utils/authentication-access';
import config from '../../../IWB/config';
export const BG_CHANGE_MESSAGE = 'bg-changed';
export const BG_TYPES = {
  BG_DARK: 'BG_DARK',
  BG_ENGLISH: 'BG_ENGLISH',
  BG_LINES: 'BG_LINES',
  BG_MATH: 'BG_MATH',
  BG_LIGHT: 'BG_LIGHT',
  BG_GREEN: 'BG_GREEN',
  BG_CUSTOM: 'BG_CUSTOM',
};
export const SOLID_BACKGROUNDS = [BG_TYPES.BG_DARK, BG_TYPES.BG_GREEN, BG_TYPES.BG_LIGHT];
export const BG_ICON_TYPES = {
  BG_DARK: BG_DARK_ICON,
  BG_ENGLISH: BG_ENGLISH_ICON,
  BG_GREEN: BG_GREEN_ICON,
  BG_MATH: BG_MATH_ICON,
  BG_LIGHT: BG_LIGHT_ICON,
  BG_CUSTOM: BG_CUSTOM_ICON,
  BG_LINES: BG_LINES_ICONS,
};
export const BG_DEFAULT_THEME = BG_TYPES.BG_MATH;
export const BG_DEFAULT_THEME_SRC = BG_LIGHT;
export const BG_DEFAULT_THEME_ICON = BG_ICON_TYPES.BG_MATH;
class Background extends Component {
  constructor() {
    super();
    this.state = {
      backgroundCollection: [],
      pageId: null,
    };
  }
  setBackgroundCollection = (_backgroundCollection) =>
    this.setState({ backgroundCollection: _backgroundCollection });

  setTheme = (themeObj) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.updateBackgroundCollection(themeObj);
        return resolve(this.sendBGImage(themeObj));
      } catch (e) {
        return reject(e);
      }
    });
  };
  addPage = (pageId) => {
    let lastPageDetails =
      this.state.backgroundCollection[this.state.backgroundCollection.length - 1];
    let newPageDetails = cloneDeep(lastPageDetails);
    newPageDetails.pageId = pageId;
    this.state.backgroundCollection.push(newPageDetails);
    return newPageDetails;
  };
  deletePage = (pageId) => {
    let PagesLeft = this.state.backgroundCollection.filter(
      (background) => background.pageId !== pageId,
    );
    this.setState({
      backgroundCollection: PagesLeft,
    });
    return PagesLeft;
  };
  copyPage = (pageId) => {
    let lastPageDetails =
      this.state.backgroundCollection[this.state.backgroundCollection.length - 1];
    let newPageDetails = cloneDeep(lastPageDetails);
    newPageDetails.pageId = pageId;
    this.state.backgroundCollection.push(newPageDetails);
    return newPageDetails;
  };
  updateBackgroundCollection(obj) {
    let bgCollection = this.state.backgroundCollection;
    let filteredData = bgCollection.filter((bg) => bg.pageId !== obj.pageId) || [];
    filteredData.push(obj);
    this.setState({
      backgroundCollection: filteredData,
    });
  }
  getThemeName(pageId) {
    let fetchBackgroundDetails = this.getThemeObj(pageId);
    let backgroundBG_TYPES =
      fetchBackgroundDetails && fetchBackgroundDetails.type
        ? fetchBackgroundDetails.type
        : BG_DEFAULT_THEME;
    return backgroundBG_TYPES;
  }
  getBackground = (pageId) => {
    this.setState({ pageId });
    let themeObj = this.getThemeObj(pageId);
    return this.sendBGImage(themeObj);
  };
  getThemeObj = (pageId) => {
    return this.state.backgroundCollection.find((bg) => bg.pageId === pageId);
  };

  uploadBackgroundImage = (selpg, file) => {
    return new Promise(async (resolve, reject) => {
      try {
        let classKneuraId = getBoardInfo().classId;
        let creatorId = getusername();
        let flowId = getBoardInfo().flowId;
        let pageId = selpg;
        const formConfig = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        let formData = new FormData();
        formData.append('classId', classKneuraId);
        formData.append('creatorId', creatorId);
        formData.append('flowId', flowId);
        formData.append('pageId', pageId);
        formData.append('file', file);
        let response = await Axios.post(config.uploadBackgroundImage, formData, {
          ...formConfig,
        });
        return resolve(response.data.data);
      } catch (e) {
        console.error(e);
        return reject(e);
      }
    });
  };
  /**
   * @description sends background image svg according to the type passed
   * @param {object} themeObj
   * @returns svg image
   */
  sendBGImage = (themeObj) => {
    if (isEmpty(themeObj)) return BG_DEFAULT_THEME_SRC;

    let backgroundBG_TYPES = themeObj.type;
    switch (backgroundBG_TYPES) {
      case BG_TYPES.BG_ENGLISH:
        return BG_ENGLISH;

      case BG_TYPES.BG_GREEN:
        return BG_GREEN;

      case BG_TYPES.BG_LIGHT:
        return BG_LIGHT;

      case BG_TYPES.BG_MATH:
        return BG_LIGHT;

      case BG_TYPES.BG_LINES:
        return BG_LINES;
      case BG_TYPES.BG_CUSTOM:
        return themeObj.url || BG_DEFAULT_THEME_SRC;

      case BG_TYPES.BG_DARK:
        return BG_DARK;

      default:
        return BG_DEFAULT_THEME_SRC;
    }
  };
  render() {
    return (
      <BackgroundIcon
        backgroundData={this.props.backgroundData}
        theme={this.props.theme}
        onIconClick={this.props.onIconClick}
        onCustomImageSelected={this.props.customImageData}
      />
    );
  }
}
export default Background;
