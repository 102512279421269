import React from 'react';

const DropLinkIcon = (props) => {
  let fill = props.fill ? props.fill : '#000';
  let fillOpacity = props.fill ? 1 : 0.38;
  let width = props.width ? props.width : 66;
  let height = props.height ? props.height : 67;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 66 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.7016 37.6232L50.5624 31.7615C54.8379 27.4869 54.8379 20.5303 50.5624 16.2558C46.2874 11.98 39.3312 11.98 35.0559 16.2558L26.5978 24.7139C22.3223 28.9886 22.3223 35.9451 26.5978 40.2196C27.2291 40.8512 27.9201 41.3867 28.6511 41.8319L33.1789 37.3034C32.3163 37.0972 31.4982 36.6621 30.827 35.9907C28.8837 34.0475 28.8837 30.8851 30.827 28.9425L39.285 20.4844C41.2283 18.5412 44.3906 18.5412 46.3333 20.4844C48.2765 22.4276 48.2765 25.5896 46.3333 27.5326L43.8484 30.0179C44.8726 32.4279 45.1558 35.0783 44.7016 37.6232Z"
        fill={fill}
      />
      <path
        d="M21.2145 30.0967L15.3537 35.9582C11.0779 40.2331 11.0779 47.1894 15.3537 51.4647C19.6283 55.7402 26.5849 55.7402 30.8602 51.4647L39.3175 43.0066C43.593 38.7316 43.5924 31.7754 39.3175 27.5009C38.6868 26.8693 37.9958 26.3338 37.2646 25.8887L32.7367 30.4172C33.5987 30.6239 34.4168 31.0581 35.0887 31.7298C37.0319 33.673 37.0319 36.835 35.0887 38.778L26.6298 47.2361C24.6865 49.1794 21.5245 49.1794 19.5815 47.2361C17.6383 45.2929 17.6383 42.1309 19.5815 40.1879L22.0668 37.703C21.0422 35.2929 20.759 32.6419 21.2138 30.097L21.2145 30.0967Z"
        fill={fill}
      />
    </svg>
  );
};

export default DropLinkIcon;
