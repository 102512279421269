import BaseService from './base.service';
import { BIRDAI_SERVER_URL } from '../config';
import { get, del, post, put } from '../Utils/api';
import { getCanvasCode } from '../Utils/authentication-access';
import { constructQueryParams } from '../Utils/url';

class SchedulesService extends BaseService {
  constructor() {
    super(BIRDAI_SERVER_URL);
  }

  create = async (payload) => {
    try {
      const url = `${this.baseUrl}/schedules`;
      return await post(url, payload);
    } catch (error) {
      console.error('schedules:create error', error);
      throw error;
    }
  };

  getOutlookImportedEvents = async (paramsObj) => {
    try {
      const queryParams = constructQueryParams(paramsObj);
      const url = `${this.baseUrl}/schedules/outlook/imported-events?${queryParams}`;
      return await get(url);
    } catch (error) {
      console.error('schedules:getOutlookImportedEvents error', error);
      throw error;
    }
  };

  getOutlookImportedEventsIds = async (paramsObj) => {
    try {
      const events = await this.getOutlookImportedEvents(paramsObj);
      const outlookImportedEventsIds = events.data.data.schedules.map((schedule) => ({
        metaId: schedule.meta.id,
        iCalUId: schedule.meta.iCalUId,
      }));
      return outlookImportedEventsIds;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  edit = async (payload) => {
    try {
      const url = `${this.baseUrl}/schedules`;
      return await put(url, payload);
    } catch (error) {
      console.error('schedules:edit error', error);
      throw error;
    }
  };

  getAll = async (paramsObj) => {
    try {
      const searchParams = new URLSearchParams(paramsObj);
      const url = `${this.baseUrl}/schedules?${searchParams.toString()}`;
      return await get(url);
    } catch (error) {
      console.error('schedules:getAll error', error);
      throw error;
    }
  };

  delete = async (scheduleId) => {
    try {
      const url = `${this.baseUrl}/schedules?scheduleId=${scheduleId}`;
      return await del(url);
    } catch (error) {
      console.error('schedules:delete error', error);
      throw error;
    }
  };

  async joinOnPairedDevice(dataInfo) {
    try {
      const { meetingLink } = dataInfo;
      const path = '/schedules/notify-join-on-paired-device';
      const classId = getCanvasCode();
      const data = {
        classId,
        meetingLink,
      };
      return this.makePostRequest(path, data);
    } catch (error) {
      console.log(`error: ${error.message}`);
    }
  }

  /**
   * TODO: implement this
   * @param {*} iCalUId
   */
  async getByICalUId(iCalUId) {}
}

export const schedulesService = new SchedulesService();
