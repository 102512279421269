import React from 'react';
import { Button, Typography } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiLinkVariant } from '@mdi/js';
import { useCopyLinkStyles } from './component.style';

export const CopyLink = (props) => {
  const classes = useCopyLinkStyles();
  let { link, buttonLabel, onClick, containerStyle } = props;

  const linkContent = (
    <>
      <div style={{ position: 'absolute', left: '0.5rem' }}>
        <Icon path={mdiLinkVariant} size={0.75} />
      </div>
      <Typography variant="h4" className={classes.labelContainer}>
        {link}
      </Typography>
    </>
  );

  const buttonContent = (
    <Button
      classes={{ root: classes.copyLink }}
      className={classes.linkButton}
      color="primary"
      onClick={onClick}
    >
      {buttonLabel}
    </Button>
  );

  return (
    <div className={classes.container} style={containerStyle}>
      {linkContent}
      {buttonContent}
    </div>
  );
};
