import { replace } from 'lodash';
import React, { useState } from 'react';
import zoomService from '../../../../services/zoom.service';
import { useZoomDispatcher } from '../../../../store/dispatcher/useZoomDispatcher';
import { useLastPageVisited } from '../../../../Utils/history/useLastPageVisited';
import { useZoomAuth } from '../../../../Utils/zoom/useZoomAuth';
import { SetupZoomDialog } from './component';

export const SetupZoomDialogContainer = (props) => {
  const { isOpenDialog, closeDialog } = props;
  const { me, isZoomAuthenticated, initiateZoomOAuthAuthorization, revokeAccess } = useZoomAuth();
  const { dispatchSetZoomMe } = useZoomDispatcher();

  const title = 'Setup Zoom';
  const accountEmail = me?.email;

  const authorizeZoom = () => {
    initiateZoomOAuthAuthorization();
  };

  const revokeAccessHandler = () => {
    revokeAccess();
    dispatchSetZoomMe(null);
    closeDialog();
  };

  const replaceAccount = () => {
    const option = {
      replaceAccount: true,
    };
    initiateZoomOAuthAuthorization(option);
  };

  return (
    <SetupZoomDialog
      accountEmail={accountEmail}
      authorizeZoom={authorizeZoom}
      closeDialog={closeDialog}
      isOpenDialog={isOpenDialog}
      isZoomAuthenticated={isZoomAuthenticated}
      title={title}
      revokeAccess={revokeAccessHandler}
      replaceAccount={replaceAccount}
    />
  );
};
