import React, { useRef, useState } from 'react';
import { RemoteControlDialog } from './component';
import { sendMessageToDevice as anonyUserSendMessageToDevice } from '../../../Utils/sockets/anonymous-user-launcher';
import { sendMessageToDevice as authUserSendMessageToDevice } from '../../../Utils/sockets/auth-user-launcher';
import { useDialogSelector } from '../../../store/selector/useDialogSelector';
import { useDialogDispatcher } from '../../../store/dispatcher/useDialogDispatcher';
import { isMobile } from 'react-device-detect';
import { isTablet } from '../../IWB/utils/device';
import { useEventQueue } from './use-event-queue';

export const RemoteControlDialogContainer = (props) => {
  // const { isOpen, onClose, sendMessageToDevice } = props;
  const { dispatchSetIsOpenRemoteControlDialog } = useDialogDispatcher();

  const { remoteControlDialog } = useDialogSelector();
  const eventCaptureSectionRef = useRef(null);
  const twoFingerStartTouches = useRef(null);
  const customLeftButtonData = useRef(null); /** @deprecated this might not be required in future */
  const [keyboardValue, setKeyboardValue] = useState('');

  const isOneFingerMoveDisabled = useRef(false);
  const isOneFingerMoveDisabledTimeout = useRef(null);

  const sendMessageToDevice = (type, payload) => {
    if (remoteControlDialog.isAnonymousUser) {
      anonyUserSendMessageToDevice(type, payload);
    } else {
      authUserSendMessageToDevice(type, payload);
    }
  };

  const onDequeueEvent = (event) => {
    sendMessageToDevice('REMOTE_CONTROL', event);
  };

  const { enqueueEvent } = useEventQueue({ onDequeueEvent });

  /**
   * This is used to calculate the deltaXY between the last touch event and the current touch event.
   */
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const isTouching = useRef(false);
  /**
   * Note: this might be used on the touch devices only.
   * This is used to store the new clientXY position of our custom trackpad.
   * This is the position of the mouse cursor on the screen to we're performing a remote control.
   */
  const [clientXY, setClientXY] = useState({ x: 0, y: 0 });

  const dialogTitle = 'Remote Control';
  const label =
    'Your mouse/trackpad and keyboard are now controlling the connected Room device. Stay on this tab to continue using them for Room device control. Switching to another tab will temporarily deactivate Room control mode until you switch back to this tab.';

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleCloseRemoteControlDialog = () => {
    sendRemoteStopMessageToDevice();
    dispatchSetIsOpenRemoteControlDialog({
      isOpen: false,
      isAnonymousUser: false,
    });
  };

  const sendRemoteStartMessageToDevice = () => {
    sendMessageToDevice('REMOTE_CONTROL', {
      type: 'REMOTE_START',
    });
  };

  const sendRemoteStopMessageToDevice = () => {
    sendMessageToDevice('REMOTE_CONTROL', {
      type: 'REMOTE_STOP',
    });
  };

  const getMouseDownPayload = () => {
    return {
      type: 'MOUSE_DOWN',
    };
  };

  const sendMouseDownMessageToDevice = (event) => {
    const mouseDownPayload = getMouseDownPayload();
    sendMessageToDevice('REMOTE_CONTROL', mouseDownPayload);
  };

  /**
   * left mouse: 0
   * right mouse: 2
   * @param {*} event
   */
  const handleMouseDown = (event) => {
    if (event.button === 0) {
      if (isMobile || isTablet) {
        const mouseDownPayload = getMouseDownPayload();
        enqueueEvent(mouseDownPayload);
      } else {
        sendMouseDownMessageToDevice();
      }
    }
  };

  const sendMouseMoveMessageToDevice = ({ mousePosition }) => {
    setMousePosition({
      x: mousePosition.x,
      y: mousePosition.y,
    });
    sendMessageToDevice('REMOTE_CONTROL', {
      type: 'MOUSE_MOVE',
      x: mousePosition.x,
      y: mousePosition.y,
    });
  };

  const sendTouchMoveMessageToDevice = ({ deltaX, deltaY }) => {
    sendMessageToDevice('REMOTE_CONTROL', {
      type: 'MOUSE_MOVE_DELTA',
      deltaX,
      deltaY,
    });
  };

  /**
   * Execute this function only on desktop.
   */
  const handleMouseMove = (event) => {
    if (isMobile || isTablet) {
      return;
    }
    eventCaptureSectionRef.current.focus();
    const boundingRect = eventCaptureSectionRef.current.getBoundingClientRect();
    const x = (event.clientX - boundingRect.left) / boundingRect.width;
    const y = (event.clientY - boundingRect.top) / boundingRect.height;
    sendMouseMoveMessageToDevice({ mousePosition: { x, y } });
  };

  const handleOneFingerTouchStart = (event) => {
    if (!isTouching.current) {
      isTouching.current = true;
      const touch = event.touches[0];
      setStartPosition({ x: touch.clientX, y: touch.clientY });
    }
  };

  const handleTwoFingerTouchStart = (event) => {
    twoFingerStartTouches.current = event.touches;
  };

  /**
   * Note: this is triggered, when the user touch the event capture section.
   *
   * touchesWithingBounds: we only care about the touches that are within the event capture section.
   * hence we need to filter out the touches that are outside of the event capture section.
   *
   * @param {*} param0
   */
  const handleTouchStart = ({ touches }) => {
    const touchesWithinBounds = getTouchesWithinBounds(touches);

    if (touchesWithinBounds.length === 2) {
      handleTwoFingerTouchStart({ touches: touchesWithinBounds });
    } else if (touchesWithinBounds.length === 1) {
      handleOneFingerTouchStart({ touches: touchesWithinBounds });
    }
  };

  const onLeftButtonTouchStart = (event) => {
    customLeftButtonData.current = {
      isPressed: true,
    };

    event.preventDefault();
    sendMouseDownMessageToDevice();
  };

  const onLeftButtonTouchEnd = (event) => {
    customLeftButtonData.current = {
      isPressed: false,
    };
    event.preventDefault();
    sendMouseUpMessageToDevice();
  };

  /**
   * Use case:
   * Browser: Safari (Mobile)
   * on long press on the custom left button: the text is selected.
   * Hence, we are preventing the default behavior.
   *
   * @param {*} event
   */
  const onLeftButtonHandleContextMenu = (event) => {
    event.preventDefault();
  };

  /**
   * Use case:
   * Browser: Safari (Mobile)
   * on long press on the custom right button: the text is selected.
   * Hence, we are preventing the default behavior.
   *
   * @param {*} event
   */
  const onRightButtonHandleContextMenu = (event) => {
    event.preventDefault();
  };

  const getBoundingRect = () => {
    if (!eventCaptureSectionRef.current) return null;
    return eventCaptureSectionRef.current.getBoundingClientRect();
  };

  /**
   * Returns the normalized bounding rectangle for touch devices.
   * The sensitivity can be configured.
   * @param {number} sensitivity - The sensitivity of the bounding rectangle. Defaults to 1.
   * @returns {DOMRect} - The normalized bounding rectangle.
   */
  const getBoundingRectForTouchDevices = (sensitivity = 1) => {
    // Get the bounding rectangle of the event capture section.
    const boundingRect = getBoundingRect();

    // Get the width and height of the bounding rectangle.
    const { width, height } = boundingRect;

    // Check if the device is in landscape mode.
    const isLandscape = width > height;

    // If the device is in landscape mode, return the original bounding rectangle.
    if (isLandscape) {
      return boundingRect;
    } else {
      // If the device is in portrait mode, calculate a new bounding rectangle with a 16:9 aspect ratio.
      const newWidth = height * (16 / 9);
      const newHeight = height;
      const modifiedBoundingRect = {
        ...boundingRect,
        width: newWidth / sensitivity,
        height: newHeight / sensitivity,
      };
      return modifiedBoundingRect;
    }
  };

  const getNormalizedXYForTouch = (x, y) => {
    const boundingRect = getBoundingRectForTouchDevices();
    let normalizedX = x ? x / boundingRect.width : 0;
    let normalizedY = y ? y / boundingRect.height : 0;

    if (normalizedX > 1) normalizedX = 1;
    if (normalizedY > 1) normalizedY = 1;

    return { normalizedX, normalizedY };
  };

  const getNormalizedXYForMouse = (x, y) => {
    const boundingRect = getBoundingRect();
    const normalizedX = x ? (x - boundingRect.left) / boundingRect.width : 0;
    const normalizedY = y ? (y - boundingRect.top) / boundingRect.height : 0;
    return { normalizedX, normalizedY };
  };

  /**
   * Note: the clientXY already stores the value withing the bounds.
   * e.g x: 0 to width, y: 0 to height, hence we don't need to subtract the left and top of the boundingRect to x and y respectively.
   *
   * @param {*} x
   * @param {*} y
   * @returns
   */
  const getNormalizedXY = (x, y) => {
    /** TODO: find out isTouchDevice based on device */
    const isTouchDevice = true;
    if (isTouchDevice) {
      return getNormalizedXYForTouch(x, y);
    } else {
      return getNormalizedXYForMouse(x, y);
    }
  };

  /**
   * newClientX: min: 0, max: boundingRect.width
   * newClientY: min: 0, max: boundingRect.height
   * @param {*} param0
   * @returns
   */
  const calcNewClientXY = ({ deltaX, deltaY, clientXY }) => {
    const boundingRect = getBoundingRectForTouchDevices();
    const newClientX = clientXY.x + deltaX;
    const newClientY = clientXY.y + deltaY;

    if (newClientX < 0) {
      return {
        x: 0,
        y: newClientY,
      };
    }

    if (newClientX > boundingRect.width) {
      return {
        x: boundingRect.width,
        y: newClientY,
      };
    }

    if (newClientY < 0) {
      return {
        x: newClientX,
        y: 0,
      };
    }

    if (newClientY > boundingRect.height) {
      return {
        x: newClientX,
        y: boundingRect.height,
      };
    }

    return {
      x: newClientX,
      y: newClientY,
    };
  };

  const handleOneFingerTouchMove = ({ touches }) => {
    if (!isTouching.current) return;

    const touch = touches[0];

    /** calculate the deltaXY values */
    const deltaX = touch.clientX - startPosition.x;
    const deltaY = touch.clientY - startPosition.y;

    /**
     * calculate the new clientXY on the screen
     *
     */
    const newClientXY = calcNewClientXY({
      deltaX,
      deltaY,
      clientXY,
    });
    setClientXY(newClientXY);

    const normalizedXY = getNormalizedXY(newClientXY.x, newClientXY.y);

    sendMouseMoveMessageToDevice({
      mousePosition: {
        x: normalizedXY.normalizedX,
        y: normalizedXY.normalizedY,
      },
    });

    /**
     * this will to be used to calculate the deltaXY values on the next touchmove event
     */
    setStartPosition({ x: touch.clientX, y: touch.clientY });
  };

  const sendScrollMessageToDevice = ({ deltaX, deltaY }) => {
    const payload = {
      type: 'TWO_FINGERS_MOVE',
      x: deltaX,
      y: deltaY,
    };
    sendMessageToDevice('REMOTE_CONTROL', payload);
  };

  const handleTwoFingerTouchMove = (event) => {
    isOneFingerMoveDisabled.current = true;

    const deltaX =
      event.touches[0].clientX -
      twoFingerStartTouches.current[0].clientX +
      (event.touches[1].clientX - twoFingerStartTouches.current[1].clientX);

    const deltaY =
      event.touches[0].clientY -
      twoFingerStartTouches.current[0].clientY +
      (event.touches[1].clientY - twoFingerStartTouches.current[1].clientY);

    sendScrollMessageToDevice({ deltaX, deltaY });

    /**
     * Note:
     * when user lift fingers from the touch screen after using two finger move
     * one of the finger might still be touching the screen, hence we need to wait for 500ms
     * before we allow one finger move again.
     */
    if (isOneFingerMoveDisabled.current) {
      clearTimeout(isOneFingerMoveDisabledTimeout.current);
    }
    isOneFingerMoveDisabledTimeout.current = setTimeout(() => {
      isOneFingerMoveDisabled.current = false;
    }, 500);
  };

  /**
   * Note:
   * this will filter and return the touches that are within the bounded area (event capture area)
   * @param {*} touches
   * @returns
   */
  const getTouchesWithinBounds = (touches) => {
    const boundingRect = getBoundingRect();
    const touchesArray = Array.from(touches); // convert touches to an array
    const touchesWithinBounds = touchesArray.filter((touch) => {
      const { width, height } = boundingRect;
      const touchX = touch.clientX;
      const touchY = touch.clientY;
      return touchX >= 0 && touchX <= width && touchY >= 0 && touchY <= height;
    });

    return touchesWithinBounds;
  };

  const handleTouchMove = ({ touches }) => {
    /** get the touches within the bounded area */
    const touchesWithinBounds = getTouchesWithinBounds(touches);

    if (touchesWithinBounds.length === 2) {
      handleTwoFingerTouchMove({ touches: touchesWithinBounds });
    } else if (touchesWithinBounds.length === 1) {
      if (isOneFingerMoveDisabled.current) {
        console.log('one finger move is disabled at the moment');
        return;
      }

      handleOneFingerTouchMove({ touches: touchesWithinBounds });
    }
  };

  /**
   * Note:
   * This event will be triggered when the user lifts up the finger from the screen (specifically from the event capture section).
   *
   * @param {*} event
   */
  const handleTouchEnd = (event) => {
    const touchesWithinBounds = getTouchesWithinBounds(event.touches);
    if (touchesWithinBounds.length === 0) {
      isTouching.current = false;
    }
  };

  const sendWheelMessageToDevice = ({ deltaX, deltaY }) => {
    const payload = {
      type: 'WHEEL',
      x: deltaX,
      y: deltaY,
    };
    sendMessageToDevice('REMOTE_CONTROL', payload);
  };

  const handleWheel = (event) => {
    event.preventDefault();
    const { deltaX, deltaY } = event;
    sendWheelMessageToDevice({ deltaX, deltaY });
  };

  const getMouseUpPayload = () => {
    return {
      type: 'MOUSE_UP',
    };
  };

  const sendMouseUpMessageToDevice = () => {
    const mouseUpPayload = getMouseUpPayload();
    sendMessageToDevice('REMOTE_CONTROL', mouseUpPayload);
  };

  /**
   * left mouse: 0
   * right mouse: 2
   * @param {*} event
   */
  const handleMouseUp = (event) => {
    if (event.button === 0) {
      if (isMobile || isTablet) {
        const mouseUpPayload = getMouseUpPayload();
        enqueueEvent(mouseUpPayload);
      } else {
        sendMouseUpMessageToDevice();
      }
    }
  };

  const onClick = () => {
    // sendMessageToDevice('REMOTE_CONTROL', {
    //   type: 'MOUSE_CLICK',
    // });
  };

  /**
   * virtual keyboard
   * identify if key or delete/backspace key (removing) is pressed
   */
  const getKeyPressOnInput = (event) => {
    const value = event.target.value;

    if (value.length < keyboardValue.length) {
      return { key: 'Backspace' };
    }

    return { key: value.slice(-1) };
  };

  const constructKeyboardPayload = (event) => {
    const { ctrlKey, key, metaKey, altKey, shiftKey } = event;

    return {
      altKey,
      ctrlKey,
      key,
      metaKey,
      shiftKey,
      type: 'KEYBOARD_PRESS',
    };
  };

  const constructInputEventPayload = (event) => {
    const { key } = getKeyPressOnInput(event);
    return {
      altKey: false,
      ctrlKey: false,
      key,
      metaKey: false,
      shiftKey: false,
      type: 'KEYBOARD_PRESS',
    };
  };

  /**
   * Handles the physical keyboard
   * @param {*} event
   */
  const handleOnKeyboardEventKeyDown = (event) => {
    const payload = constructKeyboardPayload(event);
    sendMessageToDevice('REMOTE_CONTROL', payload);
  };

  /**
   * Handles the virtual keyboard
   * @param {*} event
   */
  const handleOnInputEventKeyDown = (event) => {
    const payload = constructInputEventPayload(event);
    sendMessageToDevice('REMOTE_CONTROL', payload);
  };

  /**
   *
   * @param {*} event
   */
  const onKeyDown = (event) => {
    event.preventDefault();
    const { nativeEvent } = event;

    if (nativeEvent instanceof KeyboardEvent) {
      handleOnKeyboardEventKeyDown(event);
    } else if (nativeEvent instanceof InputEvent) {
      setKeyboardValue(event.target.value);
      handleOnInputEventKeyDown(event);
    } else {
      console.log('unsupported event', nativeEvent);
      return;
    }
  };

  const onContextMenu = (event) => {
    event.preventDefault();
    sendMessageToDevice('REMOTE_CONTROL', {
      type: 'MOUSE_RIGHT_CLICK',
    });
  };

  const initializedClientXY = () => {
    if (!eventCaptureSectionRef.current) {
      console.log('eventCaptureSectionRef.current is null');
      return;
    }
    /** initialize the clientXY to the center of the screen */
    const boundingRect = eventCaptureSectionRef.current.getBoundingClientRect();

    /** get the distance of the rect from the left most of the window */
    const x = boundingRect.left + boundingRect.width / 2;
    /** get the distance of the rect from the top most of the window */
    const y = boundingRect.top + boundingRect.height / 2;

    setClientXY({
      x,
      y,
    });
  };

  const onLeftClick = () => {
    sendMessageToDevice('REMOTE_CONTROL', {
      type: 'MOUSE_LEFT_CLICK',
    });
  };

  const onRightClick = () => {
    sendMessageToDevice('REMOTE_CONTROL', {
      type: 'MOUSE_RIGHT_CLICK',
    });
  };

  if (!remoteControlDialog?.isOpen) return null;

  return (
    <RemoteControlDialog
      dialogTitle={dialogTitle}
      initializedClientXY={initializedClientXY}
      isOpen={remoteControlDialog?.isOpen}
      label={label}
      mousePosition={mousePosition}
      onClick={onClick}
      onClose={handleCloseRemoteControlDialog}
      sendRemoteStartMessageToDevice={sendRemoteStartMessageToDevice}
      onContextMenu={onContextMenu}
      onKeyDown={onKeyDown}
      onLeftButtonHandleContextMenu={onLeftButtonHandleContextMenu}
      onLeftButtonTouchEnd={onLeftButtonTouchEnd}
      onLeftButtonTouchStart={onLeftButtonTouchStart}
      onLeftClick={onLeftClick}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onRightButtonHandleContextMenu={onRightButtonHandleContextMenu}
      onRightClick={onRightClick}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
      onTouchStart={handleTouchStart}
      onWheel={handleWheel}
      ref={eventCaptureSectionRef}
      keyboardValue={keyboardValue}
    />
  );
};
