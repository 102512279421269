import { makeStyles, styled } from '@material-ui/core';

export const useImportOutlookEventStyles = makeStyles((theme) => ({
  eventsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    flex: 1,
  },
  button: {
    borderColor: 'rgba(0, 0, 0, 0.8)',
    color: 'rgba(0, 0, 0, 0.8)',
  },
  spinner: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const AuthenticatedFooterContent = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flex: 1,
  alignItems: 'center',
});

export const AuthenticatedFooterContentRight = styled('div')({
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
});
