import React from 'react';
import { Box, Popover, Typography } from '@material-ui/core';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { mdiGoogle, mdiMicrosoftOutlook, mdiCalendar } from '@mdi/js';
import Icon from '@mdi/react';
import { useScheduleMeetingOptionsStyles } from './component.style';
import { ZoomSmall } from '../../../../assets/icons';
import { FEATURE_FLAGS } from '../../../../constants/feature-flags.constants';

export const ScheduleMeetingOptionsDialog = (props) => {
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => <PopupContent popupState={popupState} {...props} />}
    </PopupState>
  );
};

const PopupContent = (props) => {
  const {
    initiateZoomOAuthAuthorization,
    isZoomAuthenticated,
    onGoogleCalendarOptionClick,
    onImportOutlookOptionCalendarClick,
    onManuallyCreateMeetingOptionClick,
    openSetupZoomAccountDialog,
    popupState,
    scheduleMeetingButtonLabel,
    setSetupZoomAccountDialogAsLastVisited,
  } = props;

  const classes = useScheduleMeetingOptionsStyles();

  const button = (
    <div className={classes.button} {...bindTrigger(popupState)}>
      <div>{scheduleMeetingButtonLabel}</div>
      <ArrowDropDownIcon />
    </div>
  );

  const handleOnManuallyCreateMeetingOptionClick = () => {
    onManuallyCreateMeetingOptionClick();
    popupState.close();
  };

  const handleOnImportOutlookOptionCalendarClick = () => {
    onImportOutlookOptionCalendarClick();
    popupState.close();
  };

  const handleOnGoogleCalendarOptionClick = () => {
    onGoogleCalendarOptionClick();
    popupState.close();
  };

  const handleOnSetupZoomAccountOptionClick = () => {
    if (isZoomAuthenticated) {
      openSetupZoomAccountDialog();
      popupState.close();
    } else {
      setSetupZoomAccountDialogAsLastVisited();
      initiateZoomOAuthAuthorization();
    }
  };

  const manuallyCreateMeetingItem = (
    <div onClick={handleOnManuallyCreateMeetingOptionClick} className={classes.item}>
      <div className={classes.itemIcon}>
        <Icon path={mdiCalendar} size="1rem" />
      </div>
      <Typography className={classes.textColor}>Manually create a meeting</Typography>
    </div>
  );

  const importOutlookCalendarItem = (
    <div onClick={handleOnImportOutlookOptionCalendarClick} className={classes.item}>
      <div className={classes.itemIcon}>
        <Icon path={mdiMicrosoftOutlook} size="1rem" />
      </div>
      <Typography className={classes.textColor}>Import Outlook Calendar</Typography>
    </div>
  );

  const importGoogleCalendarItem = (
    <div onClick={handleOnGoogleCalendarOptionClick} className={classes.item}>
      <div className={classes.itemIcon}>
        <Icon path={mdiGoogle} size="1rem" />
      </div>
      <Typography className={classes.textColor}>Import Google Calendar</Typography>
    </div>
  );

  const setupZoomAccountItem = (
    <div onClick={handleOnSetupZoomAccountOptionClick} className={classes.item}>
      <div className={classes.itemIcon}>
        <ZoomSmall />
      </div>
      <Typography className={classes.textColor}>Setup Zoom Account</Typography>
    </div>
  );

  let items = [
    manuallyCreateMeetingItem,
    // importGoogleCalendarItem,
  ];

  /**
   * TODO: remove this code, once the personal calendar is implemented and stable
   */
  if (false && FEATURE_FLAGS.outlookCalendar.isEnabled) {
    items.push(importOutlookCalendarItem);
  }

  if (FEATURE_FLAGS.zoomCall.isEnabled) {
    items.push(setupZoomAccountItem);
  }

  const content = (
    <Box p={2} className={classes.container}>
      {items}
    </Box>
  );

  return (
    <div>
      {button}
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {content}
      </Popover>
    </div>
  );
};
