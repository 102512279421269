import React from 'react';
import { EventDateTimeInfo } from './component';

export const EventDateTimeInfoContainer = (props) => {
  const { isRecurring, startTime, nextStartDateTime, endTime, endsAt, isAllDay, dateTimeFormat } =
    props;

  return (
    <EventDateTimeInfo
      endsAt={endsAt}
      endTime={endTime}
      isAllDay={isAllDay}
      isRecurring={isRecurring}
      nextStartDateTime={nextStartDateTime}
      startTime={startTime}
      dateTimeFormat={dateTimeFormat}
    />
  );
};
