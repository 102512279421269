import { styled } from '@material-ui/core';

export const EventCaptureSection = styled('div')({
  height: '100%',
  position: 'relative',
  width: '100%',
  '&:focus': {
    outline: 'none',
  },
});

export const ContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  userSelect: 'none',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
});

/**
 * Note:
 * padding 1rem
 * only on portrait mode, and when the screen is small (mobile)
 */
export const BackgroundContent = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  left: 0,
  padding: '10rem',
  [`${theme.breakpoints.down('sm')} and (orientation: portrait)`]: {
    padding: '1rem',
  },
  position: 'absolute',
  top: 0,
  width: '100%',
}));

export const InteractionArea = styled('div')({
  height: '100%',
  position: 'absolute',
  width: '100%',
  zIndex: 1,
});

export const ImageContentContainer = styled('div')(({ theme }) => ({
  padding: '0 10rem',
  [`${theme.breakpoints.down('sm')} and (orientation: portrait)`]: {
    padding: '0 1rem',
  },
}));
