import moment from 'moment';
import {
  checkIsYetToOccur,
  checkIsYetToStart,
  getTimeForComparision,
  getWithTodaysDate,
  updateDatePortionInISOString,
} from '../datetime';

/** note: Z is required to be appended to denote it's UTC string, microsoft is not providing Z in the string*/
export const getEventInfoStartDateTime = (eventInfo) => {
  return `${eventInfo.start.dateTime}Z`;
};

/** note: Z is required to be appended to denote it's UTC string, microsoft is not providing Z in the string*/
export const getEventInfoEndDateTime = (eventInfo) => {
  return `${eventInfo.end.dateTime}Z`;
};

export const getMeetingURL = (eventInfo) => {
  if (eventInfo.onlineMeeting) {
    return eventInfo?.onlineMeeting?.joinUrl;
  }

  return eventInfo.link;
};

export const checkIsMeetingDisabled = (eventInfo) => {
  const link = getMeetingURL(eventInfo);
  return !link;
};

export const checkIsRecurring = (eventInfo) => {
  /**
   * outlook event object
   */
  if (eventInfo.hasOwnProperty('recurrence')) {
    return eventInfo.recurrence !== null;
  }

  /**
   * our schedule object
   */
  return eventInfo?.occurence !== 'NONE';
};

export const getStartTime = (eventInfo) => {
  if (eventInfo.startTime) {
    return eventInfo.startTime;
  }

  const eventInfoStartDateTime = getEventInfoStartDateTime(eventInfo);
  if (eventInfo.isAllDay) {
    return moment(eventInfoStartDateTime).startOf('day').toISOString();
  }

  return eventInfoStartDateTime;
};

export const getEndTime = (eventInfo) => {
  if (eventInfo.endTime) {
    return eventInfo.endTime;
  }

  const eventInfoEndDateTime = getEventInfoEndDateTime(eventInfo);
  const eventInfoStartDateTime = getEventInfoStartDateTime(eventInfo);
  if (eventInfo.isAllDay) {
    return moment(eventInfoStartDateTime).endOf('day').toISOString();
  }
  return eventInfoEndDateTime;
};

export const getNextStartDateTimeForDaily = (event) => {
  const now = moment();
  const eventStartDateTimeString = getEventInfoStartDateTime(event);
  // const todayStartDateTime = now.format('YYYY-MM-DD') + eventStartDateTime.split('T')[1];
  const todayStartDateTimeString = getWithTodaysDate(eventStartDateTimeString);

  /**
   * event has not yet started OR
   * event startDateTime is in the future
   */
  if (checkIsYetToStart(now.toISOString(), eventStartDateTimeString)) {
    return eventStartDateTimeString;
  } else {
    /**
     * event has already started
     * Note: event is recurring, hence it will repeat
     */
    const isYetToOccur = checkIsYetToOccur(now.toISOString(), todayStartDateTimeString);
    // const todaysDate = getWithTodaysDate(startDateTime);
    if (isYetToOccur) {
      /**
       * event is yet of occur today
       * hence the next start date time is today
       */
      return todayStartDateTimeString;
    } else {
      /**
       * event has already occurred today,
       * hence the next start date time is tomorrow
       */
      const tomorrowsDateTimeString = moment(todayStartDateTimeString).add(1, 'days').toISOString();
      return tomorrowsDateTimeString;
    }
  }
};

const getTodaysIndex = () => {
  return moment().day();
};

export const getNextStartDateTimeForWeeklyIfTodayIndexIsIncluded = ({
  eventStartDateTimeString,
  todaysDate,
  now,
  dayIndexArr,
}) => {
  const todayIndex = getTodaysIndex();

  let add = 0;
  let nextDateTimeString = updateDatePortionInISOString(eventStartDateTimeString, todaysDate);
  if (checkIsYetToOccur(now.toISOString(), nextDateTimeString)) {
    return nextDateTimeString;
  }

  if (dayIndexArr.length === 1) {
    return moment(nextDateTimeString).add(1, 'weeks').toISOString();
  }

  const nextDayIndex = getNextDayIndexInDayIndexArr(dayIndexArr, todayIndex);
  add = getDayDiff(todayIndex, nextDayIndex);
  return moment(nextDateTimeString).add(add, 'days').toISOString();
};

const getNextStartDateTimeForWeeklyIfTodayIndexIsNotIncluded = ({ dayIndexArr, todayIndex }) => {
  const newDayIndexArr = dayIndexArr.concat(todayIndex).sort();
  const greaterDayIndexArr = newDayIndexArr.filter((item) => item > todayIndex);
  const lessDayIndexArr = newDayIndexArr.filter((item) => item < todayIndex);

  let nextDayIndex;
  if (greaterDayIndexArr.length) {
    nextDayIndex = greaterDayIndexArr[0];
  }

  if (lessDayIndexArr.length) {
    nextDayIndex = lessDayIndexArr[0];
  }

  const add = getDayDiff(todayIndex, nextDayIndex);
  return moment().add(add, 'days').toISOString();
};

export const getNextStartDateTimeForWeekly = (event) => {
  const now = moment();
  const eventStartDateTimeString = getEventInfoStartDateTime(event);

  /** check if event has started */
  if (checkIsYetToStart(now.toISOString, eventStartDateTimeString)) {
    return eventStartDateTimeString;
  }

  /**
   * get the index of day of the first ocurrence in the week
   * this implementation is not full proof, need to update it again,
   */
  const dayIndexArr = getDayIndexArrFromEvent(event);
  const todaysDate = moment().format('YYYY-MM-DD');
  const todayIndex = getTodaysIndex();
  if (dayIndexArr.includes(todayIndex)) {
    return getNextStartDateTimeForWeeklyIfTodayIndexIsIncluded({
      eventStartDateTimeString,
      todaysDate,
      now,
      dayIndexArr,
    });
  }

  return getNextStartDateTimeForWeeklyIfTodayIndexIsNotIncluded({ dayIndexArr, todayIndex });
};

/**
 * e.g
 * dayIndexArr = [1, 2, 3, 4, 5] ie. ['monday', 'tuesday', 'wednesday', 'thurday', 'friday', 'saturday'];
 * todayIndexInWeek = 3 i.e. 'wednesday'
 * return 4 i.e 'thursday'
 *
 * @param {number[]} dayIndexArr
 * @param {number} todayIndexInWeek
 * @returns
 */
const getNextDayIndexInDayIndexArr = (dayIndexArr, todayIndexInWeek) => {
  const indexInArr = dayIndexArr.findIndex((elem) => elem === todayIndexInWeek);
  return indexInArr <= dayIndexArr.length - 2 ? dayIndexArr[indexInArr + 1] : dayIndexArr[0];
};

const getDayDiff = (todayIndex, nextDayIndex) => {
  if (todayIndex <= nextDayIndex) {
    return nextDayIndex - todayIndex;
  } else {
    return 7 - (todayIndex - nextDayIndex);
  }
};

const daysIndex = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

const getDayIndexFromEvent = (event) => {
  const day = event.recurrence.pattern.daysOfWeek[0];
  return daysIndex[day];
};

const getDayIndexArrFromEvent = (event) => {
  const daysOfWeek = event.recurrence.pattern.daysOfWeek;
  return daysOfWeek.map((day) => daysIndex[day]);
};
