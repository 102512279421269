import ACTION_TYPE from './type.action';

export const setHuddleOnline = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_ONLINE,
  });
};

export const setHuddleOffline = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_OFFLINE,
  });
};

/**
 * payload: {
 *  url: string
 * }
 * @param {*} payload
 * @returns
 */
export const setHuddleUserOnline = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_USER_ONLINE,
    payload,
  });
};

export const setHuddleUserOffline = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_USER_OFFLINE,
  });
};

/**
 *
 * @param {{ url: string }} payload
 * @returns
 */
export const startHuddle = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.START_HUDDLE,
    payload,
  });
};

export const leaveHuddle = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.LEAVE_HUDDLE,
  });
};

export const huddleEnded = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.HUDDLE_ENDED,
  });
};

export const huddleStarted = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.HUDDLE_STARTED,
  });
};

/**
 * @param {{ isHuddleInvitationOpen: boolean }} param0
 * @returns
 */
export const setOpenHuddleInvitation = (isHuddleInvitationOpen) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_OPEN_HUDDLE_INVITATION,
    payload: {
      isHuddleInvitationOpen,
    },
  });
};

/**
 *
 * @param {boolean} isViewOpen
 * @returns
 */
export const setIsViewOpen = (isViewOpen) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_VIEW_OPEN,
    payload: {
      isViewOpen,
    },
  });
};

export const setHuddleCallDuration = (huddleDuration) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_HUDDLE_DURATION,
    payload: {
      huddleDuration,
    },
  });
};
