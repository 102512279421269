import React, { useEffect } from 'react';
import { PersonalCalendarContent } from './component';
import { usePersonalCalendar } from './listing/use-personal-calendar';
import { useMicrosoftSelector } from '../../../../../store/selector/useMicrosoftSelector';

export const PersonalCalendarContentContainer = (props) => {
  const { closeSidebar } = props;
  const { refetchCalendarInfo, fetchCalendarInfo, isLoading } = usePersonalCalendar();
  const { outlookEventsDayWise } = useMicrosoftSelector();

  useEffect(() => {
    fetchCalendarInfo();
  }, [fetchCalendarInfo]);

  useEffect(() => {
    /** scroll to personal-calendar-listing-today */
    setTimeout(() => {
      const todayElement = document.getElementById('personal-calendar-listing-today');
      if (todayElement) {
        todayElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1000);
  }, [outlookEventsDayWise]);

  return (
    <PersonalCalendarContent
      isLoading={isLoading}
      closeSidebar={closeSidebar}
      refetchOutlookEvents={refetchCalendarInfo}
      outlookEventsDayWise={outlookEventsDayWise}
    />
  );
};
