import React from 'react';

const BirdAssistanceIcon = (props) => {
  let fill = props.fill ? props.fill : '#000';
  let fillOpacity = props.fill ? 1 : 0.38;

  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.4893 5.54111C17.7392 4.4863 20.2606 4.4863 22.5106 5.54111L33.105 10.508C35.4095 11.5884 35.4095 15.3283 33.105 16.4087L22.5107 21.3756C20.2608 22.4304 17.7394 22.4304 15.4894 21.3756L4.89503 16.4087C2.59054 15.3283 2.59055 11.5883 4.89504 10.5079L15.4893 5.54111Z"
        stroke="white"
      />
      <path d="M3.16667 13.4584V22.1667" stroke="white" stroke-linecap="round" />
      <path
        d="M30.0833 18.2084V26.3236C30.0833 27.9197 29.2861 29.414 27.8899 30.1872C25.565 31.4747 21.8437 33.25 19 33.25C16.1563 33.25 12.435 31.4747 10.1101 30.1872C8.71388 29.414 7.91667 27.9197 7.91667 26.3236V18.2084"
        stroke="white"
        stroke-linecap="round"
      />
      <path
        d="M29.3251 0.486383C29.5799 -0.162127 30.4943 -0.162128 30.7491 0.486382L31.4306 2.22058C31.5084 2.41858 31.6645 2.57531 31.8618 2.65339L33.5896 3.33735C34.2357 3.59312 34.2357 4.51092 33.5896 4.76669L31.8618 5.45065C31.6645 5.52874 31.5084 5.68547 31.4306 5.88346L30.7491 7.61766C30.4943 8.26617 29.5799 8.26617 29.3251 7.61766L28.6436 5.88346C28.5658 5.68547 28.4097 5.52874 28.2124 5.45065L26.4846 4.76669C25.8385 4.51092 25.8385 3.59312 26.4846 3.33735L28.2124 2.65339C28.4097 2.57531 28.5658 2.41858 28.6436 2.22058L29.3251 0.486383Z"
        fill="white"
      />
      <path
        d="M17.7942 7.48638C18.0491 6.83787 18.9635 6.83787 19.2183 7.48638L19.4668 8.11868C19.5446 8.31668 19.7007 8.4734 19.898 8.55149L20.5279 8.80087C21.1741 9.05663 21.1741 9.97444 20.5279 10.2302L19.898 10.4796C19.7007 10.5577 19.5446 10.7144 19.4668 10.9124L19.2183 11.5447C18.9635 12.1932 18.0491 12.1932 17.7942 11.5447L17.5458 10.9124C17.468 10.7144 17.3118 10.5577 17.1146 10.4796L16.4846 10.2302C15.8385 9.97444 15.8385 9.05663 16.4846 8.80087L17.1146 8.55149C17.3118 8.4734 17.468 8.31668 17.5458 8.11868L17.7942 7.48638Z"
        fill="white"
      />
      <path
        d="M8.79423 1.48638C9.04906 0.837872 9.96348 0.837872 10.2183 1.48638L10.4668 2.11868C10.5446 2.31668 10.7007 2.4734 10.898 2.55149L11.5279 2.80087C12.1741 3.05663 12.1741 3.97444 11.5279 4.2302L10.898 4.47958C10.7007 4.55767 10.5446 4.71439 10.4668 4.91239L10.2183 5.54469C9.96348 6.1932 9.04906 6.1932 8.79423 5.54469L8.54577 4.91239C8.46797 4.71439 8.31182 4.55767 8.11456 4.47958L7.48459 4.2302C6.83847 3.97444 6.83847 3.05663 7.48459 2.80087L8.11456 2.55149C8.31182 2.4734 8.46797 2.31668 8.54577 2.11868L8.79423 1.48638Z"
        fill="white"
      />
    </svg>
  );
};

export default BirdAssistanceIcon;
