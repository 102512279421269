import { get } from "../Utils/api";
import { getUserId } from "../Utils/authentication-access";
import { DEVICE_PAIR } from "../Utils/url-builder";

class CalendarService {
  getCalendarInfo = async (timezone) => {
    const userId = getUserId();
    try {
      const url = DEVICE_PAIR.calendarInfo(timezone, userId);
      return await get(url);
    } catch (error) {
      console.log('error', error);
    }
  };
}

const calendarService = new CalendarService();
export default calendarService;