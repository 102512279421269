import config from '../Kneura-Web-Whiteboard/IWB/config';
import { post, get } from '../Utils/api';
import { getBoardInfo, getFlowId, getWorkspaceId } from '../Utils/authentication-access';
import { BOARD, SPACES, WHITEBOARD } from '../Utils/url-builder';

class BoardsService {
  createBoard = async (boardName, saveParentId) => {
    const workspaceId = getWorkspaceId();
    try {
      const url = SPACES.postBoard();
      const response = await post(url, {
        name: boardName,
        parentId: saveParentId,
        workspaceId: workspaceId,
      });
      return response;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  getBoards = async (classID) => {
    try {
      const url = WHITEBOARD.getWhiteBoard(classID);
      const response = await get(url);
      return response;
    } catch (error) {
      console.log('error', error);
    }
  };

  getWorkspaceId = async (classID) => {
    try {
      const response = await this.getBoards(classID);
      const workspaceId = response.data.data.workspaceId;
      return workspaceId;
    } catch (error) {
      console.log(error);
    }
  };

  renameBoard = async (flowID, classID, renameBoardInput) => {
    try {
      let url = SPACES.renameBoard();
      const response = await post(url, {
        flowId: flowID,
        classId: classID,
        name: renameBoardInput,
      });
      return response;
    } catch (error) {
      console.log('rename board error', error);
    }
  };

  deleteBoard = async (flowID, classID) => {
    try {
      let url = SPACES.deleteBoard();
      const response = await post(url, { flowId: flowID, classId: classID });
      return response;
    } catch (error) {
      console.log('delete board error', error);
    }
  };

  duplicateBoard = async (flowID, classID, spaceID) => {
    try {
      let url = SPACES.duplicateBoard();
      const response = await post(url, { flowId: flowID, classId: classID, spaceId: spaceID });
      return response;
    } catch (error) {
      console.log('duplicate board error', error);
    }
  };

  getFlowItem = async () => {
    try {
      const { flowId } = getBoardInfo();
      const url = config.flowItemURL + flowId;
      return await get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  getElementInfo = async (elementId) => {
    const response = await this.getFlowItem();
    const flowItem = response.data && response.data.data;
    if (flowItem && flowItem.items) {
      return flowItem.items.find((el) => el.elementId === elementId);
    }
  };

  getPresentationModeStatus = async () => {
    try {
      const flowId = getFlowId();
      const url = BOARD.getPresentationModeStatus(flowId);
      return await get(url);
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  };
}

const boardsService = new BoardsService();
export default boardsService;
