import React from 'react'
import './responsive.css'

const FairUsagePolicy = () => {

  return (
    <div>
      <div className='containerMargin'>
        <h3 style={{ textAlign: 'center' }}>Bird AI Cloud Fair Usage Policy (Storage & Network)</h3>
        <p>We have this Fair Usage Policy (FUP) public to be upfront about all terms and conditions relating to our product offering. The vast majority of Bird AI users need not worry about Bird AI Fair usage policy. This will only affect a very small percentage of users who are exceeding the regular use of the accounts. Fair Usage Policy is implemented by Bird AI keeping in view the fact that a small number of customers may otherwise indulge in activities capable of impairing the experience of a large majority. Hence, through this policy, the Bird AI seeks to address this imbalance and allow all customers to utilize the storage space and functionality uniformly.
        </p>
        <h5>Sharing of Account Credentials</h5>
        <p>
          An individual Bird AI account is meant to be used by only one user. Sharing Bird AI account credentials for both personal use or commercial purposes is against our Terms and Conditions & will result in account blockage without warning.
        </p>
        <h5>
          Limited Storage Space & Network Bandwidth usage
        </h5>
        <p>
          Under the Fair Usage Policy (FUP), Bird AI services offer different product plans to suit the varying storage space and network bandwidth requirement of the customers. The purpose is that a user with the need for low data storage in a month is not charged higher as different customers have different data storage and network bandwidth requirements. Bird AI has defined the FUP limit with varying charges and varying data storage limits. FUP is different for different plans. The data storage and network bandwidth limit is generally kept sufficiently enough so that a fair majority of the customers do not reach that limit.
          <br />
          Once reaching to certain data transfer limit (as per storage plan and network bandwidth), the Customer will be notified that it reaches the storage limit. They may opt to select a higher storage plan.
        </p>
        <h5>File Type allowed for Storage</h5>
        <p>
          As long as your files are stored for Meeting, Ideation and Discussion purpose and legal under applicable laws then you can store them on your Bird AI Cloud storage account. Where possible issues arise is when a user attempts to offload all of their storage needs onto the Bird AI Cloud storage. It’s important to realize that a Bird AI storage and network bandwidth are optimized to handle our Bird AI Service-related files. The Bird AI service is not a mass file storage one like Google Drive, Dropbox, etc.
        </p>
        <p>
          For instance, if you have a folder with 2 GB worth of pictures you’ve taken with your camera over the years. Simply uploading these thousands of images for storage is not related to the intended use. At that point, you’d be utilizing the storage as a file repository – not for the Educational purpose that it is intended for.
        </p>
      </div>
    </div>
  )
}

export default FairUsagePolicy
