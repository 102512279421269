import React from 'react';
import { Snackbar } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useNotificationDispatcher } from '../../../store/dispatcher/useNotificationDispatcher';

export const StyledSnackbar = () => {
  const snackbar = useSelector((state) => state.notification.snackbar);
  const { dispatchResetSnackbar } = useNotificationDispatcher();

  const closeSnackbar = () => {
    dispatchResetSnackbar();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      message={snackbar.message}
      open={snackbar.isOpen}
      onClose={closeSnackbar}
      autoHideDuration={snackbar.duration}
      style={{ zIndex: 99999 }}
    />
  );
};
