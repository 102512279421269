import React, { useState } from 'react';
import { useEffect } from 'react';
import { ZoomComponentViewContainer } from '../zoom-component-view/container';
import PubSub from 'pubsub-js';
import { EVENTS } from '../../../../../constants/events';
import { useLocation } from 'react-router';
import zoomService from '../../../../../services/zoom.service';

export const ZoomExternalMeetingManagerContainer = (props) => {
  const location = useLocation();
  const [meetingUrl, setMeetingUrl] = useState('');
  const [count, setCount] = useState(0);

  /**
   * render the zoom component view after redirected from oauth authorization
   * this should be rendered when, user is host/admin
   */
  useEffect(() => {
    const init = async () => {
      const zoomMeeting = location?.state?.zoom;
      const isAuthenticated = await zoomService.hasAuthorizedOAuth();
      if (isAuthenticated && zoomMeeting) {
        const meetingUrl = zoomMeeting.meetingUrl;
        setMeetingUrl(meetingUrl);
        setCount((value) => value + 1);
      }

      /** clear the location state, so that zoom component view is not rendered again */
      window.history.replaceState({}, document.title);
    };
    init();
  }, []);

  /**
   * render the zoom component view on clicking the go to meeting button
   */
  useEffect(() => {
    const joinZoomMeetingSubscriber = (msg, data) => {
      setMeetingUrl(data.data.meetingUrl);
      setCount((value) => value + 1);
    };
    const listenerToken = PubSub.subscribe(EVENTS.JOIN_ZOOM_MEETING, joinZoomMeetingSubscriber);
    return () => {
      PubSub.unsubscribe(listenerToken);
    };
  }, []);

  return meetingUrl && <ZoomComponentViewContainer count={count} meetingUrl={meetingUrl} />;
};
