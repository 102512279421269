import { Typography } from '@material-ui/core';
import RepeatIcon from '@material-ui/icons/Repeat';
import moment from 'moment';
import React from 'react';
import { useImportOutlookDateTimeInfoStyles } from './component.style';

export const EventDateTimeInfo = (props) => {
  const { isRecurring, startTime, nextStartDateTime, endTime, endsAt, isAllDay, dateTimeFormat } =
    props;
  const classes = useImportOutlookDateTimeInfoStyles();

  const displayStartTime = moment(startTime).format('h:mm a');
  const displayEndTime = moment(endTime).format('h:mm a');

  const eventStartDateTime = nextStartDateTime || startTime;
  const eventDate = moment(eventStartDateTime).format('dddd, MMMM D');

  const timeSection = isAllDay ? 'All Day' : `${displayStartTime} - ${displayEndTime}`;

  const getDateTimeForRecurringEvent = () => {
    if (dateTimeFormat === 'onlyTime') {
      return timeSection;
    }

    return nextStartDateTime ? `${eventDate}, ${timeSection}` : timeSection;
  };

  const getDateTimeForNonRecurringEvent = () => {
    if (dateTimeFormat === 'onlyTime') {
      return timeSection;
    }
    return `${eventDate}, ${timeSection}`;
  };

  const dateTimeForRecurringEvent = getDateTimeForRecurringEvent();
  const dateTimeForNonRecurringEvent = getDateTimeForNonRecurringEvent();

  const repeatIcon = <RepeatIcon style={{ fontSize: '1rem' }} />;

  const dateTime = isRecurring ? (
    <div className={classes.recurringDateTime}>
      {dateTimeForRecurringEvent}
      {repeatIcon}
    </div>
  ) : (
    dateTimeForNonRecurringEvent
  );

  const displayDateAndStartEndTime = (
    <Typography style={{ fontSize: '0.75rem', fontWeight: 400 }}>{dateTime}</Typography>
  );

  return <>{displayDateAndStartEndTime}</>;
};
