import React from 'react';

const SecureityIcon = () => {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.2" cx="19" cy="19" r="19" fill="#FFDF7B" />
      <path
        d="M10 17.4167C10 14.2191 10 12.6203 10.3775 12.0824C10.755 11.5445 12.2583 11.03 15.2649 10.0008L15.8377 9.80472C17.405 9.26824 18.1886 9 19 9C19.8114 9 20.595 9.26824 22.1623 9.80472L22.7351 10.0008C25.7417 11.03 27.245 11.5445 27.6225 12.0824C28 12.6203 28 14.2191 28 17.4167C28 17.8996 28 18.4234 28 18.9914C28 24.6294 23.761 27.3655 21.1014 28.5273C20.38 28.8424 20.0193 29 19 29C17.9807 29 17.62 28.8424 16.8986 28.5273C14.239 27.3655 10 24.6294 10 18.9914C10 18.4234 10 17.8996 10 17.4167Z"
        stroke="#FFC900"
        stroke-width="1.5"
      />
      <path
        d="M16.5 19.4L17.9286 21L21.5 17"
        stroke="#FFC900"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SecureityIcon;
