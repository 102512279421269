import React from 'react';
import { CALENDAR_TABS } from '../use-calendar-selection';
import { Tab, Tabs } from '@material-ui/core';

export const CalendarSelectionTabs = (props) => {
  const { selectedCalendarTab, handleSelectedCalendarTab } = props;

  const personalCalendarLabel = 'Personal Calendar';
  const spaceCalendarLabel = 'Space Calendar';

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const calendarSelectionTabs = (
    <Tabs
      variant="fullWidth"
      value={selectedCalendarTab}
      onChange={handleSelectedCalendarTab}
      style={{ borderBottom: '1px solid rgb(224, 224, 224)' }}
    >
      <Tab label={personalCalendarLabel} {...a11yProps(CALENDAR_TABS.PERSONAL)} />
      <Tab label={spaceCalendarLabel} {...a11yProps(CALENDAR_TABS.SPACE)} />
    </Tabs>
  );

  return <>{calendarSelectionTabs}</>;
};
