import React, { useCallback } from 'react';
import { Typography } from '@material-ui/core';

import { StyledDialog } from './index';

export const ConFirmDialog = ({
  dialogTitle,
  text,
  onClose,
  onConfirm,
  confirmText = 'YES',
  cancelText = 'NO',
}) => {
  const handleConfirm = useCallback(() => {
    onConfirm();
    onClose();
  }, [onConfirm, onClose]);

  return (
    <StyledDialog
      open={true}
      onClose={onClose}
      onSubmit={handleConfirm}
      title={dialogTitle}
      submitText={confirmText}
      cancelBtnText={cancelText}
    >
      <Typography>{text}</Typography>
    </StyledDialog>
  );
};
