import React, { useState } from 'react';
import microsoftGraphService from '../../../../../../services/microsoft-graph.service';
import { useDialogDispatcher } from '../../../../../../store/dispatcher/useDialogDispatcher';
import { useMicrosoftDispatcher } from '../../../../../../store/dispatcher/useMicrosoftDispatcher';
import VideoCallUserSelectionDialog from './component';
import {useMicrosoftAuth} from '../../../../../../Utils/microsoft/useMicrosoftAuth'
import { useLastPageVisited } from '../../../../../../Utils/history/useLastPageVisited';

export const VideoCallUserSelectionDialogContainer = (props) => {
  const { schedule, closeDialog } = props;
  const [disableJoinAsGuestButton, setDisableJoinAsGuestButton] = useState(false);
  const [disableJoinAsMicrosoftUserButton, setDisableJoinAsMicrosoftUserButton] = useState(false);
  const { initiateMicrosoftOAuthAuthorization } = useMicrosoftAuth();
  const { setSignInAndJoinMicrosoftTeamsAsLastVisisted } = useLastPageVisited();

  const title = 'Microsoft Teams Meeting';
  const joinAsGuestButtonTitle = 'Join as Guest';
  const joinAsMicrosoftUserButtonTitle = 'Join as Microsoft User';
  const { dispatchSetMicrosoftCommunicationIdentity, dispatchSetMicrosoftTeamsMeeting } =
    useMicrosoftDispatcher();
  const { dispatchCloseVideoCallSidebarDialog } = useDialogDispatcher();

  const openMicrosoftTeamsViewer = async () => {
    const communicationIdentity = await microsoftGraphService.getCommunicationIdentity();

    /** set the teams meeting url */
    dispatchSetMicrosoftTeamsMeeting({ url: schedule.link });

    /** set the communication identity */
    dispatchSetMicrosoftCommunicationIdentity(communicationIdentity);
  };

  const joinAsGuest = async () => {
    setDisableJoinAsGuestButton(true);

    await openMicrosoftTeamsViewer();
    closeDialog();
    dispatchCloseVideoCallSidebarDialog();

    setDisableJoinAsGuestButton(false);
  };

  const joinAsMicrosoftUser = () => {
    /**
     * disable the join as 
     * Authenticate Microsoft Oauth
     * handle redirect back
     * join call as microsoft user
     */
    setDisableJoinAsMicrosoftUserButton(true);

    setSignInAndJoinMicrosoftTeamsAsLastVisisted({ meetingUrl: schedule.link });
    initiateMicrosoftOAuthAuthorization();
  };

  return (
    <VideoCallUserSelectionDialog
      {...props}
      disableJoinAsGuestButton={disableJoinAsGuestButton}
      disableJoinAsMicrosoftUserButton={disableJoinAsMicrosoftUserButton}
      joinAsGuest={joinAsGuest}
      joinAsGuestButtonTitle={joinAsGuestButtonTitle}
      joinAsMicrosoftUser={joinAsMicrosoftUser}
      joinAsMicrosoftUserButtonTitle={joinAsMicrosoftUserButtonTitle}
      title={title}
    />
  );
};
