import React from 'react';
import { schedulesService } from '../../../../../services/schedules.service';
import { getCanvasCode, getWorkspaceId } from '../../../../../Utils/authentication-access';
import { EventInfo } from './component';
import PubSub from 'pubsub-js';
import { EVENTS } from '../../../../../constants/events';
import moment from 'moment';
import { useSchedulesSelector } from '../../../../../store/selector/useSchedulesSelector';
import { useSchedulesDispatcher } from '../../../../../store/dispatcher/useSchedulesDispatcher';
import { getEndTime, getStartTime } from '../../../../../Utils/microsoft/outlook-event';

export const EventInfoContainer = (props) => {
  const { eventInfo } = props;
  const { outlookImportedEvents } = useSchedulesSelector();
  const { dispatchAddOutlookImportedEvent } = useSchedulesDispatcher();

  const getOccurence = (eventInfo) => {
    /** handles the custom outlook calendar response */
    if (eventInfo?.occurence) {
      return eventInfo?.occurence;
    }

    /** handles the outlook info */
    switch (eventInfo?.recurrence?.pattern?.type) {
      case 'daily':
        return 'DAILY';
      case 'weekly':
        return 'WEEKLY';
      case 'absoluteMonthly':
        return 'MONTHLY';
      case 'absoluteYearly':
        return 'YEARLY';
      default:
        return 'NONE';
    }
  };

  const isRecurring = eventInfo?.recurrence;

  /** schedules do not have end date at the moment */
  // const getEndsAt = (eventInfo) => {
  //   if (eventInfo?.recurrence?.pattern?.type) {
  //     return `${eventInfo?.recurrence?.range?.endDate}T00:00:0000000Z`;
  //   }
  // };
  const getEndsAt = () => {
    if (!isRecurring) {
      return undefined;
    }

    const endsAt = eventInfo?.recurrence?.range?.endDate;

    if (endsAt === '0001-01-01') {
      return {
        date: '0001-01-01',
        text: 'Never ends',
      };
    }

    return {
      date: endsAt,
      text: moment(endsAt).format('Do MMM YY'),
    };
  };

  const endsAt = getEndsAt();

  const getLink = () => {
    const joinUrl = eventInfo?.onlineMeeting?.joinUrl;
    return joinUrl ? joinUrl : '';
  };

  const importEvent = async () => {
    const payload = {
      workspaceId: getWorkspaceId(),
      classId: getCanvasCode(),
      title: eventInfo.subject,
      description: '',
      link: getLink(),
      startTime: getStartTime(eventInfo),
      endTime: getEndTime(eventInfo),
      occurence: getOccurence(eventInfo),
      isAllDay: eventInfo.isAllDay,
      meta: {
        type: 'OUTLOOK',
        id: eventInfo.id,
        iCalUId: eventInfo.iCalUId,
      },
    };
    await schedulesService.create(payload);
    PubSub.publish(EVENTS.GET_SCHEDULES);
    dispatchAddOutlookImportedEvent({ id: eventInfo.id });
  };

  if (!eventInfo) {
    return <></>;
  }

  return (
    <EventInfo
      endsAt={endsAt}
      eventInfo={eventInfo}
      importEvent={importEvent}
      isRecurring={isRecurring}
      outlookImportedEventsIds={outlookImportedEvents}
    />
  );
};
