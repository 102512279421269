import React from 'react';
import { Button, DialogActions, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    paddingRight: '1rem',
    paddingLeft: '1.5rem',
    paddingBottom: '0.5rem',
  },
  actions: {},
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  cancelBtn: {
    // width: '100px',
    height: '36px !important',
    fontWeight: '500',
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '14px',
    lineHeight: '0 !important',
  },
  confirmBtn: {
    marginLeft: '5px',
    // width: '100px',
    height: '36px !important',
    boxShadow: '0px 0.3px 0.5px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    color: 'white',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '0 !important',
  },
}));

const StyledFooter = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <DialogActions>
        <div className={classes.container}>
          {props.children ? props.children : <div />}
          <div className={classes.actions}>
            {/* <Button variant="outlined" color="primary" onClick={props.onClose}>
              {t('Cancel')}
            </Button> */}

            {!props.hideCloseBtn && (
              <Button onClick={props.onClose} className={classes.cancelBtn}>
                {t(props.cancelBtnText || 'CANCEL')}
              </Button>
            )}

            {/* <Button variant='contained' color="primary" onClick={props.onSubmit} style={{marginLeft: '0.5rem'}}>
              {t('Save')}
            </Button> */}

            <Button
              color="primary"
              variant="contained"
              className={classes.confirmBtn}
              onClick={props.onSubmit}
              href={props.href ? props.href : '#'}
            >
              {t(props.submitText || 'Save')}
            </Button>
          </div>
        </div>
      </DialogActions>
    </div>
  );
};

export default StyledFooter;
