import React from 'react';
import { makeStyles } from '@material-ui/core';

import Sidebar from './sidebar';
import Topbar from './topbar';

const useStyles = ({ showTopbar }) =>
  makeStyles({
    root: {
      display: 'flex',
      position: 'fixed',
      top: 52,
      left: 59,
      bottom: 0,
      right: 0,
      overflow: 'auto',
      '& .MuiButton-root': {
        textTransform: 'capitalize',
      },
    },
    container: {
      flexGrow: 1,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: 1,
      height: showTopbar ? 'calc(100vh - 80px - 64px)' : 'calc(100vh - 80px)',
    },
  });

const BrightAILayout = ({ children, showTopbar = false }) => {
  const classes = useStyles({ showTopbar })();

  return (
    <div className={classes.root}>
      <Sidebar />
      <div className={classes.container}>
        <Topbar show={showTopbar} />
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};

export default React.memo(BrightAILayout);
