import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import Color from 'color';
import { IconButton, Menu, MenuItem, Popover, Typography, makeStyles } from '@material-ui/core';
import { MoreVert as MoreIcon } from '@material-ui/icons';

import { stringToHSLColor } from '../../../../Utils/common-utils';
import { DeleteDialog, InputDialog } from '../../../dialogs';

const useStyles = ({ title }) =>
  makeStyles(() => {
    let primaryColor = new Color(stringToHSLColor(title));
    const hoverColor = primaryColor.desaturate(0.6).hex();
    primaryColor = primaryColor.desaturate(0.6).lighten(0.3).hex();
    return {
      notebookRoot: {
        position: 'relative',
        backgroundColor: primaryColor,
        minHeight: 180,
        borderRadius: 6,
        padding: 12,
        '&:hover': {
          backgroundColor: hoverColor,
        },
        '& .i-options-button': {
          position: 'absolute',
          top: 12,
          right: 12,
        },
      },
      detailsContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 68,
        padding: '8px 12px',
        borderRadius: '0px 0px 6px 6px',
        backgroundColor: '#fff3',
        '& .i-title': {
          fontSize: 14,
          color: '#000',
        },
      },
    };
  });

const Notebook = ({ id, title, onDelete, onRename }) => {
  const [showRenameDialog, setShowRenameDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const classes = useStyles({ title })();
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);

  const toggleRenameDialog = useCallback(
    (e) => {
      if (e) e.stopPropagation();
      setShowRenameDialog(!showRenameDialog);
      setOptionsAnchorEl(null);
    },
    [showRenameDialog],
  );

  const toggleDeleteDialog = useCallback(
    (e) => {
      if (e) e.stopPropagation();
      setShowDeleteDialog(!showDeleteDialog);
      setOptionsAnchorEl(null);
    },
    [showDeleteDialog],
  );

  const optionsClickHandler = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setOptionsAnchorEl(e.currentTarget);
  }, []);

  const deleteHandler = useCallback(() => onDelete(id), [id, onDelete]);
  const renameHandler = useCallback((name) => onRename({ id, name }), [id, onRename]);

  return (
    <>
      <Link to={`/controller/smart-notes/notebooks/${id}`} className={classes.notebookRoot}>
        <IconButton className="i-options-button" size="small" onClick={optionsClickHandler}>
          <MoreIcon />
        </IconButton>
        <div className={classes.detailsContainer}>
          <Typography className="i-title">{title}</Typography>
        </div>
        <Popover
          anchorEl={optionsAnchorEl}
          open={!!optionsAnchorEl}
          onClose={(e) => {
            e.preventDefault();
            setOptionsAnchorEl(null);
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem onClick={toggleRenameDialog}>Rename</MenuItem>
          <MenuItem onClick={toggleDeleteDialog}>Delete</MenuItem>
        </Popover>
      </Link>
      {showRenameDialog && (
        <InputDialog
          dialogTitle="Rename Note"
          inputLabel="Note Name"
          onClose={toggleRenameDialog}
          onSubmit={renameHandler}
          submitText="Rename"
          emptyValueErrorMessage="Name is required"
          initialValue={title}
        />
      )}
      {showDeleteDialog && (
        <DeleteDialog
          dialogTitle="Delete Note"
          onClose={toggleDeleteDialog}
          onDelete={deleteHandler}
          name={title}
        />
      )}
    </>
  );
};

export default Notebook;
