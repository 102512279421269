import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import BaseTheme from './theme/base-theme';
import './assets/css/common.scss';
import { StyledSnackbar } from './components/common/feedback/styled-snackbar';
import { StyledGlobalSpinner } from './components/common/feedback/styled-global-spinner';
import importRetry from './components/manage/commonFiles/importRetry';
import { CircularProgress } from '@material-ui/core';
import { checkIsAuthUser, clearUserData } from './Utils/authentication-access';
import { PrivateRoute } from './Utils/PrivateRoute';
import PrivacyPolicy from './StaticPages/PrivacyPolicy';
import Terms from './StaticPages/Terms';
import LicenseAgreement from './StaticPages/LicenseAgreement';
import FairUsagePolicy from './StaticPages/FairUsagePolicy';
import { useHydrateApp } from './useHydrateApp';
import Upgrade from './components/manage/LicenceBilling/PaymentPages/Upgrade';
import UpgradePlan from './components/manage/LicenceBilling/UpgradePlan';
import ZoomDocumentation from './StaticPages/ZoomDocumentation';
import ZoomVerify from './StaticPages/zoomVerify';
import Controller from './Kneura-Web-Whiteboard/Controller/Controller';
import ScreenshareRoomContainer from './components/screenshare/container';
import { GlobalDialogContainer } from './components/common/dialog/global-dialog-container/container';
import LiveMeeting from './Kneura-Web-Whiteboard/Controller/common/LiveMeeting';
import './App.css';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const MicrosoftTeamsExternalMeetings = lazy(() =>
  importRetry(() =>
    import('./components/video-call-links/external-meetings/microsoft-teams/container'),
  ),
);
const AdminJoin = lazy(() =>
  importRetry(() => import('./components/manage/pages/admin/AdminJoin')),
);
const ManageContainer = lazy(() =>
  importRetry(() => import('./components/manage/Manage/container')),
);
const SwitchWorkspace = lazy(() =>
  importRetry(() => import('./components/sideBar/Workspace/Switchworkspace')),
);
const NewWorkspace = lazy(() =>
  importRetry(() => import('./components/authenticationPages/NewWorkspace')),
);
const Signup = lazy(() => importRetry(() => import('./components/authenticationPages/Signup')));
const ResetPassword = lazy(() =>
  importRetry(() => import('./components/authenticationPages/ForgotPasswordScreens/ResetPassword')),
);
const ResendScreen = lazy(() =>
  importRetry(() => import('./components/authenticationPages/ForgotPasswordScreens/ResendScreen')),
);
const ForgotPassword = lazy(() =>
  importRetry(() =>
    import('./components/authenticationPages/ForgotPasswordScreens/ForgotPassword'),
  ),
);
const ProfileDetailsEnter = lazy(() =>
  importRetry(() => import('./components/authenticationPages/ProfileDetailsEnter')),
);
const OtpScreen = lazy(() =>
  importRetry(() => import('./components/authenticationPages/OtpScreen')),
);
const LoginPage = lazy(() =>
  importRetry(() => import('./components/authenticationPages/LoginPage')),
);
const CreateCanvas = lazy(() =>
  importRetry(() => import('./components/create-canvas/createCanvas')),
);
const MeetingSchedule = lazy(() =>
  importRetry(() => import('./components/meetingSchedule/MeetingSchedule')),
);
const ScheduleMeeting = lazy(() =>
  importRetry(() => import('./components/scheduleForm/scheduleMeeting')),
);
const WhiteBoard = lazy(() => importRetry(() => import('./Web-Ai-Canvas/WhiteBoard/Whiteboard')));
const CanvasBoard = lazy(() => importRetry(() => import('./components/canvasboard/canvasboard')));
const MobileView = lazy(() => importRetry(() => import('./components/mobileview/mobileView')));
const JoinFlowByLInk = lazy(() =>
  importRetry(() => import('./components/joincanvas/joinCanvasByLink')),
);
const JoinCanvas = lazy(() => importRetry(() => import('./components/joincanvas/joinCanvas')));
const JoinWorkspaceContainer = lazy(() =>
  importRetry(() => import('./components/invites/workspace/JoinWorkspaceContainer')),
);
const JoinSpaceContainer = lazy(() =>
  importRetry(() => import('./components/invites/space/JoinSpaceContainer')),
);
const ZoomExternalMeetingContainer = lazy(() =>
  importRetry(() => import('./components/video-call-links/zoom-external-meeting/container')),
);
const ZoomOAuthContainer = lazy(() =>
  importRetry(() => import('./components/oauth/zoom/container')),
);
const MicrosoftOAuthRedirectURIContainer = lazy(() =>
  importRetry(() => import('./components/oauth/microsoft/microsoft-oauth-redirect-uri/container')),
);
const LinkMsWithDeviceContainer = lazy(() =>
  importRetry(() => import('./components/oauth/microsoft/link-with-device/container')),
);

function App() {
  useHydrateApp();
  const loader = (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          height: 'calc( 100vh - 30px )',
        }}
      >
        <CircularProgress />
        <p>Loading ...</p>
      </div>
    </>
  );

  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      if (!checkIsAuthUser()) {
        clearUserData();
      }
      console.log('beforeunload event triggered');
    };
    window.addEventListener('beforeunload', handleTabClose);
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={BaseTheme}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Suspense fallback={loader}>
              <div>
                <Switch>
                  <Route exact path="/join-canvas" component={JoinCanvas} />
                  <Route exact path="/mobile" component={MobileView} />
                  <Route exact path="/meetings" component={ScheduleMeeting} />
                  <Route exact path="/create-canvas" component={CreateCanvas} />
                  <Route exact path="/meeting-details" component={MeetingSchedule} />
                  <Route exact path="/joinflow/:canvascode" component={JoinFlowByLInk} />
                  <PrivateRoute exact path={'/Wbflow'} component={CanvasBoard} />
                  <Route
                    exact
                    path={'/mobile-canvas'}
                    render={(props) => <WhiteBoard {...props} />}
                  />
                  <Route exact path={'/'} component={LoginPage} />
                  <Route exact path={'/signup'} component={Signup} />
                  <Route exact path={'/enter-otp'} component={OtpScreen} />
                  <Route exact path={'/enter-details'} component={ProfileDetailsEnter} />
                  <Route exact path={'/forgot-password'} component={ForgotPassword} />
                  <Route exact path={'/resend-Screen'} component={ResendScreen} />
                  <Route exact path={'/reset-Password'} component={ResetPassword} />
                  <Route exact path={'/newWorkspace'} component={NewWorkspace} />
                  <Route path="/join/workspaces/:workspaceId" component={JoinWorkspaceContainer} />
                  <Route path="/join/spaces/:spaceId" component={JoinSpaceContainer} />
                  <Route exact path={'/switchWorkspace'} component={SwitchWorkspace} />
                  <Route path={'/manage'} component={ManageContainer} />
                  <Route path={'/workspaces/join'} component={AdminJoin} />
                  <Route path={'/privacy-policy'} component={PrivacyPolicy} />
                  <Route path={'/terms'} component={Terms} />
                  <Route path={'/license-agreement'} component={LicenseAgreement} />
                  <Route path={'/fair-usage-policy'} component={FairUsagePolicy} />
                  <Route path={'/documentation/zoom'} component={ZoomDocumentation} />
                  <Route
                    path={'/external-meetings/zoom'}
                    component={ZoomExternalMeetingContainer}
                  />
                  <Route
                    path={'/external-meetings/ms-teams'}
                    component={MicrosoftTeamsExternalMeetings}
                  />
                  <Route path={'/oauth/zoom'} component={ZoomOAuthContainer} />
                  <Route
                    exact
                    path={'/oauth/microsoft/link-with-device'}
                    component={LinkMsWithDeviceContainer}
                  />
                  <Route path={'/oauth/microsoft'} component={MicrosoftOAuthRedirectURIContainer} />
                  <Route path={'/upgrade'} component={Upgrade} />
                  <Route path={'/upgradePlan'} component={UpgradePlan} />
                  <Route path={'/huddle'} component={ScreenshareRoomContainer} />
                  <Route path={'/zoomverify/verifyzoom.html'} component={ZoomVerify} />
                  <PrivateRoute path={'/controller'} component={Controller} />
                  <Route path={'/live-meeting'} component={LiveMeeting} />
                </Switch>
              </div>
            </Suspense>
          </Router>
        </QueryClientProvider>
        <StyledSnackbar />
        <StyledGlobalSpinner />
        <GlobalDialogContainer />
      </ThemeProvider>
    </>
  );
}

export default App;
