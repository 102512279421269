const ACTION_TYPES = {
  GETALL_USER: 'GETALL_USER',
  ADD_USER: 'ADD_USER',
  REMOVE_USER: 'REMOVE_USER',
  UPDATE_MOUSE_POSITION: 'UPDATE_MOUSE_POSITION',
  UPDATE_CURSORS: 'UPDATE_CURSORS',

  //canvas (white board) related
  UPDATE_TRANSFORM_FACTOR: 'UPDATE_TRANSFORM_FACTOR',
  ADD_STAGE: 'ADD_STAGE',
  CHANGE_PRESENTATION_MODE: 'CHANGE_PRESENTATION_MODE',
  STORE_PRESENTER_INFO: 'STORE_PRESENTER_INFO',
  RESET_PRESENTER_INFO: 'RESET_PRESENTER_INFO',
  RESET_ALL_PRESENTER_INFO: 'RESET_ALL_PRESENTER_INFO',
  SET_CANVAS_SCALE: 'SET_CANVAS_SCALE',
  SET_CANVAS_POSITION: 'SET_CANVAS_POSITION',
  SET_CURRENT_PAGE_ID: 'SET_CURRENT_PAGE_ID',
  SET_FULLSCREEN: 'SET_FULLSCREEN',

  //Tools
  SELECT_PEN: 'SELECT_PEN',
  SELECT_HIGHLIGHTER: 'SELECT_HIGHLIGHTER',
  SELECT_ERASER: 'SELECT_ERASER',
  SELECT_SELECT_TOOL: 'SELECT_SELECT_TOOL',
  SELECT_SHAPES: 'SELECT_SHAPES',
  SELECT_TEXT_TOOL: 'SELECT_TEXT_TOOL',
  SELECT_MEDIA: 'SELECT_MEDIA',
  CLEAR_CANVAS: 'CLEAR_CANVAS',
  SHOW_LOADER: 'SHOW_LOADER',
  SET_LOADER_TEXT: 'SET_LOADER_TEXT',

  //Pen and highlighter color and size
  SELECT_PEN_COLOR: 'SELECT_PEN_COLOR',
  SELECT_PEN_SIZE: 'SELECT_PEN_SIZE',
  SELECT_HIGHLIGHTER_COLOR: 'SELECT_HIGHLIGHTER_COLOR',
  SELECT_HIGHLIGHTER_SIZE: 'SELECT_HIGHLIGHTER_SIZE',

  //WhiteBoard Elements
  LOAD_ELEMENTS: 'LOAD_ELEMENTS',
  LOAD_PAGE_COLLECTION: 'LOAD_PAGE_COLLECTION',
  LOAD_BACK_GROUND_COLLECTION: 'LOAD_BACK_GROUND_COLLECTION',
  UPDATE_SELECTED_PAGE: 'UPDATE_SELECTED_PAGE',
  ADD_NEW_ELEMENT: 'ADD_NEW_ELEMENT',
  ADD_IMAGE_ELEMENT: 'ADD_IMAGE_ELEMENT',
  LOAD_GROUP_COLLECTION: 'LOAD_GROUP_COLLECTION',

  //undo redo
  ADD_UNDO_ELEMENT: 'ADD_UNDO_ELEMENT',
  ADD_REDO_ELEMENT: 'ADD_REDO_ELEMENT',
  PERFORM_REDO: 'PERFORM_REDO',
  PERFORM_UNDO: 'PERFORM_UNDO',
  ADD_ALL_ELEMENTS: 'ADD_ALL_ELEMENTS',

  //Pagination
  PAGE_CHANGE: 'PAGE_CHANGE',
  PAGE_ADD: 'PAGE_ADD',
  PAGE_DELETE: 'PAGE_DELETE',

  //Meeting Schedule
  UPDATE_MEETING_SCHEDULE: 'UPDATE_MEETING',
  GET_MEETING_SCHEDULE: 'GET_MEETING_SCHEDULE',

  //create-canvas popup
  SHOW_CREATE_CANVAS_POPUP: 'SHOW_CREATE_CANVAS_POPUP',

  // Huddle
  SET_ONLINE: 'SET_ONLINE',
  SET_OFFLINE: 'SET_OFFLINE',
  SET_USER_ONLINE: 'SET_USER_ONLINE',
  SET_USER_OFFLINE: 'SET_USER_OFFLINE',
  SET_OPEN_HUDDLE_INVITATION: 'SET_OPEN_HUDDLE_INVITATION',
  SET_VIEW_OPEN: 'SET_VIEW_OPEN',
  START_HUDDLE: 'START_HUDDLE',
  LEAVE_HUDDLE: 'LEAVE_HUDDLE',
  HUDDLE_STARTED: 'HUDDLE_STARTED',
  HUDDLE_ENDED: 'HUDDLE_ENDED',
  SET_HUDDLE_DURATION: 'SET_HUDDLE_DURATION',

  // document import
  SET_FILE_INFO: 'SET_FILE_INFO',
  RESET_FILE_INFO: 'RESET_FILE_INFO',
  SET_DOC_INFO: 'SET_DOC_INFO',
  RESET_DOC_INFO: 'RESET_DOC_INFO',

  /** notification */
  SET_SNACKBAR: 'SET_SNACKBAR',
  RESET_SNACKBAR: 'RESET_SNACKBAR',
  SHOW_SPINNER: 'SHOW_SPINNER',
  HIDE_SPINNER: 'HIDE_SPINNER',
  SHOW_LICENSE_EXHAUSTED_DIALOG: 'SHOW_LICENSE_EXHAUSTED_DIALOG',
  HIDE_LICENSE_EXHAUSTED_DIALOG: 'HIDE_LICENSE_EXHAUSTED_DIALOG',

  /** spaces */
  FETCH_SPACELIST: 'FETCH_SPACELIST',
  ACTIVE_TEAM_MEMBERS_COUNT: 'ACTIVE_TEAM_MEMBERS_COUNT',
  ACTIVE_SPACE_INFO: 'ACTIVE_SPACE_INFO',
  TEAMS_INFO: 'TEAMS_INFO',

  /** INVITES */
  SET_PENDING_REQUESTS: 'SET_PENDING_REQUESTS',
  REMOVE_INVITE: 'REMOVE_INVITE',
  REMOVE_INVITE_BY_IDS: 'REMOVE_INVITE_BY_IDS',
  ADD_INVITE: 'ADD_INVITE',
  SHOW_JOIN_WORKSPACE_INVITE_DIALOG: 'SHOW_JOIN_WORKSPACE_INVITE_DIALOG',
  HIDE_JOIN_WORKSPACE_INVITE_DIALOG: 'HIDE_JOIN_WORKSPACE_INVITE_DIALOG',
  SHOW_JOIN_SPACE_INVITE_DIALOG: 'SHOW_JOIN_SPACE_INVITE_DIALOG',
  HIDE_JOIN_SPACE_INVITE_DIALOG: 'HIDE_JOIN_SPACE_INVITE_DIALOG',

  /** Dialog */
  SET_IS_OPEN_VIDEO_CALL_SIDEBAR_DIALOG: 'SET_IS_OPEN_VIDEO_CALL_SIDEBAR_DIALOG',
  SET_IS_OPEN_IMPORT_OUTLOOK_EVENTS_DIALOG: 'SET_IS_OPEN_IMPORT_OUTLOOK_EVENTS_DIALOG',
  SET_IS_OPEN_NAVIGATION_SIDEBAR_DIALOG: 'SET_IS_OPEN_NAVIGATION_SIDEBAR_DIALOG',
  SET_IS_OPEN_JOIN_AS_HOST_DIALOG: 'SET_IS_OPEN_JOIN_AS_HOST_DIALOG',
  SET_IS_OPEN_JOIN_REQUEST_SENT_DIALOG: 'SET_IS_OPEN_JOIN_REQUEST_SENT_DIALOG',
  SET_IS_REMOTE_CONTROL_DIALOG_OPEN: 'SET_IS_REMOTE_CONTROL_DIALOG_OPEN',

  /** OAUTH MICROSOFT */
  SET_MICROSOFT_IS_AUTHENTICATED: 'SET_MICROSOFT_IS_AUTHENTICATED',
  SET_MICROSOFT_ME: 'SET_MICROSOFT_ME',
  SET_MICROSOFT_TEAMS_MEETING: 'SET_MICROSOFT_TEAMS_MEETING',
  RESET_MICROSOFT_TEAMS_MEETING: 'RESET_MICROSOFT_TEAMS_MEETING',
  SET_MICROSOFT_COMMUNICATION_IDENTITY: 'SET_MICROSOFT_COMMUNICATION_IDENTITY',
  RESET_MICROSOFT_COMMUNICATION_IDENTITY: 'RESET_MICROSOFT_COMMUNICATION_IDENTITY',
  SET_OUTLOOK_EVENTS: 'SET_OUTLOOK_EVENTS',
  SET_OUTLOOK_EVENTS_DAYWISE: 'SET_OUTLOOK_EVENTS_DAYWISE',

  /** OAUTH ZOOM */
  SET_ZOOM_IS_AUTHENTICATED: 'SET_ZOOM_IS_AUTHENTICATED',
  SET_ZOOM_ME: 'SET_ZOOM_ME',

  /** SCHEDULES */
  SET_OUTLOOK_IMPORTED_EVENTS: 'SET_OUTLOOK_IMPORTED_EVENTS',
  RESET_OUTLOOK_IMPORTED_EVENTS: 'RESET_OUTLOOK_IMPORTED_EVENTS',
  ADD_OUTLOOK_IMPORTED_EVENT: 'ADD_OUTLOOK_IMPORTED_EVENT',
  REMOVE_OUTLOOK_IMPORTED_EVENT: 'REMOVE_OUTLOOK_IMPORTED_EVENT',

  /** MEDIA SERVER */
  SET_IS_SCREENSHARED: 'SET_IS_SCREENSHARED',

  /** PAIR DEVICE */
  PAIRED_DEVICE_ID: 'PAIRED_DEVICE_ID',
  DEVICE_CONNECTED: 'DEVICE_CONNECTED',

  /**LICENSE */
  SHOW_FEATURE_LOCKED: 'SHOW_FEATURE_LOCKED',
  HIDE_FEATURE_LOCKED: 'HIDE_FEATURE_LOCKED',

  GET_LICENSE_DETAILS: 'GET_LICENSE_DETAILS',
  GET_CLOUD_LICENSE_DETAILS: 'GET_CLOUD_LICENSE_DETAILS',

  SHOW_MAX_SIMULTANEOUS_USAGE_LIMIT_EXHAUSTED_DIALOG:
    'SHOW_MAX_SIMULTANEOUS_USAGE_LIMIT_EXHAUSTED_DIALOG',
  HIDE_MAX_SIMULTANEOUS_USAGE_LIMIT_EXHAUSTED_DIALOG:
    'HIDE_MAX_SIMULTANEOUS_USAGE_LIMIT_EXHAUSTED_DIALOG',

  SHOW_DEVICE_LICENSE_EXHAUSTED_DIALOG: 'SHOW_DEVICE_LICENSE_EXHAUSTED_DIALOG',
  HIDE_DEVICE_LICENSE_EXHAUSTED_DIALOG: 'HIDE_DEVICE_LICENSE_EXHAUSTED_DIALOG',

  SHOW_HUDDLE_SESSION_EXHAUSTED_DIALOG: 'SHOW_HUDDLE_SESSION_EXHAUSTED_DIALOG',
  HIDE_HUDDLE_SESSION_EXHAUSTED_DIALOG: 'HIDE_HUDDLE_SESSION_EXHAUSTED_DIALOG',

  /** ADMINS */
  GET_ADMINS_INFO: 'GET_ADMINS_INFO',

  // SELF_LEARN
  SET_SELF_LEARN_LANGUAGE: 'SET_SELF_LEARN_LANGUAGE',
};

export const SMART_NOTES_ACTION_TYPES = {
  SET_NOTES: 'SET_NOTES',
  ADD_NOTE: 'ADD_NOTE',
  DELETE_NOTE: 'DELETE_NOTE',
  UPDATE_NOTE: 'UPDATE_NOTE',
  ADD_SOURCE_TO_NOTE: 'ADD_SOURCE_TO_NOTE',
  UPDATE_SOURCE: 'UPDATE_SOURCE',
  SELECT_SOURCE: 'SELECT_SOURCE',
  DESELECT_SOURCE: 'DESELECT_SOURCE',
  TOGGLE_ALL_NOTE_SOURCES: 'TOGGLE_ALL_NOTE_SOURCES',
};

export default ACTION_TYPES;
