import React from 'react';
import useSnackbarGlobal from '../../../Utils/message/useSnackbarGlobal';
import { CopyLink } from './component';

export const CopyLinkContainer = (props) => {
  const { link } = props;
  const { snackbarShowMessage } = useSnackbarGlobal();
  const buttonLabel = 'Copy Link';

  const onClick = () => {
    navigator.clipboard.writeText(link);
    snackbarShowMessage({ message: 'Link copied to clipboard' });
  };

  return <CopyLink link={link} onClick={onClick} buttonLabel={buttonLabel} />;
};
