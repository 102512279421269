import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, List, ListItem, Popover, Typography, makeStyles } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

import { chatIcon } from '../../../assets/icons';
import { InputDialog, DeleteDialog } from '../../dialogs';
import MoveThreadDialog from './move-thread-dialog';

const useStyles = ({ selected, loading }) =>
  makeStyles((theme) => ({
    threadRoot: {
      textDecoration: 'none',
      pointerEvents: loading ? 'none' : 'all',
      opacity: loading ? 0.4 : 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      gap: 12,
      width: '100%',
      padding: '12px 4px 12px 16px',
      borderRadius: 8,
      border: '1px solid',
      borderColor: selected ? theme.palette.primary.main : '#E7E7E7',
      cursor: loading ? 'no-drop' : 'pointer',
      '& .chat-icon': {
        width: 24,
        height: 24,
        mask: `url(${chatIcon}) no-repeat center`,
        '-webkit-mask': `url(${chatIcon}) no-repeat center`,
        backgroundColor: selected ? theme.palette.primary.main : '#787878',
      },
      '& .MuiIconButton-root': { color: '#787878' },
      '& .MuiTypography-root': {
        flex: 1,
        minWidth: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 12,
        fontWeight: 500,
        color: selected ? theme.palette.primary.main : '#787878',
      },
      '&:hover': {
        backgroundColor: '#0000000a',
        '& .MuiTypography-root': {
          color: theme.palette.primary.main,
        },
      },
    },
    actionsDialogPaper: {
      borderRadius: 16,
      '& .MuiListItem-root': {
        fontWeight: 500,
      },
    },
  }));

const Thread = ({
  id,
  name,
  notebookId,
  selected,
  onRename,
  onMoveToNew,
  onMove,
  onDelete,
  onClick,
  loading,
}) => {
  const classes = useStyles({ selected, loading })();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showRenameDialog, setShowRenameDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const [showMoveToNewDialog, setShowMoveToNewDialog] = useState(false);

  const handleActionsClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => setAnchorEl(null);

  const toggleRenameDialog = () => {
    handleActionsClose();
    setShowRenameDialog(!showRenameDialog);
  };

  const toggleDeleteDialog = () => {
    handleActionsClose();
    setShowDeleteDialog(!showDeleteDialog);
  };

  const toggleMoveToNewDialog = () => {
    handleActionsClose();
    setShowMoveToNewDialog(!showMoveToNewDialog);
  };

  const toggleMoveDialog = () => {
    handleActionsClose();
    setShowMoveDialog(!showMoveDialog);
  };

  const handleRename = (newName) => onRename({ id, newName });

  const handleMoveToNew = (notebookName) => onMoveToNew({ threadId: id, notebookName });

  const handleMove = (notebookId) => onMove({ threadId: id, notebookId });

  const handleDelete = () => onDelete(id);

  const handleCreateNotebookClick = () => {
    toggleMoveDialog();
    toggleMoveToNewDialog();
  };

  return (
    <>
      <Link
        onClick={onClick}
        to={{
          pathname: `/controller/learn-ai/chats/${notebookId}/${id}`,
          state: { refresh: true },
        }}
        className={classes.threadRoot}
        replace
      >
        <div className="chat-icon" alt="chat" />
        <Typography>{name}</Typography>
        <IconButton onClick={handleActionsClick} size="small">
          <MoreVertIcon />
        </IconButton>
      </Link>
      <Popover
        id="simple-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleActionsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disablePortal
        classes={{ paper: classes.actionsDialogPaper }}
      >
        <List>
          <ListItem button onClick={toggleRenameDialog}>
            Rename
          </ListItem>
          <ListItem button onClick={toggleMoveDialog}>
            Move
          </ListItem>
          <ListItem button onClick={toggleMoveToNewDialog}>
            Move to new Notebook
          </ListItem>
          <ListItem button onClick={toggleDeleteDialog}>
            Delete
          </ListItem>
        </List>
      </Popover>
      {showRenameDialog && (
        <InputDialog
          onClose={toggleRenameDialog}
          onSubmit={handleRename}
          initialValue={name}
          dialogTitle="Rename Thread"
          inputLabel="Thread Name"
          submitText="RENAME"
        />
      )}
      {showDeleteDialog && (
        <DeleteDialog
          onClose={toggleDeleteDialog}
          onDelete={handleDelete}
          name={name}
          dialogTitle="Delete Thread"
        />
      )}
      {showMoveToNewDialog && (
        <InputDialog
          onClose={toggleMoveToNewDialog}
          onSubmit={handleMoveToNew}
          dialogTitle="Create notebook and move thread"
          inputLabel="Notebook Name"
          submitText="Create"
        />
      )}
      {showMoveDialog && (
        <MoveThreadDialog
          threadName={name}
          onClose={toggleMoveDialog}
          onMove={handleMove}
          onNewNotebookClick={handleCreateNotebookClick}
        />
      )}
    </>
  );
};

export default React.memo(Thread);
