import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  activeStyle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '8px',
    lineHeight: '1px',
    color: '#335AFB',
  },
  activeLauncherStyle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '8px',
    lineHeight: '1px',
    color: '#fff',
  },

  disableStyle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '8px',
    lineHeight: '1px',
    color: '#000',
  },

  disableLauncherStyle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '8px',
    lineHeight: '1px',
    color: '#fff',
  },

  activeStyleMobile: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '23px',
    color: '#1E33F2',
  },
  disableStyleMobile: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '23px',
    color: '#000',
  },

  itemContainer: {
    '&:hover': {
      background: '#fdfdfd',
    },
  },
  itemContainerMobileView: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    margin: '0 24px',
    borderBottom: '1px solid #ddd',
  },
}));

const MainSideBarItem = ({ icon, Icon, title, active, onClick, style }) => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [isMobileSideBar, setIsMobileSideBar] = useState(false);
  const isLauncherScreen = window.location.href.includes('launcher');
  const isSmartNotesPage = location.pathname.startsWith('/controller/smart-notes');
  const isLearnAIPage = location.pathname.startsWith('/controller/learn-ai');

  useEffect(() => {
    //INFO: if we want to use mobile and system view variations in launcher screen use bellow line
    // if (!matches && window.location.href.includes('launcher')) {
    if (!matches) {
      setIsMobileSideBar(true);
    } else {
      setIsMobileSideBar(false);
    }
    console.log('matches', isMobileSideBar);
  }, [matches, isMobileSideBar]);

  return (
    <div
      className={isMobileSideBar ? classes.itemContainerMobileView : classes.itemContainer}
      style={{
        height: '59px',
        padding: '6px',
        borderRight: `${isMobileSideBar ? '' : active ? '2px solid rgb(51, 90, 251)' : 'none'}`,
        background: `${
          isLauncherScreen
            ? // || isSmartNotesPage || isLearnAIPage
              isMobileSideBar
              ? ''
              : active
              ? 'rgb(51, 90, 251)'
              : 'none'
            : 'none'
        }`,
        cursor: 'pointer',
        ...style,
      }}
      onClick={onClick}
    >
      {/* {isLauncherScreen ? (
          isMobileSideBar ? (
            <Icon fill={active ? '#335AFB' : '#949494'} />
          ) : (
            <Icon fill={'#fff'} />
          )
        ) : (
          <Icon fill={active ? '#335AFB' : '#949494'} />
        )} */}

      {/* <Icon /> */}
      {icon}
      <div
        className={
          isMobileSideBar
            ? active
              ? classes.activeStyleMobile
              : classes.disableStyleMobile
            : active
            ? isLauncherScreen
              ? classes.activeLauncherStyle
              : classes.activeStyle
            : isLauncherScreen
            ? classes.disableLauncherStyle
            : classes.disableStyle
        }
      >
        {title}
      </div>
    </div>
  );
};

export default MainSideBarItem;
