import React from 'react';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooksOutlined';
import { KsarComponent } from '../../Ksar/KsarComponent/Ksar';
import ResourcesMenuItem from './ResourcesMenuItem';
import classes from './fileuploadpopup.module.css';

class FilesSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (files) => {
    this.props.fileUpload(files);
  };

  render() {
    return (
      <div className="files-popup">
        <div className="popupContainer">
          <div className={classes.empty}>&nbsp;</div>
          <div>
            <label htmlFor="inputUpload">
              <div className={classes.mediaOptionsStyle}>Import Media</div>
            </label>
            <input
              type="file"
              id="inputUpload"
              accept="image/*,video/*"
              onChange={(e) => this.handleChange(e.target.files)}
            />
          </div>
          <div>
            <label htmlFor="fileUpload">
              <div className={classes.mediaOptionsStyle}>Import Document</div>
            </label>
            <input
              type="file"
              id="fileUpload"
              accept="application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={(e) => this.handleChange(e.target.files)}
              style={{ display: 'none' }}
            />
          </div>
          <div>
            <div className={classes.mediaOptionsStyle}>
              <ResourcesMenuItem toggleKsarModal={this.props.toggleKsarModal} />
            </div>
          </div>
          <div className={classes.empty}>&nbsp;</div>
        </div>
      </div>
    );
  }
}

export default FilesSelect;
