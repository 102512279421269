import { makeStyles } from '@material-ui/core';

export const useSetupZoomDialogStyles = makeStyles((theme) => ({
  footerContent: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  unauthorizedFooterContent: {
    display: 'flex',
    gap: '0.5rem',
  },
  button: {
    borderColor: 'black',
  },
}));
