import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { StyledDialogContainer } from '../../common/dialog/styled-dialog/container';
import { useDocumentActionDialogStyles } from './component.style';

export const DocumentActionDialog = (props) => {
  const classes = useDocumentActionDialogStyles();
  const {
    acceptedFileTypes,
    closeDocumentActionDialog,
    descriptionText,
    dialogTitle,
    fileInfo,
    importButtonLabel,
    isOpen,
    onImportClick,
    onViewClick,
    viewButtonLabel,
  } = props;

  const viewButtonContent = (
    <Button variant="contained" onClick={onViewClick}>
      {viewButtonLabel}
    </Button>
  );

  const importButtonContent =
    fileInfo && acceptedFileTypes.includes(fileInfo.filetype) ? (
      <Button variant="contained" color="primary" onClick={onImportClick}>
        {importButtonLabel}
      </Button>
    ) : (
      <></>
    );

  const footerContent = (
    <div className={classes.actionContainer}>
      {viewButtonContent}
      {importButtonContent}
    </div>
  );

  return (
    <StyledDialogContainer
      title={dialogTitle}
      open={isOpen}
      onClose={closeDocumentActionDialog}
      footerContent={footerContent}
    >
      <div className={classes.container}>
        <Typography>{descriptionText}</Typography>
      </div>
    </StyledDialogContainer>
  );
};
