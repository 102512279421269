import React from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CachedIcon from '@material-ui/icons/Cached';
import { ContentContainer, MoreButton, Section } from './component.style';
import StyledButton from '../../../../../../UIComponents/input/StyledButton';
import { StyledMenuContainer } from '../../../../../../UIComponents/menu/container';
import Icon from '@mdi/react';
import { mdiCalendar } from '@mdi/js';

export const BottomBar = (props) => {
  const {
    refetchOutlookEvents,
    menuItems,
    allEventsLabel,
    refreshLabel,
    openImportOutlookCalendarEventDialog,
  } = props;

  const refetchOutlookEventsIcon = <CachedIcon />;

  const refetchOutlookEventsButton = (
    <StyledButton
      onClick={refetchOutlookEvents}
      icon={refetchOutlookEventsIcon}
      disableTransform
      color="primary"
    >
      {refreshLabel}
    </StyledButton>
  );

  const refreshButton = refetchOutlookEventsButton;

  const calendarIcon = <Icon path={mdiCalendar} size={1} />;
  const allEventsButton = (
    <StyledButton
      icon={calendarIcon}
      disableTransform
      onClick={openImportOutlookCalendarEventDialog}
    >
      {allEventsLabel}
    </StyledButton>
  );

  const moreButton = (
    <MoreButton>
      <MoreHorizIcon fontSize="small" />
    </MoreButton>
  );

  const moreMenu = <StyledMenuContainer customButtonContent={moreButton} items={menuItems} />;

  const rightSection = (
    <Section>
      {allEventsButton}
      {moreMenu}
    </Section>
  );

  return (
    <ContentContainer>
      {refreshButton}
      {rightSection}
    </ContentContainer>
  );
};
