
import { spacesService } from '../../services/spaces.service';
import { getWorkspaceId } from '../../Utils/authentication-access';
import ACTION_TYPES from './type.action';


export const getTeamsInfo = (teamsInfo) => async (dispatch) => {
  //INFO: for reference
  // try {
  //   let workspaceId = getWorkspaceId();
  //   const teams = await spacesService.getSpaces(workspaceId);
  // dispatch({
  //   type: ACTION_TYPES.TEAMS_INFO,
  //   payload: teams.data.data.spaces,
  // });
  // }
  // catch (error) {
  //   console.error(error)
  // }

  dispatch({
    type: ACTION_TYPES.TEAMS_INFO,
    payload: teamsInfo,
  });
};