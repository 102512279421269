import React from 'react';
import config from '../../../../config';
import { getWorkspaceId } from '../../../../Utils/authentication-access';
import { StyledDialogContainer } from '../../../common/dialog/styled-dialog/container';
import { ShareLinkContentContainer } from '../../share-link-content/container';

export const InviteWorkspaceDialog = (props) => {
  const { title } = props;
  const workspaceId = getWorkspaceId();
  const link = `${config.APP_URL}/join/workspaces/${workspaceId}`;
  return (
    <StyledDialogContainer {...props} title={title}>
      <ShareLinkContentContainer link={link} />
    </StyledDialogContainer>
  );
};
