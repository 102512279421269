import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { useStyles } from './component.style';

export const StyledMenu = (props) => {
  const classes = useStyles();
  const { label, customButtonContent, items, customMenuList } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    // if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //   return;
    // }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const button = (
    <Button
      ref={anchorRef}
      aria-controls={open ? 'menu-list-grow' : undefined}
      aria-haspopup="true"
      onClick={handleToggle}
    >
      {label}
    </Button>
  );

  const customButton = (
    <div
      ref={anchorRef}
      aria-controls={open ? 'menu-list-grow' : undefined}
      aria-haspopup="true"
      onClick={handleToggle}
    >
      {customButtonContent}
    </div>
  );

  const displayButton = customButtonContent ? customButton : button;

  const displayMenuItems = items.map((item) => {
    const onClick = () => {
      item.onClick();
      handleClose();
    };

    return (
      <MenuItem key={item} onClick={onClick}>
        {item.label}
      </MenuItem>
    );
  });

  const displayMenuList = (
    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
      {customMenuList ?? displayMenuItems}
    </MenuList>
  );

  const displayContent = (
    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          {/* TODO: make the margin of the component should be based on position  */}
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>{displayMenuList}</ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );

  return (
    <div className={classes.root}>
      <div>
        {displayButton}
        {displayContent}
      </div>
    </div>
  );
};
