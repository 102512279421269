import React from "react";
import { useLocation, Redirect } from "react-router-dom";

const RedirectToWebsite = (props) => {
  const location = useLocation();

  return (
    <Redirect to={{
      pathname: '/',
      search: '',
      state: {
        from: location
      }
    }} />
  )
}

export default RedirectToWebsite;
