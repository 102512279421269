module.exports = {
  AUTH_SERVER_URL: process.env.REACT_APP_AUTH_SERVER_URL || 'http://localhost:4600',
  BASE_URL: process.env.REACT_APP_BASE_URL || `http://localhost:7771/`,
  CANVAS_URL: process.env.REACT_APP_CANVAS_URL || `https://canvas.local.birdai.com`,
  LOGIN_PAGE_URL: process.env.REACT_APP_LOGIN_PAGE_URL || `https://ubispace-dev.birdai.com`,
  APP_URL: process.env.REACT_APP_CANVAS_URL || `https://canvas.local.birdai.com`,
  BIRDAI_SERVER_URL: process.env.REACT_APP_BIRDAI_SERVER_URL || 'https://server-stage.birdai.com',
  BIRDAI_SOCKET_SERVER_URL:
    process.env.REACT_APP_BIRDAI_SOCKET_SERVER_URL || 'https://server-stage.birdai.com',
  HUDDLE_SERVER_URL:
    process.env.REACT_APP_HUDDLE_SERVER_URL || 'https://huddle-server-stage.birdai.com',
  PORT: process.env.REACT_APP_PORT || 8053,
  SCREEGO_URL: process.env.REACT_APP_SCREEGO_URL || 'https://app.screego.net',
  MEDIA_CONVERTER_SERVER_URL:
    process.env.REACT_APP_MEDIA_CONVERTER_SERVER_URL || 'http://localhost:8080',
  MEDIA_SERVER_URL:
    process.env.REACT_APP_MEDIA_SERVER_URL || 'https://media-server.local.birdai.com',
  ENFORCE_ONLY_ONE_STREAM_IN_SPACE:
    process.env.REACT_APP_ENFORCE_ONLY_ONE_STREAM_IN_SPACE === 'true' || false,
  CONTACT_US_EMAIL: process.env.REACT_APP_CONTACT_US_EMAIL || 'hello@birdai.com',
  JOIN_CANVAS_URL: process.env.REACT_APP_JOIN_CANVAS_URL || 'https://canvas.teamhub.io',
  ZOOM_CLIENT_ID: process.env.REACT_APP_ZOOM_CLIENT_ID || 'XHvRwNY2c1WeIjNKSFRUCBLUiqs9khRpkdbc',
  ZOOM_REDIRECT_URI:
    process.env.REACT_APP_ZOOM_REDIRECT_URI || 'https://canvas.local.birdai.com/oauth/zoom',
  ZOOM_SDK_KEY: process.env.REACT_APP_ZOOM_SDK_KEY || 'XHvRwNY2c1WeIjNKSFRUCBLUiqs9khRpkdbc',
  MICROSOFT_OAUTH_CLIENT_ID:
    process.env.REACT_APP_MICROSOFT_OAUTH_CLIENT_ID || '6f89e393-ed9f-400d-bb36-3837abe90383',
  MICROSOFT_OAUTH_REDIRECT_URI:
    process.env.REACT_APP_MICROSOFT_OAUTH_REDIRECT_URI || 'http://localhost:3020/oauth/microsoft',
  MICROSOFT_OAUTH_SCOPE:
    process.env.REACT_APP_MICROSOFT_OAUTH_SCOPE ||
    'Calendars.ReadWrite offline_access User.Read https://auth.msft.communication.azure.com/Teams.ManageCalls https://auth.msft.communication.azure.com/Teams.ManageChats',
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || 'development',

  LICENSE_SERVICE_URL:
    process.env.REACT_APP_LICENSE_SERVICE_URL || 'https://ubs-license-server-dev.birdai.com',
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  SMART_NOTES_API_URL: process.env.REACT_APP_SMART_NOTES_API,
  LEARN_AI_URL: process.env.REACT_APP_LEARN_AI_URL || 'https://ubispace-learn-ai-dev.birdai.com',
  LEARN_AI_API_URL:
    process.env.REACT_APP_LEARN_AI_API_URL ||
    'https://learn-ai-dev.brightclass.com/chat-completion',
};
