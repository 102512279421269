import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Dialog, IconButton, TextField, Typography } from '@material-ui/core';
import { GoogleMeet, MicrosoftTeam, Webex, Zoom } from '../../../assets/icons';

const StartInstantMeetingDialog = ({
  open,
  closeHandler,
  meetingNameHandler,
  meetingName,
  meetingLinkHandler,
  meetingLink,
  sendLinkHandler,
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={closeHandler}
        fullWidth={true}
        PaperProps={{
          style: {
            borderRadius: 16,
          },
        }}
      >
        <div
          style={{
            padding: ' 2% 2%',
            display: 'grid',
            gridTemplateColumns: '90% 10%',
            alignItems: 'center',
            minHeight: '30px',
            borderBottom: '1px solid rgba(0,0,0,0.12)',
          }}
        >
          <div
            style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '28px',
              textAlign: 'center',
              marginLeft: '50px',
              color: 'rgba(0, 0, 0)',
            }}
          >
            Start instant meeting
          </div>
          <div style={{ textAlign: 'center', cursor: 'pointer', marginTop: '2px' }}>
            <IconButton edge="end" color="inherit" onClick={closeHandler} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <div style={{ margin: '29px', marginTop: 0 }}>
          <div
            style={{ float: 'right', margin: '10px', marginRight: 0, gap: '17px', display: 'flex' }}
          >
            <MicrosoftTeam />
            <GoogleMeet />
            <Zoom />
            <Webex />
          </div>
          <TextField
            autoFocus
            id="name"
            type="name"
            placeholder="Meeting Name (optional)"
            style={{ width: '100%' }}
            value={meetingName}
            variant="outlined"
            onChange={(event) => meetingNameHandler(event.target.value)}
          />

          <TextField
            autoFocus
            id="name"
            type="name"
            placeholder="Meeting Link"
            style={{ width: '100%', marginTop: '8px' }}
            value={meetingLink}
            variant="outlined"
            onChange={(event) => meetingLinkHandler(event.target.value)}
            helperText={!meetingLink && '*Required'}
          />
        </div>

        <div
          style={{
            borderTop: '1px solid rgba(0,0,0,0.12)',
            flex: '0 0 auto',
            display: 'flex',
            padding: '12px 29px 21px',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={closeHandler}
            style={{
              width: '100px',
              height: '36px',
              borderRadius: '4px',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              color: 'rgba(0, 0, 0, 0.38)',
            }}
          >
            BACK
          </Button>

          <Button
            style={{
              marginLeft: '5px',
              width: '100px',
              height: '36px',
              background: '#335AFB',
              boxShadow: '0px 0.3px 0.5px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.2)',
              borderRadius: '4px',
              color: 'white',
            }}
            onClick={sendLinkHandler}
          >
            START
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default StartInstantMeetingDialog;
