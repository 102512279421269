import React from 'react'
import './responsive.css'

const PrivacyPolicy = () => {
  return (
    <div>
      <div className='containerMargin'>
        <h1>
          PRIVACY POLICY
        </h1>

        <h2>1. Introduction: </h2>

        <h4>Bird AI Dynamics Private Limited (Bird AI or Bird AI Platform) is committed to safeguarding the
          privacy of users (“Users/Individual/ Entity/Enterprise/Educational Institute” or “you”). </h4>


        <p>
          This privacy policy explains our policy towards clear and transparent use, collection, disclosure and
          management of your information to our subsidiary and/or affiliate(s) (collectively referred to as the "Company"
          or “We, Us, Our”).
        </p>

        <p>The information provided by you will help us serve you better and provide you with a better product.<br />
          This Privacy Policy does not apply to any third-party applications or software that integrates with the Services
          through the <b>Bird AI Dynamics</b> ("Third Party Services"), or any other third party products, services or
          businesses. We encourage you to review this Privacy Policy to stay informed. You will be deemed to have
          accepted the changes in any revised Privacy Policy by your continued use of the Application.</p>


        <h2>2. What is "Personal Data"?</h2>

        <p>Any information which identifies You, including information such as your name, phone number, email address, company name (if
          applicable) and specific information about you as a user (and not any other person) that we either hold to. Some data falls into a category
          known as Special Category Data(personal) where usually the processing of that data can only be undertaken with your consent. </p>

        <h2>3. What is Special Category data? </h2>

        <p> Special Category Data(personal) is data that identifies particular characteristics and where your privacy deserves special protection. Personal
          Data Revealing your ethnicity, religious beliefs, trade union membership, political opinion and Data Concerning a person’s health and
          sexual orientation are examples of Special categories of data. </p>

        <h2> 4. What data do we collect? </h2>

        <p>
          We only collect and hold information relevant to the purpose of our dealings with you lawfully. Processing such information is subsequently
          carried out by us in pursuance of our legitimate interest, to enter into a valid contract and subsequent provision of services under the
          contract. We collect, hold and process such information in the way we describe within the policy. We make individuals aware of our policy
          about our holding and any processing of that person's information for the purpose of collection of the Data concerned.
        </p>

        <p style={{ marginBottom: 0 }}>List of Personal Information we may collect: </p>
        <ul style={{ marginTop: 0 }}>
          <li> Personal information includes name, username, e-mail address, and telephone number.</li>
          <li> Age, gender and date of birth, etc.</li>
          <li> Name of organization, postal address, country or area</li>
          <li> Access log files, cookies, and other information collected upon your visit to this website. </li>
        </ul>


        <p>
          You may have been re-directed to this Policy through a weblink we provided you intend to make you aware of our policy in connection to
          our holding and processing of your data at the point of collection of such personal information.
        </p>



        <h2>5. How we collect Personal information </h2>


        <p>We may collect your Personal Information for the purpose you make an inquiry about Bird AI Dynamics Products. This includes any
          telephone inquiries you may make any inquiries that we receive at an event including marketing activities we conduct. Certain information
          may already be held by us once you make contact if you’ve researched/inquired about our business and product but until you contact us will
          have been held on an anonymized basis.</p>
        <p>
          By providing us with your business card or completing an inquiry/contact form offered to you by us we’ll communicate with you assuming
          that you simply allow us that we do so. We’ll invite you to consent to our retaining of your information and therefore the consent you’ll
          provide could also be withdrawn at any time by contacting: support@birdai.com
        </p>
        <p>
          Initially, your personal details are going to be processed in these circumstances on the idea that it is in our legitimate interest to interact with
          individuals and organizations who are interested in BIRD AI products and services and what we do within the education domain.
          Thereafter we will only retain your personal information if we are entitled to do so based upon you have freely consented to further
          communication otherwise you agree to pilot the BIRD AI Platform.
        </p>



        <p>
          When it is communicated to us that you simply wish to receive information from us we will accordingly only retain your information on a
          basis that entitles us to thereafter hold and use that Personal Information with an express consent provided by you to us holding the
          information and for every purpose that we then shall use that information. If you sign up for any training event or conference we also require
          personal information so that we can communicate with you and prepare for your involvement in the event.
        </p>


        <h2>
          6. Why we collect personal information
        </h2>

        <p style={{ fontSize: "20px", marginBottom: "0" }}>
          {" a) To provide better service"}
        </p>

        <p style={{ marginTop: "0" }}>
          We collect personal information from you to understand your needs to serve you better. In connection with your subscription to the BIRD
          AI platform you may, for instance, use the services to grant and take away access to, assign roles and configure settings, create student/users
          profiles, modify, export, share and remove your Data and otherwise apply its policies to the Services. E.g.: for purposes associated with the
          activation, billing, support, updates, troubleshooting and resolving problems, repair & maintenance, deactivation, and answering service
          requests.
        </p>

        <p style={{ fontSize: "20px", marginBottom: "0" }}>{"b) To communicate with you"}</p>

        <p style={{ marginTop: "0" }}>
          If you indicate now or at a future time that you consent to our doing so, we will maintain the personal information you provide us with in
          order that we may:
        </p>

        <ul>
          <li>Contact you to communicate to you of services and products we will be offering</li>
          <li> Upon receiving an inquiry send you requested information about our services or products; or</li>
          <li> Contact you with updates on the further development of any products and/or services you’ve got expressed interest in.</li>
        </ul>

        <br />

        <p>We consider that we may use your personal data and our processing of that Personal Data will accordingly arise within the course of
          pursuing our legitimate interests as a business engaged in and committed to the success of the education sector.<br />
          Our emails provided on the basis above will always have an opt-out option in respect of further communications.</p>
        <h2>{"c) To provide better user management "}</h2>

        <p>
          Information collected in connection with the registration of subscription/user account/service –Educational Institution/ Enterprises and/or Individuals.<br /><br />
          We will collect and process the following data about (Educational Institution/ Enterprises and/or Individuals) which may include any staff engaged by yourself who utilize the BIRD AI Platform in connection with registration/accounts management.<br />
          We may collect and process the following data provided by you about your class/students/subscribers/audience/group of people:<br /><br />Student personal data, Example: Date of birth, email address, Name, gender, User name, and other personal information to create a student profile, Parent details including occupation and education, and Health information. <br />Subscribers/audience/group of people personal data, Example: Date of birth, email address, Name, gender, User name, and other personal information to create a profile,.<br />
          The information will be collected either from the person to whom you have granted administrative rights on behalf of the educational institution/enterprise or directly from you in case of the individual as the case may be. The information is collected within the Platform or as a result of any correspondence with us by phone, e-mail or otherwise. <br /><br />
          Student personal data may be collected. Such information may be collected upon parental/guardian consent taken by the education institution/educators/teachers/individual as per the Terms of use of Service.
        </p>


        <h2>{"d) To provide a personalised experience"}</h2>
        <p>
          The BIRD AI Platform enables educational institutions, enterprises and/or individuals to use the services on the Platform available to their class/students/subscribers/audience/group of people based on the Product subscription. The Bird AI Platform can only be accessed by the students if the educational institution or individual educator/teacher has approved the student with the appropriate login or ID access code and the student may be required to enter his name, username, password, and date of birth, etc.<br />
          The educational institution or an individuals to ensure that any student to whom they intend to provide with login or an ID access code (including, students under the age of 16) has, before any such login or code is provided, obtained all necessary consents and permissions (from a parent and/or guardian) to use the Platform and for us to collect and process any user data.<br />
          You are also reminded that upon a student attaining the age of 16 there is a legal requirement to retake consent directly from the student and we require the relevant School to secure that consent.<br />
          As an individual(educator/teacher) you acknowledge and warrant that you have and will provide all requisite authority from, and on behalf of, the student including where relevant the parent/guardian of the student and on behalf of the educational institution to (i) for you to provide the Platform and make it available to your student; (ii) allow your student to use the Platform and, (iii) and to make any User Data available to us in connection with the use of the Platform on the terms set out in our terms and conditions and this privacy policy.<br />
          <br />We believe that you, the user has prior permissions to use the analytics feature for the classroom. In the case of students below 16 years of age for the above purpose, you shall procure written permission from Guardian /Parents as mentioned in EULA.<br /><br />Enterprise and/or individual:<br />The BIRD AI Platform enables enterprise and/or individuals to use the services on the Platform available to their subscribers/audience/group of people based on the subscription agreement. The Bird AI Platform can only be accessed by the such subscribers/audience/group of people with the appropriate login or ID access code. The subscribers/audience/group of people may be required to enter his name, username, password, and date of birth, etc.<br />Enterprise and/or individual to ensure that their subscribers/audience/group of people to whom they intend to provide with login or an ID access code (including, students under the age of 16) has, before any such login or code is provided, obtained all necessary consents and permissions (from a parent and/or guardian) to use the Platform and for us to collect and process any user data. 
        </p>


        <h2 style={{ marginBottom: "0" }}>
            {"e) Technical information"}
          </h2>
        <p style={{ marginTop: "0" }}>
            We will automatically collect the subsequent information in connection with the your (Enterprise users, individual educator/Teacher, Users and subscribers/audience/group of people) use of the Bird AI Platform.

          </p>
          
          
          <ul>
            <li> Information concerning Login, Internet protocol (IP address); to connect the computer to the internet, browser Version, Time-Zone setting. Browser, plug-in (types & Version) operating system, and the user device.
            </li>
            <li>
              Information concerning your visit including the date and time of access, the duration of your visit to the Platform, details of the Platform features which were utilized during the visit and for how long, the location from which the Platform was accessed page response times.
            </li>
            <li>
              Download errors, page interaction information (such as scrolling, clicks, and mouse-overs).

            </li>
            <li>
              Much of this information are going to be collected using cookies. For details of cookies and their use in connection with the Platform please see our Cookies Policy.

            </li>
          </ul>

          
          
                            
        <h2>
            7.Data Retention
          </h2>

          <p>
            Where we keep in touch with you to give you information about BIRD AI products and services we ask you from time to time to check the Personal information that we hold and we will be glad to update that in accordance with your wishes. For Institutes Educator/teacher and students involved with the BIRD AI  Platform, our agreement requires the School to provide us with information concerning Educator/teacher and Student (users) when any of that information changes (such as a joining of a new Student or a change in the identity of a member of staff to be provided with access to the Platform).<br />
            We will retain your data in accordance with your consent, or in accordance with any applicable terms in the EULA Agreement and your use of any functionality(service), and as required by applicable law. You can apply  different settings to messages, files or other types of your personal information. The deletion of such Personal information and other use of the Service may result in the deletion and/or de-identification of certain associated Other Information. For more detail, please contact compliance@birdai.com. <br/>
    We may retain other information pertaining to you for as long as necessary for the purposes described in this Privacy Policy.     
                       </p>

            <h2>8. Keeping your Personal information up to Date</h2>
            <p>
              Where we keep in touch with you to give you information about BIRD AI and our services we ask you from time to time to check the Personal information that we hold and we will be glad to update that in accordance with your wishes. For students and staff involved with the BIRD AI Platform, our agreement with the school requires the them to provide us with information concerning Staff and Users when any of that information changes (such as a new Student to be provided with access to the Platform or a change in the identity of a member of staff).
            </p>

            <h2>
              9. Unsubscribe and Right to have Personal information Deleted
            </h2>
            <p>
          Where you have consented to contact being made by us with you for any of the purposes mentioned above but you no longer wish to receive emails from us or wish to change your selections as to how or what our contact with you should be please email us at the <h2 style={{display:"inline",color:"blue"}}>  support@birdai.com</h2> with “Email Unsubscribe” in the subject heading. We will act upon this as soon as practicable. You may also wish to write to us at the address given in at the beginning of this Policy.<br/><br/>
          If you choose to unsubscribe from our mailing lists we will only make contact with you again in circumstances where you have consented to our being re-provided with your information and that contact is authorized by you.
        </p>   
            <h2>
              10. PARENTAL CONSENT
            </h2>
            <p>
              If you are a parent/guardian and believe that your child have provided us with personal information, please contact us. We do not knowingly collect personal information from users under the age of 16 years directly. In such cases, we will delete personal information from our servers upon communication by you.
            </p>
            <h2>
              11. COPPA and GDPR compliant
            </h2>
            <p>
              We, contractually obligate schools/educators/teachers to obtain parental consent that our services can be used in compliance with COPPA, GDPR and any law regulates the use of online service by an users under the age of 16.
            </p>

            <h2>
              12. SECURITY OF YOUR INFORMATION
            </h2>

            <p>
              BIRD AI uses appropriate, physical, managerial and technical measures to help protect your personal information. While we have taken reasonable steps to secure the personal information provided by you. Please be aware that despite our best efforts, no security measures are absolutely secure, and no method of data transmission can be guaranteed against any interception or other type of misuse.
            </p>

            <h2>
              13. FOR RESIDENTS OF EEA
            </h2>

            <p>
              We ensure to be consistent with the provision of GDPR, Hence, as rights framed by the legislation for residents of Europe Economic Area allows them to access, correct, update, restrict usage, or erase personal information. Users can do so at any time by writing to us at: <b style={{color:"blue"}}>compliance@birdai.com</b>. The information includes details of the purposes, categories of personal data and the recipients of the personal data. You can revoke such consent at any time or restrict such consent by contacting us at <b style={{color:"blue"}}>compliance@birdai.com.</b>
      </p>
        
            <h2>
              14. Cookie Policy
            </h2>
            <p>
              A cookie is a type of small amount of data used as an anonymous unique identifier that enable the device to be identified by our system. In our website we allow cookies to be configured at your preference to collect information and to improve our Service. You will have the option to opt-in. You may disable cookies at any time by modifying browser preference. You can learn more details about cookies from your browser's "Help" menu. We will not use cookies or collect any personal information for any purpose other than those stated in this Privacy policy. 
           </p>

              <h2>
                15. Use of YouTube API:
              </h2>

              <p>
                Our Content/Resource features use the YouTube API for searching and embedding videos, We do not ask, access to your private information data on YouTube, as such, by agreeing to These Terms you also agree to YouTube's Terms of Service (https://www.youtube.com/t/terms).
              </p>

              <h2>
                16. Other important Privacy options
              </h2>

              <p>
                You have the right to choose “opt-out “ option to stop marketing communications we may send you periodically. You can exercise your right to restrict by clicking on the "opt-out" option in the marketing emails.
              </p>

              <h2>
                17. Promotional events, Emails, and Communications
              </h2>

              <p>
        This website operates an email newsletter and/or notice for promotional and event programs to subscribers about products and services and promotional activities of this website. By clicking on Sign Up, you hereby agree to subscribe to the aforesaid program and to receive, from time to time, the aforesaid email newsletters and/or notices.  Should you wish to do so, you can unsubscribe through an online-automated process contained in the aforesaid email newsletters and/or notices.<br/><br/>
                
        If you no longer wish to receive emails or other communic a tions from us, you may opt-out by changing preferences at the time you register your account with the Application or logging into yur account at any time change settings and updating your preferences.
              </p>

              <h2>
                18. Policy Updates
              </h2>

              <p>
                As and when required, we may update this policy. This website may revise the privacy policy when necessary, and the revisions will be posted on this website. We may update this Policy from time to time and comply with the relevant local privacy laws and regulations. When we make significant changes, we will make an announcement on our official website. If you do not agree with any part of this Policy or any amended content, you may discontinue the use of the product immediately. If significant changes are made we will notify all our users. By using our services, you consent to this policy. 
              </p>

                <h2>CONTACT US </h2>

                <h4>
                  Please contact us regarding Privacy policy:
                </h4>
                <p style={{fontSize:"16px"}}> 
                  <b>Registered Offices:</b><br />
                  BI RD AI DYNAMICS PRIVATE LIMITED <br/> B 1106, iThum Tower Plot No. A 40, Sector 62 Noida Gautam Buddha Nagar UP 201301 INDIA <b style={{color:"blue"}}>support@birdai.com</b> <br/><br/>
  <b>Product Support :</b> < br />BIRD AI DYNAMICSAI PRIVATE LIMITED<br />2nd Floor, Plot No -1290, 17th Cross, 5th Main, Sector - 7, HSR Layout, BENGALURU, KARNATAKA 560102, INDIA<br/> <b style={{color:"blue"}}>support@birdai.com </b>
    </p>
           </div >
      </div>    
                
                
      )  }
            export default PrivacyPolicy                                                            