import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import combinedReducer from './reducers';
import thunk from 'redux-thunk';

const middleWare = [thunk];

const store =
  process.env.NODE_ENV === 'development'
    ? createStore(
        combinedReducer,
        undefined,
        composeWithDevTools(
          applyMiddleware(...middleWare),
          // window.__REDUX_DEVTOOLS_EXTENSION__ &&
          //   window.__REDUX_DEVTOOLS_EXTENSION__()
        ),
      )
    : createStore(combinedReducer, compose(applyMiddleware(...middleWare)));


export default store;
