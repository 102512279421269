import { Button } from '@material-ui/core';
import React from 'react';
import { StyledDialogContainer } from '../../../common/dialog/styled-dialog/container';
import { usePromptRevokeAccessDialogStyles } from './component.style';

const PromptRevokeAccessDialog = (props) => {
  const { isOpenDialog, closeDialog, title, onClickRevokeAccess } = props;
  const classes = usePromptRevokeAccessDialogStyles();

  return (
    <StyledDialogContainer open={isOpenDialog} onClose={closeDialog} title={title} hideFooter>
      <div className={classes.container}>
        <Button variant="outlined" onClick={onClickRevokeAccess} className={classes.button}>
          Revoke
        </Button>
        <Button variant="outlined" onClick={closeDialog} className={classes.button}>
          Cancel
        </Button>
      </div>
    </StyledDialogContainer>
  );
};

export default PromptRevokeAccessDialog;
