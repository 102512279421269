import ACTION_TYPE from "./type.action";

export const updateMousePosition = ({ x, y,pageId }) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.UPDATE_MOUSE_POSITION,
    payload: { x, y , pageId},
  });
};

/**
 * @param {{ userName: string, mousePosX: number, mousePosY: number }[]} positions 
 * @returns 
 */
export const updateCursor = (positions) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.UPDATE_CURSORS,
    payload: positions
  })
}