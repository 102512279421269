import { Drawer, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    color: ' var(--black-87, rgba(0, 0, 0, 0.87))',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    borderRadius: '38px',
    background: 'var(--black-12, rgba(0, 0, 0, 0.12))',
    padding: ' 10px 20px',
    whiteSpace: 'nowrap',
    userSelect: 'none',
  },
  activeButtonStyle: {
    color: ' var(--black-87, rgba(0, 0, 0, 0.87))',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    borderRadius: '38px',
    background: '#335AFB',
    padding: ' 10px 20px',
    whiteSpace: 'nowrap',
    userSelect: 'none',
  },
  buttonsContainer: {
    // display: 'flex',
    gap: '10px',
    paddingTop: '5px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  label: {
    color: 'var(--black-12, rgba(0, 0, 0, 0.12))',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
  },
  root: {
    padding: '36px 16px',
  },
}));
const MobileViewFilters = ({
  open,
  onClose,
  sortByData = [],
  showFilterBy,
  filterByData,
  activeSortState,
  activeFilterState,
}) => {
  const classes = useStyles();

  const clickAction1 = (actionCall) => {
    actionCall();
    onClose();
  };

  const clickAction2 = (actionCall) => {
    actionCall();
    onClose();
  };

  const activeButtonHandler = (label) => {
    if (label === activeSortState) {
      return classes.activeButtonStyle;
    } else if (label === activeFilterState) {
      return classes.activeButtonStyle;
    } else {
      return classes.buttonStyle;
    }
  };

  useEffect(() => {
    activeButtonHandler();
  }, [activeSortState, activeFilterState]);

  return (
    <>
      <Drawer anchor="bottom" open={open} onClose={onClose}>
        <div className={classes.root}>
          <div className={classes.label}>SORT BY</div>
          <div className={classes.buttonsContainer}>
            {sortByData.map((item) => (
              <div
                className={activeButtonHandler(item.label)}
                onClick={() => clickAction1(item.onClick)}
              >
                {item.label}
              </div>
            ))}
          </div>

          {showFilterBy && (
            <div style={{ marginTop: '16px' }}>
              <div className={classes.label}>FILTER BY</div>
              <div className={classes.buttonsContainer}>
                {filterByData.map((item) => (
                  <div
                    key={item.label}
                    className={activeButtonHandler(item.label)}
                    onClick={() => clickAction2(item.onClick)}
                  >
                    {item.label}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default MobileViewFilters;
