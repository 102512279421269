import React, { Suspense, useCallback, useEffect } from 'react';
import Layout from '../IWB/Layout/layout';
import MyTeamIcon from '../../assets/icons/myTeamIcon';
import ControllerIcon from '../../assets/icons/controllerIcon';
import { CircularProgress, Hidden, makeStyles, useMediaQuery } from '@material-ui/core';
import { useState } from 'react';
import MainSideBarItem from './MainSideBarItem';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import Launcher from './Launcher';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { Drawer, Badge } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ControllerTopbar from './ControllerTopbar';
import SettingIcon from '../../assets/icons/settingicon';
import helpSVG from '../../assets/img/help.svg';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { clearUserData, setUserId } from '../../Utils/authentication-access';
import userService from '../../services/user.service';
import ProfileDialog from '../../components/sideBar/UserProfile/ProfileDialog';
import LayoutWrapper from '../IWB/Layout/LayoutWrapper';
import deviceService from '../../services/device.service';
import { useNotificationDispatcher } from '../../store/dispatcher/useNotificationDispatcher';
import { useAuth } from '../../Utils/auth/useAuth';
import { useMicrosoftAuth } from '../../Utils/microsoft/useMicrosoftAuth';
import ProductLogoFull from '../../assets/icons/ProductLogoFull';
import SmartNotesPage from '../../pages/smart-notes';
import LearnAIPage from '../../pages/learn-ai';
import Spaces from '../../components/spaces/Spaces';
import HomeContainedIcon from '../../assets/icons/sidebar/homeContainedIcon';
import HomeOutlinedIcon from '../../assets/icons/sidebar/homeOutlinedIcon';
import CanvasOutlinedIcon from '../../assets/icons/sidebar/canvasOutlinedIcon';
import CanvasContainedIcon from '../../assets/icons/sidebar/canvasContainedIcon';
import FilesOutlinedIcon from '../../assets/icons/sidebar/filesOutlinedIcon';
import FilesContainedIcon from '../../assets/icons/sidebar/filesContainedIcon';
import Files from '../../components/files/Files';
import { ENVIRONMENT } from '../../config';

const helpCenterLink = 'https://www.birdai.com/support/';

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 3,
    top: 3,
    padding: '0 4px',
    fontSize: '10px',
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: '100',
  },
  secondContainer: {
    display: 'flex',
    height: '100%',
  },
  contentDev: {
    backgroundColor: '#EDEFF1',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    height: 'calc(100% - 5rem)',
  },
  loadingGrid: {
    height: '100%',
    width: '100%',
    display: 'grid',
    gridRowGap: '10px',
    gridTemplateRows: 'auto auto',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
  },
  controllerSidebarMain: {
    display: 'flex',
    width: '53px',
    height: '100%',
    zIndex: '9998',
    position: 'absolute',
    top: '52px',
  },
  controllerSidebarSubContainer: {
    width: '53px',
    textAlign: 'center',
    height: '100%',
    position: 'fixed',
    top: '52px',
    background: '#fff',
    borderRight: '1px solid #ddd',
  },
  sideBarfooter: {
    borderBottom: '1.5px solid #ddd',
    margin: '0px 4px',
    height: '12px',
  },
  mobileMenuFooter: {
    display: 'grid',
    gridGap: '27px',
    paddingLeft: '24px',
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const Controller = () => {
  const [iconMyTeams, setIconMyTeams] = useState(false);
  const [iconController, setIconController] = useState(false);
  const [iconFiles, setIconFiles] = useState(false);
  const [isNotesActive, setIsNotesActive] = useState(false);
  const [isLearnAIActive, setIsLearnAIActive] = useState(false);
  const history = useHistory();
  const { handleLogout } = useAuth();
  const location = useLocation();
  const classes = useStyles();
  const [userData, setUserData] = useState(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [workspaceAnchorEl, setWorkspaceAnchorEl] = React.useState(null);
  const sortWorkSpacesOption = Boolean(workspaceAnchorEl);
  const [userName, setUserName] = useState('');
  const [isProfileDialog, setIsProfileDialog] = useState(false);
  const { dispatchSetSnackbar } = useNotificationDispatcher();
  const { initMicrosoftUser } = useMicrosoftAuth();

  const defaultPagesActive = useCallback(() => {
    setIconMyTeams(false);
    setIconController(false);
    setIsNotesActive(false);
    setIsLearnAIActive(false);
    setIconFiles(false);
  }, [setIconMyTeams, setIconController, setIsNotesActive]);

  const iconMyTeamsClick = useCallback(() => {
    defaultPagesActive();
    setIconMyTeams(true);
    setIsMenuOpen(false);
    // history.push('/controller/Wbflow');
    history.push('/controller/spaces');
  }, [defaultPagesActive, setIconMyTeams, history]);

  const iconControllerClick = useCallback(() => {
    defaultPagesActive();
    setIconController(true);
    setIsMenuOpen(false);
    history.push('/controller/launcher');
  }, [defaultPagesActive, setIconController, history]);

  const smartNotesClickHandler = useCallback(() => {
    defaultPagesActive();
    setIsNotesActive(true);
    history.push('/controller/smart-notes/notebooks');
  }, [defaultPagesActive, history]);

  const iconFilesClick = useCallback(() => {
    defaultPagesActive();
    setIconFiles(true);
    history.push('/controller/files');
  }, [defaultPagesActive, history]);

  const setOption = useCallback(() => {
    switch (location.pathname) {
      // case '/controller/Wbflow':
      case '/controller/spaces':
        iconMyTeamsClick();
        break;
      case '/controller/launcher':
        iconControllerClick();
        break;
      default:
        break;
    }
    if (location.pathname.startsWith('/controller/smart-notes')) {
      defaultPagesActive();
      setIsNotesActive(true);
    }
    if (location.pathname.startsWith('/controller/learn-ai')) {
      defaultPagesActive();
      setIsLearnAIActive(true);
    }
  }, [location, iconMyTeamsClick, iconControllerClick]);

  useEffect(() => {
    const init = async () => {
      setOption();
      getUserDetails();
    };
    init();
    initMicrosoftUser();
  }, []);

  const handleMobileSideMenuOpen = useCallback(() => setIsMenuOpen(true), []);

  const getUserNameHandler = (info) => {
    setUserName(info);
  };

  const helpCenterHandleClick = (event) => {
    window.open(helpCenterLink, '_blank');
  };

  const onClickLogout = async () => {
    await handleLogout();
  };

  const disconnectDeviceHandler = async () => {
    try {
      const response = await deviceService.unPairDevice();
      dispatchSetSnackbar({ message: 'Device disconnected' });
    } catch (error) {
      console.log('deviceId error', error.response.data.message);
      dispatchSetSnackbar({ message: error.response.data.message });
    }
    return;
  };

  const getUserDetails = async () => {
    const response = await userService.getUserDetails();
    setUserData(response?.data?.user);
    setUserId(response?.data?.user.id);
    getUserNameHandler(response?.data?.user.name);
    return response?.data?.user;
  };

  const profileDialogHandler = () => {
    setIsMenuOpen(false);
    setIsProfileDialog(true);
  };

  const onCloseUserProfileDialog = () => {
    setIsProfileDialog(false);
  };

  return (
    <div>
      {/* mobile view burger menu */}
      <Drawer
        anchor="left"
        open={isMenuOpen}
        style={{
          width: '100%',
          zIndex: '9999',
        }}
        onClose={() => {
          setIsMenuOpen(false);
        }}
        PaperProps={{
          style: {
            position: 'fixed',
            bottom: 0,
            top: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <div className={classes.headerContainer}>
          <div style={{ paddingLeft: '8px' }}>
            <ProductLogoFull />
          </div>
          <CloseRoundedIcon
            style={{
              margin: '23px 26px',
            }}
            fontSize="medium"
            onClick={() => {
              setIsMenuOpen(false);
            }}
          />
        </div>
        <div style={{ flex: '1' }}>
          <div style={{ width: '100vw' }}>&nbsp;</div>
          <MainSideBarItem
            // Icon={ControllerIcon}
            icon={
              iconController ? (
                <HomeContainedIcon fill=" rgba(51, 90, 251, 1)" />
              ) : (
                <HomeOutlinedIcon stroke="#000" />
              )
            }
            title="Room"
            active={iconController}
            onClick={iconControllerClick}
          />
          <MainSideBarItem
            // Icon={MyTeamIcon}
            icon={
              iconMyTeams ? (
                <CanvasContainedIcon fill=" rgba(51, 90, 251, 1)" />
              ) : (
                <CanvasOutlinedIcon stroke="#000" />
              )
            }
            title="Canvas"
            active={iconMyTeams}
            onClick={iconMyTeamsClick}
          />
          {ENVIRONMENT === 'development' && (
            <MainSideBarItem
              icon={
                location.pathname === '/controller/files' ? (
                  <FilesOutlinedIcon stroke="#fff" />
                ) : iconFiles ? (
                  <FilesContainedIcon fill=" rgba(51, 90, 251, 1)" />
                ) : (
                  <FilesOutlinedIcon stroke="#000" />
                )
              }
              iconController
              title="Files"
              active={iconFiles}
              onClick={iconFilesClick}
            />
          )}

          {/* 
          INFO: Note Sidebar
          <MainSideBarItem
            // Icon={notesIcon}
            icon={
              isNotesActive ? (
                <FilesContainedIcon fill=" rgba(51, 90, 251, 1)" />
              ) : (
                <FilesOutlinedIcon stroke="#000" />
              )
            }
            title="Note"
            active={isNotesActive}
            onClick={smartNotesClickHandler}
            style={{ marginTop: 6 }}
          /> */}
        </div>
        <div className={classes.mobileMenuFooter}>
          {/* TODO: feature implementation
          <div style={{ display: 'flex', alignItems: 'center', gap: '13px', height: '30px' }}>
            <SettingIcon />
            <div
              style={{
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '20px',
                lineHeight: '23px',
                color: '#000000',
              }}
            >
              Settings
            </div>
          </div> */}
          <div
            onClick={helpCenterHandleClick}
            style={{ display: 'flex', alignItems: 'center', gap: '13px', height: '30px' }}
          >
            <div>
              <img src={helpSVG} alt="help" style={{ width: '24px' }} />
            </div>
            <div
              style={{
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '20px',
                lineHeight: '23px',
                color: '#000000',
              }}
            >
              Help & Support
            </div>
          </div>
          <div
            onClick={profileDialogHandler}
            style={{ display: 'flex', alignItems: 'center', gap: '13px', height: '30px' }}
          >
            <div>
              <AccountCircleOutlinedIcon
                fontSize="medium"
                style={{ color: 'rgba(0, 0, 0, 0.4)', marginTop: '5px' }}
              />
            </div>
            <div
              style={{
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '20px',
                lineHeight: '23px',
                color: '#000000',
              }}
            >
              Profile
            </div>
          </div>

          <div
            onClick={onClickLogout}
            style={{ display: 'flex', alignItems: 'center', gap: '13px', height: '30px' }}
          >
            <div>
              <ExitToAppOutlinedIcon
                fontSize="medium"
                style={{ color: 'rgba(0, 0, 0, 0.4)', marginTop: '5px' }}
              />
            </div>
            <div
              style={{
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '20px',
                lineHeight: '23px',
                color: '#000000',
              }}
            >
              Logout
            </div>
          </div>
          <div style={{ height: '50px' }}>&nbsp;</div>
        </div>
      </Drawer>

      <Suspense
        fallback={
          <div className={classes.loadingGrid}>
            <CircularProgress size={50} thickness={2} />
            <div className="loadingText">Loading</div>
          </div>
        }
      >
        <Switch>
          <Route
            path={'/controller/Wbflow'}
            render={(props) => <LayoutWrapper history={props.history} />}
          />
          <Route
            path={'/controller/spaces'}
            render={(props) => (
              <Spaces
                history={props.history}
                userName={userName}
                handleMobileSideMenuOpen={handleMobileSideMenuOpen}
              />
            )}
          />
          <Route
            path={'/controller/files'}
            render={(props) => (
              <Files
                history={props.history}
                userName={userName}
                handleMobileSideMenuOpen={handleMobileSideMenuOpen}
              />
            )}
          />

          <Route
            path={'/controller/launcher'}
            render={(props) => (
              <Launcher
                history={props.history}
                userName={userName}
                handleMobileSideMenuOpen={handleMobileSideMenuOpen}
              />
            )}
          />
          <Route
            path={'/controller/smart-notes'}
            render={() => <SmartNotesPage handleMobileSideMenuOpen={handleMobileSideMenuOpen} />}
          />
          <Route
            path={'/controller/learn-ai'}
            render={() => <LearnAIPage handleMobileSideMenuOpen={handleMobileSideMenuOpen} />}
          />
        </Switch>
      </Suspense>

      {/** Profile Dialog */}
      <ProfileDialog
        userName={userData?.name}
        open={isProfileDialog}
        picture={userData?.imageUrl}
        onClose={onCloseUserProfileDialog}
        callUserDetails={getUserDetails}
        userEmail={userData?.email}
      />
    </div>
  );
};

export default Controller;
