import React from "react";
import StrokeEraseImg from '../assets/img/stroke_erase.svg';
import LineImg from '../assets/img/line.svg';
import RectangleImg from '../assets/img/rect.svg';
import TriangleImg from '../assets/img/triangle.svg';
import CircleImg from '../assets/img/circle.svg';
import HexagonImg from '../assets/img/hexagon.svg';

class ShapesSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }

        }
 
 

  render() {
   
   

    return (
        <div className="shapes-popup">
        <div className="shapes-grid" >
            <div></div>
            <div className="shape-grid" onClick={() => this.props.selectShape('line')}>
            <img src={LineImg}></img>
            <div className="title" > Line</div>
            </div>
            <div className="shape-grid" onClick={() => this.props.selectShape('triangle')} >
            <img src={TriangleImg}></img>
            <div className="title" > Triangle</div>
            </div>
            <div className="shape-grid" onClick={() => this.props.selectShape('rectangle')} >
            <img src={RectangleImg}></img>
            <div className="title" > Rectangle</div>
            </div>
            <div className="shape-grid" onClick={() => this.props.selectShape('circle')} >
            <img src={CircleImg}></img>
            <div className="title" > Circle</div>
            </div>
            <div className="shape-grid" onClick={() => this.props.selectShape('hexagon')} >
            <img src={HexagonImg}></img>
            <div className="title" > Hexagon</div>
            </div>
            
            
 
        </div>
       </div>
    );
  }
}

export default ShapesSelect;

