import moment from 'moment';

export const getTimeForComparision = (dateTime, datePrefix = '2022-01-01') => {
  const arr = dateTime.split('T');
  arr[0] = datePrefix;
  return arr.join('T');
};

export const updateDatePortionInISOString = (dateTime, datePrefix) => {
  const arr = dateTime.split('T');
  arr[0] = datePrefix;
  return arr.join('T');
};

export const isBeforeOnlyByTime = (aDateTime, bDateTime) => {};

/**
 * update the date section to today's date
 * Note:
 * for customizable date, use getTimeForComparision;
 * @param {string} dateTime
 * @returns
 */
export const getWithTodaysDate = (dateTime) => {
  const date = moment().format('YYYY-MM-DD');
  return getTimeForComparision(dateTime, date);
};

export const checkIsYetToOccur = (aDateTimeString, bDateTimeString) => {
  return moment(aDateTimeString).isBefore(moment(bDateTimeString));
};

export const checkIsYetToStart = (aDateTimeString, bDateTimeString) => {
  return checkIsYetToOccur(aDateTimeString, bDateTimeString);
};
