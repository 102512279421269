import React, { createRef, useEffect, useState } from 'react';
import PubSub from 'pubsub-js';
import config from '../../../config';
import { EVENTS } from '../../../constants/events';
import useMediaServerDispatcher from '../../../store/dispatcher/useMediaServerDispatcher';
import { getAnonymousUserToken, getFlowId, getToken } from '../../../Utils/authentication-access';
import { useURL } from '../../../Utils/url/useURL';
import { useNotificationDispatcher } from '../../../store/dispatcher/useNotificationDispatcher';

const mediasoup = require('mediasoup-client');
const socketClient = require('socket.io-client');
const socketPromise = require('./lib/socket.io-promise').promise;

const hostname = config.MEDIA_SERVER_URL;

let device;
let socket;
let transport;
let producer;
let canSubscribe = false;
let isDeviceLoaded = false;
let screenshareStream;
let microphoneStream;
let screenshareTransportId;
let microphoneTransportId;
let screenshareProducerId;
let microphoneProducerId;
let screenshareTrack;
let clonedScreenshareTrack;
let microphoneTrack;
let screenshareTransport;
let transportsMap = new Map();

const $ = document.querySelector.bind(document);
const $fsPublish = $('#fs_publish');
const $fsSubscribe = $('#fs_subscribe');
const $btnConnect = $('#btn_connect');
const $btnWebcam = $('#btn_webcam');
const $btnScreen = $('#btn_screen');
const $btnSubscribe = $('#btn_subscribe');
const $chkSimulcast = $('#chk_simulcast');
const $txtConnection = $('#connection_status');
const $txtWebcam = $('#webcam_status');
const $txtScreen = $('#screen_status');
const $txtSubscription = $('#sub_status');
let $txtPublish;

// $('#btn_connect').addEventListener('click', connect);
// $('#btn_webcam').addEventListener('click', publish);
// $('#btn_screen').addEventListener('click', publish);
// $('#btn_subscribe').addEventListener('click', subscribe);

if (typeof navigator.mediaDevices?.getDisplayMedia === 'undefined') {
  // $('#screen_status').innerHTML = 'Not supported';
  // $('#btn_screen').disabled = true;
}

/**
 * roomId: the room id
 * willHandleConnection: if true, the user will handle the connection, and call connect method explicitly
 * onlyOutgoing: if true, the user will only publish, and not subscribe
 * sendScreenshareStoppedInfoToDevice: if true, the user will send screenshare stopped info to the device
 *
 * @param {{ roomId: string, willHandleConnection: boolean, onlyOutgoing: boolean, sendScreenshareStoppedInfoToDevice: () => void) }} data
 * @returns
 */
export const useMediaClient = (options = {}) => {
  const { sendScreenshareStoppedInfoToDevice } = options;
  const [showRemoteVideo, setShowRemoteVideo] = useState(false);
  const [isScreenShared, setIsScreenShared] = useState(false);
  const { dispatchSetScreenshareStarted, dispatchSetScreenshareStopped } =
    useMediaServerDispatcher();
  const { dispatchShowSimultaneousLimitExhaustedDialog, dispatchShowFeatureLockedDialog } =
    useNotificationDispatcher();
  const [isMuted, setIsMuted] = useState(true);
  let roomId;
  const [subscribedData, setSubscribedData] = useState([]);
  const [localSubscribedData, setLocalSubscribedData] = useState([]);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const { getParam } = useURL();

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    console.log('isOnline', isOnline);

    // Update network status
    const handleStatusChange = () => {
      /**
       * coming back online (from offline)
       */
      if (!isOnline && navigator.onLine) {
        console.log('coming back online');
        subscribedData.forEach((item) => removeSubscribeItem(item));
        subscribeToProducers();
      }

      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);

  useEffect(() => {
    const isViewOnly = getParam('isViewOnly');
    console.log('isViewOnly', isViewOnly);
    setIsViewOnly(isViewOnly);
  }, []);

  /**
   * enable this if, we want to enforce only one stream at a time
   */
  const enforceOnlyOneStream = config.ENFORCE_ONLY_ONE_STREAM_IN_SPACE;

  useEffect(() => {
    if (!options.willHandleConnection) {
      connect();
    }

    /**
     * TODO: update this logic more efficiently
     * stop screenshare on logout
     */
    const disconnectPairedDeviceSubscriber = () => {
      onScreenshareStreamEnded();
    };
    const logoutSubscriber = () => {
      onScreenshareStreamEnded();
    };
    const shareScreenStoppedFromPairedDeviceSubscriber = () => {
      onScreenshareStreamEnded();
    };

    const disconnectPairedDeviceToken = PubSub.subscribe(
      EVENTS.DISCONNECT_PAIRED_DEVICE,
      disconnectPairedDeviceSubscriber,
    );
    const shareScreenStoppedFromPairedDeviceToken = PubSub.subscribe(
      EVENTS.SCREEN_SHARE_STOPPED_FROM_DEVICE,
      shareScreenStoppedFromPairedDeviceSubscriber,
    );
    const logoutListenerToken = PubSub.subscribe(EVENTS.LOGOUT, logoutSubscriber);
    return () => {
      PubSub.unsubscribe(disconnectPairedDeviceToken);
      PubSub.unsubscribe(logoutListenerToken);
      PubSub.unsubscribe(shareScreenStoppedFromPairedDeviceToken);
    };
  }, []);

  const mute = () => {
    setIsMuted(true);
  };

  const unmute = () => {
    setIsMuted(false);
  };

  const constructServerUrl = ({ roomId }) => {
    return `${hostname}?roomId=${roomId}`;
  };

  const isSocketConnected = () => {
    return socket && socket.connected;
  };

  const initDevice = async () => {
    if (getIsDeviceLoaded()) {
      return;
    }

    try {
      await loadDevice();
      console.log('Device initialized');
      setIsDeviceLoaded(true);

      subscribeToProducers();
    } catch (error) {
      console.error('Error while initializing device', error);
    }
  };

  const subscribeToProducers = async () => {
    console.log('subscribe to producers');
    const { hasProducers, producersData } = await socket.request('checkHasProducers');

    /** user setup should support incoming OR should have handled displaying media (audio/video) stream */
    if (hasProducers && !options.onlyOutgoing) {
      producersData.forEach(({ producerId, kind }) => subscribe({ producerId, kind, roomId }));
    }
  };

  const setIsDeviceLoaded = (value) => {
    isDeviceLoaded = value;
  };

  const getIsDeviceLoaded = () => {
    return isDeviceLoaded;
  };

  const getAccessToken = () => {
    let accessToken = getToken();
    if (!accessToken) {
      accessToken = getAnonymousUserToken();
    }
    return accessToken;
  };

  const connect = async () => {
    console.log('test: connect client');

    const accessToken = await getAccessToken();

    const opts = {
      path: '/server',
      transports: ['websocket'],
      rejectUnauthorized: false,
      auth: {
        token: accessToken,
      },
    };

    roomId = options.roomId ? options.roomId : getFlowId();

    const serverUrl = constructServerUrl({ roomId });
    socket = socketClient(serverUrl, opts);
    socket.request = socketPromise(socket);

    socket.on('connect', async () => {
      console.log('test: socket connected');
      // $('#connection_status').innerHTML = 'Connected';
      // $('#fs_publish').disabled = false;
      // $('#fs_subscribe').disabled = false;

      initDevice();
    });

    socket.on('disconnect', () => {
      // $('#connection_status').innerHTML = 'Disconnected';
      // $('#btn_connect').disabled = false;
      // $('#fs_publish').disabled = true;
      // $('#fs_subscribe').disabled = true;
    });

    socket.on('connect_error', (error) => {
      console.error(
        'useClient: could not connect to %s%s (%s)',
        serverUrl,
        opts.path,
        error.message,
      );
      console.error(error);
      // $('#connection_status').innerHTML = 'Connection failed';
      // $('#btn_connect').disabled = false;
    });

    socket.on('error', (error) => {
      console.error('error', error);
    });

    /**
     * Note newProducer should be send on socket connection.
     * It should be send only when the user clicks/start screenshare
     *
     * TODO:
     * initially, when user device is loaded, user can check with the backend server,
     * if a screenshare session is going on
     */
    socket.on('newProducer', (data) => {
      // $('#fs_subscribe').disabled = false;
      // subscribe();
      // if (!isDeviceLoaded) {
      //   canSubscribe = true;
      //   return;
      // }
      const { producerId, kind } = data;

      if (!options.onlyOutgoing) {
        subscribe({ producerId, kind });
      }

      if (enforceOnlyOneStream) {
        stopScreenshare();
      }
    });

    socket.on('stream-ended', (data) => {
      removeSubscribeItem({
        transportId: data.transportId,
        producerId: data.producerId,
        kind: 'video',
      });

      // setShowRemoteVideo(false);
    });
  };

  const loadDevice = async () => {
    try {
      const routerRtpCapabilities = await socket.request('getRouterRtpCapabilities');
      device = new mediasoup.Device();
      await device.load({ routerRtpCapabilities });
    } catch (error) {
      if (error.name === 'UnsupportedError') {
        console.error('browser not supported');
      }
      throw error;
    }
  };

  const addSendTransport = async (transport) => {
    transportsMap.set(transport.id, { id: transport.id, transport, direction: 'send' });
  };

  const removeTransport = async (transportId) => {
    transportsMap.delete(transportId);
  };

  /**
   * Notes:
   * - it stops the screenshare stream
   * - it sends a request to the backend server to stop the screenshare stream
   * - it sends a request to the paired device to stop the screenshare stream
   */
  const onScreenshareStreamEnded = async () => {
    removeLocalScreenshareSubscribedItem();
    stopScreenshare();
    sendScreenshareStoppedInfoToDevice && sendScreenshareStoppedInfoToDevice();
  };

  const handleStopScreenshare = async () => {
    await socket.request('stream-ended', {
      source: 'screenshare',
      transportId: screenshareTransportId,
      producerId: screenshareProducerId,
    });
    setIsScreenShared(false);
    dispatchSetScreenshareStopped();
  };

  const resetScreenshareStream = () => {
    screenshareStream = null;
    screenshareProducerId = null;
    screenshareTransportId = null;
  };

  const onMicrophoneStreamEnded = async () => {
    console.log('test: microphone stream ended');
    await socket.request('stream-ended', {
      source: ' microphone',
      transportId: microphoneTransportId,
    });
  };

  const stopScreenshare = async () => {
    if (!screenshareStream) {
      console.log('no screenshare stream');
      return;
    }
    var tracks = screenshareStream.getTracks();
    for (var i = 0; i < tracks.length; i++) tracks[i].stop();
    handleStopScreenshare({ transportId: screenshareTransportId });
    closeTransport({ transportId: screenshareTransportId });
    resetScreenshareStream();
    removeLocalScreenshareSubscribedItem();
  };

  const onClickMute = () => {
    var tracks = microphoneStream.getTracks();
    for (var i = 0; i < tracks.length; i++) tracks[i].stop();
    onMicrophoneStreamEnded();
    mute();
  };

  const onClickUnmute = () => {
    publish({ source: 'microphone' });
    unmute();
  };

  /**
   *
   * @param {{
   *  source: 'microphone' | 'screenshare',
   *  useExistingStream: boolean
   *  forceVP8?: boolean
   *  forceH264?: boolean
   * }} options
   * @returns
   */
  const publish = async (options) => {
    const { source, useExistingStream, forceVP8, forceH264 } = options;
    // const isWebcam = e.target.id === 'btn_webcam';
    // $txtPublish = isWebcam ? $('#webcam_status') : $txtScreen;

    // if (transport) {
    //   stream = produce({ transport, isWebcam });
    //   return;
    // }

    const data = await socket.request('createProducerTransport', {
      forceTcp: false,
      rtpCapabilities: device.rtpCapabilities,
      source,
    });
    if (data.error) {
      console.error(data.error);
      return;
    }

    const transport = device.createSendTransport(data);
    await addSendTransport(transport);

    if (source === 'screenshare') {
      screenshareTransportId = transport.id;
    } else {
      microphoneTransportId = transport.id;
    }

    transport.on('connect', async ({ dtlsParameters }, callback, errback) => {
      socket
        .request('connectProducerTransport', { dtlsParameters, transportId: data.id })
        .then(callback)
        .catch(errback);
    });

    transport.on('produce', async ({ kind, rtpParameters }, callback, errback) => {
      console.log('test: transport on produce');
      try {
        const { id } = await socket.request('produce', {
          transportId: transport.id,
          kind,
          rtpParameters,
        });
        callback({ id });
      } catch (err) {
        errback(err);
      }
    });

    transport.on('connectionstatechange', async (state) => {
      console.log(`produce transport: ${transport.id}, ${state}`);
      switch (state) {
        case 'connecting':
          // $txtPublish.innerHTML = 'publishing...';
          // $('#fs_publish').disabled = true;
          // $('#fs_subscribe').disabled = true;
          break;

        case 'connected':
          if (source === 'screenshare') {
            addLocalSubscribedItem({
              stream: screenshareStream,
              type: 'screenshare',
              kind: 'video',
              ref: createRef(),
            });
          }
          // $txtPublish.innerHTML = 'published';
          // $('#fs_publish').disabled = true;
          // $('#fs_subscribe').disabled = false;
          break;

        case 'failed':
          if (source === 'screenshare') {
            republishScreenshare(transport, { forceVP8, forceH264 });
          }

          // $txtPublish.innerHTML = 'failed';
          // $('#fs_publish').disabled = false;
          // $('#fs_subscribe').disabled = true;
          break;

        case 'disconnected':
          break;

        case 'closed':
          break;
        default:
          break;
      }
    });

    await produce({ transport, source, useExistingStream, forceVP8, forceH264 });

    if (source === 'screenshare') {
      setScreenshareTransport(transport);
    }
  };

  /**
   * clone the track, since closing the transport will stop the track
   * close the transport
   * handle stop screenshare
   * try to re-publish the screenshare
   */
  const republishScreenshare = async (transport, options) => {
    const { forceVP8, forceH264 } = options;
    if (!getScreenshareStream()) {
      console.log('no screenshare stream');
      return;
    }
    console.log('republishing screenshare');

    cloneScreenshareTrack();
    closeTransport({ transportId: transport.id });

    /**
     * TODO: update the function handle stop screenshare to something more appropriate related to republish.
     */
    handleStopScreenshare();

    publish({ source: 'screenshare', useExistingStream: true, forceVP8, forceH264 });
  };

  const setScreenshareTransport = (transport) => {
    screenshareTransport = transport;
  };

  /**
   * Note: Once the transport is closed,
   * - it cannot be used again.
   * - also the tracks that are associated with the transport cannot be used again.
   * - so, we need to clone the track and create a new transport, if we want to re-publish the track.
   *
   * @param {*} transport
   */
  const closeTransport = ({ transportId }) => {
    const transportRecord = getTransportRecordById(transportId);
    const transport = transportRecord?.transport;
    if (transportId && transport) {
      console.log('closing transport');
      transport.close();
      removeTransport(transportId);
    }
  };

  const getTransportRecordById = (transportId) => {
    return transportsMap.get(transportId);
  };

  const produceScreenshareParams = {
    resolutions: [
      { width: 1920, height: 1080 },
      { width: 1280, height: 720 },
    ],
  };

  /**
   *
   * @param {{
   *  forceH264?: boolean,
   *  forceVP8?: boolean,
   *  simulcast?: boolean,
   * }} options
   * @returns
   */
  const getProduceScreenshareParams = (options) => {
    const params = { ...produceScreenshareParams };
    if (options?.forceH264) {
      params.codec = getH264Codec();
    }

    if (options?.forceVP8) {
      params.codec = getVP8Codec();
    }

    if (options?.simulcast) {
      params.encodings = [{ maxBitrate: 100000 }, { maxBitrate: 300000 }, { maxBitrate: 900000 }];
      params.codecOptions = {
        videoGoogleStartBitrate: 1000,
      };
    }

    return params;
  };

  const getDevice = () => {
    return device;
  };

  const getH264Codec = () => {
    const codec = getDevice().rtpCapabilities.codecs.find(
      (c) => c.mimeType.toLowerCase() === 'video/h264',
    );
    return codec;
  };

  const getVP8Codec = () => {
    return getDevice().rtpCapabilities.codecs.find((c) => c.mimeType.toLowerCase() === 'video/vp8');
  };

  /**
   * @param {{
   *   transport: any,
   *   source: 'screenshare' | 'microphone',
   *   useExistingStream?: boolean,
   *   forceH264?: boolean,
   *   forceVP8?: boolean,
   *   simulcast?: boolean,
   * }} options
   */
  const produce = async (options) => {
    const { transport, source, useExistingStream, forceH264, forceVP8, simulcast } = options;
    try {
      const { stream, track } = await getUserMedia({ transport, source, useExistingStream });
      const params = { track, ...getProduceScreenshareParams({ forceH264, forceVP8, simulcast }) };
      producer = await transport.produce(params);

      if (source === 'screenshare') {
        screenshareProducerId = producer.id;
        setIsScreenShared(true);
        dispatchSetScreenshareStarted();
      } else {
        microphoneProducerId = producer.id;
      }

      return { producer, stream, track };
    } catch (err) {
      // $txtPublish.innerHTML = 'failed';
      console.log('test: error', err);
      closeProducerTransport();
      throw err;
    }
  };

  const closeProducerTransport = async (transport) => {
    if (!transport) {
      return;
    }
    transport?.close && transport.close();
  };

  /**
   * TODO: improve the screenshare quality
   *
   * Note:
   * this did not work
   * {
   *  video: {
   *    width: { min: 1920, ideal: 1920, max: 1920 },
   *    height: { min: 1920, ideal: 1080, max: 1080 },
   *    frameRate: { min: 30, ideal: 60, max: 120 },
   *    bitrate: { min: 1000000, max: 10000000 },
   *  },
   * }
   */
  const screenshareContraints = {
    video: {
      width: 1920,
      height: 1080,
      frameRate: 15,
      bitrate: 10000000,
    },
  };

  const getScreenshareStream = () => {
    return screenshareStream;
  };

  const getClonedScreenshareTrack = () => {
    return clonedScreenshareTrack;
  };

  /**
   * remove the current track from the stream
   * add the new track to the stream
   * @param {*} track
   */
  const replaceScreenshareTrack = (track) => {
    getScreenshareStream().removeTrack(getScreenshareTrack());
    getScreenshareStream().addTrack(track);
  };

  /**
   *
   * @returns
   */
  const checkIsSafari = () => {
    return navigator.userAgent.indexOf('Safari') > -1;
  };

  /**
   * screenshare getDisplayMedia contraints for Safari
   *
   * TODO:
   * Currently, the default screenshareContraints Configured seems to be not working properly on Safari
   * Need to do more research on this
   * @returns
   */
  const getSafariScreenshareContrainsts = () => {
    return {
      video: true,
    };
  };

  const getScreenshareConstraints = () => {
    return checkIsSafari ? getSafariScreenshareContrainsts() : screenshareContraints;
  };

  /**
   * Note:
   * we need to capture the media stream here
   * because Safari does not allow capturing the media stream
   * when the user is not interacting with the page
   * e.g
   * the following works:
   * when the user clicks on the button, the media stream will be captured
   *
   * the following does not work:
   * when the user clicks on the button,
   * the button calls another function
   * which then calls the appropriate capture mediastream function
   * the media stream will not captured
   *
   * @param {{ source: 'screenshare' | 'microphone'; useExistingStream: boolean }} options
   * @returns
   */
  const getUserMedia = async (options) => {
    const { source, useExistingStream } = options;
    if (!device.canProduce('video')) {
      console.error('cannot produce video');
      return;
    }

    /**
     * This might be used on trying to re-publish the screenshare
     * in case of failure or disconnection
     */
    if (useExistingStream && source === 'screenshare') {
      console.log('useExisting', getScreenshareTrack().readyState);
      /**
       * before re-publishing the screenshare, we have already cloned the track
       * so, we need to replace the track with the cloned track
       * and use the cloned track to re-publish
       */
      const track =
        getScreenshareTrack().readyState === 'ended'
          ? getClonedScreenshareTrack()
          : getScreenshareTrack();
      replaceScreenshareTrack(track);
      return { stream: getScreenshareStream(), track };
    }

    let stream;
    let track;
    try {
      switch (source) {
        case 'webcam':
          stream = await navigator.mediaDevices.getUserMedia({ video: true });
          break;
        case 'screenshare':
          const constraint = getScreenshareConstraints();
          stream = await navigator.mediaDevices.getDisplayMedia(constraint);
          break;
        case 'microphone':
          stream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
          break;
        default:
          stream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
      }

      if (source === 'microphone') {
        // stream.getAudioTracks()[0].onended = onMicrophoneStreamEnded;
        track = stream.getAudioTracks()[0];
      } else {
        stream.getVideoTracks()[0].onended = onScreenshareStreamEnded;
        track = stream.getVideoTracks()[0];
      }
    } catch (err) {
      console.error('getUserMedia() failed:', err.message);
      throw err;
    }

    if (source === 'screenshare') {
      screenshareStream = stream;
      screenshareTrack = track;
    } else {
      microphoneStream = stream;
      microphoneTrack = track;
    }

    return { stream, track };
  };

  /**
   * subscribeData contains info of the subscribe item
   * we use the subscribe Item to render it on the screen
   *
   * we have to remove the subscribe item when the producer is closed
   * sometimes, the producer is closed before the subscribe item is removed
   *
   * so, before adding a new subscribe item, we need to check if the subscribe item is still in the subscribeData
   * if it is, we need to remove it first, before adding the new subscribe item
   * this is to prevent duplicate subscribe item, which will cause the video to be rendered twice
   *
   * TODO:
   * inspect why we are receiving multiple subscribe event with the same producerId and kind.
   *
   * TODO: transportId should be the id of transport used by the producer
   * @param {{ producerId: string, transportId: string, kind: string, stream: any }} data
   */
  const addSubscribeItem = (data) => {
    const { producerId, transportId, kind, ref, stream } = data;
    setSubscribedData((data) => {
      let newData = [...data];
      const filterItem = (item) => !(item.producerId === producerId && item.kind === kind);
      newData = newData.filter(filterItem);
      return [...newData, { producerId, transportId, kind, ref, stream }];
    });
  };

  const removeSubscribeItem = (data) => {
    const { producerId, kind } = data;
    const filterItem = (item) => !(item.producerId === producerId && item.kind === kind);
    setSubscribedData((currentSubscribedData) => currentSubscribedData.filter(filterItem));
  };

  const cloneScreenshareTrack = () => {
    const clonedTrack = getScreenshareTrack().clone();
    clonedTrack.onended = onScreenshareStreamEnded;
    clonedScreenshareTrack = clonedTrack;
    return clonedTrack;
  };

  /**
   *
   * @param {{
   *   type: 'screenshare' | 'microphone' | 'webcam';
   *   kind: 'video' | 'audio';
   *   stream: MediaStream;
   *   ref: React.MutableRefObject<any>;
   * }} data
   */
  const addLocalSubscribedItem = (data) => {
    const { type, kind, stream, ref } = data;
    setLocalSubscribedData((data) => {
      let newData = [...data];
      const filterItem = (item) => item.type !== type;
      newData = newData.filter(filterItem);
      return [...newData, { type, stream, kind, ref }];
    });
  };

  const removeLocalScreenshareSubscribedItem = () => {
    setLocalSubscribedData((currentLocalSubscribedData) =>
      currentLocalSubscribedData.filter((item) => item.type !== 'screenshare'),
    );
  };

  /**
   *
   * @param {{producerId: string, kind: string}} param0
   * @returns
   */
  const subscribe = async ({ producerId, kind, roomId }) => {
    const data = await socket.request('createConsumerTransport', {
      forceTcp: false,
    });
    if (data.error) {
      console.error(data.error);
      return;
    }

    const transport = device.createRecvTransport(data);
    const subscribeItemInfo = { producerId, transportId: transport.id, kind, ref: createRef() };

    console.log('checkkkkkkkk', data.id, transport.id);

    transport.on('connect', ({ dtlsParameters }, callback, errback) => {
      socket
        .request('connectConsumerTransport', {
          transportId: transport.id,
          dtlsParameters,
        })
        .then(() => {
          console.log('connectConsumerTransport success');
          callback();
        })
        .catch(() => {
          console.log('connectConsumerTransport error');
          errback();
        });
    });

    transport.on('connectionstatechange', async (state) => {
      console.log(`subscribe transport: ${transport.id}, ${state}`);
      switch (state) {
        case 'connecting':
          // $('#sub_status').innerHTML = 'subscribing...';
          // $('#fs_subscribe').disabled = true;
          break;

        case 'connected':
          if (kind === 'video') {
            // document.querySelector('#remote_video').srcObject = await stream;
            // document.querySelector('#remote_video').play();
            addSubscribeItem({ ...subscribeItemInfo, stream });

            setShowRemoteVideo(true);
            await socket.request('resume', {
              roomId,
              consumerId: consumer.id,
            });
          } else {
            const audioElem = document.createElement('audio');
            const elemId = `audio-${transport.id}`;
            audioElem.id = elemId;
            audioElem.setAttribute('controls', true);
            audioElem.setAttribute('autoplay', true);
            // document.querySelector(`#${elemId}`).srcObject = await stream;
            audioElem.srcObject = await stream;
            document.querySelector('#audio-container').append(audioElem);
            document.querySelector(`#${elemId}`).play();
          }
          // $('#sub_status').innerHTML = 'subscribed';
          // $('#fs_subscribe').disabled = true;
          break;

        case 'failed':
          transport.close();
          removeSubscribeItem({ transportId: transport.id, kind });
          // $('#sub_status').innerHTML = 'failed';
          // $('#fs_subscribe').disabled = false;
          break;

        case 'disconnected':
          if (kind === 'video') {
            setShowRemoteVideo(false);
          } else {
            const elemId = `audio-${transport.id}`;
            console.log(`removing audio element ${elemId}`);
            document.querySelector(`#${elemId}`).remove();
          }
          removeSubscribeItem({ transportId: transport.id, kind });
          break;

        default:
          break;
      }
    });

    const { stream, consumer } = await consume({ transport, producerId });
  };

  const getScreenshareTrack = () => {
    return screenshareStream.getVideoTracks()[0];
  };

  const pauseScreenshareTrack = () => {
    if (!getScreenshareTrack()) {
      return;
    }
    getScreenshareTrack().enabled = false;
  };

  const resumeScreenshareTrack = () => {
    if (!getScreenshareTrack()) {
    }
    getScreenshareTrack().enabled = true;
  };

  const consume = async ({ transport, producerId }) => {
    const { rtpCapabilities } = device;
    const data = await socket.request('consume', {
      rtpCapabilities,
      producerId,
      transportId: transport.id,
    });
    const { id, kind, rtpParameters } = data;

    let codecOptions = {};
    const consumer = await transport.consume({
      id,
      producerId,
      kind,
      rtpParameters,
      codecOptions,
    });
    const stream = new MediaStream();
    stream.addTrack(consumer.track);
    return { stream, consumer };
  };

  /**
   *
   */
  const checkIsAllowedToStream = async (data = {}) => {
    const response = await socket.request('checkIsAllowedToStream', data);

    console.log('checkIsAllowedToStream', response);
    if (!response.isAllowedToStream) {
      handleLicenseError(response);
      throw new Error(response.message);
    }

    return response;
  };

  const handleLicenseError = (response) => {
    console.log('response', response);

    if (!response.isAllowedToStream) {
      if (response.type === 'FEATURE_NOT_ENABLED') {
        const { feature } = response;
        dispatchShowFeatureLockedDialog({ feature });
        return;
      } else if (response.type === 'FEATURE_LIMIT_EXHAUSTED') {
        const { feature } = response;
        dispatchShowSimultaneousLimitExhaustedDialog({ feature });
        return;
      } else if (response.type === 'SIMULTANEOUS_LIMIT_EXHAUSTED') {
        const { feature } = response;
        dispatchShowSimultaneousLimitExhaustedDialog({ feature });
        return;
      } else {
        console.log(response);
        return;
      }
    }
  };

  return {
    connect,
    consume,
    getUserMedia,
    checkIsAllowedToStream,
    isMuted,
    isScreenShared,
    isSocketConnected,
    isViewOnly,
    localSubscribedData,
    mute,
    onClickMute,
    onClickUnmute,
    publish,
    roomId,
    showRemoteVideo,
    stopScreenshare,
    subscribe,
    subscribedData,
    unmute,
  };
};
