import React from 'react';
import { Button, Typography, makeStyles } from '@material-ui/core';

import pdfIcon from '../../../assets/icons/pdf.svg';

const useStyles = makeStyles((theme) => ({
  PDFIndicatorRoot: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    padding: '8px 12px',
    marginBottom: 16,
    borderRadius: 4,
    border: `1px solid ${theme.palette.primary.main}`,
    '& .i-filename': {
      fontSize: 14,
      flex: 1,
    },
    '& .i-remove': {
      color: '#888',
      fontSize: 12,
    },
  },
}));

export const PDFIndicator = ({ fileName, onRemove }) => {
  const classes = useStyles();
  return (
    <div className={classes.PDFIndicatorRoot}>
      <img src={pdfIcon} alt="pdf" />
      <Typography className="i-filename">{fileName}</Typography>
      <Button className="i-remove" variant="text" onClick={onRemove}>
        Remove
      </Button>
    </div>
  );
};
