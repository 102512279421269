import React from 'react';
import { VideoCam } from '../../assets/icons/VideoCam';
import { useDialogDispatcher } from '../../store/dispatcher/useDialogDispatcher';
import { useDialogSelector } from '../../store/selector/useDialogSelector';
import { VideoCallLinks } from './component';
import { Calendar } from '../../assets/icons/Calendar';
import { useSelector } from 'react-redux';
import { useNotificationDispatcher } from '../../store/dispatcher/useNotificationDispatcher';

export const VideoCallLinksContainer = ({ isMobile, isSmallMobile }) => {
  const { isOpenVideoCallSidebarDialog } = useDialogSelector();
  const { dispatchSetIsOpenVideoCallSidebarDialog } = useDialogDispatcher();
  const cloudLicenseInfo = useSelector((state) => state.licenseReducer.cloudLicenseDetails);
  const { dispatchShowFeatureLockedDialog } = useNotificationDispatcher();

  const icon = <Calendar />;
  const label = `Meetings`;
  const color = 'black';
  const backgroundColor = 'white';

  const onClick = () => {
    if (cloudLicenseInfo.spaces.meetings) {
      dispatchSetIsOpenVideoCallSidebarDialog(true);
    } else {
      dispatchShowFeatureLockedDialog();
    }
  };

  const closeDialog = () => {
    dispatchSetIsOpenVideoCallSidebarDialog(false);
  };

  return (
    <VideoCallLinks
      icon={icon}
      label={label}
      color={color}
      backgroundColor={backgroundColor}
      onClick={onClick}
      isVideoCallDialogOpen={isOpenVideoCallSidebarDialog}
      closeDialog={closeDialog}
      isMobile={isMobile}
      isSmallMobile={isSmallMobile}
    />
  );
};
