import React from 'react';

const TextTool = (props) => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="8" y="8" width="22" height="22" rx="2" fill="white" stroke="black" />
    <path
      d="M13 25L15 21M25 25L23 21M15 21L18.9106 13.1789C18.9474 13.1052 19.0526 13.1052 19.0894 13.1789L23 21M15 21H23"
      stroke="black"
      strokeLinecap="round"
    />
  </svg>
);

export default TextTool;
