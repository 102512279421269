import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { throttle } from 'lodash';
import { Box, IconButton, Typography, makeStyles, CircularProgress } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { CHAT_TYPES, PERSONA_TYPES, QUERY_ACTION_TYPES, teacherTypes } from '../../../constants';
import { getusername } from '../../IWB/utils/authentication-access';
import { useStartWhiteboardChat } from '../../../services/whiteboard-chat';
import { useAsk } from '../../../services/self-learn.service';
import { ChatBox } from '../../../components/self-learn/index';
import QueryInput from '../../../components/self-learn/query-input';
import { setSnackbar } from '../../../store/actions/notification.action';

const useStyles = ({ open, loading }) =>
  makeStyles({
    whiteboardChatRoot: {
      boxSizing: 'border-box',
      display: open ? 'flex' : 'none',
      flexDirection: 'column',
      position: 'fixed',
      top: 0,
      right: 0,
      width: '100vw',
      height: '100dvh',
      backgroundColor: '#FFF',
      boxShadow: '0px 0px 6px #0007',
      zIndex: 1000000,
      pointerEvents: loading ? 'none' : undefined,
      '@media(min-width: 801px)': {
        top: 60,
        bottom: 60,
        width: 400,
        height: 'auto',
      },
      '& *, *::after, *::before': {
        boxSizing: 'border-box',
      },
      '&>div:nth-child(2)': {
        paddingTop: 8,
      },
      '& .i-header': {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        padding: 8,
        borderBottom: '1px solid #ddd',
        '& .i-title': {
          flex: 1,
          fontWeight: 500,
        },
      },
    },
  });

const WhiteboardChat = ({
  lessonName,
  elementId,
  url,
  type,
  textContent,
  role = 'TEACHER',
  onAddToLesson,
  onClose,
  query,
}) => {
  const dispatch = useDispatch();

  const [chatId, setChatId] = useState('');
  const [chatList, setChatList] = useState([]);
  const userName = useMemo(() => getusername(), []);
  const history = useHistory();
  const prevQuery = useRef('');
  const prevChatId = useRef('');

  const chatStarted = useMemo(() => chatList.some(({ type }) => type === 'user'), [chatList]);

  const { data: chatDetails, isFetching: isWhiteboardChatLoading } = useStartWhiteboardChat(
    {
      lessonName,
      elementId,
      type,
      contentUrl: url,
      text: textContent,
      role,
    },
    {
      onError: (err) => {
        onClose(
          err.response?.data?.message || err.message || 'Failed to receive the response',
          true,
        );
      },
    },
  );

  const { ask, isLoading: isQueryInProgress } = useAsk();

  const actionsEnabled = useMemo(
    () => !isQueryInProgress && chatStarted,
    [chatStarted, isQueryInProgress],
  );

  const classes = useStyles({
    open: !!elementId,
    loading: isWhiteboardChatLoading || isQueryInProgress,
  })();

  const updateChat = useCallback(
    throttle(({ chatIndex, chatMessage }) => {
      setChatList((prevChatList) => {
        const updatedChatList = [...prevChatList];
        const updatedChat = updatedChatList[chatIndex];
        updatedChat.content = chatMessage;
        updatedChatList[chatIndex] = updatedChat;
        return updatedChatList;
      });
    }, 200),
    [],
  );

  const askHandler = useCallback(
    async ({ query, action }) => {
      if (isQueryInProgress || isWhiteboardChatLoading) return;

      const queryTimestamp = moment(new Date()).format('h:mm a, D MMM');
      let chatIndex = 0;
      setChatList((prevChatList) => {
        chatIndex = prevChatList.length + 1;
        return [
          ...prevChatList,
          {
            type: 'user',
            timestamp: queryTimestamp,
            content: QUERY_ACTION_TYPES[action]?.title || query,
          },
        ];
      });
      let chatMessage = '';

      const handlers = {
        onStart: () => {
          const responseTimestamp = moment(new Date()).format('h:mm a, D MMM');
          setChatList((prevChatList) => [
            ...prevChatList,
            { type: 'ai', timestamp: responseTimestamp, content: '' },
          ]);
        },
        onMessage: (message) => {
          chatMessage += message;
          updateChat({ chatMessage, chatIndex });
        },
        onError: (err) => {
          dispatch(setSnackbar({ message: err.message || 'Failed to receive the response' }));
        },
      };

      ask({
        query,
        threadId: chatId,
        action,
        teacher: teacherTypes.GENERAL,
        ...handlers,
      });
    },
    [isQueryInProgress, isWhiteboardChatLoading, ask, chatId, updateChat, onClose, history],
  );

  const lastResponse = useMemo(() => {
    const reversedChatList = [...chatList].reverse();
    return isQueryInProgress ? '' : reversedChatList.find(({ type }) => type === 'ai')?.content;
  }, [isQueryInProgress, chatList]);

  const actionHandler = useCallback(
    (action) => askHandler({ query: lastResponse, action }),
    [askHandler, lastResponse],
  );

  const questionClickHandler = useCallback(
    (question) => askHandler({ query: question }),
    [askHandler],
  );

  useEffect(() => {
    if (!chatDetails) return;
    setChatId(chatDetails.id);
    const messages =
      chatDetails?.messages
        .sort(({ createdAt: createdAtA }, { createdAt: createdAtB }) =>
          moment(createdAtA) < moment(createdAtB) ? -1 : 1,
        )
        .map(({ type, pdfDetails, videoDetails, question, completion, createdAt }) => {
          const timestamp = moment(createdAt).format('h:mm a, D MMM');
          return type === CHAT_TYPES.PDF
            ? [{ type: CHAT_TYPES.PDF, ...pdfDetails }]
            : type === CHAT_TYPES.YOUTUBE
            ? [{ type: CHAT_TYPES.YOUTUBE, ...videoDetails }]
            : [
                ...(question ? [{ type: 'user', timestamp, content: question }] : []),
                { type: 'ai', timestamp, content: completion },
              ];
        })
        .flat() || [];
    setChatList(messages);
  }, [chatDetails]);

  useEffect(() => {
    if (
      !chatId ||
      !query ||
      isWhiteboardChatLoading ||
      (prevQuery.current === query && chatId === prevChatId.current)
    )
      return;
    prevQuery.current = query;
    prevChatId.current = chatId;

    askHandler({ query });
  }, [askHandler, chatId, isWhiteboardChatLoading, query]);

  return (
    <Box className={classes.whiteboardChatRoot}>
      <Box className="i-header">
        <Typography className="i-title">Chat</Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      {isWhiteboardChatLoading ? (
        <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <ChatBox
          persona={PERSONA_TYPES.GENERAL}
          chatList={chatList}
          isLoading={isQueryInProgress}
          onQuestionClick={questionClickHandler}
          onAddToLesson={onAddToLesson}
          isWhiteboardChat
        />
      )}
      <div style={{ padding: '0px 8px 8px 8px' }}>
        <QueryInput
          chatExpanded
          loading={isQueryInProgress}
          actionsEnabled={actionsEnabled}
          onSend={askHandler}
          onAction={actionHandler}
          userName={userName}
          chatStarted={chatStarted}
          uploadPdfEnabled={false}
          language="en"
        />
      </div>
    </Box>
  );
};

export default React.memo(WhiteboardChat);
