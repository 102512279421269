import { Box, Popover, Typography } from '@material-ui/core';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React from 'react';
import { SCHEDULE_FILTER } from '../../../../constants/schedules';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useScheduleFilterDialogStyles } from './component.style';

export const ScheduleFilterDialog = (props) => {
  const { scheduleFilter, setTodaysScheduleFilter, setUpcomingScheduleFilter } = props;

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <PopupContent
          popupState={popupState}
          scheduleFilter={scheduleFilter}
          setTodaysScheduleFilter={setTodaysScheduleFilter}
          setUpcomingScheduleFilter={setUpcomingScheduleFilter}
        />
      )}
    </PopupState>
  );
};

const PopupContent = (props) => {
  const { popupState, scheduleFilter, setTodaysScheduleFilter, setUpcomingScheduleFilter } = props;
  const classes = useScheduleFilterDialogStyles();

  const listScheduleSelectHeading =
    scheduleFilter === SCHEDULE_FILTER.TODAY ? `Today’s Schedule` : `Upcoming Schedule`;

  const listScheduleSelector = (popupState) => (
    <div className={classes.listScheduleSelect} {...bindTrigger(popupState)}>
      <div>{listScheduleSelectHeading}</div>
      <ArrowDropDownIcon />
    </div>
  );

  const todayScheduleFilterItem = (
    <div
      onClick={() => {
        setTodaysScheduleFilter();
        popupState.close();
      }}
      className={classes.selectScheduleFilterItem}
    >
      <Typography>Today's Schedule</Typography>
    </div>
  );

  const upcomingScheduleFilterItem = (
    <div
      onClick={() => {
        setUpcomingScheduleFilter();
        popupState.close();
      }}
      className={classes.selectScheduleFilterItem}
    >
      <Typography>Upcoming Schedule</Typography>
    </div>
  );

  const content = (
    <Box
      p={2}
      style={{
        width: '12rem',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      }}
      className={classes.selectScheduleFilterList}
    >
      {todayScheduleFilterItem}
      {upcomingScheduleFilterItem}
    </Box>
  );

  return (
    <div>
      {listScheduleSelector(popupState)}
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {content}
      </Popover>
    </div>
  );
};
