import React, { useEffect, useState } from 'react';
import countries from 'countries-list/dist/countries.json';
import { makeStyles } from '@material-ui/core';
import StyledAutocomplete from './component';

const useStyles = makeStyles((theme) => ({}));

const StyledAutocompleteContainer = (props) => {
  const classes = useStyles();
  const { countryError, onChangeCountry, containerStyle } = props;
  const [value, setValue] = useState('');
  const [isListOpen, setIsListOpen] = useState(false);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [showCloseIcon, setShowCloseIcon] = useState();
  const countriesArr = Object.values(countries);

  useEffect(() => {
    setFilteredCountries(countriesArr);
  }, []);

  const countriesFilter = (valueString) => (country) =>
    country.name.toLowerCase().startsWith(valueString);

  const onChange = (e) => {
    const valueString = e.target.value;
    setValue(valueString);
    if (!valueString) {
      setFilteredCountries(countriesArr);
    } else {
      setFilteredCountries(countriesArr.filter(countriesFilter(valueString.toLowerCase())));
    }
    onChangeCountry(null, null);
  };

  const onItemClick = (item) => {
    setValue(item);
    setIsListOpen(false);
    onChangeCountry(null, { name: item });
  };

  const onFocusSearchField = () => {
    setIsListOpen(true);
  };

  const onBlurSearchField = () => {
    setIsListOpen(false);
  };

  return (
    <StyledAutocomplete
      isListOpen={isListOpen}
      value={value}
      setValue={setValue}
      onChange={onChange}
      error={countryError}
      helperText={'Country required'}
      onItemClick={onItemClick}
      onFocusSearchField={onFocusSearchField}
      onBlurSearchField={onBlurSearchField}
      showCloseIcon={showCloseIcon}
      countries={filteredCountries}
      containerStyle={containerStyle}
    />
  );
};

export default StyledAutocompleteContainer;
