import ACTION_TYPES from '../actions/type.action';

const initialState = {
  admins: [],
};

const adminReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPES.GET_ADMINS_INFO:
      return {
        ...state,
        admins: payload,
      };
    default:
      return state;
  }
};

export default adminReducer;