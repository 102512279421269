import ACTION_TYPES from '../actions/type.action';

const initialState = {
  language: 'en',
};

const selfLearnReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_SELF_LEARN_LANGUAGE:
      return {
        ...state,
        language: payload,
      };
    default:
      return state;
  }
};

export default selfLearnReducer;
