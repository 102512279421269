import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';

export const OverlaySpinner = (props) => {
  const { open } = props;

  return (
    <Backdrop
      style={{
        position: 'fixed',
        zIndex: 9999,
      }}
      open={open}
    >
      <CircularProgress />
    </Backdrop>
  );
};
