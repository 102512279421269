import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, styled } from '@material-ui/core';
import { Fullscreen, FullscreenExit } from '@material-ui/icons';
import { setFullscreen } from '../../store/actions/canvas.action';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  bottom: '67px',
  right: '12px',
  backgroundColor: `white !important`,
  borderRadius: '4px',
  padding: '2px',
  '& .MuiSvgIcon-root': {
    width: '32px',
    height: '32px',
  },

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const FullscreenButton = () => {
  const dispatch = useDispatch();
  const isFullscreen = useSelector((state) => state.canvasReducer.isFullscreen);
  const setIsFullscreen = useCallback((enabled) => dispatch(setFullscreen(enabled)), [dispatch]);

  const toggleFullscreen = useCallback(() => {
    if (document.fullscreenEnabled) {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    } else {
      setIsFullscreen(!isFullscreen);
    }
  }, [setIsFullscreen, isFullscreen]);

  const fullscreenChangeHandler = useCallback(
    () => setIsFullscreen(!!document.fullscreenElement),
    [setIsFullscreen],
  );

  useEffect(() => {
    document.documentElement.addEventListener('fullscreenchange', fullscreenChangeHandler);
    return () =>
      document.documentElement.removeEventListener('fullscreenchange', fullscreenChangeHandler);
  }, [fullscreenChangeHandler]);

  return (
    <StyledIconButton id="fullscreen-button" onClick={toggleFullscreen}>
      {isFullscreen ? <FullscreenExit htmlColor="#000" /> : <Fullscreen htmlColor="#000" />}
    </StyledIconButton>
  );
};

export default FullscreenButton;
