import React, { useEffect, useRef, useState } from 'react';
import appLogo from '../../../assets/img/appLogo.svg';
import HeaderName from '../../../assets/svg/HeaderName.svg';
import goBackLeftArrow from '../../../assets/svg/goBackLeftArrow.svg';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Price35 from '../../../assets/svg/Price35.svg';
import Price99 from '../../../assets/svg/Price99.svg';
import Price199 from '../../../assets/svg/Price199.svg';
import './UpgradePlan.css';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
// import { baseService } from "../../store/services/base.service";
// import { ADMIN, PAYMENT, SIGNUP } from "../../utils";
// import { LICENSE } from "../../utils/url-builder";
// import {
//   getInstituteKneuraId,
//   getToken,
//   getusername,
//   setInstituteId,
//   setInstituteKneuraId,
// } from "../../utils/authentication-access";
import InstitueDefaultIcon from '../../../assets/svg/institute.svg';
import ZeroPriceFree from '../../../assets/svg/ZeroPriceFree.svg';
import Price199Pro from '../../../assets/svg/Price199Pro.svg';
import Price1499Adv from '../../../assets/svg/Price1499Adv.svg';
import Price4999Ins from '../../../assets/svg/Price4999Ins.svg';
import DiscountWhite from '../../../assets/svg/DiscountWhite.svg';
import DiscountBlack from '../../../assets/svg/DiscountBlack.svg';
import Price299Pro from '../../../assets/svg/Price299Pro.svg';
import Price1999Adv from '../../../assets/svg/Price1999Adv.svg';
import Price6999Ins from '../../../assets/svg/Price6999Ins.svg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DownArrowBlack from '../../../assets/svg/DownArrowBlack.svg';
import UpArrowBlack from '../../../assets/svg/UpArrowBlack.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '0 60px',
    marginBottom: 0,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
const UpgradePlan = () => {
  const classes = useStyles();
  const history = useHistory();
  const isWebview = window.location.href.includes('webview') ? true : false;
  const [instituteName, setinstituteName] = useState();
  const [instituteLogourl, setinstituteLogourl] = useState();
  const [planGrade, setplanGrade] = useState();
  const [PlanName, setLicensName] = useState();
  const [monthPlanActive, setmonthPlanActive] = useState(true);
  const [yearPlanActive, setyearPlanActive] = useState(false);
  const [planDuration, setplanDuration] = useState(12);
  const [sorterAnchorEl, setsorterAnchorEl] = useState(null);
  const [sortType, setsortType] = useState('INR Rupees');
  const [rupeePrice, setrupeePrice] = useState(true);
  const [dollarPrice, setdollarPrice] = useState(false);
  const [showTable, setshowTable] = useState(false);

  const titleRef = useRef();
  //     const isWebview = window.location.href.includes("webview") ? true : false;
  // if(isWebview){
  //   history.push("/webview/storage");
  // }
  // else{
  // }

  const getInstituteInfo = async () => {
    // try {
    //   const url = ADMIN.getInstituteInfo(getInstituteKneuraId());
    //   let res = await baseService.makeGetRequest(url);
    //   setinstituteName(res.data.instituteName);
    //   setinstituteLogourl(res.data.institute_photo);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const fetchLicense = async () => {
    // return new Promise(async (resolve, reject) => {
    // try {
    //   let url = LICENSE.licenseDetails();
    //   let license = await baseService.makeGetRequestWithHeaders(url, {
    //     access_token: getToken(),
    //     instituteid: getInstituteKneuraId(),
    //   });
    //   setLicensName(license.planName);
    //   setplanGrade(license.planGrade);
    //   console.log("2222");
    //   // this.setState({ license });
    //   // return resolve(license);
    // } catch (error) {
    //   // return reject(error);
    //   console.log(error);
    // }
    // // });
    // console.log("fdfd");
  };

  const storeUrlInfo = () => {
    // const searchObj = new URLSearchParams(window.location.search);
    // const instituteKneuraId = searchObj.get("instituteKneuraId");
    // const instituteId = searchObj.get("instituteId");
    // if (instituteKneuraId) {
    //   setInstituteKneuraId(instituteKneuraId);
    //   setInstituteId(instituteId);
    // }
  };

  useEffect(() => {
    storeUrlInfo();
    getInstituteInfo();
    fetchLicense();
  }, []);

  useEffect(() => {
    console.log('aa', PlanName);
  }, [PlanName]);

  const gotoplanBillingPage = () => {
    history.push('/manage/license-billing');
  };

  const contactSles = () => {
    history.push('/Contactsales');
  };

  const UpgradePlanClick = (planName, type) => {
    history.push({
      pathname: '/upgrade',
      state: {
        plan: planName,
        accountType: type,
        currentPaymentPlan: planDuration,
      },
    });
  };

  const monthlyPlan = () => {
    setmonthPlanActive(false);
    setyearPlanActive(true);
    setplanDuration(1);
  };

  const yearlyPlan = () => {
    setmonthPlanActive(true);
    setyearPlanActive(false);
    setplanDuration(12);
  };

  function handleBackClick() {
    // window.scrollTo({top:0} )
    titleRef.current.scrollIntoView({ top: 0, behavior: 'smooth' });
  }

  const showTableClick = () => {
    setshowTable(!showTable);

    setTimeout(() => {
      handleBackClick();
    }, 100);
  };

  return (
    <div>
      <div className="containerWidth" style={{ background: 'white' }}>
        {!isWebview && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '20px 30px',
            }}
          >
            <div>
              <img src={appLogo} alt="" />
            </div>

            <div className="centerImage" style={{ display: 'flex', alignItems: 'center' }}>
              {instituteLogourl ? (
                <div>
                  <img
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50px',
                    }}
                    src={instituteLogourl}
                    alt=""
                  />
                </div>
              ) : (
                <div>
                  <img
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50px',
                    }}
                    src={InstitueDefaultIcon}
                    alt=""
                  />
                </div>
              )}
              {instituteName ? (
                <div style={{ paddingLeft: '5px' }}>{instituteName}</div>
              ) : (
                <div style={{ paddingLeft: '5px' }}> The LNMIIT</div>
              )}
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={gotoplanBillingPage}
            >
              <img src={goBackLeftArrow} alt="left arrow" />
              <div
                style={{
                  display: 'inline',
                  color: '#335AFB',
                  marginLeft: '6px',
                }}
              >
                {' '}
                Back
              </div>
            </div>
          </div>
        )}

        <div className="priceToggleBtn">
          <div>&nbsp;</div>
          <div className="ToggleContainer">
            <div
              className={monthPlanActive ? 'monthBtnDisable' : ' monthBtnActive'}
              onClick={monthlyPlan}
            >
              MONTHLY
            </div>
            <div
              className={yearPlanActive ? 'yearlyBtnDisable' : 'yearlyBtnActive'}
              onClick={yearlyPlan}
            >
              ANNUALLY
              <img src={yearPlanActive ? DiscountBlack : DiscountWhite} alt="discountSymbole" />
            </div>
          </div>

          <div style={{ display: 'none' }}>
            <Button
              style={{
                boxShadow: '0px 3.06px rgba(0, 0, 0, 0.04)',
                width: 143,
                height: 38,
                marginLeft: 5,
                borderRadius: '8px',
                border: '1px solid #000',
                // background: "var(--White)",
              }}
              variant="outlined"
              endIcon={<ArrowDropDownIcon />}
              onClick={(e) => setsorterAnchorEl(e.target)}
            >
              {/* {this.state.sortType} */}
              {/* INR Rupee ($) */}
              {sortType}
            </Button>

            <Menu
              id="sorter-menu"
              anchorEl={sorterAnchorEl}
              keepMounted
              open={Boolean(sorterAnchorEl)}
              onClose={(e) => setsorterAnchorEl(null)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              getContentAnchorEl={null}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              // className={classes.cardMenu}
              // style={{width:'143px'}}
            >
              <MenuItem
                // className={classes.cardItem}
                onClick={
                  () => {
                    setsorterAnchorEl(null);
                    setsortType(' INR Rupee (₹​)');
                    setrupeePrice(true);
                    setdollarPrice(false);
                  }
                  // this.setState({ sortType: "Name", sorterAnchorEl: null })
                }
              >
                INR Rupee (₹​)
              </MenuItem>
              <MenuItem
                // className={classes.cardItem}
                onClick={
                  () => {
                    setsorterAnchorEl(null);
                    setsortType('  US Dollers ($)');
                    setrupeePrice(false);
                    setdollarPrice(true);
                  }
                  // this.setState({ sortType: "Name", sorterAnchorEl: null })
                }
              >
                US Dollers ($)
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div className={classes.root}>
          <Grid
            style={{
              border: '1.2px solid rgba(0,0,0,0.12)',
              // marginBottom: "65px",
            }}
            container
            spacing={0}
          >
            <Grid item xs={12} style={{ display: 'flex' }}>
              <div className="cellStyle">
                <div className="headerTextContainer">
                  <div className="headerHeight" style={{ background: '#328481', padding: '5px 0' }}>
                    <div className="headerText">Free Forever</div>
                    <div className="headerSubTextFree">For teachers who want to get started</div>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    {/* <img
                      className="pricesizeZero"
                      src={ZeroPriceFree}
                      alt="0"
                      style={{ paddingBottom: "18px" }}
                    /> */}

                    <div className="priceDiv" style={{ color: '#328481' }}>
                      <div className="ruppySymbol">{rupeePrice ? `₹` : `$`}</div>
                      <div className="price">0</div>
                    </div>
                    {/* <div
                      className="pricediv"
                      style={{ textAlign: "center", color: "#328481" }}
                    >
                      <span> &#x20B9;</span>
                      <span
                        style={{
                          color: "#328481",
                          fontWeight: "bold",
                          fontFamily: "Poppins",
                        }}
                      >
                        0
                      </span>
                    </div> */}
                  </div>
                  <div className="billedText" style={{ textAlign: 'center' }}>
                    {monthPlanActive ? 'per month billed annually' : 'per month billed monthly'}
                  </div>
                  <div>
                    {/* <img
                      src={Price35}
                      alt=""
                      style={{ paddingBottom: "15px" }}
                    /> */}
                    <div></div>
                  </div>
                  {/* <div className="billedText">per month billed annually</div> */}

                  <div>&nbsp;</div>
                </div>
              </div>
              <div className="cellStyle">
                <div className="headerTextContainer">
                  <div className="headerHeight" style={{ background: '#335AFB', padding: '5px 0' }}>
                    <div className="headerText">Professional</div>
                    <div className="headerSubTextPro">For teachers and single classrooms</div>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    {/* <img
                      className="pricesizePro"
                      src={monthPlanActive ? Price199Pro : Price299Pro}
                      alt="199"
                      style={{ paddingBottom: "18px" }}
                    /> */}
                    <div className="priceDiv" style={{ color: '#335AFB' }}>
                      <div className="ruppySymbol">{rupeePrice ? `₹` : `$`}</div>
                      <div className="price">
                        {rupeePrice
                          ? monthPlanActive
                            ? '199'
                            : '299'
                          : monthPlanActive
                          ? '2.5'
                          : '5'}
                      </div>
                    </div>
                    {/* <div
                      className="pricediv"
                      style={{ textAlign: "center", color: "#335AFB" }}
                    >
                      <div style={{ fontSize: "21px" }}> &#x20B9;</div>
                      <div
                        style={{
                          color: "#335AFB",
                          fontWeight: "bold",
                          fontFamily: "Poppins",
                        }}
                      >
                        199
                      </div>
                    </div> */}
                  </div>
                  <div className="billedText" style={{ textAlign: 'center' }}>
                    {monthPlanActive ? 'per month billed annually' : 'per month billed monthly'}
                  </div>
                  <div>&nbsp;</div>
                </div>
              </div>
              <div className="cellStyle">
                <div className="headerTextContainer">
                  <div className="headerHeight" style={{ background: '#7553B2', padding: '5px 0' }}>
                    <div className="headerText">Advanced</div>
                    <div className="headerSubText">
                      For coaching centres and training institutions
                    </div>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    {/* <img
                      className="pricesize"
                      src={monthPlanActive ? Price1499Adv : Price1999Adv}
                      alt="1499"
                    /> */}
                    <div className="priceDiv" style={{ color: '#7553B2' }}>
                      <div className="ruppySymbol">{rupeePrice ? `₹` : `$`}</div>
                      <div className="price">
                        {rupeePrice
                          ? monthPlanActive
                            ? '1499'
                            : '1999'
                          : monthPlanActive
                          ? '19'
                          : '29'}
                      </div>
                    </div>
                    {/* <div
                      className="pricediv"
                      style={{ textAlign: "center", color: "#7553B2" }}
                    >
                      <span> &#x20B9;</span>
                      <span
                        style={{
                          color: "#7553B2",
                          fontWeight: "bold",
                          fontFamily: "Poppins",
                        }}
                      >
                        1499
                      </span>
                    </div> */}
                  </div>
                  <div className="billedText" style={{ textAlign: 'center' }}>
                    {monthPlanActive ? 'per month billed annually' : 'per month billed monthly'}
                  </div>
                  <div>&nbsp;</div>
                </div>
              </div>

              <div className="cellStyle hide">
                <div className="headerTextContainer">
                  <div className="headerHeight" style={{ background: '#3075D2', padding: '5px 0' }}>
                    <div className="headerText">Institute</div>
                    <div className="headerSubText">For schools and universities</div>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    {/* <img
                      className="pricesize"
                      src={monthPlanActive ? Price4999Ins : Price6999Ins}
                      alt="4999"
                    /> */}
                    <div className="priceDiv" style={{ color: '#3075D2' }}>
                      <div className="ruppySymbol">{rupeePrice ? `₹` : `$`}</div>
                      <div className="price">
                        {rupeePrice
                          ? monthPlanActive
                            ? '4999'
                            : '6999'
                          : monthPlanActive
                          ? '69'
                          : '99'}
                      </div>
                    </div>
                    {/* <div>
                      <div
                        className="pricediv"
                        style={{ textAlign: "center", color: "#3075D2" }}
                      >
                        <span> &#x20B9;</span>
                        <span
                          style={{
                            color: "#3075D2",
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                          }}
                        >
                          4999
                        </span>
                      </div>
                    </div> */}
                  </div>
                  <div className="billedText" style={{ textAlign: 'center' }}>
                    {monthPlanActive ? 'per month billed annually' : 'per month billed monthly'}
                  </div>
                  <div>&nbsp;</div>
                </div>
              </div>
              <div className="cellStyle" style={{ borderRight: 'none' }}>
                <div className="headerTextContainer">
                  <div className="headerHeight" style={{ background: '#EDEFF1', padding: '5px 0' }}>
                    <div className="firstPageHeader">
                      <div className="MainHeaderTitle">Institute</div>

                      <div className="MainHeaderSubTitle">
                        For schools, universities & large educational institutions
                      </div>
                      <div className="largeInsituteSubtext">
                        We can customise the plan as per your requirement
                      </div>
                    </div>
                    {/* <div>&nbsp;</div> */}
                    {/* <div className="ButtonText">Contact Sales</div> */}

                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <div
                style={{
                  width: '25%',
                  borderRight: '1px solid rgba(0,0,0,0.12)',
                }}
              >
                {/* {PlanName == "Free" ? ( */}
                {/* <div className="ButtonDivCurrent" style={{ padding: "8px" }}>
                    Current Plan
                  </div>
                ) : ( */}
                <div className="ButtonDiv">
                  {/* {planGrade <= 1 ? ( */}
                  <Button
                    style={{ borderRadius: '25px' }}
                    variant="contained"
                    color="primary"
                    onClick={() => UpgradePlanClick('Free', 'FREE_PLAN')}
                  >
                    Free Forever
                  </Button>
                  {/* ) : (
                      ""
                    )} */}
                </div>
                {/* )} */}
              </div>
              <div
                style={{
                  width: '25%',
                  borderRight: '1px solid rgba(0,0,0,0.12)',
                }}
              >
                {/* {PlanName == "Professional" ? (
                  <div className="ButtonDivCurrent" style={{ padding: "8px" }}>
                    Current Plan
                  </div>
                ) : ( */}
                <div className="ButtonDiv">
                  {/* {planGrade <= 2 ? ( */}
                  <Button
                    style={{ borderRadius: '25px' }}
                    variant="contained"
                    color="primary"
                    onClick={() => UpgradePlanClick('Professional', 'PROFESSIONAL_PLAN')}
                  >
                    Upgrade to Professional
                  </Button>
                  {/* // ) : ( // "" // )} */}
                </div>
                {/* )} */}
              </div>
              <div
                style={{
                  width: '25%',
                  borderRight: '1px solid rgba(0,0,0,0.12)',
                }}
              >
                {/* {PlanName == "Advanced" ? (
                  <div className="ButtonDivCurrent" style={{ padding: "8px" }}>
                    Current Plan
                  </div>
                ) : ( */}
                <div className="ButtonDiv">
                  {/* {planGrade <= 3 ? ( */}
                  <Button
                    style={{ borderRadius: '25px' }}
                    variant="contained"
                    color="primary"
                    onClick={() => UpgradePlanClick('Advanced', 'ADVANCED_PLAN')}
                  >
                    Upgrade to Advanced
                  </Button>
                  {/* ) : (
                      ""
                    )} */}
                </div>
                {/* )} */}
              </div>
              <div
                className="hide"
                style={{
                  width: '20%',
                  borderRight: '1px solid rgba(0,0,0,0.12)',
                }}
              >
                {/* {PlanName == "Institute" ? (
                  <div className="ButtonDivCurrent" style={{ padding: "8px" }}>
                    Current Plan
                  </div>
                ) : ( */}
                <div className="ButtonDiv">
                  {/* {planGrade <= 4 ? ( */}
                  <Button
                    style={{ borderRadius: '25px' }}
                    variant="contained"
                    color="primary"
                    onClick={() => UpgradePlanClick('Institute', 'INSTITUTE_PLAN')}
                  >
                    Upgrade to Institute
                  </Button>
                  {/* ) : (
                      ""
                    )} */}
                </div>
                {/* )} */}
              </div>

              <div
                style={{
                  width: '25%',
                }}
              >
                <div className="ButtonDiv">
                  <Button
                    style={{ padding: '5px 40px', borderRadius: '25px' }}
                    variant="contained"
                    color="primary"
                    onClick={contactSles}
                  >
                    Contact Sales{' '}
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <div className="cellStyle listItemName">
                <div className="itemLeftSpace">What’s Included</div>
              </div>
              <div className="cellStyle listItemName">
                <div className="itemLeftSpace">All Free Forever Features, Plus</div>
              </div>
              <div className="cellStyle listItemName">
                <div className="itemLeftSpace">All Professional Features, Plus</div>
              </div>
              <div className="cellStyle listItemName hide">
                <div className="itemLeftSpace">All Advanced Features, Plus</div>
              </div>
              <div className=" listItemName">
                <div className="itemLeftSpace">&nbsp;</div>
              </div>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <div className="cellStyle listItemPoints">
                <ol>
                  <li>1 Teacher , 1 Administrator</li>
                </ol>
              </div>
              <div className="cellStyle listItemPoints">
                <ol>
                  <li>1 Teacher , 1 Administrator</li>
                </ol>
              </div>
              <div className="cellStyle listItemPoints">
                <ol>
                  <li>10 Teachers , 1 Administrator</li>
                </ol>
              </div>
              <div className="cellStyle listItemPoints hide">
                <ol>
                  <li>Unlimited teachers & Administrators</li>
                </ol>
              </div>
              <div className=" listItemName">
                <div className="itemLeftSpace">&nbsp;</div>
              </div>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <div className="cellStyle listItemPoints">
                <ol>
                  <li>Unlimited students </li>
                </ol>
              </div>
              <div className="cellStyle listItemPoints">
                <ol>
                  <li>Advanced interactive teaching tools </li>
                </ol>
              </div>
              <div className="cellStyle listItemPoints">
                <ol>
                  <li>Unlimited advanced interactive teaching tools access</li>
                </ol>
              </div>
              <div className="cellStyle listItemPoints hide">
                <ol>
                  <li>Unlimited online class at a stretch</li>
                </ol>
              </div>
              <div className=" listItemName">
                <div className="itemLeftSpace">&nbsp;</div>
              </div>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <div className="cellStyle listItemPoints">
                <ol>
                  <li>Unlimited offline class</li>
                </ol>
              </div>
              <div className="cellStyle listItemPoints">
                <ol>
                  <li>Up to 200 lessons, Assessments and self study courses</li>
                </ol>
              </div>
              <div className="cellStyle listItemPoints">
                <ol>
                  <li>Unlimited lessons, Assessments and self study courses</li>
                </ol>
              </div>
              <div className="cellStyle listItemPoints hide">
                <ol>
                  <li>Student attendance management</li>
                </ol>
              </div>
              <div className=" listItemName">
                <div className="itemLeftSpace">&nbsp;</div>
              </div>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <div className="cellStyle listItemPoints">
                <ol>
                  <li>Basic interactive teaching tools </li>
                </ol>
              </div>
              <div className="cellStyle listItemPoints">
                <ol>
                  <li>30 Mins class recording (local & cloud storage)</li>
                </ol>
              </div>
              <div className="cellStyle listItemPoints">
                <ol>
                  <li>Unlimited class recording (local & cloud storage)</li>
                </ol>
              </div>
              <div className="cellStyle listItemPoints hide">
                <ol>
                  <li>School performance dashboard</li>
                </ol>
              </div>
              <div className=" listItemName">
                <div className="itemLeftSpace">&nbsp;</div>
              </div>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <div className="cellStyle listItemPoints">
                <ol>
                  <li>Up to 10 lessons, Assessments and self study courses </li>
                </ol>
              </div>
              <div className="cellStyle listItemPoints">
                <ol>
                  <li>5 GB Cloud storage</li>
                </ol>
              </div>
              <div className="cellStyle listItemPoints">
                <ol>
                  <li>2 hrs Online class at a stretch</li>
                </ol>
              </div>
              <div className="cellStyle listItemPoints hide">
                <ol>
                  <li>Parent app (coming soon) </li>
                </ol>
              </div>
              <div className=" listItemName">
                <div className="itemLeftSpace">&nbsp;</div>
              </div>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <div className="cellStyle listItemPoints">
                <ol>
                  <li>10 Mins class recording (local storage) </li>
                </ol>
              </div>
              <div className="cellStyle listItemPoints">
                {/* <li className="itemLeftSpace">&nbsp;</li> */}
              </div>
              <div className="cellStyle listItemPoints">
                <ol>
                  <li>300 GB Cloud storage</li>
                </ol>
              </div>
              <div className="cellStyle listItemPoints hide">
                <ol>
                  <li>Tailored onboarding support </li>
                </ol>
              </div>
              <div className=" listItemName">
                <div className="itemLeftSpace">&nbsp;</div>
              </div>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <div className="cellStyle listItemPoints">
                <ol>
                  <li>30 Mins online class at a stretch</li>
                </ol>
              </div>
              <div className="cellStyle listItemPoints">
                {/* <li className="itemLeftSpace">&nbsp;</li> */}
              </div>
              <div className="cellStyle listItemPoints">
                {/* <li className="itemLeftSpace">&nbsp;</li> */}
              </div>
              <div className="cellStyle listItemPoints hide">
                <ol>
                  <li>Priority support </li>
                </ol>
              </div>
              <div className=" listItemName">
                <div className="itemLeftSpace">&nbsp;</div>
              </div>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <div className="cellStyle listItemPoints">
                <ol>
                  <li>500 MB Cloud storage</li>
                </ol>
              </div>
              <div className="cellStyle listItemPoints">
                {/* <li className="itemLeftSpace">&nbsp;</li> */}
              </div>
              <div className="cellStyle listItemPoints">
                {/* <li className="itemLeftSpace">&nbsp;</li> */}
              </div>
              <div className="cellStyle listItemPoints hide">
                <ol>
                  <li>750 GB Cloud storage </li>
                </ol>
              </div>
              <div className=" listItemName">
                <div className="itemLeftSpace">&nbsp;</div>
              </div>
            </Grid>
          </Grid>
          <div ref={titleRef} />
          <div className="tableshowbutton" onClick={showTableClick}>
            See full feature matrix &nbsp;
            {showTable == true ? <img src={UpArrowBlack} /> : <img src={DownArrowBlack} />}
          </div>

          {showTable == true ? (
            <Grid
              style={{
                border: '1.2px solid rgba(0,0,0,0.12)',
                marginBottom: '65px',
                marginTop: '60px',
              }}
              container
              spacing={0}
            >
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div
                  className="cellStyle bottomBorderStyle"
                  style={{
                    width: '25%',
                    fontWeight: 600,
                    background: '#F9F9F9',
                    padding: '5px 0 5px 0',
                  }}
                >
                  <div className="itemLeftSpace font12 ">Essentials</div>
                </div>
                <div className="cellStyle font12 firstLinesubtitleContent"> Free Forever</div>
                <div className="cellStyle font12 firstLinesubtitleContent"> Professional</div>
                <div className="cellStyle font12 firstLinesubtitleContent lastlistItemLimit">
                  {' '}
                  Advanced
                </div>
                <div className="cellStyle  font12  firstLinesubtitleContent hide"> Institute</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle">&nbsp;</div>
                <div className="cellStyle">&nbsp;</div>
                <div className="cellStyle">&nbsp;</div>
                <div className="cellStyle lastlistItemLimit">&nbsp;</div>
                <div className="cellStyle  hide">&nbsp;</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle">&nbsp;</div>
                <div className="cellStyle">&nbsp;</div>
                <div className="cellStyle">&nbsp;</div>
                <div className="cellStyle lastlistItemLimit">&nbsp;</div>
                <div className="cellStyle  hide">&nbsp;</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Students</div>
                </div>
                <div className="cellStyle listItemLimit">Unlimited</div>
                <div className="cellStyle listItemLimit">Unlimited</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">Unlimited</div>
                <div className="cellStyle listItemLimit  hide">Unlimited</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Teachers</div>
                </div>
                <div className="cellStyle listItemLimit">1</div>
                <div className="cellStyle listItemLimit">1</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">10</div>
                <div className="cellStyle listItemLimit  hide">Unlimited</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Administrator</div>
                </div>
                <div className="cellStyle listItemLimit">1</div>
                <div className="cellStyle listItemLimit">1</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">1</div>
                <div className="cellStyle listItemLimit  hide">Unlimited</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Offline Class</div>
                </div>
                <div className="cellStyle listItemLimit">Unlimited</div>
                <div className="cellStyle listItemLimit">Unlimited</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">Unlimited</div>
                <div className="cellStyle listItemLimit  hide">Unlimited</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Class Recording Duration</div>
                </div>
                <div className="cellStyle listItemLimit">
                  <div style={{ paddingRight: '20px', paddingLeft: '20px' }}>10 mins</div>
                </div>
                <div className="cellStyle listItemLimit">30 mins</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">Unlimited</div>
                <div className="cellStyle listItemLimit  hide">Unlimited</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Class Recording Storage</div>
                </div>
                <div className="cellStyle listItemLimit">
                  <div style={{ paddingRight: '20px', paddingLeft: '20px' }}>
                    (Low) Storage on Local device
                  </div>
                </div>
                <div className="cellStyle listItemLimit">Local & Cloud storage</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">
                  Local & Cloud storage
                </div>
                <div className="cellStyle listItemLimit  hide">Local & Cloud storage</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Recording Quality</div>
                </div>
                <div className="cellStyle listItemLimit">Yes</div>
                <div className="cellStyle listItemLimit">Yes</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">Yes</div>
                <div className="cellStyle listItemLimit  hide">Yes</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Collaborative Canvas</div>
                </div>
                <div className="cellStyle listItemLimit">Yes</div>
                <div className="cellStyle listItemLimit">Yes</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">Yes</div>
                <div className="cellStyle listItemLimit  hide">Yes</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Online Class</div>
                </div>
                <div className="cellStyle listItemLimit">Yes</div>
                <div className="cellStyle listItemLimit">Yes</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">Yes</div>
                <div className="cellStyle listItemLimit  hide">Yes</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Broadcast Class on YouTube, Facebook etc</div>
                </div>
                <div className="cellStyle listItemLimit">No</div>
                <div className="cellStyle listItemLimit">Yes</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">Yes</div>
                <div className="cellStyle listItemLimit  hide">Yes</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Cloud Storage</div>
                </div>
                <div className="cellStyle listItemLimit">500 MB</div>
                <div className="cellStyle listItemLimit">5 GB</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">300 GB</div>
                <div className="cellStyle listItemLimit  hide">750 GB</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div
                  className="cellStyle borderStyle"
                  style={{
                    width: '25%',
                    padding: '5px 0 5px 0px',
                    fontWeight: 600,
                    background: '#F9F9F9',
                  }}
                >
                  <div className="itemLeftSpace font12 ">Teaching Tools</div>
                </div>
                <div className="cellStyle font12 subtitleContent"> Free Forever</div>
                <div className="cellStyle font12 subtitleContent"> Professional</div>
                <div className="cellStyle font12 subtitleContent lastlistItemLimit">Advanced</div>
                <div className="cellStyle font12  subtitleContent hide">Institute</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Basic Interactive Teaching Tools</div>
                </div>
                <div className="cellStyle listItemLimit">Yes</div>
                <div className="cellStyle listItemLimit">Yes</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">Yes</div>
                <div className="cellStyle listItemLimit  hide">Yes</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">MyCloud Educational Content Web Search</div>
                </div>
                <div className="cellStyle listItemLimit">Unlimited</div>
                <div className="cellStyle listItemLimit">Unlimited</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">Unlimited</div>
                <div className="cellStyle listItemLimit hide">Unlimited</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Prebuilt Content Repository</div>
                </div>
                <div className="cellStyle listItemLimit">Unlimited</div>
                <div className="cellStyle listItemLimit">Unlimited</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">Unlimited</div>
                <div className="cellStyle listItemLimit hide">Unlimited</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Automatic Math & Text Recognition</div>
                </div>
                <div className="cellStyle listItemLimit">No (Unlock & Free Trial for 1 month)</div>
                <div className="cellStyle listItemLimit">100/month</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">500/month</div>
                <div className="cellStyle listItemLimit hide">Unlimited</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">AI Course Creation</div>
                </div>
                <div className="cellStyle listItemLimit">No (Unlock & Free Trial for 1 month)</div>
                <div className="cellStyle listItemLimit">200</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">Unlimited</div>
                <div className="cellStyle listItemLimit hide">Unlimited</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">AI Question Generation</div>
                </div>
                <div className="cellStyle listItemLimit">No (Unlock & Free Trial for 1 month)</div>
                <div className="cellStyle listItemLimit">100/month</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">500/month</div>
                <div className="cellStyle listItemLimit hide">Unlimited</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div
                  className="cellStyle borderStyle"
                  style={{
                    width: '25%',
                    padding: '5px 0 5px 0px',
                    fontWeight: 600,
                    background: '#F9F9F9',
                  }}
                >
                  <div className="itemLeftSpace font12 ">Content Assets</div>
                </div>
                <div className="cellStyle font12 subtitleContent"> Free Forever</div>
                <div className="cellStyle font12 subtitleContent"> Professional</div>
                <div className="cellStyle font12 subtitleContent lastlistItemLimit">Advanced</div>
                <div className="cellStyle  font12 subtitleContent hide">Institute</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Lessons</div>
                </div>
                <div className="cellStyle listItemLimit">10</div>
                <div className="cellStyle listItemLimit">200</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">Unlimited</div>
                <div className="cellStyle listItemLimit  hide">Unlimited</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Assessments</div>
                </div>
                <div className="cellStyle listItemLimit">10</div>
                <div className="cellStyle listItemLimit">200</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">Unlimited</div>
                <div className="cellStyle listItemLimit  hide">Unlimited</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Self Study Courses</div>
                </div>
                <div className="cellStyle listItemLimit">10</div>
                <div className="cellStyle listItemLimit">200</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">Unlimited</div>
                <div className="cellStyle listItemLimit  hide">Unlimited</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div
                  className="cellStyle borderStyle"
                  style={{
                    width: '25%',
                    padding: '5px 0 5px 0px',
                    fontWeight: 600,
                    background: '#F9F9F9',
                  }}
                >
                  <div className="itemLeftSpace font12 ">Online Classes</div>
                </div>
                <div
                  className="cellStyle font12 subtitleContent"
                  style={{
                    width: '25%',
                    background: '#F9F9F9',
                    textAlign: 'center',
                    paddingTop: '5px',
                  }}
                >
                  {' '}
                  Free Forever
                </div>
                <div
                  className="cellStyle font12 subtitleContent"
                  style={{
                    width: '25%',
                    background: '#F9F9F9',
                    textAlign: 'center',
                    paddingTop: '5px',
                  }}
                >
                  {' '}
                  Professional
                </div>
                <div
                  className="cellStyle font12 subtitleContent lastlistItemLimit"
                  style={{
                    width: '25%',
                    background: '#F9F9F9',
                    textAlign: 'center',
                    paddingTop: '5px',
                  }}
                >
                  Advanced
                </div>
                <div
                  className="cellStyle  font12 subtitleContent hide"
                  style={{
                    width: '20%',
                    background: '#F9F9F9',
                    textAlign: 'center',
                    paddingTop: '5px',
                  }}
                >
                  Institute
                </div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Online Class Limits</div>
                </div>
                <div className="cellStyle listItemLimit">30 mins at a stretch</div>
                <div className="cellStyle listItemLimit">60 mins at a stretch</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">2 hrs at a stretch</div>
                <div className="cellStyle listItemLimit  hide">Unlimited</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Simultaneous Online Class</div>
                </div>
                <div className="cellStyle listItemLimit">1 Class with 10 students per class</div>
                <div className="cellStyle listItemLimit">1 Class with 50 students per class</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">
                  3 Class with 50 students per class
                </div>
                <div className="cellStyle listItemLimit  hide">
                  10 Class with 100 students per class
                </div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace"> Online Class Limit per Day</div>
                </div>
                <div className="cellStyle listItemLimit">5</div>
                <div className="cellStyle listItemLimit">5</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">15</div>
                <div className="cellStyle listItemLimit  hide">75</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">
                    {' '}
                    Integration with Other Video Conferencing Applications
                  </div>
                </div>
                <div className="cellStyle listItemLimit">Yes</div>
                <div className="cellStyle listItemLimit">Yes</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">Yes</div>
                <div className="cellStyle listItemLimit  hide">Yes</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div
                  className="cellStyle borderStyle"
                  style={{
                    width: '25%',
                    padding: '5px 0 5px 0px',
                    fontWeight: 600,
                    background: '#F9F9F9',
                  }}
                >
                  <div className="itemLeftSpace font12">Administration</div>
                </div>
                <div className="cellStyle font12 subtitleContent"> Free Forever</div>
                <div className="cellStyle font12 subtitleContent"> Professional</div>
                <div className="cellStyle font12 subtitleContent lastlistItemLimit">Advanced</div>
                <div className="cellStyle font12  subtitleContent hide">Institute</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace"> Class Invite via Link</div>
                </div>
                <div className="cellStyle listItemLimit">Yes</div>
                <div className="cellStyle listItemLimit">Yes</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">Yes</div>
                <div className="cellStyle listItemLimit  hide">Yes</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace"> Class Scheduler</div>
                </div>
                <div className="cellStyle listItemLimit">Yes</div>
                <div className="cellStyle listItemLimit">Yes</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">Yes</div>
                <div className="cellStyle listItemLimit  hide">Yes</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace"> Student Attendance</div>
                </div>
                <div className="cellStyle listItemLimit">No (Unlock & Free Trial for 1 month)</div>
                <div className="cellStyle listItemLimit">No (Unlock & Free Trial for 1 month)</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">
                  No (Unlock & Free Trial for 1 month)
                </div>
                <div className="cellStyle listItemLimit  hide">Yes</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace"> Admin Management Portal</div>
                </div>
                <div className="cellStyle listItemLimit">No (Unlock & Free Trial for 1 month)</div>
                <div className="cellStyle listItemLimit">No (Unlock & Free Trial for 1 month)</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">
                  No (Unlock & Free Trial for 1 month)
                </div>
                <div className="cellStyle listItemLimit  hide">Yes</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace"> Classroom Performance Dashboard</div>
                </div>
                <div className="cellStyle listItemLimit">No (Unlock & Free Trial for 1 month)</div>
                <div className="cellStyle listItemLimit">No (Unlock & Free Trial for 1 month)</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">
                  No (Unlock & Free Trial for 1 month)
                </div>
                <div className="cellStyle listItemLimit  hide">Yes</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace"> School/ Institute Performance Dashboard</div>
                </div>
                <div className="cellStyle listItemLimit">No (Unlock & Free Trial for 1 month)</div>
                <div className="cellStyle listItemLimit">No (Unlock & Free Trial for 1 month)</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">
                  No (Unlock & Free Trial for 1 month)
                </div>
                <div className="cellStyle listItemLimit  hide">Yes</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace"> Class Communication Wall</div>
                </div>
                <div className="cellStyle listItemLimit">No (Unlock & Free Trial for 1 month)</div>
                <div className="cellStyle listItemLimit">No (Unlock & Free Trial for 1 month)</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">
                  No (Unlock & Free Trial for 1 month)
                </div>
                <div className="cellStyle listItemLimit  hide">Yes</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace"> Parent App</div>
                </div>
                <div className="cellStyle listItemLimit">No</div>
                <div className="cellStyle listItemLimit">No</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">No</div>
                <div className="cellStyle listItemLimit  hide">Yes</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div
                  className="cellStyle borderStyle"
                  style={{
                    width: '25%',
                    padding: '5px 0 5px 0px',
                    fontWeight: 600,
                    background: '#F9F9F9',
                  }}
                >
                  <div className="itemLeftSpace font12 ">Support</div>
                </div>
                <div className="cellStyle font12 subtitleContent"> Free Forever</div>
                <div className="cellStyle font12 subtitleContent"> Professional</div>
                <div className="cellStyle font12 subtitleContent lastlistItemLimit">Advanced</div>
                <div className="cellStyle font12   subtitleContent hide">Institute</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Self-serve Knowledge Base</div>
                </div>
                <div className="cellStyle listItemLimit">Yes</div>
                <div className="cellStyle listItemLimit">Yes</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">Yes</div>
                <div className="cellStyle listItemLimit  hide">Yes</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Personalized Support</div>
                </div>
                <div className="cellStyle listItemLimit">No</div>
                <div className="cellStyle listItemLimit">No</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">No</div>
                <div className="cellStyle listItemLimit  hide">Yes</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Tailored Onboarding</div>
                </div>
                <div className="cellStyle listItemLimit">No</div>
                <div className="cellStyle listItemLimit">No</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">No</div>
                <div className="cellStyle listItemLimit  hide">Yes</div>
              </Grid>{' '}
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">Migrate Data from Other LMS</div>
                </div>
                <div className="cellStyle listItemLimit">No</div>
                <div className="cellStyle listItemLimit">No</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">No</div>
                <div className="cellStyle listItemLimit  hide">Yes</div>
              </Grid>{' '}
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">
                  <div className="itemLeftSpace">API Integration</div>
                </div>
                <div className="cellStyle listItemLimit">No</div>
                <div className="cellStyle listItemLimit">No</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">No</div>
                <div className="cellStyle listItemLimit  hide">Yes</div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle listItemName">&nbsp;</div>
                <div className="cellStyle listItemLimit">&nbsp;</div>
                <div className="cellStyle listItemLimit">&nbsp;</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">&nbsp;</div>
                <div className="cellStyle listItemLimit  hide"></div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div className="cellStyle">
                  <div className="TableFooterText">*Unlimited terms are subjected to FUP</div>
                </div>
                <div className="cellStyle listItemLimit">&nbsp;</div>
                <div className="cellStyle listItemLimit">&nbsp;</div>
                <div className="cellStyle listItemLimit lastlistItemLimit">&nbsp;</div>
                <div className="cellStyle listItemLimit  hide"></div>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default UpgradePlan;
