import React from 'react';
import Color from 'color';
import { ButtonBase, Typography, makeStyles } from '@material-ui/core';

import { addCircleIcon } from '../../../assets/icons';

const useStyles = makeStyles((theme) => {
  const primaryColor = new Color('#EAEEFF');
  const hoverColor = primaryColor.saturate(1).darken(0.05).hex();
  return {
    newNotebookRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 240,
      borderRadius: 12,
      backgroundColor: '#EAEEFF',
      '&:hover': {
        backgroundColor: hoverColor,
      },
      '& .MuiSvgIcon-root': {
        width: 64,
        height: 64,
      },
    },
  };
});

const NewNotebook = ({ onClick }) => {
  const classes = useStyles();
  return (
    <ButtonBase className={classes.newNotebookRoot} onClick={onClick}>
      <div>
        <img src={addCircleIcon} alt="add" width={72} height={72} />
        <Typography color="primary">New Notebook</Typography>
      </div>
    </ButtonBase>
  );
};

export default NewNotebook;
