import React, { useMemo } from 'react';
import { Box, ButtonBase, Typography, makeStyles, darken } from '@material-ui/core';

import AgentAvatar from '../agent-avatar';

const useStyles = ({ mini, micro, isActive }) =>
  makeStyles((theme) => ({
    agentCardRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      gap: micro ? 2 : mini ? 4 : 8,
      padding: mini || micro ? 4 : '4px 8px',
      borderRadius: 4,
      backgroundColor: isActive ? '#EAEEFF' : mini ? undefined : '#EAEEFF',
      '&:hover': {
        backgroundColor: micro ? undefined : isActive ? '#EAEEFF' : darken('#EAEEFF', 0.02),
      },
      '& .agent-title': {
        fontSize: micro ? 10 : mini ? 11 : 14,
        color: '#767676',
        textAlign: 'left',
        textTransform: 'none !important',
      },
      '& .agent-description': {
        display: mini || micro ? 'none' : 'block',
        fontSize: 10,
        color: '#767676',
        textAlign: 'left',
        textTransform: 'none !important',
      },
      [theme.breakpoints.down('md')]: {
        width: micro ? undefined : mini ? 'calc(50% - 4px)' : 'calc(50% - 12px)',
      },
      [theme.breakpoints.down('sm')]: {
        width: micro ? undefined : '100%',
      },
    },
  }));

const AgentCard = ({
  name,
  title,
  description,
  icon,
  background,
  onClick,
  mini,
  micro,
  isActive,
}) => {
  const classes = useStyles({ mini, micro, isActive })();

  const clickHandler = () => onClick && onClick(name);

  const Component = useMemo(() => (micro ? Box : ButtonBase), [micro]);

  return (
    <Component className={classes.agentCardRoot} onClick={clickHandler}>
      <AgentAvatar title={title} icon={icon} background={background} mini={mini} micro={micro} />
      <div>
        <Typography className="agent-title" gutterBottom={false}>
          {title}
        </Typography>
        <Typography className="agent-description" gutterBottom={false}>
          {description}
        </Typography>
      </div>
    </Component>
  );
};

export default AgentCard;
