import { Button, makeStyles, Slider } from '@material-ui/core';
import { ArrowDropDownCircleOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import CallOnIcon from '../../../assets/icons/callOnIcon';
import DropLinkIcon from '../../../assets/icons/dropLinkIcon';
import MicOnIcon from '../../../assets/icons/micOnIcon';
import VideoOnIcon from '../../../assets/icons/videoOnIcon';
import VolumeOnIcon from '../../../assets/icons/volumeOnIcon';
import deviceService from '../../../services/device.service';
import { useNotificationDispatcher } from '../../../store/dispatcher/useNotificationDispatcher';
import {
  getInstantMeetingLink,
  getInstantMeetingName,
  getRoomName,
} from '../../../Utils/authentication-access';

const useStyles = makeStyles((theme) => ({
  bgContainer: {
    width: '64px',
    height: '64px',
    background: '#343434',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50px',
    '&:hover': {
      opacity: '0.9',
    },
  },
  iconsConainer: {
    display: 'flex',
    gap: '32px',
  },
  subContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  mainContainer: {
    background: '#1e1e1e',
    height: '100vh',
  },
  text1: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
  },
  text2: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '32px',
    lineHeight: '38px',
    color: '#FFFFFF',
    margin: '17px 0 57px',
  },
  volumeContainer: {
    display: 'flex',
    gap: '15px',
    padding: '25px',
  },
}));
const LiveMeeting = () => {
  const classes = useStyles();
  const [value, setValue] = useState(50);
  let history = useHistory();
  const { dispatchSetSnackbar } = useNotificationDispatcher();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const meetingValues = 'MIC_ON';
    meetingControllerHandler(meetingValues);
  }, []);

  const meetingControllerHandler = async (meetingValues) => {
    try {
      const res = await deviceService.sendMeetingCommands(meetingValues);
      console.log('resMeeting', res);
      if (meetingValues === 'MEETING_END') {
        history.goBack();
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const copyLinkHandler = () => {
    navigator.clipboard.writeText(getInstantMeetingLink());
    dispatchSetSnackbar({ message: 'Link copied to clipboard' });
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.subContainer}>
        <div className={classes.text1}>Meeting live on {getRoomName()}</div>
        <div className={classes.text2}>{getInstantMeetingName() || 'Untitled meeting'}</div>

        <div className={classes.iconsConainer}>
          <Button
            className={classes.bgContainer}
            onClick={() => {
              meetingControllerHandler('MIC_OFF');
            }}
          >
            <MicOnIcon />
          </Button>
          <Button
            className={classes.bgContainer}
            onClick={() => {
              meetingControllerHandler('VIDEO_ON');
            }}
          >
            <VideoOnIcon />
          </Button>
          <Button
            className={classes.bgContainer}
            style={{ background: '#E75B5C' }}
            onClick={() => {
              meetingControllerHandler('MEETING_END');
            }}
          >
            <CallOnIcon />
          </Button>
        </div>

        <div className={classes.volumeContainer}>
          <VolumeOnIcon />
          <div style={{ width: '180px' }}>
            <Slider value={value} onChange={handleChange} aria-labelledby="continuous-slider" />
          </div>
        </div>
        <Button
          onClick={copyLinkHandler}
          color="primary"
          variant="contained"
          startIcon={<DropLinkIcon fill={'#fff'} width={24} height={24} />}
          style={{
            background: '#335AFB',
            borderRadius: '8px',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '14px',
            color: '#FFFFFF',
            textTransform: 'none',
          }}
        >
          Copy meeting link
        </Button>
      </div>
    </div>
  );
};

export default LiveMeeting;
