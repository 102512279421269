import { Button } from '@material-ui/core';
import React from 'react';
import moment from 'moment';
import RepeatIcon from '@material-ui/icons/Repeat';
import CalendarIcon from '../../../../../assets/icons/CalendarIcon';
import MeetingOptions from '../../meeting-options';
import DeleteDialog from '../DeleteDialog';
import { useMeetingCardStyles } from './component.style';
import { EventDateTimeInfoContainer } from '../../import-outlook-event-dialog/event-date-time-info/container';
import StyledButton from '../../../../../UIComponents/input/StyledButton';
import { useSchedulesSelector } from '../../../../../store/selector/useSchedulesSelector';
import {
  checkIsRecurring,
  getEndTime,
  getStartTime,
} from '../../../../../Utils/microsoft/outlook-event';
import { checkIsAuthUser } from '../../../../../Utils/authentication-access';

const MeetingCard = (props) => {
  const {
    checkIsAllScheduleFilter,
    closeDeleteDialog,
    disableOptions,
    enableEditSchedule,
    goToMeeting,
    importToSpace,
    isGoToMeetingButtonDisabled,
    isGoToMeetingButtonLoading,
    onSubmit,
    openDeleteDialog,
    openDialog,
    schedule,
    dateTimeFormat,
  } = props;

  const classes = useMeetingCardStyles();
  const { outlookImportedEventsIds } = useSchedulesSelector();
  const isAuthUser = checkIsAuthUser();

  const startTime = getStartTime(schedule);
  const endTime = getEndTime(schedule);
  const startTimeText = moment(startTime).format('hh:mm A');
  const endTimeText = moment(endTime).format('hh:mm A');
  const nextScheduleDateText = moment(schedule.scheduleStartTime).format('DD-MM-YYYY');
  const nextScheduleDate =
    checkIsAllScheduleFilter && checkIsAllScheduleFilter() ? `${nextScheduleDateText}, ` : <></>;
  const timeText = (
    <div className={classes.timeText}>
      {nextScheduleDate}
      {startTimeText} - {endTimeText}
    </div>
  );

  const repeatSchedule =
    schedule?.occurence !== 'NONE' ? <RepeatIcon style={{ fontSize: '1rem' }} /> : <></>;

  const goToMeetingButton = (
    <StyledButton
      color="primary"
      variant="outlined"
      onClick={goToMeeting}
      disabled={isGoToMeetingButtonDisabled || isGoToMeetingButtonLoading}
      loading={isGoToMeetingButtonLoading}
      spinnerSize="1.5rem"
    >
      Go to meeting
    </StyledButton>
  );

  /**
   * the event ICalId has zero in character position 32 to 39
   * e.g if calendarViewItemCalId is A0001A or A0002A of A0003A, return will be A0000A
   */
  const generateEventICalUId = (calendarViewItemCalId) => {
    const p1 = calendarViewItemCalId.substr(0, 32);
    const p2 = calendarViewItemCalId.substr(40);
    const m = '00000000';
    return p1 + m + p2;
  };

  const checkIsImportDisabled = () => {
    if (!schedule?.iCalUId) {
      return true;
    }

    console.log('debug: schedule', schedule);
    const eventICalUId = generateEventICalUId(schedule.iCalUId);
    return outlookImportedEventsIds.some((item) => item.iCalUId === eventICalUId);
  };

  const isImportDisabled = checkIsImportDisabled();

  const importToSpaceButton = importToSpace ? (
    <StyledButton
      color="primary"
      variant="outlined"
      onClick={() => importToSpace(schedule)}
      spinnerSize="1.5rem"
      disabled={isImportDisabled}
    >
      Import to space
    </StyledButton>
  ) : (
    <></>
  );

  const displayImportToSpaceButton = importToSpace ? importToSpaceButton : <></>;

  const isRecurring = checkIsRecurring(schedule);
  const isAllDay = schedule?.isAllDay;

  const displayDateAndStartEndTime = (
    <EventDateTimeInfoContainer
      isRecurring={isRecurring}
      startTime={startTime}
      endTime={endTime}
      isAllDay={isAllDay}
      dateTimeFormat={dateTimeFormat}
    />
  );

  const detailsContainer = (
    <div className={classes.detailsContainer}>
      <CalendarIcon />
      <div style={{ marginLeft: '18px' }}>
        <div className={classes.subText}>{schedule.title || schedule.subject}</div>
        <div className={classes.timeContainer}>
          {/* {timeText} {repeatSchedule} */}
          {displayDateAndStartEndTime}
        </div>
      </div>
    </div>
  );

  const meetingOptionsButton = !disableOptions ? (
    <MeetingOptions
      openDeleteDialog={openDeleteDialog}
      enableEditSchedule={enableEditSchedule}
      schedule={schedule}
    />
  ) : (
    <></>
  );

  const actionsContainer = (
    <div className={classes.actionsContainer}>
      {goToMeetingButton}
      {displayImportToSpaceButton}
      {isAuthUser && meetingOptionsButton}
    </div>
  );

  return (
    <>
      <div className={classes.container}>
        {detailsContainer}
        {actionsContainer}
      </div>

      <DeleteDialog
        openDialog={openDialog}
        closeDeleteDialog={closeDeleteDialog}
        onSubmit={onSubmit}
        meetingName={''}
      />
    </>
  );
};

export default MeetingCard;
