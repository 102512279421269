import { useDispatch } from 'react-redux';
import huddleService from '../../services/huddle.service';
import {
  setHuddleOffline,
  setHuddleOnline,
  setHuddleUserOffline,
  setHuddleUserOnline,
  setOpenHuddleInvitation,
  setIsViewOpen,
  startHuddle,
  leaveHuddle,
  huddleEnded,
  huddleStarted,
} from '../actions/huddle.action';

export const useHuddleDispatcher = () => {
  const dispatch = useDispatch();

  const dispatchSetHuddleOffline = () => {
    dispatch(setHuddleOffline());
  };
  const dispatchSetHuddleOnline = () => {
    dispatch(setHuddleOnline());
  };

  const dispatchSetHuddleUserOffline = () => {
    dispatch(setHuddleUserOffline());
  };

  /**
   * @param {{ url: string }} payload 
   */
  const dispatchSetHuddleUserOnline = (payload) => {
    dispatch(setHuddleUserOnline(payload));
  };

  /**
   * @param {{ canvasCode: string }} payload 
   */
  const dispatchStartHuddle = async (payload) => {
    const { data: { url } } = await huddleService.start(payload);
    dispatch(startHuddle({ url }));
  }

  const dispatchLeaveHuddle = async () => {
    dispatch(leaveHuddle());    
  }

  const dispatchShowHuddleInvitation = async () => {
    dispatch(setOpenHuddleInvitation(true));
  }

  const dispatchHideHuddleInvitation = async () => {
    dispatch(setOpenHuddleInvitation(false));
  }

  const dispatchShowBBBViewer = async () => {
    dispatch(setIsViewOpen(true));
  }

  const dispatchHideBBBViewer = async () => {
    dispatch(setIsViewOpen(false));
  }

  const dispatchHuddleEnded = async () => {
    dispatch(huddleEnded());
  }

  const dispatchHuddleStarted = async () => {
    dispatch(huddleStarted());
  }

  return {
    dispatchHideBBBViewer,
    dispatchHideHuddleInvitation,
    dispatchHuddleEnded,
    dispatchHuddleStarted,
    dispatchLeaveHuddle,
    dispatchSetHuddleOffline,
    dispatchSetHuddleOnline,
    dispatchSetHuddleUserOffline,
    dispatchSetHuddleUserOnline,
    dispatchShowBBBViewer,
    dispatchShowHuddleInvitation,
    dispatchStartHuddle,
  };
};
