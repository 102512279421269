import React from 'react';

export const HelpWhiteIcon = ({ width, color }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <g clip-path="url(#clip0_4547_40788)">
          <path
            d="M12.5344 14.5968H10.9309C10.9367 14.0806 10.9855 13.6586 11.0775 13.3306C11.1752 12.9973 11.3332 12.6935 11.5516 12.4194C11.77 12.1452 12.0602 11.8333 12.4223 11.4839C12.6867 11.2312 12.928 10.9946 13.1464 10.7742C13.3706 10.5484 13.5516 10.3065 13.6895 10.0484C13.8275 9.78495 13.8964 9.47043 13.8964 9.10484C13.8964 8.73387 13.8246 8.41398 13.6809 8.14516C13.543 7.87634 13.3361 7.66935 13.0602 7.52419C12.7901 7.37903 12.4539 7.30645 12.0516 7.30645C11.7183 7.30645 11.4022 7.3629 11.1033 7.47581C10.8045 7.58871 10.5631 7.76344 10.3792 8C10.1953 8.23118 10.1005 8.53495 10.0947 8.91129H8.49988C8.51137 8.30376 8.67229 7.78226 8.98264 7.34677C9.29873 6.91129 9.72402 6.57796 10.2585 6.34677C10.793 6.11559 11.3907 6 12.0516 6C12.7815 6 13.4022 6.12366 13.9137 6.37097C14.4309 6.61828 14.8246 6.97312 15.0947 7.43548C15.3648 7.89247 15.4999 8.43548 15.4999 9.06452C15.4999 9.54839 15.3936 9.99462 15.1809 10.4032C14.974 10.8065 14.7068 11.1855 14.3792 11.5403C14.0516 11.8952 13.7039 12.2339 13.3361 12.5565C13.02 12.8306 12.8073 13.1398 12.6982 13.4839C12.589 13.828 12.5344 14.1989 12.5344 14.5968ZM10.8619 17.1532C10.8619 16.9113 10.9424 16.707 11.1033 16.5403C11.2642 16.3737 11.497 16.2903 11.8016 16.2903C12.1119 16.2903 12.3476 16.3737 12.5085 16.5403C12.6694 16.707 12.7499 16.9113 12.7499 17.1532C12.7499 17.3844 12.6694 17.5833 12.5085 17.75C12.3476 17.9167 12.1119 18 11.8016 18C11.497 18 11.2642 17.9167 11.1033 17.75C10.9424 17.5833 10.8619 17.3844 10.8619 17.1532Z"
            fill={color}
          />
        </g>
        <circle cx="12" cy="12" r="11.5" stroke={color} />
      </g>
      <defs>
        <clipPath id="clip0_4547_40788">
          <rect width="14" height="14" fill="white" transform="translate(5 5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
