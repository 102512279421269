import React from 'react';
import { Dialog, makeStyles } from '@material-ui/core';
import StyledHeader from './StyledHeader';
import StyledFooter from './StyledFooter';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  middleContainer: {
    padding: '19px 42px',
  },
}));

export const StyledDialog = (props) => {
  const { t } = useTranslation();

  const { title, onClose, onSubmit, open, submitText, hideCloseBtn, cancelBtnText, href } = props;
  const classes = useStyles();

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      className="overlay"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 16,
        },
      }}
    >
      <StyledHeader title={title} onClose={onClose} />
      <div className={classes.middleContainer}>{props.children}</div>
      <StyledFooter
        onClose={onClose}
        onSubmit={onSubmit}
        submitText={submitText}
        hideCloseBtn={hideCloseBtn}
        cancelBtnText={cancelBtnText}
        href={href}
      />
    </Dialog>
  );
};

export default StyledDialog;
