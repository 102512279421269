import React from 'react';
import {
  Typography,
  TextField,
  Dialog,
  Button,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const RenameSpace = ({
  renameDialogOpen,
  handleClose,
  renameBoardInput,
  handleRenameBoardInput,
  onEnterKeyPressRenameBoard,
  boardRenameErrorTextMessage,
  parentName,
  reNameBoardClick,
}) => {
  const isMobileView = useMediaQuery('(max-width:600px)');

  return (
    <div>
      <Dialog
        open={renameDialogOpen}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'sm'}
        PaperProps={{
          style: {
            borderRadius: 16,
          },
        }}
      >
        <div
          style={{
            padding: ' 2% 2%',
            display: 'grid',
            gridTemplateColumns: '90% 10%',
            alignItems: 'center',
            minHeight: '30px',
            borderBottom: '1px solid rgba(0,0,0,0.12)',
          }}
        >
          <div
            style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '28px',
              textAlign: 'center',
              marginLeft: '50px',
              color: 'rgba(0, 0, 0)',
            }}
          >
            Rename Whiteboard
          </div>
          <div style={{ textAlign: 'center', cursor: 'pointer', marginTop: '2px' }}>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <div style={{ margin: '29px' }}>
          <Typography
            variant="h6"
            component="div"
            style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '10px',
              lineHeight: '16px',
              color: 'rgba(0, 0, 0, 0.87)',
            }}
          >
            Space Name
          </Typography>
          <TextField
            autoFocus
            id="name"
            type="name"
            style={{ width: `${isMobileView ? '100%' : '423px'}` }}
            value={renameBoardInput}
            variant="outlined"
            onChange={(event) => handleRenameBoardInput(event)}
            onKeyDown={onEnterKeyPressRenameBoard}
            helperText={boardRenameErrorTextMessage}
          />

          {/* <div className="ParentName">Team: {parentName}</div> */}
        </div>

        <div
          style={{
            borderTop: '1px solid rgba(0,0,0,0.12)',
            flex: '0 0 auto',
            display: 'flex',
            padding: '12px',
            paddingBottom: '21px',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={handleClose}
            style={{
              width: '100px',
              height: '36px',
              borderRadius: '4px',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              color: 'rgba(0, 0, 0, 0.38)',
            }}
          >
            CANCEL
          </Button>

          <Button
            style={{
              marginLeft: '5px',
              width: '100px',
              height: '36px',
              background: '#335AFB',
              boxShadow: '0px 0.3px 0.5px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.2)',
              borderRadius: '4px',
              color: 'white',
            }}
            onClick={reNameBoardClick}
          >
            SAVE
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default RenameSpace;
