import React, { Component } from 'react';
import { debounce } from 'lodash';
import './iwb.scss';
import { connect } from 'react-redux';
import Konva from 'konva';
import jsPDF from 'jspdf';
import { Backdrop, Button, LinearProgress, Tooltip, useMediaQuery } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import CanvasBoard from './canvasboard';
import Pagination from './pagination';
import Toolbar from './toolbar';
import UndoRedo from './undoredo';
import Hidden from '@material-ui/core/Hidden';
import Snackbar from '@material-ui/core/Snackbar';
import { BBBViewerContainer } from '../../../components/huddle/BBBViewer/container';
import PublishClassRight from './PublishClassRight';
import PubSub from 'pubsub-js';
import { v4 } from 'uuid';
import { EVENTS } from '../../../constants/events';
import { DocumentDialogContainer } from '../../../components/documents/document-dialog/container';
import { DocumentActionDialogContainer } from '../../../components/documents/document-action-dialog/container';
import { DocumentImportProgressDialogContainer } from '../../../components/documents/document-import-progress-dialog/container';
import { ToggleButton } from '@material-ui/lab';
import presentationModeIcon from '../../../assets/svg/presentationModeIcon.svg';
import { DocumentSelectImportDialogContainer } from '../../../components/documents/document-select-import-dialog/container';
import boardsService from '../../../services/boards.service';
import { setFileInfo, resetDocInfo } from '../../../store/actions/documentImport.action';
import {
  showSpinner,
  hideSpinner,
  showFeatureLockedDialog,
  showLicenseExhaustedDialog,
} from '../../../store/actions/notification.action';
import { changePresentationMode, storePresenterInfo } from '../../../store/actions/canvas.action';
import { checkIsAuthUser, getAuthUserTag, getFlowId } from '../../../Utils/authentication-access';
import { importSupportedContentTypes, shouldConvertFileTypes } from '../../../constants/canvas';
import { DialogGroupContainer } from './dialog-group/container';
import { ZoomExternalMeetingManagerContainer } from '../../../components/video-call-links/external-meetings/zoom/zoom-external-meeting-manager/container';
import ExportCanvas from './export-canvas';
import { getBoardInfo } from '../utils/authentication-access';
import Timer from '../../../components/Timer/Timer';
import { Close } from '@material-ui/icons';
import { HuddleButtonContainer } from '../../../components/huddle/HuddleButton/container';
import { HuddleInvitationContainer } from '../../../components/huddle/HuddleInvitation/container';
import { VideoCallLinksContainer } from '../../../components/video-call-links/container';
import WhiteBoardIcon from '../../../assets/icons/WhiteBoardIcon';
import HandIcon from '../../../assets/icons/HandIcon';
import './layout.css';
import PaperIcon from '../../../assets/icons/PaperIcon';
import PollingIcon from '../../../assets/icons/PollingIcon';
import RecordIcon from '../../../assets/icons/RecordIcon';
import MediaIcon from '../../../assets/icons/MediaIcon';
import SmileyIcon from '../../../assets/icons/SmileyIcon';
import GalleryIcon from '../../../assets/icons/GalleryIcon';
import SharePdfIcon from '../../../assets/icons/SharePdfIcon';
import { poppinsBase64 } from '../../../assets/fonts/poppins';
import config from '../../../config';
import ZoomToolbar from '../../../components/zoom-toolbar/zoom-toolbar';
import FilesSelect from './fileuploadpopup';
import { KsarComponent } from '../../Ksar/KsarComponent/Ksar';
import { zoomToFit } from '../../../Utils/canvas-board';
import Background from './background/background';
import { BG_DEFAULT_THEME } from './background/background.jsx';
import FullscreenButton from '../../../components/fullscreen';
import { sleep } from '../../../Utils/util';
import ScreenshareContainer from '../../../components/webrtc/screenshare/container';
import { FEATURE_FLAGS } from '../../../constants/feature-flags.constants';
import { ScreenshareDisplayControlsContainer } from '../../../components/webrtc/screenshare/display-controls/container';
import { useNotificationDispatcher } from '../../../store/dispatcher/useNotificationDispatcher';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.exportCanvasContainerRef = React.createRef();
    this.toolsRef = React.createRef();
    this.toolbarRef = null;
    this.topbarRef = null;
    this.zoomPanContainerRef = React.createRef();
    this.state = {
      isExportInProgress: false,
      exportProgress: 0,
      penColor: 'Black',
      brushSize: 5,
      isEraseAll: false,
      lineCap: 'round',
      lineJoin: 'round',
      brushOpacity: 1,
      pagenum: 0,
      errmsg: ' ',
      openerrmsg: false,
      activeAction: {
        select: true,
        media: false,
      },
      toolAreaWidth: '100vw',
      bbbWindowWidth: 0,
      boardKey: 0,
      isOpenDocumentDialog: false,
      isOpenDocumentActionDialog: false,
      isOpenDocumentSelectImportDialog: false,
      isOpenDocumentImportProgressDialog: false,
      documentS3Url: '',
      presentingMode: false,
      isPageSelectEnabled: true,
      isWhiteBoardToolsActive: false,
      whiteboardScale: 1,
      showMiniMap: false,
      mediaopen: false,
      mediaanchorEl: null,
      ksarModal: false,
      //canvasRelated variables
      theme: BG_DEFAULT_THEME,
      selectedPageId: '',
      undoEnabled: false,
      redoEnabled: false,
    };
    this.CanvasBoard = React.createRef();
    this.Toolbar = React.createRef();
    this.Pagination = React.createRef();
    this.file = null; /** this is used to store the currently selected file for upload */
    this.maxWhiteboardScale = 20;
    this.maxZoomLevel = 200;
    this.defaultWhiteboardScale = +(
      this.maxWhiteboardScale / Math.round(this.maxZoomLevel / 100)
    ).toFixed(2);
  }

  initPresentationMode = async () => {
    try {
      const response = await boardsService.getPresentationModeStatus();
      const flowId = getFlowId();
      const { status, userTag, userId } = response.data.data;
      const authUserTag = getAuthUserTag();
      if (status === 'PRESENTATION_MODE_ACTIVE' && userTag && userTag !== authUserTag) {
        this.disablePageSelection();
        this.props.storePresenterInfo({ userId, flowId, userTag });
      }
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 1);
    this.content.setToolbar(this.toolbarRef);
    this.toolbarRef.setCanvasBoard(this.content);
    this.content.setPagination(this.pagination);

    const subscriber = (msg, data) => {
      console.log(`setting paired class status to false, message from socket: ${msg}`);
      this.setState({ boardKey: this.state.boardKey + 1 });
    };
    PubSub.subscribe(EVENTS.BOARD_CHANGED, subscriber);
    const imageToCanvasSubscriber = (msg, data) => {
      const { dataUrls, options } = data.data;
      this.uploadFiles(dataUrls, options);
    };
    PubSub.subscribe(EVENTS.IMAGES_TO_CANVAS, imageToCanvasSubscriber);
    this.initPresentationMode();
  };

  componentDidUpdate = () => {
    this.content.setToolbar(this.toolbarRef);
    this.toolbarRef.setCanvasBoard(this.content);
    this.content.setPagination(this.pagination);
  };

  componentWillUnmount = () => {
    PubSub.unsubscribe(EVENTS.IMAGES_TO_CANVAS);
    this.boardResizeObserver.disconnect();
  };

  setUndoEnabled = (payload) => this.setState({ undoEnabled: payload });
  setRedoEnabled = (payload) => this.setState({ redoEnabled: payload });

  changeColor = (color) => {
    this.setState({ penColor: color });
  };

  changeBrushSize = (size) => {
    this.setState({ brushSize: size });
  };

  highlighterSelect = () => {
    this.setState({ brushOpacity: 0.4 });
  };

  selectPen = () => {
    this.setState({ brushOpacity: 1 });
  };

  eraseAll = () => {
    this.content.erase();
  };

  uploadFile = async (file, options = {}) => {
    var FileSize = file[0].size / 1024 / 1024; // in MB
    if (FileSize > 100) {
      this.props.hideSpinner();
      this.setState({ errmsg: `File size needs to be less than 100 MB`, openerrmsg: true });
    } else {
      return this.content.handleChange(file, options);
    }
  };

  setPageNumber = (pagenum) => this.setState({ pagenum });

  activateAction = (stateObj) => {
    try {
      if (this.content !== null) {
        this.content.removeTransformer();
        this.content.cleanDrawing();
      }
    } catch (error) {
      console.error('Error while removing transformer');
      console.log(error);
    }
    if (this.props.inactivateAll === true) {
      stateObj.pen = false;
    }
    this.setState({ activeAction: stateObj });
  };

  addTextToCanvas = (text) => {
    this.content.addTextEditor(text);
  };

  addShapeToCanvas = (shape) => {
    // this.toolbarRef.selectClick();
    // this.content.addShape(shape);
    this.setState({ brushOpacity: 1 });
    this.activateAction({ [shape]: true });
  };

  addPage = async (index) => {
    await this.content.addNewPage(index);
    await this.content.zoomToDefault();
  };

  changePage = async (pageId, existing = false, isPresentationSync) => {
    if (pageId !== this.pagination.getPageId(this.state.pagenum) && !isPresentationSync) {
      // This will change the pageId of pagination, so it need to run after checking the above condition
      await this.content.pageSelect(pageId, existing);
      // This need to run after pageSelect so the socket messages of page change, and zoom-pan sync will be in order
      await this.content.zoomToDefault();
    } else {
      await this.content.pageSelect(pageId, existing);
    }
  };

  deletePage = async (page, existing = false) => {
    await this.content.deleteAnyPage(page, existing);
  };

  keyEnable = () => {
    this.content.enableShortCutKeys();
  };

  keyDisable = () => {
    this.content.disableShortcutKeys();
  };

  showPaginationMessage = () => {
    this.setState({ errmsg: `Adding More Than 15 Pages Not Allowed`, openerrmsg: true });
  };

  updateToolAreaOnHuddleWindowOpen = () => {
    /**
     * TODO: do not call this
     */
    // this.setState({
    //   toolAreaWidth: '75vw',
    //   bbbWindowWidth: '25vw',
    // });
  };

  updateToolAreaOnHuddleWindowClosed = () => {
    this.setState({
      toolAreaWidth: '100vw',
      bbbWindowWidth: 0,
    });
  };

  setDocumentS3Url = (s3Url) => {
    this.setState({
      documentS3Url: s3Url,
    });
  };

  openDocumentDialog = (s3Url) => {
    this.setState({
      isOpenDocumentDialog: true,
    });

    if (s3Url) {
      this.setDocumentS3Url(s3Url);
    }
  };

  closeDocumentDialog = () => {
    this.setState({
      isOpenDocumentDialog: false,
    });
    this.props.resetDocInfo();
  };

  openDocumentActionDialog = (s3Url) => {
    this.setState({
      isOpenDocumentActionDialog: true,
      documentS3Url: s3Url,
    });
  };

  closeDocumentActionDialog = () => {
    this.setState({
      isOpenDocumentActionDialog: false,
    });
  };

  openDocumentImportDialog = () => {
    this.setState({
      isOpenDocumentImportProgressDialog: true,
    });
  };

  closeDocumentImportDialog = () => {
    this.setState({
      isOpenDocumentImportProgressDialog: false,
    });
  };

  showPDF = () => {
    this.content.showPDF(this.state.documentS3Url);
  };

  showDocument = () => {
    this.content.showDocument(this.state.documentS3Url);
  };

  wait = (ms) => {
    return new Promise((res) =>
      setTimeout(() => {
        res();
      }, ms),
    );
  };

  uploadFiles = async (dataUrls, options = {}) => {
    this.props.showSpinner();
    options.count = dataUrls.length;
    for (const [index, dataUrl] of dataUrls.entries()) {
      options.index = index;
      const file = await this.dataUrlToFile(dataUrl, v4());
      await this.uploadFile([file], options);
    }
    this.props.hideSpinner();
  };

  uploadMediaFile = (file) => {
    this.setState({ mediaanchorEl: null });
    this.setState({ mediaopen: false });
    this.onFileSelected(file);
    this.closeMediaPopOver();
  };

  closeMediaPopOver = () => {
    this.setState({ mediaanchorEl: null });
    this.setState({ mediaopen: false });
    this.toolbarRef.selectClick();
  };

  dataUrlToFile = async (dataUrl, fileName) => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
  };

  handlePresentingMode = () => {
    if (this.props.cloudLicense.spaces.presentationMode) {
      if (this.state.presentingMode) {
        this.content.sendEndPresentationMessage();
      } else {
        this.content.handleStartPresentation();
        this.pagination.notifyCurrentPage();
      }
      this.enablePageSelection();

      this.setState({ presentingMode: !this.state.presentingMode });
    } else {
      this.props.showFeatureLockedDialog();
    }
  };

  disablePageSelection = () => {
    this.setState({ isPageSelectEnabled: false });
    this.props.changePresentationMode(true);
  };

  enablePageSelection = () => {
    this.setState({ isPageSelectEnabled: true });
    this.props.changePresentationMode(false);
  };

  disablePresentingMode = () => {
    this.setState({ presentingMode: false });
  };

  /**
   * when file is selected for upload
   * @param {*} file
   */
  onFileSelected = async (file, pointerPosition) => {
    if (importSupportedContentTypes.includes(file[0].type)) {
      this.openDocumentSelectImportDialog(file);
    } else {
      this.props.showSpinner();
      await this.uploadFile(file, { pointerPosition });
      this.props.hideSpinner();
    }
  };

  openDocumentSelectImportDialog = (file) => {
    this.file = file;
    this.setState({
      isOpenDocumentSelectImportDialog: true,
    });
  };

  closeDocumentSelectImportDialog = () => {
    this.file = null;
    this.setState({
      isOpenDocumentSelectImportDialog: false,
    });
  };

  onJustImportClick = async () => {
    this.props.showSpinner();
    const elementInfo = await this.uploadFile(this.file);
    if (!elementInfo) return this.props.hideSpinner();
    /** clear the selected file info  */
    this.file = null;
    let { s3Url, fileFormat } = elementInfo;
    if (shouldConvertFileTypes.includes(fileFormat)) {
      const encodedFileUrl = encodeURIComponent(s3Url);
      const pptToPdfUrl = `${config.MEDIA_CONVERTER_SERVER_URL}/ppt-to-pdf?fileUrl=${encodedFileUrl}`;
      s3Url = pptToPdfUrl;
    }
    this.props.setFileInfo({ ...elementInfo, s3Url });
    this.closeDocumentSelectImportDialog();
    this.setDocumentS3Url(elementInfo.s3Url);
    fileFormat === 'application/pdf' ? this.showPDF() : this.showDocument();
    this.props.hideSpinner();
  };

  onImportAndExpandClick = async () => {
    try {
      this.props.showSpinner();
      const elementInfo = await this.uploadFile(this.file);
      if (!elementInfo) return this.props.hideSpinner();
      /** clear the selected file info  */
      this.file = null;
      this.closeDocumentSelectImportDialog();
      let { s3Url, fileFormat } = elementInfo;
      if (shouldConvertFileTypes.includes(fileFormat)) {
        const encodedFileUrl = encodeURIComponent(s3Url);
        const pptToPdfUrl = `${config.MEDIA_CONVERTER_SERVER_URL}/ppt-to-pdf?fileUrl=${encodedFileUrl}`;
        s3Url = pptToPdfUrl;
      }
      this.props.setFileInfo({ ...elementInfo, s3Url });
      this.openDocumentDialog(s3Url);
      this.props.hideSpinner();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  dropHandler = (e) => {
    e.preventDefault();
    const pointerPosition = this.content.getCurrentStage().getRelativePointerPosition();
    if (e.dataTransfer.items) {
      [...e.dataTransfer.items].forEach((item) => {
        if (item.kind === 'string' && item.type.match('^text/plain')) {
          item.getAsString((text) => this.content.addTextEditor({ text }, '60%'));
        }
        if (item.kind === 'file') {
          const file = item.getAsFile();
          this.onFileSelected([file], pointerPosition);
        }
      });
    } else {
      [...e.dataTransfer.files].forEach((file) => {
        this.onFileSelected([file], pointerPosition);
      });
    }
  };

  setStateSynchronous = (stateUpdate) => {
    return new Promise((resolve) => {
      this.setState(stateUpdate, () => resolve());
    });
  };

  exportCanvas = async () => {
    try {
      await this.setStateSynchronous({ isExportInProgress: true });
      const loadBackground = (src) =>
        new Promise((resolve, reject) => {
          const background = new Image();
          background.crossOrigin = 'Anonymous';
          background.src = src;
          background.onload = () => resolve(background);
          background.onerror = (...args) => reject(...args);
        });
      const { width, height } = this.content.size;
      const pdf = new jsPDF('l', 'px', [width, height]);
      pdf.addFileToVFS('poppins-regular.ttf', poppinsBase64);
      pdf.addFont('poppins-regular.ttf', 'Poppins', 'normal');
      pdf.setFont('Poppins');
      pdf.setTextColor('#000000');
      await this.content.getFlowItem({ disableCORS: true });
      await sleep(500);
      const elementsLayers = this.content.getStages();
      for (let [index, layer] of elementsLayers.entries()) {
        const stage = new Konva.Stage({
          container: this.exportCanvasContainerRef.current,
          width,
          height,
        });
        const bgSrc = this.content.getStageBackgroundSrc(index);
        const bgImage = await loadBackground(bgSrc);
        const background = new Konva.Image({
          x: 0,
          y: 0,
          image: bgImage,
          width,
          height,
        });
        const backgroundLayer = new Konva.Layer();
        backgroundLayer.add(background);
        stage.add(backgroundLayer);
        stage.add(layer);
        stage.draw();
        const {
          scale,
          position: { x: stageX, y: stageY },
        } = await zoomToFit({ mainStage: stage, elementsLayer: layer });
        const absoluteStageX = -stageX / scale;
        const absoluteStageY = -stageY / scale;
        backgroundLayer.setAttrs({
          scaleX: 1 / scale,
          scaleY: 1 / scale,
          x: absoluteStageX,
          y: absoluteStageY,
        });
        layer.find('Text').forEach((text) => {
          const textX = (text.x() - absoluteStageX) * scale;
          const textY = (text.y() - absoluteStageY) * scale;
          const fontSize = (text.fontSize() / 0.75) * text.getAbsoluteScale().x * scale;
          const maxWidth = text.width() * text.getAbsoluteScale().x * scale;
          pdf.setFontSize(fontSize);
          pdf.text(text.text(), textX, textY, {
            baseline: 'top',
            angle: -text.getAbsoluteRotation(),
            maxWidth,
          });
        });
        const pixelRatio = Math.round(((1 / scale) * 15) / this.props.transformFactor);
        const dataURL = stage.toDataURL({ pixelRatio, mimeType: 'image/jpeg', quality: '0.9' });
        backgroundLayer.destroy();
        stage.removeChildren();
        stage.destroy();
        if (dataURL.length > 10) {
          pdf.addImage(dataURL, 0, 0, width, height);
          if (index + 1 < elementsLayers.length) {
            pdf.addPage();
          }
        }
        const exportProgress = ((index + 1) / elementsLayers.length) * 100;
        await this.setStateSynchronous({ exportProgress });
      }
      const currentStage = this.content.getCurrentStage();
      const currentPage = this.pagination.getCurrentPage();
      currentStage.add(elementsLayers[currentPage - 1]);
      currentStage.draw();
      const { name: boardName } = getBoardInfo();
      pdf.save(boardName + '.pdf');
      this.setState({ isExportInProgress: false, exportProgress: 0 });
      await this.content.getFlowItem({ disableCORS: false });
    } catch (error) {
      this.setState({ isExportInProgress: false, exportProgress: 0 });
      console.log(error);
    }
  };

  enableToolsPointerEvents = () => {
    this.toolsRef.current.style.pointerEvents = 'all';
    this.topbarRef.style.pointerEvents = 'all';
  };
  disableToolsPointerEvents = () => {
    this.toolsRef.current.style.pointerEvents = 'none';
    this.topbarRef.style.pointerEvents = 'none';
  };

  //INFO :not Required in current implementation
  // exportPdf = () => {
  //   return <ExportCanvas onClick={this.exportCanvas} />;
  // };
  setZoomState = (scale) => this.setState({ whiteboardScale: scale });

  toggleMiniMap = () => this.setState({ showMiniMap: !this.state.showMiniMap });

  mediaClick = (event) => {
    this.defaultselector('media');
    this.setState((prevState) => ({
      activeAction: { ...prevState.activeAction, media: true },
    }));
    this.setState({ mediaopen: !this.state.mediaopen });
    this.setState({ mediaanchorEl: event.currentTarget });
  };

  defaultselector = (selectkey) => {
    Object.keys(this.state.activeAction).map((key) => {
      this.state.activeAction[key] = false;
    });
    this.state.activeAction[selectkey] = true;
    this.activateAction(this.state.activeAction);
  };

  toggleKsarModal = () => {
    this.setState({ mediaanchorEl: null });
    this.setState({ mediaopen: false });

    this.setState({ ksarModal: !this.state.ksarModal });
    !this.state.ksarModal ? this.keyEnable() : this.keyDisable();
  };

  exportBackground = () => {
    return (
      <Background
        theme={this.state.theme}
        onIconClick={(theme, customImageData) =>
          this.content.renderBackground(this.props.currentPageId, theme, customImageData)
        }
        // ref={(instance) => (this.content.Background = instance)}
        toolAreaWidth={this.props.isViewOpen ? this.state.toolAreaWidth : '100vw'}
      />
    );
  };

  updateTheme = (theme) => {
    this.setState({ theme });
  };

  updateSelectedPageId = async (selectedPageId) => {
    await this.setStateSynchronous({ selectedPageId });
  };

  allActiveUsers = () => {
    let activeUsersNames = this.props.activeUsers.map(({ userName }) => userName);
    return activeUsersNames.length > 1;
  };

  exportPresentation = () => {
    return (
      <div style={{ zIndex: this.state.isOpenDocumentDialog ? 10000 : 1000 }}>
        <Tooltip
          title={
            this.state.presentingMode
              ? this.allActiveUsers()
                ? 'Members are following you'
                : 'No members are online on this space'
              : 'Everyone in the space will follow you'
          }
        >
          <div
            onClick={this.handlePresentingMode}
            className={this.state.presentingMode ? 'activePresentation' : 'inactivePresentation'}
          >
            <ToggleButton
              className="presentationBtn"
              style={{
                border: this.state.presentingMode ? ' 1px solid #335AFB' : '1px solid #DADADA',
              }}
              size="medium"
              value="check"
              selected={this.state.presentingMode}
              //TODO: required for feature implementation
              // onChange={() => {
              //   this.props.handlePresentingMode();
              // }}
            >
              <img src={presentationModeIcon} alt="Pre" style={{ marginRight: '8px' }} /> Present
            </ToggleButton>
          </div>
        </Tooltip>
      </div>
    );
  };

  observeBoardContainer = (ref) => {
    if (ref) {
      this.boardResizeObserver = new ResizeObserver(debounce(this.content.resizeCanvas, 500));
      this.boardResizeObserver.observe(ref);
    }
  };

  handleChatWithDoc = () => {
    this.content.toggleChat({
      elementId: this.props.fileInfo.elementId,
      s3Url: this.state.documentS3Url,
      type: 'PDF',
    });
    this.closeDocumentActionDialog();
  };

  render() {
    const { isMobile, isSmallMobile, isPortrait } = this.props;
    const isAuthUser = checkIsAuthUser();
    const pagination = (
      <Pagination
        history={this.props.history}
        setPageNumber={this.setPageNumber}
        addPage={this.addPage}
        ref={(instance) => (this.pagination = instance)}
        deletePage={this.deletePage}
        changePage={this.changePage}
        role={this.props.role}
        addPageAllowed={this.props.addPageAllowed}
        showMessage={this.showPaginationMessage}
        presentationMode={this.props.presentationMode}
        isPageSelectEnabled={this.state.isPageSelectEnabled}
        isDocumentDialogOpen={this.state.isOpenDocumentDialog}
        canvasBoard={this.content}
        cloudLicense={this.props.cloudLicense}
        showLicenseExhaustedDialog={this.props.showLicenseExhaustedDialog}
      />
    );
    return (
      <>
        <Backdrop open={this.state.isExportInProgress} style={{ zIndex: 10000 }} unmountOnExit>
          <LinearProgress
            variant="determinate"
            value={this.state.exportProgress}
            style={{ width: '80%' }}
          />
        </Backdrop>
        <div
          onDragOver={(event) => event.preventDefault()}
          onDrop={this.dropHandler}
          style={{ position: 'relative' }}
        >
          {this.state.isExportInProgress && (
            <div
              ref={this.exportCanvasContainerRef}
              style={{ position: 'fixed', visibility: 'hidden' }}
            />
          )}
          <div
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              ref={(ref) => (this.topbarRef = ref)}
              style={{
                display: this.props.isFullscreen ? 'none' : 'flex',
                alignItems: 'center',
                minHeight: '52px',
                background: '#FFFFFF',
                width: this.props.isViewOpen ? this.state.toolAreaWidth : '100vw',
                zIndex: 1000,
              }}
            >
              <PublishClassRight
                canvasBoard={this.content}
                canvasImage={this.content?.galleryView}
                canvasVideo={this.content?.AddCloudVideo}
                canvasDocument={this.content?.canvasDocument}
                canvasText={this.content?.addTextToCanvas}
                enableShortCutKey={this.keyEnable}
                disableShortCutKey={this.keyDisable}
                updateToolAreaOnHuddleWindowClosed={this.updateToolAreaOnHuddleWindowClosed}
                updateToolAreaOnHuddleWindowOpen={this.updateToolAreaOnHuddleWindowOpen}
                // exportPdf={this.exportPdf}
                zoomToolbar={
                  <ZoomToolbar
                    applyZoom={this.content?.applyZoom}
                    zoomToFit={this.content?.zoomToFit}
                    zoomRate={1.1}
                    scale={this.state.whiteboardScale}
                    showMiniMap={this.state.showMiniMap}
                    toggleMiniMap={this.toggleMiniMap}
                    maxZoomLevel={this.maxZoomLevel}
                    defaultScale={this.defaultWhiteboardScale}
                    maxScale={this.maxWhiteboardScale}
                  />
                }
                exportBackground={this.exportBackground}
                exportPresentation={this.exportPresentation}
              />
            </div>

            <div
              style={{ position: 'relative', width: '100vw', flexGrow: 1 }}
              id="board-container"
              ref={this.observeBoardContainer}
            >
              <CanvasBoard
                history={this.props.history}
                ref={(instance) => {
                  this.content = instance;
                }}
                brushOpacity={this.state.brushOpacity}
                penColor={this.state.penColor}
                brushSize={this.state.brushSize}
                lineCap={this.state.lineCap}
                lineJoin={this.state.lineJoin}
                selectedpage={this.state.pagenum}
                activeAction={this.state.activeAction}
                role={this.props.role}
                inactivateAll={this.props.inactivateAll}
                syncPageChange={this.props.syncPageChange}
                toolAreaWidth={this.state.toolAreaWidth}
                key={this.state.boardKey}
                openDocumentActionDialog={this.openDocumentActionDialog}
                handlePresentingMode={this.handlePresentingMode}
                presentingMode={this.state.presentingMode}
                disablePageSelection={this.disablePageSelection}
                enablePageSelection={this.enablePageSelection}
                disablePagenation={this.disablePagenation}
                disablePresentingMode={this.disablePresentingMode}
                isPageSelectEnabled={this.state.isPageSelectEnabled}
                disableToolsPointerEvents={this.disableToolsPointerEvents}
                enableToolsPointerEvents={this.enableToolsPointerEvents}
                showMiniMap={this.state.showMiniMap}
                updateTheme={this.updateTheme}
                theme={this.state.theme}
                updateSelectedPageId={this.updateSelectedPageId}
                selectedPageId={this.state.selectedPageId}
                maxScale={this.maxWhiteboardScale}
                defaultScale={this.defaultWhiteboardScale}
                setUndoEnabled={this.setUndoEnabled}
                setRedoEnabled={this.setRedoEnabled}
              />
            </div>
            <div
              className="layoutGrid"
              style={{
                //TODO required for feature implementation
                // width: this.props.isViewOpen ? this.state.toolAreaWidth : '100vw',
                zIndex: '1000',
                bottom: isMobile ? '0px' : '10px',
                backgroundColor: isMobile ? '#fff' : 'unset',
                boxShadow: isMobile ? '0px 4px 16px rgba(0, 0, 0, 0.21)' : 'unset',
                height: isMobile ? '55px' : 'unset',
              }}
            >
              <div
                style={{
                  display: `${this.state.isWhiteBoardToolsActive ? 'none' : 'flex'}`,
                  width: isMobile && isPortrait ? '100%' : 'unset',
                  justifyContent: isMobile && isPortrait ? 'space-between' : 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                  background: 'white',
                  boxShadow: isMobile ? 'unset' : '0px 4px 16px rgba(0, 0, 0, 0.21)',
                  borderRadius: isMobile ? '0px' : '51px',
                  padding: isMobile ? 0 : '0 30px',
                  pointerEvents: 'all',
                }}
              >
                {/* //INFO: Hiding due to server service down for cost reducing START */}
                {/* <div>
                  <HuddleButtonContainer
                    updateToolAreaOnHuddleWindowClosed={this.updateToolAreaOnHuddleWindowClosed}
                    updateToolAreaOnHuddleWindowOpen={this.updateToolAreaOnHuddleWindowOpen}
                    isMobile={isMobile}
                    isSmallMobile={isSmallMobile}
                  />
                </div> */}

                {/* {((!isMobile && !isPortrait) || (!isMobile && isPortrait)) && (
                  <>
                    {FEATURE_FLAGS.screenshareInASpace.isEnabled && (
                      <ScreenshareDisplayControlsContainer
                        getUserMedia={this.props.mediaClient.getUserMedia}
                        isMuted={this.props.mediaClient.isMuted}
                        isScreenShared={this.props.mediaClient.isScreenShared}
                        isViewOnly={this.props.mediaClient.isViewOnly}
                        mute={this.props.mediaClient.mute}
                        onClickMute={this.props.mediaClient.onClickMute}
                        onClickUnmute={this.props.mediaClient.onClickUnmute}
                        publish={this.props.mediaClient.publish}
                        stopScreenshare={this.props.mediaClient.stopScreenshare}
                        checkIsAllowedToStream={this.props.mediaClient.checkIsAllowedToStream}
                      />
                    )}
                  </>
                )} */}

                {/* //INFO: Hiding due to server service down for cost reducing END */}

                <div>
                  {/* {isAuthUser && ( */}
                  <VideoCallLinksContainer isMobile={isMobile} isSmallMobile={isSmallMobile} />
                  {/* )} */}
                </div>
                <Tooltip title="Whiteboard">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      this.setState({ isWhiteBoardToolsActive: true });
                    }}
                    style={{
                      width: isSmallMobile ? '34px' : '85px',
                      // padding: isMobile ? 0 : undefined,
                      padding: isSmallMobile ? 0 : isMobile ? '3px 0' : '8px 0px',
                      border: 'none',
                      marginRight: isSmallMobile && isPortrait ? '0' : '8px',
                      minWidth: isSmallMobile ? '34px' : 'auto',
                    }}
                    size={isMobile ? 'small' : 'medium'}
                  >
                    <div>
                      <div>
                        <WhiteBoardIcon />
                      </div>

                      {!isSmallMobile && (
                        <div
                          style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: '#000000',
                            textTransform: 'none',
                            border: 'none',
                          }}
                        >
                          Whiteboard
                        </div>
                      )}
                    </div>
                  </Button>
                </Tooltip>
                {/* pageButton */}
                {pagination}
                <div
                  style={{
                    display: 'flex',
                    gap: '8px',
                    padding: isMobile && isPortrait ? '0 0 0 2px' : '0 8px',
                    overflow: 'hidden',
                  }}
                >
                  {/* 
                  //TODO: feature implementation
                  {(!isMobile || !isPortrait) && (
                    <>
                      <div
                        className="buttonStyle"
                        style={{ opacity: '0.2', cursor: 'not-allowed' }}
                      >
                        <HandIcon />
                      </div>

                      <div
                        className="buttonStyle"
                        style={{ opacity: '0.2', cursor: 'not-allowed' }}
                      >
                        <PaperIcon />
                      </div>
                      <div
                        className="buttonStyle"
                        style={{ opacity: '0.2', cursor: 'not-allowed' }}
                      >
                        <PollingIcon />
                      </div>
                      <div style={{ opacity: '0.2', cursor: 'not-allowed' }}>
                        <Timer />
                      </div>
                      <div
                        className="buttonStyle"
                        style={{ opacity: '0.2', cursor: 'not-allowed' }}
                      >
                        <RecordIcon />
                      </div>
                    </>
                  )} */}

                  <div
                    className="buttonStyle"
                    onClick={this.mediaClick}
                    style={{
                      backgroundColor: this.state.mediaopen
                        ? 'rgba(51, 90, 251, 0.4)'
                        : 'transparent',
                    }}
                  >
                    <MediaIcon fill={this.state.mediaopen ? '#FFFFFF' : '#000000'} />
                  </div>
                  <Popover
                    onClose={this.closeMediaPopOver}
                    open={this.state.mediaopen}
                    anchorEl={this.state.mediaanchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  >
                    <FilesSelect
                      fileUpload={this.uploadMediaFile}
                      toggleKsarModal={this.toggleKsarModal}
                    />
                  </Popover>

                  <KsarComponent
                    toggleModal={this.toggleKsarModal}
                    canvasImage={this.content?.galleryView}
                    canvasVideo={this.content?.AddCloudVideo}
                    canvasDocument={this.content?.canvasDocument}
                    canvasText={this.content?.addTextToCanvas}
                    open={this.state.ksarModal}
                  />
                  {/* 
                  //TODO: feature implementation
                  {(!isMobile || !isPortrait) && (
                    <>
                      <div
                        className="buttonStyle"
                        style={{ opacity: '0.2', cursor: 'not-allowed' }}
                      >
                        <SmileyIcon />
                      </div>
                      <div
                        className="buttonStyle"
                        style={{ opacity: '0.2', cursor: 'not-allowed' }}
                      >
                        <GalleryIcon />
                      </div>
                    </>
                  )} */}
                  <div className="buttonStyle" onClick={this.exportCanvas}>
                    <ExportCanvas />
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: `${this.state.isWhiteBoardToolsActive ? 'flex' : 'none'}`,
                  gap: '4px',
                  overflow: 'hidden',
                  background: 'white',
                  boxShadow: isMobile ? 'unset' : '0px 4px 16px rgba(0, 0, 0, 0.21)',
                  borderRadius: isMobile ? '0px' : '51px',
                  padding: isMobile ? '0px' : '14px 31px',
                  pointerEvents: 'all',
                }}
                ref={this.toolsRef}
              >
                <Button
                  startIcon={(!isMobile || !isPortrait) && <Close />}
                  variant="outlined"
                  onClick={() => {
                    this.setState({ isWhiteBoardToolsActive: false });
                  }}
                  style={{
                    border: '1px solid #DADADA',
                    borderRadius: '4px',
                    justifySelf: 'end',
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '12px',
                    color: '#000000',
                    height: '40px',
                    minWidth: 'unset',
                    textTransform: 'none',
                  }}
                  size={isMobile && isPortrait ? 'small' : 'medium'}
                >
                  {isMobile && isPortrait ? <Close /> : 'Done'}
                </Button>

                <Toolbar
                  activateAction={this.activateAction}
                  deleteAll={this.eraseAll}
                  selectHighlighter={this.highlighterSelect}
                  selectPen={this.selectPen}
                  selectColor={this.changeColor}
                  selectBrushSize={this.changeBrushSize}
                  addText={this.addTextToCanvas}
                  ref={(instance) => (this.toolbarRef = instance)}
                  history={this.props.history}
                  selectShape={this.addShapeToCanvas}
                  role={this.props.role}
                  onBackButton={this.props.onBackButton}
                  canvas={this.content}
                  enableShortCutKey={this.keyEnable}
                  disableShortCutKey={this.keyDisable}
                  inactivateAll={this.props.inactivateAll}
                />

                <Hidden xsDown>
                  <UndoRedo
                    performUndo={this.content?.undo}
                    performRedo={this.content?.redo}
                    undoEnabled={this.state.undoEnabled}
                    redoEnabled={this.state.redoEnabled}
                  />
                </Hidden>
                {/* TODO: uncomment this when screen share feature will be implemented at a later point of time */}
                {/* <ScreenshareButtonContainer /> */}
              </div>
            </div>
          </div>
          <Snackbar
            message={this.state.errmsg}
            open={this.state.openerrmsg}
            onClose={() => this.setState({ openerrmsg: false })}
            autoHideDuration={2000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          />
          <div
            style={{
              marginTop: '0.5rem',
              position: 'absolute',
              bottom: '123px',
              left: '200px',
              zIndex: '99',
            }}
          >
            <HuddleInvitationContainer
              updateToolAreaOnHuddleWindowOpen={this.updateToolAreaOnHuddleWindowOpen}
            />
          </div>
          <ZoomExternalMeetingManagerContainer />
          <BBBViewerContainer
            updateToolAreaOnHuddleWindowClosed={this.updateToolAreaOnHuddleWindowClosed}
            updateToolAreaOnHuddleWindowOpen={this.updateToolAreaOnHuddleWindowOpen}
            bbbWindowWidth={this.state.bbbWindowWidth}
          />
          <DocumentActionDialogContainer
            isOpen={this.state.isOpenDocumentActionDialog}
            openDocumentDialog={this.openDocumentDialog}
            closeDocumentActionDialog={this.closeDocumentActionDialog}
            showPDF={this.showPDF}
            showDocument={this.showDocument}
            onChatWithDoc={this.handleChatWithDoc}
          />
          <DocumentSelectImportDialogContainer
            isOpen={this.state.isOpenDocumentSelectImportDialog}
            onJustImportClick={this.onJustImportClick}
            closeDialog={this.closeDocumentSelectImportDialog}
            onImportAndExpandClick={this.onImportAndExpandClick}
          />
          <DocumentDialogContainer
            isOpen={this.state.isOpenDocumentDialog}
            closeDialog={this.closeDocumentDialog}
            documentS3Url={this.state.documentS3Url}
          />
          <DocumentImportProgressDialogContainer
            isOpen={this.state.isOpenDocumentImportProgressDialog}
            closeDialog={this.closeDocumentImportDialog}
          />
        </div>
        <FullscreenButton />
      </>
    );
  }
}

const WrappedLayout = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isSmallMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const isPortrait = useMediaQuery('(orientation: portrait)');
  return <Layout {...{ isMobile, isSmallMobile, isPortrait, ...props }} />;
};

const mapStateToProps = (state) => {
  return {
    presentationMode: state.canvasReducer.presentationMode,
    isViewOpen: state.huddle.isViewOpen,
    fileInfo: state.documentImport.fileInfo,
    currentPageId: state.canvasReducer.currentPageId,
    transformFactor: state.canvasReducer.transformFactor,
    isFullscreen: state.canvasReducer.isFullscreen,
    activeUsers: state.userlistReducer,
    cloudLicense: state.licenseReducer.cloudLicenseDetails,
  };
};

export default connect(mapStateToProps, {
  setFileInfo,
  resetDocInfo,
  showSpinner,
  hideSpinner,
  changePresentationMode,
  storePresenterInfo,
  showFeatureLockedDialog,
  showLicenseExhaustedDialog,
})(WrappedLayout);
