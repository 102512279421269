import { useEffect } from 'react';
import microsoftGraphService from './services/microsoft-graph.service';
import zoomService from './services/zoom.service';
import { useMicrosoftDispatcher } from './store/dispatcher/useMicrosoftDispatcher';
import { useZoomDispatcher } from './store/dispatcher/useZoomDispatcher';

export const useHydrateApp = () => {
  const { dispatchSetMicrosoftIsAuthenticated, dispatchSetMicrosoftMe } = useMicrosoftDispatcher();
  const { dispatchSetZoomIsAuthenticated, dispatchSetZoomMe } = useZoomDispatcher();

  useEffect(() => {
    const init = async () => {
      try {
        const isMicrosoftAuthenticated = await microsoftGraphService.hasAuthorizedOAuth();
        dispatchSetMicrosoftIsAuthenticated(isMicrosoftAuthenticated);

        if (isMicrosoftAuthenticated) {
          const me = await microsoftGraphService.getMe();
          dispatchSetMicrosoftMe(me);
        }

        const isZoomAuthenticated = await zoomService.hasAuthorizedOAuth();
        dispatchSetZoomIsAuthenticated(isZoomAuthenticated);

        if (isZoomAuthenticated) {
          const me = await zoomService.getMe();
          dispatchSetZoomMe(me);
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    init();
  }, []);
};
