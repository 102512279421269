import React from 'react';
import { Dialog, makeStyles } from '@material-ui/core';
import StyledFooter from './StyledFooter';
import StyledHeader from './StyledHeader';

const useStyles = makeStyles((theme) => ({
  content: {
    overflow: 'auto',
  },
}));

const StyledDialog = ({
  open,
  onClose,
  onCloseFooter,
  contentPadding,
  showFooterCloseButton,
  closeText,
  onSubmit,
  submitText,
  hideFooter,
  title,
  disabledSubmitButton,
  contentStyle,
  ...props
}) => {
  const classes = useStyles();

  if (contentPadding) {
    contentStyle = { ...contentStyle, padding: '2rem' };
  }

  const footer = !hideFooter && (
    <StyledFooter
      onClose={onClose}
      onCloseFooter={onCloseFooter}
      closeText={closeText}
      showFooterCloseButton={showFooterCloseButton}
      submitText={submitText}
      onSubmit={onSubmit}
      disabledSubmitButton={disabledSubmitButton}
    />
  );

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      className="overlay"
      {...props}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 16,
        },
      }}
    >
      <StyledHeader title={title} onClose={onClose} />
      <div className={classes.content} style={contentStyle}>
        {props.children}
      </div>
      {footer}
    </Dialog>
  );
};

StyledDialog.defaultProps = {
  contentStyle: {},
  contentPadding: true,
};

export default StyledDialog;
