import React from 'react';
import { Heading } from './component';
import { useDialogDispatcher } from '../../../../store/dispatcher/useDialogDispatcher';

export const HeadingContainer = (props) => {
  const { onClose } = props;
  const title = 'Room Controller Mode Enabled';

  return <Heading title={title} onClose={onClose} />;
};
