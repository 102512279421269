import { get, post } from '../Utils/api';
import { getToken, getWorkspaceId } from '../Utils/authentication-access';
import { LICENSE_LIST, PAYMENT, SPACES } from '../Utils/url-builder';

class LicenseService {
  getCloudLicenseDetails = async () => {
    const workspaceId = getWorkspaceId();
    try {
      const url = LICENSE_LIST.getCloudLicense(workspaceId);
      return await get(url);
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  getDeviceLicenseDetails = async (hardwareId) => {
    try {
      const url = LICENSE_LIST.getDeviceLicense(hardwareId);
      return await get(url);
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  activateLicenseWithKey = async (licenseKey) => {
    const workspaceId = getWorkspaceId();
    try {
      const url = PAYMENT.licenseActivateWithKey(licenseKey);
      return await post(url, { workspaceId: workspaceId });
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };
}

const licenseService = new LicenseService();
export default licenseService;
