import deviceService from '../../../../services/device.service';
import { useNotificationDispatcher } from '../../../../store/dispatcher/useNotificationDispatcher';

export const useMeetingOnPairedDevice = () => {
  const { dispatchSetSnackbar } = useNotificationDispatcher();

  const launchMeetingHandler = ({ subject, link, isDeviceConnected, onMeetingStartedOnDevice }) => {
    if (isDeviceConnected) {
      startMeetingOnDeviceHandler(subject, link, onMeetingStartedOnDevice);
    } else {
      window.open(link, '_blank');
    }
  };

  const startMeetingOnDeviceHandler = async (eventName, eventLink, onMeetingStartedOnDevice) => {
    try {
      await deviceService.startMeetingOnDevice(eventName, eventLink);
      dispatchSetSnackbar({ message: 'Meeting started on Bird device' });
      onMeetingStartedOnDevice && onMeetingStartedOnDevice()
    } catch (error) {
      console.log('error', error);
      dispatchSetSnackbar({ message: 'event not able to start in device' });
    }
  };

  return { launchMeetingHandler, startMeetingOnDeviceHandler };
};
