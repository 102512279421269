import config from '../config';

export class Positioning {
  constructor(width, height) {
    this.width = width;
    this.height = height;
  }

  transform(x, y) {
    const transformFactor = this.calculateTransformFactor();
    const transformedX = x == null ? x : x / transformFactor;
    const transformedY = y == null ? y : y / transformFactor;
    return { x: transformedX, y: transformedY };
  }

  calculateTransformFactor() {
    const boardContainer = document.getElementById('board-container');
    if (!boardContainer) return 1;
    const boardWidth = boardContainer.offsetWidth;
    const boardHeight = boardContainer.offsetHeight;
    const screenAspectRatio = boardWidth / boardHeight;
    const baseAspectRatio = this.width / this.height;
    const isWide = screenAspectRatio > baseAspectRatio;
    let transformFactor = isWide ? boardHeight / this.height : boardWidth / this.width;
    return transformFactor;
  }

  retrieve(x, y) {
    const transformFactor = this.calculateTransformFactor();
    const retrievedX = x == null ? x : x * transformFactor;
    const retrievedY = y == null ? y : y * transformFactor;
    return { x: retrievedX, y: retrievedY };
  }
}
const positioning = new Positioning(config.width, config.height);
export default positioning;
