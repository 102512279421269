import { useDispatch } from 'react-redux';
import {
  setIsOpenImportOutlookEventsDialog,
  setIsOpenVideoCallSidebarDialog,
  setIsOpenNavigationSidebarDialog,
  setIsOpenJoinAsHostDialog,
  setIsOpenJoinRequestSentDialog,
  setIsRemoteControlDialogOpen,
} from '../actions/dialog.action';

export const useDialogDispatcher = () => {
  const dispatch = useDispatch();

  /**
   *
   * @param {boolean} payload
   */
  const dispatchSetIsOpenImportOutlookEventsDialog = (payload) => {
    dispatch(setIsOpenImportOutlookEventsDialog(payload));
  };

  /**
   *
   * @param {boolean} payload
   */
  const dispatchSetIsOpenVideoCallSidebarDialog = (payload) => {
    dispatch(setIsOpenVideoCallSidebarDialog(payload));
  };

  const dispatchOpenVideoCallSidebarDialog = () => {
    dispatchSetIsOpenVideoCallSidebarDialog(true);
  };

  const dispatchCloseVideoCallSidebarDialog = () => {
    dispatchSetIsOpenVideoCallSidebarDialog(true);
  };

  /**
   *
   * @param {boolean} payload
   */
  const dispatchSetIsOpenNavigationSidebarDialog = (payload) => {
    dispatch(setIsOpenNavigationSidebarDialog(payload));
  };

  /**
   *
   * @param {boolean} payload
   */
  const dispatchSetIsOpenJoinAsHostDialog = (payload) => {
    dispatch(setIsOpenJoinAsHostDialog(payload));
  };

  /**
   *
   * @param {boolean} payload
   */
  const dispatchSetIsOpenJoinRequestSentDialog = (payload) => {
    dispatch(setIsOpenJoinRequestSentDialog(payload));
  };

  const dispatchSetIsOpenRemoteControlDialog = (payload) => {
    dispatch(setIsRemoteControlDialogOpen(payload));
  };

  return {
    dispatchCloseVideoCallSidebarDialog,
    dispatchOpenVideoCallSidebarDialog,
    dispatchSetIsOpenImportOutlookEventsDialog,
    dispatchSetIsOpenJoinAsHostDialog,
    dispatchSetIsOpenNavigationSidebarDialog,
    dispatchSetIsOpenVideoCallSidebarDialog,
    dispatchSetIsOpenJoinRequestSentDialog,
    dispatchSetIsOpenRemoteControlDialog,
  };
};
