import React from 'react';
import { CalendarSelectionTabs } from './component';

export const CalendarSelectionTabsContainer = (props) => {
  const { selectedCalendarTab, handleSelectedCalendarTab } = props;

  return (
    <CalendarSelectionTabs
      selectedCalendarTab={selectedCalendarTab}
      handleSelectedCalendarTab={handleSelectedCalendarTab}
    />
  );
};
