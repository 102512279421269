import React from 'react';

const HomeContainedIcon = (props) => {
  let fill = props.fill ? props.fill : '#000';
  let fillOpacity = props.fill ? 1 : 0.38;

  return (
    // <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path
    //     d="M12.7365 13.1398C11.9554 12.3588 11.9554 11.0924 12.7365 10.3114L21.2218 1.82609C22.0028 1.04504 23.2691 1.04504 24.0502 1.82609L32.5355 10.3114C33.3165 11.0924 33.3165 12.3588 32.5355 13.1398L24.0502 21.6251C23.2691 22.4061 22.0028 22.4061 21.2218 21.6251L12.7365 13.1398Z"
    //     fill={fill}
    //   />
    //   <path
    //     d="M0 11.7344L9.908 1.82637C10.689 1.04533 11.9554 1.04533 12.7364 1.82637L21.2217 10.3117C22.0028 11.0927 22.0028 12.359 21.2217 13.1401L12.7279 21.6339C11.9469 22.4149 10.6805 22.4149 9.89949 21.6339L0 11.7344Z"
    //     fill={fill}
    //   />
    //   <path
    //     fill-rule="evenodd"
    //     clip-rule="evenodd"
    //     d="M12.6434 10.4049C11.9131 11.1352 11.9131 12.3192 12.6434 13.0495L16.9779 17.384L21.3142 13.0478C22.0441 12.3179 22.0441 11.1345 21.3142 10.4046L16.9789 6.06934L12.6434 10.4049Z"
    //     fill="black"
    //     fill-opacity="0.32"
    //   />
    // </svg>

    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.74155 14.0395C7.125 15.1659 7.125 16.5246 7.125 19.2422V21.0484C7.125 25.6807 7.125 27.9968 8.51624 29.4359C9.90749 30.875 12.1467 30.875 16.625 30.875H21.375C25.8533 30.875 28.0925 30.875 29.4838 29.4359C30.875 27.9968 30.875 25.6807 30.875 21.0484V19.2422C30.875 16.5246 30.875 15.1659 30.2584 14.0395C29.6419 12.9131 28.5155 12.214 26.2627 10.8159L23.8877 9.34191C21.5064 7.86397 20.3157 7.125 19 7.125C17.6843 7.125 16.4936 7.86397 14.1123 9.34191L11.7373 10.8159C9.4845 12.214 8.3581 12.9131 7.74155 14.0395ZM15.4375 25.2344C14.9456 25.2344 14.5469 25.6331 14.5469 26.125C14.5469 26.6169 14.9456 27.0156 15.4375 27.0156H22.5625C23.0544 27.0156 23.4531 26.6169 23.4531 26.125C23.4531 25.6331 23.0544 25.2344 22.5625 25.2344H15.4375Z"
        fill={fill}
      />
    </svg>
  );
};

export default HomeContainedIcon;
