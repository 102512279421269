import axios from 'axios';
import { getToken } from './authentication-access';

const defaultConfig = {
    headers: {
        "Content-Type": "application/json",
    }
}

export function getWithHeader(url, config = defaultConfig) {
    const token = getToken();
    config.headers['Authorization'] = `Bearer ${token}`;
    return axios.get(url, config);
}

export function get(url, config = defaultConfig) {
    const token = config.token ? config.token : getToken();
    if (!config.headers) {
        config.headers = {
            "Content-Type": "application/json",
        }
    }
    config.headers['Authorization'] = `Bearer ${token}`;
    return axios.get(url, config);
}

export function post(url, data = {}, config = defaultConfig) {
    const token = config.token ? config.token : getToken();
    if (!config.headers) {
        config.headers = {
            "Content-Type": "application/json",
        }
    }
    config.headers['Authorization'] = `Bearer ${token}`;
    return axios.post(url, data, config);
}

export function put(url, data = {}, config = defaultConfig) {
    const token = getToken();
    config.headers['Authorization'] = `Bearer ${token}`;
    return axios.put(url, data, config);
}

export function del(url, data = {}, config = defaultConfig) {
    const token = getToken();
    config.headers['Authorization'] = `Bearer ${token}`;
    return axios.delete(url, { ...config, data });
}
