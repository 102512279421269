import React, { Component } from 'react';
import classes from './MediaCard.module.css';
import MediaPDFIcon from '../../../assets/svg/media/MediaPDFIcon.svg';
import MediaPPTIcon from '../../../assets/svg/media/MediaPPTIcon.svg';
import MediaDOCIcon from '../../../assets/svg/media/MediaDOCIcon.svg';
import MediaXLSIcon from '../../../assets/svg/media/MediaXLSIcon.svg';
import MediaIMAGEIcon from '../../../assets/svg/media/MediaIMAGEIcon.svg';
import MediaVIDEOIcon from '../../../assets/svg/media/MediaVIDEOIcon.svg';
import MediaAUDIOIcon from '../../../assets/svg/media/MediaAUDIOIcon.svg';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withTranslation } from 'react-i18next';
import PublishedOrangeIcon from '../../../assets/icons/PublishedOrangeIcon';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

class MediaCard extends Component {
  state = {
    mediaCardMenu: null,
  };
  handleMenuClick = (event) => {
    event.stopPropagation();
    this.setState({ mediaCardMenu: event.currentTarget });
  };

  handleMenuClose = (event) => {
    event.stopPropagation();
    this.setState({ mediaCardMenu: null });
  };

  onCardClick = (event) => {
    event.stopPropagation();
    this.props.selectedMedia(this.props.mediaDocInfo);
  };

  onOpenOptionClick = (event) => {
    event.stopPropagation();
    this.setState({ mediaCardMenu: null });
    this.props.selectedMedia(this.props.mediaDocInfo);
  };

  onPublishOptionCLick = (event) => {
    event.stopPropagation();
    this.setState({ mediaCardMenu: null });
    this.props.publishMedia(this.props.mediaDocInfo);
  };
  onDeleteOptionClick = (event) => {
    event.stopPropagation();
    this.setState({ mediaCardMenu: null });
    // this.props.deleteMedia(this.props.mediaDocInfo);
    this.props.deleteMediaInfo(this.props.mediaDocInfo);
  };

  onRenameOptionClick = (event) => {
    event.stopPropagation();
    this.setState({ mediaCardMenu: null });
    this.props.renameDialogHandler(this.props.mediaDocInfo);
  };

  downloadFilesHandler = (url, filename) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        const aTag = document.createElement('a');
        aTag.href = blobURL;
        aTag.setAttribute('download', filename);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      });
  };

  render() {
    const { t } = this.props;
    let fileIconType = '';
    switch (this.props.mediaDocInfo.fileIconType) {
      case 'IMAGE':
        fileIconType = MediaIMAGEIcon;
        break;
      case 'VIDEO':
        fileIconType = MediaVIDEOIcon;
        break;
      case 'AUDIO':
        fileIconType = MediaAUDIOIcon;
        break;
      case 'DOC':
        fileIconType = MediaDOCIcon;
        break;
      case 'PPT':
        fileIconType = MediaPPTIcon;
        break;
      case 'XLS':
        fileIconType = MediaXLSIcon;
        break;
      case 'PDF':
        fileIconType = MediaPDFIcon;
        break;
      default:
        fileIconType = MediaPDFIcon;
        break;
    }
    return (
      <>
        <div className={classes.Card} onClick={this.onCardClick}>
          {/* Card Image */}
          <div className={classes.CardImageWrapper}>
            <img
              src={fileIconType}
              style={{ height: '50%', objectFit: 'contain' }}
              alt="Lesson-Plan-Img"
            />
          </div>
          {/* Card Description */}
          <div className={classes.CardDescription}>
            <Tooltip title={this.props.mediaDocInfo.name}>
              <p className={classes.CardDescriptionText}>{this.props.mediaDocInfo.name}</p>
            </Tooltip>
            <div>
              {this.props.mediaDocInfo.isPublished ? (
                <span className={classes.publishStatus}>
                  <PublishedOrangeIcon />
                  <span>Shared</span>
                </span>
              ) : (
                // <span className={classes.publishStatus}>
                //   <UnpublishedOrangeIcon />
                //   <span>Unpublished</span>
                // </span>
                ''
              )}
            </div>
          </div>
          <div className={classes.actionsContainer}>
            <button className={classes.openButton} onClick={this.onOpenOptionClick}>
              Open
            </button>

            <div
              className={[classes.CardDescriptionOptions, 'TextDarkGrey'].join(' ')}
              aria-controls="lesson-card-menu"
              aria-haspopup="true"
              onClick={this.handleMenuClick}
            >
              <MoreHorizIcon />
            </div>
          </div>
        </div>

        {/* Menu  */}
        <Menu
          id="media-card-menu"
          anchorEl={this.state.mediaCardMenu}
          keepMounted
          open={Boolean(this.state.mediaCardMenu)}
          onClose={this.handleMenuClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          getContentAnchorEl={null}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <MenuItem
            disabled={this.props.mediaDocInfo.isPublished}
            onClick={this.onPublishOptionCLick}
          >
            {t('Share')}
          </MenuItem>
          <MenuItem onClick={this.onRenameOptionClick}>{t('Rename')}</MenuItem>
          <MenuItem onClick={this.onDeleteOptionClick}>{t('Delete')}</MenuItem>
          <MenuItem
            onClick={() =>
              this.downloadFilesHandler(this.props.mediaDocInfo.url, this.props.mediaDocInfo.name)
            }
          >
            Download
          </MenuItem>
        </Menu>
      </>
    );
  }
}

export default withTranslation()(MediaCard);
