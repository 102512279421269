import React from 'react';
import { Paper, Button, styled } from '@material-ui/core';
import { SaveAltOutlined as SaveAltIcon } from '@material-ui/icons';
import SharePdfIcon from '../../../assets/icons/SharePdfIcon';

const StyledButton = styled(Button)({
  padding: 0,
  height: 40,
  width: 40,
  minWidth: 40,
  borderRadius: 6,
  backgroundColor: '#fff',
  zIndex: 1000,
  '& .MuiButton-startIcon': {
    margin: 0,
  },
  '& .MuiSvgIcon-root': {
    fontSize: '28px !important',
  },
});

const ExportCanvas = (props) => {
  return (
    <div {...props}>
      {' '}
      <SharePdfIcon />
    </div>
  );
  //TODO required for feature implementation for icon
  // <StyledButton variant="contained" startIcon={<SaveAltIcon />} disableRipple {...props} />;
};

export default ExportCanvas;
