import React from 'react';
import { BBBIframeContainer } from './BBBIframe/container';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useBBBViewerStyle } from './component.style';
import Draggable from 'react-draggable';

export const BBBViewer = (props) => {
  const classes = useBBBViewerStyle();
  const {
    bbbWindowWidth,
    closeBBBViewer,
    isFullScreen,
    isUserOnline,
    isViewOpen,
    onChangeFullScreen,
    openBBBViewer,
    showFullScreen,
    draggableConfig,
    isMinimalizedUI,
    isContentUIDisplayed,
    displayMediumUI,
    displayMinimalUI,
    displayContentUI,
    hideContentUI,
  } = props;

  const displayFullScreenButton = (
    <div className={classes.fullScreenButton}>
      <FullscreenIcon onClick={showFullScreen} />
    </div>
  );

  /**
   * TODO: remove this, when BBB UI draggable feature is stable
   * this button will be used to either show/hide BBBViewer
   */
  const displayBBBViewerButton = (
    <div>
      {isViewOpen ? (
        <KeyboardArrowRightRoundedIcon style={{ color: 'white' }} onClick={closeBBBViewer} />
      ) : (
        <KeyboardArrowLeftRoundedIcon style={{ color: 'white' }} onClick={openBBBViewer} />
      )}
    </div>
  );

  const draggableHandleButton = (
    <strong className="cursor" style={{ cursor: 'pointer' }}>
      <DragIndicatorIcon style={{ color: 'white' }} />
    </strong>
  );

  const displayMinimalizeUIButton = (
    <strong className="cursor huddle-control" style={{ cursor: 'pointer' }}>
      <KeyboardArrowUpIcon style={{ color: 'white' }} onClick={displayMinimalUI} />
    </strong>
  );

  const displayMediumUIButton = (
    <strong className="cursor huddle-control" style={{ cursor: 'pointer' }}>
      <KeyboardArrowDownIcon style={{ color: 'white' }} onClick={displayMediumUI} />
    </strong>
  );

  const minimalizedOrMediumUIButton = isMinimalizedUI
    ? displayMediumUIButton
    : displayMinimalizeUIButton;

  const hideContentUIButton = (
    <strong className="cursor huddle-control" style={{ cursor: 'pointer' }}>
      <VisibilityIcon style={{ color: 'white' }} onClick={hideContentUI} />
    </strong>
  );

  const showContentUIButton = (
    <strong className="cursor huddle-control" style={{ cursor: 'pointer' }}>
      <VisibilityOffIcon style={{ color: 'white' }} onClick={displayContentUI} />
    </strong>
  );

  const hideOrShowContentUIButton = isContentUIDisplayed
    ? hideContentUIButton
    : showContentUIButton;

  let customViewOptionsPositionStyle = {};

  if (!isContentUIDisplayed) {
    customViewOptionsPositionStyle = {
      borderRadius: '0.5rem',
    };
  }

  const displayViewOptions = (
    <div className={classes.viewOptionsPosition} style={customViewOptionsPositionStyle}>
      <div className={classes.viewOptionsLayout}>
        {draggableHandleButton}
        {displayFullScreenButton}
        {hideOrShowContentUIButton}
        {minimalizedOrMediumUIButton}
      </div>
    </div>
  );

  const displayViewOptionsContainer = isUserOnline ? displayViewOptions : <></>;

  const bbbIframeContainer = (
    <BBBIframeContainer
      isFullScreen={isFullScreen}
      isContentUIDisplayed={isContentUIDisplayed}
      isMinimalizedUI={isMinimalizedUI}
      onChangeFullScreen={onChangeFullScreen}
    />
  );

  return (
    <div className={classes.draggableContainer}>
      <Draggable {...draggableConfig}>
        <div className={classes.container}>
          <div className={classes.contentContainer}>
            {displayViewOptionsContainer}
            {bbbIframeContainer}
          </div>
        </div>
      </Draggable>
    </div>
  );
};
