export function getToken() {
  return localStorage.getItem("usertoken");
}

export function setToken(token) {
  localStorage.setItem("usertoken", token);
}

export function deleteAll() {
  localStorage.clear();
}

export function setClassInfo(classinfo) {
  localStorage.setItem("classinfo", JSON.stringify(classinfo));
}

export function getClassInfo() {
  return JSON.parse(localStorage.getItem("classinfo"));
}

export function removeClassInfo() {
  localStorage.removeItem("classinfo");
}
 
export function setBoardInfo(boardinfo) {
  localStorage.setItem("boardinfo", JSON.stringify(boardinfo));
}

export function getBoardInfo() {
  return JSON.parse(localStorage.getItem("boardinfo"));
}

export function removeBoardInfo() {
  localStorage.removeItem("boardinfo");
}
export function setProfileInfo(profileinfo) {
  localStorage.setItem("profileinfo", JSON.stringify(profileinfo));
}

export function setusername(name) {
  localStorage.setItem("userName", name);
}

export function getusername() {
  return localStorage.getItem("userName");
}
