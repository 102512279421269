import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Checkbox, Dialog, IconButton } from '@material-ui/core';
import { useDialogStyle } from '../../../components/manage/commonFiles/DialogBox.styles';
import StyledDialog from '../../../UIComponents/modal/StyledDialog';
import { StyledDialogContainer } from '../../../components/common/dialog/styled-dialog/container';
import { setIsScreenshareImproveExperienceSuggestionDisabled } from '../../../Utils/authentication-access';
import DeviceDetector from 'device-detector-js';

const ScreenShareExperienceDialog = ({ open, onClose, continueClickHandler }) => {
  const classes = useDialogStyle();
  const [checked, setChecked] = React.useState(false);
  const deviceDetector = new DeviceDetector();
  const findMyOS = deviceDetector.parse(navigator.userAgent);

  console.log('findMyOS', findMyOS);

  const text = (
    <>
      <div>
        Use AirPlay/Miracast for best latency & clarity when on the same network, or click
        'Continue' for web-based screen sharing
      </div>
      {findMyOS.os.name === 'Mac' && (
        <div style={{ marginTop: '20px' }}>
          Note: Web-based Screen Share on Mac devices requires mac0S v13.0 or above. It may not work
          on versions below this.
        </div>
      )}
    </>
  );

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setIsScreenshareImproveExperienceSuggestionDisabled(true);
  };

  const centerContent = (
    <div className={classes.containerText} style={{ padding: '5px 20px' }}>
      {text}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <Checkbox
            color="primary"
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            style={{ paddingLeft: '0' }}
          />
        </div>
        <div>Do not show this popup again.</div>
      </div>
    </div>
  );

  const launchMeetingButton = (
    <Button
      className={classes.saveBtn}
      style={{ width: 'auto', padding: '0 54px' }}
      onClick={continueClickHandler}
    >
      Continue
    </Button>
  );

  const footerContent = (
    <div
      style={{
        width: '100%',
        textAlign: 'right',
      }}
    >
      {launchMeetingButton}
    </div>
  );

  return (
    <div>
      <StyledDialogContainer
        open={open}
        onClose={onClose}
        title="Enhance your Screen Sharing"
        footerContent={footerContent}
      >
        {centerContent}
      </StyledDialogContainer>
    </div>
  );
};

export default ScreenShareExperienceDialog;
