import { addSquareIcon } from '../assets/icons';

import {
  generalAgentIcon,
  lessonPlanCreatorAgentIcon,
  quizMasterAgentIcon,
  quizIcon,
  improveIcon,
  elaborateIcon,
  summariseIcon,
} from '../assets/icons';

export const SOURCES_ACTIONS = {
  ADD_DOCUMENT: {
    title: 'Add Document',
    type: 'fileInput',
    inputId: 'chat-doc-input',
    accept: '.pdf,.csv,.docx,.ppt,.pptx,.pptm,.txt',
    iconSrc: addSquareIcon,
  },
  ADD_IMAGE: {
    title: 'Add Images',
    type: 'fileInput',
    inputId: 'chat-image-input',
    accept: '.jpg,.png',
    iconSrc: addSquareIcon,
  },
  ADD_AUDIO: {
    title: 'Add Audio',
    type: 'fileInput',
    inputId: 'chat-audio-input',
    accept: '.mp3,.wav',
    iconSrc: addSquareIcon,
  },
  ADD_VIDEO: {
    title: 'Add Video',
    type: 'fileInput',
    inputId: 'chat-video-input',
    accept: '.mp4',
    iconSrc: addSquareIcon,
  },
  ADD_LIST: { title: 'Add List', iconSrc: addSquareIcon, disabled: true },
};

export const PERSONA_TYPES = {
  GENERAL: 'GENERAL',
  LESSON_PLAN_CREATOR: 'LESSON_PLAN_CREATOR',
  QUIZ_MASTER: 'QUIZ_MASTER',
};

export const PERSONA_TYPE_MAP = {
  [PERSONA_TYPES.GENERAL]: 'General',
  [PERSONA_TYPES.LESSON_PLAN_CREATOR]: 'Lesson Plan Creator',
  [PERSONA_TYPES.QUIZ_MASTER]: 'Quiz Master',
};

export const PERSONA_DETAILS = {
  [PERSONA_TYPES.GENERAL]: {
    title: 'General Teacher',
    description: '',
    icon: generalAgentIcon,
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%), #C579C9',
  },
  [PERSONA_TYPES.LESSON_PLAN_CREATOR]: {
    title: 'Lesson plan creator',
    description: 'AI crafts tailored lesson',
    icon: lessonPlanCreatorAgentIcon,
    background: '#CC3E6E',
  },
  [PERSONA_TYPES.QUIZ_MASTER]: {
    title: 'Quiz master',
    description: 'Quiz Crafting AI for educators',
    icon: quizMasterAgentIcon,
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%), #00A3E7',
  },
};

export const CHAT_TYPES = {
  USER: 'user',
  PDF: 'PDF',
  YOUTUBE: 'YOUTUBE_TRANSCRIPT',
};

export const QUERY_ACTION_TYPES = {
  SUMMARIZE: { title: 'Summarize', iconSrc: summariseIcon },
  APPLY_MY_KNOWLEDGE: { title: 'Apply My Knowledge', iconSrc: improveIcon },
  EXPLAIN_MORE: { title: 'Explain More', iconSrc: elaborateIcon },
  QUIZ_ME: { title: 'Quiz Me', iconSrc: quizIcon },
};

export const SOURCE_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
  FAILED: 'FAILED',
};
