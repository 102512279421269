import React, { useCallback, useEffect, useMemo } from 'react';
import { Hidden, makeStyles } from '@material-ui/core';

import {
  useAddNotebook,
  useDeleteNotebook,
  useGetNotebooks,
  useGetUserThreads,
  useRenameNotebook,
} from '../../services/notebooks.service';
import { useNotificationDispatcher } from '../../store/dispatcher/useNotificationDispatcher';
import { useLayoutDetails } from '../../context/layout-context';
import ControllerSidebar from '../../Kneura-Web-Whiteboard/Controller/ControllerSidebar';
import ControllerTopbar from '../../Kneura-Web-Whiteboard/Controller/ControllerTopbar';
import BrightAILayout from '../../hoc/bright-ai-layout';
import { NotebookList } from '../../components/notebooks';
import { RecentThreads } from '../../components/self-learn/recent-threads';

const useStyles = makeStyles((theme) => ({
  notebooksPageRoot: {
    display: 'flex',
    gap: 32,
    height: 'calc(100vh - 80px)',
    backgroundColor: '#FFF',
    '& .i-threads-container': {
      width: 360,
      paddingTop: 24,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}));

const NotebooksPage = ({ handleMobileSideMenuOpen }) => {
  const classes = useStyles();
  const { dispatchShowSpinner, dispatchHideSpinner } = useNotificationDispatcher();
  const { setLayoutDetails } = useLayoutDetails();

  useEffect(() => {
    setLayoutDetails({ headerTitle: 'My Notebooks' });
  }, [setLayoutDetails]);

  const { data: notebooks, isFetching: isGetNotebooksLoading } = useGetNotebooks();

  const { data: userThreads, isFetching: isGetUserThreadsLoading } = useGetUserThreads();

  const defaultNotebookId = useMemo(
    () => notebooks && notebooks.find(({ isDefaultNotebook }) => isDefaultNotebook)?.id,
    [notebooks],
  );

  const loadingStateHandlers = {
    onMutate: () => {
      dispatchShowSpinner();
    },
    onSettled: () => {
      dispatchHideSpinner();
    },
  };

  const { mutateAsync: addNotebook } = useAddNotebook({ ...loadingStateHandlers });

  const { mutateAsync: renameNotebook } = useRenameNotebook({ ...loadingStateHandlers });

  const { mutateAsync: deleteNotebook } = useDeleteNotebook({ ...loadingStateHandlers });

  const deleteNotebookHandler = useCallback(
    async (id) => await deleteNotebook(id),
    [deleteNotebook],
  );

  const isLoading = isGetNotebooksLoading || isGetUserThreadsLoading;

  useEffect(() => {
    isLoading ? dispatchShowSpinner() : dispatchHideSpinner();
  }, [isLoading, dispatchShowSpinner, dispatchHideSpinner]);

  return (
    <>
      <Hidden smDown>
        <ControllerSidebar />
      </Hidden>
      <ControllerTopbar handleMobileSideMenuOpen={handleMobileSideMenuOpen} />
      <div>
        <BrightAILayout>
          <div className={classes.notebooksPageRoot}>
            <NotebookList
              list={notebooks}
              onCreate={addNotebook}
              onRename={renameNotebook}
              onDelete={deleteNotebookHandler}
            />
            <div className="i-threads-container">
              <RecentThreads
                list={userThreads}
                notebookList={notebooks}
                defaultNotebookId={defaultNotebookId}
              />
            </div>
          </div>
        </BrightAILayout>
      </div>
    </>
  );
};

export default NotebooksPage;
