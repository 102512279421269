import React from 'react';
import { Button, makeStyles, styled } from '@material-ui/core';
import { StyledSpinner } from '../../components/common/spinner/styled-spinner';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const ButtonContentContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
});

const StyledButton = ({
  containerStyle = {},
  disabled,
  icon,
  lg,
  loading,
  marginLeft,
  marginTop,
  marginY,
  roundedBorder,
  spinnerSize,
  disableTransform,
  ...props
}) => {
  const classes = useStyles();

  if (lg) {
    containerStyle = { ...containerStyle, height: '3.375rem' };
  }

  if (marginY) {
    containerStyle = { ...containerStyle, margin: '1rem 0' };
  }

  if (marginTop) {
    containerStyle = { ...containerStyle, marginTop: '1rem' };
  }

  if (marginLeft === 'sm') {
    containerStyle = { ...containerStyle, marginLeft: '0.5rem' };
  }

  if (roundedBorder) {
    containerStyle = { ...containerStyle, borderRadius: '2.5rem' };
  }

  let content = (
    <ButtonContentContainer>
      {icon}
      {props.children}
    </ButtonContentContainer>
  );

  if (loading) {
    content = <StyledSpinner size={spinnerSize || '1rem'} />;
  }

  if (disableTransform) {
    containerStyle = { ...containerStyle, textTransform: 'none' };
  }

  const defaultStyle = {
    userSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
    MozUserSelect: 'none',
  };

  return (
    <Button
      className={classes.container}
      {...props}
      style={{ ...defaultStyle, ...containerStyle, ...props.style }}
      disabled={disabled}
    >
      {content}
    </Button>
  );
};

export default StyledButton;
