import React from 'react';

const InfoIconOutLined = ({ color }) => {
  const fill = color ? color : '#000';

  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.50006 8.4502C5.70717 8.4502 5.87506 8.2823 5.87506 8.0752V5.0752C5.87506 4.86809 5.70717 4.7002 5.50006 4.7002C5.29295 4.7002 5.12506 4.86809 5.12506 5.0752V8.0752C5.12506 8.2823 5.29295 8.4502 5.50006 8.4502Z"
        fill={fill}
      />
      <path
        d="M5.50006 3.0752C5.7762 3.0752 6.00006 3.29905 6.00006 3.5752C6.00006 3.85134 5.7762 4.0752 5.50006 4.0752C5.22392 4.0752 5.00006 3.85134 5.00006 3.5752C5.00006 3.29905 5.22392 3.0752 5.50006 3.0752Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.125061 5.5752C0.125061 2.60666 2.53153 0.200195 5.50006 0.200195C8.46859 0.200195 10.8751 2.60666 10.8751 5.5752C10.8751 8.54373 8.46859 10.9502 5.50006 10.9502C2.53153 10.9502 0.125061 8.54373 0.125061 5.5752ZM5.50006 0.950195C2.94574 0.950195 0.875061 3.02088 0.875061 5.5752C0.875061 8.12951 2.94574 10.2002 5.50006 10.2002C8.05438 10.2002 10.1251 8.12951 10.1251 5.5752C10.1251 3.02088 8.05438 0.950195 5.50006 0.950195Z"
        fill={fill}
      />
    </svg>
  );
};

export default InfoIconOutLined;
