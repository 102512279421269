import axios from "axios";

const defaultConfig = {
  headers: {
    "Content-Type": "application/json"
  }
};

export function get(url, config) {
  if (!config) config = defaultConfig;
  return axios.get(url, config);
}

export function post(url, data = {}, config) {
  if (!config) config = defaultConfig;
  return axios.post(url, data, config);
}

export function put(url, data = {}, config) {
  if (!config) config = defaultConfig;
  return axios.put(url, data, config);
}
