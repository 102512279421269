import React from 'react';
import { JoinRequestSentDialogComponent } from './component';

export const JoinRequestSentDialog = (props) => {
  const { open, onClickNext, ...rest } = props;
  const headingText = 'Request Sent';
  const descriptionText = 'Contact workspace’s admin to get started in this workspace.';

  return (
    <JoinRequestSentDialogComponent
      {...rest}
      open={open}
      headingText={headingText}
      descriptionText={descriptionText}
      onClickNext={onClickNext}
    />
  );
};
