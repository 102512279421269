import React from 'react';
import { ContentContainer } from './component.style';
import { BottomBarContainer } from './bottom-bar/container';
import { PersonalCalendarListingContainer } from './listing/container';
import { useMicrosoftAuth } from '../../../../../Utils/microsoft/useMicrosoftAuth';

export const PersonalCalendarContent = (props) => {
  const { refetchOutlookEvents, closeSidebar, isLoading } = props;
  const { isMicrosoftAuthenticated } = useMicrosoftAuth();

  const listing = (
    <PersonalCalendarListingContainer isLoading={isLoading} closeSidebar={closeSidebar} />
  );
  const bottomBar = isMicrosoftAuthenticated ? (
    <BottomBarContainer refetchOutlookEvents={refetchOutlookEvents} />
  ) : (
    <></>
  );

  return (
    <ContentContainer>
      {listing}
      {bottomBar}
    </ContentContainer>
  );
};
