import React from 'react';

const HighLighterTool = (props) => (
  <svg width="38" height="38" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4197_92)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6756 9.49268V5.6166C13.6756 5.46509 13.7612 5.32659 13.8967 5.25883L18.3862 3.01411C18.5191 2.94762 18.6756 3.04432 18.6756 3.19299V9.49268H19.6756C20.2279 9.49268 20.6724 9.94075 20.7115 10.4916C21.3283 19.1816 29.6756 27.9927 29.6756 27.9927H2.6756C2.6756 27.9927 11.0229 19.181 11.6397 10.4916C11.6788 9.94075 12.1233 9.49268 12.6756 9.49268H13.6756ZM2.6756 27.9927H29.6756V47.9927H2.6756V27.9927Z"
        fill={props.fill}
      />
      <path
        d="M29.6756 27.9927H2.6756C2.6756 27.9927 11.0229 19.1811 11.6397 10.4917C11.6788 9.94081 12.1233 9.49274 12.6756 9.49274H13.6756H18.6756H19.6756C20.2279 9.49274 20.6724 9.94081 20.7115 10.4917C21.3283 19.1817 29.6756 27.9927 29.6756 27.9927Z"
        fill="white"
      />
      <path
        d="M29.6756 27.9928C29.6756 27.9928 21.9529 19.8411 20.815 11.4862C20.6659 10.3917 19.7802 9.49283 18.6756 9.49283H16.1756V27.9928H29.6756Z"
        fill="url(#paint0_linear_4197_92)"
        fillOpacity="0.8"
      />
      <path
        d="M2.6756 27.9927C2.6756 27.9927 10.3983 19.841 11.5362 11.4861C11.6853 10.3916 12.571 9.49274 13.6756 9.49274H16.1756V27.9927H2.6756Z"
        fill="url(#paint1_linear_4197_92)"
        fillOpacity="0.8"
      />
      <path
        d="M16.1756 27.9927H29.6756V48.9927H16.1756L16.1756 27.9927Z"
        fill="url(#paint2_linear_4197_92)"
        fillOpacity="0.8"
      />
      <path
        d="M2.6756 48.9927V27.9927H16.1756V48.9927H2.6756Z"
        fill="url(#paint3_linear_4197_92)"
        fillOpacity="0.8"
      />
      <path
        d="M2.6756 27.9927H29.6756M2.6756 27.9927V48.9935H29.6756V27.9927M2.6756 27.9927C2.6756 27.9927 11.0229 19.1811 11.6397 10.4917C11.6788 9.94081 12.1233 9.49274 12.6756 9.49274H13.6756M29.6756 27.9927C29.6756 27.9927 21.3283 19.1817 20.7115 10.4917C20.6724 9.94081 20.2279 9.49274 19.6756 9.49274H18.6756M13.6756 9.49274V5.23995C13.6756 5.08844 13.7612 4.94994 13.8967 4.88218L18.3862 2.63746C18.5191 2.57097 18.6756 2.66767 18.6756 2.81634V9.49274M13.6756 9.49274H18.6756"
        stroke="black"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4197_92"
        x1="19.1756"
        y1="18.9928"
        x2="16.1756"
        y2="18.9928"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DDDDDD" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4197_92"
        x1="13.1756"
        y1="18.9927"
        x2="16.1756"
        y2="18.9927"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DDDDDD" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4197_92"
        x1="19.5506"
        y1="17.154"
        x2="16.1756"
        y2="17.154"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.2" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4197_92"
        x1="12.8006"
        y1="17.154"
        x2="16.1756"
        y2="17.154"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.2" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <clipPath id="clip0_4197_92">
        <rect width="31" height="43" fill="white" transform="translate(0.675598 0.492737)" />
      </clipPath>
    </defs>
  </svg>
);

export default HighLighterTool;
