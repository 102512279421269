/**
 * https://login.microsoftonline.com/{tenant}/oauth2/v2.0/authorize?
client_id=11111111-1111-1111-1111-111111111111
&response_type=code
&redirect_uri=http%3A%2F%2Flocalhost%2Fmyapp%2F
&response_mode=query
&scope=offline_access%20user.read%20mail.read
&state=12345

 */

import moment from 'moment';
import config from '../config';
import { constructQueryParams } from '../Utils/url';
import BaseService from './base.service';

class MicrosoftGraphService extends BaseService {
  constructor() {
    const url = config.BIRDAI_SERVER_URL;
    super(url);
  }

  /**
   *
   * @param {{code: string}} param0
   * @returns
   */
  async getAccessToken({ code }) {
    const path = '/microsoft/get-access-token';
    const data = {
      code,
    };
    return this.makePostRequest(path, data);
  }

  async hasAuthorizedOAuth() {
    try {
      const path = '/microsoft/has-authorized-oauth';
      const res = await this.makeGetRequest(path);
      return res.data.hasAuthorizedOAuth;
    } catch (error) {
      return false;
    }
  }

  async getMyEvents() {
    const top = 100;
    const skip = 0;
    const queryParams = constructQueryParams({
      top,
      skip,
    });
    const path = `/microsoft/me/events?${queryParams}`;
    const res = await this.makeGetRequest(path);
    return res.data.value;
  }

  /**
   *
   * @param {string[]} iCalUIds
   * @returns
   */
  async getEventsByICalUIds(iCalUIds) {
    try {
      const path = `/microsoft/me/events`;
      const res = await this.makeGetRequest(path, {
        params: { iCalUIds },
      });
      return res.data.value[0];
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  /**
   * recurrence events supported by microsoft
   * const RECURRENCE_EVENTS = ['daily', 'weekly', 'absoluteMonthly', 'absoluteYearly'];
   *
   * Note: absoluteMonthly, absoluteYearly recurrence events is not supported by TeamHub
   * @returns
   */
  async getMyUpcomingEvents() {
    const events = await this.getMyEvents();
    const now = moment();
    return events.filter((event) => {
      if (!event.recurrence) {
        const endDate = moment(`${event.end.dateTime}Z`);
        return endDate.isAfter(now);
      }

      const SUPPORTED_RECURRENCE_EVENTS = ['daily', 'weekly'];
      const endDate = event?.recurrence?.range?.endDate;
      if (
        SUPPORTED_RECURRENCE_EVENTS.includes(event?.recurrence?.pattern?.type) &&
        (endDate === '0001-01-01' || moment(endDate).isAfter(now))
      ) {
        return true;
      }
    });
  }

  async getMyCalendarEvents() {
    const now = moment();
    const startDate = now.subtract(1, 'days').format();
    const endDate = now.add(2, 'days').endOf('day').format();
    const startDateTime = startDate;
    const endDateTime = endDate;
    const queryParams = constructQueryParams({
      startDateTime,
      endDateTime,
    });
    const path = `/microsoft/me/calendar-view?${queryParams}`;
    const res = await this.makeGetRequest(path);
    return res.data.value;
  }

  async getMyUpcomingCalendarEvents() {
    const events = await this.getMyCalendarEvents();
    const now = moment();
    return events;
    //TODO : it's for references
    // events.filter((event) => {
    //   if (!event.recurrence) {
    //     const endDate = moment(`${event.end.dateTime}Z`);
    //     return endDate.isAfter(now);
    //   }

    //   const SUPPORTED_RECURRENCE_EVENTS = ['daily', 'weekly'];
    //   const endDate = event?.recurrence?.range?.endDate;
    //   if (
    //     SUPPORTED_RECURRENCE_EVENTS.includes(event?.recurrence?.pattern?.type) &&
    //     (endDate === '0001-01-01' || moment(endDate).isAfter(now))
    //   ) {
    //     return true;
    //   }
    // });
  }

  async getMyOutlookUpcomingEvents() {
    const now = moment();
    const startDate = now.format('YYYY-MM-DD');
    const endDate = now.add(3, 'months').format('YYYY-MM-DD');
    const startDateTime = `${startDate}T00:00:00-05:30`;
    const endDateTime = `${endDate}T00:00:00-05:30`;
    const path = `/microsoft/me/upcoming-events`;
    const data = {
      getCalendarViewDto: {
        startDateTime,
        endDateTime,
        top: 1000,
        skip: 0,
      },
      getEventsDto: {
        top: 1000,
        skip: 0,
      },
    };
    const res = await this.makePostRequest(path, data);
    return res.data.value;
  }

  async getMe() {
    const path = '/microsoft/me';
    const res = await this.makeGetRequest(path);
    return res.data;
  }

  async revokeAuthorization() {
    try {
      const path = '/microsoft/revoke-authorization';
      return this.makePostRequest(path);
    } catch (error) {
      console.log(`error: ${error.message}`);
    }
  }

  async getCommunicationIdentity() {
    try {
      const path = '/microsoft/communication-identity';
      const res = await this.makeGetRequest(path);
      return res.data.communicationIdentity;
    } catch (error) {
      console.log(`error: ${error.message}`);
    }
  }
}

const microsoftGraphService = new MicrosoftGraphService();
export default microsoftGraphService;
