import React, { useRef, useState } from 'react';
import ControllerSidebar from '../../Kneura-Web-Whiteboard/Controller/ControllerSidebar';
import ControllerTopbar from '../../Kneura-Web-Whiteboard/Controller/ControllerTopbar';
import LayoutFrame from '../componentsFrame/LayoutFrame';
import MobileViewTopbar from '../../common/MobileViewTopbar/MobileViewTopbar';
import Topbar from '../componentsFrame/Topbar';
import { Button, Hidden, Menu, MenuItem, useMediaQuery } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import classes from './files.module.css';
import createPluseSymbol from '../../assets/spaces/createPluseSymbol.svg';
import MediaCard from './MediaCard/MediaCard';

const Files = () => {
  const isMobileView = useMediaQuery('(max-width: 800px)');
  const history = useHistory();
  const [sorterAnchorEl, setSorterAnchorEl] = React.useState(null);
  const sortSpacesOption = Boolean(sorterAnchorEl);
  const [sortType, setSortType] = useState('Name');
  const inputOpenFileRef = useRef();
  const [mediaData, setMediaData] = useState([]);

  const onEnterSearchText = (value) => {
    // if (value.length == 0) {
    //   setFilteredBoardData(test);
    // } else {
    //   let data = test.filter((item) => {
    //     return item.name.toLowerCase().includes(value.toLowerCase());
    //   });
    //   setFilteredBoardData(data);
    // }
  };

  const onUploadMedia = async (event) => {
    // this.props.setUploadFile(event.target.files);
    // this.handleFileUpload();
  };

  const onPublishOptionMedia = (mediaInfo) => {
    // const viewMediaUrl =
    //   'https://docs.google.com/viewer?url=' +
    //   encodeURIComponent(mediaInfo.url) +
    //   '&pid=explorer&efh=false&a=v&chrome=false&embedded=true';
    // const newMediaInfo = { ...mediaInfo, viewerUrl: viewMediaUrl };
    // this.setState(
    //   (prevProps) => ({ ...prevProps, selectedMediaCard: { ...newMediaInfo } }),
    //   () => {
    //     this.onPublishMedia();
    //   },
    // );
  };

  return (
    <>
      <LayoutFrame pageTitle="Files">
        {isMobileView ? (
          <MobileViewTopbar
            title={'Files'}
            onSearchTextChange={onEnterSearchText}
            showFilters={true}
          // filtersViewHandler={filtersViewHandler}
          // createActionButton1={false}
          />
        ) : (
          <Topbar
            title={'Files'}
            onEnterSearchText={onEnterSearchText}
            shortButton={
              <div>
                {' '}
                {/* <SortIcon
                fontSize="small"
                style={{
                  color: 'rgba(0, 0, 0, 0.6)',
                  marginLeft: '0.5rem',
                  cursor: 'pointer',
                }}
                onClick={sortSpaces}
              /> */}
                <Button
                  variant="contained"
                  // onClick={sortSpaces}
                  endIcon={<ArrowDropDownIcon style={{ color: '#335AFB' }} />}
                  className={classes.SortButton}
                >
                  {sortType}
                </Button>
                {/* ////// */}
                <Menu
                  id="sorter-menu"
                  anchorEl={sorterAnchorEl}
                  keepMounted
                  open={sortSpacesOption}
                  // onClose={sortSpacesOptionClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  getContentAnchorEl={null}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <MenuItem
                    className={sortType === 'Name' ? 'activeSort' : ''}
                  // onClick={() => sortByName()}
                  >
                    Name
                  </MenuItem>
                  <MenuItem
                    className={sortType === 'Last Modified' ? 'activeSort' : ''}
                  // onClick={() => sortSpacesByUpdated()}
                  >
                    Last Modified
                  </MenuItem>
                  <MenuItem
                    className={sortType === 'Date Created' ? 'activeSort' : ''}
                  // onClick={() => sortSpacesByDate()}
                  >
                    Date Created
                  </MenuItem>
                </Menu>
                {/* /////// */}
              </div>
            }
          />
        )}

        <div>
          {/* Render FIles here */}
          <div className={classes.cardsContainer}>
            <label htmlFor="import-media">
              <div className={classes.createLessonWrap}>
                <img
                  src={createPluseSymbol}
                  style={{
                    width: '38px',
                    height: '38px',
                  }}
                  alt="Create Assessment"
                />
                <div className={classes.createBtnText}>
                  <div className={classes.createLessonTitle}>Upload a new File</div>
                </div>
              </div>
            </label>

            {mediaData.map((mediaData) => (
              <MediaCard
                publishMedia={(info) => {
                  onPublishOptionMedia(info);
                }}
              // selectedMedia={(info) => {
              //   this.onClickMediaCard(info);
              // }}
              // deleteMediaInfo={(info) => {
              //   this.deleteMediaInfo(info);
              // }}
              // mediaDocInfo={mediaData}
              // renameDialogHandler={(info) => {
              //   this.renameDialogHandler(info);
              // }}
              />
            ))}

            {/* {this.state.mediaData?.length >= this.state.limit && (
              <>
                <InView
                  onChange={(inView, entry) => {
                    console.log('inView', inView);
                    if (inView == true) {
                      this.handleInView();
                    }
                  }}
                >
                  {({ inView, ref, entry }) => <div ref={ref}></div>}
                </InView>
              </>
            )} */}
          </div>
          <input
            ref={inputOpenFileRef}
            accept="image/*,video/*,application/x-mpegURL,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.spreadsheetml.template,application/vnd.ms-excel.sheet.macroEnabled.12,application/vnd.ms-excel.template.macroEnabled.12,application/vnd.ms-excel.addin.macroEnabled.12,application/vnd.ms-excel.sheet.binary.macroEnabled.12,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template,application/vnd.ms-word.document.macroEnabled.12,application/vnd.ms-word.template.macroEnabled.12,text/plain,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.presentationml.template,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.ms-powerpoint.addin.macroEnabled.12,application/vnd.ms-powerpoint.presentation.macroEnabled.12,application/vnd.ms-powerpoint.template.macroEnabled.12,application/vnd.ms-powerpoint.slideshow.macroEnabled.12,application/pdf"
            style={{ display: 'none' }}
            id="import-media"
            onChange={onUploadMedia}
            type="file"
          />
        </div>
        {/* accept="image/*,video/*,application/x-mpegURL,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.spreadsheetml.template,application/vnd.ms-excel.sheet.macroEnabled.12,application/vnd.ms-excel.template.macroEnabled.12,application/vnd.ms-excel.addin.macroEnabled.12,application/vnd.ms-excel.sheet.binary.macroEnabled.12,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template,application/vnd.ms-word.document.macroEnabled.12,application/vnd.ms-word.template.macroEnabled.12,text/plain,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.presentationml.template,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.ms-powerpoint.addin.macroEnabled.12,application/vnd.ms-powerpoint.presentation.macroEnabled.12,application/vnd.ms-powerpoint.template.macroEnabled.12,application/vnd.ms-powerpoint.slideshow.macroEnabled.12,application/pdf" */}
      </LayoutFrame>
    </>
  );
};

export default Files;
