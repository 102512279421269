import React from 'react';
import { useSelector } from 'react-redux';
import { acceptedFileTypes, importSupportedContentTypes } from '../../../constants/canvas';
import { DocumentActionDialog } from './component';

export const DocumentActionDialogContainer = (props) => {
  const fileInfo = useSelector((state) => state.documentImport.fileInfo);
  const { openDocumentDialog, closeDocumentActionDialog, showPDF, showDocument } = props;
  const dialogTitle = 'Import file';
  const headingText = 'Import Document';
  const descriptionText = 'Do you want to view the the document or import the document to canvas?';
  const viewButtonLabel = 'VIEW';
  const importButtonLabel = 'IMPORT AND EXPAND';

  const onImportClick = () => {
    openDocumentDialog();
    closeDocumentActionDialog();
  };

  const onViewClick = () => {
    fileInfo.fileType === 'application/pdf' ? showPDF() : showDocument();
    closeDocumentActionDialog();
  };

  return (
    <DocumentActionDialog
      {...props}
      acceptedFileTypes={[...acceptedFileTypes, ...importSupportedContentTypes]}
      descriptionText={descriptionText}
      dialogTitle={dialogTitle}
      fileInfo={fileInfo}
      headingText={headingText}
      importButtonLabel={importButtonLabel}
      onImportClick={onImportClick}
      onViewClick={onViewClick}
      viewButtonLabel={viewButtonLabel}
    />
  );
};
