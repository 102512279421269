import { makeStyles } from '@material-ui/core';

export const useShareLinkContentStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: '1rem',
  },
}));
