/**
 * For creating debounced version of a function
 *
 * @export
 * @param {*} fun { function to be converted }
 * @param {*} delay {debounce time delay in milliseconds}
 * @returns
 */

export function debounce(fun, delay) {
  if (typeof fun === 'function' && typeof delay === 'number') {
    let inDebounce;
    return function () {
      const context = this;
      const args = [...arguments];
      clearTimeout(inDebounce);
      inDebounce = setTimeout(() => {
        fun.apply(context, args);
      }, delay);
    };
  } else {
    throw new Error('First parameter should be a function and second parameter should be number');
  }
}

export const sleep = (duration = 1000) =>
  new Promise((resolve) => setTimeout(() => resolve(), duration));
