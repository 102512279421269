import React from 'react';
import { Hidden } from '@material-ui/core';
import ControllerSidebar from '../../Kneura-Web-Whiteboard/Controller/ControllerSidebar';
import ControllerTopbar from '../../Kneura-Web-Whiteboard/Controller/ControllerTopbar';
import PageLayout from './PageLayout';

const LayoutFrame = ({ children, pageTitle }) => {
  return (
    <>
      <Hidden smDown>
        <ControllerSidebar />
      </Hidden>
      <ControllerTopbar pageTitle={pageTitle} />
      <PageLayout>{children}</PageLayout>
    </>
  );
};

export default LayoutFrame;
