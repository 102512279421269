import React from 'react';
import PubSub from 'pubsub-js';
import { useMediaQuery } from '@material-ui/core';

import { StyledDialogContainer } from '../../common/dialog/styled-dialog/container';
import { PdfPagesGalleryContainer } from '../pdf-pages-gallery/container';
import { FooterContainer } from '../pdf-pages-gallery/footer/container';
import { useDocumentDialogStyles } from './component.style';
import { EVENTS } from '../../../constants/events';

export const DocumentDialog = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const classes = useDocumentDialogStyles();
  const {
    allPdfPagesNos,
    closeDialog,
    currentGalleryPage,
    displayPagesNos,
    docInfo,
    goToNextGalleryPage,
    goToPreviousGalleryPage,
    hasNextGalleryPage,
    hasPrevGalleryPage,
    importDocs,
    isOpen,
    onDocumentLoadSuccess,
    onLoadError,
    pageRange,
    pagesPerGalleryPage,
    setCurrentGalleryPage,
    setFirstPageRange,
    setLastPageRange,
    shouldBeFullscreen,
    title,
    toggleShouldBeFullscreen,
    totalGalleryPage,
    totalPages,
    url,
  } = props;

  const footerContent = totalGalleryPage > 0 && docInfo && (
    <FooterContainer
      pageRange={pageRange}
      setFirstPageRange={setFirstPageRange}
      setLastPageRange={setLastPageRange}
      shouldBeFullscreen={shouldBeFullscreen}
      toggleShouldBeFullscreen={toggleShouldBeFullscreen}
      importDocs={importDocs}
    />
  );

  return (
    <div className={classes.container}>
      <StyledDialogContainer
        title={title}
        open={isOpen}
        onClose={closeDialog}
        footerContent={footerContent}
        maxWidth={isMobile ? 'md' : 'sm'}
        PaperProps={isMobile ? { style: { borderRadius: 0 } } : undefined}
        fullScreen={isMobile}
        classes={{ paperWidthSm: classes.paperWidthSm }}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          e.preventDefault();
          const type = e.dataTransfer.getData('type');
          if (type !== 'pdf-page-drag') return;
          const data = JSON.parse(e.dataTransfer.getData('data'));
          PubSub.publish(EVENTS.IMAGES_TO_CANVAS, {
            data,
          });
        }}
      >
        <PdfPagesGalleryContainer
          url={url}
          closeDialog={closeDialog}
          allPdfPagesNos={allPdfPagesNos}
          currentGalleryPage={currentGalleryPage}
          displayPagesNos={displayPagesNos}
          goToNextGalleryPage={goToNextGalleryPage}
          goToPreviousGalleryPage={goToPreviousGalleryPage}
          hasNextGalleryPage={hasNextGalleryPage}
          hasPrevGalleryPage={hasPrevGalleryPage}
          importDocs={importDocs}
          onDocumentLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onLoadError}
          pageRange={pageRange}
          pagesPerGalleryPage={pagesPerGalleryPage}
          setCurrentGalleryPage={setCurrentGalleryPage}
          setFirstPageRange={setFirstPageRange}
          setLastPageRange={setLastPageRange}
          shouldBeFullscreen={shouldBeFullscreen}
          toggleShouldBeFullscreen={toggleShouldBeFullscreen}
          totalGalleryPage={totalGalleryPage}
          totalPages={totalPages}
        />
      </StyledDialogContainer>
    </div>
  );
};
