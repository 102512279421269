import React, { Component } from 'react';
import '../toolbar.scss';
import backgroundCss from './BackgroundIcon.module.css';
import { Popover, Tooltip, withStyles } from '@material-ui/core';
import { BG_DEFAULT_THEME_ICON, BG_ICON_TYPES, BG_TYPES } from './background';
import { checkIsAuthUser } from '../../../../Utils/authentication-access';

class BackgroundIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bgOptionsMenu: false,
      currentThemeSrc: BG_DEFAULT_THEME_ICON,
    };
  }
  componentDidMount = () => {
    this.setState({
      currentThemeSrc: BG_ICON_TYPES[this.props.theme] || this.state.currentThemeSrc,
    });
  };
  componentDidUpdate(props) {
    if (props.theme !== this.props.theme) {
      this.setState({ currentThemeSrc: BG_ICON_TYPES[this.props.theme] });
    }
  }
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  backgroundOptionsClicked = async (event) => {
    this.setState({
      bgAnchorEl: event.currentTarget,
      bgOptionsMenu: true,
    });
  };
  activateTheme = (type) => {
    this.setState({ currentThemeSrc: BG_ICON_TYPES[type] });
    this.closeMenu();
    this.props.onIconClick(BG_TYPES[type]);
  };
  handleFileChange = (event) => {
    this.props.onIconClick(BG_TYPES.BG_CUSTOM, event.target.files[0]);
    this.closeMenu();
  };

  closeMenu = () => this.setState({ bgOptionsMenu: false });
  render() {
    const isAuthUser = checkIsAuthUser();
    return (
      <div>
        <Tooltip title="Canvas Background">
          <div onClick={this.backgroundOptionsClicked}>
            <img src={this.state.currentThemeSrc} alt="background preview" />
          </div>
        </Tooltip>
        <Popover
          id={'backgroundOptionPopup'}
          onClose={() => this.setState({ bgOptionsMenu: false })}
          open={this.state.bgOptionsMenu}
          anchorEl={this.state.bgAnchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          marginThreshold={0}
          ref={(ref) => {
            ref && ref.addEventListener('wheel', (e) => e.preventDefault());
            ref && ref.addEventListener('gesturestart', (e) => e.preventDefault());
          }}
          PaperProps={{
            style: {
              transform: 'translate(-12px, 2px)',
            },
          }}
        >
          <div className={backgroundCss.recorderMenu}>
            <div
              style={{
                display: 'grid',
                alignItems: 'center',
                gridTemplateColumns: '80%',
                gridGap: '10px',
                backgroundColor: 'white',
                padding: '10px',
              }}
            >
              <div>
                <label htmlFor={'myInput'}>
                  <img
                    src={BG_ICON_TYPES.BG_CUSTOM}
                    alt=""
                    className={this.props.classes.imageHolder}
                  />
                </label>
                <input
                  id="myInput"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={this.handleFileChange}
                />
              </div>
              <img
                src={BG_ICON_TYPES.BG_GREEN}
                onClick={() => this.activateTheme(BG_TYPES.BG_GREEN)}
                alt=""
                className={this.props.classes.imageHolder}
              />
              <img
                src={BG_ICON_TYPES.BG_DARK}
                onClick={() => this.activateTheme(BG_TYPES.BG_DARK)}
                alt=""
                className={this.props.classes.imageHolder}
              />
              <img
                src={BG_ICON_TYPES.BG_MATH}
                onClick={() => this.activateTheme(BG_TYPES.BG_MATH)}
                alt=""
                className={this.props.classes.imageHolder}
              />
              <img
                src={BG_ICON_TYPES.BG_LINES}
                onClick={() => this.activateTheme(BG_TYPES.BG_LINES)}
                alt=""
                className={this.props.classes.imageHolder}
              />
              <img
                src={BG_ICON_TYPES.BG_ENGLISH}
                onClick={() => this.activateTheme(BG_TYPES.BG_ENGLISH)}
                alt=""
                className={this.props.classes.imageHolder}
              />
              <img
                src={BG_ICON_TYPES.BG_LIGHT}
                onClick={() => this.activateTheme(BG_TYPES.BG_LIGHT)}
                alt=""
                className={this.props.classes.imageHolder}
              />
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

const styles = {
  imageHolder: {
    height: '1.5rem',
  },
};

export default withStyles(styles)(BackgroundIcon);
