import { styled } from '@material-ui/core';

export const ContentContainer = styled('div')({
  flex: 1,
  overflowY: 'scroll',
  padding: '1rem',
});

export const DayEventHeading = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
});

export const DayEvent = styled('div')(({ theme }) => ({}));

export const DayEvents = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  '& > *': {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    paddingBottom: '0.5rem',
  },
  '& > *:last-child': {
    borderBottom: 'none',
    paddingBottom: 0,
  },
}));
