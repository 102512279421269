import React from 'react';
import { Dialog } from '@material-ui/core';
import { StyledFooterContainer } from '../styled-footer/container';
import { useStyledDialogStyles } from './component.style';
import { StyledHeaderContainer } from '../styled-header/container';

export const StyledDialog = (props) => {
  let {
    closeText,
    contentPadding,
    containerStyle,
    contentStyle,
    disabledSubmitButton,
    footerContent,
    hideFooter,
    onClose,
    onCloseFooter,
    onSubmit,
    open,
    showFooterCloseButton,
    submitText,
    title,
    fullWidth = true,
    ...rest
  } = props;
  const classes = useStyledDialogStyles();

  if (contentPadding) {
    contentStyle = { ...contentStyle, padding: '1rem', ...containerStyle };
  }

  const footer = !hideFooter && (
    <StyledFooterContainer
      closeText={closeText}
      disabledSubmitButton={disabledSubmitButton}
      footerContent={footerContent}
      onClose={onClose}
      onCloseFooter={onCloseFooter}
      onSubmit={onSubmit}
      showFooterCloseButton={showFooterCloseButton}
      submitText={submitText}
    />
  );

  const header = <StyledHeaderContainer title={title} onClose={onClose} />;

  const content = (
    <div className={classes.content} style={contentStyle}>
      {props.children}
    </div>
  );

  return (
    <Dialog
      fullWidth={fullWidth}
      className="overlay"
      PaperProps={{
        style: {
          borderRadius: 16,
        },
      }}
      {...rest}
      open={open}
      onClose={onClose}
    >
      {header}
      {content}
      {footer}
    </Dialog>
  );
};

StyledDialog.defaultProps = {
  contentStyle: {},
  contentPadding: true,
};
