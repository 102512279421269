import { useState, useEffect } from 'react';
import { registerEvent } from '../store/service/socket.service';

export const useUpdateCursorSocket = () => {
  const [socketData, setSocketData] = useState({
    color: '#000',
    flowId: '',
    id: '',
    mousePosX: 0,
    mousePosY: 0,
    type: 'update-cursor',
    userName: '',
  });

  //   register event only once
  useEffect(() => {
    registerEvent({
      type: 'update-cursor',
      callback: (value) => {
        // console.log("update-cursor socket event", value);
        setSocketData(value);
      },
    });
  }, []);

  return socketData;
};
