import { makeStyles } from '@material-ui/core';

export const useShareUsingCanvasCodeStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
}));
