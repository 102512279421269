import socketIOClient from 'socket.io-client';
import { BIRDAI_SERVER_URL } from '../../config';
import { getToken } from '../authentication-access';

export let socket;

export const sendMessageToDevice = (channel, msg) => {
  try {
    socket.emit(channel, msg);
  } catch (error) {
    console.log(error);
  }
};

export const initSocket = async () => {
  if (socket) {
    return;
  }

  socket = socketIOClient(BIRDAI_SERVER_URL, {
    path: '/user-events',
    auth: {
      token: getToken(),
    },
    reconnection: true,
    transports: ['websocket'],
    upgrade: true,
    autoConnect: true,
  });
};

export const sendRemoteStartMessageToDevice = () => {
  sendMessageToDevice('REMOTE_CONTROL', {
    type: 'REMOTE_START',
  });
};
