import moment from 'moment';
import {
  getNextStartDateTimeForDaily,
  getNextStartDateTimeForWeekly,
} from '../../../../Utils/microsoft/outlook-event';
import { useMicrosoftDispatcher } from '../../../../store/dispatcher/useMicrosoftDispatcher';
import { getFlowId } from '../../../../Utils/authentication-access';
import microsoftGraphService from '../../../../services/microsoft-graph.service';
import { schedulesService } from '../../../../services/schedules.service';
import { useSchedulesDispatcher } from '../../../../store/dispatcher/useSchedulesDispatcher';
import { useCallback, useState } from 'react';
import { useMicrosoftSelector } from '../../../../store/selector/useMicrosoftSelector';
import { useMicrosoftAuth } from '../../../../Utils/microsoft/useMicrosoftAuth';
import { useDialogDispatcher } from '../../../../store/dispatcher/useDialogDispatcher';
import { useLastPageVisited } from '../../../../Utils/history/useLastPageVisited';

export const useGetOutlookEvents = () => {
  const { dispatchSetOutlookImportedEvents } = useSchedulesDispatcher();
  const { dispatchSetOutlookEvents } = useMicrosoftDispatcher();
  const { isAuthenticated, outlookEvents } = useMicrosoftSelector();
  const { isMicrosoftAuthenticated } = useMicrosoftAuth();
  const { dispatchSetIsOpenImportOutlookEventsDialog } = useDialogDispatcher();
  const { setImportOutlookEventDialogAsLastVisited } = useLastPageVisited();
  const { initiateMicrosoftOAuthAuthorization } = useMicrosoftAuth();

  const [isHydrated, setIsHydrated] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * +5:30 hours is added for the ease to sort the events
   * @param {*} startDateTime
   * @returns
   */
  const generateTimeForComparision = (startDateTime) => {
    const timeString = moment(`${startDateTime}Z`).add(330, 'minutes').toISOString().substring(10);
    return `2022-01-01${timeString}`;
  };

  const addTimeForComparisionField = (event) => {
    const timeForComparision = generateTimeForComparision(event.start.dateTime);
    return {
      ...event,
      timeForComparision,
    };
  };

  const sortEventsByNextStartDateTimeFn = (a, b) => {
    return moment(a.nextStartDateTime).isBefore(b.nextStartDateTime) ? -1 : 1;
  };

  const addNextRecurringStartDateTime = (event) => {
    if (event?.recurrence?.pattern?.type === 'absoluteMonthly') {
      return {
        ...event,
        nextStartDateTime: event.start.dateTime,
      };
    }

    if (event?.recurrence?.pattern?.type === 'absoluteYearly') {
      return {
        ...event,
        nextStartDateTime: event.start.dateTime,
      };
    }

    if (event?.recurrence?.pattern?.type === 'daily') {
      return {
        ...event,
        nextStartDateTime: getNextStartDateTimeForDaily(event),
      };
    }

    if (event?.recurrence?.pattern?.type === 'weekly') {
      return {
        ...event,
        nextStartDateTime: getNextStartDateTimeForWeekly(event),
      };
    }
  };

  const addNextStartDateTime = (event) => {
    if (!event.recurrence) {
      return {
        ...event,
        nextStartDateTime: `${event.start.dateTime}Z`,
      };
    }

    return addNextRecurringStartDateTime(event);
  };

  /**
   * Note:
   * this was used in the past to get the outlook events
   * in import outlook event dialog
   */
  const getOutlookEvents = useCallback(
    /**
     *
     * @param {{ forceRefresh: boolean }} param0
     * @returns
     */
    async ({ forceRefresh } = {}) => {
      try {
        if (!forceRefresh && outlookEvents.length) {
          return;
        }

        if (isAuthenticated) {
          setIsLoading(true);
          const events = await microsoftGraphService.getMyOutlookUpcomingEvents();

          const flowId = getFlowId();
          const outlookImportedEventsIds = await schedulesService.getOutlookImportedEventsIds({
            flowId,
          });
          dispatchSetOutlookImportedEvents(outlookImportedEventsIds);

          /**
           * How does sorting by time work
           * add a field, e.g timeForComparision
           * where the date portion is made contant and
           * time portion is extracted from the startTime field of the event
           */
          const sortedEvents = events
            .map(addTimeForComparisionField)
            .map(addNextStartDateTime)
            .sort(sortEventsByNextStartDateTimeFn);
          dispatchSetOutlookEvents({ outlookEvents: sortedEvents });
          setIsLoading(false);
        } else {
          dispatchSetOutlookEvents({ outlookEvents: [] });
        }
      } catch (error) {
        setIsHydrated(true);
        setIsLoading(false);
      }
    },
    [isAuthenticated, dispatchSetOutlookEvents, dispatchSetOutlookImportedEvents, outlookEvents],
  );

  const openImportOutlookCalendarEventDialog = () => {
    if (isMicrosoftAuthenticated) {
      dispatchSetIsOpenImportOutlookEventsDialog(true);
    } else {
      setImportOutlookEventDialogAsLastVisited();
      initiateMicrosoftOAuthAuthorization();
    }
  };

  return { getOutlookEvents, isLoading, isHydrated, openImportOutlookCalendarEventDialog };
};
