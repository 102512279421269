import store from '../../store';
import { setHuddleCallDuration } from '../../store/actions/huddle.action';
import {
  showFeatureLockedDialog,
  showHuddleSessionLimitExhaustedDialog,
  showLicenseExhaustedDialog,
  showMaxSimultaneousUsageLimitExhaustedDialog,
} from '../../store/actions/notification.action';

const LICENSE_ERROR_TYPES = {
  FEATURE_NOT_ENABLED: 'FEATURE_NOT_ENABLED',
  FEATURE_LIMIT_EXHAUSTED: 'FEATURE_LIMIT_EXHAUSTED',
  SIMULTANEOUS_LIMIT_EXHAUSTED: 'SIMULTANEOUS_LIMIT_EXHAUSTED',
  SESSION_LIMIT_EXHAUSTED: 'SESSION_LIMIT_EXHAUSTED',
};

export const licenseExceptionHandler = (message) => {
  const { feature, type } = message;
  if (type === LICENSE_ERROR_TYPES.FEATURE_NOT_ENABLED) {
    store.dispatch(showFeatureLockedDialog({ feature }));
    return;
  } else if (type === LICENSE_ERROR_TYPES.FEATURE_LIMIT_EXHAUSTED) {
    store.dispatch(showMaxSimultaneousUsageLimitExhaustedDialog({ feature }));
    return;
  } else if (type === LICENSE_ERROR_TYPES.SIMULTANEOUS_LIMIT_EXHAUSTED) {
    store.dispatch(showMaxSimultaneousUsageLimitExhaustedDialog({ feature }));
    return;
  } else if (type === LICENSE_ERROR_TYPES.SESSION_LIMIT_EXHAUSTED) {
    // store.dispatch(showLicenseExhaustedDialog({ feature }));
    if (message.meta && message?.meta?.duration) {
      store.dispatch(setHuddleCallDuration({ callDuration: message.meta.duration }));
    }
    store.dispatch(showHuddleSessionLimitExhaustedDialog({ feature }));
    console.log("feature", message)

    //777
  } else {
    console.log('Unknown license error type: ', { feature, type });
    return;
  }
};
