import { useRef } from 'react';

export const useEventQueue = ({ onDequeueEvent }) => {
  const eventQueue = useRef([]);
  const intervalId = useRef(null);

  const dequeueEvent = () => {
    if (eventQueue.current.length > 0) {
      const event = eventQueue.current.shift();
      onDequeueEvent(event);
    } else if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  };

  const enqueueEvent = (event) => {
    eventQueue.current.push(event);
    if (!intervalId.current) {
      intervalId.current = setInterval(dequeueEvent, 15);
    }
  };

  return {
    dequeueEvent,
    enqueueEvent,
  };
};
