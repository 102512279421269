import React, { useRef, useState } from 'react';
import { Keyboard } from './component';

export const KeyboardContainer = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
  const { handleChange, value } = props;

  const open = () => {
    console.log('open keyboard');
    // setIsOpen(true);
    inputRef.current.focus();
  };

  const close = () => {
    console.log('close keyboard');
    // setIsOpen(false);
    handleBlur();
  };

  const handleBlur = () => {
    setIsOpen(false);
  };

  const onDisplayIconClick = () => {
    // if (isOpen) {
    // close();
    // } else {
    // open();
    // }

    open();
  };

  return (
    <Keyboard
      isOpen={isOpen}
      ref={inputRef}
      onDisplayIconClick={onDisplayIconClick}
      handleChange={handleChange}
      handleBlur={handleBlur}
      value={value}
    />
  );
};
