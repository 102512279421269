import React, { useState, useMemo } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';

import Notebook from './notebook';
import NotebookListHeader from './header';
import NewNotebook from './new-notebook';
import { InputDialog } from '../../dialogs';
import { useSelector } from 'react-redux';
import { useNotificationDispatcher } from '../../../store/dispatcher/useNotificationDispatcher';

export const sortOptions = {
  ASCENDING: 'Ascending',
  DESCENDING: 'Descending',
  LATEST: 'Latest',
  OLDEST: 'Oldest',
};

const useStyles = makeStyles((theme) => ({
  notebookListRoot: {
    flex: 1,
    padding: 24,
    boxSizing: 'border-box',
    '& *, *::after, *::before': {
      boxSizing: 'border-box',
    },
    '& .i-notebook-list-container': {
      maxHeight: 'calc(100% - 32px)',
      overflow: 'auto',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
      gap: 16,
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '1fr 1fr 1fr',
      },
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 1fr 1fr',
        maxHeight: 'initial',
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr 1fr',
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '1fr',
      },
    },
  },
}));

export const NotebookList = ({ list = [], onCreate, onRename, onDelete }) => {
  const classes = useStyles();
  const [showNewNotebookDialog, setShowNewNotebookDialog] = useState(false);
  const [sortBy, setSortBy] = useState(sortOptions.LATEST);
  const [searchValue, setSearchValue] = useState('');
  // const licenseStatus = useSelector((state) => state.brightAiLicenseReducer.isLicenseActive);
  const { dispatchShowFeatureLockedDialog } = useNotificationDispatcher();

  const toggleNewNotebookDialog = () => {
    setShowNewNotebookDialog(!showNewNotebookDialog);
  };

  const filteredList = useMemo(() => {
    const filteredBySearch = list.filter(({ name }) =>
      name.toLowerCase().includes(searchValue.toLocaleLowerCase()),
    );
    switch (sortBy) {
      case sortOptions.ASCENDING:
        return filteredBySearch.sort(({ name: nameA }, { name: nameB }) =>
          nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1,
        );
      case sortOptions.DESCENDING:
        return filteredBySearch.sort(({ name: nameA }, { name: nameB }) =>
          nameA.toLowerCase() < nameB.toLowerCase() ? 1 : -1,
        );
      case sortOptions.OLDEST:
        return filteredBySearch.sort(({ createdAt: createdAtA }, { createdAt: createdAtB }) =>
          moment(createdAtA) < moment(createdAtB) ? -1 : 1,
        );
      case sortOptions.LATEST:
        return filteredBySearch.sort(({ createdAt: createdAtA }, { createdAt: createdAtB }) =>
          moment(createdAtA) > moment(createdAtB) ? -1 : 1,
        );
      default:
        return null;
    }
  }, [list, searchValue, sortBy]);

  return (
    <>
      <div className={classes.notebookListRoot}>
        <NotebookListHeader
          sortBy={sortBy}
          onSort={setSortBy}
          searchValue={searchValue}
          onSearch={setSearchValue}
          onCreate={onCreate}
        />
        <div className="i-notebook-list-container">
          <NewNotebook onClick={toggleNewNotebookDialog} />
          {filteredList
            .filter(({ isDeleted }) => !isDeleted)
            .map((notebookDetails) => (
              <Notebook
                key={notebookDetails.id}
                onRename={onRename}
                onDelete={onDelete}
                {...notebookDetails}
              />
            ))}
        </div>
      </div>
      {showNewNotebookDialog && (
        <InputDialog
          dialogTitle="Create Notebook"
          inputLabel="Notebook Name"
          submitText="CREATE"
          onSubmit={onCreate}
          onClose={toggleNewNotebookDialog}
        />
      )}
    </>
  );
};
