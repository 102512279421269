import React from 'react';

const CallOnIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.435 18.36C5.01 14.025 11.19 11.355 18 11.355C24.81 11.355 30.99 14.025 35.565 18.36C35.835 18.63 36 19.005 36 19.425C36 19.845 35.835 20.22 35.565 20.49L31.845 24.21C31.575 24.48 31.2 24.645 30.78 24.645C30.375 24.645 30 24.48 29.73 24.225C28.545 23.115 27.195 22.185 25.725 21.45C25.23 21.21 24.885 20.7 24.885 20.1V15.45C22.725 14.73 20.4 14.355 18 14.355C15.6 14.355 13.275 14.73 11.1 15.435V20.085C11.1 20.67 10.755 21.195 10.26 21.435C8.79 22.17 7.455 23.115 6.27 24.21C6 24.48 5.625 24.63 5.22 24.63C4.8 24.63 4.425 24.465 4.155 24.195L0.435 20.475C0.165 20.22 0 19.845 0 19.425C0 19.005 0.165 18.63 0.435 18.36ZM8.09994 16.635V19.185C7.12494 19.74 6.17994 20.37 5.29494 21.09L3.68994 19.485C5.05494 18.36 6.53994 17.415 8.09994 16.635ZM27.8848 16.635L27.8998 16.6423V19.2C28.9048 19.77 29.8498 20.4 30.7198 21.105L32.3248 19.5C30.9491 18.3637 29.4692 17.4061 27.8998 16.6423V16.635H27.8848Z"
        fill="white"
      />
    </svg>
  );
};

export default CallOnIcon;
