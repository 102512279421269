import React from 'react';
import { ScreenshareDisplayControls } from './component';
import { useMediaQuery } from '@material-ui/core';

export const ScreenshareDisplayControlsContainer = (props) => {
  const {
    getUserMedia,
    isMuted,
    isScreenShared,
    isViewOnly,
    mute,
    onClickMute,
    onClickUnmute,
    publish,
    stopScreenshare,
    checkIsAllowedToStream,
  } = props;
  const [switchState, setSwitchState] = React.useState({
    checkedA: false,
  });

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const handleSwitchChange = (event) => {
    setSwitchState({ ...switchState, [event.target.name]: event.target.checked });
    if (!event.target.checked) {
      mute();
    }
  };

  return (
    <ScreenshareDisplayControls
      getUserMedia={getUserMedia}
      handleSwitchChange={handleSwitchChange}
      checkIsAllowedToStream={checkIsAllowedToStream}
      isMobile={isMobile}
      isMuted={isMuted}
      isScreenShared={isScreenShared}
      isViewOnly={isViewOnly}
      onClickMute={onClickMute}
      onClickUnmute={onClickUnmute}
      publish={publish}
      stopScreenshare={stopScreenshare}
      switchState={switchState}
    />
  );
};
