import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, makeStyles } from '@material-ui/core';

import { arrowLeftIcon, chatIcon, notebookIcon } from '../../../assets/icons';
import Item from './item';
import { useLayoutDetails } from '../../../context/layout-context';

const items = [
  { title: 'Chats', iconSrc: chatIcon, href: '/controller/learn-ai/chats' },
  { title: 'Notebooks', iconSrc: notebookIcon, href: '/controller/learn-ai/notes' },
];

const useStyles = ({ collapsed }) =>
  makeStyles({
    sidebarRoot: {
      position: 'relative',
      backgroundColor: '#F0F4EB',
      padding: '20px 8px',
      width: collapsed ? 48 : 212,
      flexShrink: 0,
      overflow: 'hidden',
      '& .i-logo': {
        display: 'block',
        margin: collapsed ? '0px auto 24px' : '0px 0px 24px',
        '& img': {
          width: collapsed ? 28 : 124,
          height: 40,
          objectFit: 'cover',
          objectPosition: '0%',
        },
      },
      '& .i-arrow': {
        position: 'absolute',
        top: 20,
        right: collapsed ? 4 : 0,
        transform: collapsed ? 'rotate(180deg)' : 'none',
      },
    },
    nav: {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },
  });

const Sidebar = () => {
  const {
    layoutDetails: { sidebarCollapsed: collapsed },
    setLayoutDetails,
  } = useLayoutDetails();

  const classes = useStyles({ collapsed })();

  const toggleCollapsed = () => setLayoutDetails({ sidebarCollapsed: !collapsed });

  return (
    <div className={classes.sidebarRoot}>
      <div style={{ height: 40 }} />
      <IconButton className="i-arrow" onClick={toggleCollapsed}>
        <img src={arrowLeftIcon} alt={collapsed ? 'expand' : 'collapse'} />
      </IconButton>
      <div className={classes.nav}>
        {items.map((details) => (
          <Item key={details.title} {...details} collapsed={collapsed} />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
