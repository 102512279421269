import { Button, makeStyles, useMediaQuery } from '@material-ui/core';
import React from 'react';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  SortButton: {
    boxShadow: '0px 3.06px rgba(0, 0, 0, 0.04)',
    width: '11rem !important',
    background: '#fff !important',
    color: '#000 !important',
    fontSize: '15px !important',
    height: '55px !important',
    '&:hover': {
      background: ' rgba(236, 103, 47, 0.25) !important',
      color: ' #ed672e !important',
      boxShadow: 'none !important',
    },

    '@media (max-width: 959px)': {
      border: '1px solid var(--100-bright, #EC672F)',
      background: 'transparent !important',
    },
  },

  'SortButton svg': {
    fill: '#ed672e',
  },
}));

const CreateBtn = ({ createActionButton1Label, createActionClickAction, hideIcon }) => {
  const isLarge = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const classes = useStyles();

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        startIcon={hideIcon ? '' : <AddIcon style={{ color: '#ED672E' }} />}
        className={classes.SortButton}
        onClick={createActionClickAction}
        variant="outlined"
      >
        {createActionButton1Label}
      </Button>
    </>
  );
};

export default CreateBtn;
