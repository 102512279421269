import React from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { Button, Dialog, IconButton, TextField, Typography, useMediaQuery } from '@material-ui/core'

const CreateSpaceDialog = ({ boardInput, handleClose, boardName, setBoardName, onEnterKeyPressCreateBoard, createBoardErrorTextMessage, saveParentName, CreateBoard }) => {
  const isMobileView = useMediaQuery('(max-width:600px)');

  return (
    <>
      <Dialog
        open={boardInput}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'sm'}
        PaperProps={{
          style: {
            borderRadius: 16,
          },
        }}
      >
        <div
          style={{
            padding: ' 2% 2%',
            display: 'grid',
            gridTemplateColumns: '90% 10%',
            alignItems: 'center',
            minHeight: '30px',
            borderBottom: '1px solid rgba(0,0,0,0.12)',
          }}
        >
          <div
            style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '28px',
              textAlign: 'center',
              marginLeft: '50px',
              color: 'rgba(0, 0, 0)',
            }}
          >
            {' '}
            Create a new Canvas
          </div>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{ marginRight: 10, cursor: 'pointer', marginTop: '2px' }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div style={{ margin: '29px' }}>
          <Typography
            variant="h6"
            component="div"
            style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '10px',
              lineHeight: '16px',
              color: 'rgba(0, 0, 0, 0.87)',
            }}
          >
            Canvas Name
          </Typography>
          <TextField
            autoFocus
            id="name"
            type="name"
            style={{ width: `${isMobileView ? '100%' : '423px'}` }}
            value={boardName}
            variant="outlined"
            onChange={(event) => setBoardName(event.target.value)}
            onKeyDown={onEnterKeyPressCreateBoard}
            helperText={createBoardErrorTextMessage}
          />
          {/* <div className="TeamName">Team: {saveParentName}</div> */}
        </div>

        <div
          style={{
            borderTop: '1px solid rgba(0,0,0,0.12)',
            flex: '0 0 auto',
            display: 'flex',
            padding: '12px',
            paddingBottom: '21px',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={handleClose}
            style={{
              width: '100px',
              height: '36px',
              borderRadius: '4px',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              color: 'rgba(0, 0, 0, 0.38)',
            }}
          >
            {' '}
            CANCEL
          </Button>

          <Button
            style={{
              marginLeft: '5px',
              width: '100px',
              height: '36px',
              background: '#335AFB',
              boxShadow: '0px 0.3px 0.5px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.2)',
              borderRadius: '4px',
              color: 'white',
            }}
            onClick={CreateBoard}
          >
            SAVE
          </Button>
        </div>
      </Dialog>
    </>
  )
}

export default CreateSpaceDialog;