import React from 'react';
import Slider from '@material-ui/core/Slider';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
class ColorSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 2,
      defaultValue: 2,
      min: 2,
      max: 20,
    };
  }
  componentDidMount() {
    if (this.props.optionType && this.props.optionType === 'highlighter') {
      this.setState({
        defaultValue: 30,
        min: 20,
        max: 60,
      });
    }
  }

  selectPenColor = (color) => {
    this.props.selectedcolor(color);
  };

  selectSize = (e, size) => {
    this.props.selectedBrushSize(size);
  };

  render() {
    return (
      <div className="color-popup">
        <div className="color-grid">
          <div></div>
          <div className="title">Colors</div>
          <div className="colorselect-grid">
            <div
              onClick={() => this.selectPenColor('#000000')}
              className=" color-circle color_1"
            ></div>
            <div
              onClick={() => this.selectPenColor('#3075D2')}
              className=" color-circle color_2"
            ></div>
            <div
              onClick={() => this.selectPenColor('#95D9FB')}
              className=" color-circle color_3"
            ></div>
            <div
              onClick={() => this.selectPenColor('#579673')}
              className=" color-circle color_4"
            ></div>
            <div
              onClick={() => this.selectPenColor('#65C855')}
              className=" color-circle color_5"
            ></div>
            <div
              onClick={() => this.selectPenColor('#F9DC4B')}
              className=" color-circle color_6"
            ></div>
            <div
              onClick={() => this.selectPenColor('#EF8A3B')}
              className=" color-circle color_7"
            ></div>
            <div
              onClick={() => this.selectPenColor('#EB5142')}
              className=" color-circle color_8"
            ></div>
            <div
              onClick={() => this.selectPenColor('#DD3AB9')}
              className=" color-circle color_9"
            ></div>
            <div
              onClick={() => this.selectPenColor('#C7CCD2')}
              className=" color-circle color_10"
            ></div>
          </div>
          <div className="title">Size</div>
          <div className="slider-option">
            <MuiThemeProvider theme={sliderTheme}>
              <Slider
                defaultValue={this.state.defaultValue}
                onChange={(e, val) => this.selectSize(e, val)}
                value={this.props.brushsize}
                step={this.state.step}
                min={this.state.min}
                max={this.state.max}
                valueLabelDisplay="auto"
              />
            </MuiThemeProvider>
          </div>
        </div>
      </div>
    );
  }
}

const sliderTheme = createTheme({
  palette: {
    primary: {
      main: '#34495E',
    },
  },
});

export default ColorSelect;
