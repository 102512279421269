// This is reusable Dialog Component, please make sure to pass certain props
//  open ---- (Boolean) it tells weather to open this dialog or not
// onClose ---- (function) to execute when you want to close the dialog box
// dialogActions ----- (HTML Text) it will render the input in the Dialog Actions Eg. The success or cancel button
// maxWidth -----(string; false, xs, sm, md, lg, xl)
// fullWidth ----- (boolean, by default its 'true)

import React, { Component } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@material-ui/core";

import CancelIcon from "@material-ui/icons/Cancel";

class DialogComponent extends Component {
  render() {
    return (
      <>
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          maxWidth={this.props.maxWidth ? this.props.maxWidth : "sm"}
          fullWidth={this.props.fullWidth ? this.props.fullWidth : true}
          scroll="paper"
          {...this.props}
        >
          <DialogTitle
            id="scroll-dialog-title"
            style={{
              background: "rgba(0, 116, 228, 0.25)",
              display: "flex",
              minHeight: "60px",
              boxSizing: "border-box",
              flexDirection: "row-reverse",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: "14px 24px",
            }}
          >
            {this.props.hideCloseButton ? (
              <></>
            ) : (
              <IconButton onClick={this.props.onClose} size="small">
                <CancelIcon style={{ color: "#fff" }} />
              </IconButton>
            )}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="scroll-dialog-description">
              {this.props.children}
            </DialogContentText>
          </DialogContent>
          {this.props.dialogActions ? (
            <DialogActions>{this.props.dialogActions}</DialogActions>
          ) : (
            <></>
          )}
        </Dialog>
      </>
    );
  }
}

export default DialogComponent;
