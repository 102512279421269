import React from 'react';
import { DisplayVideoContainer } from '../display-video/container';

export const LocalVideoContainer = (props) => {
  const { localSubscribedData } = props;

  const onlyVideoFilter = ({ kind }) => {
    return kind === 'video';
  };

  const onlyVideoSubscribedData = localSubscribedData.filter(onlyVideoFilter);
  const videoCount = onlyVideoSubscribedData.length;
  const videoContainer = onlyVideoSubscribedData.map(({ type, kind, ref, stream }) => {
    const id = `${type}-${kind}`;
    const displayVideoContainer = (
      <DisplayVideoContainer
        key={id}
        id={id}
        ref={ref}
        videoCount={videoCount}
        showRemoteVideo={true}
        isViewOnly={false}
        stream={stream}
      />
    );

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#000000',
        }}
        key={id}
      >
        {displayVideoContainer}
      </div>
    );
  });

  return <>{videoContainer}</>;
};
