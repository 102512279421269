import ACTION_TYPES from '../actions/type.action';

const initialState = {
  allAddedElements:[],
	undoElements: [],
	redoElements: [],
  redoElement:{},
  undoElement:{}
};

const reducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
    case ACTION_TYPES.ADD_ALL_ELEMENTS:
			let allElementsArray = state.allAddedElements;
      allElementsArray.push(payload);
			return {
				...state,
				allAddedElements: allElementsArray
			};
		case ACTION_TYPES.ADD_UNDO_ELEMENT:
			let undoElementsArray = state.undoElements;
      undoElementsArray.push(payload);
			return {
				...state,
				undoElements: undoElementsArray
			};
		case ACTION_TYPES.ADD_REDO_ELEMENT:
			let redoElementsArray = state.redoElements;;
      redoElementsArray.push(payload);
			return {
				...state,
				redoElements: redoElementsArray
			};
		case ACTION_TYPES.PERFORM_REDO:
			let redoTempElement = state.redoElements.pop();
      if(redoTempElement.type != 'remove'){
        var removeElement ={
					type: "remove",
					classId:redoTempElement.classId,
					elementId:redoTempElement.elementId,
					flowId:redoTempElement.flowId	
				}
      }
      state.undoElements.push(removeElement);
			return {
				...state,
				redoElement: redoTempElement
			};
		case ACTION_TYPES.PERFORM_UNDO:
			let undoTempElement = state.undoElements.pop();
      if(undoTempElement.type == 'remove'){
        var newRedoElement = state.allAddedElements.filter((element)=>
         element.elementId == undoTempElement.elementId
         );
      }
      console.log('newRedoElement',newRedoElement);
      let redoAddElements = state.redoElements;
      redoAddElements.push(newRedoElement[0]);
			return {
				...state,
				undoElement: undoTempElement,
        redoElements:redoAddElements
			};

		default:
			return { ...state };
	}
};

export default reducer;
