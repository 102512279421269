import React from 'react';
import { Button, Typography } from '@material-ui/core';
import useStyles from './FeatureLocked.style';
import LockDanger from '../../../../assets/icons/LockDanger';

const FeatureLockedComponent = (props) => {
  const classes = useStyles();
  const { text, subText, upgradeText, upgradeButtonOnClick, message } = props;

  const upgradeButton = (
    <Button
      variant="contained"
      color="primary"
      style={{ padding: '5px 36px' }}
      onClick={upgradeButtonOnClick}
    >
      {upgradeText}
    </Button>
  );

  const displayMessage = (
    <div className={classes.messageContainer}>
      <a href="mailto:support@birdai.com" className={classes.supportBtn}>
        {message}
      </a>
    </div>
  );

  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <LockDanger />
      </div>
      <div className={classes.warningText}>{text}</div>
      <p className={classes.subText}>{subText}</p>
      {displayMessage}
    </div>
  );
};

export default FeatureLockedComponent;
