import { useDispatch } from 'react-redux';
import {
  resetMicrosoftCommunicationIdentity,
  resetMicrosoftTeamsMeeting,
  setMicrosoftCommunicationIdentity,
  setMicrosoftIsAuthenticated,
  setMicrosoftMe,
  setMicrosoftTeamsMeeeting,
  setOutlookEvents,
  setOutlookEventsDaywise,
} from '../actions/microsoft.action';

export const useMicrosoftDispatcher = () => {
  const dispatch = useDispatch();

  /**
   * @param {boolean} payload
   */
  const dispatchSetMicrosoftIsAuthenticated = (payload) => {
    dispatch(setMicrosoftIsAuthenticated(payload));
  };

  /**
   * @param {boolean} payload
   */
  const dispatchSetMicrosoftMe = (payload) => {
    dispatch(setMicrosoftMe(payload));
  };

  /**
   * @param {{ url: string }} payload
   */
  const dispatchSetMicrosoftTeamsMeeting = (payload) => {
    dispatch(setMicrosoftTeamsMeeeting(payload));
  };

  /**
   */
  const dispatchResetMicrosoftTeamsMeeting = () => {
    dispatch(resetMicrosoftTeamsMeeting());
  };

  /**
   * @param {{ communicationUserId: string, accessToken: string, }} payload
   */
  const dispatchSetMicrosoftCommunicationIdentity = (payload) => {
    dispatch(setMicrosoftCommunicationIdentity(payload));
  };

  /**
   */
  const dispatchResetMicrosoftCommunicationIdentity = () => {
    dispatch(resetMicrosoftCommunicationIdentity());
  };

  /**
   *
   * @param {{ outlookEvents: [] }} payload
   */
  const dispatchSetOutlookEvents = (payload) => {
    dispatch(setOutlookEvents(payload));
  };

  /**
   *
   * @param {{
   *  yesterday: [],
   *  today: [],
   *  tomorrow: [],
   * }} payload
   */
  const dispatchSetOutlookEventsDayWise = (payload) => {
    dispatch(setOutlookEventsDaywise(payload));
  };

  return {
    dispatchResetMicrosoftCommunicationIdentity,
    dispatchResetMicrosoftTeamsMeeting,
    dispatchSetMicrosoftCommunicationIdentity,
    dispatchSetMicrosoftIsAuthenticated,
    dispatchSetMicrosoftMe,
    dispatchSetMicrosoftTeamsMeeting,
    dispatchSetOutlookEvents,
    dispatchSetOutlookEventsDayWise,
  };
};
