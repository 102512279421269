import React from 'react';
import { ScheduleMeetingDialog } from './component';

export const ScheduleMeetingDialogContainer = (props) => {
  const { isOpenDialog, closeDialog, onSubmit, formik, setStartTime, setEndTime } = props;
  return (
    <ScheduleMeetingDialog
      closeDialog={closeDialog}
      formik={formik}
      isOpenDialog={isOpenDialog}
      onSubmit={onSubmit}
      setEndTime={setEndTime}
      setStartTime={setStartTime}
    />
  );
};
