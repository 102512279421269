import React from 'react';

const InstantMeetingIcon = (props) => {
  let fill = props.fill ? props.fill : '#000';
  let fillOpacity = props.fill ? 1 : 0.38;

  return (
    // <svg width="66" height="67" viewBox="0 0 66 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path
    //     d="M55.8578 49.34C55.1157 49.34 54.3991 49.1092 53.8358 48.6897L46.9628 43.8009C45.5906 42.8248 44.7757 41.2451 44.7757 39.5612V30.7395C44.7757 29.0556 45.5906 27.4759 46.9628 26.4998L53.8358 21.611C54.3991 21.1915 55.1157 20.9608 55.8578 20.9608C57.5733 20.9608 58.9653 22.17 58.9653 23.6631V46.6389C58.9653 48.1307 57.5747 49.34 55.8578 49.34Z"
    //     fill={fill}
    //   />
    //   <path
    //     d="M42.4105 41.5357C42.4105 45.8459 38.9164 49.34 34.6062 49.34H14.7408C10.4306 49.34 6.93652 45.8459 6.93652 41.5357V28.765C6.93652 24.4548 10.4306 20.9608 14.7408 20.9608H34.6062C38.9164 20.9608 42.4105 24.4548 42.4105 28.765V41.5357Z"
    //     fill={fill}
    //   />
    // </svg>
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.16667 18.2084C3.16667 13.0032 3.16667 10.4006 4.60427 8.64888C4.86745 8.3282 5.16149 8.03415 5.48218 7.77097C7.2339 6.33337 9.83649 6.33337 15.0417 6.33337C20.2468 6.33337 22.8494 6.33337 24.6012 7.77097C24.9218 8.03415 25.2159 8.3282 25.4791 8.64888C26.9167 10.4006 26.9167 13.0032 26.9167 18.2084V19.7917C26.9167 24.9969 26.9167 27.5995 25.4791 29.3512C25.2159 29.6719 24.9218 29.9659 24.6012 30.2291C22.8494 31.6667 20.2468 31.6667 15.0417 31.6667C9.83649 31.6667 7.2339 31.6667 5.48218 30.2291C5.16149 29.9659 4.86745 29.6719 4.60427 29.3512C3.16667 27.5995 3.16667 24.9969 3.16667 19.7917V18.2084Z"
        stroke="white"
      />
      <path
        d="M26.9167 15.0416L27.9591 14.5204C31.0401 12.9799 32.5806 12.2096 33.7069 12.9058C34.8333 13.6019 34.8333 15.3242 34.8333 18.7689V19.2309C34.8333 22.6756 34.8333 24.3979 33.7069 25.094C32.5806 25.7902 31.0401 25.0199 27.9591 23.4794L26.9167 22.9582V15.0416Z"
        stroke="white"
      />
    </svg>
  );
};

export default InstantMeetingIcon;
