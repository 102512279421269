import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = ({ iconSrc, collapsed }) =>
  makeStyles({
    itemRoot: {
      display: 'flex',
      gap: 8,
      padding: '8px 12px',
      borderRadius: 4,
      color: '#767676',
      justifyContent: collapsed ? 'center' : 'start',
      textDecoration: 'none',
      '& .i-icon': {
        height: 24,
        minWidth: 24,
        '-webkit-mask': `url(${iconSrc}) no-repeat center`,
        mask: `url(${iconSrc}) no-repeat center`,
        backgroundColor: '#767676',
      },
      '& .i-title': {
        display: collapsed ? 'none' : 'block',
      },
    },
    activeLink: {
      backgroundColor: '#EAEEFF',
      '& .i-icon': {
        backgroundColor: '#000',
      },
      '& .i-title': {
        color: '#000',
      },
    },
  });

const Item = ({ href, iconSrc, title, collapsed }) => {
  const classes = useStyles({ iconSrc, collapsed })();
  return (
    <Box
      component={NavLink}
      to={href}
      className={classes.itemRoot}
      activeClassName={classes.activeLink}
    >
      <span className="i-icon" />
      <Typography className="i-title">{title}</Typography>
    </Box>
  );
};

export default Item;
