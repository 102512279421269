import React from 'react';
import { ContentContainer, DayEvent, DayEventHeading, DayEvents } from './component.style';
import { UnauthenticatedAccountContainer } from './unauthenticated-account/container';
import { useMicrosoftAuth } from '../../../../../../Utils/microsoft/useMicrosoftAuth';
import { usePersonalCalendar } from './use-personal-calendar';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import { Meetings } from '../../../meetings/container';
import { StyledSpinner } from '../../../../../common/spinner/styled-spinner';
import { schedulesService } from '../../../../../../services/schedules.service';
import { useNotificationDispatcher } from '../../../../../../store/dispatcher/useNotificationDispatcher';
import microsoftGraphService from '../../../../../../services/microsoft-graph.service';

export const PersonalCalendarListing = (props) => {
  const {
    isLoading,
    microsoftAuthenticatedHandler,
    noMeetingsLabel,
    closeSidebar,
    outlookEventsDayWise,
  } = props;
  const { isMicrosoftAuthenticated } = useMicrosoftAuth();
  const { importEventToSpace } = usePersonalCalendar();
  const { dispatchSetSnackbar } = useNotificationDispatcher();

  const dates = {
    yesterday: moment().subtract(1, 'days').format('Do MMMM'),
    today: moment().format('Do MMMM'),
    tomorrow: moment().add(1, 'days').format('Do MMMM'),
  };

  const noMeetingsContent = <Typography>{noMeetingsLabel}</Typography>;

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  /**
   * fetch an outlook calendar event by iCalUId
   * pass the event to importEventToSpace
   */
  const importToSpace = async (event) => {
    const { iCalUId } = event;
    const outlookEvent = await microsoftGraphService.getEventsByICalUIds([iCalUId]);
    if (!outlookEvent) {
      const message = 'no outlook event found';
      console.log(message);
      dispatchSetSnackbar({ message });
      return;
    }
    importEventToSpace(outlookEvent);
  };

  const displayDayEvents = ([day, events]) => {
    const displayEvents = (
      <Meetings
        dateTimeFormat="onlyTime"
        schedules={events}
        disableOptions={true}
        importToSpace={importToSpace}
        // getSchedulesHandler={getSchedulesHandler}
        // enableEditSchedule={enableEditSchedule}
        // checkIsAllScheduleFilter={checkIsAllScheduleFilter}
        closeSidebar={closeSidebar}
      />
    );

    return (
      <DayEvent key={day}>
        <div id={`personal-calendar-listing-${day}`}>
          <DayEventHeading>
            <Typography variant="h4">{dates[day]}</Typography>
            <Typography variant="body2">{capitalize(day)}</Typography>
          </DayEventHeading>
          <div>{events.length ? displayEvents : noMeetingsContent}</div>
        </div>
      </DayEvent>
    );
  };

  const displayAllDayEvents = outlookEventsDayWise ? (
    <DayEvents>
      {displayDayEvents(['yesterday', outlookEventsDayWise['yesterday']])}
      {displayDayEvents(['today', outlookEventsDayWise['today']])}
      {displayDayEvents(['tomorrow', outlookEventsDayWise['tomorrow']])}
    </DayEvents>
  ) : (
    <></>
  );

  const displayAllDayEventsContent = isLoading ? <StyledSpinner center /> : displayAllDayEvents;

  const unauthenticatedContent = (
    <UnauthenticatedAccountContainer
      microsoftAuthenticatedHandler={microsoftAuthenticatedHandler}
    />
  );
  const displayContent = isMicrosoftAuthenticated
    ? displayAllDayEventsContent
    : unauthenticatedContent;

  return <ContentContainer>{displayContent}</ContentContainer>;
};
