import { SMART_NOTES_ACTION_TYPES } from './type.action';

export const setNotes = (noteList) => ({
  type: SMART_NOTES_ACTION_TYPES.SET_NOTES,
  payload: noteList,
});
export const addNote = (noteDetails) => ({
  type: SMART_NOTES_ACTION_TYPES.ADD_NOTE,
  payload: noteDetails,
});
export const deleteNote = (id) => ({ type: SMART_NOTES_ACTION_TYPES.DELETE_NOTE, payload: id });
export const updateNote = (noteDetails) => ({
  type: SMART_NOTES_ACTION_TYPES.UPDATE_NOTE,
  payload: noteDetails,
});
export const addSourceToNote = ({ noteId, ...sourceDetails }) => ({
  type: SMART_NOTES_ACTION_TYPES.ADD_SOURCE_TO_NOTE,
  payload: { noteId, ...sourceDetails },
});
export const updateSource = ({ noteId, id, ...sourceDetails }) => ({
  type: SMART_NOTES_ACTION_TYPES.UPDATE_SOURCE,
  payload: { noteId, id, ...sourceDetails },
});
export const selectSource = ({ noteId, id }) => ({
  type: SMART_NOTES_ACTION_TYPES.SELECT_SOURCE,
  payload: { noteId, id },
});
export const deselectSource = ({ noteId, id }) => ({
  type: SMART_NOTES_ACTION_TYPES.DESELECT_SOURCE,
  payload: { noteId, id },
});
export const toggleAllNoteSources = ({ noteId, selected }) => ({
  type: SMART_NOTES_ACTION_TYPES.TOGGLE_ALL_NOTE_SOURCES,
  payload: { noteId, selected },
});
