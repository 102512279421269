import { useDispatch } from 'react-redux';
import {
  addOutlookImportedEvent,
  removeOutlookImportedEvent,
  resetOutlookImportedEvents,
  setOutlookImportedEvents,
} from '../actions/schedules.action';

export const useSchedulesDispatcher = () => {
  const dispatch = useDispatch();

  /**
   * @param {string[]} payload
   */
  const dispatchSetOutlookImportedEvents = (payload) => {
    dispatch(setOutlookImportedEvents(payload));
  };

  /**
   *
   */
  const dispatchResetOutlookImportedEvents = () => {
    dispatch(resetOutlookImportedEvents());
  };

  /**
   * @param {{id: string}} payload
   */
  const dispatchAddOutlookImportedEvent = (payload) => {
    dispatch(addOutlookImportedEvent(payload));
  };

  /**
   * @param {{id: string}} payload
   */
  const dispatchRemoveOutlookImportedEvent = (payload) => {
    dispatch(removeOutlookImportedEvent(payload));
  };

  return {
    dispatchSetOutlookImportedEvents,
    dispatchResetOutlookImportedEvents,
    dispatchAddOutlookImportedEvent,
    dispatchRemoveOutlookImportedEvent,
  };
};
