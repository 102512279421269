import React, { Component, useCallback, useEffect, useRef } from 'react';
import Konva from 'konva';
import { useInView } from 'react-intersection-observer';
import { Popover, Paper, Hidden, Dialog, DialogActions } from '@material-ui/core';
import { ArrowDropUp as ArrowDropUpIcon, AddCircle as AddCircleIcon } from '@material-ui/icons';
import previousIcon from '../assets/img/previous_icon.svg';
import noPages from '../assets/img/no_pages.svg';

import nextIcon from '../assets/img/next_icon.svg';
import addPageIcon from '../assets/img/pageadd_icon.svg';
import PageOptionIcon from '../assets/img/pageoption.svg';
import closeLogo from '../assets/img/closeIcon.svg';
import { get } from '../utils/api';
import { getBoardInfo } from '../utils/authentication-access';
import Config from '../config';
import './iwb.scss';

var pages = [];
var stage = [];
var width = 1280;
var height = 720;
var scaleAttrX = 1;
var scaleAttrY = 1;

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      showAllPages: false,
      data: [],
      selectedPage: 0,
      showDeleteDialog: false,
    };
    this.scrollContainer = null;
  }

  scrollOnWheel = (e) => {
    if (e.deltaX || !e.deltaY) return;
    e.preventDefault();
    const container = this.scrollContainer;
    container.scrollBy(e.deltaY, 0);
  };

  scrollHandler = () => {
    if (this.scrollTimer) clearTimeout(this.scrollTimer);
    this.isScrolling = true;
    this.scrollTimer = setTimeout(() => (this.isScrolling = false), 200);
  };

  componentDidMount() {
    this.getFlowItem();
  }

  componentWillUnmount() {
    this.scrollContainer && this.scrollContainer.removeEventListener('wheel', this.scrollOnWheel);
  }

  getFlowItem = () => {
    var url = Config.flowItemURL + getBoardInfo().flowId;
    get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
          const pageNumber = data.pageCollection.indexOf(data.selectedPageId) + 1;
          this.props.setPageNumber(pageNumber);
          this.setState({ data: data.pageCollection, pageNumber });
        }
      })
      .catch((err) => {
        this.setState({ data: [0] });
        this.canvasErrorHandle();
      });
  };

  canvasErrorHandle = () => {
    pages = [0];
    for (var i = 0; i < pages.length; i++) {
      stage[i] = new Konva.Layer();
    }

    var text = new Konva.Text({
      x: 100,
      y: 150,
      text: 'Unable to Connect to WhiteBoard',
      fontSize: 75,
    });
    var text1 = new Konva.Text({
      x: 120,
      y: 250,
      text: 'Please Refresh the page',
      fontSize: 75,
    });

    stage[0].add(text);
    stage[0].add(text1);
    stage[0].draw();
  };

  notifyCurrentPage = () => {
    this.props.changePage(this.state.data[this.state.pageNumber - 1]);
  };

  increment = () => {
    const pageCount = this.state.data.length;
    if (this.state.pageNumber < pageCount) {
      this.props.changePage(this.state.data[this.state.pageNumber]);
      this.setState({ pageNumber: this.state.pageNumber + 1 });
      this.props.setPageNumber(this.state.pageNumber + 1);
    }
  };

  decrement = () => {
    if (this.state.pageNumber > 1) {
      this.props.changePage(this.state.data[this.state.pageNumber - 2]);
      this.setState({ pageNumber: this.state.pageNumber - 1 });
      this.props.setPageNumber(this.state.pageNumber - 1);
    }
  };

  addPage = async (index, addRemote = true) => {
    if (
      this.props.cloudLicense.pagesPerSpace.isUnlimited ||
      index < this.props.cloudLicense.pagesPerSpace.totalLimit
    ) {
      const pageCount = this.state.data.length;
      if (!index) {
        index = pageCount;
      }

      const addPageAllowed = this.props.addPageAllowed;
      if (typeof addPageAllowed === 'undefined' || addPageAllowed === false) {
        return;
      }
      if (addRemote) {
        await this.props.addPage(index);
      }
      stage.splice(index, 0, new Konva.Layer());
      this.setState({ pageNumber: index + 1 });
      this.props.setPageNumber(index + 1);
      this.setState({ showAllPages: false });
    } else {
      this.props.showLicenseExhaustedDialog();
    }
  };

  addPageFromOthers = (index, pageCollection) => {
    stage.splice(index, 0, new Konva.Layer());
    this.setState({ data: pageCollection, pageNumber: index + 1 });
    this.props.setPageNumber(index + 1);
  };

  addPageDisablePresentationMode = (newIndex, pageCollection, CurrentPageIndex) => {
    stage.splice(newIndex, 0, new Konva.Layer());
    this.setState({ pageNumber: CurrentPageIndex + 1 });
    this.props.setPageNumber(CurrentPageIndex + 1);
    this.setState({ data: pageCollection });
  };

  updatePageIds = (index, newPageId) => {
    let newPageCollection = this.state.data;
    newPageCollection.splice(index, 0, newPageId);
    this.setState({ data: newPageCollection });
  };

  updatePageCount = async (index, currentPageId) => {
    if (index >= 0) await this.setStateSynchronous({ selectedPage: index });
    let page = this.state.selectedPage;
    if (this.state.data.length !== 1) {
      let temp_data = this.state.data;
      temp_data.splice(page, 1);
      this.setStateSynchronous({ data: temp_data });
      stage.splice(page, 1);
      let currentPage = temp_data.indexOf(currentPageId);
      if (currentPage === -1) return;
      this.setState({ pageNumber: currentPage + 1 });
      this.props.setPageNumber(currentPage + 1);
    }
  };

  openShowPages = (event) => {
    if (this.props.presentationMode) {
      return;
    }
    this.setState({
      showAllPages: !this.state.showAllPages,
      anchorEl: event.currentTarget,
    });
  };

  closePopover = () => {
    this.setState({ showAllPages: false });
  };

  pageSelect = async (pageIndex, existing = false, pageCollection) => {
    if (this.isScrolling) return;
    if (pageCollection && pageCollection.length) {
      this.setState(
        { data: pageCollection },
        async () => await this.props.changePage(this.state.data[pageIndex], existing, true),
      );
    } else {
      await this.props.changePage(this.state.data[pageIndex], existing);
    }
    this.setState({ pageNumber: pageIndex + 1 });
    this.props.setPageNumber(pageIndex + 1);
    this.setState({ showAllPages: false });
  };

  deleteDialogClose = () => {
    this.setState({ showDeleteDialog: false });
  };

  deletePage = (page) => {
    this.setState({
      showDeleteDialog: true,
      selectedPage: page,
    });
  };

  setStateSynchronous = (stateUpdate) => {
    return new Promise((resolve) => {
      this.setState(stateUpdate, () => resolve());
    });
  };

  confirmDelete = async (event, index) => {
    if (index >= 0) await this.setStateSynchronous({ selectedPage: index });
    let pageIndex = this.state.selectedPage;
    await this.props.deletePage(this.state.data[pageIndex], index >= 0 ? true : false);
    if (this.state.data.length === 1) {
      this.props.changePage(this.state.data[0]);
    } else if (this.state.data.length === pageIndex + 1) {
      this.props.changePage(this.state.data[pageIndex - 1]);
      this.setState({ pageNumber: pageIndex });
      this.props.setPageNumber(pageIndex);
    } else {
      this.props.changePage(this.state.data[pageIndex + 1]);
      this.setState({ pageNumber: pageIndex + 1 });
      this.props.setPageNumber(pageIndex + 1);
    }

    if (this.state.data.length !== 1) {
      let temp_data = this.state.data;
      temp_data.splice(pageIndex, 1);
      await this.setStateSynchronous({ data: temp_data });
      stage.splice(pageIndex, 1);
    }

    this.setState({ showDeleteDialog: false });
  };

  getCurrentPage = () => this.state.pageNumber;

  getPageId = (pageNumber) => this.state.data[pageNumber - 1];

  render() {
    const { isDocumentDialogOpen } = this.props;
    const PreviousIconSvg = (props) => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="12"
          cy="12"
          r="10.25"
          transform="rotate(-180 12 12)"
          fill="#DDDDDD"
          stroke="black"
          stroke-width="1.5"
        />
      </svg>
    );

    const pageCount = this.state.data.length;
    const isLastPage = this.state.pageNumber === pageCount;

    console.log(
      { pageCount, pageNumber: this.state.pageNumber, isLastPage },
      this.state,
      typeof this.state.pageNumber,
      typeof pageCount,
    );

    return (
      <div
        className={this.props.role === 'instructor' ? 'paginationWrap' : 'paginationWrap-Learn'}
        style={{
          zIndex: isDocumentDialogOpen ? 10000 : 1000,
          borderRadius: '4px',
          boxShadow: 'none',
          height: '44px',
        }}
      >
        <Paper
          className="pagination-content"
          style={{
            backgroundColor: this.props.presentationMode ? '#d4d4d4' : '#ffffff',
            boxShadow: 'none',
            border: '1px solid #DADADA',
          }}
          elevation={3}
        >
          {/* <Hidden xsDown> */}
          {this.props.presentationMode ? (
            <div className="previous-slide text-left mr-1">
              {/* <PreviousIconSvg /> */}
              <img
                className="askeins-tools-icon tools-sm-icon xyz"
                alt="Previous"
                src={noPages}
                style={{ width: '20px' }}
              />
            </div>
          ) : (
            <div className="previous-slide text-left mr-1" onClick={this.decrement}>
              {this.state.pageNumber === 1 ? (
                <img
                  className="askeins-tools-icon tools-sm-icon xyz"
                  alt="Previous"
                  src={noPages}
                  style={{ width: '20px' }}
                />
              ) : (
                <img
                  className="askeins-tools-icon tools-sm-icon"
                  alt="Previous"
                  src={previousIcon}
                  style={{ cursor: 'pointer' }}
                />
              )}
            </div>
          )}
          {/* </Hidden> */}
          <div className="pages-grid" onClick={this.openShowPages} style={{ cursor: 'pointer' }}>
            {this.props.presentationMode ? <div /> : <ArrowDropUpIcon style={{ width: 20 }} />}
            <div className="slide-number text-center">
              <p className="pagenum">{this.state.pageNumber + ' / ' + pageCount}</p>
            </div>
          </div>
          {/* <Hidden xsDown> */}
          {this.props.presentationMode ? (
            <div className="next-slide text-left mr-1">
              <PreviousIconSvg />
            </div>
          ) : (
            <div
              className="next-slide text-right ml-1"
              onClick={isLastPage ? async () => await this.addPage(pageCount) : this.increment}
            >
              {isLastPage ? (
                <img
                  className="askeins-tools-icon tools-sm-icon"
                  alt="Next"
                  src={addPageIcon}
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <img
                  className="askeins-tools-icon tools-sm-icon"
                  alt="add"
                  src={nextIcon}
                  style={{ cursor: 'pointer' }}
                />
              )}
            </div>
          )}
          {/* </Hidden> */}
        </Paper>
        <Popover
          open={this.state.showAllPages}
          className="popoveroverlay"
          onClose={this.closePopover}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <section
            className="allpages"
            ref={(element) => {
              if (!element) return;
              this.scrollContainer = element;
              this.scrollContainer.addEventListener('wheel', this.scrollOnWheel);
            }}
            onScroll={this.scrollHandler}
          >
            <div style={{ display: 'flex' }}>
              {this.state.data.map((page, index) => (
                <LazyPage
                  key={page}
                  isSelected={index + 1 === this.state.pageNumber}
                  pageCount={pageCount}
                  data={this.state.data}
                  page={page}
                  stages={this.props.canvasBoard.pageLayouts}
                  pageSelect={this.pageSelect}
                  deletePage={this.deletePage}
                  role={this.props.role}
                  index={index}
                  addPage={this.addPage}
                />
              ))}
            </div>
          </section>
        </Popover>

        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          className="overlay"
          open={this.state.showDeleteDialog}
          onClose={this.deleteDialogClose}
        >
          <div className="dialog-modal-header bg-primary">
            <div></div>
            <div className="text-center">
              <img
                className="close-icon hover"
                onClick={this.deleteDialogClose}
                src={closeLogo}
                alt="Close"
              />
            </div>
          </div>
          <div className="simplemodal-card">
            <div className="modal-title">
              <b>Delete Page</b>
            </div>
            <p>
              Are you sure you want to Delete this <b>Page</b>?
            </p>
            <p>You can't undo this action once cleared.</p>
            <DialogActions>
              <button className="btn-button-blank" onClick={this.deleteDialogClose}>
                Cancel
              </button>
              <button className="btn-button" onClick={this.confirmDelete}>
                Delete
              </button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default Pagination;

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPageOptions: false,
      pageOptionsAnchorElement: null,
      data: this.props.data,
      stages: this.props.stages,
      page: this.props.page,
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.renderKonva();
  }

  renderKonva = () => {
    let currStagePage = new Konva.Stage({
      container: this.myRef.current,
      width: width,
      height: height,
    });
    currStagePage.on('tap', this.pageSelect);
    const pageLayout = this.props.stages[this.props.data.indexOf(this.props.page)].clone();
    currStagePage.add(pageLayout);

    var containerWidth = 200 - 30;
    var containerHeight = 120 - 30;

    scaleAttrX = containerWidth / width;
    scaleAttrY = containerHeight / height;

    if (currStagePage) {
      currStagePage.width(width * scaleAttrX);
      currStagePage.height(height * scaleAttrY);
      currStagePage.scale({ x: scaleAttrX, y: scaleAttrY });
      currStagePage.draw();
    }
  };

  pageSelect = async () => {
    await this.props.pageSelect(this.props.data.indexOf(this.props.page));
  };

  pageOption = (event) => {
    this.setState({ showPageOptions: !this.state.showPageOptions });
    this.setState({ pageOptionsAnchorElement: event.currentTarget });
  };

  deletePage = async () => {
    await this.props.deletePage(this.props.data.indexOf(this.props.page));
    this.setState({ showPageOptions: false });
  };

  render() {
    return (
      <div
        style={{
          display: 'flex',
          justifyItems: 'center',
          alignItems: 'center',
          gridRow: 1,
        }}
      >
        <div className="page-layout">
          <div className="page-options">
            <img alt="Previous" src={PageOptionIcon} onClick={this.pageOption} />
          </div>
          <div>
            {this.props.data ? this.props.data.indexOf(this.props.page) + 1 : 0}/
            {this.props.pageCount}
          </div>
          <div
            className="convasKonva page"
            id="paginationcontainer"
            ref={this.myRef}
            onClick={this.pageSelect}
          ></div>
        </div>
        <Popover
          onClose={() => this.setState({ showPageOptions: false })}
          open={this.state.showPageOptions}
          anchorEl={this.state.pageOptionsAnchorElement}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <div className="delete-option" onClick={this.deletePage}>
            <div className="delete-menuitem">Delete</div>
          </div>
        </Popover>
        {this.props.role === 'instructor' && (
          <AddCircleIcon
            onClick={async () => await this.props.addPage(this.props.index + 1)}
            style={{ fontSize: 30, color: '#335AFB' }}
          />
        )}
      </div>
    );
  }
}

const Loading = () => (
  <div
    style={{
      width: 240,
      height: 150,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    Loading...
  </div>
);

const LazyPage = ({ isSelected, ...otherProps }) => {
  const mounted = useRef(false);
  const { ref, inView } = useInView();

  const setRef = useCallback(
    (target) => {
      if (mounted.current) return;
      if (isSelected) target.scrollIntoView({ inline: 'start' });
      ref(target);
    },
    [ref, isSelected],
  );

  useEffect(() => {
    if (mounted.current) return;
    if (inView) mounted.current = true;
  }, [inView]);

  return (
    <div ref={setRef}>
      {inView ? <Page {...otherProps} /> : mounted.current ? <Page {...otherProps} /> : <Loading />}
    </div>
  );
};
