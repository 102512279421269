import { Button, Link, Typography } from '@material-ui/core';
import React from 'react';
import { FEATURE_FLAGS } from '../../../../../constants/feature-flags.constants';
import { StyledDialogContainer } from '../../../../common/dialog/styled-dialog/container';
import { useJoinAsHostDialogStyles } from './component.style';

export const JoinAsHostDialog = (props) => {
  const {
    areYouHostLabel,
    closeDialog,
    isDevicePaired,
    isOpenDialog,
    joinAsHost,
    joinAsHostOnPairedDevice,
    joinAsParticipant,
    joinAsParticipantOnPairedDevice,
    setupAccountLabel,
    setupAccountLabelAndStartRemote,
    title,
  } = props;
  const classes = useJoinAsHostDialogStyles();

  const joinAsParticipantButton = (
    <Button variant="contained" color="primary" onClick={joinAsParticipant}>
      Join as participant
    </Button>
  );

  /**
   * Note: if Zoom SDK is in production, we might need to rename this to `Join as participant on Remote`
   */
  const joinsAsParticipantOnPairedDeviceButton = (
    <Button variant="contained" color="primary" onClick={joinAsParticipantOnPairedDevice}>
      Join on Paired Device
    </Button>
  );

  const footerContent = (
    <div className={classes.footerContent}>
      {joinAsParticipantButton}
      {isDevicePaired && joinsAsParticipantOnPairedDeviceButton}
    </div>
  );

  const displayLabel = <Typography variant="h3">{areYouHostLabel}</Typography>;

  const displayJoinAsHostOnWeb = (
    <>
      {displayLabel}
      <Link onClick={joinAsHost} style={{ cursor: 'pointer' }}>
        <Typography variant="h3" color="primary">
          {setupAccountLabel}
        </Typography>
      </Link>
    </>
  );

  const displayZoomCallFeatureDisabledContent = <Typography>Join a Zoom Call</Typography>;

  const displayContent = FEATURE_FLAGS.zoomCall.isEnabled
    ? displayJoinAsHostOnWeb
    : displayZoomCallFeatureDisabledContent;

  /**
   * TODO: show this when Zoom SDK is in production
   */
  const displayJoinAsHostOnPairedDevice = (
    <>
      <Link onClick={joinAsHostOnPairedDevice} style={{ cursor: 'pointer' }}>
        <Typography variant="h3" color="primary">
          {setupAccountLabelAndStartRemote}
        </Typography>
      </Link>
    </>
  );

  return (
    <StyledDialogContainer
      open={isOpenDialog}
      onClose={closeDialog}
      title={title}
      footerContent={footerContent}
    >
      <div className={classes.setupAccountBlock}>
        {displayContent}
        {/* {isDevicePaired && displayJoinAsHostOnPairedDevice} */}
      </div>
    </StyledDialogContainer>
  );
};
