import React from 'react';

const NewWhiteboardIcon = (props) => {
  let fill = props.fill ? props.fill : '#000';
  let fillOpacity = props.fill ? 1 : 0.38;

  return (
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_11297_14485)">
        <path
          d="M37.6807 11.714V19.9474V21.214C37.6807 27.1852 37.6807 30.1707 35.8258 32.0257C33.9708 33.8807 30.9852 33.8807 25.0141 33.8807H14.8807C8.90962 33.8807 5.92406 33.8807 4.06907 32.0257C2.21408 30.1707 2.21408 27.1852 2.21408 21.214V18.6807C2.21408 12.7096 2.21408 9.72402 4.06907 7.86903C5.92406 6.01404 8.90962 6.01404 14.8807 6.01404H19.9474H25.0141"
          stroke="white"
          stroke-linecap="round"
        />
        <rect
          x="9.81404"
          y="30.0807"
          width="20.2667"
          height="1.26667"
          rx="0.633333"
          stroke="white"
          stroke-width="1.26667"
        />
        <path
          d="M30.9067 4.05719L31.7285 3.23535C33.0902 1.8737 35.2978 1.8737 36.6595 3.23535C38.0212 4.59701 38.0212 6.8047 36.6595 8.16635L35.8377 8.98819M30.9067 4.05719C30.9067 4.05719 31.0094 5.80358 32.5503 7.34452C34.0913 8.88546 35.8377 8.98819 35.8377 8.98819M30.9067 4.05719L23.3512 11.6127C22.8394 12.1244 22.5835 12.3803 22.3635 12.6624C22.1039 12.9953 21.8813 13.3554 21.6998 13.7364C21.5458 14.0594 21.4314 14.4027 21.2025 15.0892L20.2327 17.9986M35.8377 8.98819L28.2822 16.5437C27.7704 17.0554 27.5145 17.3113 27.2324 17.5314C26.8996 17.791 26.5395 18.0135 26.1585 18.1951C25.8355 18.349 25.4922 18.4635 24.8056 18.6923L21.8962 19.6621M21.8962 19.6621L21.185 19.8992C20.8472 20.0118 20.4747 19.9239 20.2228 19.672C19.971 19.4202 19.8831 19.0477 19.9957 18.7098L20.2327 17.9986M21.8962 19.6621L20.2327 17.9986"
          stroke="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_11297_14485">
          <rect width="38" height="38" fill="white" transform="translate(0.947388 0.947388)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NewWhiteboardIcon;
