import ACTION_TYPES from '../actions/type.action';

const initialState = {
  deviceConnected: false,
};

const deviceStatusReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPES.DEVICE_CONNECTED:
      return {
        deviceConnected: payload,
      };
    default:
      return state;
  }
};

export default deviceStatusReducer;