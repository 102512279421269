import React, { useRef } from 'react';
import { RenderPdf } from './component';
import PubSub from 'pubsub-js';
import { EVENTS } from '../../../../constants/events';
import { useSelector } from 'react-redux';

export const RenderPdfContainer = (props) => {
  const canvasRef = useRef(null);
  const fileInfo = useSelector((state) => state.documentImport.fileInfo);
  const { pageNumber, shouldBeFullscreen } = props;

  const thumbnailWidth = fileInfo && fileInfo.filetype === 'document/pptx' ? 180 : 100;

  const onClick = () => {
    /**
     * Todo:
     * canvasRef is not able to get the canvas element,
     * hence using the querySelector to get the element.
     * Maybe, we might to update this to canvasRef later
     */
    const importPDFCanvas = document.querySelector(`.import-pdf-page-${pageNumber} canvas`);
    const pdfDataUrl = importPDFCanvas.toDataURL();
    const options = shouldBeFullscreen ? { scale: 'MAX' } : {};
    PubSub.publish(EVENTS.IMAGES_TO_CANVAS, { data: { dataUrls: [pdfDataUrl], options } });
  };

  const onDragStart = (e) => {
    const importPDFCanvas = document.querySelector(`.import-pdf-page-${pageNumber} canvas`);
    const pdfDataUrl = importPDFCanvas.toDataURL();
    const options = shouldBeFullscreen ? { scale: 'MAX' } : {};
    e.dataTransfer.setData('type', 'pdf-page-drag');
    e.dataTransfer.setData('data', JSON.stringify({ dataUrls: [pdfDataUrl], options }));
  };

  return (
    <RenderPdf
      {...props}
      canvasRef={canvasRef}
      onClick={onClick}
      thumbnailWidth={thumbnailWidth}
      onDragStart={onDragStart}
    />
  );
};
