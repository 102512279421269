const { makeStyles } = require('@material-ui/core');

export const useDisplayControlsStyles = makeStyles(() => ({
  buttonText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#000000',
    textTransform: 'none',
    border: 'none',
  },
}));
