import React from 'react';

const FiltersIcon = () => {
  return (
    <>
      <svg
        width="23"
        height="23"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_6838_8286)">
          <path
            d="M10.1663 1.27038L10.1663 3.04573C9.23367 3.33439 8.54828 4.21708 8.54828 5.27039C8.54828 6.32371 9.23367 7.20574 10.1663 7.49505L10.1663 15.9371C10.1663 16.3051 10.4562 16.6038 10.8135 16.6038C11.1708 16.6038 11.4607 16.3051 11.4607 15.9371L11.4607 7.49509C12.3933 7.20643 13.0787 6.32374 13.0787 5.27042C13.0787 4.21711 12.3933 3.33508 11.4607 3.04576L11.4607 1.27042C11.4607 0.90176 11.1708 0.60376 10.8135 0.60376C10.4562 0.60376 10.1663 0.901729 10.1663 1.27038ZM11.7843 5.27039C11.7843 5.82174 11.3488 6.2704 10.8135 6.2704C10.2783 6.2704 9.8427 5.82174 9.8427 5.27039C9.8427 4.71905 10.2783 4.27039 10.8135 4.27039C11.3488 4.27039 11.7843 4.71908 11.7843 5.27039Z"
            fill="black"
            fill-opacity="0.6"
          />
          <path
            d="M4.98848 1.27038L4.98849 9.7124C4.05587 10.0017 3.37048 10.8837 3.37048 11.9371C3.37048 12.9904 4.05587 13.8724 4.98849 14.1617L4.98849 15.9371C4.98849 16.3051 5.27842 16.6038 5.63568 16.6038C5.99294 16.6038 6.28287 16.3051 6.28287 15.9371L6.28287 14.1618C7.21549 13.8724 7.90088 12.9904 7.90088 11.9371C7.90088 10.8838 7.21549 10.0017 6.28287 9.71243L6.28287 1.27042C6.28287 0.90176 5.99294 0.60376 5.63568 0.60376C5.27842 0.603727 4.98848 0.901729 4.98848 1.27038ZM6.60649 11.9371C6.60649 12.4884 6.17093 12.9371 5.63568 12.9371C5.10043 12.9371 4.66487 12.4884 4.66487 11.9371C4.66487 11.3857 5.10043 10.9371 5.63568 10.9371C6.17093 10.9371 6.60649 11.3857 6.60649 11.9371Z"
            fill="black"
            fill-opacity="0.6"
          />
        </g>
        <defs>
          <clipPath id="clip0_6838_8286">
            <rect width="16" height="16" fill="white" transform="translate(0.224609 0.60376)" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default FiltersIcon;
