import React from 'react';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import KeyboardHideIcon from '@material-ui/icons/KeyboardHide';
import StyledButton from '../../../../UIComponents/input/StyledButton';

export const Keyboard = React.forwardRef((props, ref) => {
  const { isOpen, handleChange, onDisplayIconClick, handleBlur, value } = props;
  const displayIcon = isOpen ? <KeyboardHideIcon /> : <KeyboardIcon />;

  const inputField = (
    <input
      type="text"
      ref={ref}
      onChange={handleChange}
      onBlur={handleBlur}
      style={{ opacity: 0, position: 'absolute' }}
      value={value}
    />
  );

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      onClick={onDisplayIconClick}
    >
      <StyledButton>{displayIcon}</StyledButton>
      {inputField}
    </div>
  );
});

Keyboard.displayName = 'Keyboard';

export default Keyboard;
