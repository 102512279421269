import React from 'react';

const CalendarIcon = () => {
  return (
    <>
      <svg
        width="31"
        height="35"
        viewBox="0 0 31 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.4577 30.9927H4.12435V12.6593H27.4577V30.9927ZM22.4577 0.992676V4.32601H9.12435V0.992676H5.79102V4.32601H4.12435C2.27435 4.32601 0.791016 5.80934 0.791016 7.65934V30.9927C0.791016 31.8767 1.1422 32.7246 1.76733 33.3497C2.39245 33.9748 3.24029 34.326 4.12435 34.326H27.4577C28.3417 34.326 29.1896 33.9748 29.8147 33.3497C30.4398 32.7246 30.791 31.8767 30.791 30.9927V7.65934C30.791 5.80934 29.291 4.32601 27.4577 4.32601H25.791V0.992676H22.4577ZM24.1243 19.326H15.791V27.6593H24.1243V19.326Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    </>
  );
};

export default CalendarIcon;
