export { PendingRequestIcon } from './PendingRequestIcon';
export { PhoneIcon } from './PhoneIcon';
export { Zoom } from './Zoom';
export { Webex } from './Webex';
export { GoogleMeet } from './GoogleMeet';
export { MicrosoftTeam } from './MicrosoftTeam';
export { ZoomSmall } from './ZoomSmall';
export { Microsoft } from './Microsoft';
export { RemoteControllerIcon } from './RemoteController';
export { default as notesIcon } from './notes.svg';
export { default as AddIcon } from './add.svg';
export { default as addCircleIcon } from './add-circle.png';
export { default as chatIcon } from './chat.svg';
export { default as notebookIcon } from './notebook.svg';
export { default as notebookIcon2 } from './notebook.png';
export { default as quizIcon } from './quiz.svg';
export { default as improveIcon } from './improve.svg';
export { default as elaborateIcon } from './elaborate.svg';
export { default as summariseIcon } from './summarise.svg';
export { default as generalAgentIcon } from './general-agent.png';
export { default as lessonPlanCreatorAgentIcon } from './lesson-plan-creator-agent.png';
export { default as quizMasterAgentIcon } from './quiz-master-agent.png';
export { default as devilsAdvocateAgentIcon } from './devils-advocate-agent.png';
export { default as lifeMateAgentIcon } from './life-mate-agent.png';
export { default as learnMateAgentIcon } from './learn-mate-agent.png';
export { default as masteryMateAgentIcon } from './mastery-mate-agent.png';
export { default as arrowLeftIcon } from './arrow-left.png';
export { default as backIcon } from './back.svg';
export { default as betaIcon } from './beta.svg';
export { default as langIcon } from './lang.png';
export { default as micIcon } from './mic.svg';
export { default as pdfIcon } from './pdf-2.svg';
export { default as youtubeIcon } from './youtube.svg';
export { default as stopButtonIcon } from './stop.svg';
export { default as uploadIcon } from './tray-arrow-up.svg';
export { default as birdAIIcon } from './bird-ai.png';
export { default as addSquareIcon } from './add-square.svg';
export { default as brightAIIcon } from './bright-ai-2.svg';
