import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  TextField,
  Dialog,
  Button,
  makeStyles,
  MenuItem,
  Menu,
  Popover,
  Badge,
  Tooltip,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import {
  getBoardInfo,
  getSortBy,
  getSpaceId,
  getToken,
  getWorkspaceId,
  getWorkspaceImg,
  getWorkspaceName,
  setBoardInfo,
  setSortBy,
  setSpaceId,
  setSpaceName,
  setUserId,
  setWorkspaceId,
  setWorkspaceImg,
  setWorkspaceName,
} from '../../Utils/authentication-access';
import AddIcon from '@material-ui/icons/Add';
import SortIcon from '@material-ui/icons/Sort';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import SideBarMenu from './SideBarMenu';
import logoTeamhub from '../../assets/img/logoTeamhub.svg';
import SideBarControle from '../../assets/img/SideBarControle.svg';
import SupportMenu from './SupportMenu';
import ProfileDropdown from './UserProfile/ProfileDropdown';
import { spacesService } from '../../services/spaces.service';
import userService from '../../services/user.service';
import Search from './Search';
import BoardFilterInfo from './BoardFilterInfo';

import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WorkspaceDialog from './Dialogs/WorkspaceDialog';
import WorkspaceIcon from './WorkspaceIcon';
import { useHistory } from 'react-router-dom';
import WorkspaceDefaultLogo from '../../assets/svg/institute.svg';
import workspaceService from '../../services/workspaces.services';
import { useDispatch, useSelector } from 'react-redux';
import { SidebarWorkspaceGroupContainer } from './sidebar-workspace-group/container';
import ProductLogoFull from '../../assets/icons/ProductLogoFull';
import { useDialogDispatcher } from '../../store/dispatcher/useDialogDispatcher';
import { useDialogSelector } from '../../store/selector/useDialogSelector';
import socketIOClient from 'socket.io-client';
import { BIRDAI_SERVER_URL } from '../../config';
import { EVENTS } from '../../constants/events';
import { activeSpaceInfo } from '../../store/actions/spaces.action';
import { getTeamsInfo } from '../../store/actions/teams.action';
import ControllerSidebar from '../../Kneura-Web-Whiteboard/Controller/ControllerSidebar';
import ScreenSwitcher from './ScreenSwitcher';
import { useNotificationDispatcher } from '../../store/dispatcher/useNotificationDispatcher';
import { getCloudLicenseDetails } from '../../store/actions/license.action';

const useStyles = makeStyles(() => ({
  firstlineContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  secondLineContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px',
  },
  mySpacesParentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mySpaceContainer: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  newSpacesParentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  newSpaceButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    border: '0.5px solid rgba(0, 0, 0, 0.4)',
    borderRadius: '2px',
    color: 'rgba(0,0,0,0.6)',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    padding: '5px 10px',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.05)',
    },
  },
  workspaceOptions: {
    cursor: 'pointer',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.15968px',
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'block',
  },
  popupstateBtn: {
    width: '320px',
    background: 'transparent',
    color: 'black',
    borderRadius: '25.7952px',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover, &:focus': {
      boxShadow: 'none',
    },
  },
  planDetails: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    padding: '7px',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    color: '#EC672F',
    background: 'rgba(223, 118, 55, 0.25)',
    borderRadius: '4px',
    marginBottom: '9px',
  },
  brandLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '55px',
    background: ' #E0E0E0',
    cursor: 'pointer',
  },
  workspaceIconsContainer: {
    paddingTop: '8px',
    maxHeight: '75%',
    overflow: 'auto',
  },
  sidebarIconsContainer: {
    width: '59px',
    height: '100vh',
    overflow: 'auto',
    scrollbarWidth: 'none',
    borderRight: '1px solid  #E0E0E0',
  },
  subContainerTwo: {
    position: 'absolute',
    bottom: '70px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginLeft: '14px',
  },
  sidebarMainContainer: {
    display: 'flex',
    overflow: 'hidden',
    maxWidth: 'min((100vw - 20px), 460px)',
  },
  supportMenu: {
    cursor: 'pointer',
    marginBottom: '14px',
  },
  logoMainContainer: {
    background: '#F1F1F1',
    zIndex: '9999',
    cursor: 'pointer',
  },
  logoInnerContainer: {
    margin: '12px auto',
    textAlign: 'center',
  },
}));

const SideBar = (props) => {
  const { isOpenNavigationSidebarDialog } = useDialogSelector();
  const { dispatchSetIsOpenNavigationSidebarDialog } = useDialogDispatcher();
  const history = useHistory();
  const [spaceInput, setSpaceInput] = useState(false);
  const [spaceName, setSpaceNameData] = useState('');
  const [spacesInfo, setSpacesInfo] = useState(0);
  const [userData, setUserData] = useState();
  const [count, setCount] = useState(0);
  const classes = useStyles();
  const [childCount, setChildCount] = useState(0);
  const [sorterAnchorEl, setSorterAnchorEl] = React.useState(null);
  const sortSpacesOption = Boolean(sorterAnchorEl);
  const [openNoticeBar, setOpenNoticeBar] = useState(false);
  const [searchBar, setSearchBar] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredBoardData, setFilteredBoardData] = useState(0);
  const [test, setTest] = useState(0);
  const [activeBoard, setActiveBoard] = useState();
  const [workSpaceDialogOpen, setWorkspaceDialogOpen] = useState(false);
  const [workSpaceInput, setWorkspaceInput] = useState('');
  const [activeWorkspaceName, setActiveWorkspaceName] = useState('');
  const [activeWorkspaceDp, setActiveWorkspaceDp] = useState('');
  const [listOfWorkspaces, setListOfWorkspaces] = useState();
  const [errorTextMessage, setErrorTextMessage] = useState('');
  const [createWorkspaceErrorTextMessage, setCreateWorkspaceErrorTextMessage] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const updatedCount = useSelector((state) => state.spaceReducer.count);
  const [sortType, setSortType] = useState('name');
  const dispatch = useDispatch();
  const isMobileView = useMediaQuery('(max-width:600px)');
  const { dispatchSetSnackbar, dispatchShowLicenseExhaustedDialog } = useNotificationDispatcher();
  const cloudLicenseInfo = useSelector((state) => state.licenseReducer.cloudLicenseDetails);
  const [totalSpacesCount, setTotalSpacesCount] = useState(0);

  const closeNotification = () => {
    setOpenNoticeBar(false);
  };

  const childCountChange = () => {
    setChildCount((value) => value + 1);
  };

  useEffect(() => {
    callGetSpaces();
  }, [childCount]);

  useEffect(() => {
    callGetSpaces();
  }, [updatedCount, isOpenNavigationSidebarDialog]);

  const AddSpaceName = () => {
    if (
      cloudLicenseInfo.teams.isUnlimited ||
      totalSpacesCount < cloudLicenseInfo.teams.totalLimit
    ) {
      setSpaceInput(true);
    } else {
      dispatchShowLicenseExhaustedDialog();
    }
  };

  const handleClose = () => {
    setSpaceInput(false);
  };

  const createSpace = async () => {
    try {
      if (spaceName !== '') {
        setErrorTextMessage('');
        let workspaceID = getWorkspaceId();
        await spacesService.createSpace(spaceName, workspaceID);
        callGetSpaces();
        setCount(count + 1);
        handleClose();
        setSpaceNameData('');
      } else {
        setErrorTextMessage('*Required');
      }
    } catch (error) {
      console.log('error  B', error.response.data.message);
      dispatchSetSnackbar({ message: error.response.data.message });
    }
  };

  useEffect(() => {
    dispatch(getCloudLicenseDetails());
    const init = async () => {
      const userDetails = await getUserDetails();
      callGetSpaces();
      getListOfWorkspaces();

      checkIsAdmin(userDetails.id);

      dispatch(activeSpaceInfo(getBoardInfo()));

      const workspacename = getWorkspaceName();
      setActiveWorkspaceName(workspacename);

      const workspacedp = getWorkspaceImg();
      setActiveWorkspaceDp(workspacedp);
    };
    init();

    setSortType(getSortBy());

    // INFO: Initiating socket connection
    let socket;
    socket = socketIOClient(BIRDAI_SERVER_URL, {
      path: '/user-events',
      auth: {
        token: getToken(),
      },
      reconnection: true,
      transports: ['websocket'],
      upgrade: true,
      autoConnect: true,
    });
    socket.on('connect', (data) => {
      console.log('connect', data);
    });
    socket.on('connect_error', (data) => {
      console.log('connect_error', data);
    });
    socket.on('disconnect', (data) => {
      console.log('disconnect', data);
    });
    socket.on('message', getMessage);
    return () => {
      socket.off('message', getMessage);
      socket.disconnect();
    };
  }, []);

  const getMessage = (msg) => {
    console.log('message came', msg.type);
    if (msg.type === EVENTS.ADDED_TO_SPACE || EVENTS.REMOVED_FROM_SPACE) {
      callGetSpaces();
    }
    if (msg.type === EVENTS.JOIN_SPACE_REQUEST_ACCEPTED) {
      getListOfWorkspaces();
      callGetSpaces();
    }
    if (msg.type === EVENTS.JOIN_WORKSPACE_REQUEST_ACCEPTED || EVENTS.REMOVED_FROM_WORKSPACE) {
      getListOfWorkspaces();
    }
  };

  useEffect(() => {
    sortHandler();
  }, [spacesInfo]);

  const sortHandler = () => {
    if (spacesInfo != 0) {
      if (getSortBy() === 'Name') {
        sortByName();
      } else if (getSortBy() === 'Date Created') {
        sortSpacesByDate();
      } else if (getSortBy() === 'Last Modified') {
        sortSpacesByUpdated();
      } else {
        sortByName();
      }
    }
  };

  const getUserDetails = async () => {
    const response = await userService.getUserDetails();
    setUserData(response.data.user);
    setUserId(response.data.user.id);
    return response.data.user;
  };

  const checkIsAdmin = async (userId) => {
    const response = await workspaceService.allUsersList();
    const data = response.data.data.users;

    let isTrue;
    data.forEach((user) => {
      if (user.userId === userId && user.roles.includes('ADMIN')) {
        isTrue = true;
      }
    });
    setIsAdmin(isTrue);
  };

  const callUserDetails = () => {
    getUserDetails();
  };

  const callGetSpaces = async () => {
    let workspaceID = getWorkspaceId();
    const response = await spacesService.getSpaces(workspaceID);
    dispatch(getTeamsInfo(response.data.data.spaces));
    // TODO: need to refactor the code
    let boardData;
    if (response) {
      setSpacesInfo(response.data.data.spaces);
      setTotalSpacesCount(response.data.data.spaces.length);

      const spacesData = response.data.data.spaces;
      boardData = mergeSpaceChildren(spacesData);

      response.data.data.spaces.forEach((space) => {
        if (space.id.includes(getSpaceId())) {
          setSpaceName(space.name);
        }
      });

      setFilteredBoardData(boardData);
      setTest(boardData);
      checkTeamAccess(response?.data?.data?.spaces);
    } else {
      setSpacesInfo(0);
    }
  };

  const checkTeamAccess = (teamsInfo) => {
    const teamCheck = teamsInfo.find((element) => element.id === getSpaceId());
    if (!teamCheck) {
      setSpaceId(teamsInfo[0].id);
      setSpaceName(teamsInfo[0].name);
      let { email, hardwareId, name, flowId, classId } = getBoardInfo();
      name = teamsInfo[0].childrens[0].name;
      classId = teamsInfo[0].childrens[0].meta.classId;
      flowId = teamsInfo[0].childrens[0].meta.flowId;
      let canvasInfo = {
        email,
        hardwareId,
        name,
        flowId,
        classId,
      };
      setBoardInfo(canvasInfo);
      window.location.reload();
    }
  };

  const mergeSpaceChildren = (spacesData) => {
    return spacesData?.reduce(mergeChildrenReducer, []);
  };
  const mergeChildrenReducer = (a, b) => [...a, ...b.childrens];

  const sortSpaces = (event) => {
    setSorterAnchorEl(event.currentTarget);
  };

  const sortSpacesOptionClose = () => {
    setSorterAnchorEl(null);
  };

  const sortByName = () => {
    let updatedList = spacesInfo;

    updatedList = updatedList.sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    setSpacesInfo(updatedList);
    setSorterAnchorEl(null);
    setSortType('Name');
    setSortBy('Name');
  };

  const sortSpacesByDate = () => {
    let updatedList = spacesInfo;
    updatedList = updatedList.sort(function (a, b) {
      if (a.createdAt < b.createdAt) {
        return 1;
      }
      if (a.createdAt > b.createdAt) {
        return -1;
      }
      return 0;
    });
    setSpacesInfo(updatedList);
    setSorterAnchorEl(null);
    setSortType('Date Created');
    setSortBy('Date Created');
  };

  const sortSpacesByUpdated = () => {
    let updatedList = spacesInfo;
    updatedList = updatedList.sort(function (a, b) {
      if (a.updatedAt < b.updatedAt) {
        return 1;
      }
      if (a.updatedAt > b.updatedAt) {
        return -1;
      }
      return 0;
    });
    setSpacesInfo(updatedList);
    setSorterAnchorEl(null);
    setSortType('Last Modified');
    setSortBy('Last Modified');
  };

  const setSearchBarClick = () => {
    setSearchBar(true);
  };

  const closeSearch = () => {
    setSearchBar(false);
  };

  const onEnterSearchText = (value) => {
    setSearchText(value);
    if (value.length == 0) {
      setFilteredBoardData(test);
    } else {
      let data = test.filter((item) => {
        return item.name.toLowerCase().includes(value.toLowerCase());
      });
      setFilteredBoardData(data);
    }
  };

  const closeWorkspaceDialog = () => {
    setWorkspaceDialogOpen(false);
  };

  const onChangeInputText = (event) => {
    setWorkspaceInput(event.target.value);
  };

  const createNewWorkspace = async (workspaceName) => {
    if (workspaceName !== '') {
      setCreateWorkspaceErrorTextMessage('');
      const response = await workspaceService.createWorkspace(workspaceName);
      closeWorkspaceDialog();
      setWorkspaceInput('');
      if (response.status === 200 || 201) {
        getListOfWorkspaces();
      }
    } else {
      setCreateWorkspaceErrorTextMessage('*Required');
    }
  };

  const getListOfWorkspaces = async () => {
    const response = await workspaceService.getWorkspaces();
    setListOfWorkspaces(response?.data?.data?.workspaces);
    const workspaceCheck = response?.data?.data?.workspaces.find(
      (element) => element.id === getWorkspaceId(),
    );
    if (!workspaceCheck) {
      setWorkspaceId(response?.data?.data?.workspaces[0].id);
      setWorkspaceName(response?.data?.data?.workspaces[0].name);
      setWorkspaceImg(response?.data?.data?.workspaces[0].imageUrl);
      window.location.reload();
    }
  };

  const switchWorkspaceScreen = () => {
    history.push('/switchWorkspace');
  };

  const landOnAdminScreen = () => {
    history.push('/manage/member');
  };

  const openEditWorkspaceDialog = () => {
    history.push({
      pathname: '/manage/member',
      state: {
        openDialog: true,
      },
    });
  };

  const onEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      createSpace();
    }
  };

  const openNavigationSidebarDialog = () => {
    dispatchSetIsOpenNavigationSidebarDialog(true);
  };

  const closeNavigationSidebarDialog = () => {
    dispatchSetIsOpenNavigationSidebarDialog(false);
  };

  return (
    <>
      <Tooltip title="Switch to">
        <IconButton
          size="small"
          edge="start"
          color="inherit"
          aria-label="logo"
          onClick={openNavigationSidebarDialog}
          style={{
            zIndex: 99999,
            width: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '3px',
            borderRadius: '4px',
          }}
        >
          <img src={SideBarControle} alt="img" />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="left"
        open={isOpenNavigationSidebarDialog}
        onClose={closeNavigationSidebarDialog}
      >
        <div className={classes.sidebarMainContainer}>
          <div className={classes.sidebarIconsContainer}>
            <ControllerSidebar />
          </div>
          <div style={{ overflow: 'auto', scrollbarWidth: 'none', flex: 1, width: '380px' }}>
            <Box textAlign="center" role="presentaion">
              <Typography variant="h6" component="div"></Typography>
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '100%' }}>
                    <ScreenSwitcher />
                  </div>
                  {isMobileView && (
                    <div style={{ marginRight: '5px' }}>
                      <CloseIcon onClick={closeNavigationSidebarDialog} />
                    </div>
                  )}
                </div>
                {searchBar ? (
                  <div style={{ display: 'flex', alignItems: 'center', padding: '15px' }}>
                    <Search
                      searchText={'Search space here...'}
                      onEnterSearchText={onEnterSearchText}
                    />

                    <CloseIcon onClick={closeSearch} style={{ paddingLeft: '13px' }} />
                  </div>
                ) : (
                  <div className={classes.secondLineContainer}>
                    <div className={classes.mySpacesParentContainer}>
                      <div className={classes.mySpaceContainer}>My Teams</div>
                    </div>
                    <div className={classes.newSpacesParentContainer}>
                      <div onClick={AddSpaceName} className={classes.newSpaceButton}>
                        <AddIcon
                          fontSize="small"
                          style={{
                            color: 'rgba(0,0,0,0.6)',
                            paddingRight: '5px',
                            cursor: 'pointer',
                          }}
                        />
                        Create new
                      </div>
                      <div>
                        <SearchIcon
                          fontSize="small"
                          style={{
                            color: 'rgba(0, 0, 0, 0.6)',
                            marginLeft: '0.5rem',
                            cursor: 'pointer',
                          }}
                          onClick={setSearchBarClick}
                        />
                      </div>
                      <div>
                        {' '}
                        <SortIcon
                          fontSize="small"
                          style={{
                            color: 'rgba(0, 0, 0, 0.6)',
                            marginLeft: '0.5rem',
                            cursor: 'pointer',
                          }}
                          onClick={sortSpaces}
                        />
                        {/* ////// */}
                        <Menu
                          id="sorter-menu"
                          anchorEl={sorterAnchorEl}
                          keepMounted
                          open={sortSpacesOption}
                          onClose={sortSpacesOptionClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          getContentAnchorEl={null}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <MenuItem
                            className={sortType === 'name' ? 'activeSort' : ''}
                            onClick={() => sortByName()}
                          >
                            Name
                          </MenuItem>
                          <MenuItem
                            className={sortType === 'Last Modified' ? 'activeSort' : ''}
                            onClick={() => sortSpacesByUpdated()}
                          >
                            Last Modified
                          </MenuItem>
                          <MenuItem
                            className={sortType === 'Date Created' ? 'activeSort' : ''}
                            onClick={() => sortSpacesByDate()}
                          >
                            Date Created
                          </MenuItem>
                        </Menu>
                        {/* /////// */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Box>
            <Box p={0}>
              <hr
                style={{
                  margin: 0,
                  padding: 0,
                  boxSizing: 'border-box',
                  border: '0.5px solid #E0E0E0',
                }}
              />
            </Box>

            {searchBar ? (
              <div>
                <BoardFilterInfo boardInfo={filteredBoardData} spacesInfo={spacesInfo} />
              </div>
            ) : (
              <SideBarMenu
                boardInfo={filteredBoardData}
                isDrawerOpen={isOpenNavigationSidebarDialog}
                count={count}
                spacesInfo={spacesInfo}
                childCountChange={childCountChange}
                sortHandler={sortHandler}
                sortType={sortType}
              />
            )}
          </div>
        </div>

        {/* 
        TODO: required
        <div className={classes.logoMainContainer} onClick={switchWorkspaceScreen}>
          <div className={classes.logoInnerContainer}>
            <ProductLogoFull size="sm" />
          </div>
        </div> */}
      </Drawer>

      <Dialog
        open={spaceInput}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'sm'}
        PaperProps={{
          style: {
            borderRadius: 16,
          },
        }}
      >
        <div
          style={{
            padding: '2%',
            display: 'grid',
            gridTemplateColumns: '90% 10%',
            alignItems: 'center',
            minHeight: '30px',
            borderBottom: '1px solid rgba(0,0,0,0.12)',
          }}
        >
          <div
            style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '28px',
              textAlign: 'center',
              marginLeft: '50px',
              color: 'rgba(0, 0, 0)',
            }}
          >
            {' '}
            Create a new Team
          </div>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{ marginRight: 10, cursor: 'pointer' }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div style={{ margin: '29px' }}>
          <Typography
            variant="h6"
            component="div"
            style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '10px',
              lineHeight: '16px',
              color: 'rgba(0, 0, 0, 0.87)',
            }}
          >
            Team Name
          </Typography>
          <TextField
            autoFocus
            id="name"
            type="name"
            style={{ width: `${isMobileView ? '100%' : '423px'}` }}
            value={spaceName}
            variant="outlined"
            onChange={(event) => setSpaceNameData(event.target.value)}
            onKeyDown={onEnterKeyPress}
            helperText={errorTextMessage}
          />
        </div>

        <div
          style={{
            borderTop: '1px solid rgba(0,0,0,0.12)',
            flex: '0 0 auto',
            display: 'flex',
            padding: '12px',
            paddingBottom: '21px',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={handleClose}
            style={{
              width: '100px',
              height: '36px',
              borderRadius: '4px',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              color: 'rgba(0, 0, 0, 0.38)',
            }}
          >
            {' '}
            CANCEL
          </Button>

          <Button
            style={{
              marginLeft: '5px',
              width: '100px',
              height: '36px',
              background: '#335AFB',
              boxShadow: '0px 0.3px 0.5px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.2)',
              borderRadius: '4px',
              color: 'white',
            }}
            onClick={createSpace}
          >
            SAVE
          </Button>
        </div>
      </Dialog>

      <WorkspaceDialog
        open={workSpaceDialogOpen}
        closeDialog={closeWorkspaceDialog}
        inputText={workSpaceInput}
        onChangeInputText={onChangeInputText}
        createNewWorkspace={createNewWorkspace}
        errorTextMessage={createWorkspaceErrorTextMessage}
      />
    </>
  );
};

export default SideBar;
