import React from 'react'
import './responsive.css'

const Terms = () => {
  return (
    <div>

      <div className='containerMargin'>
        <h1 style={{ textAlign: "center" }}>
          TERMS OF USE
        </h1>
        <br />
        <h3>1. Acceptance of Terms of Use</h3>
        <p>
          These terms of use (Terms) constitute a legally binding agreement between you/user and the company regarding your use of the Bird AI website. i.e. <a href={'www.birdai.com'} style={{ textDecoration: 'none' }}>www.birdai.com</a> (the Site), and Bird AI products /mobile application (“App”) and associated services.<br />
          <br />
          “User” or “You” means any person who accesses, uses or avails this site, App or the services of the company for publishing sharing, transacting, displaying, or uploading information or views and includes another person jointly participating in using the site or the app of the company.<br /><br />
          By accessing the Site, App or Service, you consent to these Terms. You hereby represent and warrant to the Company that you are at least eighteen (18) years of age or above and are capable of entering, adhering and fulfilling to the Terms and conditions herein and that you agree to be bound by the following terms and conditions. While Individuals under the age of 18 years may utilize the Products and services only with the guidance and supervision of their parents and / or Legal guardians.<br /><br />
          You must not use Bird AI products and platforms if you have any objection to any of these Website Terms And Conditions or any condition laid in the privacy policy.The App and the site should be used by anyone eligible to enter into a valid contract. You warrant that you possess the legal right to use payment options for any purchase. You must use the App and the site under parental or Guardian supervision if you are a minor. <br /><br />
          The  company reserves the right at its discretion to change, modify, add, or remove these Terms at any time by posting the amended Terms. Please check these Terms periodically for changes. Your continued use of the App and Website after the posting of changes constiutes your binding acceptance of such changes, Also, when using any particular service, you may be subject to any posted guidelines or rules applicable to such services. If you do not agree with the Terms, please do not use this site or the App. 

        </p>

        <h3>2. Registration and Availability</h3>
        <p>
          To register for Bird AI App you may be required to open an account by completing the registration process (by providing with current, complete and accurate information as prompted by the registration form). You expressly agree to absolve the Company and/or the Bird AI Site, App or service of any responsibility/liability in this regard. By registering, you agree to the Terms stated herein and in addition to any other specific terms which shall be posted at an appropriate location of the app and the site. Each registration is for a single individual user only.<br /><br />
          To access the Bird AI App, you will be asked to input your email id and phone number, during your registration, to establish a unique identity for you. If the Company reasonably believes that an account is being misused in any manner, the Company shall reserve the right to cancel access rights immediately without notice, and block access to all its users. Furthermore, you shall be entirely responsible for any activities that occur under your account. You agree to notify the Company immediately of any unauthorized use of your account or any other breach of security. The Company will not be liable for any loss that you may incur as a result of someone else using your account, however, you could be held liable for losses incurred by the Company or another party due to someone else using your account.<br /><br />
          Availability: The data provided through Bird AI App will change from time to time. The quality of app service may vary from device to device and may be affected by a variety of factors, such as your location, speed of your internet connection, etc. You are responsible for all internet access charges.
          <br />Please check with your internet provider for information on possible Internet data usage changers.
        </p>
        <br />
        <h3>3. Intellectual Property Rights</h3>
        <p>
          Other than the content you own, which you may have opted to include on the App, under these Terms We, Bird AI and/or its affiliates own all rights to the intellectual property and material contained in the App, and all such rights are reserved. All programs, services, processes, designs, software, technologies, trademarks, and trade names and inventions appearing or accessible via the website or the Bird AI App, are the property of Bird AI or its licensors.
        </p>
        <h3>4. Download </h3>
        <p> In the event you download the App from the site, the App, including any files, images incorporated in or generated by the App, and data accompanying the App (collectively, the "App") are licensed to you by Us. The use of the App is subject to the license terms in the Software License Agreement that accompanies the App. We do not transfer the title of the App/software to you. Bird AI retains complete title to the app/Software/site and all intellectual property rights therein. You may not redistribute, sell, decompile, reverse engineer, disassemble, or otherwise reduce the App to a human-readable form. </p>

        <h3>
          5. Ordering and Contracting
        </h3>

        <p>
          5.1 You can place an order of our products from the site, or send an e-mail to us. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. This condition does not affect your statutory rights related to electronic communications. You are providing your consent to receive communications electronically from us concerning your purchase of our products.
          <br /><br />
          5.2 You agree to abide by the Terms and Conditions while placing an order on the website. Prior to the confirmation or acceptance of an order and/or completion of purchase require the verification of information provided by you. While submitting your order you shall have the privilege to review such order. Any corrections you may seek at this stage will be allowed to correct the input error.
          <br /><br />
          5.3 Order placed through the website constitutes an offer to purchase a product and/or services listed on the website.We, Bird AI reserve the discretionary right to accept or reject the offer placed on the website.We deliver within a selected delivery area.You will receive an email confirmation about the acceptance of your offer.
        </p>

        <h3>6. Third-Party Content </h3>

        <p>
          6.1 Bird AI has made available the third-party content by way of search results to you. Subject to your compliance with Terms herein, Bird AI does not claim any ownership right or any other right in the third-party content (digital or otherwise). <br />Only search results are made available to you to access third-party content available with a limited, non-exclusive, non-transferable license, to (a) access and view any such Content solely for your personal and non-commercial purposes and (b) access and view any such content to which you permitted to access, solely for your personal and non-commercial purposes. You have no right to sublicense the license rights granted in this section. <br /><br />
                6.2  You will not copy, adapt, modify, prepare derivative works, sell, distribute, license, transfer, publicly perform, transmit, broadcast or otherwise exploit the Site, Application, Services, or Collective Content, except as expressly permitted in these Terms. No licenses or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by us or its licensors, except for the licenses an rights expressly granted in these Terms. 
                  </p>

        <h3>7. Member Content</h3>
        <p>
          7.1 We may permit you to post, publish, submit or transmit any Content(digital or otherwise). By making any such content available on or through the Site, Services, and Application, you hereby grant to Bird AI a worldwide, irrevocable, perpetual (or for the term of the protection), non-exclusive, transferable, royalty-free license, with the right to sublicense, to use, view, copy, adapt, modify, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast, access, view, and otherwise exploit such content on, through, utilizing or to promote or market the Site, Application and Services.
          <br />
          <br />
          7.2 You acknowledge and agree that you are solely responsible for digital content that you make avail through the Site, Service, and Application. Bird AI does not claim any ownership rights in any such Content and nothing in these Terms will be deemed to restrict any rights that you may have to use and exploit any such Content owned by you.<br /> <br />
          7.3 You acknowledge and agree that you either are the sole and exclusive owner of any Content(digital or otherwise) that you make available through the Site, Services, and Application or you have all rights, licenses, and consent that are necessary to grant to Bird AI the rights in such content as contemplated under these Terms.<br /> <br />
          7.4 You represent and warrants that neither of your any or all Content(digital or otherwise) nor your posting, uploading, publication, submission or transmittal of such Content or Bird AI use of Content (or any portion thereof) on, through or utilizing the Site, Application, the Services or Bird AI promotional campaigns will infringe, misappropriate or violate a third party's patent, copyright, trademark, trade secret, moral rights or other proprietary or intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.<br />
        </p>

        <h3>
          8. Feedback and Information
        </h3>

        <p>
          Any feedback you provide at the app and the site, by electronic mail or otherwise, including data, questions, comments, suggestions or the like shall be deemed to be non-confidential and non-proprietary.
        </p>

        <h3>
          9. No warranties
        </h3>

        <p>
          We do make no express or implied representations or warranties, of any kind related to the site or the materials contained on the site and the App. Additionally, nothing contained on the site shall be construed as providing consult or advice to you. <br />The website and Services are made available to you with a commercially reasonable level of skill and care and without warranty of any kind, either express or implied. Without limiting the foregoing, neither Bird AI nor its suppliers or distributors explicitly make any specific promises, warranties or undertakings about the Services disclaims any warranties of merchantability, fitness for a particular purpose, non-infringement, and any warranties arising out of the course of dealing or usage of trade. Bird AI PROVIDE THE SERVICES “AS IS”. <br /><br />
                Som e jurisdictions provide for certain warranties, like the implied warranty of merchantability, fitness for a particular purpose and non-infringement. To the extent ermitted by law, we exclude all warranties. 
        </p>


        <h3>10. Limitation of liability</h3>
        <p>

          In no event will Bird AI, its officers, directors, and employees, be liable for any damages, including without direct and indirect incidental and consequential damages, loss of expense, loss of business, business interruption, or loss of business opportunity in connection with any product, purchase order, service. The foregoing does not affect your non-excludable statutory rights and only applies to the extent permitted by the applicable law. In no event shall Bird AI's total liability to you for all damages, losses, and causes of action (whether in contract, tort (including but not limited to negligence) or otherwise) exceed the amount paid by you, if any, for accessing the app and the site.
        </p>

        <h3>
          11. Indemnification
        </h3>

        <p>
          You hereby indemnify to the fullest extent Bird AI from and against any liabilities, costs, demands, causes of action, damages and expenses (including reasonable attorney’s fees) arising out of or in any way related to your breach of any of the provisions of these Terms.
        </p>


        <h3>
          12. Severability
        </h3>

        <p>
          If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.
        </p>

        <h3>
          13. Variation of Terms
        </h3>

        <p>
          The Contents, webpages or/and entire website may change subject to provide a better user experience. The terms may also change, subject to revision of Terms as it sees fit, and by using the site and the app you are expected to review such Terms on regularly to ensure you understand all terms and conditions governing the use of the app.
        </p>


        <br />
        <h3>14. Suspension or Termination of account </h3>
        <p>
          If we have reasonable grounds to suspect that information provided by you is inaccurate, false, or not in accordance with the Terms of Use Privacy policy and EULA or in case of any suspicious or fraudulent activity from your account, we shall have the right to indefinitely suspend or terminate or block access to your account. You are solely responsible to keep your OTP, Debit card, Credit card details and bank account details confidential.

        </p>

        <h3>15. No Waiver</h3>

        <p>
          15.1 The failure to enforce a right or waiver of a breach of any provisions under the terms & conditions will not constitute a waiver of future enforcement of that right or provision.
          <br />15.2 The waiver of any such right or provision will be effective only if in writing by an authorized representative of Bird AI.


          <br />
          15.3 The terms and conditions contained herein shall be presumed to be enforceable, and any
          reading causing unenforceability shall yield to construction permitting enforcement. If any single
          term or clause shall be found unenforceable, it shall be severed,       <br />       <br />
          and the remaining term or clause and clauses enforced. Except as expressly outlined in these
          Terms, the exercise by either party of any of its remedies under these Terms will be without
          prejudice to its other remedies under these Terms or otherwise.</p>

        <h3>
          16. Entire Agreement
        </h3>
        <p>
          These Terms, including any legal notices and disclaimers contained on the app or the site, constitute the entire agreement between Bird AI and you in relation to your use of the app and the site and supersede all prior agreements and understandings concerning the same.
        </p>

        <h3>
          17. Governing Law & Jurisdiction
        </h3>

        <p>
          17.1 These Terms will be governed by and construed per the laws of the State of India, and you submit to the non-exclusive jurisdiction of the Courts located in New Delhi, India for the resolution of any disputes. Any dispute, claim or controversy arising out of or relating to this Terms including the determination of the scope or applicability of this Terms to arbitrate, or your use of the Application or information to which it gives access, shall be determined by arbitration in India.<br /><br />17.2 Arbitration shall be conducted under the Arbitration and Conciliation Act, 1996. All proceedings of such arbitration, including, without limitation, any awards, shall be in the English language. The award shall be final and binding on the parties to the dispute.
        </p>
        </div>
        </div>
  )
}
      
export default Terms