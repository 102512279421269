import React, { useEffect } from 'react';
import { Rnd } from 'react-rnd';
import { CloseButtonContainer, VideoContainer } from './component.style';
import { DisplayViewOptionsContainer } from './display-view-options/container';
import FullScreen from 'react-full-screen';
import { defaultDisplayHeight, defaultDisplayWidth } from './container';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

export const DisplayVideo = React.forwardRef((props, ref) => {
  const {
    id,
    showRemoteVideo,
    isViewOnly,
    videoCount,
    stream,
    isFullScreen,
    onChangeFullScreen,
    isContentUIDisplayed,
    toggleDisplay,
    onResizeStop,
    handleMetadataLoaded,
    displaySize,
  } = props;

  useEffect(() => {
    let videoElement;
    if (ref.current) {
      ref.current.srcObject = stream;
      ref.current.play();

      videoElement = ref.current;
      videoElement.addEventListener('loadedmetadata', handleMetadataLoaded);
    }

    return () => {
      videoElement.removeEventListener('loadedmetadata', handleMetadataLoaded);
    };
  }, []);

  let remoteVideoStyle = {};
  if (!showRemoteVideo) {
    remoteVideoStyle.display = 'none';
  }

  /** show full screen, if video count is 1 */
  if (isViewOnly && videoCount === 1) {
    remoteVideoStyle = {
      ...remoteVideoStyle,
      height: '100vh',
      width: '100%',
      backgroundColor: '#000000',
    };
  } else {
    /**
     * the height calculation is ideal only till video count is 4
     * TODO: calculate height for video count > 4
     */
    remoteVideoStyle = {
      ...remoteVideoStyle,
      height: '100%',
      width: '100%',
      backgroundColor: '#000000',
      borderRadius: '0.25rem',
    };
  }

  const displayVideo = (
    <video
      key={id}
      id={id}
      autoPlay
      muted
      playsInline
      width={displaySize.width}
      height={displaySize.height}
      style={{
        width: displaySize.width,
        height: displaySize.height,
        ...remoteVideoStyle,
      }}
      ref={ref}
    ></video>
  );

  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'solid 1px #ddd',
    background: '#f0f0f0',
    pointerEvents: 'all',
    borderRadius: '0.25rem',
  };

  const controls = (
    <DisplayViewOptionsContainer
      isFullScreen={isFullScreen}
      onChangeFullScreen={onChangeFullScreen}
      showDraggableOption={true}
      showFullScreenOption={true}
      showHideOrShowContentUIButtonOption={true}
      isContentUIDisplayed={isContentUIDisplayed}
      setIsContentUIDisplayed={toggleDisplay}
    />
  );

  const onExitFullscreen = () => {
    onChangeFullScreen(false);
  };

  const displayCloseButton = isFullScreen ? (
    <CloseButtonContainer onClick={onExitFullscreen}>
      <FullscreenExitIcon />
    </CloseButtonContainer>
  ) : (
    <></>
  );

  const content = (
    <FullScreen enabled={isFullScreen} onChange={onChangeFullScreen}>
      {displayVideo}
      {displayCloseButton}
    </FullScreen>
  );

  const displayVideoWithControls = (
    <VideoContainer isContentUIDisplayed={isContentUIDisplayed}>
      {controls}
      {content}
    </VideoContainer>
  );

  /**
   * center a react-rnd component on the screen
   */
  const getCenterRND = (width, height) => {
    const x = window.innerWidth / 2 - width / 2;
    const y = window.innerHeight / 2 - height / 2;
    return { x, y };
  };

  /**
   * randomize x and y by 48px
   * @returns
   */
  const getRandomDefaultRND = () => {
    const { x, y } = getCenterRND(defaultDisplayWidth, defaultDisplayHeight);
    const randomize = 48;
    const randomX = Math.floor(Math.random() * randomize);
    const randomY = Math.floor(Math.random() * randomize);
    return { x: x + randomX, y: y + randomY };
  };

  const rndDefault = {
    ...getRandomDefaultRND(),
    width: defaultDisplayWidth,
    height: defaultDisplayHeight,
  };

  if (isViewOnly) {
    return <>{displayVideo}</>;
  }

  return (
    <Rnd
      dragHandleClassName="drag-handle"
      style={style}
      default={rndDefault}
      size={{ width: displaySize.width, height: displaySize.height }}
      onResizeStop={onResizeStop}
    >
      {displayVideoWithControls}
    </Rnd>
  );
});
