import React, { useEffect, useState } from 'react';
import {
  Button,
  Chip,
  ClickAwayListener,
  Dialog,
  IconButton,
  Select,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import infoIcon from '../../../../assets/svg/infoIcon.svg';
import useIsOpenListItems from './useIsOpenListItems';
import ListItems from './ListItems';
import workspaceService from '../../../../services/workspaces.services';
import MembersList from './MembersList';
import { spacesService } from '../../../../services/spaces.service';
import { checkIsAuthUser, getSpaceId, getSpaceName } from '../../../../Utils/authentication-access';
import { useNotificationDispatcher } from '../../../../store/dispatcher/useNotificationDispatcher';
import { useDispatch } from 'react-redux';
import { activeTeamMembersInfo, reloadSpaces } from '../../../../store/actions/spaces.action';
import { useNewShareDialogStyle } from './useNewShareDialogStyle';
import { ShareUsingQRContainer } from '../share-using-qr/container';
import { ShareJoinSpaceLinkContentContainer } from '../../../../components/invites/share-join-space-link-content/container';
import { ShareUsingCanvasCodeContainer } from '../share-using-canvas-code/container';
import { ShareJoinCanvasLinkContentContainer } from '../../../../components/invites/share-join-canvas-link-content/container';

const NewShareDialog = ({ open, closeDialog, shareSpaceId, shareSpaceName, isMobile }) => {
  const classes = useNewShareDialogStyle();
  const { isOpenListItems, hideListItems, toggleListItems } = useIsOpenListItems();
  const [membersData, setMembersData] = useState();

  const [openAboutSpacesScreen, setOpenAboutSpacesScreen] = useState(true);
  const [updatedSuggestions, setUpdatedSuggestions] = useState();
  const [spaceMembersData, setSpaceMembersData] = useState(0);
  const [spaceMemberCount, setSpaceMemberCount] = useState(0);
  let listData = [];
  let spaceName = getSpaceName();
  const { dispatchSetSnackbar } = useNotificationDispatcher();
  const [enableBtn, setEnableBtn] = useState(0);
  const dispatch = useDispatch();

  const aboutHeadingText = 'Anyone added (from Workspace) can edit all the team’s boards.';

  const displayText = (
    <>
      <Chip label={`${spaceMembersData.length} members`} />
      <span> Find members</span>
    </>
  );

  const isAuthUser = checkIsAuthUser();

  useEffect(() => {
    isAuthUser && spaceMembersList();
    setEnableBtn(0);
  }, [open]);

  useEffect(() => {
    isAuthUser && getMembersList();
    isAuthUser && spaceMembersList();
  }, []);

  const callfunction = () => {
    spaceMembersList();
  };

  //INFO: spaceMembers list Nothing but Team Members List
  const spaceMembersList = async () => {
    let spaceId = shareSpaceId || getSpaceId();
    const response = await spacesService.membersList(spaceId);
    setSpaceMembersData(response?.data.data.members);
    setSpaceMemberCount(response?.data.data.members);
    setUpdatedSuggestions(response?.data.data.members);
    const members = response?.data.data.members;
    //TODO: code refactor required
    dispatch(activeTeamMembersInfo(members));
  };

  const getMembersList = async () => {
    const response = await workspaceService.allUsersList();
    const data = response?.data?.data?.users;
    setMembersData(data);
  };

  const aboutSpaceScreen = () => {
    setOpenAboutSpacesScreen(true);
  };

  const MembersScreen = () => {
    setOpenAboutSpacesScreen(false);
  };

  const onEnterSearchText = (event) => {
    let updatedSuggestions = spaceMembersData;
    updatedSuggestions = updatedSuggestions.filter(function (item) {
      return item.user.name.toLowerCase().indexOf(event.toLowerCase()) !== -1;
    });
    setUpdatedSuggestions(updatedSuggestions);
  };

  const findExistedMember = (option) => {
    return spaceMembersData.find((element) => element.user.id == option.user.id);
  };

  const onlistItemClick = (option) => {
    setEnableBtn(enableBtn + 1);

    const found = findExistedMember(option);
    if (found) {
      const selectedMembers = spaceMembersData.filter((item) => {
        return item.user.id != option.user.id;
      });
      setSpaceMembersData(selectedMembers);
    } else {
      setSpaceMembersData([...spaceMembersData, option]);
    }
    //required for reference
    // listData = [...listData, option];
  };

  const addMembersToSpacecall = async () => {
    let selectedUserListArray = [];
    listData = spaceMembersData;
    const spaceOwner = spaceMembersData.find((item) => {
      return item.roles.includes('OWNER');
    });
    if (spaceOwner) {
      selectedUserListArray = listData.map((item) => {
        if (spaceOwner.userId === item.userId) {
          return { userId: item.userId, roles: ['OWNER'] };
        } else {
          return { userId: item.userId, roles: ['EDITOR'] };
        }
        //required for reference
        // roles: [...item.roles];
      });

      let spaceId = shareSpaceId || getSpaceId();

      const response = await spacesService.addMembersToSpace(spaceId, selectedUserListArray);
      spaceMembersList();
      if (response.status === 200 || 201) dispatchSetSnackbar({ message: response.data.message });

      setEnableBtn(0);
      dispatch(reloadSpaces());
    } else {
      spaceMembersList();
      dispatchSetSnackbar({ message: 'Do not remove owner of space' });
    }
  };

  const dialogHeadingSection = (
    <div className={classes.dialogHeader}>
      <div> &nbsp;</div>
      <div className={classes.headerName}> Share {shareSpaceName || spaceName}</div>
      <IconButton
        edge="end"
        color="inherit"
        style={{ marginRight: 12 }}
        aria-label="close"
        onClick={closeDialog}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );

  const aboutTabHeading = (
    <div className={classes.infoContainer}>
      <img src={infoIcon} alt="info" style={{ paddingRight: '10px' }} />
      {aboutHeadingText}
    </div>
  );

  const addMemberSection = (
    <div className={classes.addMemberContainer}>
      <div className={classes.subtitle}>Add people</div>
      <ClickAwayListener onClickAway={hideListItems}>
        <div className={classes.listContainer}>
          <Select
            variant="outlined"
            renderValue={() => displayText}
            displayEmpty={true}
            fullWidth
            classes={{ root: classes.membersSelect }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            <ListItems
              items={membersData}
              selectedList={spaceMembersData}
              onlistItemClick={onlistItemClick}
            />
          </Select>
          <Button
            disabled={enableBtn === 0}
            onClick={addMembersToSpacecall}
            className={enableBtn === 0 ? classes.disableBtn : classes.addBtn}
            style={{ color: 'white' }}
          >
            ADD
          </Button>
        </div>
      </ClickAwayListener>
    </div>
  );

  const aboutTabButton = (
    <div
      onClick={aboutSpaceScreen}
      className={openAboutSpacesScreen ? classes.activeScreen : classes.unactiveScreen}
    >
      Invite Link
    </div>
  );

  const membersTabButton = (
    <div
      onClick={MembersScreen}
      className={openAboutSpacesScreen ? classes.unactiveScreen : classes.activeScreen}
    >
      Members {spaceMemberCount.length}
    </div>
  );

  const tabsButtons = (
    <div className={classes.toggleContainer}>
      {aboutTabButton}
      {isAuthUser && membersTabButton}
    </div>
  );

  const shareCanvasHeadingText = 'Invite as guest to this Space';

  const aboutTabContents = (
    <div className={classes.innerContainer}>
      {/* //INFO: commented code was related to Teams implementation current version , teams UI no needed */}
      {/* {isAuthUser && <ShareJoinSpaceLinkContentContainer spaceId={shareSpaceId} />}
      {isAuthUser && aboutTabHeading}
      {isAuthUser && addMemberSection} */}
      <div className={classes.shareCanvasContainer}>
        {/* <Typography variant="h4">{shareCanvasHeadingText}</Typography>
        <div className={classes.shareCanvasContent}>
          <ShareUsingQRContainer /> */}
        {/* <ShareUsingCanvasCodeContainer /> */}
        <div style={{ width: '100%' }}>
          <ShareJoinCanvasLinkContentContainer />
        </div>
        {/* </div> */}
      </div>
    </div>
  );

  const membersTabContents = (
    <div className={classes.innerContainer}>
      <div
        style={{
          border: '1px solid rgba(0,0,0,0.12)',
          borderRadius: '13px',
          padding: '16px',
        }}
      >
        <MembersList
          onEnterSearchText={onEnterSearchText}
          suggestions={updatedSuggestions}
          callfunction={callfunction}
          membersCount={spaceMemberCount.length}
        />
      </div>
    </div>
  );

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth={true}
      fullScreen={isMobile ? true : false}
      maxWidth={'sm'}
      PaperProps={{
        style: {
          borderRadius: isMobile ? 0 : 16,
          overflow: 'hidden',
        },
      }}
    >
      {dialogHeadingSection}
      {tabsButtons}
      {openAboutSpacesScreen ? aboutTabContents : membersTabContents}
    </Dialog>
  );
};
export default NewShareDialog;
