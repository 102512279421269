import React from 'react';
import classes from './Topbar.module.css';
import SearchBox from '../../common/UI/searchBox/SearchBox';

const Topbar = ({ onEnterSearchText, shortButton, title }) => {
  return (
    <>
      <div className={classes.pageTopbarContainer}>
        <h3 className={classes.title}>{title}</h3>
        <div className={classes.rightContainer}>
          <div>
            <SearchBox onEnterSearchText={onEnterSearchText} />
          </div>
          <div>
            <>{shortButton}</>
          </div>
          {/* <div>filter</div> */}
        </div>
      </div>
    </>
  );
};

export default Topbar;
