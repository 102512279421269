import React from 'react';

const PenTool = (props) => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <g clipPath="url(#clip1)">
        <path
          d="M19.025 6C19.4704 6 19.8737 6.27242 20.0418 6.68725C20.4367 7.64074 21.1299 9.33103 21.4114 10.0245C21.4996 10.2288 21.5 10.4702 21.5 10.6931L21.5 13.5L16.5 13.5L16.5 10.6931C16.5 10.4764 16.542 10.2536 16.6261 10.0493C16.9118 9.35582 17.605 7.65932 17.9957 6.69964C18.168 6.27243 18.5755 6 19.025 6Z"
          fill={props.fill}
        />
        <path
          d="M13.9869 23.0279C13.9869 23.0279 9.92258 30.0685 9 31.4688L9 49.75L29 49.75L29 31.4688C27.8957 29.7645 23.9608 23.0279 23.9608 23.0279L23.9608 15.3347C23.9608 14.1505 23.1522 13.1876 22.1549 13.1876L15.7929 13.1876C15.312 13.1876 14.8525 13.4143 14.5141 13.8171C14.1757 14.2198 13.9869 14.7666 13.9869 15.3347L13.9869 23.0279Z"
          fill="white"
        />
        <path d="M29 49.75L29 31.4688L9 31.4688L9 49.75L29 49.75Z" fill={props.fill} />
        <path
          d="M13.9869 23.0279C13.9869 23.0279 9.92258 30.0685 9 31.4688L19 31.4688L19 13.1876L15.7929 13.1876C15.312 13.1876 14.8525 13.4143 14.5141 13.8171C14.1757 14.2198 13.9869 14.7666 13.9869 15.3347L13.9869 23.0279Z"
          fill="url(#paint0_linear)"
          fillOpacity="0.8"
        />
        <path
          d="M24.0131 23.0279C24.0131 23.0279 28.0774 30.0685 29 31.4688L19 31.4688L19 13.1876L22.2071 13.1876C22.688 13.1876 23.1475 13.4143 23.4859 13.8171C23.8243 14.2198 24.0131 14.7666 24.0131 15.3347L24.0131 23.0279Z"
          fill="url(#paint1_linear)"
          fillOpacity="0.8"
        />
        <path
          d="M19 31.4688H29V49.75H19L19 31.4688Z"
          fill="url(#paint2_linear)"
          fillOpacity="0.8"
        />
        <path d="M9 49.75V31.4688H19V49.75H9Z" fill="url(#paint3_linear)" fillOpacity="0.8" />
        <path
          d="M16.5 13.5C16.5 13.5 16.5 11.7085 16.5 10.6931C16.5 10.4764 16.542 10.2536 16.6261 10.0493C16.9118 9.35582 17.605 7.65932 17.9957 6.69964C18.168 6.27243 18.5755 6 19.025 6C19.4704 6 19.8737 6.27242 20.0418 6.68725C20.4367 7.64074 21.1299 9.33103 21.4114 10.0245C21.4996 10.2288 21.5 10.4702 21.5 10.6931L21.5 13.5"
          stroke="black"
        />
        <path
          d="M13.9869 22.958C13.9869 22.958 10.31 29.4037 9.15688 31.2529C9.05437 31.4172 9 31.6031 9 31.7968L9 50L29 50L29 31.7926C29 31.6015 28.9468 31.4175 28.8456 31.2553C27.5707 29.2121 23.9608 22.958 23.9608 22.958L23.9608 15.1727C23.9608 13.9744 23.1522 13 22.1549 13L15.7929 13C15.312 13 14.8525 13.2294 14.5141 13.637C14.1757 14.0445 13.9869 14.5978 13.9869 15.1727L13.9869 22.958Z"
          stroke="black"
        />
        <path d="M9 31.4688H29" stroke="black" />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="15.875"
        y1="20.9219"
        x2="19"
        y2="20.9219"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DDDDDD" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="22.125"
        y1="20.9219"
        x2="19"
        y2="20.9219"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DDDDDD" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="21.5"
        y1="22.0333"
        x2="19"
        y2="22.0333"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.2" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="16.5"
        y1="22.0333"
        x2="19"
        y2="22.0333"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.2" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="38" height="38" fill="white" />
      </clipPath>
      <clipPath id="clip1">
        <rect width="24" height="43" fill="white" transform="translate(7 4)" />
      </clipPath>
    </defs>
  </svg>
);

export default PenTool;
