import { useSelector } from 'react-redux';

export const useInvitesSelector = () => {
  const pendingRequests = useSelector((state) => state.invites.pendingRequests);
  const isJoinWorkspaceInviteDialogOpen = useSelector(
    (state) => state.invites.isJoinWorkspaceInviteDialogOpen,
  );
  const isJoinSpaceInviteDialogOpen = useSelector(
    (state) => state.invites.isJoinSpaceInviteDialogOpen,
  );
  return { isJoinWorkspaceInviteDialogOpen, isJoinSpaceInviteDialogOpen, pendingRequests };
};
