import React from 'react';
const RupeeIcon = () => (
  <span
    style={{
      fontFamily: "lato,ubuntu,helvetica,sans-serif",
      WebkitFontSmoothing: "auto",
    }}
  >
    &#8377;
  </span>
);

export default RupeeIcon;