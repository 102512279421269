import { useDispatch } from 'react-redux';
import {
  hideSpinner,
  resetSnackbar,
  setSnackbar,
  showSpinner,
  hideLicenseExhaustedDialog,
  showLicenseExhaustedDialog,
  showFeatureLockedDialog,
  hideFeatureLockedDialog,
  showMaxSimultaneousUsageLimitExhaustedDialog,
  hideMaxSimultaneousUsageLimitExhaustedDialog,
  showDeviceLicenseExhaustedDialog,
  hideDeviceLicenseExhaustedDialog,
  showHuddleSessionLimitExhaustedDialog,
  hideHuddleSessionLimitExhaustedDialog,
} from '../actions/notification.action';

/**
 *
 * @returns {{
 *  dispatchResetSnackbar: () => void,
 *  dispatchSetSnackbar: (payload: {message: string}) => void,
 *  dispatchShowSpinner: () => void,
 *  dispatchHideSpinner: () => void
 * }}
 */
export const useNotificationDispatcher = () => {
  const dispatch = useDispatch();

  const dispatchResetSnackbar = () => {
    dispatch(resetSnackbar());
  };

  /**
   * @param {{ message: string }} payload
   */
  const dispatchSetSnackbar = (payload) => {
    dispatch(setSnackbar(payload));
  };

  const dispatchShowSpinner = () => {
    dispatch(showSpinner());
  };

  const dispatchHideSpinner = () => {
    dispatch(hideSpinner());
  };

  const dispatchShowLicenseExhaustedDialog = () => {
    dispatch(showLicenseExhaustedDialog());
  };

  const dispatchHideLicenseExhaustedDialog = () => {
    dispatch(hideLicenseExhaustedDialog());
  };

  /**
   *
   * @param {{
   *   feature: string
   * }} payload
   */
  const dispatchShowFeatureLockedDialog = (payload) => {
    dispatch(showFeatureLockedDialog(payload));
  };

  const dispatchHideFeatureLockedDialog = () => {
    dispatch(hideFeatureLockedDialog());
  };

  const dispatchShowMaxSimultaneousLicenseExhaustedDialog = () => {
    dispatch(showMaxSimultaneousUsageLimitExhaustedDialog());
  };

  const dispatchHideMaxSimultaneousLicenseExhaustedDialog = () => {
    dispatch(hideMaxSimultaneousUsageLimitExhaustedDialog());
  };

  const dispatchShowDeviceLicenseExhaustedDialog = () => {
    dispatch(showDeviceLicenseExhaustedDialog());
  };

  const dispatchHideDeviceLicenseExhaustedDialog = () => {
    dispatch(hideDeviceLicenseExhaustedDialog());
  };


  const dispatchShowHuddleSessionExhaustedDialog = () => {
    dispatch(showHuddleSessionLimitExhaustedDialog());
  };

  const dispatchHideHuddleSessionExhaustedDialog = () => {
    dispatch(hideHuddleSessionLimitExhaustedDialog());
  };

  /**
   *
   * @param {{
   *   feature: string
   * }} payload
   */
  const dispatchShowSimultaneousLimitExhaustedDialog = (payload) => {
    dispatch(showMaxSimultaneousUsageLimitExhaustedDialog(payload));
  };

  return {
    dispatchHideFeatureLockedDialog,
    dispatchHideLicenseExhaustedDialog,
    dispatchHideSpinner,
    dispatchResetSnackbar,
    dispatchSetSnackbar,
    dispatchShowFeatureLockedDialog,
    dispatchShowLicenseExhaustedDialog,
    dispatchShowSimultaneousLimitExhaustedDialog,
    dispatchShowSpinner,
    dispatchShowMaxSimultaneousLicenseExhaustedDialog,
    dispatchHideMaxSimultaneousLicenseExhaustedDialog,
    dispatchShowDeviceLicenseExhaustedDialog,
    dispatchHideDeviceLicenseExhaustedDialog,
    dispatchShowHuddleSessionExhaustedDialog,
    dispatchHideHuddleSessionExhaustedDialog
  };
};
