import ACTION_TYPE from './type.action';

/**
 *
 * @param {string[]} payload
 * @returns
 */
export const setOutlookImportedEvents = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_OUTLOOK_IMPORTED_EVENTS,
    payload,
  });
};

/**
 *
 * @returns
 */
export const resetOutlookImportedEvents = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.RESET_OUTLOOK_IMPORTED_EVENTS,
  });
};

/**
 *
 * @param {{ id: string }} payload
 * @returns
 */
export const addOutlookImportedEvent = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.ADD_OUTLOOK_IMPORTED_EVENT,
    payload,
  });
};

/**
 *
 * @param {{ id: string }} payload
 * @returns
 */
export const removeOutlookImportedEvent = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.REMOVE_OUTLOOK_IMPORTED_EVENT,
    payload,
  });
};
