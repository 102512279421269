import ACTION_TYPES from '../actions/type.action';

const initialState = {
	selectPen: false,
	selectHighlighter: false,
	selectEraser: false,
	selectSelect: false,
	selectText: false,
	selectShapes: false,
	selectMedia: false,
	penColor: '#3075D2',
	penSize: 4,
	highlighterColor: '#F9DC4B',
	highlighterSize: 30,
	brushOpacity: 1,
	brushSize: 2,
	clearCanvas: false,
	showLoader: false,
	loaderText: 'Loading . . .'
};

const reducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case ACTION_TYPES.SELECT_PEN:
			const selectedPenBrushSize = state.penSize;
			return {
				...state,
				selectPen: true,
				selectHighlighter: false,
				selectEraser: false,
				selectSelect: false,
				selectText: false,
				selectShapes: false,
				selectMedia: false,
				brushOpacity: 1,
				brushSize: selectedPenBrushSize
			};
		case ACTION_TYPES.SELECT_HIGHLIGHTER:
			const selectedBrushSize = state.highlighterSize;
			return {
				...state,
				selectPen: false,
				selectHighlighter: true,
				selectEraser: false,
				selectSelect: false,
				selectText: false,
				selectShapes: false,
				selectMedia: false,
				brushOpacity: 0.4,
				brushSize: selectedBrushSize
			};
		case ACTION_TYPES.SELECT_ERASER:
			return {
				...state,
				selectPen: false,
				selectHighlighter: false,
				selectEraser: true,
				selectSelect: false,
				selectText: false,
				selectShapes: false,
				selectMedia: false,
				brushOpacity: 1,
				brushSize: 4
			};
		case ACTION_TYPES.SELECT_SELECT_TOOL:
			return {
				...state,
				selectPen: false,
				selectHighlighter: false,
				selectEraser: false,
				selectSelect: true,
				selectText: false,
				selectShapes: false,
				selectMedia: false,
				brushOpacity: 1,
				brushSize: 2
			};
		case ACTION_TYPES.SELECT_TEXT_TOOL:
			return {
				...state,
				selectPen: false,
				selectHighlighter: false,
				selectEraser: false,
				selectSelect: false,
				selectText: true,
				selectShapes: false,
				selectMedia: false
			};
		case ACTION_TYPES.SELECT_MEDIA:
			return {
				...state,
				selectPen: false,
				selectHighlighter: false,
				selectEraser: false,
				selectSelect: false,
				selectText: false,
				selectShapes: false,
				selectMedia: true
			};
		case ACTION_TYPES.SELECT_SHAPES:
			let selectedShapeBrushSize = state.penSize;
			return {
				...state,
				selectPen: false,
				selectHighlighter: false,
				selectEraser: false,
				selectSelect: false,
				selectText: false,
				selectShapes: true,
				selectMedia: false,
				brushOpacity: 1,
				brushSize: selectedShapeBrushSize
			};
		case ACTION_TYPES.SELECT_PEN_COLOR:
			return {
				...state,
				penColor: payload
			};
		case ACTION_TYPES.SELECT_PEN_SIZE:
			return {
				...state,
				penSize: payload,
				brushSize: payload
			};
		case ACTION_TYPES.SELECT_HIGHLIGHTER_COLOR:
			return {
				...state,
				highlighterColor: payload
			};
		case ACTION_TYPES.SELECT_HIGHLIGHTER_SIZE:
			return {
				...state,
				highlighterSize: payload,
				brushSize: payload
			};
		case ACTION_TYPES.CLEAR_CANVAS:
			return {
				...state,
				clearCanvas: payload
			};
		case ACTION_TYPES.SHOW_LOADER:
			return {
				...state,
				showLoader: payload
			};
		case ACTION_TYPES.SET_LOADER_TEXT:
			return {
				...state,
				loaderText: payload
			};

		default:
			return { ...state };
	}
};

export default reducer;
