import React from 'react';
import { CalendarSelection } from './component';

export const CalendarSelectionContainer = (props) => {
  const {
    scheduleFilter,
    setTodaysScheduleFilter,
    setUpcomingScheduleFilter,
    openDialog,
    openImportOutlookCalendarEventDialog,
    openSetupZoomAccountDialog,
    handleSelectedCalendarTab,
    selectedCalendarTab,
  } = props;

  return (
    <CalendarSelection
      scheduleFilter={scheduleFilter}
      setTodaysScheduleFilter={setTodaysScheduleFilter}
      setUpcomingScheduleFilter={setUpcomingScheduleFilter}
      openDialog={openDialog}
      openImportOutlookCalendarEventDialog={openImportOutlookCalendarEventDialog}
      openSetupZoomAccountDialog={openSetupZoomAccountDialog}
      handleSelectedCalendarTab={handleSelectedCalendarTab}
      selectedCalendarTab={selectedCalendarTab}
    />
  );
};
