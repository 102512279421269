import { makeStyles } from '@material-ui/core';

export const useVideoCallLinksButtonStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  button: {
    textTransform: 'none',
    boxShadow: 'none',
    "&:hover": {
      boxShadow: 'none',
      backgroundColor: `rgba(0, 0, 0, 0.04) !important`
    },
  },
}));
