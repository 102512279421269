import React, { useState } from 'react';
// import { Card } from 'material-ui';
import { Button, Card, Menu, MenuItem } from '@material-ui/core';
import classes from './customCode.module.css';
import LessonCardIcon from '../../../assets/spaces/LessonCard.svg';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const ITEM_HEIGHT = 48;

const CustomCard = ({ spaceInfo, openSpace, DuplicateBoard, renameBoard, deleteBoard }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const boardOptionOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleCloseboardOptions = () => {
    setAnchorEl(null);
  };

  const renameSpaceHandler = (data) => {
    handleCloseboardOptions();
    renameBoard(data);
  };

  const deleteSpaceHandler = (data) => {
    handleCloseboardOptions();
    deleteBoard(data);
  };

  const duplicateSpaceHandler = (data) => {
    handleCloseboardOptions();
    DuplicateBoard();
  };
  return (
    <>
      <Card className={classes.cardContainer} onClick={() => openSpace(spaceInfo)}>
        <div style={{ background: '#F4F4F4' }}>
          {/* <img src="#" alt="img" /> */}
          <img
            src={LessonCardIcon}
            style={{
              margin: '30px auto',
              display: 'flex',
              width: 'auto',
            }}
            alt="Lesson-Plan-Img"
          />
        </div>
        <div className={classes.centerContainer}>
          <h3>{spaceInfo.name}</h3>
        </div>
        <div className={classes.rightContainer}>
          <Button className={classes.openButton} variant="contained">
            Open
          </Button>

          <MoreHorizIcon
            fontSize="medium"
            style={{
              color: 'rgba(0,0,0,0.6',
              cursor: 'pointer',
              width: '30px',
              height: '30px',
            }}
            onClick={handleClick}
          />
        </div>
      </Card>

      <Menu
        id={1}
        anchorEl={anchorEl}
        keepMounted
        open={boardOptionOpen}
        onClose={handleCloseboardOptions}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '160px',
          },
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          className="textColor"
          onClick={() => renameSpaceHandler({ spaceInfo, ParentName: 'ParentName' })}
        >
          Rename
        </MenuItem>
        <MenuItem
          className="textColor"
          // disabled={childLength === 1 || spaceInfo.meta.classId === boardData.classId}
          onClick={() => deleteSpaceHandler(spaceInfo)}
        >
          Delete
        </MenuItem>
        <MenuItem className="textColor" onClick={() => duplicateSpaceHandler()}>
          Duplicate
        </MenuItem>
      </Menu>
    </>
  );
};

export default CustomCard;
