import React from 'react';

const MicOnIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 21.75C20.49 21.75 22.5 19.74 22.5 17.25V8.25C22.5 5.76 20.49 3.75 18 3.75C15.51 3.75 13.5 5.76 13.5 8.25V17.25C13.5 19.74 15.51 21.75 18 21.75ZM16.5 8.25C16.5 7.425 17.175 6.75 18 6.75C18.825 6.75 19.5 7.425 19.5 8.25V17.25C19.5 18.075 18.825 18.75 18 18.75C17.175 18.75 16.5 18.075 16.5 17.25V8.25ZM18 24.75C22.14 24.75 25.5 21.39 25.5 17.25H28.5C28.5 22.545 24.585 26.895 19.5 27.63V32.25H16.5V27.63C11.415 26.895 7.5 22.545 7.5 17.25H10.5C10.5 21.39 13.86 24.75 18 24.75Z"
        fill="white"
      />
    </svg>
  );
};

export default MicOnIcon;
