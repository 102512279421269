import { makeStyles } from '@material-ui/core';

export const useBBBViewerStyle = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    right: 0,
    zIndex: 9999,
    [theme.breakpoints.down('md')]: {
      right: 'unset',
      left: 0,
    },
  },
  contentContainer: {
    position: 'relative',
    height: '100%',
    bottom: '50px',
    [theme.breakpoints.down('md')]: {
      position: 'static',
      bottom: 'unset',
    },
  },
  draggableContainer: {
    position: 'fixed',
    bottom: '24rem',
    right: '3rem',
    zIndex: 9999,
    [theme.breakpoints.down('md')]: {
      top: '8px',
      right: 'unset',
      bottom: 'unset',
      left: '40px',
    },
  },
  viewOptionsLayout: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  viewOptionsPosition: {
    position: 'absolute',
    left: '-2rem',
    top: 0,
    bottom: 0,
    width: '2rem',
    height: '10rem',
    backgroundColor: 'black',
    borderTopLeftRadius: '0.5rem',
    borderBottomLeftRadius: '0.5rem',
  },
  fullScreenButton: {
    backgroundColor: 'white',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.5rem',
    height: '1.5rem',
    margin: '0.25rem',
    cursor: 'pointer',
  },
}));
