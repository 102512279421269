import React, { useState } from 'react';
import { useEffect } from 'react';
import { spacesService } from '../../../../../services/spaces.service';
import { getCanvasCode } from '../../../../../Utils/authentication-access';
import { JoinAsHostDialog } from './component';

export const JoinAsHostDialogContainer = (props) => {
  const {
    closeDialog,
    isOpenDialog,
    joinAsHost,
    joinAsHostOnPairedDevice,
    joinAsParticipant,
    joinAsParticipantOnPairedDevice,
  } = props;
  const [isDevicePaired, setIsDevicePaired] = useState(false);
  const title = 'Go to Meeting';
  const areYouHostLabel = 'Are you the Administrator/Host of this meeting?';
  const setupAccountLabel = 'Setup Zoom Account and start meeting as a host';
  const setupAccountLabelAndStartRemote =
    'Setup Zoom Account and start meeting as a host on paired device';

  useEffect(() => {
    const init = async () => {
      const classId = getCanvasCode();
      const isPaired = await spacesService.isPaired(classId);
      setIsDevicePaired(isPaired);
    };
    init();
  });

  return (
    <JoinAsHostDialog
      title={title}
      closeDialog={closeDialog}
      isDevicePaired={isDevicePaired}
      isOpenDialog={isOpenDialog}
      joinAsHost={joinAsHost}
      joinAsHostOnPairedDevice={joinAsHostOnPairedDevice}
      joinAsParticipant={joinAsParticipant}
      joinAsParticipantOnPairedDevice={joinAsParticipantOnPairedDevice}
      areYouHostLabel={areYouHostLabel}
      setupAccountLabel={setupAccountLabel}
      setupAccountLabelAndStartRemote={setupAccountLabelAndStartRemote}
    />
  );
};
