import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import { Close as CloseIcon, Search as SearchIcon } from '@material-ui/icons';

import { useGetNotebooks } from '../../../../services/notebooks.service';
import { useNotificationDispatcher } from '../../../../store/dispatcher/useNotificationDispatcher';
import NotebookCard from './notebook-card';

const useStyles = ({ searchValue }) =>
  makeStyles((theme) => ({
    moveThreadDialogRoot: {
      '& .MuiPaper-root': {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        height: 560,
        maxHeight: '80vh',
        borderRadius: 16,
        padding: '16px 32px',
        [theme.breakpoints.down('sm')]: {
          height: '100vh',
          maxHeight: '100vh',
        },
        '& .i-dialog-header': {
          display: 'flex',
          alignItems: 'center',
          '& .MuiTypography-root': {
            textAlign: 'center',
            fontSize: 20,
            fontWeight: 500,
            color: '#000000DE',
          },
        },
      },
      '& .i-new-notebook': {
        fontSize: 14,
        alignSelf: 'start',
      },
    },
    searchContainer: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      flex: 1,
      borderRadius: 12,
      border: '1px solid #0000001F',
      padding: 16,
      '& .MuiInputBase-root': {
        backgroundColor: searchValue ? '#FFF' : '#0000001F',
        '&.Mui-focused': {
          backgroundColor: '#FFF',
        },
      },
    },
    notebookList: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      overflow: 'auto',
    },
  }));

const MoveThreadDialog = ({ threadName, onClose, onMove, onNewNotebookClick, ...props }) => {
  const { dispatchShowSpinner, dispatchHideSpinner } = useNotificationDispatcher();
  const [searchValue, setSearchValue] = useState('');
  const classes = useStyles({ searchValue })();
  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { data: notebooks, isFetching: isGetNotebooksLoading } = useGetNotebooks();

  useEffect(() => {
    isGetNotebooksLoading ? dispatchShowSpinner() : dispatchHideSpinner();
  }, [isGetNotebooksLoading, dispatchShowSpinner, dispatchHideSpinner]);

  const searchChangeHandler = (e) => setSearchValue(e.target.value);

  const filteredNotebooks = notebooks?.filter(({ name }) =>
    name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const moveHandler = async (notebookId) => {
    onClose();
    await onMove(notebookId);
  };

  return (
    <Dialog
      className={classes.moveThreadDialogRoot}
      open={true}
      onClose={onClose}
      maxWidth="sm"
      fullScreen={fullScreen}
      fullWidth
      {...props}
    >
      <div className="i-dialog-header">
        <Typography style={{ flex: 1 }}>Move Thread</Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon htmlColor="#000000DE" />
        </IconButton>
      </div>
      <Divider style={{ margin: '16px -32px 24px' }} />
      <div className={classes.searchContainer}>
        <TextField
          value={searchValue}
          onChange={searchChangeHandler}
          variant="outlined"
          size="small"
          placeholder="Search here..."
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <div className={classes.notebookList}>
          {filteredNotebooks?.map(({ id, name }) => (
            <NotebookCard key={id} id={id} title={name} onClick={moveHandler} />
          ))}
        </div>
      </div>
      <Button className="i-new-notebook" color="primary" onClick={onNewNotebookClick}>
        + Create new notebook
      </Button>
    </Dialog>
  );
};

export default MoveThreadDialog;
