import { Box, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ScheduleFilterDialogContainer } from '../schedule-filter-dialog/container';
import { ScheduleMeetingOptionsDialogContainer } from '../schedule-meeting-options-dialog/container';
import { ContentContainer, useCalendarSelectionStyle } from './component.style';
import { CalendarSelectionTabsContainer } from './calendar-selection-tabs/container';
import { CALENDAR_TABS } from './use-calendar-selection';
import { checkIsAuthUser } from '../../../../Utils/authentication-access';
const isAuthUser = checkIsAuthUser();

export const CalendarSelection = (props) => {
  const {
    scheduleFilter,
    setTodaysScheduleFilter,
    setUpcomingScheduleFilter,
    openDialog,
    openImportOutlookCalendarEventDialog,
    openSetupZoomAccountDialog,
    handleSelectedCalendarTab,
    selectedCalendarTab,
  } = props;

  const [userAuthCheck, setUserAuthCheck] = useState();

  useEffect(() => {
    const isAuthUser = checkIsAuthUser();
    setUserAuthCheck(isAuthUser);
  }, []);

  const classes = useCalendarSelectionStyle();
  const isSmallMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const calendarSelectionTabs = (
    <CalendarSelectionTabsContainer
      selectedCalendarTab={selectedCalendarTab}
      handleSelectedCalendarTab={handleSelectedCalendarTab}
    />
  );

  const shouldShowSpaceCalendarOptions = selectedCalendarTab === CALENDAR_TABS.SPACE;

  const spaceCalendarOptions = shouldShowSpaceCalendarOptions ? (
    <Box style={{ borderBottom: '1px solid rgb(224, 224, 224)' }}>
      <div className={classes.topContainer}>
        <ScheduleFilterDialogContainer
          scheduleFilter={scheduleFilter}
          setTodaysScheduleFilter={setTodaysScheduleFilter}
          setUpcomingScheduleFilter={setUpcomingScheduleFilter}
        />
        {isAuthUser && (
          <ScheduleMeetingOptionsDialogContainer
            openManuallyScheduleDialog={openDialog}
            openImportOutlookCalendarEventDialog={openImportOutlookCalendarEventDialog}
            openSetupZoomAccountDialog={openSetupZoomAccountDialog}
          />
        )}
      </div>
    </Box>
  ) : (
    <></>
  );

  return (
    <ContentContainer style={{ width: `${isSmallMobile ? '19rem' : '30rem'}` }}>
      {userAuthCheck && calendarSelectionTabs}
      {spaceCalendarOptions}
    </ContentContainer>
  );
};
