import React, { useCallback, useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';

import { brightAIIcon } from '../../../assets/icons';

const VideoOptionsButton = ({ toggleChat }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const mouseDownHandler = useCallback((e) => e.stopPropagation(), []);
  const openMenuHandler = useCallback((e) => setAnchorEl(e.currentTarget), []);
  const closeMenuHandler = useCallback(() => setAnchorEl(null), []);
  const toggleChatHandler = useCallback(() => {
    closeMenuHandler();
    toggleChat();
  }, [closeMenuHandler, toggleChat]);

  return (
    <div onMouseDown={mouseDownHandler}>
      <IconButton size="small" onMouseDown={mouseDownHandler} onClick={openMenuHandler}>
        <img src={brightAIIcon} alt="Bright AI" width={24} height={24} />
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenuHandler}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
      >
        <MenuItem onMouseDown={mouseDownHandler} onClick={toggleChatHandler}>
          Chat with Video
        </MenuItem>
      </Menu>
    </div>
  );
};

export default VideoOptionsButton;
