import ACTION_TYPES from '../actions/type.action';

const initialState = {
  transformFactor: 1,
  stages: [],
  currentPageId: undefined,
  presentationMode: false,
  showPopUp: false,
  presenters: [],
  scale: 1,
  position: { x: 0, y: 0 },
  isFullscreen: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  let presenters = [];

  switch (type) {
    case ACTION_TYPES.UPDATE_TRANSFORM_FACTOR:
      return {
        ...state,
        transformFactor: payload,
      };
    case ACTION_TYPES.ADD_STAGE:
      return {
        ...state,
        stages: payload,
      };
    case ACTION_TYPES.CHANGE_PRESENTATION_MODE:
      return {
        ...state,
        presentationMode: payload,
      };
    case ACTION_TYPES.STORE_PRESENTER_INFO:
      if (
        !state.presenters.find(
          (presenter) =>
            presenter.userId === payload.userId && presenter.userTag === payload.userTag,
        )
      ) {
        presenters = [...state.presenters, payload];
      }
      return {
        ...state,
        presenters,
      };
    case ACTION_TYPES.RESET_PRESENTER_INFO:
      presenters = state.presenters.filter(
        (presenter) => presenter.userId !== payload.userId && presenter.userTag === payload.userTag,
      );
      return {
        ...state,
        presenters,
      };
    case ACTION_TYPES.RESET_ALL_PRESENTER_INFO:
      return {
        ...state,
        presenters: [],
      };
    case ACTION_TYPES.SHOW_CREATE_CANVAS_POPUP:
      return {
        ...state,
        showPopUp: payload,
      };
    case ACTION_TYPES.SET_CANVAS_SCALE:
      return { ...state, scale: payload };
    case ACTION_TYPES.SET_CANVAS_POSITION:
      return { ...state, position: payload };
    case ACTION_TYPES.SET_CURRENT_PAGE_ID:
      return { ...state, currentPageId: payload };
    case ACTION_TYPES.SET_FULLSCREEN:
      return { ...state, isFullscreen: payload };

    default:
      return { ...state };
  }
};

export default reducer;
