import { getWithHeader, put } from '../Utils/api';
import { AUTHENTICATION } from '../Utils/url-builder';

class UserService {
  getUserDetails = async () => {
    try {
      const url = AUTHENTICATION.userProfile();
      return await getWithHeader(url);
    } catch (error) {
      console.log('User Details Error', error);
    }
  };

  editUserDetails = async (formData) => {
    try {
      const url = AUTHENTICATION.editProfile();
      return await put(url, formData);
    } catch (error) {
      console.log('error', error);
    }
  };
}

const userService = new UserService();
export default userService;
