import React from 'react';

const FilesOutlinedIcon = (props) => {
  let stroke = props.stroke ? props.stroke : '#000';
  let fillOpacity = props.stroke ? 1 : 0.38;

  return (
    // <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path
    //     d="M12.7365 13.1398C11.9554 12.3588 11.9554 11.0924 12.7365 10.3114L21.2218 1.82609C22.0028 1.04504 23.2691 1.04504 24.0502 1.82609L32.5355 10.3114C33.3165 11.0924 33.3165 12.3588 32.5355 13.1398L24.0502 21.6251C23.2691 22.4061 22.0028 22.4061 21.2218 21.6251L12.7365 13.1398Z"
    //     fill={fill}
    //   />
    //   <path
    //     d="M0 11.7344L9.908 1.82637C10.689 1.04533 11.9554 1.04533 12.7364 1.82637L21.2217 10.3117C22.0028 11.0927 22.0028 12.359 21.2217 13.1401L12.7279 21.6339C11.9469 22.4149 10.6805 22.4149 9.89949 21.6339L0 11.7344Z"
    //     fill={fill}
    //   />
    //   <path
    //     fill-rule="evenodd"
    //     clip-rule="evenodd"
    //     d="M12.6434 10.4049C11.9131 11.1352 11.9131 12.3192 12.6434 13.0495L16.9779 17.384L21.3142 13.0478C22.0441 12.3179 22.0441 11.1345 21.3142 10.4046L16.9789 6.06934L12.6434 10.4049Z"
    //     fill="black"
    //     fill-opacity="0.32"
    //   />
    // </svg>

    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.38775 15.1786C8.38775 13.6411 9.63407 12.3948 11.1715 12.3948H26.8316C28.369 12.3948 29.6153 13.6411 29.6153 15.1786V27.1428C29.6153 28.6802 28.369 29.9266 26.8316 29.9266H11.1715C9.63407 29.9266 8.38775 28.6802 8.38775 27.1428V15.1786Z"
        stroke={stroke}
        stroke-width="0.947368"
      />
      <path
        d="M21.693 15.7874C22.124 15.7874 22.4734 16.1368 22.4734 16.5679C22.4734 16.9989 22.124 17.3483 21.693 17.3483H12.4836C12.0525 17.3483 11.7031 16.9989 11.7031 16.5679C11.7031 16.1368 12.0525 15.7874 12.4836 15.7874H21.693Z"
        fill={stroke}
      />
      <path
        d="M12.4836 20.4204H18.6231C19.0542 20.4204 19.4036 20.0709 19.4036 19.64C19.4036 19.2089 19.0542 18.8595 18.6231 18.8595H12.4836C12.0525 18.8595 11.7031 19.2089 11.7031 19.64C11.7031 20.0709 12.0525 20.4204 12.4836 20.4204Z"
        fill={stroke}
      />
      <path
        d="M12.4836 23.4885H15.5534C15.9845 23.4885 16.3339 23.1392 16.3339 22.7081C16.3339 22.277 15.9845 21.9276 15.5534 21.9276H12.4836C12.0525 21.9276 11.7031 22.277 11.7031 22.7081C11.7031 23.1392 12.0525 23.4885 12.4836 23.4885Z"
        fill={stroke}
      />
      <path
        d="M13.2871 8.52222C13.2871 8.01194 13.7008 7.59827 14.2111 7.59827H23.4506C23.9609 7.59827 24.3746 8.01194 24.3746 8.52222C24.3746 9.03251 23.9609 9.44618 23.4506 9.44618H14.2111C13.7008 9.44618 13.2871 9.03251 13.2871 8.52222Z"
        fill={stroke}
      />
    </svg>
  );
};

export default FilesOutlinedIcon;
