import axios from 'axios';

import { getToken } from '../Utils/authentication-access';
import { LICENSE_EXCEPTION_TYPES } from '../constants/license.constants';
import store from '../store';
import { HTTP_CODES } from '../constants/http-status.constants';
import {
  showMaxSimultaneousUsageLimitExhaustedDialog,
  showFeatureLockedDialog,
  showLicenseExhaustedDialog,
  setSnackbar,
} from '../store/actions/notification.action';

// DEFAULT TIMEOUT OF 30s
const httpClient = axios.create({ timeout: 30000 });

class BaseService {
  constructor(baseUrl = '') {
    this.baseUrl = baseUrl;
  }

  getHeaders() {
    return {
      'Content-Type': 'application/json',
      access_token: localStorage.getItem('usertoken'),
      Authorization: `Bearer ${getToken()}`,
    };
  }

  setBaseUrl = (baseUrl) => {
    this.baseUrl = baseUrl;
    return this;
  };

  async makeGetRequest(endPoint, options = {}) {
    try {
      const url = (this.baseUrl || '') + endPoint;
      const headers = { ...this.getHeaders(), ...options.headers };
      const res = await httpClient.get(url, { headers, params: options.params });
      return res.data;
    } catch (error) {
      this.handleError(error, options.customMessage);
      throw error;
    }
  }

  async makePostRequest(endPoint, data, options = {}) {
    try {
      const url = (this.baseUrl || '') + endPoint;
      const headers = { ...this.getHeaders(), ...options.headers };
      const res = await httpClient.post(url, data, { headers });
      return res.data;
    } catch (error) {
      this.handleError(error, options.customMessage);
      throw error;
    }
  }

  async makePutRequest(endPoint, data, options = {}) {
    try {
      const url = this.baseUrl + endPoint;
      const headers = { ...this.getHeaders(), ...options.headers };
      const res = await httpClient.put(url, data, { headers });
      return res.data;
    } catch (error) {
      this.handleError(error, options.customMessage);
      throw error;
    }
  }

  async makeDeleteRequest(endPoint, options = {}) {
    try {
      const url = this.baseUrl + endPoint;
      const headers = { ...this.getHeaders(), ...options.headers };
      const res = await httpClient.delete(url, { params: options.params, headers });
      return res.data;
    } catch (error) {
      this.handleError(error, options.customMessage);
      throw error;
    }
  }

  handleError(error, customMessage = 'Request Failed') {
    if (!error.response) {
      store.dispatch(setSnackbar({ message: customMessage }));
      return;
    }
    console.log(error, 'error');
    console.log(error.response, 'error.response');
    if (error.response?.status == HTTP_CODES.NOT_ACCEPTABLE) {
      const feature = error.response.data.feature;
      if (error.response.data.type === LICENSE_EXCEPTION_TYPES.FEATURE_LIMIT_EXHAUSTED) {
        console.log(
          `handling error for ${feature}: ${LICENSE_EXCEPTION_TYPES.FEATURE_LIMIT_EXHAUSTED}}`,
        );
        store.dispatch(showLicenseExhaustedDialog({ feature }));
      } else if (error.response.data.type === LICENSE_EXCEPTION_TYPES.FEATURE_NOT_ENABLED) {
        console.log(
          `handling error for ${feature}: ${LICENSE_EXCEPTION_TYPES.FEATURE_NOT_ENABLED}}`,
        );
        store.dispatch(showFeatureLockedDialog({ feature }));
      } else if (
        error.response.data.type === LICENSE_EXCEPTION_TYPES.SIMULTANEOUS_LIMIT_EXHAUSTED
      ) {
        console.log(
          `handling error for ${feature}: ${LICENSE_EXCEPTION_TYPES.SIMULTANEOUS_LIMIT_EXHAUSTED}}`,
        );
        store.dispatch(showMaxSimultaneousUsageLimitExhaustedDialog({ feature }));
      } else if (error.response.data.type === LICENSE_EXCEPTION_TYPES.LICENSE_NOT_FOUND) {
        console.log(`handling error for ${feature}: ${LICENSE_EXCEPTION_TYPES.LICENSE_NOT_FOUND}}`);
        store.dispatch(showFeatureLockedDialog({ feature }));
      } else {
        store.dispatch(setSnackbar({ message: error.response.data?.message || customMessage }));
      }
    } else {
      store.dispatch(setSnackbar({ message: error.response.data?.message || customMessage }));
    }
  }
}

export default BaseService;
export const baseService = new BaseService();
