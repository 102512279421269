import React, { useCallback } from 'react';
import { Button, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  questionListRoot: {
    '& .i-questions-title': {
      fontSize: 14,
      fontWeight: 700,
    },
    '& .i-question': {
      color: '#536CF9',
      '& .MuiTypography-root': {
        color: '#536CF9',
        fontSize: 14,
        textAlign: 'left',
      },
    },
  },
});

const Question = ({ question, onQuestionClick }) => {
  const questionClickHandler = useCallback(
    () => onQuestionClick(question),
    [onQuestionClick, question],
  );

  return (
    <li className="i-question">
      <Button variant="text" onClick={questionClickHandler}>
        <Typography>{question}</Typography>
      </Button>
    </li>
  );
};

const QuestionList = ({ list, onQuestionClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.questionListRoot}>
      <Typography className="i-questions-title">
        Here are the questions you might be asking:
      </Typography>
      <ol>
        {list.map((question, index) => (
          <Question key={index} question={question} onQuestionClick={onQuestionClick} />
        ))}
      </ol>
    </div>
  );
};

export default React.memo(QuestionList);
