import { useDispatch } from 'react-redux';
import {
  acceptAllInvites,
  acceptInvite,
  rejectInvite,
  fetchPendingRequests,
  showJoinWorkspaceInviteDialog,
  hideJoinWorkspaceInviteDialog,
  hideJoinSpaceInviteDialog,
  showJoinSpaceInviteDialog,
} from '../actions/invites.action';

const useInvitesDispatcher = () => {
  const dispatch = useDispatch();

  const dispatchAcceptInvite = (requestId) => {
    dispatch(acceptInvite(requestId));
  };

  const dispatchRejectInvite = (requestId) => {
    dispatch(rejectInvite(requestId));
  };

  const dispatchAcceptAllInvites = (instituteKneuraId) => {
    dispatch(acceptAllInvites(instituteKneuraId));
  };

  const getAllPendingRequests = (workspaceId) => {
    dispatch(fetchPendingRequests(workspaceId));
  };

  const dispatchShowJoinWorkspaceInviteDialog = () => {
    dispatch(showJoinWorkspaceInviteDialog());
  };

  const dispatchHideJoinWorkspaceInviteDialog = () => {
    dispatch(hideJoinWorkspaceInviteDialog());
  };

  const dispatchShowJoinSpaceInviteDialog = () => {
    dispatch(showJoinSpaceInviteDialog());
  };

  const dispatchHideJoinSpaceInviteDialog = () => {
    dispatch(hideJoinSpaceInviteDialog());
  };

  return {
    dispatchAcceptAllInvites,
    dispatchAcceptInvite,
    dispatchRejectInvite,
    dispatchShowJoinWorkspaceInviteDialog,
    dispatchHideJoinWorkspaceInviteDialog,
    dispatchShowJoinSpaceInviteDialog,
    dispatchHideJoinSpaceInviteDialog,
    getAllPendingRequests,
  };
};

export default useInvitesDispatcher;
