import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  greetings: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: 'left',
    color: '#fff',
  },
}));
const Greetings = () => {
  const classes = useStyles();

  const hour = new Date().getHours();
  const welcomeTypes = ['Good Morning,', 'Good Afternoon,', 'Good Evening,'];
  let welcomeText = '';

  if (hour < 12) welcomeText = welcomeTypes[0];
  else if (hour < 18) welcomeText = welcomeTypes[1];
  else welcomeText = welcomeTypes[2];

  return <div className={classes.greetings}>{welcomeText}</div>;
};

export default Greetings;
