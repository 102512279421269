import React from 'react';
import useInvitesDispatcher from '../../../../store/dispatcher/useInvitesDispatcher';
import { useInvitesSelector } from '../../../../store/selector/useInvitesSelector';
import { DialogGroup } from './component';

export const DialogGroupContainer = (props) => {
  const { isJoinWorkspaceInviteDialogOpen } = useInvitesSelector();
  const { dispatchHideJoinWorkspaceInviteDialog } = useInvitesDispatcher();

  const closeJoinWorkspaceInviteDialog = () => {
    dispatchHideJoinWorkspaceInviteDialog();
  };

  return (
    <DialogGroup
      isJoinWorkspaceInviteDialogOpen={isJoinWorkspaceInviteDialogOpen}
      closeJoinWorkspaceInviteDialog={closeJoinWorkspaceInviteDialog}
    />
  );
};
