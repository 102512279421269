import { styled } from '@material-ui/core';

export const ContentContainer = styled('div')({
  display: 'flex',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: '#f5f5f5',
  zIndex: 9999,
});
