import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { MouseCursorContainer } from '../../../components/mouse-cursor-container/mouse-cursor-container';
import ScreenshareContainer from '../../../components/webrtc/screenshare/container';
import { FEATURE_FLAGS } from '../../../constants/feature-flags.constants';
import huddleService from '../../../services/huddle.service';
import { useHuddleDispatcher } from '../../../store/dispatcher/useHuddleDispatcher';
import { getCanvasCode } from '../../../Utils/authentication-access';
import Layout from './layout';
import SecureityIcon from '../../../assets/icons/SecureityIcon';
import { useMediaClient } from '../../../components/webrtc/screenshare/useMediaClient';
import { ScreenshareDisplayContentContainer } from '../../../components/webrtc/screenshare/display-content-container/container';
import { LayoutScreenshareContentContainer } from './common/screenshare/content-container';

/**
 * This is used in /controller/Wbflow
 * For /Wbflow, check `src/Kneura-Web-Whiteboard/IWB/IWB.jsx`
 * @returns
 */
const LayoutWrapper = () => {
  const history = useHistory();
  const { dispatchSetHuddleOnline, dispatchShowHuddleInvitation, dispatchSetHuddleOffline } =
    useHuddleDispatcher();
  const mediaClient = useMediaClient();

  useEffect(() => {
    initHuddle();

    /**
     * stop screenshare on unmount
     */
    return () => {
      mediaClient.stopScreenshare();
    };
  }, []);

  const initHuddle = async () => {
    const canvasCode = getCanvasCode();
    const res = await huddleService.getStatus({ canvasCode });
    if (res.data.STATUS === 'HUDDLE_IN_PROGRESS') {
      dispatchSetHuddleOnline();
      dispatchShowHuddleInvitation();
    } else {
      /**
       * we need to set the huddle status as offline
       * if its not in progress
       *
       * why is this required?
       * - when user is in huddle
       * - user logged out and logged in again
       * - the huddle button might be marked as join
       * - since the app logged out and might have missed the socket message
       * - to update the huddle status
       */
      dispatchSetHuddleOffline();
    }
  };

  const goToHomePage = () => {
    history.push('/');
  };

  const layoutScreenshareContentContainer = (
    <LayoutScreenshareContentContainer mediaClient={mediaClient} />
  );

  return (
    <>
      <Layout
        role={'instructor'}
        onBackButton={goToHomePage}
        addPageAllowed={true}
        inactivateAll={false}
        mediaClient={mediaClient}
      />
      {FEATURE_FLAGS.screenshareInASpace.isEnabled && layoutScreenshareContentContainer}
    </>
  );
};

export default LayoutWrapper;
