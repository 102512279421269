import React from 'react';
import Avatar from 'react-avatar';
import { useEventUserProfileStyles } from './component.style';

export const EventUserProfile = (props) => {
  const classes = useEventUserProfileStyles();
  const { eventOrganizerName } = props;

  const avatar = (
    <Avatar
      variant="rounded"
      name={eventOrganizerName}
      round={true}
      size="48"
      //TODO: required
      // src={singleWorkspace.imageUrl || WorkspaceDefaultLogo}
    />
  );

  return (
    <div className={classes.container}>
      {avatar}
      {/* <div className={classes.eventOrganizerName}>{eventOrganizerName}</div> */}
    </div>
  );
};

export const EventUserProfileName = (props) => {
  const classes = useEventUserProfileStyles();
  const { eventOrganizerName } = props;

  return (
    <div className={classes.container}>
      {/* {avatar} */}
      <div className={classes.eventOrganizerName}>{eventOrganizerName}</div>
    </div>
  );
};
