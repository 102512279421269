import React, { useEffect } from "react";
import { makeStyles, Menu, MenuItem } from "@material-ui/core";
import userDefaultIcon from "../../../../assets/img/userIcon.svg";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const useStyles = makeStyles(() => ({
  singleContainer: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    marginBottom: '7.5px',
  },
  memberName: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  memberEmail: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: 'rgba(0, 0, 0, 0.38)',
  },
}));

const SingleContent = ({ contentItem, removeMemberFromSpace, menuOptionsClose }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const boardOptionOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseboardOptions = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleCloseboardOptions();
  }, [menuOptionsClose]);

  console.log('aaa', contentItem);
  return (
    <div className={classes.singleContainer}>
      <img
        style={{ borderRadius: '50px' }}
        src={contentItem.user.imageUrl || userDefaultIcon}
        alt="img"
        width={40}
        height={40}
      />
      <div style={{ paddingLeft: '8px' }}>
        <div className={classes.memberName}>{contentItem.user.name}</div>
        <div className={classes.memberEmail}>{contentItem.user.email}</div>
      </div>
      <MoreHorizIcon
        onClick={handleClick}
        fontSize="medium"
        style={{
          marginLeft: 'auto',
          marginRight: '22px',
          color: 'rgba(0, 0, 0, 0.6)',
          cursor: 'pointer',
        }}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={boardOptionOpen}
        onClose={handleCloseboardOptions}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '90px',
          },
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          disabled={contentItem.roles.includes('OWNER')}
          onClick={() => removeMemberFromSpace(contentItem)}
        >
          Remove
        </MenuItem>
      </Menu>
    </div>
  );
};

export default SingleContent;
