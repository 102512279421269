import { makeStyles } from '@material-ui/core';

export const useHuddleActionDialogStyles = makeStyles((theme) => ({
  actionsContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    gap: '1rem'
  },
  headingContainer: {
    marginBottom: '2rem'
  }
}));