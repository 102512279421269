import ACTION_TYPES from '../actions/type.action';

/**
 * isOnline: flag to check if huddle is online
 * user.isOnline: flag to check if user is online in the huddle
 */
const initialState = {
  isOnline: false,
  url: '',
  members: [],
  user: {
    isOnline: false,
  },
  isHuddleInvitationOpen: false,
  isViewOpen: false,
  huddleDuration: 30,
};

const huddleReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPES.SET_ONLINE:
      return {
        ...state,
        isOnline: true,
      };
    case ACTION_TYPES.SET_OFFLINE:
      return {
        ...state,
        isOnline: false,
      };
    case ACTION_TYPES.SET_USER_ONLINE:
      return {
        ...state,
        user: {
          isOnline: true,
        },
        url: payload.url,
        isHuddleInvitationOpen: false
      };
    case ACTION_TYPES.SET_USER_OFFLINE:
      return {
        ...state,
        user: {
          isOnline: false,
        },
        url: '',
      };
    case ACTION_TYPES.START_HUDDLE:
      return {
        ...state,
        user: {
          isOnline: true,
        },
        url: payload.url,
        isHuddleInvitationOpen: false,
        isViewOpen: true,
      }

    case ACTION_TYPES.LEAVE_HUDDLE:
      return {
        ...state,
        user: {
          isOnline: false,
        },
        url: '',
        isViewOpen: false,
      }
    case ACTION_TYPES.HUDDLE_ENDED:
      return {
        ...state,
        isOnline: false,
        user: {
          isOnline: false,
        },
        url: '',
        isViewOpen: false,
        isHuddleInvitationOpen: false
      }

    case ACTION_TYPES.HUDDLE_STARTED:
      return {
        ...state,
        isOnline: true,
      }

    case ACTION_TYPES.SET_OPEN_HUDDLE_INVITATION:
      return {
        ...state,
        ...payload,
      }
    case ACTION_TYPES.SET_VIEW_OPEN:
      return {
        ...state,
        isViewOpen: payload.isViewOpen,
      }
    case ACTION_TYPES.SET_HUDDLE_DURATION:
      return {
        ...state,
        huddleDuration: payload.huddleDuration,
      }
    default:
      return { ...state };
  }
};

export default huddleReducer;
