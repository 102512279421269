import React from 'react';

const OutlookIcon = (props) => {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="38" height="38" rx="6" fill="white" />
      <path
        d="M21.8009 11.3008H31.9735C32.5405 11.3008 33.0004 11.7607 33.0004 12.3276V25.6732C33.0004 26.2402 32.5405 26.7001 31.9735 26.7001H21.8009V11.3008Z"
        fill="#1976D2"
      />
      <path d="M31.6004 14.7715H21.8009V25.6504H31.6004V14.7715Z" fill="white" />
      <path d="M21.1006 32.9997L5.00134 29.8498V8.15083L21.1006 5.00098V32.9997Z" fill="#1976D2" />
      <path
        d="M12.8752 13.751C10.6521 13.751 8.85034 16.1015 8.85034 19.0007C8.85034 21.9 10.6521 24.2505 12.8752 24.2505C15.0983 24.2505 16.9 21.9 16.9 19.0007C16.9 16.1015 15.0983 13.751 12.8752 13.751ZM12.7002 22.1506C11.5403 22.1506 10.6003 20.7402 10.6003 19.0007C10.6003 17.2613 11.5403 15.8509 12.7002 15.8509C13.86 15.8509 14.8001 17.2613 14.8001 19.0007C14.8001 20.7402 13.86 22.1506 12.7002 22.1506Z"
        fill="white"
      />
      <path d="M23.7237 23.0156H21.8337V25.0455H23.7237V23.0156Z" fill="#1976D2" />
      <path d="M26.1034 23.0156H24.2135V25.0455H26.1034V23.0156Z" fill="#1976D2" />
      <path d="M28.484 23.0156H26.5941V25.0455H28.484V23.0156Z" fill="#1976D2" />
      <path d="M23.7237 20.5117H21.8337V22.5416H23.7237V20.5117Z" fill="#1976D2" />
      <path d="M26.1034 20.5117H24.2135V22.5416H26.1034V20.5117Z" fill="#1976D2" />
      <path d="M28.484 20.5117H26.5941V22.5416H28.484V20.5117Z" fill="#1976D2" />
      <path d="M30.8648 20.5117H28.9749V22.5416H30.8648V20.5117Z" fill="#1976D2" />
      <path d="M23.7237 18.0947H21.8337V20.1246H23.7237V18.0947Z" fill="#1976D2" />
      <path d="M26.1034 18.0947H24.2135V20.1246H26.1034V18.0947Z" fill="#1976D2" />
      <path d="M28.484 18.0947H26.5941V20.1246H28.484V18.0947Z" fill="#1976D2" />
      <path d="M30.8648 18.0947H28.9749V20.1246H30.8648V18.0947Z" fill="#1976D2" />
      <path d="M26.1034 15.5791H24.2135V17.609H26.1034V15.5791Z" fill="#1976D2" />
      <path d="M28.484 15.5791H26.5941V17.609H28.484V15.5791Z" fill="#1976D2" />
      <path d="M30.8648 15.5791H28.9749V17.609H30.8648V15.5791Z" fill="#1976D2" />
    </svg>
  );
};

export default OutlookIcon;
