import React from 'react';
import { Page } from 'react-pdf';
import { useRenderPdfStyles } from './component.style';

export const RenderPdf = (props) => {
  const classes = useRenderPdfStyles();
  const { pageNumber, onClick, onDragStart, thumbnailWidth } = props;

  return (
    <div onClick={onClick} className={classes.container} draggable onDragStart={onDragStart}>
      <Page pageNumber={pageNumber} width={thumbnailWidth} />
    </div>
  );
};
