import { ZOOM_MEETINGS } from "./url-builder";

const signatureEndpoint = ZOOM_MEETINGS.getSignature();

export const getSignature = async (params) => {
  const { meetingNumber, role } = params;
  const res = await fetch(signatureEndpoint, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      meetingNumber: meetingNumber,
      role: role,
    }),
  });
  const data = await res.json()
  return data?.signature;
};
