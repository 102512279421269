import React, { useCallback, useMemo, useState } from 'react';
import { Button, TextField, makeStyles } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

import { LANGUAGES } from '../../constants/self-learn.constants';
import { langIcon } from '../../assets/icons';

const useStyles = ({ open, standard }) =>
  makeStyles((theme) => ({
    languageSelectRoot: {
      position: 'relative',
      width: standard ? '100%' : undefined,

      '& .lang-select-button': {
        justifyContent: 'space-between',
        gap: 8,
        height: 36,
        width: 170,
        borderRadius: standard ? 4 : 16,
        border: standard ? undefined : `1px solid ${theme.palette.primary.main}`,
        fontSize: 14,
        fontWeight: 400,
        color: '#767676',
        lineHeight: 1,
      },
    },
    languageSelectMenu: {
      position: 'absolute',
      top: '100%',
      width: '100%',
      maxHeight: 200,
      overflow: 'auto',
      zIndex: 1,
      marginTop: 12,
      display: open ? 'flex' : 'none',
      flexDirection: 'column',
      gap: 4,
      backgroundColor: '#fff',
      padding: 4,
      filter:
        'drop-shadow(0px 10px 32px rgba(24, 39, 75, 0.10)) drop-shadow(0px 6px 14px rgba(24, 39, 75, 0.12))',
      '&::after': {
        content: "''",
        display: 'block',
        position: 'absolute',
        top: -24,
        left: '50%',
        transform: 'translate(-50%,0)',
        border: '12px solid #fff',
        borderColor: 'transparent transparent #fff',
      },
      '& .MuiButton-root': {
        fontSize: 14,
        color: '#787878',
      },
      '& .active-item': {
        backgroundColor: '#EAEEFF',
        color: '#000',
      },
      '& .i-search': {
        fontSize: 14,
        '& .MuiInputBase-root': {
          height: 32,
        },
      },
    },
  }));

export const LanguageSelect = ({ language, onChange, standard }) => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const classes = useStyles({ open, standard })();

  const toggle = useCallback(() => {
    if (open) setSearchValue('');
    setOpen((open) => !open);
  }, [open]);

  const options = useMemo(() => {
    return Object.entries(LANGUAGES)
      .filter(([_, title]) => title.toLowerCase().includes(searchValue.toLowerCase()))
      .map(([key, title]) => (
        <Button
          key={key}
          className={language === key ? 'active-item' : ''}
          variant="text"
          onClick={() => {
            toggle();
            onChange(key);
          }}
        >
          {title}
        </Button>
      ));
  }, [language, onChange, searchValue, toggle]);

  const searchChangeHandler = (e) => setSearchValue(e.target.value);

  return (
    <div className={classes.languageSelectRoot}>
      <Button
        className="lang-select-button"
        variant="outlined"
        startIcon={<img src={langIcon} alt="language" width={20} height={20} />}
        endIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
        onClick={toggle}
      >
        {LANGUAGES[language]}
      </Button>
      <div className={classes.languageSelectMenu}>
        <TextField
          className="i-search"
          variant="outlined"
          value={searchValue}
          onChange={searchChangeHandler}
          size="small"
          placeholder="Search language"
        />
        {options}
      </div>
    </div>
  );
};
