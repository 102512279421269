import uuid from 'uuid';
import { getUserId } from './authentication-access';
import { SOURCE_STATUS } from '../constants/smart-notes.constants';

const SMART_NOTES_KEY = 'SMART_NOTES';

export const addNote = ({ name }) => {
  const userId = getUserId();
  const id = uuid();
  const notes = getAllNotes();
  notes.push({ id, userId, name, sources: [] });
  saveNotes(notes);
  return notes;
};

export const deleteNote = (id) => {
  const notes = getAllNotes();
  const updatedNotes = notes.filter((note) => note.id !== id);
  saveNotes(updatedNotes);
  return updatedNotes;
};

export const updateNote = ({ id, ...noteDetails }) => {
  const notes = getAllNotes();
  const updatedNotes = notes.map((note) => {
    const updatedNote = { ...note, ...noteDetails };
    return note.id === id ? updatedNote : note;
  });
  saveNotes(updatedNotes);
  return updatedNotes;
};

export const getUserNotes = () => {
  const userId = getUserId();
  const notes = getAllNotes();
  return notes.filter((note) => note.userId === userId);
};

export const getAllNotes = () => {
  const notes = localStorage.getItem(SMART_NOTES_KEY) || '[]';
  return JSON.parse(notes);
};

export const saveNotes = (notes) => {
  localStorage.setItem(SMART_NOTES_KEY, JSON.stringify(notes));
};

export const addSource = ({ noteId, ...sourceDetails }) => {
  const notes = getAllNotes();
  const updatedNotes = notes.map((note) =>
    note.id === noteId ? { ...note, sources: [...note.sources, sourceDetails] } : note,
  );
  saveNotes(updatedNotes);
  return updatedNotes;
};

export const updateSource = ({ noteId, id, ...sourceDetails }) => {
  const notes = getAllNotes();
  const updatedNotes = notes.map((note) => {
    if (note.id === noteId) {
      const updatedSources = note.sources.map((source) =>
        source.id === id ? { ...source, ...sourceDetails } : source,
      );
      return { ...note, sources: updatedSources };
    }
    return note;
  });
  saveNotes(updatedNotes);
  return updatedNotes;
};

export const toggleAllNoteSources = ({ noteId, selected }) => {
  const notes = getAllNotes();
  const updatedNotes = notes.map((note) => {
    if (note.id === noteId) {
      const updatedSources = note.sources.map((source) =>
        source.status === SOURCE_STATUS.COMPLETE ? { ...source, selected } : source,
      );
      return { ...note, sources: updatedSources };
    }
    return note;
  });
  saveNotes(updatedNotes);
  return updatedNotes;
};
