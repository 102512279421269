import ACTION_TYPES from './type.action';

export const setSnackbar = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_SNACKBAR,
    payload: JSON.parse(JSON.stringify(payload)),
  });
};

export const resetSnackbar = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.RESET_SNACKBAR,
  });
};

export const showSpinner = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SHOW_SPINNER,
  });
};

export const hideSpinner = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.HIDE_SPINNER,
  });
};

/**
 * payload: {
 *  feature: name of feature
 * }
 * @param {*} payload
 * @returns
 */
export const showLicenseExhaustedDialog = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SHOW_LICENSE_EXHAUSTED_DIALOG,
    payload,
  });
};

export const hideLicenseExhaustedDialog = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.HIDE_LICENSE_EXHAUSTED_DIALOG,
  });
};

/**
 * payload: {
 *  feature: feature name
 * }
 *
 *
 * @param {*} payload
 * @returns
 */
export const showFeatureLockedDialog = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SHOW_FEATURE_LOCKED,
    payload,
  });
};

export const hideFeatureLockedDialog = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.HIDE_FEATURE_LOCKED,
  });
};

/**
 * payload: {
 *  feature: name of feature
 * }
 * @param {*} payload
 * @returns
 */

export const showMaxSimultaneousUsageLimitExhaustedDialog = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SHOW_MAX_SIMULTANEOUS_USAGE_LIMIT_EXHAUSTED_DIALOG,
    payload,
  });
};

export const hideMaxSimultaneousUsageLimitExhaustedDialog = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.HIDE_MAX_SIMULTANEOUS_USAGE_LIMIT_EXHAUSTED_DIALOG,
  });
};

/**
 * payload: {
 *  feature: name of feature
 * }
 * @param {*} payload
 * @returns
 */

export const showDeviceLicenseExhaustedDialog = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SHOW_DEVICE_LICENSE_EXHAUSTED_DIALOG,
    payload,
  });
};

export const hideDeviceLicenseExhaustedDialog = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.HIDE_DEVICE_LICENSE_EXHAUSTED_DIALOG,
  });
};

export const showHuddleSessionLimitExhaustedDialog = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SHOW_HUDDLE_SESSION_EXHAUSTED_DIALOG,
    payload,
  });
};

export const hideHuddleSessionLimitExhaustedDialog = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.HIDE_HUDDLE_SESSION_EXHAUSTED_DIALOG,
  });
};
