import React from 'react';
import { StyledDialogContainer } from '../../../../components/common/dialog/styled-dialog/container';
import { EventDescriptionContainer } from '../event-description/container';
import { EventDetailContainer } from '../event-detail/container';
import { LaunchMeetingButtonContainer } from '../launch-meeting-button/container';

export const CalendarDetailsDialog = (props) => {
  const { title, isOpen, close, eventInfo, isDeviceConnected } = props;

  const footerContent = (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
      <LaunchMeetingButtonContainer
        eventInfo={eventInfo}
        isDeviceConnected={isDeviceConnected}
        isOpen={isOpen}
      />
    </div>
  );

  const description = eventInfo?.body?.content;
  const displayEventDescription = !!description && (
    <EventDescriptionContainer description={description} isDeviceConnected={isDeviceConnected} />
  );

  return (
    <StyledDialogContainer
      title={title}
      open={isOpen}
      onClose={close}
      footerContent={footerContent}
    >
      <EventDetailContainer eventInfo={eventInfo} />
      {displayEventDescription}
    </StyledDialogContainer>
  );
};
