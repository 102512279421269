import { IconButton, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  bgColor: {
    // backgroundColor: theme.palette.primary.light,
  },
  dialogStyledHeader: {
    padding: '2% 2%',
    opacity: '0.75',
    display: 'grid',
    gridTemplateColumns: '92% 10%',
    alignItems: 'end',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '2.5rem',
  },
  closeIcon: {
    marginRight: 10,
    cursor: 'pointer',
  },
  titleStyle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '28px',
    textAlign: 'center',
    marginLeft: '50px',
    color: 'rgba(0, 0, 0)',
  },
  headerContainer: {
    padding: ' 2% 2%',
    display: 'grid',
    gridTemplateColumns: '90% 10%',
    alignItems: 'center',
    minHeight: '30px',
    borderBottom: '1px solid rgba(0,0,0,0.12)',
  },
}));

const StyledHeader = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div
      className={classes.headerContainer}
      // className={[classes.dialogStyledHeader, classes.bgColor].join(" ")}
    >
      <div className={classes.titleStyle}> {t(props.title)}</div>
      {props.onClose && (
        <IconButton
          edge="end"
          color="inherit"
          onClick={props.onClose}
          aria-label="close"
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  );
};

export default StyledHeader;
