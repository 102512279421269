import { useDispatch } from 'react-redux';
import { setDocInfo } from '../actions/documentImport.action';

export const useDocumentImportDispatcher = () => {
  const dispatch = useDispatch();

  const dispatchSetDocInfo = (payload) => {
    dispatch(setDocInfo(payload));
  };

  return {
    dispatchSetDocInfo,
  };
};
