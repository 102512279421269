import React from 'react';
import { useSelector } from 'react-redux';
import { PdfPagesGallery } from './component';

export const PdfPagesGalleryContainer = (props) => {
  const docInfo = useSelector((state) => state.documentImport.docInfo);
  const {
    url,
    allPdfPagesNos,
    currentGalleryPage,
    displayPagesNos,
    goToNextGalleryPage,
    goToPreviousGalleryPage,
    hasNextGalleryPage,
    hasPrevGalleryPage,
    importDocs,
    onDocumentLoadSuccess,
    onLoadError,
    pageRange,
    pagesPerGalleryPage,
    setCurrentGalleryPage,
    setFirstPageRange,
    setLastPageRange,
    shouldBeFullscreen,
    toggleShouldBeFullscreen,
    totalGalleryPage,
    totalPages,
  } = props;

  return (
    <div>
      <PdfPagesGallery
        allPdfPagesNos={allPdfPagesNos}
        currentGalleryPage={currentGalleryPage}
        displayPagesNos={displayPagesNos}
        docInfo={docInfo}
        goToNextGalleryPage={goToNextGalleryPage}
        goToPreviousGalleryPage={goToPreviousGalleryPage}
        hasNextGalleryPage={hasNextGalleryPage}
        hasPrevGalleryPage={hasPrevGalleryPage}
        importDocs={importDocs}
        onDocumentLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onLoadError}
        pageRange={pageRange}
        pagesPerGalleryPage={pagesPerGalleryPage}
        setCurrentGalleryPage={setCurrentGalleryPage}
        setFirstPageRange={setFirstPageRange}
        setLastPageRange={setLastPageRange}
        shouldBeFullscreen={shouldBeFullscreen}
        toggleShouldBeFullscreen={toggleShouldBeFullscreen}
        totalGalleryPage={totalGalleryPage}
        totalPages={totalPages}
        url={url}
      />
    </div>
  );
};
