import { post, get, put, del } from '../Utils/api';
import { getWorkspaceId } from '../Utils/authentication-access';
import { MEMBER, SPACES } from '../Utils/url-builder';

class WorkspaceService {
  createWorkspace = async (workspaceName) => {
    try {
      const url = SPACES.createWorkspace();
      return await post(url, { name: workspaceName });
    } catch (error) {
      console.log('error', error);
    }
  };

  getWorkspaceIdByClassId = async () => {
    try {
      const url = SPACES.getWorkspaceIdByClassId();
      return await get(url);
    } catch (error) {
      console.log('error', error);
    }
  };

  getWorkspaces = async () => {
    try {
      const workspaceUrl = SPACES.getWorkspaces();
      return await get(workspaceUrl);
    } catch (error) {
      console.log('error', error);
    }
  };

  editWorkspace = async (formData) => {
    try {
      const url = SPACES.editWorkspace();
      return await put(url, formData);
    } catch (error) {
      console.log('error', error);
    }
  };

  getMemberslist = async () => {
    try {
      const workspaceId = getWorkspaceId();
      const getMembersUrl = MEMBER.getMembers(workspaceId);
      return await get(getMembersUrl);
    } catch (error) {
      console.log('error', error);
    }
  };

  getAdminslist = async () => {
    try {
      const workspaceId = getWorkspaceId();
      const getAdminsUrl = MEMBER.getAdmins(workspaceId);
      return await get(getAdminsUrl);
    } catch (error) {
      console.log('error', error);
    }
  };

  inviteAdmin = async (emailId) => {
    try {
      const workspaceId = getWorkspaceId();
      const inviteAdminUrl = MEMBER.inviteAdmin();
      return await post(inviteAdminUrl, {
        email: emailId,
        workspaceId: workspaceId,
      });
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  inviteMember = async (emailId) => {
    try {
      const workspaceId = getWorkspaceId();
      const inviteMemberUrl = MEMBER.inviteMember();
      return await post(inviteMemberUrl, {
        email: emailId,
        workspaceId: workspaceId,
      });
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  canSendJoinWorkspace = async (token) => {
    try {
      const url = MEMBER.canSendJoinWorkspace();
      return await post(url, { token });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  joinMember = async (formData) => {
    try {
      const joinMemberUrl = MEMBER.joinMember();
      return await post(joinMemberUrl, formData);
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  allUsersList = async () => {
    try {
      const workspaceId = getWorkspaceId();
      const url = MEMBER.allUsersList(workspaceId);
      return await get(url);
    } catch (error) {
      console.log('error', error);
    }
  };

  upgradeToAdmin = async (userID) => {
    try {
      const workspaceID = getWorkspaceId();
      const url = MEMBER.upgradeToAdmin();
      return await post(url, { workspaceId: workspaceID, userId: userID });
    } catch (error) {
      console.log('error', error);
    }
  };

  deleteUserInWorkspace = async (userID) => {
    try {
      const workspaceID = getWorkspaceId();
      const url = MEMBER.deleteUserInWorkspace();
      return await del(url, { workspaceId: workspaceID, userId: userID });
    } catch (error) {
      console.log('error', error);
    }
  };

  revokeAdminAccess = async (userID) => {
    try {
      const workspaceID = getWorkspaceId();
      const url = MEMBER.revokeAdminAccess();
      return await post(url, { workspaceId: workspaceID, userId: userID });
    } catch (error) {
      console.log('error', error);
    }
  };

  getSingleWorkspaceInfo = async (workspaceId) => {
    try {
      const url = SPACES.singleWorkspaceInfo(workspaceId);
      return await get(url);
    } catch (error) {
      console.log('single Team Info error', error);
      throw error;
    }
  };
}

const workspaceService = new WorkspaceService();
export default workspaceService;
