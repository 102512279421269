import ACTION_TYPE from './type.action';

/**
 *
 * @param {boolean} payload
 * @returns
 */
export const setIsOpenImportOutlookEventsDialog = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_IS_OPEN_IMPORT_OUTLOOK_EVENTS_DIALOG,
    payload,
  });
};

/**
 *
 * @param {boolean} payload
 * @returns
 */
export const setIsOpenVideoCallSidebarDialog = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_IS_OPEN_VIDEO_CALL_SIDEBAR_DIALOG,
    payload,
  });
};

/**
 *
 * @param {boolean} payload
 * @returns
 */
export const setIsOpenNavigationSidebarDialog = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_IS_OPEN_NAVIGATION_SIDEBAR_DIALOG,
    payload,
  });
};

/**
 *
 * @param {boolean} payload
 * @returns
 */
export const setIsOpenJoinAsHostDialog = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_IS_OPEN_JOIN_AS_HOST_DIALOG,
    payload,
  });
};

/**
 *
 * @param {boolean} payload
 * @returns
 */
export const setIsOpenJoinRequestSentDialog = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_IS_OPEN_JOIN_REQUEST_SENT_DIALOG,
    payload,
  });
};

/**
 *
 * @param {*} payload
 * @returns
 */
export const setIsRemoteControlDialogOpen = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_IS_REMOTE_CONTROL_DIALOG_OPEN,
    payload,
  });
};
