import React from 'react';
import { StyledMenu } from './component';

export const StyledMenuContainer = (props) => {
  const { label, customButtonContent, items, customMenuList } = props;

  return (
    <StyledMenu
      label={label}
      customButtonContent={customButtonContent}
      items={items}
      customMenuList={customMenuList}
    />
  );
};
