import React from 'react';
import { Box, Hidden, makeStyles } from '@material-ui/core';

import ControllerTopbar from '../../../Kneura-Web-Whiteboard/Controller/ControllerTopbar';
import ControllerSidebar from '../../../Kneura-Web-Whiteboard/Controller/ControllerSidebar';
import NotebookList from '../../../components/smart-notes/notebooks/notebook-list';

const useStyles = makeStyles((theme) => ({
  notesPageRoot: {
    position: 'fixed',
    top: 52,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'auto',
    padding: 52,
    boxSizing: 'border-box',
    '& *, *::after, *::before': {
      boxSizing: 'border-box',
    },
    [theme.breakpoints.up('md')]: {
      left: 59,
    },
  },
}));

const NotebooksPage = ({ handleMobileSideMenuOpen }) => {
  const classes = useStyles();
  return (
    <>
      <Hidden smDown>
        <ControllerSidebar />
      </Hidden>
      <ControllerTopbar handleMobileSideMenuOpen={handleMobileSideMenuOpen} />
      <Box className={classes.notesPageRoot}>
        <NotebookList />
      </Box>
    </>
  );
};

export default NotebooksPage;
