import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  keywordListRoot: {
    '& .i-keywords-title': {
      fontSize: 14,
      fontWeight: 700,
      marginBottom: 16,
    },
    '& .i-keyword': {
      display: 'flex',
      alignItems: 'center',
      height: 32,
      borderRadius: 16,
      padding: 8,
      fontSize: 14,
      backgroundColor: '#EDF0FF',
      color: '#536CF9',
    },
  },
  keywordsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
  },
});

const KeywordList = ({ list, isYoutube }) => {
  const classes = useStyles();

  return (
    <div className={classes.keywordListRoot}>
      <Typography className="i-keywords-title">
        {(isYoutube ? 'Video' : 'PDF') + ' Keywords:'}
      </Typography>
      <div className={classes.keywordsContainer}>
        {list.map((keyword, index) => (
          <Typography key={index} className="i-keyword">
            {keyword}
          </Typography>
        ))}
      </div>
    </div>
  );
};

export default React.memo(KeywordList);
