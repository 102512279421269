import axios from 'axios';

import { BIRDAI_SERVER_URL, LOGIN_PAGE_URL } from '../config';

const excludedPaths = ['/zoom', '/microsoft'];

axios.interceptors.response.use(undefined, (error) => {
  /**
   * Note:
   *
   * 1. if api returns unauthorised, log out the user
   * 2. exceptions: url {host}/zoom* and {host}/microsoft
   */
  if (
    error.response?.status === 401 &&
    window.location.href !== `${LOGIN_PAGE_URL}/` && // avoid reloading the page if the user is in the login page and has entered wrong credentials
    !excludedPaths.some((excludedPath) =>
      error.response?.config.url.includes(BIRDAI_SERVER_URL + excludedPath),
    )
  ) {
    localStorage.clear();
    window.location.href = '/';
  }
  return Promise.reject(error);
});
