import ACTION_TYPES from "../actions/type.action";

const initialState = {
  mousePosition: {
    x: 0,
    y: 0,
    pageId : 0
  },
  cursorPositions: []
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPES.UPDATE_MOUSE_POSITION:
      return {
        ...state,
        mousePosition: { ...payload },
      };

    case ACTION_TYPES.UPDATE_CURSORS:
      return {
        ...state,
        cursorPositions: payload
      }

    default:
      return { ...state };
  }
};

export default reducer;
