import CONFIG, { SMART_NOTES_API_URL } from '../config';
import { getCanvasCode } from './authentication-access';

// export const { BASE_URL } = CONFIG;

// TODO MODIFY THE STAGE SCRIPT TO BUILD IN JENKINS
export const BASE_URL = `${CONFIG.BIRDAI_SERVER_URL}/`;
export const LICENSE_SERVICE_URL = `${CONFIG.LICENSE_SERVICE_URL}/`;
const { AUTH_SERVER_URL, LEARN_AI_URL, LEARN_AI_API_URL } = CONFIG;

export const WHITEBOARD = {
  getWhiteBoard: (classid) => `${BASE_URL}class/findOne?classId=${classid}`,
  createCanvas: () => `${BASE_URL}class/create`,
  getUserToken: () => `${BASE_URL}user/login`,
};

export const AUTHENTICATION = {
  signup: () => `${BASE_URL}auth/signup`,
  otpSend: () => `${BASE_URL}otp/signup`,
  login: () => `${BASE_URL}auth/login`,
  otpReceive: () => `${BASE_URL}otp/signup/verify`,
  forgetPassword: () => `${BASE_URL}auth/forgot-password`,
  resetPassword: () => `${BASE_URL}auth/reset-password`,
  userProfile: () => `${BASE_URL}user/me`,
  editProfile: () => `${BASE_URL}user`,
};

export const HUDDLE = {
  startOnPairedDevice: () => `${BASE_URL}device/notify-start-remote-huddle`,
};

export const SPACES = {
  getSpaces: () => `${BASE_URL}spaces`,
  postSpaces: () => `${BASE_URL}spaces`,
  postBoard: () => `${BASE_URL}spaces/add-class`,
  renameBoard: () => `${BASE_URL}spaces/rename-class`,
  deleteBoard: () => `${BASE_URL}spaces/delete-class`,
  duplicateBoard: () => `${BASE_URL}spaces/duplicate-class`,
  deleteSpaces: () => `${BASE_URL}spaces`,
  renameSpaces: () => `${BASE_URL}spaces`,
  getWorkspaces: () => `${BASE_URL}workspaces`,
  createWorkspace: () => `${BASE_URL}workspaces`,
  fetchSpaces: (workspaceId) => `${BASE_URL}workspaces/spaces?workspaceId=${workspaceId}`,
  editWorkspace: () => `${BASE_URL}workspaces`,
  singleSpaceInfo: (spaceId) => `${BASE_URL}spaces/${spaceId}`,
  singleWorkspaceInfo: (workspaceId) => `${BASE_URL}workspaces/${workspaceId}`,
};

export const MEMBER = {
  getMembers: (workspaceId) => `${BASE_URL}workspaces/members?workspaceId=${workspaceId}`,
  getAdmins: (workspaceId) => `${BASE_URL}workspaces/admins?workspaceId=${workspaceId}`,
  inviteAdmin: () => `${BASE_URL}workspaces/invite-admin`,
  inviteMember: () => `${BASE_URL}workspaces/invite`,
  canSendJoinWorkspace: () => `${BASE_URL}workspaces/can-join`,
  joinMember: () => `${BASE_URL}workspaces/join`,
  spaceMemberslist: (spaceId) => `${BASE_URL}spaces/members?spaceId=${spaceId}`,
  removeMemberAccess: () => `${BASE_URL}spaces/members`,
  addMemberToSpace: () => `${BASE_URL}spaces/permissions`,
  allUsersList: (workspaceId) => `${BASE_URL}workspaces/users?workspaceId=${workspaceId}`,
  upgradeToAdmin: () => `${BASE_URL}workspaces/users/upgrade-to-admin`,
  deleteUserInWorkspace: () => `${BASE_URL}workspaces/users`,
  revokeAdminAccess: () => `${BASE_URL}workspaces/users/revoke-admin-privileges`,
};

export const BOARD = {
  getPresentationModeStatus: (flowId) =>
    `${BASE_URL}class/presentation-mode/status?flowId=${flowId}`,
  getJoinFlow: () => {
    const canvasCode = getCanvasCode();
    return `${CONFIG.APP_URL}/joinflow/${canvasCode}`;
  },
};

export const INVITES = {
  canSendJoinSpaceRequest: (spaceId, email) =>
    `${BASE_URL}join-requests/can-send-join-space-request?spaceId=${spaceId}&email=${email}`,
  canSendJoinWorkspaceRequest: (workspaceId, email) =>
    `${BASE_URL}join-requests/can-send-join-workspace-request?workspaceId=${workspaceId}&email=${email}`,
  createAndSendJoinSpaceRequest: () =>
    `${BASE_URL}join-requests/create-and-send-join-space-request`,
  createAndSendJoinSpaceRequestUsingOAuth: () =>
    `${BASE_URL}join-requests/create-and-send-join-space-request-using-oauth`,
  createAndSendJoinWorkspaceRequest: () =>
    `${BASE_URL}join-requests/create-and-send-join-workspace-request`,
  createAndSendJoinWorkspaceRequestUsingOAuth: () =>
    `${BASE_URL}join-requests/create-and-send-join-workspace-request-using-oauth`,
  sendJoinSpaceRequest: () => `${BASE_URL}join-requests/send-join-space-request`,
  sendJoinWorkspaceRequest: () => `${BASE_URL}join-requests/send-join-workspace-request`,
  accept: () => `${BASE_URL}join-requests/accept`,
  reject: () => `${BASE_URL}join-requests/reject`,
  acceptAll: () => `${BASE_URL}join-requests/accept-all`,
  getAllPendingRequests: (workspaceId) =>
    `${BASE_URL}join-requests/get-all-pending-requests?workspaceId=${workspaceId}`,
  sendOtp: () => `${BASE_URL}join-requests/send-otp`,
  verifyOtp: () => `${BASE_URL}join-requests/verify-otp`,
};

export const DEVICE_PAIR = {
  sendMeetingCommand: () => `${BASE_URL}device/sendMeetingCommand`,
  sendDeviceId: () => `${BASE_URL}device/pairDevice`,
  unPairDevice: () => `${BASE_URL}device/unpairDevice `,
  pairedDeviceStatus: () => `${BASE_URL}device/getPairDeviceStatus`,
  startMeetingOnDevice: () => `${BASE_URL}device/startMeetingOnDevice`,
  calendarInfo: (timeZone) =>
    `${BASE_URL}microsoft/me/calendar-view-for-android?timezone=${timeZone}`,
  screenshareStarted: () => `${BASE_URL}device/screenshare-started`,
  screenshareStopped: () => `${BASE_URL}device/screenshare-stopped`,
};

export const ZOOM_MEETINGS = {
  getSignature: () => `${BASE_URL}zoom/meetings/get-signature`,
  joinAsHostOnPairedDevice: () => `${BASE_URL}zoom/join-as-host-on-paired-device`,
  joinAsParticipantOnPairedDevice: () => `${BASE_URL}zoom/join-as-participant-on-paired-device`,
};

export const PAYMENT = {
  currentPayment: (instituteKneuraId) => `${BASE_URL}payment/current-payment/${instituteKneuraId}`,
  createPayment: () => `${BASE_URL}payment/create`,
  licenseActivateWithKey: (licenseKey) =>
    `${LICENSE_SERVICE_URL}license/activate-web/${licenseKey}`,
};

export const LICENSE_LIST = {
  getCloudLicense: (workspaceId) => `${LICENSE_SERVICE_URL}license/workspace/${workspaceId}`,
  getDeviceLicense: (hardwareId) => `${LICENSE_SERVICE_URL}license/device/${hardwareId}`,
};

export const GOOGLE_OAUTH = {
  signupOrLogin: () => `${AUTH_SERVER_URL}/oauth/google/signup-or-login`,
};

export const SMART_NOTES = {
  upload: (userID) => `${SMART_NOTES_API_URL}/file/upload?userID=${userID}`,
  getUploadStatus: (uniqueId) => `${SMART_NOTES_API_URL}/file/status/?uniqueId=${uniqueId}`,
  chat: ({ query, noteId }) =>
    `${SMART_NOTES_API_URL}/chat/query?query=${encodeURI(query)}&noteID=${noteId}`,
};

export const SELF_LEARN = {
  ask: () => `${LEARN_AI_API_URL}/fetch`,
  askPdf: () => `${LEARN_AI_API_URL}/fetch-pdf`,
  pdfUpload: () => `${LEARN_AI_API_URL}/upload-pdf`,
  resetSession: () => `${LEARN_AI_API_URL}/reset`,
  welcomeMessage: () => `${LEARN_AI_API_URL}/send-user-message0`,
  createYoutubeChat: () => `${LEARN_AI_API_URL}/add-youtube-transcript`,
};

export const NOTEBOOKS = {
  getNotebooks: () => `${LEARN_AI_URL}/notebooks/user`,
  getNotebook: (id) => `${LEARN_AI_URL}/notebooks/${id}`,
  addNotebook: () => `${LEARN_AI_URL}/notebooks`,
  deleteNotebook: (id) => `${LEARN_AI_URL}/notebooks/${id}`,
  renameNotebook: (id) => `${LEARN_AI_URL}/notebooks/rename/${id}`,
  getNotebookThreads: (notebookId) => `${LEARN_AI_URL}/threads/notebook/${notebookId}`,
  getUserThreads: () => `${LEARN_AI_URL}/threads/user`,
  getThreadDetails: (id) => `${LEARN_AI_URL}/threads/${id}`,
  addThread: () => `${LEARN_AI_URL}/threads`,
  renameThread: (id) => `${LEARN_AI_URL}/threads/rename/${id}`,
  deleteThread: (id) => `${LEARN_AI_URL}/threads/${id}`,
  moveThread: () => `${LEARN_AI_URL}/threads/move-to-different-notebook`,
  moveThreadToNewNotebook: () => `${LEARN_AI_URL}/notebooks/create-and-add-thread`,
};

export const WHITEBOARD_CHAT_URLS = {
  START_CHAT: `${LEARN_AI_API_URL}/bright-ai/upload-element`,
};
