import React from 'react';
import ShareScreenIcon from '../../../../assets/icons/shareScreenIcon';
import classes from '../../launcher.module.css';

export const ScreenshareTileButton = (props) => {
  const { onClick, label } = props;
  return (
    <div className={classes.smallBox} onClick={onClick}>
      <ShareScreenIcon fill={false ? '#335AFB' : '#fff'} />
      <div className={classes.smallBoxText}>{label}</div>
    </div>
  );
};
