import React from 'react';
import { makeStyles } from '@material-ui/core';
import ScreenshareContainer from '../webrtc/screenshare/container';
import { v4 } from 'uuid';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme) => ({}));

const ScreenshareRoomContainer = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  let roomId = searchParams.get('roomId');
  if (!roomId) {
    return <div>Room Id not found</div>;
  }

  return <ScreenshareContainer roomId={roomId} />;
};

export default ScreenshareRoomContainer;
