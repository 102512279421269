import { useSelector } from 'react-redux';
import config from '../../config';
import microsoftGraphService from '../../services/microsoft-graph.service';
import { useMicrosoftDispatcher } from '../../store/dispatcher/useMicrosoftDispatcher';
import { useNotificationDispatcher } from '../../store/dispatcher/useNotificationDispatcher';

export const useMicrosoftAuth = () => {
  const isMicrosoftAuthenticated = useSelector((state) => state.microsoft.isAuthenticated);
  const me = useSelector((state) => state.microsoft.me);
  const { dispatchSetMicrosoftIsAuthenticated, dispatchSetMicrosoftMe } = useMicrosoftDispatcher();
  const { dispatchSetSnackbar } = useNotificationDispatcher();

  const getMicrosoftAuthEmail = () => {
    return me?.email || me?.userPrincipalName;
  };

  const revokeAccess = async () => {
    try {
      await microsoftGraphService.revokeAuthorization();
      dispatchSetMicrosoftIsAuthenticated(false);
      dispatchSetMicrosoftMe(null);
      dispatchSetSnackbar({ message: 'Revoked successfully' });
    } catch (error) {
      console.log(error.message);
      const message = error?.response?.data?.message || error?.message;
      dispatchSetSnackbar({ message });
    }
  };

  const initiateMicrosoftOAuthAuthorization = (options = {}) => {
    const tenant = 'common';

    let paramsObj = {
      client_id: config.MICROSOFT_OAUTH_CLIENT_ID,
      response_type: 'code',
      redirect_uri: config.MICROSOFT_OAUTH_REDIRECT_URI,
      response_mode: 'query',
      scope: config.MICROSOFT_OAUTH_SCOPE,
      state: '12345',
    };

    if (options.replaceAccount) {
      paramsObj.prompt = 'select_account';
    }

    const searchParams = new URLSearchParams(paramsObj);
    let url = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?${searchParams.toString()}`;

    window.open(url, '_self');
  };

  const initMicrosoftUser = async () => {
    try {
      const isMicrosoftAuthenticated = await microsoftGraphService.hasAuthorizedOAuth();
      dispatchSetMicrosoftIsAuthenticated(isMicrosoftAuthenticated);

      if (isMicrosoftAuthenticated) {
        const me = await microsoftGraphService.getMe();
        dispatchSetMicrosoftMe(me);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return {
    getMicrosoftAuthEmail,
    initiateMicrosoftOAuthAuthorization,
    initMicrosoftUser,
    isMicrosoftAuthenticated,
    me,
    revokeAccess,
  };
};
