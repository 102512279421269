import { makeStyles } from "@material-ui/core";

export const useHuddleInvitationStyles = makeStyles(() => ({
  container: {
    padding: '1rem',
    width: '268px',
  },
  button: {
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  buttonContainer: {
    marginTop: '0.5rem',
    display: 'flex',
    gap: '1rem',
  },
}));