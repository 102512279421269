import { useState, useEffect } from 'react';
import { registerEvent } from '../store/service/socket.service';

export const useUserLeft = () => {
  const [disconnectedID, setDisconnectedID] = useState('');

  //   register event only once
  useEffect(() => {
    registerEvent({
      type: 'USER_LEFT',
      callback: (value) => setDisconnectedID(value.userId),
    });
  }, []);

  return disconnectedID;
};
