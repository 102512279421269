import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
// import closeLogo from '../../assets/img/closeIcon.svg';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  bgColor: {
    // backgroundColor: theme.palette.primary.light,
  },
  dialogStyledHeader: {
    padding: '2% 2%',
    opacity: '1',
    display: 'grid',
    alignItems: 'end',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    position: 'relative',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 500,
    color: 'black',
  },
  closeIconContainer: {
    position: 'absolute',
    right: '1rem',
    top: '50%',
    transform: 'translateY(-40%)',
    cursor: 'pointer',
  },
}));

const StyledHeader = (props) => {
  const classes = useStyles();

  const titleContent = (
    <div className={classes.textContainer}>
      <Typography className={classes.title}>{props.title}</Typography>
    </div>
  );

  const closeIconContent = (
    <div className={classes.closeIconContainer}>
      {/* <img className="close-icon hover" onClick={props.onClose} src={CloseIcon} alt="Close" /> */}
      {/* <div style={{ marginRight: '5px' }}> */}
      <CloseIcon onClick={props.onClose} />
      {/* </div> */}
    </div>
  );

  return (
    <div className={[classes.dialogStyledHeader, classes.bgColor].join(' ')}>
      {titleContent}
      {closeIconContent}
    </div>
  );
};

export default StyledHeader;
