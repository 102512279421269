import React from 'react';
import { BOARD } from '../../../Utils/url-builder';
import { ShareJoinCanvasLinkContent } from './component';

export const ShareJoinCanvasLinkContentContainer = (props) => {
  const link = BOARD.getJoinFlow();
  const variant = 'h5';
  const headingText =
    'Scan the QR code or share link to access your meetings and content directly.';

  return <ShareJoinCanvasLinkContent link={link} variant={variant} headingText={headingText} />;
};
