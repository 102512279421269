import React from 'react';

const FileSelectorUserProfile = (props) => {
  const { setFile, setProfileImage, fileSelectorRef } = props

  const previewPic = (file) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      setProfileImage(reader.result)
    };
    if (file && file.type.match('image.*')) {
      reader.readAsDataURL(file);
    }
  };

  const fileChangedHandler = (event) => {
    previewPic(event.target.files[0]);
    setFile(event.target.files[0])
  };

  return (
    <input
      type="file"
      id="file"
      ref={fileSelectorRef}
      style={{ display: 'none' }}
      onChange={fileChangedHandler}
    />
  );
}

export default FileSelectorUserProfile