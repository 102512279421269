export function EmailValidationError(emailid) {
  let regex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
  return !regex.test(emailid);
}

export const stringToHSLColor = (string) => {
  if (!string) return 'hsl(0,0%,0%,0)';
  const hash = [...string].reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
  return `hsl(${hash % 359}, 100%, 50%)`;
};
