import { isTablet as isTabletRDD } from 'react-device-detect';
import DeviceDetector from 'device-detector-js';

const deviceDetector = new DeviceDetector();
const device = deviceDetector.parse(navigator.userAgent);
const isTabletFromUserAgent = device.device.type.includes('tablet');

export const checkIsTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
};

export const isTablet = isTabletRDD || isTabletFromUserAgent;
