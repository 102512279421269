//@ts-check
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PubSub from 'pubsub-js';
import './iwb.scss';
import Konva from 'konva';
import socketIOClient from 'socket.io-client';
import _, { isEmpty } from 'lodash';
import { isMobile } from 'react-device-detect';
import {
  Menu,
  MenuItem,
  withStyles,
  Box,
  Dialog,
  CircularProgress,
  Divider,
  IconButton,
} from '@material-ui/core';
import Draggable from 'react-draggable';
import ReactPlayer from 'react-player';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import {
  generateUUID,
  customBoundRect,
  getChildrenIds,
  generateShortId,
  minMaxPositions,
  getLengthBetweenPoints,
} from '../utils/updateCanvas';
import { get } from '../utils/api';
import Config from '../config';
import { getBoardInfo, getToken } from '../utils/authentication-access';
import IconClass from '../icons/icons';
import select_item_menu from '../assets/img/select_item_menu.svg';
import { HISTORY_ACTIONS } from '../../constants/canvasboard.js';
import { Positioning } from '../utils/positioning';
import Iframe from './Iframe';
import closeLogo from '../assets/img/closeIcon.svg';
import { getallUser, addUser, removeUser } from '../../../store/actions/userlist.action';
import { updateMousePosition } from '../../../store/actions/mouse.action';
import {
  changePresentationMode,
  storePresenterInfo,
  resetPresenterInfo,
  resetAllPresenterInfo,
  setCanvasScale,
  setCanvasPosition,
  setTransformFactor,
  setCurrentPageId,
} from '../../../store/actions/canvas.action';
import { initSocket } from '../../../store/service/socket.service';
import Background, {
  BG_TYPES,
  BG_CHANGE_MESSAGE,
  SOLID_BACKGROUNDS,
} from './background/background.jsx';
import DialogComponent from './DialogComponent';
import { EVENTS } from '../../../constants/events';
import config from '../../../config';
import { setFileInfo } from '../../../store/actions/documentImport.action';
import { acceptedFileTypes, importSupportedContentTypes } from '../../../constants/canvas';
import {
  getAuthUserDecodedInfo,
  getFlowId,
  getUserId,
  getUserName,
} from '../../../Utils/authentication-access';
import {
  drawItems,
  limitPosition,
  positionElement,
  rotateAroundCenter,
  zoomToFit,
} from '../../../Utils/canvas-board';
import { sleep } from '../../../Utils/util';
import './text-styles.css';
import MiniMap from '../../../components/mini-map';
import WhiteboardGrid from '../../../components/whiteboard-grid';
import {
  showSpinner,
  hideSpinner,
  setSnackbar,
  showLicenseExhaustedDialog,
} from '../../../store/actions/notification.action';
import { MouseCursorContainer } from '../../../components/mouse-cursor-container/mouse-cursor-container';
import { licenseExceptionHandler } from '../../../Utils/sockets/license-handler';
import WhiteboardChat from '../../components/whiteboard-chat';
import { ConFirmDialog } from '../../../components/dialogs';
import VideoOptionsButton from '../../components/whiteboard/video-options-button';
import { Close as CloseIcon } from '@material-ui/icons';

/**
 * @type {Konva.Stage}
 */
var currStage;
var socket;
var pageIds = [];
var selectedPageIndex = 0;
var selectedPageId = 0;
//need to change
var creatorId = getBoardInfo() ? getBoardInfo().email : Config.userID;
var creatorName = '';
var fullScreen = false;
var isPaint = false;
var tempLine;
var lastPointerPosition = { x: 0, y: 0 };
var icon_x = 0;
var icon_y = 0;
var radius = 0,
  shapeStartX = 0,
  shapeStartY = 0;

let positioning = null;

class CanvasBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: 'black',
      brushSize: 5,
      recording: false,
      endpoint: Config.endpointURL(getToken(), getBoardInfo().flowId),
      documentUrl: null,
      pdfUrl: null,
      showDocumentDialog: false,
      pdfDialogOpen: false,
      showLocalVideoDialog: false,
      strokeErase: false,
      zIndex: 0,
      userID: getBoardInfo().email,
      className: getBoardInfo().className,
      flowId: getBoardInfo().flowId,
      classId: getBoardInfo().classId,
      /**@type {any[]} */
      elementMenuItems: [],
      /**@type {any[]} */
      items: [],
      /**@type {any[]} */
      elements: [],
      /**@type {any[]} */
      videos: [],
      showLoader: false,
      loaderMessage: '',
      lassoItems: [],
      upgradePlan: false,
      mediaLinks: [],
      /**@type {any[]} */
      documents: [],
      uploadProgress: {},
      keyBuffer: [],
      lastKeyTime: Date.now(),
      selectedElementId: '',
      /**@type {any[]} */
      copiedElementId: [],
      movePositionX: 0,
      movePositionY: 0,
      pixelRatio: window.devicePixelRatio,
      defaultElementSize: {
        video: {
          width: 400,
          height: 225,
        },
        document: {
          width: 120,
          height: 120,
        },
        image: {
          width: 300,
          height: 169,
        },
        imageStandardSize: {
          width: 300,
          height: 300,
        },
        imageMobileStandardSize: {
          width: 80,
          height: 80,
        },
      },
      gridRenderFlag: false,
      isGridEnabled: false,
      showChat: false,
    };
    /**
     * @type {Konva.Layer[]}
     */
    this.pageLayouts = [];
    this.myRef = React.createRef();
    this.canvasComponentRef = React.createRef();
    this.Background = React.createRef().current;
    this.createLine = this.createLine.bind(this);
    this.IconClass = new IconClass();
    this.isAddingText = false;
    this.isZooming = false;
    this.zoomAnimationPlaying = false;
    this.lastCenter = null;
    this.lastDist = null;
    const { width, height, x, y, scale } = this.calculateStageDefaults();
    this.lastScale = scale;
    this.scale = scale;
    this.position = { x, y };
    this.size = { width, height };
    this.zoomSyncTimer = null;
    this.clickCounter = 0;
    this.textClickCounter = 0;
    this.videos = [];
    this.enablePaste = true;
    this.actionHistory = [];
    this.actionIndex = -1;
  }

  calculateStageDefaults = () => {
    if (!positioning)
      return {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        transformFactor: 1,
        scale: this.props.defaultScale,
      };
    const { x: width, y: height } = positioning.retrieve(positioning.width, positioning.height);
    const transformFactor = positioning.calculateTransformFactor();
    const { defaultScale: scale } = this.props;
    const x = -(width * (scale - 1)) / 2;
    const y = -(height * (scale - 1)) / 2;
    return { width, height, x, y, transformFactor, scale };
  };

  componentWillUnmount() {
    socket.close();
    document.removeEventListener('keydown', this.zoomPanHandler, true);
    document.removeEventListener('wheel', this.preventPageZoom, { capture: true });
    // socket.disconnect();
  }

  enableShortCutKeys = () => {
    document.addEventListener('keydown', this.handleKeyDown);
    this.enablePaste = true;
  };

  disableShortcutKeys = () => {
    document.removeEventListener('keydown', this.handleKeyDown);
    this.enablePaste = false;
  };

  addElementToState = (element) => {
    const clonedElement = _.cloneDeep(element);
    this.setState(({ elements: prevElements }) => {
      const updatedElements = [...prevElements];
      const oldElementIndex = prevElements.findIndex(
        (oldElement) => oldElement.elementId === clonedElement.elementId,
      );
      if (oldElementIndex > -1) {
        updatedElements[oldElementIndex] = clonedElement;
      } else {
        updatedElements.push(clonedElement);
      }
      return { elements: updatedElements };
    });
  };

  removeElementFromState = (elementId) =>
    this.setState(({ elements: prevElements }) => ({
      elements: prevElements.filter((element) => element.elementId !== elementId),
    }));

  manipulateElementsState = (manipulationMessage) => {
    const clonedMessage = _.cloneDeep(manipulationMessage);
    this.setState(({ elements: prevElements }) => {
      const updatedElements = [...prevElements];
      clonedMessage.elementIds.forEach((elementId, index) => {
        const updatedElement = updatedElements.find((element) => element.elementId === elementId);
        if (updatedElement) {
          updatedElement.elementBounds = {
            ...updatedElement.elementBounds,
            ...clonedMessage.endBounds[index],
          };
          updatedElement.elementScale = {
            ...updatedElement.elementScale,
            ...clonedMessage.endScales[index],
          };
          updatedElement.elementAngle = clonedMessage.angles[index];
        }
      });
      return { elements: updatedElements };
    });
  };

  updateElementState = (elementDetails) => {
    const clonedDetails = _.cloneDeep(elementDetails);
    this.setState(({ elements: prevElements }) => {
      const updatedElements = [...prevElements];
      const oldElementIndex = prevElements.findIndex(
        (oldElement) => oldElement.elementId === clonedDetails.elementId,
      );
      if (oldElementIndex > -1)
        updatedElements[oldElementIndex] = {
          ...updatedElements[oldElementIndex],
          ...clonedDetails,
        };
      return { elements: updatedElements };
    });
  };

  pushToStack = (msg) => {
    const clonedMessage = _.cloneDeep(msg);
    const updateActionState = (details) => {
      this.actionHistory.splice(this.actionIndex + 1);
      this.actionHistory.push(details);
      this.props.setUndoEnabled(true);
      this.props.setRedoEnabled(false);
      this.actionIndex = this.actionHistory.length - 1;
    };
    if (
      ['polyline', 'video', 'text', 'image', 'brush', 'youtube', 'document'].includes(
        clonedMessage.type,
      )
    )
      updateActionState({ type: HISTORY_ACTIONS.ADD, details: clonedMessage });

    if (clonedMessage.type === 'remove') {
      const elementDetails = this.state.elements.find(
        (element) => element.elementId === clonedMessage.elementId,
      );
      const clonedDetails = _.cloneDeep(elementDetails);
      if (elementDetails)
        updateActionState({
          type: HISTORY_ACTIONS.REMOVE,
          // Spread clonedMessage to extract flowId, classId, pageId,...
          details: { ...clonedMessage, ...clonedDetails },
        });
    }

    if (clonedMessage.type === 'manipulation') {
      const clonedElements = _.cloneDeep(this.state.elements);
      const elements = clonedElements.filter((element) =>
        clonedMessage.elementIds.includes(element.elementId),
      );
      if (elements.length)
        updateActionState({
          type: HISTORY_ACTIONS.MANIPULATION,
          details: { ...clonedMessage, elements },
        });
    }
  };

  updateStack = (details) => {
    const clonedDetails = _.cloneDeep(details);
    const { elementId, ...otherDetails } = clonedDetails;
    const historyIndex = this.actionHistory
      .reverse()
      .findIndex(({ details }) => details.elementId === elementId);
    if (historyIndex > -1)
      this.actionHistory[historyIndex].details = {
        ...this.actionHistory[historyIndex].details,
        ...otherDetails,
      };
    this.actionHistory.reverse();
  };

  undo = () => {
    const lastAction = this.actionHistory[this.actionIndex];
    if (!lastAction) return;
    this.props.setRedoEnabled(true);
    this.actionIndex = this.actionIndex - 1;
    if (this.actionIndex < 0) this.props.setUndoEnabled(false);
    switch (lastAction.type) {
      case HISTORY_ACTIONS.ADD:
        return this.deleteElement(lastAction.details.elementId, false);
      case HISTORY_ACTIONS.REMOVE:
        this.sendMessage(lastAction.details, false, false);
        this.handleSocket(lastAction.details, true, false);
        return;
      case HISTORY_ACTIONS.MANIPULATION: {
        const elementIds = lastAction.details.elements.map(({ elementId }) => elementId);
        const endBounds = lastAction.details.elements.map(({ elementBounds }) => elementBounds);
        const endScales = lastAction.details.elements.map(({ elementScale }) => elementScale);
        const angles = lastAction.details.elements.map(({ elementAngle }) => elementAngle);
        const manipulationDetails = {
          type: 'manipulation',
          flowId: lastAction.details.flowId,
          classId: lastAction.details.classId,
          pageId: lastAction.details.pageId,
          elementIds,
          endBounds,
          endScales,
          angles,
        };
        this.sendMessage(manipulationDetails, false, false);
        this.handleSocket(manipulationDetails, true, false);
        return;
      }
      default:
        return;
    }
  };

  redo = () => {
    let action = _.cloneDeep(this.actionHistory[this.actionIndex + 1]);

    if (!action) return;
    this.props.setUndoEnabled(true);
    this.actionIndex = this.actionIndex + 1;
    if (this.actionIndex === this.actionHistory.length - 1) this.props.setRedoEnabled(false);
    switch (action.type) {
      case HISTORY_ACTIONS.ADD:
        this.sendMessage(action.details, false, false);
        this.handleSocket(action.details, true, false);
        return;
      case HISTORY_ACTIONS.REMOVE:
        return this.deleteElement(action.details.elementId, false);
      case HISTORY_ACTIONS.MANIPULATION: {
        const { elements, ...message } = action.details;
        this.sendMessage(message, false, false);
        this.handleSocket(message, true, false);
        return;
      }
      default:
        return;
    }
  };

  moveElement = (element, movement) => {
    element.to({
      x: element.x() + movement.x,
      y: element.y() + movement.y,
      duration: 0,
      onFinish: () => {
        const rotation = element.rotation();
        rotateAroundCenter(element, 0);
        const manipulationObj = {
          type: 'manipulation',
          flowId: this.state.flowId,
          classId: this.state.classId,
          elementIds: [element.id()],
          endBounds: [
            {
              x: element.x(),
              y: element.y(),
            },
          ],
          endScales: [
            {
              x: element.scaleX(),
              y: element.scaleY(),
            },
          ],
          angles: [rotation],
        };
        rotateAroundCenter(element, rotation);
        this.sendMessage(manipulationObj);
      },
    });
  };

  handleKeyDown = (event) => {
    if (!this.checkIsCanvasFocused()) return;
    const delta = 4,
      movement = { x: 0, y: 0 };
    const isMovement = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.code);
    switch (event.code) {
      case 'Backspace':
      case 'Delete': {
        const transformer = currStage.findOne('Transformer');
        if (!transformer) return;
        // @ts-ignore
        transformer.nodes().forEach((item) => this.deleteElement(item.id()));
        this.removeTransformer();
        return;
      }
      case 'ArrowUp':
        movement.y -= delta;
        break;
      case 'ArrowDown':
        movement.y += delta;
        break;
      case 'ArrowLeft':
        movement.x -= delta;
        break;
      case 'ArrowRight': {
        movement.x += delta;
        break;
      }
      default:
        break;
    }
    if (isMovement) {
      const transformer = currStage.findOne('Transformer');
      if (!transformer) return;
      // @ts-ignore
      transformer.nodes().forEach((item) => this.moveElement(item, movement));
      return;
    }

    if (event.metaKey && event.shiftKey && event.code === 'KeyZ') {
      this.redo();
      return;
    }

    if (event.ctrlKey || event.metaKey) {
      switch (event.code) {
        case 'KeyA': {
          event.preventDefault();
          this.toolBar.selectClick();
          const shapesLayer = this.pageLayouts[pageIds.indexOf(selectedPageId)];
          const nodes = shapesLayer.getChildren();
          this.selectIntersections(undefined, nodes);
          return;
        }
        case 'KeyD':
          event.preventDefault();
          this.removeTransformer();
          return;
        case 'KeyC': {
          const transformer = currStage.findOne('Transformer');
          if (!transformer) return;
          const copiedElements = this.state.elements.filter(({ elementId }) =>
            // @ts-ignore
            transformer.nodes().some((node) => node.id() === elementId),
          );
          navigator.clipboard.writeText(
            JSON.stringify({ type: 'kneura', elements: copiedElements }),
          );
          return;
        }
        case 'KeyZ':
          this.undo();
          return;
        case 'KeyY':
          this.redo();
          return;
        default:
          return;
      }
    }
  };

  setToolbar = (toolBarContext) => (this.toolBar = toolBarContext);

  setPagination = (pagination) => (this.pagination = pagination);

  loginToSocket = () => {
    let loginObj = {};
    loginObj.type = 'login';
    loginObj.classId = this.state.classId;
    loginObj.flowId = this.state.flowId;
    loginObj.user_id = this.state.userID;
    loginObj.class_name = this.state.className;
    socket.emit('message', JSON.stringify(loginObj));
    this.props.addUser({ ...loginObj, userId: getUserId(), userName: getUserName() });
  };

  renderSvg = async (
    obj,
    { height, width, x, y, fileName, scale = { x: 1, y: 1 } },
    onDoubleClick,
    doubleClickParams,
    fileSize,
    visible = true,
  ) => {
    if (!obj.pageId) return;
    const { elementId, pageId } = obj;
    const shapesLayer = this.pageLayouts[pageIds.indexOf(pageId)];
    let oldElement = shapesLayer.findOne(`#${elementId}`);
    if (oldElement) {
      oldElement.setAttrs({ height, width, x, y, scale });
      oldElement.on('dblclick dbltap', () => {
        onDoubleClick(doubleClickParams, fileSize);
        this.props.setFileInfo(obj);
      });
      this.addDocumentTransformer(oldElement, obj, () =>
        onDoubleClick(doubleClickParams, fileSize),
      );
      return;
    }
    const generatedSvg = this.IconClass.generateIcons(obj.filetype || obj.fileFormat || obj.type, {
      height,
      width,
      x,
      y,
      fileName,
    });
    const createImageFromURL = (url) =>
      new Promise((resolve, reject) => {
        try {
          Konva.Image.fromURL(url, (image) => resolve(image));
        } catch (error) {
          console.error(error);
          reject(error);
        }
      });
    const image = await createImageFromURL('data:image/svg+xml;base64,' + btoa(generatedSvg));
    oldElement = shapesLayer.findOne(`#${elementId}`);
    if (oldElement) {
      oldElement.setAttrs({ height, width, x, y, scale });
      oldElement.on('dblclick dbltap', () => {
        onDoubleClick(doubleClickParams, fileSize);
        this.props.setFileInfo(obj);
      });
      this.addDocumentTransformer(oldElement, obj, () =>
        onDoubleClick(doubleClickParams, fileSize),
      );
      return;
    }
    image.setAttrs({
      height,
      width,
      x,
      y,
      scale,
      id: elementId,
      // Using the opacity instead of visible attribute,
      // so the image will take up space on the canvas and new elements will not be inserted on top of each other
      opacity: visible ? 1 : 0,
    });
    shapesLayer.add(image);
    if (visible) {
      image.on('dblclick dbltap', () => {
        onDoubleClick(doubleClickParams, fileSize);
        this.props.setFileInfo(obj);
      });
      image.on('dragmove', () => (document.body.style.cursor = 'grabbing'));
      image.on('dragend', () => {
        document.body.style.cursor = 'default';
        const rotation = image.rotation();
        rotateAroundCenter(image, 0);
        const manipulationObj = {
          type: 'manipulation',
          flowId: this.state.flowId,
          classId: this.state.classId,
          elementIds: [elementId],
          endBounds: [
            {
              x: image.x(),
              y: image.y(),
            },
          ],
          endScales: [
            {
              x: image.scaleX(),
              y: image.scaleY(),
            },
          ],
          angles: [rotation],
        };
        rotateAroundCenter(image, rotation);
        this.sendMessage(manipulationObj);
      });
      this.addDocumentTransformer(image, obj, () => onDoubleClick(doubleClickParams, fileSize));
    }
  };

  deleteElement = (elementId, pushToStack = true, removeTransformer = true) => {
    this.removeTransformer();
    this.sendMessage(
      {
        type: 'remove',
        elementId: elementId,
      },
      pushToStack,
    );
    this.removeElement(
      this.pageLayouts[pageIds.indexOf(selectedPageId)],
      elementId,
      removeTransformer,
    );
  };

  constructStartPresentationSocketMessagePayload = () => {
    const { userId, tag } = getAuthUserDecodedInfo();
    return {
      type: EVENTS.TYPE_START_PRESENTATION,
      flowId: this.state.flowId,
      pageId: selectedPageId,
      userId,
      userTag: tag,
    };
  };

  sendStartPresentationMessage = () => {
    const payload = this.constructStartPresentationSocketMessagePayload();
    this.sendMessage(payload);
  };

  handleStartPresentation = () => {
    this.sendStartPresentationMessage();
    const payload = this.constructStartPresentationSocketMessagePayload();
    this.props.resetAllPresenterInfo();
    this.props.storePresenterInfo({ ...payload });
    this.handleZoomSync(true);
  };

  sendEndPresentationMessage = () => {
    this.sendMessage({ type: EVENTS.TYPE_STOP_PRESENTATION });
    const flowId = getFlowId();
    const { userId, tag } = getAuthUserDecodedInfo();
    this.props.resetPresenterInfo({ userId, flowId, userTag: tag });
  };

  transformMessageBounds = (message, type = 'retrieve') => {
    const clonedMessage = _.cloneDeep(message);
    if (clonedMessage.type === 'manipulation') {
      clonedMessage.endBounds = clonedMessage.endBounds.map((elementBounds) => {
        const retrievedBounds = positioning[type](elementBounds.x, elementBounds.y);
        elementBounds.x = retrievedBounds.x;
        elementBounds.y = retrievedBounds.y;
        const retrievedSizes = positioning[type](elementBounds.width, elementBounds.height);
        elementBounds.width = retrievedSizes.x;
        elementBounds.height = retrievedSizes.y;
        return elementBounds;
      });
      return clonedMessage;
    }
    if (!clonedMessage.elementBounds) return clonedMessage;
    const retrievedBounds = positioning[type](
      clonedMessage.elementBounds.x,
      clonedMessage.elementBounds.y,
    );
    clonedMessage.elementBounds.x = retrievedBounds.x;
    clonedMessage.elementBounds.y = retrievedBounds.y;
    const retrievedSizes = positioning[type](
      clonedMessage.elementBounds.width,
      clonedMessage.elementBounds.height,
    );
    clonedMessage.elementBounds.width = retrievedSizes.x;
    clonedMessage.elementBounds.height = retrievedSizes.y;

    if (clonedMessage.type === 'polyline' && clonedMessage.stroke) {
      const coordinates = clonedMessage.stroke.trim().split(' ');
      const updatedCoordinates = coordinates.map((coordinate) => positioning[type](coordinate).x);
      clonedMessage.stroke = updatedCoordinates.join(' ');
    }
    return clonedMessage;
  };

  handleSocket = async (msg, transform = true, updateElementsState = true) => {
    if (!msg) return;
    let clonedMessage = _.cloneDeep(msg);
    // If message bounds need to be transformed, we will use the original message in our elementsState
    // otherwise we'll convert the bounds to absolute values (bound to default canvas size (1280 * 720))
    if (updateElementsState) {
      const messageToAddToState = transform
        ? clonedMessage
        : this.transformMessageBounds(clonedMessage, 'transform');
      if (
        ['polyline', 'video', 'text', 'image', 'brush', 'youtube', 'document'].includes(
          clonedMessage.type,
        )
      ) {
        this.addElementToState(messageToAddToState);
      }
      if (clonedMessage.type === 'manipulation') this.manipulateElementsState(messageToAddToState);
      if (clonedMessage.type === 'remove') this.removeElementFromState(clonedMessage.elementId);
      if (clonedMessage.type === 'video-changed') {
        this.updateElementState({
          elementId: clonedMessage.elementId,
          s3Url: clonedMessage.s3Url,
          fileType: clonedMessage.fileType,
        });
        this.updateStack({
          elementId: clonedMessage.elementId,
          s3Url: clonedMessage.s3Url,
          fileType: clonedMessage.fileType,
        });
      }
      if (clonedMessage.type === 'image-changed') {
        this.updateElementState({ elementId: clonedMessage.elementId, s3Url: clonedMessage.s3Url });
        this.updateStack({ elementId: clonedMessage.elementId, s3Url: clonedMessage.s3Url });
      }
    }
    if (clonedMessage.type === EVENTS.TYPE_START_PRESENTATION) {
      if (this.props.presentingMode) {
        /**
         * currently, user is a presenter,
         * since, other user wants to present
         * we need to exit presentation mode
         */
        //commented for testing
        // this.sendEndPresentationMessage();
        this.props.disablePresentingMode();
        this.props.disablePageSelection();

        const flowId = getFlowId();
        const { userId, tag } = getAuthUserDecodedInfo();
        this.props.resetPresenterInfo({ userId, flowId, userTag: tag });
      }

      this.props.storePresenterInfo(clonedMessage);
    }

    /**
     * if the presenter has stop presentation, then enable page selection
     */
    if (clonedMessage.type === EVENTS.TYPE_STOP_PRESENTATION) {
      const presenters = this.props.presenters;
      const presenter = presenters.find((p) => p.flowId === clonedMessage.flowId);
      if (presenter && presenter.userId === clonedMessage.userId) {
        this.props.enablePageSelection();
        this.props.disablePresentingMode();
      }
    }

    if (clonedMessage.type === 'item-changed') {
      clonedMessage.type = clonedMessage.originalType;
      delete clonedMessage.originalType;
      return await this.handleSocket(clonedMessage, true, false);
    }
    if (clonedMessage.zIndex) {
      this.setState({ zIndex: clonedMessage.zIndex + 1 });
    }
    if (transform) clonedMessage = this.transformMessageBounds(clonedMessage, 'retrieve');

    if (clonedMessage.type === 'manipulation') this.handleManipulation(clonedMessage);

    if (clonedMessage.type === 'polyline') {
      if (clonedMessage.flowId !== this.state.flowId) return;
      this.updatePolyline(clonedMessage);
    } else if (clonedMessage.type === 'brush') {
      this.drawBrushObject(clonedMessage);
    } else if (clonedMessage.type === 'remove') {
      this.removeElement(
        this.pageLayouts[pageIds.indexOf(clonedMessage.pageId)],
        clonedMessage.elementId,
      );
    } else if (msg.type === 'image') {
      if (clonedMessage.flowId !== this.state.flowId) return;
      this.addImage(clonedMessage);
    } else if (clonedMessage.type === 'text') {
      if (clonedMessage.flowId !== this.state.flowId) return;
      this.removeElement(
        this.pageLayouts[pageIds.indexOf(selectedPageId)],
        clonedMessage.elementId,
      );
      this.addText(clonedMessage, false, false, false);
    } else if (clonedMessage.type === 'image-changed') {
      this.addItem(clonedMessage);
    } else if (clonedMessage.type === 'document') {
      this.addDocument(clonedMessage, false);
    } else if (clonedMessage.type === 'document-changed') {
      let documentsSupported = [...importSupportedContentTypes, ...acceptedFileTypes];
      if (clonedMessage.elementId) this.setStateSynchronous({ [clonedMessage.elementId]: 101 });
      clonedMessage.filetype = clonedMessage.fileType;
      if (documentsSupported.includes(clonedMessage.filetype)) {
        await this.addDocument(clonedMessage, true);
      } else {
        console.log({
          EVENT: 'document-changed',
          elementObject: clonedMessage,
          error: 'Document not supported',
        });
      }
    } else if (clonedMessage.type === 'page-add') {
      if (this.props.presentationMode) {
        await this.addPage(clonedMessage.pageId, clonedMessage.index);
        this.pagination.addPageFromOthers(clonedMessage.index, clonedMessage.pageCollection);
      } else {
        let pageIndex = clonedMessage.pageCollection.indexOf(selectedPageId);
        if (pageIndex < 0) return;
        await this.addPageFromSocket(clonedMessage.pageId, clonedMessage.index, selectedPageId);
        this.pagination.addPageDisablePresentationMode(
          clonedMessage.index,
          clonedMessage.pageCollection,
          pageIndex,
        );
      }
    } else if (clonedMessage.type === 'page-select') {
      if (this.props.presentationMode) {
        if (clonedMessage.pageCollection.indexOf(clonedMessage.pageId) < 0) return;
        await this.pagination.pageSelect(
          clonedMessage.pageCollection.indexOf(clonedMessage.pageId),
          true,
          clonedMessage.pageCollection,
        );
      }
    } else if (
      clonedMessage.type === 'page-delete' &&
      creatorId !== '' &&
      creatorId !== clonedMessage.creatorId
    ) {
      if (clonedMessage.pageId === selectedPageId) {
        this.pagination.confirmDelete(null, pageIds.indexOf(clonedMessage.pageId));
      } else {
        this.pagination.updatePageCount(pageIds.indexOf(clonedMessage.pageId), selectedPageId);
        this.removeOtherPage(clonedMessage.pageId);
      }
    } else if (clonedMessage.type === 'video' || clonedMessage.type === 'video-changed') {
      await this.iconUpdate({ obj: clonedMessage, fileType: 'video' });
    } else if (clonedMessage.type === 'youtube') {
      await this.renderVideoIcon(clonedMessage);
      this.setState({ videos: [...this.state.videos, clonedMessage] });
      let pageIndex = pageIds.indexOf(clonedMessage.pageId);
      this.setState({
        mediaLinks: [
          ...this.state.mediaLinks,
          { pageNo: pageIndex, fileName: 'Youtube Link', url: clonedMessage.s3Url },
        ],
      });
    } else if (clonedMessage.type === 'USER_JOINED') {
      this.props.addUser(clonedMessage);
    } else if (clonedMessage.type === 'USER_LEFT') {
      this.props.removeUser(clonedMessage);
    } else if (clonedMessage.type === 'start-presentation') {
      this.props.changePresentationMode(true);
    } else if (clonedMessage.type === 'end-presentation') {
      this.props.changePresentationMode(false);
    } else if (clonedMessage.type === BG_CHANGE_MESSAGE) {
      if (clonedMessage.detail && clonedMessage.detail.type)
        await this.renderBackground(
          clonedMessage.pageId,
          clonedMessage.detail.type,
          clonedMessage.detail,
          false,
        );
    } else if (clonedMessage.type === EVENTS.UNPAIR_CLASS) {
      PubSub.publish(EVENTS.UNPAIR_CLASS, clonedMessage);
    } else if (clonedMessage.type === EVENTS.PAIR_CLASS) {
      PubSub.publish(EVENTS.PAIR_CLASS, clonedMessage);
    } else if (clonedMessage.type === EVENTS.SET_PAGE_ZOOM) {
      const { presentingMode } = this.props;
      if (presentingMode) return;
      let { pageId, topLeft, bottomRight, scale } = clonedMessage;
      const areOnSamePage = selectedPageId === pageId;
      if (areOnSamePage) {
        currStage.scale({ x: scale, y: scale });
        const { transformFactor } = this.props;
        topLeft = { x: topLeft.x * transformFactor, y: topLeft.y * transformFactor };
        bottomRight = { x: bottomRight.x * transformFactor, y: bottomRight.y * transformFactor };
        const center = this.getCenter(topLeft, bottomRight);
        const relativeCenter = { x: center.x * scale, y: center.y * scale };
        const x = -(relativeCenter.x - currStage.width() / 2);
        const y = -(relativeCenter.y - currStage.height() / 2);
        const position = this.limitPosition(scale, { x, y });
        currStage.position(position);
        this.applyZoomEffects(scale, position, false);
      }
    }
  };

  handleZoomSync = (force) => {
    this.zoomSyncTimer && clearTimeout(this.zoomSyncTimer);
    this.zoomSyncTimer = setTimeout(() => {
      const { presentingMode } = this.props;
      if (!presentingMode && !force) return;
      const scale = this.scale;
      let { x, y } = this.position;
      x = x / this.props.transformFactor;
      y = y / this.props.transformFactor;
      const topLeft = { x: -x / scale, y: -y / scale };
      const bottomRight = {
        x: (-x + currStage.width() / this.props.transformFactor) / scale,
        y: (-y + currStage.height() / this.props.transformFactor) / scale,
      };
      const message = {
        type: EVENTS.SET_PAGE_ZOOM,
        scale: this.scale,
        topLeft,
        bottomRight,
        pageId: selectedPageId,
      };
      this.sendMessage(message, false);
    }, 200);
  };

  handleManipulation = (msg) => {
    msg.elementIds.forEach((elementId, index) => {
      let item = this.pageLayouts[pageIds.indexOf(msg.pageId)].findOne('#' + elementId);

      const bounds = { x: msg.endBounds[index].x, y: msg.endBounds[index].y };

      if (item) {
        rotateAroundCenter(item, 0);
        item.setAttrs({
          x: bounds.x,
          y: bounds.y,
          scaleX: msg.endScales[index].x,
          scaleY: msg.endScales[index].y,
        });
        rotateAroundCenter(item, msg.angles[index]);
      }
      let video = this.state.videos.filter((videoElement) => videoElement.elementId === elementId);
      if (video && video.length) {
        video[0].elementBounds.x = bounds.x;
        video[0].elementBounds.y = bounds.y;
        if (video[0].angle) video[0].angle = msg.angles[index];
        this.setState(
          {
            videos: this.state.videos.filter(
              (videoElement) => videoElement.elementId !== elementId,
            ),
          },
          () => {
            this.setState({ videos: [...this.state.videos, video[0]] });
          },
        );
      }
      let document = this.state.documents.filter(
        (documentElement) => documentElement.elementId === elementId,
      );
      if (document && document.length) {
        document[0].elementBounds.x = bounds.x;
        document[0].elementBounds.y = bounds.y;

        if (document[0].angle) document[0].angle = msg.angles[index];

        this.setState(
          {
            documents: this.state.documents.filter(
              (documentElement) => documentElement.elementId !== elementId,
            ),
          },
          () => {
            this.setState({
              documents: [...this.state.documents, document[0]],
            });
          },
        );
      }
      return;
    });
  };

  addDocumentVideo = async (obj) => {
    var pos = this.randomPos();
    obj.elementBounds = { x: pos.x, y: pos.y };
    obj['tmUrl'] = obj.url;
    var tempRes = this.wrapperDocumentType(obj.filetype);
    var tempObj = {
      obj: obj,
      fileType: tempRes['documentType'],
      extraInfo: tempRes,
    };
    await this.iconUpdate(tempObj);
  };

  addDocument = async (obj, drawExisting) => {
    obj['tmUrl'] = obj.s3Url;
    var tempRes = this.wrapperDocumentType(
      obj.fileType && obj.fileType.includes('/') ? obj.fileType : obj.fileFormat,
    );
    var tempObj = {
      obj: obj,
      fileType: tempRes['documentType'],
      extraInfo: tempRes,
    };
    await this.documentIconUpdate(tempObj, drawExisting, false, obj.mimeType || '', obj.fileSize);
  };

  getDocumentSize = (_height = 120, _width = 120) => {
    let size = {
      height: _height,
      width: _width,
    };
    if (_height >= 120 || _width >= 120) return { height: 120, width: 120 };
    return size;
  };

  documentIconUpdate = async (
    tempObj,
    drawExisting,
    drawTransformer = true,
    contentType,
    fileSize,
  ) => {
    let { obj } = tempObj;
    let { height, width, x, y } = obj.elementBounds ? obj.elementBounds : obj;
    let iconObj = {
      height,
      width,
      x,
      y,
      fileName: obj.fileName || 'document',
    };

    const openDocumentType = contentType === 'application/pdf' ? this.showPDF : this.showDocument;
    let existingDocIndex = this.state.documents.findIndex((doc) => doc.elementId === obj.elementId);
    if (existingDocIndex > -1) {
      const existingDoc = this.state.documents[existingDocIndex];
      const { elementBounds } = existingDoc;
      this.removeElement(this.pageLayouts[pageIds.indexOf(selectedPageId)], obj.elementId);
      const updatedDoc = { ...existingDoc };
      updatedDoc.s3Url = obj.s3Url;
      updatedDoc.filetype = obj.filetype;
      const updatedDocuments = [...this.state.documents];
      updatedDocuments[existingDocIndex] = updatedDoc;
      await this.setStateSynchronous({ documents: updatedDocuments });
      iconObj.fileName = existingDoc.fileName || 'document';
      iconObj = { ...iconObj, ...elementBounds };
      if (importSupportedContentTypes.includes(contentType)) {
        await this.renderSvg(
          obj,
          iconObj,
          this.props.openDocumentActionDialog,
          obj.s3Url,
          fileSize,
        );
        return;
      } else {
        await this.renderSvg(existingDoc, iconObj, openDocumentType, obj.s3Url);
        return;
      }
    } else {
      // To avoid adding duplicate document icons if it's an existing document, but it's not rendered yet
      if (drawExisting) {
        await sleep(500);
        return await this.documentIconUpdate(
          tempObj,
          drawExisting,
          drawTransformer,
          contentType,
          fileSize,
        );
      }
      /**
       * if document type is pdf / ppt
       * show dialog to view or import
       * else open the document directly
       */
      if (importSupportedContentTypes.includes(contentType)) {
        await this.renderSvg(
          obj,
          iconObj,
          this.props.openDocumentActionDialog,
          obj.s3Url,
          fileSize,
        );
      } else {
        await this.renderSvg(obj, iconObj, openDocumentType, obj.s3Url, fileSize);
      }
      await this.setStateSynchronous({ documents: [...this.state.documents, obj] });
    }
  };

  canvasDocument = async (documentObj) => {
    this.toolBar.selectClick();
    const id = generateUUID();
    const fileType = `${documentObj.contentUrl.type}/${documentObj.contentUrl.subType}`;
    const pos = this.randomPos();
    const tempRes = this.wrapperDocumentType(fileType);
    const contentDetails = documentObj.contentDetails;
    let tempObj = {
      elementId: id,
      elementType: 'document',
      elementBounds: {
        x: documentObj.x || pos.x,
        y: documentObj.y || pos.y,
        width: 300,
        height: 300,
      },
      pageId: selectedPageId,
      mimeType: fileType,
      fileFormat: fileType,
      fileName: documentObj.contentUrl.name,
      s3MetaData: contentDetails.s3MetaData,
      s3Url: documentObj.contentUrl.url,
    };

    const item = this.createItem(tempObj);
    this.sendMessage(item);
    await this.iconUpdate({
      obj: item,
      fileType: documentObj.contentUrl.subType,
      extraInfo: tempRes,
    });
  };

  wrapperDocumentType = (fileType) => {
    var typeArr = fileType && fileType.includes('/') ? fileType.split('/') : 'document/doc';
    var ty = typeArr[typeArr.length - 1];
    var docArr = {
      ppt: true,
      pptx: true,
      doc: true,
      docx: true,
      xls: true,
      xlsx: true,
      txt: true,
      pdf: true,
      csv: true,
    };
    if (docArr[ty]) {
      if (ty === 'pdf') {
        return {
          type: 'document',
          viewer: true,
          documentType: 'pdf',
          serverSide: true,
        };
      }
      if (ty === 'ppt' || ty === 'pptx') {
        return {
          type: 'document',
          viewer: true,
          documentType: 'ppt',
          serverSide: true,
        };
      } else {
        return {
          type: 'document',
          viewer: false,
          serverSide: true,
          documentType: 'file',
        };
      }
    } else {
      return {
        type: 'document',
        viewer: true,
        serverSide: true,
        documentType: 'video',
      };
    }
  };

  async iconUpdate(details) {
    var imgSrc = '/';
    var obj = details['obj'];
    var fileType = details['fileType'];
    var group;
    if (obj.type === 'video' || obj.type === 'video-changed' || obj.type === 'youtube') {
      await this.setStateSynchronous((prevState) => {
        const existingVideoIndex = prevState.videos.findIndex(
          (video) => video.elementId === obj.elementId,
        );
        if (existingVideoIndex > -1) {
          const updatedVideos = [...prevState.videos];
          updatedVideos[existingVideoIndex].s3Url = obj.s3Url;
          return { ...prevState, videos: updatedVideos };
        } else {
          const updatedVideos = [...prevState.videos, obj];
          return { ...prevState, videos: updatedVideos };
        }
      });
      const video = this.state.videos.find(({ elementId }) => elementId === obj.elementId);
      await this.renderVideoIcon(video);
      return;
    }
    let existingDoc = this.state.documents.filter((doc) => doc.elementId === obj.elementId);
    if (existingDoc && existingDoc.length) {
      this.removeElement(this.pageLayouts[pageIds.indexOf(selectedPageId)], obj.elementId);
      existingDoc[0].s3Url = obj.s3Url;
      existingDoc[0].iconUrl = imgSrc;
      existingDoc[0].filetype = existingDoc[0].fileType || existingDoc[0].fileFormat;
      await this.addDocument(existingDoc[0]);
      return;
    }
    icon_x += 15 * this.props.transformFactor;
    icon_y += 20 * this.props.transformFactor;
    if (icon_y > 160 * this.props.transformFactor) {
      icon_y = 0;
    }
    if (icon_x > 340 * this.props.transformFactor) {
      icon_x = 10 * this.props.transformFactor;
    }
    let existingDocument = this.pageLayouts[pageIds.indexOf(selectedPageId)].findOne(
      '#' + obj.elementId,
    );
    let existingFileName = this.pageLayouts[pageIds.indexOf(selectedPageId)].findOne(
      '#text_' + obj.elementId,
    );
    if (existingDocument) {
      obj.elementBounds = {};
      obj.elementBounds.x = existingDocument.x();
      obj.elementBounds.y = existingDocument.y();
      obj.elementBounds.height = existingDocument.height();
      obj.elementBounds.width = existingDocument.width();
      this.removeElement(this.pageLayouts[pageIds.indexOf(selectedPageId)], obj.elementId);
    }
    this.removeElement(
      this.pageLayouts[pageIds.indexOf(selectedPageId)],
      '#Uploading_' + obj.elementId,
    );
    if (existingFileName)
      this.removeElement(
        this.pageLayouts[pageIds.indexOf(selectedPageId)],
        '#text_' + obj.elementId,
      );

    switch (fileType) {
      case 'youtube':
        group = new Konva.Group({
          x: obj.elementBounds.x,
          y: obj.elementBounds.y,
          url: obj.s3Url,
          id: obj.elementId,
          draggable: true,
        });
        group.on('dblclick', () => {
          var tempRes = group.getAttrs();
          this.playYoutubeVideo(tempRes);
        });

        imgSrc = imgSrc + 'video.png';
        break;
      case 'video':
        group = new Konva.Group({
          x: obj.elementBounds.x,
          y: obj.elementBounds.y,
          url: obj.s3Url,
          id: obj.elementId,
          draggable: true,
        });

        group.on('dblclick', () => {
          var tempRes = group.getAttrs();
          this.playLocalVideo(tempRes);
        });

        imgSrc = imgSrc + 'video.png';
        break;
      case 'pdf':
        if (details.extraInfo !== undefined) {
          group = new Konva.Group({
            x: obj.elementBounds.x,
            y: obj.elementBounds.y,
            url: obj.tmUrl,
            id: obj.elementId,
          });
          group.on('dblclick', () => {
            this.showPDF(details.obj.s3Url);
          });
        } else {
          var objUrl = URL.createObjectURL(details['fileObj']);
          group = new Konva.Group({
            x: obj.elementBounds.x,
            y: obj.elementBounds.y,
            url: objUrl,
            id: obj.elementId,
          });

          group.on('dblclick', () => {
            this.showPDF(details.obj.s3Url);
          });
        }

        imgSrc = imgSrc + 'pdf.svg';
        break;
      case 'ppt':
      case 'pptx':
        group = new Konva.Group({
          x: obj.elementBounds.x,
          y: obj.elementBounds.y,
          url: obj.tmUrl,
          id: obj.elementId,
        });
        group.on('dblclick', () => {
          this.showDocument(details.obj.s3Url, obj.fileSize);
        });
        imgSrc = imgSrc + 'ppt.svg';

        break;
      case 'xls':
      case 'excel':
        group = new Konva.Group({
          x: obj.elementBounds.x,
          y: obj.elementBounds.y,
          url: obj.tmUrl,
          id: obj.elementId,
        });
        group.on('dblclick', () => {
          this.showDocument(details.obj.s3Url, obj.fileSize);
        });
        imgSrc = '/xlsx.svg';

        break;
      default:
        group = new Konva.Group({
          x: obj.elementBounds.x,
          y: obj.elementBounds.y,
          url: obj.tmUrl,
          id: obj.elementId,
        });
        group.on('dblclick', () => {
          this.showDocument(details.obj.s3Url, obj.fileSize);
        });
        imgSrc = imgSrc + 'docs.svg';
    }

    if (
      details.obj &&
      details.obj.filetype &&
      (details.obj.filetype.includes('/xls') || details.obj.filetype.includes('/excel'))
    )
      imgSrc = '/xlsx.svg';

    obj.iconUrl = imgSrc;
    this.setState({ documents: [...this.state.documents, obj] });
    return;
  }

  leftPosition = () => {
    return {
      x: window.innerWidth / 8,
      y: window.innerHeight / 6,
    };
  };

  getCurrentCenter = () => {
    const scale = this.scale;
    const { x, y } = this.position;
    const { width, height } = currStage.size();
    const x1 = -x + width;
    const y1 = -y + height;
    const center = this.getCenter({ x: -x, y: -y }, { x: x1, y: y1 });
    return { x: center.x / scale, y: center.y / scale };
  };

  addTextEditor = (textDetails, defaultWidth = 'unset') => {
    const text = textDetails.text.trim();
    if (!text) return;
    const textProps = {
      elementType: 'text',
      bytesId: '',
      pageId: selectedPageId,
      fontUnderline: textDetails.underline ? true : false,
      fontWeight: textDetails.bold ? 600 : 400,
      fontStyle: textDetails.italic ? 'italic' : '',
      elementSize: textDetails.fontSize ? textDetails.fontSize : 16,
      fontFamily: 'Poppins',
      strikethrough: false,
      wordWrap: true,
      elementColor: '#000000',
      ...textDetails,
    };
    if (!textDetails.elementBounds || !textDetails.elementBounds.width) {
      const shadowDiv = document.createElement('div');
      this.myRef.current.appendChild(shadowDiv);
      shadowDiv.className = 'cnx-shadowDiv';
      shadowDiv.style.top = '10px';
      shadowDiv.style.left = '10px';
      shadowDiv.style.width = defaultWidth;
      shadowDiv.style.lineHeight = '1.5';
      shadowDiv.textContent = text;
      const textWidth = shadowDiv.getBoundingClientRect().width;
      const textHeight = shadowDiv.getBoundingClientRect().height;
      shadowDiv.parentNode.removeChild(shadowDiv);
      textProps.elementBounds = {
        x: (10 - this.position.x) / this.scale,
        y: (10 - this.position.y) / this.scale,
        width: textWidth * this.props.transformFactor,
        height: textHeight * this.props.transformFactor,
      };
      textProps.elementScale = {
        x: 1 / this.props.transformFactor / this.scale,
        y: 1 / this.props.transformFactor / this.scale,
      };
    }
    const item = this.createItem(textProps);
    this.addText(item, true, true);
    this.sendMessage(item, true, true);
  };

  destroyTransformers = () =>
    currStage.find('Transformer').forEach((transformer) => transformer.destroy());

  addText = (text, old, overrideBounds, drawTransformer = true) => {
    this.destroyTransformers();
    this.removeElement(this.pageLayouts[pageIds.indexOf(selectedPageId)], text.elementId);
    var simpleText = new Konva.Text({
      id: text.elementId,
      x: text.elementBounds.x,
      y: text.elementBounds.y,
      fill: text.elementColor || '#000000',
      // height: text.elementBounds.height + text.elementSize * this.props.transformFactor,
      width: text.elementBounds.width + text.elementSize * this.props.transformFactor,
      scaleX: text.elementScale.x,
      scaleY: text.elementScale.y,
      text: text.text,
      fontSize: text.elementSize * this.props.transformFactor,
      fontFamily: text.fontFamily || 'poppins',
      fontStyle: text.fontStyle
        ? `${text.fontStyle} ${text.fontWeight}`
        : text.fontWeight
        ? `${text.fontWeight}`
        : '',
      textDecoration: text.fontUnderline ? 'underline' : '',
      draggable: false,
      wrap: 'word',
      lineHeight: 1.5,
    });
    rotateAroundCenter(simpleText, text.elementAngle);
    this.pageLayouts[pageIds.indexOf(text.pageId)].add(simpleText);
    this.pageLayouts[pageIds.indexOf(selectedPageId)].draw();

    if (this.props.role === 'instructor') this.addTextTransformer(simpleText, text);
    if (drawTransformer && this.props.role === 'instructor')
      setTimeout(() => simpleText.fire('click'), 1000);

    return text;
  };

  playYoutubeVideo = (tm) => {
    var url = tm.url;
    this.setState({ localVideoURL: url });
    this.activateCloudVideoModal();
  };

  playLocalVideo = (tm) => {
    var url = tm.url;
    this.setState({ localVideoURL: url });
    this.activateVideoModal();
  };

  onDocumentComplete = (pages) => {
    this.setState({ page: 1, pages });
  };

  closeUpgradePlanDialog = () => {
    this.setState({
      upgradePlan: false,
    });
  };

  erase() {
    var ids = getChildrenIds(this.pageLayouts[pageIds.indexOf(selectedPageId)]);
    for (var i = 0; i < ids.length; i++) {
      var tm = { type: 'remove', elementId: ids[i] };
      this.sendMessage(tm);
    }
    let videos = this.state.videos;
    for (let j = 0; j < videos.length; j++) {
      const tm = { type: 'remove', elementId: videos[j].elementId };
      this.sendMessage(tm);
    }
    this.setState({ videos: [], documents: [] });
    this.pageLayouts[pageIds.indexOf(selectedPageId)].destroy();
    this.pageLayouts[pageIds.indexOf(selectedPageId)] = new Konva.Layer({ name: 'page' });
    currStage.add(this.pageLayouts[pageIds.indexOf(selectedPageId)]);
  }

  sendMessage = (msg = {}, pushToStack = true, transform = true) => {
    if (this.props.role !== 'instructor' || !msg) return;
    msg.classId = msg.classId || this.state.classId;
    msg.flowId = msg.flowId || this.state.flowId;
    msg.pageId = msg.pageId || selectedPageId;
    const clonedMessage = _.cloneDeep(msg);
    if (clonedMessage.type === 'polyline' && clonedMessage.stroke) {
      const coordinates = clonedMessage.stroke.trim().split(' ');
      const minMaxPos = minMaxPositions(coordinates);
      clonedMessage.elementBounds.width = clonedMessage.elementBounds.width || minMaxPos.width;
      clonedMessage.elementBounds.height = clonedMessage.elementBounds.height || minMaxPos.height;
      clonedMessage.stroke = coordinates.join(' ');
    }
    if (transform) {
      if (clonedMessage.elementBounds) {
        const transformedPosition = positioning.transform(
          clonedMessage.elementBounds.x,
          clonedMessage.elementBounds.y,
        );
        clonedMessage.elementBounds.x = transformedPosition.x;
        clonedMessage.elementBounds.y = transformedPosition.y;
        const transformedSize = positioning.transform(
          clonedMessage.elementBounds.width,
          clonedMessage.elementBounds.height,
        );
        clonedMessage.elementBounds.width = transformedSize.x;
        clonedMessage.elementBounds.height = transformedSize.y;
      }
      if (clonedMessage.type === 'manipulation') {
        clonedMessage.elementIds.forEach((_, index) => {
          const bounds = clonedMessage.endBounds[index];
          const { x, y } = positioning.transform(bounds.x, bounds.y);
          clonedMessage.endBounds[index] = { x, y };
        });
      }
      if (clonedMessage.type === 'polyline' && clonedMessage.stroke) {
        let coordinates = clonedMessage.stroke.trim().split(' ');
        coordinates = coordinates.map((coordinate) => positioning.transform(+coordinate, null).x);
        clonedMessage.stroke = coordinates.join(' ');
      }
    }

    if (msg.type === 'manipulation') {
      // Check to see if it's a duplicate manipulation message as a result of transformend event of both lasso transformer and elements being called
      const elementsCount = msg.elementIds.length;
      const isLassoManipulation = this.state.lassoItems.length;
      const isDuplicateMessage = isLassoManipulation && elementsCount === 1;
      if (isDuplicateMessage) return;
    }

    pushToStack && this.pushToStack(clonedMessage);

    if (
      ['polyline', 'video', 'text', 'image', 'brush', 'youtube', 'document'].includes(
        clonedMessage.type,
      )
    )
      this.addElementToState(clonedMessage);

    if (clonedMessage.type === 'manipulation') this.manipulateElementsState(clonedMessage);

    if (clonedMessage.type === 'remove') this.removeElementFromState(clonedMessage);

    socket.emit('message', JSON.stringify(clonedMessage));
  };

  /**
   * one of the use case
   * this displays the image when other user add image.
   * this method is called when a socket message is received
   *
   */
  addImage = (obj) => {
    const imageObj = new Image();
    imageObj.src = obj.s3Url || '/image.png';
    let imagePlaceHolder,
      imageLoaded = false;

    /** show loader while the image is loaded */
    let loaderImageElement = new Image();
    loaderImageElement.src = '/image.png';
    loaderImageElement.onload = () => {
      if (imageLoaded) return;
      const loaderDisplayData = {
        x: obj.elementBounds.x,
        y: obj.elementBounds.y,
        scale: { x: 1 / this.scale, y: 1 / this.scale },
        width: 100,
        height: 100,
      };
      imagePlaceHolder = new Konva.Image({
        ...loaderDisplayData,
        image: loaderImageElement,
        draggable: false,
      });
      const shapesLayer = this.pageLayouts[pageIds.indexOf(obj.pageId)];
      shapesLayer && shapesLayer.add(imagePlaceHolder);
    };

    imageObj.onload = () => {
      imageLoaded = true;
      const imagePlot = new Konva.Image({
        x: obj.elementBounds.x,
        y: obj.elementBounds.y,
        width: +obj.elementBounds.width,
        height: +obj.elementBounds.height,
        image: imageObj,
        id: obj.elementId,
        scaleX: obj.elementScale.x,
        scaleY: obj.elementScale.y,
      });
      rotateAroundCenter(imagePlot, obj.elementAngle || 0);
      const shapesLayer = this.pageLayouts[pageIds.indexOf(obj.pageId)];
      shapesLayer && shapesLayer.add(imagePlot);
      this.addImageTransformer(imagePlot, obj);
      imagePlaceHolder && imagePlaceHolder.destroy(); /** remove the loader image */
    };
  };

  addItem = (obj) => {
    if (obj.type === 'image-changed') {
      let existingImage = this.pageLayouts[pageIds.indexOf(obj.pageId)].findOne(
        '#' + obj.elementId,
      );
      if (!existingImage) return;
      let imageWidth = existingImage.getAttr('width');
      let imageHeight = existingImage.getAttr('height');
      var imagePlot;
      var imageObj = new Image();
      imageObj.onload = () => {
        if (!existingImage) return;
        imagePlot = new Konva.Image({
          x: existingImage.x(),
          y: existingImage.y(),
          width: imageWidth,
          height: imageHeight,
          id: obj.elementId,
          image: imageObj,
          scaleX: existingImage.scaleX(),
          scaleY: existingImage.scaleY(),
        });
        rotateAroundCenter(imagePlot, existingImage.rotation());
        existingImage.destroy();
        this.pageLayouts[pageIds.indexOf(obj.pageId)].add(imagePlot);
        if (this.props.role === 'instructor') {
          imagePlot.setAttr('draggable', true);
          this.destroyTransformers();
          this.addImageTransformer(imagePlot, obj);
        }
      };
      if (obj.s3Url) imageObj.src = obj.s3Url;
    }
  };

  removeElement = (konvaLayerObj, eleID, removeTransformer = true) => {
    removeTransformer && this.removeTransformer();
    konvaLayerObj.getChildren((node) => {
      if (node.getAttr('id') === eleID) node.destroy();
      currStage.batchDraw();
    });
    // remove video from state here
    let isVideo = this.state.videos.filter((video) => video.elementId === eleID);
    let isDoc = this.state.documents.filter((doc) => doc.elementId === eleID);
    if (isVideo && isVideo.length)
      this.setState({
        videos: this.state.videos.filter((video) => video.elementId !== eleID),
      });
    if (isDoc && isDoc.length)
      this.setState({
        documents: this.state.documents.filter((doc) => doc.elementId !== eleID),
      });
  };

  getElement = (elementID) => {
    let isFound = false;
    // @ts-ignore
    this.pageLayouts[pageIds.indexOf(selectedPageId)].getChildren((node) => {
      isFound = node.getAttr('id') === elementID;
    });
    return isFound;
  };

  createItem = (itemObj) => {
    let newItem = {};
    newItem.elementId = itemObj.elementId || generateUUID();
    newItem.elementBounds = itemObj.elementBounds || { x: 0, y: 0, width: 0, height: 0 };
    newItem.elementScale = itemObj.elementScale || { x: 1, y: 1 };
    newItem.elementType = itemObj.elementType || 'polyline';
    newItem.type = itemObj.elementType || 'polyline';
    newItem.pageId = itemObj.pageId || selectedPageId;
    newItem.elementAngle = itemObj.elementAngle || 0;
    if (newItem.elementType === 'polyline') {
      newItem.elementColor = itemObj.stroke || '';
      newItem.elementSize = itemObj.strokeWidth || 1;
      newItem.fill = { filled: false, color: '', opacity: 1 };
      newItem.isShape = !!itemObj.isShape;
      newItem.stroke = itemObj.points || [];
      newItem.elementOpacity = itemObj.elementOpacity || itemObj.opacity || 1;
    }
    newItem.creatorId = creatorId;
    newItem.creatorName = creatorName;
    newItem.timestamp = Date.now();
    newItem.socketId = '';
    newItem.classId = this.state.classId;
    newItem.flowId = this.state.flowId;
    newItem.zIndex = this.state.zIndex + 1;
    this.setState({ zIndex: newItem.zIndex });
    newItem.isLocked = false;
    newItem.groupId = '';
    newItem.groupingEnabled = false;
    if (itemObj.elementType === 'image') {
      newItem.thumbnail = { url: '', height: 30, width: 30 };
      newItem.fileName = '';
      newItem.fileFormat = '';
      newItem.s3Url = itemObj.s3Url || '';
      newItem.s3MetaData = !_.isEmpty(itemObj.s3MetaData) ? itemObj.s3MetaData : {};
    }
    if (itemObj.elementType === 'video') {
      newItem.thumbnail = { url: '', height: 30, width: 30 };
      newItem.fileName = itemObj.fileName || '';
      newItem.fileFormat = itemObj.fileFormat || '';
      newItem.mimeType = itemObj.mimeType || '';
      newItem.s3Url = itemObj.s3Url || '';
      newItem.s3MetaData = !_.isEmpty(itemObj.s3MetaData) ? itemObj.s3MetaData : {};
    }
    if (itemObj.elementType === 'youtube') {
      newItem.thumbnail = { url: '', height: 30, width: 30 };
      newItem.fileName = '';
      newItem.fileFormat = '';
      newItem.mimeType = '';
      newItem.s3Url = itemObj.s3Url || '';
    }
    if (itemObj.elementType === 'document') {
      newItem.thumbnail = { url: '', height: 30, width: 30 };
      newItem.fileName = itemObj.fileName || '';
      newItem.fileFormat = itemObj.fileFormat || '';
      newItem.mimeType = itemObj.mimeType || '';
      newItem.s3Url = itemObj.s3Url || '';
      newItem.s3MetaData = !_.isEmpty(itemObj.s3MetaData) ? itemObj.s3MetaData : {};
    }
    if (itemObj.elementType === 'text') {
      newItem.fontUnderline = !!itemObj.fontUnderline;
      newItem.text = itemObj.text || '';
      newItem.fontWeight = itemObj.fontWeight || '';
      newItem.fontStyle = itemObj.fontStyle || '';
      newItem.elementSize = itemObj.elementSize || '';
      newItem.elementColor = itemObj.elementColor || '#000000';
      newItem.fontFamily = itemObj.fontFamily || '';
      newItem.strikethrough = !!itemObj.strikethrough;
      newItem.wordWrap = !!itemObj.wordWrap;
    }
    return newItem;
  };

  updatePolyline = (item) => {
    if (currStage.findOne('#' + item.elementId)) return;
    const points = item.stroke
      .trim()
      .split(' ')
      .map((n) => +n);
    if (points.length === 2) points.push(points[0], points[1], points[0] + 0.01, points[1]);
    if (points.length === 4) points.push(points[2] + 0.01, points[3]);
    const box = new Konva.Line({
      x: parseFloat(item.elementBounds.x),
      y: parseFloat(item.elementBounds.y),
      stroke: item.elementColor,
      strokeWidth: parseFloat(item.elementSize) * this.props.transformFactor,
      draggable: false,
      width: parseFloat(item.elementBounds.width),
      height: parseFloat(item.elementBounds.height),
      scaleX: parseFloat(item.elementScale.x),
      scaleY: parseFloat(item.elementScale.y),
      lineJoin: 'round',
      lineCap: 'round',
      bezier: false,
      id: item.elementId,
      tension: 0,
      opacity: item.elementOpacity,
      points,
    });
    rotateAroundCenter(box, item.elementAngle);
    this.setHitStrokeWidth(box);
    this.addLineTransformer(box, item);
    this.pageLayouts[pageIds.indexOf(item.pageId)].add(box);
    this.pageLayouts[pageIds.indexOf(selectedPageId)].draw();
  };

  createLine = (
    x,
    y,
    lineColor = this.props.penColor,
    lineWidth = this.props.brushSize,
    lineOpacity = this.props.brushOpacity,
    dashed = false,
  ) => {
    var item;
    var lineObj;
    item = this.createItem({
      elementType: 'polyline',
      opacity: this.props.brushOpacity,
      strokeWidth: lineWidth / this.scale,
    });
    lineObj = new Konva.Line({
      points: [x, y, x, y],
      stroke: lineColor || this.props.penColor,
      id: dashed ? 'lasso-line' : item.elementId,
      customObj: item,
      strokeWidth: (lineWidth * this.props.transformFactor) / this.scale,
      opacity: lineOpacity || this.props.brushOpacity,
      lineCap: 'round',
      lineJoin: 'round',
      bezier: true,
      tension: 0.4,
      dash: dashed ? [5 / this.scale, 10 / this.scale] : [],
    });
    this.setHitStrokeWidth(lineObj);
    this.addLineTransformer(lineObj, item);
    this.pageLayouts[pageIds.indexOf(selectedPageId)].add(lineObj);
    this.pageLayouts[pageIds.indexOf(selectedPageId)].draw();
    return lineObj;
  };

  createShape = (
    points,
    lineColor = this.props.penColor,
    lineWidth = this.props.brushSize,
    lineOpacity = this.props.brushOpacity,
  ) => {
    var item;
    var lineObj;
    item = this.createItem({
      elementType: 'polyline',
      opacity: this.props.brushOpacity,
      isShape: true,
      strokeWidth: lineWidth / this.scale,
    });
    lineObj = new Konva.Line({
      points: points,
      stroke: lineColor || this.props.penColor,
      id: item.elementId,
      customObj: item,
      strokeWidth: (lineWidth * this.props.transformFactor) / this.scale,
      opacity: lineOpacity || this.props.brushOpacity,
      lineCap: 'round',
      lineJoin: 'round',
      isShape: true,
    });
    this.setHitStrokeWidth(lineObj);
    this.addLineTransformer(lineObj, item);
    this.pageLayouts[pageIds.indexOf(selectedPageId)].add(lineObj);
    this.pageLayouts[pageIds.indexOf(selectedPageId)].draw();
    return lineObj;
  };

  enteredFullScreenMode = async () => {
    if (fullScreen) {
      await sleep(1000);
      fullScreen = !fullScreen;
    } else fullScreen = !fullScreen;
  };

  checkIsCanvasFocused = () => {
    return document.activeElement?.id === 'container';
  };

  updateMousePosition = _.throttle(this.props.updateMousePosition, 100);

  /**
   *
   * @param {{
   *   type: string;
   *   feature: string;
   *   internalFeature: string
   * }} msg
   */
  handleSocketExceptionsMessages = (msg) => {
    const { type, feature } = msg;
    licenseExceptionHandler({ type, feature });
  };

  async componentDidMount() {
    document.addEventListener('keydown', this.zoomPanHandler, true);
    document.addEventListener('wheel', this.preventPageZoom, { capture: true, passive: false });
    const zoomPanWrapper = this.canvasComponentRef.current;
    zoomPanWrapper.addEventListener('gesturestart', this.zoomPanHandler, true);
    zoomPanWrapper.addEventListener('gesturechange', this.zoomPanHandler, true);
    zoomPanWrapper.addEventListener('gestureend', this.zoomPanHandler, true);
    zoomPanWrapper.addEventListener('wheel', this.zoomPanHandler, true);
    positioning = new Positioning(1280, 720);
    socket = socketIOClient(config.BIRDAI_SOCKET_SERVER_URL, {
      path: '/v1',
      query: {
        userToken: getToken() || '',
        flowId: getBoardInfo().flowId,
      },
      reconnection: true,
      transports: ['websocket'],
      upgrade: true,
      autoConnect: true,
    });
    socket.on('connect', () => {
      this.loginToSocket();
      initSocket(socket);
    });
    socket.on('disconnect', () => {
      // resetSocket();
    });

    socket.on('message', this.handleSocket);
    socket.on('exception', this.handleSocketExceptionsMessages);
    this.setState({
      showLoader: true,
      loaderMessage: 'Loading Space...',
    });
    this.renderKonva();
    if (this.props.role === 'instructor') this.setEvent();
    await this.getFlowItem();
    document.addEventListener('fullscreenchange', this.enteredFullScreenMode, false);
    document.addEventListener('webkitfullscreenchange', this.enteredFullScreenMode, false);
    document.addEventListener('mozfullscreenchange', this.enteredFullScreenMode, false);
    this.enableShortCutKeys();

    document.addEventListener(
      'paste',
      (thePasteEvent) => {
        const randomPositionChange = (20 + Math.random() * 20) / this.scale;
        if (!this.enablePaste || this.props.inactivateAll) return;
        if (!this.checkIsCanvasFocused()) return;
        if (!thePasteEvent.clipboardData) return;
        const items = thePasteEvent.clipboardData.items;
        if (!items.length) return;
        const lastItem = items[0];
        if (lastItem.kind === 'string') {
          if (lastItem.type.match('^text/plain')) {
            lastItem.getAsString((data) => {
              try {
                const parsedData = JSON.parse(data);
                if (parsedData.type === 'kneura') {
                  const copiedElements = parsedData.elements;
                  const clonedElementsIds = [];
                  for (const copiedElement of copiedElements) {
                    const { timestamp, socketId, ...details } = copiedElement;
                    const { x, y, width, height } = details.elementBounds;
                    const elementId = generateUUID();
                    const clonedElement = {
                      ...details,
                      elementBounds: {
                        x: x + randomPositionChange,
                        y: y + randomPositionChange,
                        width,
                        height,
                      },
                      elementId,
                      creatorId: creatorId,
                      creatorName: creatorName,
                      timestamp: Date.now(),
                      socketId: '',
                      classId: this.state.classId,
                      flowId: this.state.flowId,
                      pageId: selectedPageId,
                      zIndex: this.state.zIndex + 1,
                    };
                    this.sendMessage(clonedElement, true, false);
                    this.handleSocket(clonedElement, true, false);
                    clonedElementsIds.push(elementId);
                  }
                  setTimeout(() => {
                    const shapesLayer = this.pageLayouts[pageIds.indexOf(selectedPageId)];
                    const nodes = shapesLayer.getChildren((node) =>
                      clonedElementsIds.includes(node.getAttr('id')),
                    );
                    this.selectIntersections(undefined, nodes);
                  }, 500);
                } else {
                  this.addTextEditor({ text: data }, '60%');
                }
              } catch (error) {
                this.addTextEditor({ text: data }, '60%');
              }
            });
          } else if (lastItem.type.match('^text/html')) {
            lastItem.getAsString(async (htmlText) => {
              const parser = new DOMParser();
              const doc = parser.parseFromString(htmlText, 'text/html');
              const htmlImage = doc.querySelector('img');
              if (htmlImage) {
                const data = await fetch(htmlImage.src);
                const blob = await data.blob();
                this.uploadImage(blob);
              }
            });
          }
        } else if (lastItem.kind === 'file' && lastItem.type.match('^image/')) {
          const file = lastItem.getAsFile();
          this.uploadImage(file);
        }
      },
      false,
    );
  }

  preventPageZoom = (e) => {
    if (e.ctrlKey) {
      e.preventDefault();
    }
  };

  getFlowItem = (options = { disableCORS: false }) => {
    var url = Config.flowItemURL + this.state.flowId;
    return new Promise((resolve, reject) => {
      get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(async (response) => {
          this.toolBar.updateAction();
          if (response.status === 200) {
            try {
              const data = response.data.data;
              this.Background.setBackgroundCollection(response.data.data.backgroundCollection);
              /*  case 1 : on time of presentation mode presenter try upload any from media option pagination gets disabled for presenter.
                  case 2: on time of who others are receiving the presentation mode those try to upload any from media option for them pagination is enabled even presenter is presentation.
                  ( this 2 cases are occurring some times , do to this 2 cases to resolve below code was commented)

               if (response.data.data.isPresenting) {
                 this.props.changePresentationMode(true);
               } else {
                 this.props.changePresentationMode(false);
               }
               */
              const { disableCORS } = options;

              this.setState({ elements: _.cloneDeep(data.items) });

              await this.drawCanvas({ ...data, disableCORS });
              resolve(true);
              this.resetHitStrokes();
              this.props.getallUser(data.users);

              this.setState({
                items: [...data.items],
              });

              let pageIndex = data.pageCollection.indexOf(data.selectedPageId);
              let selectedPageIndex = 0;
              if (pageIndex === -1) {
                selectedPageIndex = 0;
              } else {
                selectedPageIndex = pageIndex;
              }
              await this.pagination.pageSelect(selectedPageIndex, true, data.pageCollection);
              this.setState({ showLoader: false });
            } catch (error) {
              this.setState({ showLoader: false });
              console.log(error);
            }
          }
        })
        .catch(async (err) => {
          this.setState({ showLoader: false });
          console.log('ERROR - ', err);
          this.canvasErrorHandle();
          await this.pageSelect(pageIds[0]);
          reject(err);
        });
    });
  };

  canvasErrorHandle = async () => {
    pageIds = [0];
    selectedPageId = 0;
    selectedPageIndex = 1;
    await this.setStateSynchronous({
      videos: [],
    });
    for (var i = 0; i < pageIds.length; i++) {
      this.pageLayouts[i] = new Konva.Layer({ name: 'page' });
    }

    var text = new Konva.Text({
      x: 100,
      y: 150,
      text: 'Unable to Connect to WhiteBoard',
      fontSize: 30,
    });
    var text1 = new Konva.Text({
      x: 120,
      y: 200,
      text: 'Please Refresh the page',
      fontSize: 30,
    });
    this.pageLayouts[0].add(text);
    this.pageLayouts[0].add(text1);
    this.pageLayouts[0].draw();
  };

  setHitStrokeWidth = (node, scale = this.scale) => {
    if (typeof node.getAttr('text') === 'string') return;
    let initialStrokeWidth = node.getAttr('strokeWidth');
    if (!initialStrokeWidth) return;
    const actualStrokeWidth = initialStrokeWidth * node.scaleX() * scale;
    const hitStrokeWidth = actualStrokeWidth > 20 ? initialStrokeWidth : 20 / node.scaleX() / scale;
    node.setAttrs({ hitStrokeWidth });
  };

  resetHitStrokes = (scale = this.scale) => {
    const shapesLayer = this.pageLayouts[pageIds.indexOf(selectedPageId)];
    if (!shapesLayer) return;
    shapesLayer
      .find((node) => typeof node.getAttr('hitStrokeWidth') === 'number')
      .forEach((node) => {
        this.setHitStrokeWidth(node, scale);
      });
  };

  positionTransformerOptionButton = () => {
    /**@type {Konva.Transformer | undefined} */
    const transformer = currStage.findOne('Transformer');
    if (!transformer) return;
    const transformerChildren = transformer?.getChildren();
    const optionsButton = transformerChildren?.find((node) => node.getType() === 'Group');
    optionsButton && optionsButton.x(transformer.width());
  };

  removeTransformer = () => {
    const transformers = currStage.find('Transformer');
    transformers.forEach((transformer) =>
      // @ts-ignore
      transformer.nodes([]),
    );
    let lassoLineElement = currStage.findOne('#lasso-line');
    if (lassoLineElement) lassoLineElement.remove();
    this.setState({ lassoItems: [] });
    document.body.style.cursor = 'default';
    this.destroyTransformers();
    const shapesLayer = this.pageLayouts[pageIds.indexOf(selectedPageId)];
    if (shapesLayer) {
      // @ts-ignore
      shapesLayer.getChildren((node) => {
        node.setAttr('draggable', false);
      });
    }
    this.setState({ openMenu: false });
  };

  transformerClickHandler = async () => {
    // To avoid removing the transformer before detecting double click event of it
    this.doubleClickTimer && clearTimeout(this.doubleClickTimer);
    this.clickCounter++;
    this.doubleClickTimer = setTimeout(() => (this.clickCounter = 0), 200);
    if (this.clickCounter < 2) {
      setTimeout(() => {
        if (this.clickCounter > 1) return;
        if (this.state.openMenu) return;
        // For selecting elements above transformer on clicking on them
        this.destroyTransformers();
        const pointerPosition = currStage.getPointerPosition();
        const shapesLayer = this.pageLayouts[pageIds.indexOf(selectedPageId)];
        setTimeout(() => {
          const target = pointerPosition && shapesLayer.getIntersection(pointerPosition);
          target && target.fire('click');
        }, 200);
      }, 200);
    }
  };

  addTextTransformer = (simpleText, text) => {
    if (this.props.role !== 'instructor') return;
    const updatedText = JSON.parse(JSON.stringify(text));
    const editHandler = () => {
      this.isAddingText = true;
      this.setCursor('auto');
      this.toolBar.textClick();
      simpleText.hide();
      const shadowDiv = document.createElement('div');
      const textarea = document.createElement('textarea');
      this.myRef.current.appendChild(shadowDiv);
      this.myRef.current.appendChild(textarea);
      shadowDiv.className = 'cnx-shadowDiv';
      textarea.className = 'cnx-textarea';
      const style = {
        top: simpleText.y() * this.scale + currStage.y() + 'px',
        left: simpleText.x() * this.scale + currStage.x() + 'px',
        fontFamily: simpleText.fontFamily(),
        fontSize: simpleText.fontSize() + 'px',
        lineHeight: simpleText.lineHeight(),
        color: simpleText.fill(),
        transform: `scale(${simpleText.scaleX()},${simpleText.scaleY()})`,
      };
      Object.keys(style).forEach((property) => {
        shadowDiv.style[property] = style[property];
        textarea.style[property] = style[property];
      });
      shadowDiv.textContent = simpleText.text();
      textarea.value = simpleText.text();
      textarea.placeholder = 'Start typing';
      if (!shadowDiv.parentElement) return;
      const parentWidth = shadowDiv.parentElement.getBoundingClientRect().width;
      const maxWidth = (parentWidth - shadowDiv.offsetLeft) / simpleText.scaleX() - 20 + 'px';
      shadowDiv.style.maxWidth = maxWidth;
      textarea.style.width = shadowDiv.getBoundingClientRect().width / simpleText.scaleX() + 'px';
      textarea.style.height = 'auto';
      let transform = `scale(${simpleText.scaleX() * this.scale},${
        simpleText.scaleY() * this.scale
      })`;
      const rotation = simpleText.rotation();
      if (rotation) {
        transform += 'rotateZ(' + rotation + 'deg)';
      }
      var px = 0;
      // also we need to slightly move textarea on firefox
      // because it jumps a bit
      var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      if (isFirefox) {
        px += 2 + Math.round(simpleText.fontSize() / 20);
      }
      transform += 'translateY(-' + px + 'px)';
      textarea.style.transform = transform;
      textarea.style.height = textarea.scrollHeight + 3 + 'px';
      setTimeout(() => textarea.focus());
      textarea.addEventListener('input', () => {
        shadowDiv.textContent = textarea.value || 'Start typing';
        const textWidth = shadowDiv.getBoundingClientRect().width;
        if (!shadowDiv.parentElement) return;
        const parentWidth = shadowDiv.parentElement.getBoundingClientRect().width;
        const maxWidth = (parentWidth - shadowDiv.offsetLeft) / this.scale - 20 * this.scale;
        const width = (textWidth > maxWidth ? maxWidth : textWidth) / simpleText.scaleX();
        textarea.style.width = width + 'px';
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 3 + 'px';
      });
      textarea.addEventListener('blur', () => {
        const elementId = simpleText.getAttr('id');
        if (!textarea.value.trim()) {
          if (textarea.parentNode) textarea.parentNode.removeChild(textarea);
          if (shadowDiv.parentNode) shadowDiv.parentNode.removeChild(shadowDiv);
          this.isAddingText = false;
          this.deleteElement(elementId);
          return;
        }
        this.toolBar.textEditorDialogClose();
        const text = {
          elementId,
          elementBounds: {
            x: simpleText.x(),
            y: simpleText.y(),
            width: textarea.getBoundingClientRect().width / simpleText.scaleX() / this.scale,
            height: textarea.getBoundingClientRect().height / simpleText.scaleY() / this.scale,
          },
          elementScale: { x: simpleText.scaleX(), y: simpleText.scaleY() },
          text: textarea.value,
          elementSize: simpleText.fontSize() / this.props.transformFactor,
          fontFamily: simpleText.fontFamily(),
          elementAngle: rotation,
          elementType: 'text',
          bytesId: '',
          fontUnderline: false,
          fontWeight: 400,
          fontStyle: '',
          strikethrough: false,
          wordWrap: true,
          elementColor: '#000000',
        };
        const item = this.createItem(text);
        this.addText(item, true, true);
        this.sendMessage({ ...item, type: 'item-changed', originalType: 'text' }, true, true);
        if (textarea.parentNode) textarea.parentNode.removeChild(textarea);
        if (shadowDiv.parentNode) shadowDiv.parentNode.removeChild(shadowDiv);
        this.isAddingText = false;
      });
    };
    simpleText.on('dblclick dbltap', editHandler);
    simpleText.on('click tap', () => {
      this.textClickTimer && clearTimeout(this.textClickTimer);
      this.textClickCounter++;
      this.textClickTimer = setTimeout(() => (this.textClickCounter = 0), 200);
      if (this.textClickCounter < 2) {
        setTimeout(() => {
          if (this.clickCounter > 1) return;
          if (this.props.activeAction.select && !this.isZooming) {
            simpleText.setAttr('draggable', true);
            updatedText.value = updatedText.text;
            updatedText.bold = updatedText.fontweight && updatedText.fontweight === 'bold';
            updatedText.underline = updatedText.fontunderline;
            updatedText.italic = updatedText.fontstyle && updatedText.fontstyle === 'italic';
            updatedText.fontSize = updatedText.elementSize;
            updatedText.update = true;
            this.removeTransformer();
            simpleText.setAttr('draggable', true);
            this.setState({
              elementMenuItems: [
                {
                  name: 'Edit',
                  handler: editHandler,
                },
                {
                  name: 'Delete',
                  handler: () => {
                    this.sendMessage({
                      type: 'remove',
                      elementId: text.elementId,
                    });
                    this.removeElement(
                      this.pageLayouts[pageIds.indexOf(selectedPageId)],
                      text.elementId,
                    );
                  },
                },
                {
                  name: 'Bring to Front',
                  handler: () => {
                    simpleText.moveToTop();
                    this.removeTransformer();
                  },
                },
                {
                  name: 'Send to Back',
                  handler: () => {
                    simpleText.moveToBottom();
                    this.removeTransformer();
                  },
                },
                {
                  name: 'Chat',
                  handler: () =>
                    this.toggleChat({
                      elementId: simpleText.getAttr('id'),
                      contentUrl: '',
                      type: 'TEXT',
                      text: simpleText.text(),
                    }),
                },
              ],
            });
            const simpleTextTr = new Konva.Transformer({
              node: simpleText,
              flipEnabled: false,
              enabledAnchors: ['top-left', 'bottom-right'],
              boundBoxFunc: (oldBox, newBox) => {
                if (Math.abs(newBox.width) < 40) {
                  return oldBox;
                }
                return newBox;
              },
              keepRatio: true,
              borderDash: [5, 5],
              rotationSnaps: [0, 90, 180, 270],
              elementId: text.elementId,
              shouldOverdrawWholeArea: true,
            });
            let menuGroup = new Konva.Group({
              x: simpleTextTr.getWidth(),
              y: 0,
            });
            var imageObj = new Image();
            imageObj.src = select_item_menu;
            imageObj.onload = () => {
              let menuImgButton = new Konva.Image({
                height: 20,
                width: 20,
                image: imageObj,
              });
              menuGroup.add(menuImgButton);
              simpleTextTr.add(menuGroup);
              this.pageLayouts[pageIds.indexOf(selectedPageId)].add(simpleTextTr);
              this.pageLayouts[pageIds.indexOf(selectedPageId)].draw();
            };
            simpleTextTr.on('click', this.transformerClickHandler);
            simpleTextTr.on('transform', () => {
              menuGroup.x(simpleTextTr.getWidth());
            });
            simpleTextTr.on('dblclick dbltap', editHandler);
            menuGroup.on('click tap', (e) => {
              this.setState({
                mouseX: e.evt.x !== undefined ? e.evt.x : e.evt.changedTouches[0].clientX,
                mouseY: e.evt.y !== undefined ? e.evt.y : e.evt.changedTouches[0].clientY,
                openMenu: true,
              });
            });
            this.pageLayouts[pageIds.indexOf(selectedPageId)].add(simpleTextTr);
            simpleTextTr.attachTo(simpleText);
            this.pageLayouts[pageIds.indexOf(selectedPageId)].draw();
          }
        }, 200);
      }
    });

    simpleText.on('transformend', (e) => {
      const rotation = simpleText.rotation();
      rotateAroundCenter(simpleText, 0);
      this.pageLayouts[pageIds.indexOf(selectedPageId)].draw();
      const manipulationObj = {
        type: 'manipulation',
        flowId: this.state.flowId,
        classId: this.state.classId,
        elementIds: [text.elementId],
        endBounds: [
          {
            x: simpleText.x(),
            y: simpleText.y(),
          },
        ],
        endScales: [
          {
            x: simpleText.scaleX(),
            y: simpleText.scaleY(),
          },
        ],
        angles: [rotation],
      };
      rotateAroundCenter(simpleText, rotation);
      this.sendMessage(manipulationObj);
    });
    simpleText.on('dragmove', () => (document.body.style.cursor = 'grabbing'));
    simpleText.on('dragend', () => {
      const rotation = simpleText.rotation();
      rotateAroundCenter(simpleText, 0);
      document.body.style.cursor = 'default';
      updatedText.elementBounds.x = simpleText.x();
      updatedText.elementBounds.y = simpleText.y();
      const manipulationObj = {
        type: 'manipulation',
        flowId: this.state.flowId,
        classId: this.state.classId,
        elementIds: [text.elementId],
        endBounds: [
          {
            x: simpleText.x(),
            y: simpleText.y(),
          },
        ],
        endScales: [
          {
            x: simpleText.scaleX(),
            y: simpleText.scaleY(),
          },
        ],
        angles: [rotation],
      };
      rotateAroundCenter(simpleText, rotation);
      this.sendMessage(manipulationObj);
    });
  };

  activateLineTransformer = (simpleLine, item, e) => {
    this.removeTransformer();
    simpleLine.setAttr('draggable', true);
    this.setState({
      elementMenuItems: [
        {
          name: 'Delete',
          handler: () => this.deleteElement(item.elementId),
        },
        {
          name: 'Bring to Front',
          handler: () => {
            simpleLine.moveToTop();
            this.removeTransformer();
          },
        },
        {
          name: 'Send to Back',
          handler: () => {
            simpleLine.moveToBottom();
            this.removeTransformer();
          },
        },
      ],
    });
    var simpleLineTr = new Konva.Transformer({
      node: e.currentTarget,
      enabledAnchors: ['top-left', 'bottom-right'],
      boundBoxFunc: (oldBox, newBox) => newBox,
      keepRatio: true,
      borderDash: [5, 5],
      rotationSnaps: [0, 90, 180, 270],
      elementId: item.elementId,
      shouldOverdrawWholeArea: true,
      flipEnabled: false,
    });
    let menuGroup = new Konva.Group({
      x: simpleLineTr.getWidth(),
      y: 0,
    });
    var imageObj = new Image();
    imageObj.src = select_item_menu;
    imageObj.onload = () => {
      let menuImgButton = new Konva.Image({
        height: 20,
        width: 20,
        image: imageObj,
      });
      menuGroup.add(menuImgButton);
      simpleLineTr.add(menuGroup);
      this.pageLayouts[pageIds.indexOf(selectedPageId)].add(simpleLineTr);
      this.pageLayouts[pageIds.indexOf(selectedPageId)].draw();
    };
    simpleLineTr.on('click', this.transformerClickHandler);
    simpleLineTr.on('transform', () => menuGroup.x(simpleLineTr.getWidth()));
    menuGroup.on('click tap', (e) => {
      this.setState({
        mouseX: e.evt.x !== undefined ? e.evt.x : e.evt.changedTouches[0].clientX,
        mouseY: e.evt.y !== undefined ? e.evt.y : e.evt.changedTouches[0].clientY,
        openMenu: true,
      });
    });
  };

  addLineTransformer = (simpleLine, item) => {
    if (this.props.role !== 'instructor') return;
    simpleLine.on('click tap', (e) => {
      if (this.props.activeAction.select && !this.isZooming) {
        this.activateLineTransformer(simpleLine, item, e);
      }
    });
    simpleLine.on('transformend', () => {
      const rotation = simpleLine.rotation();
      rotateAroundCenter(simpleLine, 0);
      this.setHitStrokeWidth(simpleLine);
      const manipulationObj = {
        type: 'manipulation',
        flowId: this.state.flowId,
        classId: this.state.classId,
        elementIds: [item.elementId],
        endBounds: [
          {
            x: simpleLine.x(),
            y: simpleLine.y(),
          },
        ],
        endScales: [
          {
            x: simpleLine.scaleX(),
            y: simpleLine.scaleY(),
          },
        ],
        angles: [rotation],
      };
      rotateAroundCenter(simpleLine, rotation);
      this.sendMessage(manipulationObj);
    });
    simpleLine.on('dragmove', () => (document.body.style.cursor = 'grabbing'));
    simpleLine.on('dragend', (e) => {
      document.body.style.cursor = 'default';
      const rotation = simpleLine.rotation();
      rotateAroundCenter(simpleLine, 0);
      const manipulationObj = {
        type: 'manipulation',
        flowId: this.state.flowId,
        classId: this.state.classId,
        elementIds: [item.elementId],
        endBounds: [
          {
            x: simpleLine.x(),
            y: simpleLine.y(),
          },
        ],
        endScales: [
          {
            x: simpleLine.scaleX(),
            y: simpleLine.scaleY(),
          },
        ],
        angles: [rotation],
      };
      rotateAroundCenter(simpleLine, rotation);
      this.sendMessage(manipulationObj);
    });
    simpleLine.on('mouseover touchmove', (event) => {
      if (this.props.activeAction.eraser && this.state.strokeErase && !this.isZooming) {
        setTimeout(
          () => {
            if (!this.isZooming) {
              var line = simpleLine.getAttrs();
              this.deleteElement(line.id);
            }
          },
          event.evt.type === 'touchmove' ? 100 : 0,
        );
      }
    });
  };

  addObjectTransformer = (obj, item) => {
    if (this.props.role !== 'instructor') return;
    obj.on('click tap', (e) => {
      if (this.props.activeAction.select && !this.isZooming) {
        this.removeTransformer();
        obj.setAttr('draggable', true);
        this.setState({
          elementMenuItems: [
            {
              name: 'Delete',
              handler: () => this.deleteElement(item.elementId),
            },
            {
              name: 'Bring to Front',
              handler: () => {
                obj.moveToTop();
                this.removeTransformer();
              },
            },
            {
              name: 'Send to Back',
              handler: () => {
                obj.moveToBottom();
                this.removeTransformer();
              },
            },
          ],
        });
        var simpleLineTr = new Konva.Transformer({
          node: e.currentTarget,
          enabledAnchors: [],
          boundBoxFunc: (oldBox, newBox) => newBox,
          keepRatio: true,
          borderDash: [5, 5],
          rotationSnaps: [0, 90, 180, 270],
          elementId: item.elementId,
          shouldOverdrawWholeArea: true,
          flipEnabled: false,
        });
        let menuGroup = new Konva.Group({
          x: simpleLineTr.getWidth(),
          y: 0,
        });
        var imageObj = new Image();
        imageObj.src = select_item_menu;
        imageObj.onload = () => {
          let menuImgButton = new Konva.Image({
            height: 20,
            width: 20,
            image: imageObj,
          });
          menuGroup.add(menuImgButton);
          simpleLineTr.add(menuGroup);
          this.pageLayouts[pageIds.indexOf(selectedPageId)].add(simpleLineTr);
          this.pageLayouts[pageIds.indexOf(selectedPageId)].draw();
        };
        simpleLineTr.on('click', this.transformerClickHandler);
        simpleLineTr.on('transform', () => menuGroup.x(simpleLineTr.getWidth()));
        menuGroup.on('click tap', (e) => {
          this.setState({
            mouseX: e.evt.x !== undefined ? e.evt.x : e.evt.changedTouches[0].clientX,
            mouseY: e.evt.y !== undefined ? e.evt.y : e.evt.changedTouches[0].clientY,
            openMenu: true,
          });
        });
      }
    });
    obj.on('transformend', () => {
      this.setHitStrokeWidth(obj);
      const rotation = obj.rotation();
      rotateAroundCenter(obj, 0);
      const manipulationObj = {
        type: 'manipulation',
        flowId: this.state.flowId,
        classId: this.state.classId,
        elementIds: [item.elementId],
        endBounds: [
          {
            x: obj.x(),
            y: obj.y(),
          },
        ],
        endScales: [
          {
            x: obj.scaleX(),
            y: obj.scaleY(),
          },
        ],
        angles: [rotation],
      };
      rotateAroundCenter(obj, rotation);
      this.sendMessage(manipulationObj);
    });
    obj.on('dragmove', () => (document.body.style.cursor = 'grabbing'));
    obj.on('dragend', () => {
      document.body.style.cursor = 'default';
      const rotation = obj.rotation();
      rotateAroundCenter(obj, 0);
      const manipulationObj = {
        type: 'manipulation',
        flowId: this.state.flowId,
        classId: this.state.classId,
        elementIds: [item.elementId],
        endBounds: [
          {
            x: obj.x(),
            y: obj.y(),
          },
        ],
        endScales: [
          {
            x: obj.scaleX(),
            y: obj.scaleY(),
          },
        ],
        angles: [rotation],
      };
      rotateAroundCenter(obj, rotation);
      this.sendMessage(manipulationObj);
    });
  };

  addImageTransformer = (img, item) => {
    if (this.props.role !== 'instructor') return;
    img.off('click tap');
    img.on('click tap', (e) => {
      if (this.props.activeAction.select && !this.isZooming) {
        this.removeTransformer();
        img.setAttr('draggable', true);
        this.setState({
          elementMenuItems: [
            {
              name: 'Delete',
              handler: () => this.deleteElement(item.elementId),
            },
            {
              name: 'Bring to Front',
              handler: () => {
                img.moveToTop();
                this.removeTransformer();
              },
            },
            {
              name: 'Send to Back',
              handler: () => {
                img.moveToBottom();
                this.removeTransformer();
              },
            },
          ],
        });
        var simpleImageTr = new Konva.Transformer({
          node: e.currentTarget,
          enabledAnchors: ['top-left', 'bottom-right'],
          boundBoxFunc: (oldBox, newBox) => newBox,
          keepRatio: true,
          borderDash: [5, 5],
          rotationSnaps: [0, 90, 180, 270],
          elementId: item.elementId,
          shouldOverdrawWholeArea: true,
          flipEnabled: false,
        });
        let menuGroup = new Konva.Group({
          x: simpleImageTr.getWidth(),
          y: 0,
        });
        var imageObj = new Image();
        imageObj.src = select_item_menu;
        imageObj.onload = () => {
          let menuImgButton = new Konva.Image({
            height: 20,
            width: 20,
            image: imageObj,
          });
          menuGroup.add(menuImgButton);
          simpleImageTr.add(menuGroup);
          this.pageLayouts[pageIds.indexOf(selectedPageId)].add(simpleImageTr);
          this.pageLayouts[pageIds.indexOf(selectedPageId)].draw();
        };
        simpleImageTr.on('click', this.transformerClickHandler);
        simpleImageTr.on('transform', () => menuGroup.x(simpleImageTr.getWidth()));
        menuGroup.on('click tap', (e) => {
          this.setState({
            mouseX: e.evt.x !== undefined ? e.evt.x : e.evt.changedTouches[0].clientX,
            mouseY: e.evt.y !== undefined ? e.evt.y : e.evt.changedTouches[0].clientY,
            openMenu: true,
          });
        });
      }
    });
    img.off('transformend');
    img.on('transformend', () => {
      const rotation = img.rotation();
      rotateAroundCenter(img, 0);
      const manipulationObj = {
        type: 'manipulation',
        flowId: this.state.flowId,
        classId: this.state.classId,
        elementIds: [item.elementId],
        endBounds: [{ x: img.x().x, y: img.y() }],
        endScales: [{ x: img.scaleX(), y: img.scaleY() }],
        angles: [rotation],
      };
      this.sendMessage(manipulationObj);
      rotateAroundCenter(img, rotation);
    });
    img.off('dragmove');
    img.on('dragmove', () => (document.body.style.cursor = 'grabbing'));
    img.off('dragend');
    img.on('dragend', () => {
      document.body.style.cursor = 'default';
      const rotation = img.rotation();
      rotateAroundCenter(img, 0);
      const manipulationObj = {
        type: 'manipulation',
        flowId: this.state.flowId,
        classId: this.state.classId,
        elementIds: [item.elementId],
        endBounds: [{ x: img.x(), y: img.y() }],
        endScales: [{ x: img.scaleX(), y: img.scaleY() }],
        angles: [rotation],
      };
      this.sendMessage(manipulationObj);
      rotateAroundCenter(img, rotation);
    });
  };

  addDocumentTransformer = (img, item, onDoubleClick) => {
    if (this.props.role !== 'instructor') return;
    const { elementId, s3Url, type, elementType, filetype, mimeType } = item;
    img.on('click tap', (e) => {
      if (this.props.activeAction.select && !this.isZooming) {
        this.removeTransformer();
        img.setAttr('draggable', true);
        this.setState({
          elementMenuItems: [
            {
              name: 'View',
              handler: () =>
                ['video', 'youtube'].includes(type || elementType)
                  ? onDoubleClick()
                  : this.showDocument(s3Url),
            },
            ...((filetype || mimeType)?.toLowerCase().includes('pdf')
              ? [
                  {
                    name: 'Chat with Document',
                    handler: () => this.toggleChat({ elementId, s3Url, type: 'PDF' }),
                  },
                ]
              : ['youtube'].includes(type || elementType)
              ? [
                  {
                    name: 'Chat with Video',
                    handler: () => this.toggleChat({ elementId, s3Url, type: 'YOUTUBE' }),
                  },
                ]
              : []),
            {
              name: 'Delete',
              handler: () => this.deleteElement(item.elementId),
            },
          ],
        });
        var simpleImageTr = new Konva.Transformer({
          node: e.currentTarget,
          enabledAnchors: [],
          boundBoxFunc: (oldBox, newBox) => newBox,
          rotateEnabled: false,
          keepRatio: true,
          borderDash: [5, 5],
          rotationSnaps: [],
          elementId: item.elementId,
          shouldOverdrawWholeArea: true,
          flipEnabled: false,
        });
        let menuGroup = new Konva.Group({
          x: simpleImageTr.getWidth(),
          y: 0,
        });
        var imageObj = new Image();
        imageObj.src = select_item_menu;
        imageObj.onload = () => {
          let menuImgButton = new Konva.Image({
            height: 20,
            width: 20,
            image: imageObj,
          });
          menuGroup.add(menuImgButton);
          simpleImageTr.add(menuGroup);
          // added timeout to avoid canceling double click event of the document
          setTimeout(() => {
            this.pageLayouts[pageIds.indexOf(selectedPageId)].add(simpleImageTr);
            this.pageLayouts[pageIds.indexOf(selectedPageId)].draw();
          }, 200);
        };
        simpleImageTr.on('click tap', this.transformerClickHandler);
        simpleImageTr.on('transform', () => menuGroup.x(simpleImageTr.getWidth()));
        simpleImageTr.on('dblclick dbltap', async () => {
          onDoubleClick && onDoubleClick();
          const doc = this.state.documents.find((doc) => doc.elementId === item.elementId);
          if (!doc) return;
          this.props.setFileInfo(doc);
          this.props.openDocumentActionDialog(item.s3Url, 0);
        });
        menuGroup.on('click tap', (e) => {
          this.setState({
            mouseX: e.evt.x !== undefined ? e.evt.x : e.evt.changedTouches[0].clientX,
            mouseY: e.evt.y !== undefined ? e.evt.y : e.evt.changedTouches[0].clientY,
            openMenu: true,
          });
        });
      }
    });
    img.on('transformend', () => {
      const rotation = img.rotation();
      rotateAroundCenter(img, 0);
      const manipulationObj = {
        type: 'manipulation',
        flowId: this.state.flowId,
        classId: this.state.classId,
        elementIds: [item.elementId],
        endBounds: [
          {
            x: img.x(),
            y: img.y(),
          },
        ],
        endScales: [
          {
            x: img.scaleX(),
            y: img.scaleY(),
          },
        ],
        angles: [rotation],
      };
      rotateAroundCenter(img, rotation);
      this.sendMessage(manipulationObj);
    });
    img.on('dragmove', () => (document.body.style.cursor = 'grabbing'));
    img.on('dragend', () => {
      document.body.style.cursor = 'default';
      const rotation = img.rotation();
      rotateAroundCenter(img, 0);
      const manipulationObj = {
        type: 'manipulation',
        flowId: this.state.flowId,
        classId: this.state.classId,
        elementIds: [item.elementId],
        endBounds: [
          {
            x: img.x(),
            y: img.y(),
          },
        ],
        endScales: [
          {
            x: img.scaleX(),
            y: img.scaleY(),
          },
        ],
        angles: [rotation],
      };
      rotateAroundCenter(img, rotation);
      this.sendMessage(manipulationObj);
    });
  };

  setStateSynchronous = (stateUpdate) =>
    new Promise((resolve) => this.setState(stateUpdate, () => resolve(undefined)));

  drawLine = (ctx, x1, y1, x2, y2, width, color, shape) => {
    if (typeof width === 'number') ctx.lineWidth = width;
    if (typeof color === 'string') ctx.strokeStyle = color;

    ctx.beginPath();
    ctx.lineCap = 'round';
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.stroke();
    ctx.closePath();
    ctx.fillStrokeShape(shape);
  };

  drawBrushObject = (brushObj) => {
    var simpleShape = new Konva.Shape({
      x: brushObj.elementBounds.x,
      y: brushObj.elementBounds.y,
      stroke: brushObj.elementColor,
      width: brushObj.elementBounds.width,
      height: brushObj.elementBounds.height,
      scaleX: brushObj.elementScale.x,
      scaleY: brushObj.elementScale.y,
      sceneFunc: (context, shape) => {
        if (!brushObj.brushWidth) return;
        const points = brushObj.stroke.split(' ');
        const widths = brushObj.brushWidth.split(' ');
        for (let i = 0, j = 0; i < points.length; i = i + 2) {
          this.drawLine(
            context,
            Number(points[i - 2]) || 0,
            Number(points[i - 1]) || 0,
            Number(points[i]),
            Number(points[i + 1]),
            Number(widths[j]),
            null,
            shape,
          );
          j++;
        }
      },
    });
    this.setHitStrokeWidth(simpleShape);
    this.pageLayouts[pageIds.indexOf(brushObj.pageId)].add(simpleShape);
    this.pageLayouts[pageIds.indexOf(selectedPageId)].draw();
    this.addLineTransformer(simpleShape, brushObj);
  };

  drawCanvas = async ({ disableCORS = false, ...data }) => {
    try {
      this.setState({ videos: [], documents: [] });
      pageIds = [...new Set(data.pageCollection)];
      let selPageIndex = pageIds.indexOf(data.selectedPageId);
      if (selPageIndex === -1) {
        selectedPageId = pageIds[0];
      } else {
        selectedPageId = data.selectedPageId;
      }
      await this.props.updateSelectedPageId(selectedPageId);
      this.props.setCurrentPageId(selectedPageId);
      selectedPageIndex = pageIds.indexOf(selectedPageId);
      if (selectedPageIndex === -1) {
        selectedPageIndex = 0;
      }
      for (var i = 0; i < pageIds.length; i++) {
        this.pageLayouts[i] = new Konva.Layer({ name: 'page' });
      }
      await drawItems({
        canvasBoard: this,
        items: data.items,
        pageIds,
        pageLayouts: this.pageLayouts,
        selectedPageId,
        disableCORS,
      });
      await this.setStateSynchronous({ showLoader: false });
    } catch (error) {
      console.error(error);
      await this.setStateSynchronous({ showLoader: false });
    }
  };

  renderKonva = () => {
    const { width, height, x, y, transformFactor, scale } = this.calculateStageDefaults();
    this.size = { width, height };
    this.position = { x, y };
    this.scale = scale;
    this.props.setTransformFactor(transformFactor);
    this.props.setCanvasPosition({ x, y });
    this.props.setCanvasScale(scale);
    currStage = new Konva.Stage({
      container: this.myRef.current,
      x,
      y,
      width,
      height,
      scaleX: scale,
      scaleY: scale,
    });
    Konva.pixelRatio = window.devicePixelRatio;
    Konva.pixelRatio = this.state.pixelRatio || 1.5;
    currStage.draw();
  };

  resizeCanvas = () =>
    new Promise((resolve, reject) => {
      try {
        // Avoid running on initial page load
        if (!this.mounted) return (this.mounted = true);
        if (
          document.fullscreenEnabled &&
          ['IFRAME', 'VIDEO'].includes(document.fullscreenElement?.nodeName || '')
        )
          return resolve(true);
        const { width, height, transformFactor } = this.calculateStageDefaults();
        const { x: oldX, y: oldY } = this.position;
        const oldTransformFactor = this.props.transformFactor;
        const originalX = oldX / oldTransformFactor;
        const originalY = oldY / oldTransformFactor;
        const newX = originalX * transformFactor;
        const newY = originalY * transformFactor;
        this.zoomAnimationPlaying = true;
        if (!this.pageLayouts.length) return;
        this.setState({
          showLoader: true,
          loaderMessage: 'Re-rendering whiteboard elements due to resize',
        });
        this.pageLayouts.forEach((pageLayout) => pageLayout.destroyChildren());
        currStage.to({
          width,
          height,
          x: newX,
          y: newY,
          duration: 0,
          onFinish: async () => {
            try {
              this.size = { width, height };
              this.props.setTransformFactor(transformFactor);
              this.applyZoomEffects(undefined, { x: newX, y: newY }, true);
              this.zoomAnimationPlaying = false;
              const url = Config.flowItemURL + this.state.flowId;
              const {
                data: { data },
              } = await get(url, { headers: { 'Content-Type': 'application/json' } });
              this.props.getallUser(data.users);
              await drawItems({
                canvasBoard: this,
                pageIds,
                pageLayouts: this.pageLayouts,
                selectedPageId,
                items: data.items,
              });
              this.resetHitStrokes();
              await this.renderBackground(selectedPageId, undefined, undefined, false);
              this.setState({ showLoader: false });
              resolve(true);
            } catch (error) {
              this.setState({ showLoader: false });
              console.error(error);
            }
          },
        });
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });

  updateCustom = (obj, type) => {
    if (!obj) {
      return;
    }
    const attr = obj.getAttrs();
    attr.customObj.type = type;
    const { x, y } = customBoundRect(attr.points);
    const pointArr = attr.points.map((point, index) => (index % 2 ? point - y : point - x));
    attr.customObj.stroke = pointArr.join(' ');
    attr.customObj.elementBounds.x = x;
    attr.customObj.elementBounds.y = y;
    attr.customObj.elementBounds.width = obj.width();
    attr.customObj.elementBounds.height = obj.height();
    attr.customObj.elementScale.x = 1;
    attr.customObj.elementScale.y = 1;
    attr.customObj.elementOpacity = attr.opacity;
    attr.customObj.elementColor = attr.stroke;
    attr.customObj.elementSize = this.props.brushSize
      ? this.props.brushSize / this.scale
      : attr.strokeWidth !== undefined
      ? attr.strokeWidth
      : 1;
    obj.setAttrs({
      x: x,
      y: y,
      points: pointArr,
    });
    this.sendMessage(attr.customObj);
  };

  cleanDrawing = () => {
    if (tempLine) {
      tempLine.destroy();
      tempLine = undefined;
      currStage.fire('click');
    }
  };

  setEvent = () => {
    currStage.on('click tap', () => {
      let currentEventPosition = currStage.getPointerPosition();
      if (!currentEventPosition) return;
      const x = currentEventPosition.x;
      const y = currentEventPosition.y;
      let selection = this.pageLayouts[pageIds.indexOf(selectedPageId)].getIntersection({ x, y });
      if (this.isAddingText) {
        const textArea = document.querySelector('.cnx-textarea');
        // @ts-ignore
        textArea && textArea.blur();
      }
      if (this.props.activeAction.text && !this.isAddingText) {
        this.isAddingText = true;
        this.setCursor('auto');
        const shadowDiv = document.createElement('div');
        const textarea = document.createElement('textarea');
        this.myRef.current.appendChild(shadowDiv);
        this.myRef.current.appendChild(textarea);
        textarea.placeholder = 'Start typing';
        shadowDiv.className = 'cnx-shadowDiv';
        textarea.className = 'cnx-textarea';
        shadowDiv.style.top = y + 'px';
        textarea.style.top = y + 'px';
        shadowDiv.style.left = x + 'px';
        textarea.style.left = x + 'px';
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 3 + 'px';
        textarea.focus();

        textarea.addEventListener('input', () => {
          shadowDiv.textContent = textarea.value || 'Start typing';
          const textWidth = shadowDiv.getBoundingClientRect().width;
          textarea.style.width = textWidth + 'px';
          textarea.style.height = 'auto';
          textarea.style.height = textarea.scrollHeight + 3 + 'px';
        });

        textarea.addEventListener('blur', () => {
          if (!textarea.parentNode || !shadowDiv.parentNode) return;
          if (!textarea.value.trim()) {
            textarea.parentNode.removeChild(textarea);
            shadowDiv.parentNode.removeChild(shadowDiv);
            this.isAddingText = false;
            return;
          }
          this.addTextEditor({
            text: textarea.value,
            fontSize: 16,
            elementBounds: {
              x: (x - currStage.x()) / this.scale,
              y: (y - currStage.y()) / this.scale,
              width: textarea.getBoundingClientRect().width * this.props.transformFactor,
              height: textarea.getBoundingClientRect().height * this.props.transformFactor,
            },
            elementScale: {
              x: 1 / this.props.transformFactor / this.scale,
              y: 1 / this.props.transformFactor / this.scale,
            },
          });
          textarea.parentNode.removeChild(textarea);
          shadowDiv.parentNode.removeChild(shadowDiv);
          this.toolBar.textEditorDialogClose();
          this.isAddingText = false;
        });
        return;
      }
      if (!selection) {
        this.setState({ openMenu: false });
        this.removeTransformer();
      }
    });

    currStage.addEventListener('mousedown touchstart', (e) => {
      this.lastCenter = null;
      this.lastDist = null;
      // @ts-ignore
      if (e.touches && e.touches.length > 1) {
        tempLine && tempLine.destroy();
        tempLine = undefined;
        this.isZooming = true;
        return;
      }
      isPaint = true;
      if (isPaint && (this.props.activeAction.pen || this.props.activeAction.highlighter)) {
        lastPointerPosition = currStage.getPointerPosition() || { x: 0, y: 0 };
        lastPointerPosition.x =
          (lastPointerPosition.x - currStage.position().x) / currStage.scaleX();
        lastPointerPosition.y =
          (lastPointerPosition.y - currStage.position().y) / currStage.scaleY();

        tempLine = this.createLine(lastPointerPosition.x, lastPointerPosition.y);
      } else if (this.props.activeAction.eraser) {
        this.setState({ strokeErase: true });
        const canvasParent = document.getElementById('canvasParent');
        if (canvasParent) canvasParent.style.cursor = "url('../assets/img/Eraser.png'), auto";
      }
      if (
        this.props.activeAction.select &&
        currStage.find('Transformer') &&
        currStage.find('Transformer').length === 0
      ) {
        lastPointerPosition = currStage.getPointerPosition() || { x: 0, y: 0 };
        lastPointerPosition.x =
          (lastPointerPosition.x - currStage.position().x) / currStage.scaleX();
        lastPointerPosition.y =
          (lastPointerPosition.y - currStage.position().y) / currStage.scaleY();

        tempLine = this.createLine(
          lastPointerPosition.x,
          lastPointerPosition.y,
          '#000000',
          1,
          1,
          true,
        );
      }
      if (this.props.activeAction.circle) {
        lastPointerPosition = currStage.getPointerPosition() || { x: 0, y: 0 };
        lastPointerPosition.x =
          (lastPointerPosition.x - currStage.position().x) / currStage.scaleX();
        lastPointerPosition.y =
          (lastPointerPosition.y - currStage.position().y) / currStage.scaleY();
        let x = 0;
        let y = 0;
        shapeStartX = lastPointerPosition.x;
        shapeStartY = lastPointerPosition.y;
        radius = getLengthBetweenPoints(
          lastPointerPosition.x,
          lastPointerPosition.y,
          lastPointerPosition.x,
          lastPointerPosition.y,
        );
        let collectedPoints = [];
        for (let i = 0; i <= 360; i++) {
          x = lastPointerPosition.x + 5 * Math.sin((i * 2 * Math.PI) / 360) + 0.5;
          y = lastPointerPosition.y + 5 * Math.cos((i * 2 * Math.PI) / 360) + 0.5;
          collectedPoints.push(x);
          collectedPoints.push(y);
        }
        tempLine = this.createShape(collectedPoints);
      }
      if (this.props.activeAction.triangle) {
        lastPointerPosition = currStage.getPointerPosition() || { x: 0, y: 0 };
        lastPointerPosition.x =
          (lastPointerPosition.x - currStage.position().x) / currStage.scaleX();
        lastPointerPosition.y =
          (lastPointerPosition.y - currStage.position().y) / currStage.scaleY();
        shapeStartX = lastPointerPosition.x;
        shapeStartY = lastPointerPosition.y;
        let startPoint = {
            x: shapeStartX,
            y: shapeStartY,
          },
          touchPnt = {
            x: lastPointerPosition.x + 10,
            y: lastPointerPosition.y + 10,
          },
          points = [];
        if (startPoint.x < touchPnt.x && startPoint.y > touchPnt.y) {
          // 1st quadrant
          points = [
            (touchPnt.x - startPoint.x) / 2 + startPoint.x,
            touchPnt.y,
            startPoint.x,
            startPoint.y,
            touchPnt.x,
            startPoint.y,
          ];
        } else if (startPoint.x > touchPnt.x && startPoint.y > touchPnt.y) {
          // 2nd quadrant
          points = [
            (startPoint.x - touchPnt.x) / 2 + touchPnt.x,
            touchPnt.y,
            touchPnt.x,
            startPoint.y,
            startPoint.x,
            startPoint.y,
          ];
        } else if (startPoint.x > touchPnt.x && startPoint.y < touchPnt.y) {
          // 3rd quadrant
          points = [
            (startPoint.x - touchPnt.x) / 2 + touchPnt.x,
            startPoint.y,
            touchPnt.x,
            touchPnt.y,
            startPoint.x,
            touchPnt.y,
          ];
        } else if (startPoint.x < touchPnt.x && startPoint.y < touchPnt.y) {
          // 4th quadrant
          points = [
            (touchPnt.x - startPoint.x) / 2 + startPoint.x,
            startPoint.y,
            startPoint.x,
            touchPnt.y,
            touchPnt.x,
            touchPnt.y,
          ];
        }
        points = [...points, points[0], points[1]];
        tempLine = this.createShape(points);
      }
      if (this.props.activeAction.rectangle) {
        lastPointerPosition = currStage.getPointerPosition() || { x: 0, y: 0 };
        lastPointerPosition.x =
          (lastPointerPosition.x - currStage.position().x) / currStage.scaleX();
        lastPointerPosition.y =
          (lastPointerPosition.y - currStage.position().y) / currStage.scaleY();
        shapeStartX = lastPointerPosition.x;
        shapeStartY = lastPointerPosition.y;
        let startPoint = {
            x: shapeStartX,
            y: shapeStartY,
          },
          touchPnt = {
            x: lastPointerPosition.x,
            y: lastPointerPosition.y,
          },
          points = [];
        if (startPoint.x !== touchPnt.x && startPoint.y === touchPnt.y) {
          points = [
            startPoint.x,
            startPoint.y,
            startPoint.x,
            startPoint.y + 5,
            touchPnt.x,
            startPoint.y + 5,
            touchPnt.x,
            startPoint.y,
          ];
        } else if (startPoint.x === touchPnt.x && startPoint.y !== touchPnt.y) {
          points = [
            startPoint.x,
            startPoint.y,
            startPoint.x,
            touchPnt.y,
            startPoint.x + 5,
            touchPnt.y,
            touchPnt.x,
            startPoint.y,
          ];
        } else if (startPoint.x !== touchPnt.x && startPoint.y !== touchPnt.y) {
          points = [
            startPoint.x,
            startPoint.y,
            startPoint.x,
            touchPnt.y,
            touchPnt.x,
            touchPnt.y,
            touchPnt.x,
            startPoint.y,
          ];
        }
        points = [...points, points[0], points[1]];
        tempLine = this.createShape(points);
      }
      if (this.props.activeAction.line) {
        lastPointerPosition = currStage.getPointerPosition() || { x: 0, y: 0 };
        lastPointerPosition.x =
          (lastPointerPosition.x - currStage.position().x) / currStage.scaleX();
        lastPointerPosition.y =
          (lastPointerPosition.y - currStage.position().y) / currStage.scaleY();
        shapeStartX = lastPointerPosition.x;
        shapeStartY = lastPointerPosition.y;
        tempLine = this.createShape([shapeStartX, shapeStartY, shapeStartX, shapeStartY]);
      }
      if (this.props.activeAction.hexagon) {
        lastPointerPosition = currStage.getPointerPosition() || { x: 0, y: 0 };
        lastPointerPosition.x =
          (lastPointerPosition.x - currStage.position().x) / currStage.scaleX();
        lastPointerPosition.y =
          (lastPointerPosition.y - currStage.position().y) / currStage.scaleY();
        let x = 0;
        let y = 0;
        let angle = (2 * Math.PI) / 6;
        shapeStartX = lastPointerPosition.x;
        shapeStartY = lastPointerPosition.y;
        radius = getLengthBetweenPoints(
          lastPointerPosition.x,
          lastPointerPosition.y,
          lastPointerPosition.x,
          lastPointerPosition.y,
        );
        let collectedPoints = [];
        for (let i = 0; i < 6; i++) {
          x = shapeStartX + radius * Math.sin(i * angle);
          y = shapeStartY + radius * Math.cos(i * angle);
          collectedPoints.push(x);
          collectedPoints.push(y);
        }
        collectedPoints = [...collectedPoints, collectedPoints[0], collectedPoints[1]];
        tempLine = this.createShape(collectedPoints);
      }
    });

    currStage.addEventListener('mouseup touchend', (e) => {
      // @ts-ignore
      if (this.isZooming && !e.touches.length) return (this.isZooming = false);
      // @ts-ignore
      if (e.touches && e.touches.length > 0) {
        tempLine && tempLine.destroy();
        tempLine = undefined;
        return;
      }
      this.lastDist = 0;
      this.lastCenter = null;
      if (isPaint && (this.props.activeAction.pen || this.props.activeAction.highlighter)) {
        isPaint = false;
        if (!tempLine) return;
        const points = tempLine.points();
        if (points.length === 4) tempLine.points([...points, points[2] + 0.01, points[3]]);
        this.updateCustom(tempLine, 'polyline');
        lastPointerPosition = { x: 0, y: 0 };
        tempLine = undefined;
        return;
      } else if (this.props.activeAction.eraser) {
        this.setState({ strokeErase: false });
        const canvasParent = document.getElementById('canvasParent');
        if (canvasParent) canvasParent.style.cursor = 'auto';
      }
      if (this.props.activeAction.circle) {
        this.updateCustom(tempLine, 'polyline');
        lastPointerPosition = { x: 0, y: 0 };
        tempLine = undefined;
        return;
      }
      if (
        this.props.activeAction.triangle ||
        this.props.activeAction.rectangle ||
        this.props.activeAction.line ||
        this.props.activeAction.hexagon
      ) {
        this.updateCustom(tempLine, 'polyline');
        lastPointerPosition = { x: 0, y: 0 };
        tempLine = undefined;
        return;
      }

      if (
        this.props.activeAction.select &&
        currStage.find('Transformer') &&
        currStage.find('Transformer').length === 0 &&
        tempLine
      ) {
        this.setState(
          {
            lassoPoints: tempLine.getAttrs().points,
          },
          () => {
            this.removeElement(
              this.pageLayouts[pageIds.indexOf(selectedPageId)],
              tempLine.getAttrs().id,
            );
            tempLine = undefined;
            lastPointerPosition = { x: 0, y: 0 };
            this.selectIntersections(this.state.lassoPoints);
          },
        );
      }
    });

    currStage.addEventListener('mousemove touchmove', (e) => {
      e.preventDefault();
      // @ts-ignore
      if (e.touches && e.touches.length === 2) {
        this.isZooming = true;
        tempLine && tempLine.destroy();
        tempLine = undefined;
        e.stopPropagation();
        this.zoomPanHandler(e);
        return;
      }

      let { x, y } = currStage.getRelativePointerPosition() || { x: 0, y: 0 };
      const { transformFactor } = this.props;
      x = x / transformFactor;
      y = y / transformFactor;
      this.updateMousePosition({ x, y, pageId: selectedPageId });
      if (
        tempLine &&
        isPaint &&
        (this.props.activeAction.pen || this.props.activeAction.highlighter)
      ) {
        const pos = currStage.getPointerPosition() || { x: 0, y: 0 };
        pos.x = (pos.x - currStage.position().x) / currStage.scaleX();
        pos.y = (pos.y - currStage.position().y) / currStage.scaleY();
        tempLine.points(tempLine.points().concat([pos.x, pos.y]));
        currStage.batchDraw();
        return;
      }
      if (
        tempLine &&
        this.props.activeAction.select &&
        currStage.find('Transformer') &&
        currStage.find('Transformer').length === 0
      ) {
        const pos = currStage.getPointerPosition() || { x: 0, y: 0 };
        pos.x = (pos.x - currStage.position().x) / currStage.scaleX();
        pos.y = (pos.y - currStage.position().y) / currStage.scaleY();
        tempLine.points(tempLine.points().concat([pos.x, pos.y]));
        currStage.batchDraw();
        return;
      }
      if (tempLine && this.props.activeAction.circle) {
        lastPointerPosition = currStage.getPointerPosition() || { x: 0, y: 0 };
        lastPointerPosition.x =
          (lastPointerPosition.x - currStage.position().x) / currStage.scaleX();
        lastPointerPosition.y =
          (lastPointerPosition.y - currStage.position().y) / currStage.scaleY();
        let x = 0;
        let y = 0;
        radius = getLengthBetweenPoints(
          shapeStartX,
          shapeStartY,
          lastPointerPosition.x,
          lastPointerPosition.y,
        );
        let collectedPoints = [];
        for (let i = 0; i <= 360; i++) {
          x = lastPointerPosition.x + radius * Math.sin((i * 2 * Math.PI) / 360) + 0.5;
          y = lastPointerPosition.y + radius * Math.cos((i * 2 * Math.PI) / 360) + 0.5;
          collectedPoints.push(x);
          collectedPoints.push(y);
        }
        if (tempLine) tempLine.points(collectedPoints);
        this.pageLayouts[pageIds.indexOf(selectedPageId)].draw();
      }
      if (this.props.activeAction.triangle) {
        lastPointerPosition = currStage.getPointerPosition() || { x: 0, y: 0 };
        lastPointerPosition.x =
          (lastPointerPosition.x - currStage.position().x) / currStage.scaleX();
        lastPointerPosition.y =
          (lastPointerPosition.y - currStage.position().y) / currStage.scaleY();
        let startPoint = {
            x: shapeStartX,
            y: shapeStartY,
          },
          touchPnt = {
            x: lastPointerPosition.x + 10,
            y: lastPointerPosition.y + 10,
          },
          points = [];
        if (startPoint.x < touchPnt.x && startPoint.y > touchPnt.y) {
          // 1st quadrant
          points = [
            (touchPnt.x - startPoint.x) / 2 + startPoint.x,
            touchPnt.y,
            startPoint.x,
            startPoint.y,
            touchPnt.x,
            startPoint.y,
          ];
        } else if (startPoint.x > touchPnt.x && startPoint.y > touchPnt.y) {
          // 2nd quadrant
          points = [
            (startPoint.x - touchPnt.x) / 2 + touchPnt.x,
            touchPnt.y,
            touchPnt.x,
            startPoint.y,
            startPoint.x,
            startPoint.y,
          ];
        } else if (startPoint.x > touchPnt.x && startPoint.y < touchPnt.y) {
          // 3rd quadrant
          points = [
            (startPoint.x - touchPnt.x) / 2 + touchPnt.x,
            startPoint.y,
            touchPnt.x,
            touchPnt.y,
            startPoint.x,
            touchPnt.y,
          ];
        } else if (startPoint.x < touchPnt.x && startPoint.y < touchPnt.y) {
          // 4th quadrant
          points = [
            (touchPnt.x - startPoint.x) / 2 + startPoint.x,
            startPoint.y,
            startPoint.x,
            touchPnt.y,
            touchPnt.x,
            touchPnt.y,
          ];
        }
        points = [...points, points[0], points[1]];
        if (tempLine) tempLine.points(points);
        this.pageLayouts[pageIds.indexOf(selectedPageId)].draw();
      }
      if (this.props.activeAction.rectangle) {
        lastPointerPosition = currStage.getPointerPosition() || { x: 0, y: 0 };
        lastPointerPosition.x =
          (lastPointerPosition.x - currStage.position().x) / currStage.scaleX();
        lastPointerPosition.y =
          (lastPointerPosition.y - currStage.position().y) / currStage.scaleY();
        let startPoint = {
            x: shapeStartX,
            y: shapeStartY,
          },
          touchPnt = {
            x: lastPointerPosition.x,
            y: lastPointerPosition.y,
          },
          points = [];
        if (startPoint.x !== touchPnt.x && startPoint.y === touchPnt.y) {
          points = [
            startPoint.x,
            startPoint.y,
            startPoint.x,
            startPoint.y + 5,
            touchPnt.x,
            startPoint.y + 5,
            touchPnt.x,
            startPoint.y,
          ];
        } else if (startPoint.x === touchPnt.x && startPoint.y !== touchPnt.y) {
          points = [
            startPoint.x,
            startPoint.y,
            startPoint.x,
            touchPnt.y,
            startPoint.x + 5,
            touchPnt.y,
            touchPnt.x,
            startPoint.y,
          ];
        } else if (startPoint.x !== touchPnt.x && startPoint.y !== touchPnt.y) {
          points = [
            startPoint.x,
            startPoint.y,
            startPoint.x,
            touchPnt.y,
            touchPnt.x,
            touchPnt.y,
            touchPnt.x,
            startPoint.y,
          ];
        }
        points = [...points, points[0], points[1]];
        if (tempLine) tempLine.points(points);
        this.pageLayouts[pageIds.indexOf(selectedPageId)].draw();
      }
      if (this.props.activeAction.line) {
        lastPointerPosition = currStage.getPointerPosition() || { x: 0, y: 0 };
        lastPointerPosition.x =
          (lastPointerPosition.x - currStage.position().x) / currStage.scaleX();
        lastPointerPosition.y =
          (lastPointerPosition.y - currStage.position().y) / currStage.scaleY();
        if (tempLine)
          tempLine.points([shapeStartX, shapeStartY, lastPointerPosition.x, lastPointerPosition.y]);
        this.pageLayouts[pageIds.indexOf(selectedPageId)].draw();
      }
      if (tempLine && this.props.activeAction.hexagon) {
        lastPointerPosition = currStage.getPointerPosition() || { x: 0, y: 0 };
        lastPointerPosition.x =
          (lastPointerPosition.x - currStage.position().x) / currStage.scaleX();
        lastPointerPosition.y =
          (lastPointerPosition.y - currStage.position().y) / currStage.scaleY();
        let x = 0;
        let y = 0;
        let angle = (2 * Math.PI) / 6;
        radius = getLengthBetweenPoints(
          shapeStartX,
          shapeStartY,
          lastPointerPosition.x,
          lastPointerPosition.y,
        );
        let collectedPoints = [];
        for (let i = 0; i < 6; i++) {
          x = shapeStartX + radius * Math.sin(i * angle);
          y = shapeStartY + radius * Math.cos(i * angle);
          collectedPoints.push(x);
          collectedPoints.push(y);
        }
        collectedPoints = [...collectedPoints, collectedPoints[0], collectedPoints[1]];
        if (tempLine) tempLine.points(collectedPoints);
        this.pageLayouts[pageIds.indexOf(selectedPageId)].draw();
      }
    });

    currStage.addEventListener('mouseleave', () => {
      if (tempLine) {
        tempLine.destroy();
        tempLine = undefined;
        currStage.fire('click');
      }
    });
  };

  /**
   * @param {{x: number, y: number, width: number, height:number}} nodeBoundaries
   * @param {{x: number, xMax: number, y: number, yMax: number, width: number, height:number}} selectionBoundaries
   * @return {boolean}
   */
  isInsideBoundaries = (nodeBoundaries, selectionBoundaries) => {
    const { x, y, width, height } = nodeBoundaries;
    const xMax = x + width;
    const yMax = y + height;
    const isInside =
      x >= selectionBoundaries.x &&
      y >= selectionBoundaries.y &&
      xMax <= selectionBoundaries.xMax &&
      yMax <= selectionBoundaries.yMax;
    return isInside;
  };

  selectIntersections = async (points, nodes) => {
    let intersections = [];
    if (nodes) {
      intersections = nodes;
    } else {
      const { xMin: x, yMin: y, ...lassoCoordinates } = minMaxPositions(points);

      // @ts-ignore
      this.pageLayouts[pageIds.indexOf(selectedPageId)].getChildren((node) => {
        const selected = this.isInsideBoundaries(node.getClientRect({ relativeTo: currStage }), {
          ...lassoCoordinates,
          x,
          y,
        });
        selected && intersections.push(node);
      });
    }
    if (intersections.length === 0) {
      return;
    }
    // To avoid selecting invisible video thumbnails
    intersections = intersections.filter(
      (node) => !this.state.videos.some(({ elementId }) => elementId === node.id()),
    );
    if (intersections.length === 1) {
      intersections[0].fire('click');
      return;
    }
    await this.setStateSynchronous({
      lassoItems: [...intersections],
    });

    this.setState({
      elementMenuItems: [
        {
          name: 'Delete',
          handler: async () => {
            const lassoItems = [...this.state.lassoItems];
            for (let i = 0; i < lassoItems.length; i++) {
              const elementId = lassoItems[i].getAttr('id');
              this.deleteElement(elementId, true, false);
            }
            await this.setStateSynchronous({ lassoItems: [] });
            this.removeTransformer();
          },
        },
      ],
    });
    this.destroyTransformers();
    const lassoTransformer = new Konva.Transformer({
      enabledAnchors: ['top-left', 'bottom-right'],
      boundBoxFunc: (oldBox, newBox) => newBox,
      keepRatio: true,
      borderDash: [5, 5],
      rotationSnaps: [0, 90, 180, 270],
      shouldOverdrawWholeArea: true,
      flipEnabled: false,
    });
    lassoTransformer.nodes(intersections);
    let menuGroup = new Konva.Group({
      x: lassoTransformer.getWidth() * this.scale,
      y: 0,
    });
    var imageObj = new Image();
    imageObj.src = select_item_menu;
    imageObj.onload = () => {
      let menuImgButton = new Konva.Image({
        height: 20,
        width: 20,
        image: imageObj,
      });
      menuGroup.add(menuImgButton);
      lassoTransformer.add(menuGroup);
      this.pageLayouts[pageIds.indexOf(selectedPageId)].add(lassoTransformer);
      this.pageLayouts[pageIds.indexOf(selectedPageId)].draw();
    };
    lassoTransformer.on('click', this.transformerClickHandler);
    lassoTransformer.on('transform', () => menuGroup.x(lassoTransformer.getWidth()));
    menuGroup.on('click tap', (e) => {
      this.setState({
        mouseX: e.evt.x !== undefined ? e.evt.x : e.evt.changedTouches[0].clientX,
        mouseY: e.evt.y !== undefined ? e.evt.y : e.evt.changedTouches[0].clientY,
        openMenu: true,
      });
    });

    document.body.style.cursor = 'grab';
    lassoTransformer.on('dragmove', () => (document.body.style.cursor = 'grabbing'));
    lassoTransformer.on('transformend', () => {
      const manipulationObj = {
        type: 'manipulation',
        flowId: this.state.flowId,
        classId: this.state.classId,
        /**@type {any[]} */
        elementIds: [],
        /**@type {any[]} */
        endBounds: [],
        /**@type {any[]} */
        endScales: [],
        /**@type {any[]} */
        angles: [],
      };

      const lassoChildren = lassoTransformer.nodes();
      if (!lassoChildren) return;
      lassoChildren.forEach((item) => {
        const rotation = item.rotation();
        rotateAroundCenter(item, 0);
        manipulationObj.elementIds.push(item.id());
        manipulationObj.angles.push(rotation);
        manipulationObj.endBounds.push({ x: item.position().x, y: item.position().y });
        manipulationObj.endScales.push({ x: item.scaleX(), y: item.scaleY() });
        rotateAroundCenter(item, rotation);
      });
      this.sendMessage(manipulationObj);
    });
    lassoTransformer.on('dragend', () => {
      document.body.style.cursor = 'grab';
      const manipulationObj = {
        type: 'manipulation',
        flowId: this.state.flowId,
        classId: this.state.classId,
        /**@type {any[]} */
        elementIds: [],
        /**@type {any[]} */
        endBounds: [],
        /**@type {any[]} */
        endScales: [],
        /**@type {any[]} */
        angles: [],
      };

      const lassoChildren = lassoTransformer.nodes();
      if (!lassoChildren) return;
      lassoChildren.forEach((item) => {
        const rotation = item.rotation();
        rotateAroundCenter(item, 0);
        manipulationObj.elementIds.push(item.id());
        manipulationObj.angles.push(rotation);
        manipulationObj.endBounds.push({ x: item.position().x, y: item.position().y });
        manipulationObj.endScales.push({ x: item.scaleX(), y: item.scaleY() });
        rotateAroundCenter(item, rotation);
      });
      this.sendMessage(manipulationObj);
    });
  };

  getDistance = (p1, p2) => {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  };

  getCenter = (p1, p2) => ({
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  });

  galleryView = (urlObj) => {
    this.toolBar.selectClick();
    this.saveCloudImage(urlObj);
  };

  AddCloudVideo = (details) => {
    let contentUrl = JSON.parse(details.contentUrl);
    const { title, url, thumbnail } = contentUrl;
    const { contentDetails, x, y } = details;
    const decodedTitle = decodeURIComponent(title);
    this.toolBar.selectClick();
    var videoProps = {
      type: 'video',
      name: decodedTitle,
      url,
      thumbnail,
      s3MetaData: contentDetails?.s3MetaData,
      x,
      y,
    };
    this.saveCloudVideo(videoProps, !contentDetails?.s3MetaData);
  };

  saveCloudVideo = async (details, isYoutubeVideo) => {
    const id = generateUUID();
    const { name: srcfilename, url, thumbnail, s3MetaData, x: pointerX, y: pointerY } = details;
    let rect = this.myRef.current.getBoundingClientRect();
    let x = pointerX && pointerX - rect.left;
    let y = pointerY && pointerY - rect.top;
    x = x && (-currStage.x() + x) / this.scale;
    y = y && (-currStage.y() + y) / this.scale;
    const pointerPosition = x && y && { x, y };
    const { width, height } = this.state.defaultElementSize.video;
    const attributes = await this.positionElement({
      pointerPosition,
      width,
      height,
    });
    if (!attributes) return;
    const { scale: elementScale, ...elementBounds } = attributes;
    elementBounds.width = elementBounds.width * elementScale.x;
    elementBounds.height = elementBounds.height * elementScale.y;
    var videoProps = {
      elementId: id,
      elementType: isYoutubeVideo ? 'youtube' : 'video',
      elementBounds,
      elementScale: { x: 1, y: 1 },
      pageId: selectedPageId,
      filetype: isYoutubeVideo ? 'youtube' : 'video',
      srcfilename,
      s3Url: url,
      thumbnail: thumbnail,
      s3MetaData,
    };
    var item = this.createItem(videoProps);
    item.type = isYoutubeVideo ? 'youtube' : 'video';
    this.sendMessage(item);
    await this.iconUpdate({ obj: item, fileType: 'video' });
  };

  addTextToCanvas = (textObj) => {
    let textObject = {
      value: textObj.text,
      bold: false,
      underline: false,
      italic: false,
      fontSize: 20,
      update: false,
    };
    this.addTextEditor(textObject);
  };

  activateCloudVideoModal = () => {
    this.setState({ showLocalVideoDialog: true });
  };

  randomPos = () => {
    /** @type {HTMLElement | null} */
    var tm = document.querySelector('#canvasParent');
    var width = 0;
    var height = 0;
    if (tm) {
      width = tm.offsetWidth / 2;
      height = tm.offsetHeight / 2;
    }
    var obj = {};
    obj.x = this.getRandomInt(width, width + 100);
    obj.y = this.getRandomInt(height, height + 100);
    return obj;
  };

  getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  saveCloudImage = async (urlObj) => {
    try {
      var id = generateUUID();
      const contentDetails = urlObj.contentDetails;
      let rect = this.myRef.current.getBoundingClientRect();
      let x = urlObj.x && urlObj.x - rect.left;
      let y = urlObj.y && urlObj.y - rect.top;
      x = x && (-currStage.x() + x) / this.scale;
      y = y && (-currStage.y() + y) / this.scale;
      const pointerPosition = x && y && { x, y };
      const { width, height } = urlObj;
      const attributes = await this.positionElement({ pointerPosition, width, height });
      if (!attributes) return;
      const { scale, ...bounds } = attributes;
      bounds.width = bounds.width * scale.x;
      bounds.height = bounds.height * scale.y;
      var imageProps = {
        elementId: id,
        elementType: 'image',
        elementBounds: bounds,
        elementScale: { x: 1, y: 1 },
        pageId: selectedPageId,
        s3Url: urlObj.contentUrl,
      };
      if (!_.isEmpty(contentDetails) && contentDetails.s3MetaData) {
        imageProps['s3MetaData'] = contentDetails.s3MetaData;
      }
      var item = this.createItem(imageProps);
      this.sendMessage({ ...item, isCloud: true });
      const shapesLayer = this.pageLayouts[pageIds.indexOf(selectedPageId)];
      const imageObj = new Image(),
        imageIcon = new Image();
      let imagePlaceHolder;
      const loadPlaceHolder = () =>
        new Promise((resolve, reject) => {
          imageIcon.src = '/image.png';
          imageIcon.onload = () => {
            const loaderDisplayData = {
              x: bounds.x + bounds.width / 2 - 50,
              y: bounds.y + bounds.height / 2 - 50,
              scale: { x: 1 / this.scale, y: 1 / this.scale },
              width: 100,
              height: 100,
            };
            imagePlaceHolder = new Konva.Image({
              ...loaderDisplayData,
              image: imageIcon,
              draggable: false,
            });
            shapesLayer.add(imagePlaceHolder);
            resolve(true);
          };
          imageIcon.onerror = () => reject('Failed to load image placeholder');
        });
      this.props.showSpinner();
      await loadPlaceHolder();
      imageObj.src = item.s3Url;
      imageObj.onerror = () => {
        imagePlaceHolder.destroy();
        this.removeElement(shapesLayer, id);
        this.sendMessage({ type: 'remove', elementId: id });
        this.props.hideSpinner();
      };
      imageObj.onload = () => {
        var imagePlot = new Konva.Image({
          x: +item.elementBounds.x,
          y: +item.elementBounds.y,
          width: +item.elementBounds.width,
          height: +item.elementBounds.height,
          id: item.elementId,
          image: imageObj,
          draggable: false,
          scale: item.elementScale,
        });
        imagePlaceHolder.destroy();
        shapesLayer.add(imagePlot);
        this.addImageTransformer(imagePlot, item);
        this.props.hideSpinner();
      };
    } catch (error) {
      this.props.hideSpinner();
      console.error(error);
    }
  };

  handleChange = async (selectorFiles, options = {}) => {
    var file = selectorFiles[0];
    var mime_types = [];
    this.toolBar.selectClick();
    if (mime_types.indexOf(file.type) === -1) {
      if (
        file.type === 'image/png' ||
        file.type === 'image/gif' ||
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/png'
      ) {
        await this.uploadImage(file, options);
        return;
      } else if (file.type.includes('video')) {
        this.uploadLocalVideo(file, options);
        return;
      } else {
        if (file.type === 'application/pdf' && file.size > 100 * 1024 * 1024) {
          this.props.setSnackbar({
            message: 'File size needs to be be less than 100MB',
            duration: 6000,
          });
          this.props.hideSpinner();
          return;
        }
        return await this.uploadDocument(file);
      }
    } else {
      return await this.uploadPdf(file);
    }
  };

  applyTheme = (pageId, theme) =>
    new Promise((resolve, reject) => {
      try {
        var pageIndex = pageIds.indexOf(pageId);
        currStage.clear();
        currStage.removeChildren();
        let imageObj = new Image();
        imageObj.crossOrigin = 'Anonymous';
        imageObj.src = this.Background.getBackground(pageId);
        imageObj.onload = () => {
          const oldBackgroundLayer = currStage.findOne('.background');
          if (oldBackgroundLayer) oldBackgroundLayer.destroy();
          let backgroundBoundaries = {};
          if (!SOLID_BACKGROUNDS.includes(theme)) {
            backgroundBoundaries = {
              scaleX: 1 / this.scale,
              scaleY: 1 / this.scale,
              x: -this.position.x / this.scale,
              y: -this.position.y / this.scale,
            };
          }
          const newBackgroundLayer = new Konva.Layer({
            name: 'background',
            ...backgroundBoundaries,
          });
          var map = new Konva.Image({
            x: 0,
            y: 0,
            image: imageObj,
            zIndex: 0,
            width: this.size.width,
            height: this.size.height,
          });
          newBackgroundLayer.add(map);
          currStage.add(newBackgroundLayer);
          newBackgroundLayer.zIndex(0);
          if (theme === BG_TYPES.BG_MATH) {
            /** @type {Konva.Layer | undefined} */
            const gridLayer = currStage.findOne('#grid-layer');
            if (gridLayer) {
              currStage.add(gridLayer);
              gridLayer.zIndex(1);
            } else {
              this.setState({ gridRenderFlag: !this.state.gridRenderFlag, isGridEnabled: true });
            }
          } else {
            this.setState({ isGridEnabled: false });
          }
          currStage.add(this.pageLayouts[pageIndex]);
          currStage.draw();
          this.props.updateTheme(theme);
          resolve({ pageId, theme });
        };
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });

  getStageBackgroundSrc = (pageIndex) => {
    const pageId = pageIds[pageIndex];
    return this.Background.getBackground(pageId);
  };

  activateVideoModal = () => this.setState({ showLocalVideoDialog: true });

  showVideo = (url) => {
    this.setState({ localVideoURL: url });
    if (isMobile) {
      if (url.includes('youtube')) {
        window.open(url, '_blank');
        return;
      }
    }
    this.activateVideoModal();
  };

  showDocument = (pdf_url, size = 0) => {
    let encodedURL = encodeURIComponent(pdf_url);
    const unSupportableFileSize = size > 25 * 1024 * 1024;

    if (unSupportableFileSize) {
      return window.open(pdf_url, '_blank');
    } else if (!isMobile) {
      this.setState({
        documentUrl:
          'https://docs.google.com/viewer?url=' +
          encodedURL +
          '&pid=explorer&efh=false&a=v&chrome=false&embedded=true',
        showDocumentDialog: true,
      });
      return;
    } else {
      let url =
        'https://docs.google.com/viewer?url=' +
        encodedURL +
        '&pid=explorer&efh=false&a=v&chrome=false&embedded=true';
      window.open(url, '_blank');
    }
  };

  showPDF = (pdf_url) => {
    if (!isMobile) {
      this.setState(
        {
          pdfUrl: pdf_url,
        },
        () =>
          setTimeout(() => {
            this.setState({ pdfDialogOpen: true });
          }, 500),
      );
    } else {
      window.open(pdf_url, '_blank');
    }
  };

  uploadLocalVideo = async (fileObj, options) => {
    const doc = fileObj;
    const id = generateUUID();
    const srcfilename = doc.name;
    const attributes = await this.positionElement({
      pointerPosition: options.pointerPosition,
      width: this.state.defaultElementSize.video.width,
      height: this.state.defaultElementSize.video.height,
    });
    if (!attributes) return;
    const { scale, ...elementBounds } = attributes;
    elementBounds.width = elementBounds.width * scale.x;
    elementBounds.height = elementBounds.height * scale.y;
    const videoProps = {
      elementId: id,
      elementType: 'video',
      elementBounds,
      elementScale: { x: 1, y: 1 },
      bytesId: '',
      pageId: selectedPageId,
      fileFormat: doc.type,
      fileName: srcfilename,
    };
    const item = this.createItem(videoProps);
    this.setState({ videos: [...this.state.videos, videoProps] });
    await this.renderVideoIcon(videoProps);
    this.sendMessage(item);
    const tmUrl = Config.uploadURL;
    const formData = new FormData();
    formData.append('file', doc);
    formData.append('classId', this.state.classId);
    formData.append('flowId', this.state.flowId);
    formData.append('pageId', '' + selectedPageId);
    formData.append('elementId', id);
    formData.append('type', 'video');

    const xhr = new XMLHttpRequest();
    let progressHandler = (e) => {
      const progressIndicator = document.getElementById(id);
      if (!progressIndicator) return;
      progressIndicator.innerHTML = 'Uploaded ' + Math.round((e.loaded / e.total) * 100) + '%';
    };

    xhr.upload.onprogress = progressHandler;
    xhr.open('POST', tmUrl);
    xhr.setRequestHeader('srcfilename', srcfilename);
    xhr.setRequestHeader('filetype', doc.type);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 406) {
        this.setState({ upgradePlan: true });

        const res = JSON.parse(xhr.responseText);
        if (res.type === EVENTS.FEATURE_LIMIT_EXHAUSTED) {
          this.props.hideSpinner();
          this.props.showLicenseExhaustedDialog();
        }
      }
    };
    xhr.send(formData);
  };

  AddVideoCurrentPage = (obj, fileObj) => {
    var objUrl = URL.createObjectURL(fileObj);

    var border = new Konva.Rect({
      x: obj.elementBounds.x,
      y: obj.elementBounds.y,
      width: 150,
      height: 150,
      fill: 'white',
      stroke: 'black',
      strokeWidth: 2,
    });

    var docText = new Konva.Text({
      x: obj.elementBounds.x + 20,
      y: obj.elementBounds.y + 80,
      width: 120,
      draggable: false,
      id: obj.elementId,
      text: obj.srcfilename,
      fontSize: 12,
      fill: '#B0BEC5',
      type: obj.filetype,
      visible: true,
      fontStyle: 'bold',
      url: objUrl,
    });

    this.pageLayouts[pageIds.indexOf(selectedPageId)].add(border);
    this.pageLayouts[pageIds.indexOf(selectedPageId)].add(docText);
    this.pageLayouts[pageIds.indexOf(selectedPageId)].draw();

    this.pageLayouts[pageIds.indexOf(selectedPageId)].on('click tap', () => {
      var tempRes = docText.getAttrs();
      this.showVideo(tempRes.s3Url);
    });
  };

  renderBackground = async (pageId, theme, customImageData, broadcast = true) => {
    try {
      let backgroundObj = {};
      if (theme === BG_TYPES.BG_CUSTOM && customImageData instanceof File) {
        const uploadedDetails = await this.Background.uploadBackgroundImage(
          this.props.selectedPageId,
          customImageData,
        );
        backgroundObj = {
          type: BG_CHANGE_MESSAGE,
          flowId: this.state.classId,
          classId: this.state.classId,
          detail: {
            pageId,
            ...uploadedDetails,
            type: theme,
          },
        };
        await this.Background.setTheme(backgroundObj.detail);
      } else if (theme === BG_TYPES.BG_CUSTOM) {
        backgroundObj = {
          type: BG_CHANGE_MESSAGE,
          flowId: this.state.classId,
          classId: this.state.classId,
          detail: {
            ...customImageData,
          },
        };
        //update local and apply theme
        await this.Background.setTheme(backgroundObj.detail);
      } else if (theme) {
        backgroundObj = {
          type: BG_CHANGE_MESSAGE,
          flowId: this.state.classId,
          classId: this.state.classId,
          detail: {
            pageId,
            url: '',
            metaData: {
              Key: '',
              Bucket: '',
            },
            type: theme,
          },
        };
        //update local and apply theme
        await this.Background.setTheme(backgroundObj.detail);
      }
      let currentTheme = this.Background.getThemeName(pageId);
      if (pageId === selectedPageId) {
        await this.applyTheme(pageId, currentTheme);
      }
      if (broadcast && !isEmpty(backgroundObj)) {
        this.sendMessage(backgroundObj);
      }
    } catch (e) {
      console.log(e);
    }
  };

  showLocalVideo = (video_url) => {
    this.setState({ showLocalVideoDialog: true });
    this.setState({ localVideoURL: video_url });
  };

  uploadPdf = (fileObj) => {
    return new Promise((resolve, reject) => {
      var doc = fileObj;
      var id = generateUUID();
      var srcfilename = doc.name;
      var docUrl;
      var reader = new FileReader();
      reader.readAsDataURL(doc);
      reader.onload = (e) => {
        if (e.target?.result) {
          docUrl = e.target.result;
        }
      };
      const pos = this.randomPos();
      var docProps = {
        elementId: id,
        elementType: 'document',
        elementBounds: {
          x: pos.x,
          y: pos.y,
          width: this.state.defaultElementSize.document.width * this.props.transformFactor,
          height: this.state.defaultElementSize.document.height * this.props.transformFactor,
        },
        pageId: selectedPageId,
        s3Url: '',
        fileFormat: 'document/pdf',
        fileName: srcfilename,
        mimeType: fileObj.type,
      };
      var item = this.createItem(docProps);
      item.type = 'document';
      this.sendMessage(item);

      var tmUrl = Config.uploadURL;

      var formData = new FormData();
      formData.append('file', doc);
      formData.append('classId', this.state.classId);
      formData.append('flowId', this.state.flowId);
      formData.append('pageId', '' + selectedPageId);
      formData.append('elementId', id);
      formData.append('type', 'document');

      var xhr = new XMLHttpRequest();
      xhr.open('POST', tmUrl);
      xhr.setRequestHeader('srcfilename', srcfilename);
      xhr.setRequestHeader('filetype', 'document/pdf');

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          resolve({ message: 'Uploaded successfully', data: xhr.response });
        } else if (xhr.readyState === 4 && xhr.status === 406) {
          this.setState({ upgradePlan: true });

          const res = JSON.parse(xhr.responseText);
          if (res.type === EVENTS.FEATURE_LIMIT_EXHAUSTED) {
            this.props.hideSpinner();
            this.props.showLicenseExhaustedDialog();
          }

          reject({ message: 'Upgrade Plan' });
        }
      };
      xhr.send(formData);
    });
  };

  uploadDocument = (fileObj) => {
    return new Promise((resolve, reject) => {
      var doc = fileObj;
      var id = generateUUID();
      var srcfilename = doc.name;
      var type = doc.type;
      var mType = this.mimeType(doc.type);
      if (mType === '') {
        return false;
      }
      var fileType = 'document/' + mType;
      var pos = this.randomPos();
      const { x, y } = pos;
      var docProps = {
        elementId: id,
        elementType: 'document',
        elementBounds: {
          x,
          y,
          width: this.state.defaultElementSize.document.width * this.props.transformFactor,
          height: this.state.defaultElementSize.document.height * this.props.transformFactor,
        },
        pageId: selectedPageId,
        mimeType: fileObj.type,
        fileFormat: fileType,
        fileName: srcfilename,
      };
      var tmUrl = Config.uploadURL;
      var formData = new FormData();
      formData.append('file', doc);
      formData.append('classId', this.state.classId);
      formData.append('flowId', this.state.flowId);
      formData.append('pageId', '' + selectedPageId);
      formData.append('elementId', id);
      formData.append('type', 'document');
      let progressHandler = (e) =>
        this.setStateSynchronous({
          [id]: Math.round((e.loaded / e.total) * 100),
        });
      var xhr = new XMLHttpRequest();
      xhr.upload.onprogress = progressHandler;
      xhr.open('POST', tmUrl);
      xhr.setRequestHeader('srcfilename', srcfilename);
      xhr.setRequestHeader('filetype', fileType);
      xhr.setRequestHeader('Accept', type);
      xhr.onreadystatechange = async () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          const { s3Url, fileType } = response.data;
          docProps.s3Url = s3Url;
          const item = this.createItem(docProps);
          item.type = 'document';
          this.sendMessage(item);
          await this.addDocument({ ...item, filetype: fileType });
          resolve({ ...item, filetype: fileType });
        } else if (xhr.readyState === 4 && xhr.status === 406) {
          this.setState({ upgradePlan: true });

          const res = JSON.parse(xhr.responseText);
          if (res.type === EVENTS.FEATURE_LIMIT_EXHAUSTED) {
            this.props.hideSpinner();
            this.props.showLicenseExhaustedDialog();
          }

          reject();
        }
      };
      xhr.send(formData);
    });
  };

  positionElement = (args) =>
    positionElement(args, {
      canvasBoard: this,
      currentPage: this.pageLayouts[pageIds.indexOf(selectedPageId)],
      mainStage: currStage,
      selectedPageIndex,
    });

  uploadImage = (doc, options = {}) => {
    const fillPage = options.scale === 'MAX';
    const { isDocument, count, index } = options;
    const { pointerPosition } = options;

    return new Promise((resolve, reject) => {
      const id = generateUUID();
      const srcfilename = doc.name;
      const doctypeArr = doc.type.split('/');
      const reader = new FileReader();
      const tempImage = new Image();
      reader.readAsDataURL(doc);
      reader.onload = (e) => {
        const result = e.target?.result;
        if (result && typeof result === 'string') {
          tempImage.src = result;
        }
      };

      tempImage.onload = async () => {
        let { width, height } = tempImage;
        const attributes = await this.positionElement({
          pointerPosition,
          width,
          height,
          maxFit: fillPage ? 1 : 0.8,
          count,
          index,
        });
        if (!attributes) return this.props.hideSpinner();
        const { x, y, scale } = attributes;
        width = width * scale.x;
        height = height * scale.y;
        const itemConfig = {
          elementId: id,
          elementType: doctypeArr[0],
          elementBounds: {
            x,
            y,
            width,
            height,
          },
          elementScale: { x: 1, y: 1 },
          bytesId: '',
          pageId: selectedPageId,
        };
        const item = this.createItem(itemConfig);
        item.type = doctypeArr[0];
        const loaderDisplayData = {
          x,
          y,
          scale,
          width: 100,
          height: 100,
        };
        /** show loading image, till image is loaded */
        let imageIcon = new Image(),
          loaderImage;
        imageIcon.src = '/image.png';
        imageIcon.onload = () => {
          loaderImage = new Konva.Image({
            ...loaderDisplayData,
            ...(pointerPosition ? {} : isDocument ? {} : { x, y }),
            image: imageIcon,
            draggable: false,
          });
          const shapesLayer = this.pageLayouts[pageIds.indexOf(selectedPageId)];
          shapesLayer && shapesLayer.add(loaderImage);
        };

        /**
         * send socket message to other users to add new image
         */
        this.sendMessage(item);

        const { uploadURL } = Config;
        const formData = new FormData();
        formData.append('file', doc);
        formData.append('classId', this.state.classId);
        formData.append('flowId', this.state.flowId);
        formData.append('pageId', '' + selectedPageId);
        formData.append('elementId', id);
        formData.append('type', 'image');
        const xhr = new XMLHttpRequest();
        xhr.open('POST', uploadURL);
        xhr.setRequestHeader('srcfilename', srcfilename);
        xhr.setRequestHeader('filetype', doctypeArr[0]);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            if (doctypeArr[0] === 'image') {
              item.url = JSON.parse(xhr.response).data.s3Url;
              const imageObj = new Image();
              imageObj.src = JSON.parse(xhr.response).data.s3Url;
              imageObj.onload = () => {
                const imagePlot = new Konva.Image({
                  x,
                  y,
                  width,
                  height,
                  id: item.elementId,
                  image: imageObj,
                  draggable: false,
                });
                loaderImage.destroy(); /** remove the loader image */
                const shapesLayer = this.pageLayouts[pageIds.indexOf(selectedPageId)];
                shapesLayer && shapesLayer.add(imagePlot);
                this.removeTransformer();
                this.addImageTransformer(imagePlot, item);
                imagePlot.fire('click');
                resolve(imagePlot);
              };
            } else {
              reject('type is not image');
            }
          } else if (xhr.readyState === 4 && xhr.status === 406) {
            this.setState({ upgradePlan: true });
            const res = JSON.parse(xhr.responseText);
            if (res.type === EVENTS.FEATURE_LIMIT_EXHAUSTED) {
              this.props.hideSpinner();
              this.props.showLicenseExhaustedDialog();
            }

            reject('Upload Image failed');
          }
        };
        xhr.onerror = () => {
          reject('Upload Image failed');
        };
        xhr.send(formData);
      };
      tempImage.onerror = () => {
        reject('Upload Image failed');
      };
    });
  };

  addNewPage = async (index) => {
    let newPageId = generateShortId();
    var pageProps = {
      type: 'page-add',
      pageId: newPageId,
      index: index || pageIds.length,
    };
    let bgChanges = this.Background.addPage(newPageId);
    let backgroundObj = {
      type: BG_CHANGE_MESSAGE,
      flowId: this.state.flowId,
      classId: this.state.classId,
      detail: {
        ...bgChanges,
      },
    };
    this.sendMessage(pageProps);
    await this.addPage(newPageId, index);
    this.pagination.updatePageIds(index, newPageId);
    setTimeout(() => {
      this.sendMessage(backgroundObj);
    }, 100);
  };

  deleteAnyPage = async (pageId, existing = false) => {
    const item = {
      type: 'page-delete',
      pageId,
      flowId: this.state.flowId,
      classId: this.state.classId,
    };
    if (pageIds.length > 1) {
      if (!existing) this.sendMessage(item);
      await this.Background.deletePage(selectedPageId);
      await this.removePage(pageId);
    } else {
      const pageIndex = pageIds.indexOf(pageId);
      const ids = this.pageLayouts[pageIndex] ? getChildrenIds(this.pageLayouts[pageIndex]) : [];
      ids.forEach((id) => {
        const tm = { type: 'remove', elementId: id };
        if (!existing) this.sendMessage(tm);
        this.removeElement(this.pageLayouts[pageIds.indexOf(selectedPageId)], tm.elementId, false);
      });
      this.removeTransformer();
    }
  };

  addPage = async (pid, index) => {
    this.pageLayouts.splice(index, 0, new Konva.Layer({ name: 'page' }));
    pageIds.splice(index, 0, pid);
    await this.pageSelect(pid);
  };

  addPageFromSocket = async (pid, index, oldPageId) => {
    this.pageLayouts.splice(index, 0, new Konva.Layer({ name: 'page' }));
    pageIds.splice(index, 0, pid);
    await this.applyTheme(oldPageId);
  };

  removePage = async (item) => {
    let pageIndex = pageIds.indexOf(item);
    var ids = this.pageLayouts[pageIndex] ? getChildrenIds(this.pageLayouts[pageIndex]) : [];
    for (var i = 0; i < ids.length; i++) {
      var tm = { type: 'remove', elementId: ids[i] };
      this.sendMessage(tm);
    }
    if (this.pageLayouts.length > 1) {
      if (this.pageLayouts[pageIndex]) this.pageLayouts[pageIndex].destroy();
      this.pageLayouts.splice(pageIndex, 1);
      pageIds.splice(pageIndex, 1);
      if (pageIndex === 0 && pageIds.length === 1) {
        await this.pageSelect(pageIds[pageIndex]);
      } else if (pageIndex >= pageIds.length - 1) {
        await this.pageSelect(pageIds[pageIndex - 1]);
      } else {
        await this.pageSelect(pageIds[pageIndex + 1]);
      }
    } else {
      await this.pageSelect(pageIds[0]);
    }
  };

  removeOtherPage = (pageId) => {
    let pageIndex = pageIds.indexOf(pageId);
    if (this.pageLayouts.length > 1) {
      if (this.pageLayouts[pageIndex]) this.pageLayouts[pageIndex].destroy();
      this.pageLayouts.splice(pageIndex, 1);
      pageIds.splice(pageIndex, 1);
    }
  };

  async pageSelect(pageId, existing = false) {
    var pageIndex = pageIds.indexOf(pageId);
    selectedPageId = pageId;
    await this.props.updateSelectedPageId(selectedPageId);
    this.props.setCurrentPageId(selectedPageId);
    await this.renderBackground(pageId);
    selectedPageIndex = pageIndex;
    if (!existing) {
      if (this.props.syncPageChange === false) {
        return;
      }
      this.sendMessage({
        pageId: selectedPageId,
        type: 'page-select',
      });
      this.removeTransformer();
    }
  }

  mimeType = (fileType) => {
    var mimeTypeArr = {};

    mimeTypeArr['application/pdf'] = 'pdf';
    mimeTypeArr['application/vnd.ms-powerpoint'] = 'ppt';
    mimeTypeArr['application/vnd.openxmlformats-officedocument.presentationml.presentation'] =
      'pptx';
    mimeTypeArr['text/plain'] = 'txt';
    mimeTypeArr['application/vnd.ms-excel'] = 'xls';
    mimeTypeArr['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] = 'xlsx';
    mimeTypeArr['application/msword'] = 'doc';
    mimeTypeArr['application/vnd.openxmlformats-officedocument.wordprocessingml.document'] = 'docx';

    var res = '';
    if (mimeTypeArr[fileType] !== undefined) {
      res = mimeTypeArr[fileType];
    }
    return res;
  };

  closeDocumentDialog = () => this.setState({ showDocumentDialog: false });

  closeVideoDialog = () => this.setState({ showLocalVideoDialog: false });

  dropVideo = (event, id) => {
    event.preventDefault();
    const parentPosition = this.myRef.current.getBoundingClientRect();
    const position = event.target.getBoundingClientRect();
    const scale = this.scale;
    const { x: stageX, y: stageY } = this.position;
    const left = (position.left - parentPosition.left) / scale - stageX / scale;
    const top = (position.top - parentPosition.top) / scale - stageY / scale;
    const manipulationObj = {
      type: 'manipulation',
      flowId: this.state.flowId,
      classId: this.state.classId,
      elementIds: [id],
      endBounds: [
        {
          x: left || event.clientX,
          y: top || event.clientY,
        },
      ],
      endScales: [
        {
          x: 1,
          y: 1,
        },
      ],
      angles: [0],
    };
    this.sendMessage(manipulationObj);
    this.handleSocket(manipulationObj, false, false);
  };

  videoPlayerMenuHandler = (event) => {
    const elementId = event.target.getAttribute('elementId');
    this.setState({
      elementMenuItems: [
        {
          name: 'Delete',
          handler: () => this.deleteElement(elementId),
        },
      ],
      mouseX: event.clientX,
      mouseY: event.clientY,
      openMenu: true,
      selectedElementId: elementId,
    });
  };

  handleRecording = async () => {
    await this.setStateSynchronous({
      recording: !this.state.recording,
    });
    await this.setStateSynchronous({
      pixelRatio: 1,
    });
    window.dispatchEvent(new Event('resize'));
  };

  renderVideoIcon = async (obj) => {
    const {
      elementBounds: { x, y, height, width },
      elementScale: scale,
      fileName,
    } = obj;
    let iconObj = { x, y, height, width, scale, fileName: fileName || 'video' };
    await this.renderSvg(
      obj,
      iconObj,
      this.playVideo,
      obj.s3Url,
      undefined,
      !(this.props.transformFactor >= 0.5),
    );
  };

  playVideo = (s3Url) => {
    this.showVideo(s3Url);
  };

  applyZoomEffects = (scale, position, sync = true) => {
    if (scale) {
      this.scale = scale;
      this.props.setCanvasScale(scale);
      this.resetHitStrokes(scale);
    }
    if (position) {
      this.position = position;
      this.props.setCanvasPosition(position);
    }
    const backgroundAttrs = {
      ...(scale ? { scaleX: 1 / scale, scaleY: 1 / scale } : {}),
      ...(position ? { x: -position.x / this.scale, y: -position.y / this.scale } : {}),
    };
    if (!SOLID_BACKGROUNDS.includes(this.props.theme)) {
      const backgroundLayer = currStage.findOne('.background');
      if (backgroundLayer) backgroundLayer.setAttrs(backgroundAttrs);
    }
    if (scale) this.positionTransformerOptionButton();
    sync && this.handleZoomSync();
  };

  zoomToDefault = () =>
    new Promise((resolve, reject) => {
      try {
        if (this.zoomAnimationPlaying) return resolve(false);
        const { x, y, scale } = this.calculateStageDefaults();
        if (this.scale === scale && this.position.x === x && this.position.y === y) {
          if (this.props.presentingMode) this.handleZoomSync();
          return resolve(true);
        }
        this.zoomAnimationPlaying = true;
        currStage.to({
          x,
          y,
          scaleX: scale,
          scaleY: scale,
          duration: 0.5,
          onFinish: () => {
            this.zoomAnimationPlaying = false;
            this.applyZoomEffects(scale, { x, y });
            resolve(true);
          },
        });
      } catch (error) {
        reject(error);
      }
    });

  positionOnZoom = (scale) => {
    const currentScale = this.scale;
    const { width: stageWidth, height: stageHeight } = currStage.size();
    const { x, y } = currStage.position();
    const centerX = stageWidth / 2 - x;
    const centerY = stageHeight / 2 - y;
    const xRatio = centerX / (stageWidth * currentScale);
    const yRatio = centerY / (stageHeight * currentScale);
    const scaleChange = scale - currentScale;
    const widthChange = stageWidth * scaleChange;
    const heightChange = stageHeight * scaleChange;
    const newX = x - widthChange * xRatio;
    const newY = y - heightChange * yRatio;
    const newPosition = {
      x: newX,
      y: newY,
    };
    return newPosition;
  };

  applyZoom = (scale, duration = 0) =>
    new Promise((resolve, reject) => {
      try {
        if (this.zoomAnimationPlaying) return resolve(false);
        const currentScale = this.scale;
        if (scale < 1) scale = 1;
        if (scale > this.props.maxScale) scale = this.props.maxScale;
        if (scale === currentScale) return resolve(true);
        let position = this.positionOnZoom(scale);
        position = this.limitPosition(scale, position);
        this.zoomAnimationPlaying = true;
        currStage.to({
          ...position,
          scaleX: scale,
          scaleY: scale,
          duration,
          onFinish: () => {
            this.applyZoomEffects(scale, position, true);
            this.zoomAnimationPlaying = false;
            resolve(true);
          },
        });
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });

  zoomToFit = async () =>
    await zoomToFit({
      canvasBoard: this,
      mainStage: currStage,
      elementsLayer: this.pageLayouts[pageIds.indexOf(selectedPageId)],
    });

  limitPosition = (scale, position) => limitPosition({ scale, position, mainStage: currStage });

  zoomPanHandler = (event) => {
    if (event.type !== 'keydown') {
      event.preventDefault();
      event.stopPropagation();
    } else if (event.ctrlKey) {
      const isZoom = [107, 187, 109, 189].includes(event.keyCode);
      isZoom && event.preventDefault();
      isZoom && event.stopPropagation();
    }
    if (this.zoomAnimationPlaying || !currStage || this.isAddingText) return;
    const oldScale = this.scale;
    let scaleBy = 1.1;
    let newScale = oldScale;
    const maxScale = this.props.maxScale;
    let newPos = {
      x: 0,
      y: 0,
    };

    switch (event.type) {
      case 'wheel': {
        const isZoom = event.ctrlKey || event.metaKey;
        const isPan = !isZoom;

        if (isZoom) {
          const pointer = currStage.getPointerPosition();
          if (!pointer) return;
          const mousePointTo = currStage.getRelativePointerPosition();
          if (!mousePointTo) return;
          let direction = event.deltaY > 0 ? -1 : 1;
          newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;
          newScale = Math.round(newScale * 20) * 0.05;
          if (newScale > maxScale) newScale = maxScale;
          newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
          };
        }

        if (isPan) {
          const { deltaX, deltaY, shiftKey } = event;
          const isDesktopHorizontalPan = shiftKey;
          const { x: oldX, y: oldY } = currStage.position();
          const movementX = isDesktopHorizontalPan ? -deltaY : -deltaX;
          const movementY = isDesktopHorizontalPan ? 0 : -deltaY;
          newPos = { x: oldX + movementX, y: oldY + movementY };
        }

        break;
      }
      case 'touchmove': {
        const touch1 = event.touches[0];
        const touch2 = event.touches[1];

        if (touch1 && touch2) {
          if (currStage.isDragging()) {
            currStage.stopDrag();
          }

          const { top, left } = this.myRef.current.getBoundingClientRect();

          const p1 = {
            x: touch1.clientX - left,
            y: touch1.clientY - top,
          };

          const p2 = {
            x: touch2.clientX - left,
            y: touch2.clientY - top,
          };

          const newCenter = this.getCenter(p1, p2);

          if (!this.lastCenter) {
            this.lastCenter = newCenter;
            return;
          }

          const dist = this.getDistance(p1, p2);
          if (!this.lastDist) this.lastDist = dist;
          newScale = oldScale * (dist / this.lastDist);
          if (newScale > maxScale) newScale = maxScale;

          const pointTo = {
            x: (newCenter.x - currStage.x()) / oldScale,
            y: (newCenter.y - currStage.y()) / oldScale,
          };

          const dx = newCenter.x - this.lastCenter.x;
          const dy = newCenter.y - this.lastCenter.y;

          newPos = {
            x: newCenter.x - pointTo.x * newScale + dx,
            y: newCenter.y - pointTo.y * newScale + dy,
          };

          this.lastDist = dist;
          this.lastCenter = newCenter;
        }
        break;
      }
      case 'gesturestart': {
        this.isZooming = true;
        tempLine && tempLine.destroy();
        tempLine = undefined;
        this.lastScale = this.scale;
        return;
      }
      case 'gesturechange': {
        event.stopPropagation();
        newScale = event.scale * this.lastScale;
        if (newScale > maxScale) newScale = maxScale;
        const pointer = currStage.getPointerPosition();
        if (!pointer) return;
        const mousePointTo = currStage.getRelativePointerPosition();
        if (!mousePointTo) return;
        newPos = {
          x: pointer.x - mousePointTo.x * newScale,
          y: pointer.y - mousePointTo.y * newScale,
        };
        break;
      }
      case 'gestureend': {
        this.isZooming = false;
        return;
      }
      case 'keydown': {
        if ((event.ctrlKey || event.metaKey) && [48, 96].includes(event.keyCode))
          return this.applyZoom(this.props.defaultScale, 0.5);
        const isZoom =
          (event.ctrlKey || event.metaKey) && [107, 187, 109, 189].includes(event.keyCode);
        const isPan =
          this.scale > 1 &&
          [37, 38, 39, 40].includes(event.keyCode) &&
          event.target === this.myRef.current;

        if (isZoom) {
          event.preventDefault();
          const isZoomIn = [107, 187].includes(event.keyCode);
          newScale = isZoomIn ? oldScale * scaleBy : oldScale / scaleBy;
          newScale = Math.round(newScale * 20) * 0.05;
          if (newScale > maxScale) newScale = maxScale;
          newPos = this.positionOnZoom(newScale);
          break;
        }

        if (isPan) {
          let newX = currStage.x(),
            newY = currStage.y();

          switch (event.keyCode) {
            case 37:
              newX = newX + 100;
              break;
            case 38:
              newY = newY + 100;
              break;
            case 39:
              newX = newX - 100;
              break;
            case 40:
              newY = newY - 100;
              break;
            default: {
              return;
            }
          }

          newPos = { x: newX, y: newY };
          break;
        }

        return;
      }

      default: {
        return;
      }
    }

    if (newScale < 1) newScale = 1;
    if (newScale > maxScale) newScale = maxScale;
    const scaleChanged = newScale !== oldScale;
    if (scaleChanged) currStage.scale({ x: newScale, y: newScale });
    newPos = this.limitPosition(newScale, newPos);
    currStage.position(newPos);
    this.applyZoomEffects(scaleChanged && newScale, newPos, true);
  };

  getStages = () => this.pageLayouts;
  getCurrentStage = () => currStage;

  setCursor = (cursor) => (currStage.container().style.cursor = cursor);

  toggleChat = (args = {}) => {
    const { elementId, type, s3Url, text, swapChat } = args;
    if ((s3Url && s3Url === this.state.chatContentUrl) || elementId === this.state.chatElementId)
      return this.props.setSnackbar({
        message: 'Chat is already open',
        duration: 6000,
      });
    if (this.state.chatElementId && elementId && !swapChat) {
      return this.setState({
        showSwapChatDialog: true,
        newChatDetails: { elementId, type, text, s3Url },
      });
    }
    this.setState({
      chatElementId: elementId,
      chatType: type,
      chatContentUrl: s3Url,
      chatText: text,
    });
  };

  confirmSwapChat = () => {
    this.toggleChat({ ...this.state.newChatDetails, swapChat: true });
  };

  closeSwapChatDialog = () => this.setState({ showSwapChatDialog: false });

  addToLessonHandler = (text) => this.addTextEditor({ text }, '60%');

  render() {
    const ColorCircularProgress = withStyles({
      root: {
        color: '#00695c',
      },
    })(CircularProgress);

    const videos = [];
    this.state.videos.forEach((video) => {
      if (video.pageId !== selectedPageId) return;
      const {
        elementId,
        s3Url,
        elementBounds: { x, y },
      } = video;
      const { scale } = this;
      const relativeX = x * scale;
      const relativeY = y * scale;
      const { x: stageX, y: stageY } = this.position;
      const left = relativeX - -stageX;
      const top = relativeY - -stageY;
      videos.push(
        <Draggable
          onStop={(e) => this.dropVideo(e, video.elementId)}
          disabled={this.props.inactivateAll}
          key={video.elementId}
          bounds="parent"
        >
          <div style={{ position: 'absolute', left, top, pointerEvents: 'all' }}>
            <div
              style={{
                // height: video.elementBounds.height,
                width: video.elementBounds.width * this.scale,
              }}
            >
              {video.s3Url ? (
                <div>
                  {this.props.transformFactor >= 0.5 && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end',
                        gap: 12,
                        padding: '4px 8px',
                        opacity: '0.75',
                        background: 'rgba(255, 255, 255, 0.75)',
                      }}
                    >
                      {!this.props.inactivateAll && (
                        <>
                          <VideoOptionsButton
                            toggleChat={() =>
                              this.toggleChat({
                                elementId,
                                s3Url,
                                type: 'YOUTUBE',
                              })
                            }
                          />
                          <Divider
                            orientation="vertical"
                            flexItem
                            style={{ margin: '4px 0px', backgroundColor: '#0006' }}
                          />
                          <IconButton
                            size="small"
                            onMouseDown={(e) => e.stopPropagation()}
                            onClick={() => this.deleteElement(video.elementId)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </>
                      )}
                    </div>
                  )}
                  {this.props.transformFactor >= 0.5 && (
                    <ReactPlayer
                      url={video.s3Url}
                      height={video.elementBounds.height * this.scale}
                      width={video.elementBounds.width * this.scale}
                      controls
                      title={'Video'}
                    />
                  )}
                </div>
              ) : (
                <div>
                  <img
                    style={{
                      height: video.elementBounds.height * this.scale,
                      width: video.elementBounds.width * this.scale,
                    }}
                    alt={'Video loading'}
                    src={'/videoloading.png'}
                  />
                  <p id={video.elementId} />
                </div>
              )}
            </div>
          </div>
        </Draggable>,
      );
    });

    const { classes, showMiniMap } = this.props;
    return (
      <>
        <WhiteboardChat
          onClose={this.toggleChat}
          onAddToLesson={this.addToLessonHandler}
          elementId={this.state.chatElementId}
          url={this.state.chatContentUrl}
          textContent={this.state.chatText}
          type={this.state.chatType}
          lessonName={getBoardInfo() ? getBoardInfo().name : ''}
        />
        <div
          onDragOver={(event) => event.preventDefault()}
          ref={this.canvasComponentRef}
          onMouseDown={this.props.disableToolsPointerEvents}
          onMouseLeave={this.props.enableToolsPointerEvents}
          onMouseUp={this.props.enableToolsPointerEvents}
          onTouchStart={this.props.disableToolsPointerEvents}
          onTouchEnd={this.props.enableToolsPointerEvents}
          onTouchCancel={this.props.enableToolsPointerEvents}
          style={{ position: 'absolute', width: '100%', height: '100%' }}
        >
          <div
            className="container-fluid"
            id="canvasParent"
            style={{ backgroundColor: '#808080' }}
            onDragOver={(event) => event.preventDefault()}
          >
            <div style={{ position: 'relative', maxWidth: '100vw', overflow: 'hidden' }}>
              <MouseCursorContainer />
              <div
                ref={this.myRef}
                id="container"
                className="no-focus-visible"
                tabIndex={-1}
                onDragOver={(event) => event.preventDefault()}
              >
                {videos}
              </div>
              {showMiniMap && (
                <MiniMap mainStage={currStage} updateInterval={100} background={this.props.theme} />
              )}
            </div>
          </div>
          <WhiteboardGrid
            stage={currStage}
            stageSize={this.size}
            renderFlag={this.state.gridRenderFlag}
            show={this.state.isGridEnabled}
          />
          {/** In this component background change button was not showing in UI , but it's required for current working logic */}
          <div style={{ visibility: 'hidden' }}>
            <Background
              theme={this.props.theme}
              onIconClick={async (theme, customImageData) =>
                await this.renderBackground(this.props.selectedPageId, theme, customImageData)
              }
              backgroundData={{
                ...this.props.backgroundData,
                visible: 'visible',
              }}
              ref={(instance) => (this.Background = instance)}
              toolAreaWidth={this.props.isViewOpen ? this.props.toolAreaWidth : '100vw'}
            />
          </div>
          <Menu
            id="simple-menu"
            keepMounted
            anchorReference="anchorPosition"
            anchorPosition={{
              top: this.state.mouseY + 10,
              left: this.state.mouseX + 45,
            }}
            open={!!this.state.openMenu}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => this.setState({ openMenu: false })}
            onClick={() => this.setState({ openMenu: false })}
          >
            {this.state.elementMenuItems.map((item) => (
              <MenuItem onClick={item.handler} key={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Menu>
          <Dialog
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={!!this.state.showDocumentDialog}
            onClose={this.closeDocumentDialog}
            maxWidth={'lg'}
          >
            <Iframe width="60vw" height="80vh" url={this.state.documentUrl}></Iframe>
          </Dialog>
          <Dialog
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={!!this.state.pdfDialogOpen}
            onClose={() => this.setState({ pdfUrl: null, pdfDialogOpen: false })}
            classes={{ paper: classes.pdfDocument }}
          >
            <Iframe
              style={{
                height: '80vh',
              }}
              url={this.state.pdfUrl}
            />
          </Dialog>
          <Dialog
            fullScreen={true}
            scroll={'paper'}
            open={!!this.state.showLoader}
            className="pdfGrid"
            PaperProps={{
              classes: {
                root: classes.paper,
              },
            }}
          >
            <div className="loaderGrid">
              <ColorCircularProgress style={{ color: '#fff' }} size={70} thickness={5} />
              <Box fontWeight="fontWeightMedium" style={{ fontSize: '20px', color: 'white' }}>
                {this.state.loaderMessage}
              </Box>
            </div>
          </Dialog>
          <DialogComponent
            maxWidth={'md'}
            open={!!this.state.showLocalVideoDialog}
            fullWidth={false}
            onClose={this.closeVideoDialog}
          >
            <ReactPlayer
              url={this.state.localVideoURL}
              width={'inherit'}
              height={'inherit'}
              controls
            />
          </DialogComponent>
        </div>
        {this.state.showSwapChatDialog && (
          <ConFirmDialog
            dialogTitle="Chat is already open"
            text="Do you want to exit the current chat session?"
            onClose={this.closeSwapChatDialog}
            onConfirm={this.confirmSwapChat}
          />
        )}
      </>
    );
  }
}

const styles = {
  root: {
    backgroundColor: 'transparent',
  },
  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  pdfDocument: {
    minHeight: '80vh',
    maxHeight: '80vh',
    minWidth: '80vh',
    maxWidth: '80vh',
  },
  activePresentation: {
    background: 'linear-gradient(0deg, rgba(51, 90, 251, 0.4), rgba(51, 90, 251, 0.4)), #FFFFFF',
    position: 'absolute',
    bottom: '12px',
    left: '89%',
    borderRadius: '6px',
    cursor: 'pointer',
    zIndex: 1001,
  },
  inactivePresentation: {
    background: 'white',
    position: 'absolute',
    bottom: '12px',
    left: '89%',
    borderRadius: '6px',
    cursor: 'pointer',
    zIndex: 1001,
  },
};

const mapStateToProps = (state) => {
  return {
    presentationMode: state.canvasReducer.presentationMode,
    isViewOpen: state.huddle.isViewOpen,
    presenters: state.canvasReducer.presenters,
    scale: state.canvasReducer.scale,
    position: state.canvasReducer.position,
    transformFactor: state.canvasReducer.transformFactor,
  };
};

export default React.memo(
  connect(
    mapStateToProps,
    {
      getallUser,
      addUser,
      removeUser,
      updateMousePosition,
      changePresentationMode,
      storePresenterInfo,
      resetPresenterInfo,
      resetAllPresenterInfo,
      setFileInfo,
      setCanvasScale,
      setCanvasPosition,
      setTransformFactor,
      setCurrentPageId,
      showSpinner,
      hideSpinner,
      setSnackbar,
      showLicenseExhaustedDialog,
    },
    null,
    { forwardRef: true },
    // @ts-ignore
  )(withStyles(styles)(CanvasBoard)),
);
