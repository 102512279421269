import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Box, Dialog, IconButton, Grid, useMediaQuery } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { SearchTextField } from '../SearchField/SearchField';
import CloudSearchIcon from '../../../IWB/assets/img/cloudsearch.svg';
import './KsarModal.scss';

function TabPanel(props) {
  const { children } = props;

  return <Box p={3}>{children}</Box>;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    alignItems: 'end',
  },
  dialogPaper: {
    // minHeight: '80vh',
    // maxHeight: '80vh',
  },
  tabsStyle: {
    background: '#28837D',
  },
  closeButton: {
    display: 'grid',
    width: '100%',
    justifyContent: 'end',
    margin: 10,
  },
}));

export function KsarModal({ open, toggleModal, setSearchValue, searchValue, children }) {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const searchHandler = (e) => setSearchValue(e.target.value);

  useEffect(() => {
    open && setSearchValue('');
  }, [open]);

  return (
    <Dialog
      maxWidth={'md'}
      classes={{ paper: classes.dialogPaper }}
      fullScreen={isMobile}
      scroll={'body'}
      open={open}
      onDragOver={(event) => event.preventDefault()}
    >
      <div className={classes.root} id={'KsarRootDiv'}>
        <div>
          <AppBar
            position="static"
            style={{ color: 'black', background: '#ffffff', boxShadow: 'none' }}
          >
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <div className="cloudSearchGrid">
                <img src={CloudSearchIcon} alt="Cloud Search" />
                <div className="cloudSearchTitle">Cloud Search</div>
              </div>
              <IconButton
                edge="end"
                color="inherit"
                style={{ marginRight: 20 }}
                onClick={toggleModal}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </AppBar>
        </div>

        <TabPanel>
          <SearchTextField
            placeholder="Search ..."
            id="search-cloud-input"
            value={searchValue}
            onChange={searchHandler}
          />
        </TabPanel>
        {children}
      </div>
    </Dialog>
  );
}
