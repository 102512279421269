import { makeStyles } from '@material-ui/core';

export const useJoinAsHostDialogStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    gap: '1rem',
  },
  footerContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    gap: '0.5rem',
  },
  setupAccountBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
}));
