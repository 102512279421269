import React from 'react';
import { ScheduleFilterDialog } from './component';

export const ScheduleFilterDialogContainer = (props) => {
  const { scheduleFilter, setTodaysScheduleFilter, setUpcomingScheduleFilter } = props;
  return (
    <ScheduleFilterDialog
      scheduleFilter={scheduleFilter}
      setTodaysScheduleFilter={setTodaysScheduleFilter}
      setUpcomingScheduleFilter={setUpcomingScheduleFilter}
    />
  );
};
