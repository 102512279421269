import jwt_decode from 'jwt-decode';

export function getToken() {
  return localStorage.getItem('usertoken');
}

export function setToken(token) {
  localStorage.setItem('usertoken', token);
}

export function deleteAll() {
  localStorage.clear();
}

export function setBoardInfo(boardinfo) {
  localStorage.setItem('boardinfo', JSON.stringify(boardinfo));
}

export function getBoardInfo() {
  return JSON.parse(localStorage.getItem('boardinfo'));
}

export function getFlowId() {
  const { flowId } = getBoardInfo();
  return flowId;
}

export function removeBoardInfo() {
  localStorage.removeItem('boardinfo');
}

/**
 * canvas code / class id
 * @returns
 */
export function getCanvasCode() {
  const { classId } = JSON.parse(localStorage.getItem('boardinfo'));
  return classId;
}

export function setWorkspaceId(workspaceID) {
  localStorage.setItem('workspaceid', workspaceID);
}

export function getWorkspaceId() {
  return localStorage.getItem('workspaceid');
}

export function setWorkspaceName(workspaceName) {
  localStorage.setItem('workspacename', workspaceName);
}

export function getWorkspaceName() {
  return localStorage.getItem('workspacename');
}

export function setWorkspaceImg(workspaceDp) {
  localStorage.setItem('workspacedp', workspaceDp);
}

export function getWorkspaceImg() {
  return localStorage.getItem('workspacedp');
}

export function setSpaceId(spaceId) {
  localStorage.setItem('spaceId', spaceId);
}

/**
 *
 * @param {*} key
 * @param {string[]} excludeList
 */
function checkShouldRemove(key, excludeList) {
  return !excludeList.some((text) => text && key.startsWith(text));
}

/**
 * Note:
 * Any key that startsWith any of the text in the excludeList array will not be removed
 *
 * @param {string[]} excludeList
 */
function clearLocalStorage(excludeList = []) {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    let shouldRemove = checkShouldRemove(key, excludeList);

    if (shouldRemove) {
      localStorage.removeItem(key);
    }
  }
}

export function clearUserData() {
  const excludeList = ['oauth'];
  clearLocalStorage(excludeList);
}

export function getSpaceId() {
  return localStorage.getItem('spaceId');
}

export function setSpaceName(spaceName) {
  localStorage.setItem('spaceName', spaceName);
}

export function getSpaceName() {
  return localStorage.getItem('spaceName');
}

export function setUserId(userID) {
  localStorage.setItem('userId', userID);
}

export function getUserId() {
  return localStorage.getItem('userId');
}

export function setUserName(userName) {
  localStorage.setItem('userName', userName);
}

export function getUserName() {
  return localStorage.getItem('userName');
}

export function setSortBy(sortBy) {
  localStorage.setItem('sortBy', sortBy);
}

export function getSortBy() {
  return localStorage.getItem('sortBy');
}

export function checkIsAuthUser() {
  const decoded = getAuthUserDecodedInfo();

  return decoded && decoded.authType === 'WEB_LOGIN';
}

export function getAuthUserTag() {
  const decoded = getAuthUserDecodedInfo();

  return decoded && decoded.tag;
}
/**@returns {{userId: string, tag: string}} */
export function getAuthUserDecodedInfo() {
  if (getToken()) {
    const usertoken = getToken();
    return jwt_decode(usertoken);
  } else {
    return;
  }
}

export function getAnonymousUserToken() {
  return localStorage.getItem('AnonymousUserToken');
}

export function setAnonymousUserToken(token) {
  localStorage.setItem('AnonymousUserToken', token);
}

export function getInstantMeetingLink() {
  return JSON.parse(localStorage.getItem('meetingLink'));
}

export function setInstantMeetingLink(link) {
  localStorage.setItem('meetingLink', JSON.stringify(link));
}

export function getRoomName() {
  return JSON.parse(localStorage.getItem('roomName'));
}

export function setRoomName(roomName) {
  localStorage.setItem('roomName', JSON.stringify(roomName));
}

export function getInstantMeetingName() {
  return JSON.parse(localStorage.getItem('meetingName'));
}

export function setInstantMeetingName(meetingName) {
  localStorage.setItem('meetingName', JSON.stringify(meetingName));
}

export function setBirdDeviceId(deviceId) {
  localStorage.setItem('birdDeviceId', deviceId);
}

export function getBirdDeviceId() {
  return localStorage.getItem('birdDeviceId');
}

export function setDeviceIds(deviceIds) {
  localStorage.setItem('deviceIds', JSON.stringify(deviceIds));
}

export function getDeviceIds() {
  return JSON.parse(localStorage.getItem('deviceIds'));
}

export function setIsScreenshareImproveExperienceSuggestionDisabled(value) {
  localStorage.setItem('screenshare.suggestions.improveExperience.disabled', value);
}
export function getIsScreenshareImproveExperienceSuggestionDisabled() {
  return localStorage.getItem('screenshare.suggestions.improveExperience.disabled');
}

const encryptedHardwareIdKey = 'oauth:encryptedHardwareId';

export function setEncryptedHardwareId(encryptedHardwareId) {
  localStorage.setItem(encryptedHardwareIdKey, encryptedHardwareId);
}

export function getEncryptedHardwareId() {
  return localStorage.getItem(encryptedHardwareIdKey);
}

export function clearEncryptedHardwareId() {
  localStorage.removeItem(encryptedHardwareIdKey);
}

export function popEncryptedHardwareId() {
  const encryptedHardwareId = getEncryptedHardwareId();
  clearEncryptedHardwareId();
  return encryptedHardwareId;
}
