import React from 'react';
import { InviteWorkspaceDialogContainer } from '../../../../components/invites/dialog/invite-workspace-dialog/container';
import { RemoteControlDialogContainer } from '../../../Controller/remote-control-dialog/container';

export const DialogGroup = (props) => {
  const { isJoinWorkspaceInviteDialogOpen, closeJoinWorkspaceInviteDialog } = props;

  return (
    <>
      <InviteWorkspaceDialogContainer
        open={isJoinWorkspaceInviteDialogOpen}
        onClose={closeJoinWorkspaceInviteDialog}
      />
      <RemoteControlDialogContainer />
    </>
  );
};
