import { makeStyles } from '@material-ui/core';
import { PRIMARY_MAIN_COLOR } from '../../../../theme/base-theme';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFF'
  },
  dialogTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '28px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  warningText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '28px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    paddingTop: "5px",
  },
  subText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16',
    lineHeight: '24px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '0 6rem',
    paddingBottom: '34px',
    margin: '0'
  },
  warningBtn: {
    textAlign: 'center',
    display: 'block !important',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  messageContainer: {
    borderRadius: '2.5rem',
    background: '#F1F1F1',
    padding: '0.5rem 5rem',
    display: 'flex',
    justifyContent: 'center',
    width: 'fit-content',
    margin: '0 auto',
    color: PRIMARY_MAIN_COLOR,
  },
  supportBtn: {
    textDecoration: 'none',
    color: 'var(--blue-1, #335AFB)',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
}));

export default useStyles;
