import { useEffect, useRef } from 'react';

export const useWakeLock = () => {
  let wakeLock = useRef(null);

  const isScreenLockSupported = () => {
    return 'wakeLock' in navigator;
  };

  const requestWakeLock = async () => {
    try {
      wakeLock.current = await navigator.wakeLock.request('screen');
      console.log('Screen wake lock is active');
    } catch (err) {
      console.error(`${err.name}, ${err.message}`);
    }
  };

  const handleVisibilityChange = () => {
    if (wakeLock.current !== null && document.visibilityState === 'visible') {
      requestWakeLock();
    }
  };

  useEffect(() => {
    // Keep the screen awake

    if (!isScreenLockSupported()) {
      console.warn('warn: Screen wake lock is not supported');
      return;
    }

    requestWakeLock();
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      if (wakeLock.current !== null) {
        wakeLock.current.release();
        wakeLock.current = null;
        console.log('Screen wake lock is released');
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
};
