import React from 'react';
import AppLogo from '../../assets/img/teamhubLogo.svg';

const ProductLogoFull = ({ size }) => {
  /**
   * possible values
   * xs | sm | md | lg | xl
   */

  let height;
  if (size === 'sm') {
    height = 22.5;
  }
  if (size === 'md') {
    height = 38;
  }
  return <img src={AppLogo} alt="Logo" height={height} />;
};

export default ProductLogoFull;
