import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStylesSearch = makeStyles(() => ({
  input: {
    color: 'black',
    background: 'white',
    borderRadius: 4,
  },
}));

export function SearchTextField(props) {
  const classes = useStylesSearch();

  return (
    <TextField
      InputProps={{
        classes,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      {...props}
      fullWidth
    />
  );
}
