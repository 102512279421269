import {
  Button,
  ClickAwayListener,
  Grid,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
} from '@material-ui/core';
import React, { Component } from 'react';
import Steps from './Steps';
import BrandLogo from '../../../../assets/img/birdAiLogowithName.svg';
import InstitueDefaultIcon from '../../../../assets/svg/institute.svg';
import goBackLeftArrow from '../../../../assets/svg/goBackLeftArrow.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RupeeIcon from '../../../../assets/icons/RupeeIcon';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import StyledAutocompleteContainer from './StyledAutocomplete/container';
import classes from './PlanUpgrade.module.css';
import './Upgrade.css';
import { baseService } from '../../../../services/base.service';
import moment from 'moment';
import { PAYMENT } from '../../../../Utils/url-builder';
import rozarpaylogo from '../../../../assets/img/razorpayLogo.svg';

export class Upgrade extends Component {
  constructor() {
    super();
    this.state = {
      instituteLogourl: '',
      line1: '',
      line1Error: '',
    };
  }

  addressUpdated() {
    if (
      this.state.country &&
      this.state.line1 &&
      this.state.city &&
      this.state.state &&
      this.state.zip
    )
      this.setState({ disablePayment: false });
    else this.setState({ disablePayment: true });
  }

  getPaymentDetails = async (cb) => {
    try {
      this.getPaymentDetailsValidation();
    } catch (error) {
      console.log(error);
      return;
    }
    try {
      const payload = this.generateCreatePaymentPayload();
      const transaction = await baseService.makePostRequest(PAYMENT.createPayment(), payload);
      this.setState({ transaction });
      cb();
    } catch (error) {
      console.log(error);
      cb();
    }
  };

  getPaymentDetailsValidation = () => {
    console.log('getPaymentDetailsValidation');
    /**
     * if current plan is FREE_FOREVER_PLAN, customer is purchasing for the 1st time
     */
    if (this.isCurrentLicenseTypeFree()) {
      return true;
    }

    /**
     * downgrading plan is not allowed
     */
    this.downgradingValidation();

    /**
     * upgrading plan, the period of selected plan should be greater than or equal to the current license
     */
    let currentLicenseExpiry = this.props.licenseDetails.expiresAt;
    // substract 1 day due to UTC and timezone mismatch. refer: eg. A
    let currentLicenseExpiryMonth = moment(currentLicenseExpiry)
      .subtract(1, 'days')
      .startOf('month');

    let todayOrEffectiveDate = this.getTodayOrEffectiveDate();
    const timeValues = this.getUniqueMonths(todayOrEffectiveDate, currentLicenseExpiryMonth);

    console.log('timeValues', timeValues);
    if (!this.isSamePlan() && this.getSelectedPeriod() < timeValues.length) {
      const message = 'Selected period should be greater than the period of current license plan';
      throw new Error(message);
    }
  };

  isCurrentLicenseTypeFree = () => {
    // return this.props.licenseDetails.licenseType === 'FREE';
    return true;
  };

  step1 = (next) => {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 20px',
          }}
        >
          <div>
            <img src={BrandLogo} alt="" />
          </div>

          <div className="centerImage" style={{ display: 'flex', alignItems: 'center' }}>
            {this.state.instituteLogourl ? (
              <div>
                <img
                  style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50px',
                  }}
                  src={this.state.instituteLogourl}
                  alt=""
                />
              </div>
            ) : (
              <div>
                <img
                  style={{
                    width: '30px',
                    height: '30px',
                    // borderRadius: '4px',
                  }}
                  src={InstitueDefaultIcon}
                  alt=""
                />
              </div>
            )}
            {this.state.instituteName ? (
              <div style={{ paddingLeft: '5px' }}>{this.state.instituteName}</div>
            ) : (
              <div style={{ paddingLeft: '5px' }}>Institute Name</div>
            )}
          </div>

          <div
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={this.gotoplanBillingPage}
          >
            <img src={goBackLeftArrow} alt="left arrow" />

            <div style={{ display: 'inline', color: '#335AFB', marginLeft: '6px' }}> Back</div>
          </div>
        </div>

        {/* <Grid container>
          <Grid item xs={2}></Grid>
          <Grid item xs={7}> */}
        {/* <span style={{ fontSize: "12px", color: "grey",background:"red" }}>
              pricing plan
            </span> */}

        <br />
        <div className="abc" style={{ height: '80vh' }}>
          <div
            style={{
              width: '400px',
              margin: 'auto',
              maxWidth: 'calc(100% - 40px)',
            }}
          >
            <div>
              <div>
                {/* ///// */}
                <Button
                  style={{
                    boxShadow: '0px 3.06px rgba(0, 0, 0, 0.04)',
                    width: '400px',
                    maxWidth: '-webkit-fill-available',

                    height: 51,
                    // marginLeft: 5,
                    // background: "orange",
                    textAlign: 'right',
                    borderRadius: '2px',
                    marginBottom: 5,
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                  variant="outlined"
                  endIcon={
                    // <ArrowDropDownIcon />
                    <ExpandMoreIcon
                      style={{
                        width: '1em',
                        height: '0.9em',
                        fontSize: '1rem',
                      }}
                    />
                  }
                  onClick={this.openPlanMenu}
                >
                  111
                  {/* {this.state.selectedPlanName ||
                    this.state.license.planName ||
                    `${this.props.locaion ? this.props.location.state.plan : ''}`} */}
                </Button>

                <div>
                  <Button
                    style={{
                      boxShadow: '0px 3.06px rgba(0, 0, 0, 0.04)',
                      width: '400px',
                      maxWidth: '-webkit-fill-available',
                      height: 51,
                      // marginLeft: 5,
                      // background: "orange",
                      textAlign: 'right',
                      borderRadius: '2px',
                      marginBottom: 5,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                    variant="outlined"
                    endIcon={
                      // <ArrowDropDownIcon />
                      <ExpandMoreIcon
                        style={{
                          width: '1em',
                          height: '0.9em',
                          fontSize: '1rem',
                        }}
                      />
                    }
                    onClick={this.openPlanTypeMenu}
                  >
                    {this.state.selectedTypeName || 'Select Plan'}
                  </Button>
                </div>

                <div style={{ padding: '10px 0 ', display: 'none' }}>Discount Coupoun</div>

                <div
                  style={{
                    display: 'none',
                    justifyContent: 'space-between',
                    height: 34,
                  }}
                >
                  <input
                    // className={classescss.inputField}
                    style={{
                      background: 'rgba(107, 119, 154, 0.05)',
                      borderRadius: '2px',
                      width: '100%',
                      border: 'none',
                    }}
                    placeholder="   Add discount coupon"
                  />
                  <Button
                    style={{
                      width: '40%',
                      background: '#ec672f',
                      color: 'white',
                      borderRadius: 0,
                      marginLeft: '4px',
                    }}
                    // className={classescss.inputButton}
                  >
                    Apply Coupon
                  </Button>
                </div>

                <div
                  // className={classescss.sideTitle}
                  style={{
                    borderBottom: '1px solid rgba(0, 0, 0, 0.24)',
                    fontSize: '14px',
                    fontWeight: '700',
                    padding: '10px 0 ',
                  }}
                >
                  Order Summary
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px 0 5px',
                  }}
                >
                  <div style={{ fontSize: '14px' }}>Plan Pricing</div>
                  <div>
                    <span
                      style={{
                        fontFamily: 'lato,ubuntu,helvetica,sans-serif',
                        WebkitFontSmoothing: 'auto',
                      }}
                    >
                      &#8377;
                    </span>
                    {/* {this.state.unitCost.toLocaleString()} {'/ month'} */}
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '5px 0 ',
                  }}
                >
                  <div style={{ fontSize: '14px' }}>
                    Sub Total (
                    {this.state.selectedTypeName == 'Annual Plan'
                      ? '12 Months'
                      : `${this.state.selectedTypeName}` || 'Select Plan'}
                    )
                  </div>
                  <div>
                    <RupeeIcon />
                    {this.state.totalCost >= 0
                      ? parseFloat(this.state.totalCost.toFixed(2)).toLocaleString()
                      : 0}
                  </div>
                </div>

                <div
                  style={{
                    display: 'none',
                    justifyContent: 'space-between',
                    padding: '5px 0 ',
                  }}
                >
                  <div style={{ fontSize: '14px' }}>Discount (Coupon)</div>
                  <div>
                    <RupeeIcon />
                    {/* {parseFloat(this.state.discount.toFixed(2)) >= 0
                      ? parseFloat(this.state.discount.toFixed(2)).toLocaleString()
                      : 0} */}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '5px 0 ',
                  }}
                >
                  <div style={{ fontSize: '14px' }}>GST (18%)</div>
                  <div>
                    <RupeeIcon />
                    {/* {parseFloat(this.state.GSTAmount.toFixed(2)) >= 0
                      ? parseFloat(this.state.GSTAmount.toFixed(2)).toLocaleString()
                      : 0} */}
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderTop: '1px solid rgba(0,0,0,0.12)',
                  }}
                >
                  <div
                    style={{
                      fontSize: '16px',
                      fontWeight: '600',
                      padding: '10px 0 ',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Total
                    <Tooltip
                      title={
                        <div style={{ width: '152px' }}>
                          Your total amount is calculated on prorata basis. You are charged only for
                          the period you have used BrightClass service
                        </div>
                      }
                    >
                      <InfoOutlinedIcon style={{ fontSize: '14px' }} />
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      color: '#335AFB',
                      fontSize: '24px',
                      fontWeight: '700',
                    }}
                  >
                    <RupeeIcon />
                    {parseFloat(this.state.roundedFinalCost) >= 0
                      ? parseFloat(this.state.roundedFinalCost).toLocaleString()
                      : 0}
                    {/* {parseFloat(this.state.roundedFinalCost.toFixed(2)).toLocaleString()} */}
                  </div>
                </div>
                <ClickAwayListener onClickAway={this.handleClickAway}>
                  <button
                    onClick={this.buttonClick}
                    style={{
                      display: 'none',
                      border: '0',
                      borderColor: 'transparent',
                      background: 'none',
                      float: 'right',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'initial',
                        color: '#696f73',
                      }}
                    >
                      {' '}
                      <InfoOutlinedIcon style={{ fontSize: '14px', marginBottom: ' 5px' }} />
                      incl GST
                    </div>
                    {this.state.tooltipOpen ? (
                      <div>
                        222
                        {/* <CostBreakup
                          unitCost={this.state.unitCost}
                          studentCount={this.state.studentCount}
                          selectedTypeValue={this.getSelectedPeriod()}
                          payNow={this.state.payNow}
                          roundedFinalCost={this.state.roundedFinalCost}
                          costOfCurrentMonth={this.state.costOfCurrentMonth}
                          costOfOtherMonths={this.state.costOfOtherMonths}
                          GSTAmount={this.state.GSTAmount}
                          license={this.state.license}
                          currentLicenseUnusedProratedCharges={
                            this.state.currentLicenseUnusedProratedCharges
                          }
                          proratedPeriod={this.state.proratedPeriod}
                          absolutePeriod={this.state.absolutePeriod}
                          totalPeriod={this.state.totalPeriod}
                          shouldComputeProratedCharges={this.shouldComputeProratedCharges}
                        /> */}
                      </div>
                    ) : (
                      ''
                    )}
                  </button>
                </ClickAwayListener>
                {/* <div
                style={{
                  float: "right",
                  display: "inline-flex",
                  fontSize: "12px",
                  opacity: "0.3",
                  
                }}
              >
                <Tooltip
                  title={
                    
                    <CostBreakup
                      unitCost={this.state.unitCost}
                      studentCount={this.state.studentCount}
                      selectedTypeValue={this.getSelectedPeriod()}
                      payNow={this.state.payNow}
                      roundedFinalCost={this.state.roundedFinalCost}
                      costOfCurrentMonth={this.state.costOfCurrentMonth}
                      costOfOtherMonths={this.state.costOfOtherMonths}
                      GSTAmount={this.state.GSTAmount}
                      license={this.state.license}
                      currentLicenseUnusedProratedCharges={
                        this.state.currentLicenseUnusedProratedCharges
                      }
                      proratedPeriod={this.state.proratedPeriod}
                      absolutePeriod={this.state.absolutePeriod}
                      totalPeriod={this.state.totalPeriod}
                      shouldComputeProratedCharges={this.shouldComputeProratedCharges}
                    />
                  }
                  aria-label="pay now"
                  style={{ verticalAlign: "middle", marginLeft: "0.5%" }}
                >
                  <InfoOutlinedIcon
                    style={{ fontSize: "14px", marginBottom: " 5px" }}
                  />
                </Tooltip>
                <div>incl GST</div>
              </div> */}

                {/* <div className="link-text">
              <input
                defaultChecked={this.state.acceptAgreement}
                type="checkbox"
                onChange={this.accept}
              />{" "}
              <span className={classes.payText}>I have read and agree to</span>
              <span
                className={classes.payText}
                style={{ color: "#1874e4", cursor: "pointer", marginLeft: 5 }}
                onClick={this.readClick}
              >
                terms and conditions.
              </span>{" "}
            </div> */}

                <Grid container>
                  <Grid item xs={12}>
                    <Button
                      className="ContinueButton"
                      variant="contained"
                      style={{
                        backgroundColor: '#1BC47D',
                        color: 'white',

                        // marginLeft: "60%",
                        marginTop: '5%',
                        width: '400px',
                        maxWidth: '-webkit-fill-available',
                        borderRadius: '6px',
                        textAlign: 'right',
                      }}
                      // disabled={this.state.roundedFinalCost <= 0 ? true : false}
                      onClick={
                        () => next()
                        // try {
                        //   this.getPaymentDetailsValidation();
                        // } catch (error) {
                        //   console.error(error);
                        //   this.props.setSnackbar({ message: error.message });
                        //   return;
                        // }
                        // if (this.state.roundedFinalCost <= 0) {
                        //   App.showError('Please upgrade plan.');
                        // } else {
                        //   App.hideError();
                        //   next();
                        // }
                      }
                    >
                      Continue
                    </Button>
                  </Grid>
                </Grid>
                {/* ///// */}
              </div>
            </div>
          </div>
        </div>
        {/* </Grid> */}

        {/* /////// */}
        <Menu
          id="simple-menu"
          anchorEl={this.state.planAnchorEl}
          keepMounted
          open={Boolean(this.state.planAnchorEl)}
          onClose={this.handleClosePlanMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          getContentAnchorEl={null}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem
            selected={this.state.selectedPlanValue === 'FREE_PLAN'}
            onClick={(e) => this.handleClosePlanMenu(e, 'FREE_PLAN', 'Free Forever')}
            // disabled={!this.state.upgradablePlansList.includes('FREE_PLAN')}
          >
            Free Forever
          </MenuItem>
          <MenuItem
            selected={this.state.selectedPlanValue === 'PROFESSIONAL_PLAN'}
            onClick={(e) => this.handleClosePlanMenu(e, 'PROFESSIONAL_PLAN', 'Professional')}
            // disabled={!this.state.upgradablePlansList.includes('PROFESSIONAL_PLAN')}
          >
            Professional
          </MenuItem>
          <MenuItem
            selected={this.state.selectedPlanValue === 'ADVANCED_PLAN'}
            onClick={(e) => this.handleClosePlanMenu(e, 'ADVANCED_PLAN', 'Advanced')}
            // disabled={!this.state.upgradablePlansList.includes('ADVANCED_PLAN')}
          >
            Advanced
          </MenuItem>
          {/* <MenuItem
            selected={this.state.selectedPlanValue === "INSTITUTE_PLAN"}
            onClick={(e) =>
              this.handleClosePlanMenu(e, "INSTITUTE_PLAN", "Institute")
            }
            disabled={
              !this.state.upgradablePlansList.includes("INSTITUTE_PLAN")
            }
          >
            Institute
          </MenuItem> */}
        </Menu>
        <Menu
          id="simple-menu"
          anchorEl={this.state.planTypeAnchorEl}
          keepMounted
          open={Boolean(this.state.planTypeAnchorEl)}
          onClose={this.handleClosePlanTypeMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          getContentAnchorEl={null}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem selected={true} disabled={true}>
            Select Duration
          </MenuItem>
          <MenuItem
            // selected={this.getSelectedPeriod() === 1}
            onClick={(e) => this.handleClosePlanTypeMenu(e, 1, '1 Month')}
          >
            1 Month
          </MenuItem>
          <MenuItem
            // selected={this.getSelectedPeriod() === 3}
            onClick={(e) => this.handleClosePlanTypeMenu(e, 3, '3 Months')}
          >
            3 Months
          </MenuItem>
          <MenuItem
            // selected={this.getSelectedPeriod() === 6}
            onClick={(e) => this.handleClosePlanTypeMenu(e, 6, '6 Months')}
          >
            6 Months
          </MenuItem>
          <MenuItem
            // selected={this.getSelectedPeriod() === 9}
            onClick={(e) => this.handleClosePlanTypeMenu(e, 9, '9 Months')}
          >
            9 Months
          </MenuItem>
          <MenuItem
            // selected={this.getSelectedPeriod() === 12}
            onClick={(e) => this.handleClosePlanTypeMenu(e, 12, 'Annual Plan')}
          >
            Annual Plan
          </MenuItem>
        </Menu>
      </div>
    );
  };
  //step2

  step2 = (next, back) => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 20px',
          }}
        >
          <div>
            <img src={BrandLogo} alt="" />
          </div>

          <div className="centerImage" style={{ display: 'flex', alignItems: 'center' }}>
            {this.state.instituteLogourl ? (
              <div>
                <img
                  style={{
                    width: '30px',
                    height: '30px',
                    // borderRadius: '50px',
                  }}
                  src={this.state.instituteLogourl}
                  alt=""
                />
              </div>
            ) : (
              <div>
                <img
                  style={{
                    width: '30px',
                    height: '30px',
                    // borderRadius: '50px',
                  }}
                  src={InstitueDefaultIcon}
                  alt=""
                />
              </div>
            )}
            {this.state.instituteName ? (
              <div style={{ paddingLeft: '5px' }}>{this.state.instituteName}</div>
            ) : (
              <div style={{ paddingLeft: '5px' }}> The LNMIIT</div>
            )}
          </div>

          <div
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={this.gotoplanBillingPage}
          >
            <img src={goBackLeftArrow} alt="left arrow" />
            <div style={{ display: 'inline', color: '#335AFB', marginLeft: '6px' }}> Back</div>
          </div>
        </div>

        <div style={{ height: '80vh' }}>
          <div
            style={{
              margin: '19px auto',
              width: '500px',
              maxWidth: 'calc(100% - 40px)',
            }}
          >
            {this.state.currentPayment ? (
              <>
                <Grid container style={{ marginTop: '1%', marginBottom: '2%' }}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={5} style={{ fontSize: '16px' }}>
                    Total
                    <Tooltip
                      title={
                        <div style={{ width: '152px' }}>
                          Your total amount is calculated on prorata basis. You are charged only for
                          the period you have used BrightClass service
                        </div>
                      }
                    >
                      <InfoOutlinedIcon style={{ fontSize: '14px' }} />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: 'right', fontSize: '24px' }}>
                    &nbsp;{' '}
                    <span
                      style={{
                        fontFamily: 'lato,ubuntu,helvetica,sans-serif',
                        WebkitFontSmoothing: 'auto',
                      }}
                    >
                      &#8377;
                    </span>
                    {parseFloat(this.state.roundedFinalCost).toLocaleString()}
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={8}></Grid>
                  <Grid item xs={2}>
                    <ClickAwayListener onClickAway={this.handleClickAway}>
                      <button
                        onClick={this.buttonClickTwo}
                        style={{
                          display: 'none',
                          border: 0,
                          borderColor: 'transparent',
                          background: 'none',
                          float: 'right',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'initial',
                            color: '#696f73',
                          }}
                        >
                          {' '}
                          <InfoOutlinedIcon style={{ fontSize: '14px' }} />
                          incl GST
                        </div>
                        {this.state.tooltiptopOpen
                          ? 'a'
                          : // <div>
                            //   <CostBreakup
                            //     unitCost={this.state.unitCost}
                            //     studentCount={this.state.studentCount}
                            //     selectedTypeValue={this.getSelectedPeriod()}
                            //     payNow={this.state.payNow}
                            //     roundedFinalCost={this.state.roundedFinalCost}
                            //     costOfCurrentMonth={this.state.costOfCurrentMonth}
                            //     costOfOtherMonths={this.state.costOfOtherMonths}
                            //     GSTAmount={this.state.GSTAmount}
                            //     license={this.state.license}
                            //     currentLicenseUnusedProratedCharges={
                            //       this.state.currentLicenseUnusedProratedCharges
                            //     }
                            //   />
                            // </div>
                            ''}
                      </button>
                    </ClickAwayListener>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              </>
            ) : (
              ''
            )}

            <Grid container>
              <Grid item xs={1}></Grid>
              <Grid item xs={9}>
                <div
                  style={{
                    borderBottom: '1px solid rgba(0,0,0,0.12',
                    marginBottom: '10px',
                    paddingBottom: '10px',
                    fontSize: '14px',
                  }}
                >
                  Billing Address
                </div>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  error={!!this.state.line1Error}
                  onBlur={(e) => {
                    if (!this.state.line1) this.setState({ line1Error: true });
                    else this.setState({ line1Error: false });
                  }}
                  helperText={this.state.line1Error ? 'Required' : undefined}
                  type="text"
                  variant="outlined"
                  style={{ width: '75.4%', margin: '1% 0% 0% 8.5%' }}
                  size="small"
                  value={this.state.line1}
                  onChange={(e) => {
                    this.setState({ line1: e.target.value }, () => {
                      if (!this.state.line1) this.setState({ line1Error: true });
                      else this.setState({ line1Error: false });
                      this.addressUpdated();
                    });
                  }}
                  placeholder="Address Line 1"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  type="text"
                  variant="outlined"
                  style={{ width: '75.4%', margin: '1% 0% 0% 8.5%' }}
                  size="small"
                  value={this.state.line2}
                  onChange={(e) => {
                    this.setState({ line2: e.target.value }, () => this.addressUpdated());
                  }}
                  placeholder="Address Line 2 (Optional)"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  error={!!this.state.cityError}
                  onBlur={(e) => {
                    if (!this.state.city) this.setState({ cityError: true });
                    else this.setState({ cityError: false });
                  }}
                  helperText={this.state.cityError ? 'Required' : undefined}
                  type="text"
                  variant="outlined"
                  // style={{ width: "75.4%", margin: "2% 0% 0% 17%" }}
                  style={{
                    width: '75.4%',
                    // margin: "4% 0% 0% -13.75%"
                    margin: '4% 0% 0% 34.25%',
                  }}
                  size="small"
                  value={this.state.city}
                  onChange={(e) => {
                    this.setState({ city: e.target.value }, () => {
                      if (!this.state.city) this.setState({ cityError: true });
                      else this.setState({ cityError: false });
                      this.addressUpdated();
                    });
                  }}
                  placeholder="City"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={!!this.state.stateError}
                  onBlur={(e) => {
                    if (!this.state.state) this.setState({ stateError: true });
                    else this.setState({ stateError: false });
                  }}
                  helperText={this.state.stateError ? 'Required' : undefined}
                  type="text"
                  variant="outlined"
                  // style={{ width: "75.4%", margin: "4% 0% 0% -13.75%" }}
                  style={{
                    width: '75.4%',
                    // margin: "2% 0% 0% 17%"
                    margin: '2% 0% 0% 5.5%',
                  }}
                  size="small"
                  value={this.state.state}
                  onChange={(e) => {
                    this.setState({ state: e.target.value }, () => {
                      if (!this.state.state) this.setState({ stateError: true });
                      else this.setState({ stateError: false });
                      this.addressUpdated();
                    });
                  }}
                  placeholder="State"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  error={!!this.state.zipError}
                  onBlur={(e) => {
                    if (!this.state.zip) this.setState({ zipError: true });
                    else this.setState({ zipError: false });
                  }}
                  helperText={this.state.zipError ? 'Required' : undefined}
                  type="tel"
                  variant="outlined"
                  style={{
                    width: '73%',
                    margin: '4% 0% 0% -37%',
                  }}
                  size="small"
                  value={this.state.zip}
                  onChange={(e) => {
                    if (!isNaN(Number(e.target.value)))
                      this.setState({ zip: e.target.value }, () => {
                        if (!this.state.zip) this.setState({ zipError: true });
                        else this.setState({ zipError: false });
                        this.addressUpdated();
                      });
                  }}
                  placeholder="Zip Code"
                />
              </Grid>

              <Grid item xs={12}>
                <StyledAutocompleteContainer
                  onChangeCountry={(event, value) => {
                    this.setState({ country: value && value.name ? value.name : '' }, () => {
                      if (!this.state.country) this.setState({ countryError: true });
                      else this.setState({ countryError: false });
                      this.addressUpdated();
                    });
                  }}
                  countryError={this.state.countryError}
                  containerStyle={{ width: '75.4%', marginTop: '0.25rem', marginLeft: '2.625rem' }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  type="text"
                  variant="outlined"
                  style={{ width: '75.4%', margin: '1% 0% 0% 8.5%' }}
                  size="small"
                  value={this.state.gstin}
                  onChange={(e) => {
                    this.setState({ gstin: e.target.value }, () => this.addressUpdated());
                  }}
                  placeholder="GSTIN (Optional)"
                />
              </Grid>
            </Grid>
            <div>&nbsp;</div>
            <Grid container>
              <Grid item xs={1}></Grid>
              <Grid item xs={9}>
                <div style={{ display: 'flex' }}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: 'white',
                      color: '#335AFB',
                      // marginLeft: "17%",
                      marginTop: '2%',
                      borderRadius: '6px',
                      border: '1px solid rgba(34, 34, 34, 0.75)',
                      /* margin-left: 17%; */
                    }}
                    onClick={back}
                  >
                    Back
                  </Button>

                  <Button
                    className={this.state.disablePayment ? 'disableBtn' : 'activeBtn'}
                    variant="contained"
                    style={{
                      backgroundColor: '#1BC47D',
                      color: 'white',
                      marginLeft: '2%',
                      marginTop: '2%',
                      width: '100%',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                    disabled={this.state.disablePayment}
                    onClick={() => this.getPaymentDetails(next)}
                    // disabled={!this.state.acceptAgreement}

                    // onClick={this.initiatePayment}
                  >
                    Confirm & Pay
                  </Button>
                </div>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </div>
        </div>
      </>
    );
  };

  //step4 start

  step4 = (next, back) => {
    return (
      <>
        {!this.props.isWebview && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 20px',
            }}
          >
            <div>
              <img src={BrandLogo} alt="" />
            </div>

            <div className="centerImage" style={{ display: 'flex', alignItems: 'center' }}>
              {this.state.instituteLogourl ? (
                <div>
                  <img
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50px',
                    }}
                    src={this.state.instituteLogourl}
                    alt=""
                  />
                </div>
              ) : (
                <div>
                  <img
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50px',
                    }}
                    src={InstitueDefaultIcon}
                    alt=""
                  />
                </div>
              )}
              {this.state.instituteName ? (
                <div style={{ paddingLeft: '5px' }}>{this.state.instituteName}</div>
              ) : (
                <div style={{ paddingLeft: '5px' }}> My Institute</div>
              )}
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={this.gotoplanBillingPage}
            >
              <img src={goBackLeftArrow} alt="left arrow" />
              <div
                style={{
                  display: 'inline',
                  color: '#335AFB',
                  marginLeft: '6px',
                }}
              >
                {' '}
                Back
              </div>
            </div>
          </div>
        )}

        <div className="Step4container">
          <div className="totalpaymenttext">
            <div> Total Payable</div>
            <div>
              <span
                style={{
                  fontFamily: 'lato,ubuntu,helvetica,sans-serif',
                  WebkitFontSmoothing: 'auto',
                }}
              >
                &#8377;
              </span>
              {parseFloat(this.state.roundedFinalCost).toLocaleString()}
            </div>
          </div>

          <div>
            <hr />
          </div>

          <div>
            <Paper className={classes.rozarpaygrid} elevation={0}>
              <img src={rozarpaylogo} alt="Rozarpay Logo" />
              <div className={classes.payText}>
                Credit & Debit cards, Wallets, Netbanking or UPI through Razorpay
              </div>
              <div className={classes.payDivider}> </div>
              <div className={classes.payText} style={{ alignSelf: 'center' }}>
                Your personal data will be used to process your order, support your experience and
                other purpose described in our privacy policies.
              </div>
              <div className="checkbox-text">
                <input
                  defaultChecked={this.state.acceptAgreement}
                  type="checkbox"
                  style={{ marginTop: '4px', marginLeft: '0px' }}
                  onChange={this.accept}
                />{' '}
                <div>
                  <span className={classes.payText}>I have read and agree to</span>
                  <span
                    className={classes.payText}
                    style={{
                      color: '#335AFB',
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                    onClick={this.readClick}
                  >
                    terms and conditions.
                  </span>
                </div>
              </div>
            </Paper>
          </div>

          <div className="foonterbutton">
            <Button
              variant="contained"
              style={{
                backgroundColor: 'white',
                color: '#335AFB',
                // marginLeft: "17%",
                // marginTop: "5%",
                border: '1px solid rgba(34, 34, 34, 0.75)',
                borderRadius: '6px',
              }}
              onClick={back}
            >
              Back
            </Button>

            <div className={this.state.acceptAgreement ? 'enableButton' : 'disableButton'}>
              <Button
                className={this.state.acceptAgreement ? 'enableButton' : 'disableButton'}
                variant="contained"
                style={{
                  backgroundColor: '#1BC47D',
                  color: 'white',
                  // marginLeft: "10%",
                  // marginTop: "5%",
                  borderRadius: '6px',
                }}
                // disabled={this.state.paymentInitiated}
                disabled={!this.state.acceptAgreement}
                onClick={this.initiatePayment}
              >
                Continue to payment
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  };

  //step4 end

  gotoplanBillingPage = () => {
    this.props.history.push('/manage/license-billing');
  };

  render() {
    const steps = [
      {
        name: 'Review Institute',
        component: this.step1,
      },
      {
        name: 'Review Payment',
        component: this.step2,
      },
      // {
      //   name: 'Review Address',
      //   component: this.step3,
      // },
      {
        name: 'Confirm & Pay',
        component: this.step4,
      },
    ];

    return (
      <>
        <div>
          <Steps steps={steps} />
        </div>
      </>
    );
  }
}

export default Upgrade;
