import { CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  centerContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const StyledSpinner = (props) => {
  const { center, size } = props;
  const classes = useStyles();

  const displayCircularProgress = <CircularProgress size={size} />;

  const displayCenterCircularProgress = (
    <div className={classes.centerContainer}>{displayCircularProgress}</div>
  );

  if (center) {
    return displayCenterCircularProgress;
  }

  return displayCircularProgress;
};
