import React from 'react';
import { makeStyles } from '@material-ui/core';

export const useStyles = ({ background, mini, micro }) =>
  makeStyles({
    agentAvatar: {
      position: 'relative',
      width: micro ? 16 : mini ? 28 : 36,
      height: micro ? 16 : mini ? 28 : 36,
      minWidth: micro ? 16 : mini ? 28 : 36,
      minHeight: micro ? 16 : mini ? 28 : 36,
      borderRadius: micro ? 4 : 8,
      background,
    },
  });

export const AgentAvatar = ({ title, background, icon, mini, micro }) => {
  const classes = useStyles({ background, mini, micro })();

  return (
    <div className={classes.agentAvatar}>
      <img
        src={icon}
        alt={title}
        width={micro ? 16 : mini ? 28 : 36}
        height={micro ? 16 : mini ? 28 : 36}
      />
    </div>
  );
};

export default AgentAvatar;
