import React from 'react';
import { Button } from '@material-ui/core';
import { usePairBoardButtonStyles } from './component.style';
import { bindTrigger } from 'material-ui-popup-state';

export const PairBoard = (props) => {
  const classes = usePairBoardButtonStyles();
  const {
    icon,
    label,
    color,
    backgroundColor,
    height,
    boxShadow,
    border,
    fontSize,
    deviceCodeDialogPopupState,
    isPaired,
    onClick,
    isMobile,
  } = props;

  const pairingButton = (
    <Button
      disableRipple
      className={classes.huddleButton}
      variant="contained"
      size={isMobile ? 'small' : 'medium'}
      startIcon={icon}
      style={{ backgroundColor, color, height, boxShadow, border, fontSize }}
      {...bindTrigger(deviceCodeDialogPopupState)}
    >
      {label}
    </Button>
  );

  const unpairButton = (
    <Button
      disableRipple
      className={classes.unpairHuddleButton}
      variant="contained"
      size={isMobile ? 'small' : 'medium'}
      startIcon={icon}
      style={{ backgroundColor, color, height, boxShadow, border, fontSize }}
      onClick={onClick}
    >
      {label}
    </Button>
  );

  const button = isPaired ? unpairButton : pairingButton;
  return <>{button}</>;
};
