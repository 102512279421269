import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Box, Button, makeStyles, Popover, Typography, useMediaQuery } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SidebarWorkspaceGroupContainer } from './sidebar-workspace-group/container';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import workspaceService from '../../services/workspaces.services';
import { getWorkspaceImg, getWorkspaceName, setUserId } from '../../Utils/authentication-access';
import userService from '../../services/user.service';
import instituteDefaultIconWhiteLayout from '../../assets/svg/instituteDefaultIconWhiteLayout.svg';
import instituteDefaultIconBlackLayout from '../../assets/svg/instituteDefaultIconBlackLayout.svg';

import { useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
  firstLineContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  secondLineContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  mySpacesParentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mySpaceContainer: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  newSpacesParentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  newSpaceButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    border: '0.5px solid rgba(0, 0, 0, 0.4)',
    borderRadius: '2px',
    color: 'rgba(0,0,0,0.6)',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    padding: '5px 10px',
  },
  workspaceOptions: {
    cursor: 'pointer',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.15968px',
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'block',
  },
  workspaceOptionsDisable: {
    cursor: 'not-allowed',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.15968px',
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'block',
    opacity: '0.4',
  },
  popupStateBtn: {
    width: '100%',
    background: 'transparent',
    color: 'black',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover, &:focus': {
      boxShadow: 'none',
    },
  },

  popupLuncherStateBtn: {
    width: '100%',
    background: 'transparent',
    color: 'white',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover, &:focus': {
      boxShadow: 'none',
      backgroundColor: '#d8d7d726',
    },
  },
  planDetails: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    padding: '7px',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    color: '#EC672F',
    background: 'rgba(223, 118, 55, 0.25)',
    borderRadius: '4px',
    marginBottom: '9px',
  },
  brandLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '55px',
    background: ' #E0E0E0',
    cursor: 'pointer',
  },
  workspaceIconsContainer: {
    paddingTop: '8px',
    maxHeight: '75%',
    overflow: 'auto',
  },
  sidebarIconsContainer: {
    width: '59px',
    height: '100vh',
    overflow: 'auto',
    scrollbarWidth: 'none',
    borderRight: '1px solid  #E0E0E0',
  },
  subContainerTwo: {
    position: 'absolute',
    bottom: '70px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginLeft: '14px',
  },
  sidebarMainContainer: {
    display: 'flex',
    overflow: 'hidden',
  },
  supportMenu: {
    cursor: 'pointer',
    marginBottom: '14px',
  },
  logoMainContainer: {
    background: '#F1F1F1',
    zIndex: '9999',
    cursor: 'pointer',
  },
  logoInnerContainer: {
    margin: '12px auto',
    textAlign: 'center',
  },
  pageTitleWhite: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14.06px',
    textAlign: 'left',
  },

  pageTitleBlack: {
    color: '#000',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14.06px',
    textAlign: 'left',
  },
  screenSwitcherParentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'

  },
  switcherNameContainer: {
    display: 'flex',
    alignItems: "center"
  }
}));

const ScreenSwitcher = ({ pageTitle }) => {
  const classes = useStyles();
  const location = useLocation();
  const [isAdmin, setIsAdmin] = useState(false);
  const [activeWorkspaceName, setActiveWorkspaceName] = useState('');
  const [activeWorkspaceDp, setActiveWorkspaceDp] = useState('');
  const history = useHistory();
  const isLauncherScreen = window.location.href.includes('launcher');
  const isSmartNotesPage = location.pathname.startsWith('/controller/smart-notes');
  const isLearnAIPage = location.pathname.startsWith('/controller/learn-ai');
  const isMobileView = useMediaQuery('(max-width:600px)');

  const checkIsAdmin = async (userId) => {
    const response = await workspaceService.allUsersList();
    const data = response.data.data.users;

    const isAdministrator = data.some(
      (user) => user.userId === userId && user.roles.includes('ADMIN'),
    );
    setIsAdmin(isAdministrator);
  };

  const getUserDetails = async () => {
    const response = await userService.getUserDetails();
    setUserId(response?.data?.user?.id);
    return response.data?.user;
  };

  useEffect(() => {
    const init = async () => {
      const userDetails = await getUserDetails();

      checkIsAdmin(userDetails.id);
    };
    init();
    const workspacename = getWorkspaceName();
    setActiveWorkspaceName(workspacename);

    const workspacedp = getWorkspaceImg();
    setActiveWorkspaceDp(workspacedp);
  }, []);

  const landOnAdminScreen = () => {
    window.open('/manage/admin', '_blank');
  };

  const landOnMemberScreen = () => {
    window.open('/manage/member', '_blank');
  };

  const openEditWorkspaceDialog = () => {
    window.open('/manage/member?editWorkspace=true', '_blank');
  };

  const switchWorkspaceHandler = () => {
    history.push('/switchWorkspace');
  };
  return (
    <>
      <div className={classes.firstLineContainer}>
        <div style={{ width: '100%' }}>
          <PopupState variant="popover" popupId="demo-popup-popover" disableAutoFocus>
            {(popupState) => (
              <div>
                <Button
                  variant="contained"
                  {...bindTrigger(popupState)}
                  className={
                    isLauncherScreen ? classes.popupLuncherStateBtn : classes.popupStateBtn
                  }
                >
                  <img
                    width={32}
                    height={32}
                    src={
                      activeWorkspaceDp ||
                      (isLauncherScreen
                        ? instituteDefaultIconWhiteLayout
                        : instituteDefaultIconBlackLayout)
                    }
                    alt="logo"
                    style={{ marginRight: '18px', borderRadius: '6px' }}
                  />
                  <div className={classes.screenSwitcherParentContainer}>
                    <div className={classes.switcherNameContainer}>
                      {activeWorkspaceName} <ExpandMoreIcon />
                    </div>
                    <div className={isLauncherScreen ? classes.pageTitleWhite : classes.pageTitleBlack
                    }>
                      {pageTitle ? pageTitle : ''}
                    </div>
                  </div>

                </Button>
                <Popover
                  disable
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box
                    p={2}
                    style={{
                      width: '290px',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    }}
                  >
                    <div onClick={switchWorkspaceHandler} className={classes.workspaceOptions}>
                      Switch workspace
                    </div>
                  </Box>
                  {/* TODO future implementation */}
                  {/* <Box
                                p={2}
                                style={{
                                  width: '290px',
                                  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                                }}
                              >
                                <Typography>
                                  <div className={classes.planDetails}>
                                    Advanced Plan, due on June 1, 2020
                                  </div>
                                </Typography>
                                <Typography variant="div" className={classes.workspaceOptions}>
                                  Licence & billing{' '}
                                </Typography>
                              </Box> */}
                  <SidebarWorkspaceGroupContainer popupState={popupState} />
                  {!isMobileView && (
                    <Box
                      p={2}
                      style={{
                        width: '290px',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                      }}
                    >
                      <div
                        onClick={isAdmin && landOnMemberScreen}
                        className={
                          isAdmin ? classes.workspaceOptions : classes.workspaceOptionsDisable
                        }
                        style={{ marginBottom: '9px' }}
                      >
                        Manage workspace members
                      </div>
                      <div
                        onClick={isAdmin && openEditWorkspaceDialog}
                        className={
                          isAdmin ? classes.workspaceOptions : classes.workspaceOptionsDisable
                        }
                      >
                        Manage workspace profile
                      </div>
                    </Box>
                  )}
                  {!isMobileView && (
                    <Box
                      p={2}
                      style={{
                        width: '290px',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                      }}
                    >
                      <Typography
                        variant="div"
                        onClick={isAdmin && landOnAdminScreen}
                        className={
                          isAdmin ? classes.workspaceOptions : classes.workspaceOptionsDisable
                        }
                      >
                        Admin portal
                      </Typography>
                    </Box>
                  )}
                </Popover>
              </div>
            )}
          </PopupState>
        </div>
      </div>
    </>
  );
};

export default ScreenSwitcher;
