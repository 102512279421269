import React from 'react';
import { Box, Button, Typography, makeStyles, useTheme } from '@material-ui/core';

const useStyles = ({ disabled, small }) =>
  makeStyles({
    root: {
      opacity: disabled ? '0.4' : undefined,
      '& .MuiButton-startIcon': {
        marginRight: 4,
        width: small ? 12 : 24,
        height: small ? 12 : 24,
      },
      '& .MuiTypography-root': {
        fontSize: small ? 10 : 14,
      },
    },
  });

const PrimaryActionButton = (props) => {
  const { icon, iconSrc, alt, title, small, isActive, disabled, ...rest } = props;
  const theme = useTheme();
  const classes = useStyles({ disabled, small })();

  let buttonStyle = {};
  if (isActive) {
    buttonStyle = { backgroundColor: theme.palette.success.main };
  }

  const startIcon = iconSrc ? <img src={iconSrc} alt={alt} /> : undefined;

  const displayTitle = <Typography color="primary">{title}</Typography>;

  return (
    <Button
      className={classes.root}
      variant="text"
      startIcon={startIcon}
      disableRipple
      style={buttonStyle}
      disabled={disabled}
      {...rest}
    >
      <Box display="flex" alignItems="center" justifyContent="center" style={{ gap: '0.25rem' }}>
        {icon}
        {displayTitle}
      </Box>
    </Button>
  );
};

export default PrimaryActionButton;
