import { useSelector } from 'react-redux';
import config from '../../config';
import zoomService from '../../services/zoom.service';
import { useZoomDispatcher } from '../../store/dispatcher/useZoomDispatcher';

export const useZoomAuth = () => {
  const { dispatchSetZoomIsAuthenticated } = useZoomDispatcher();
  const me = useSelector((state) => state.zoom.me);
  const isZoomAuthenticated = useSelector((state) => state.zoom.isAuthenticated);

  const revokeAccess = async () => {
    try {
      await zoomService.revokeAuthorization();
      dispatchSetZoomIsAuthenticated(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const constructOAuthUrl = () => {
    const zoomOAuthPath = 'https://zoom.us/oauth/authorize';
    const paramsObj = {
      response_type: 'code',
      client_id: config.ZOOM_CLIENT_ID,
      redirect_uri: config.ZOOM_REDIRECT_URI,
    };
    const searchParams = new URLSearchParams(paramsObj);
    return `${zoomOAuthPath}?${searchParams.toString()}`;
  };

  const initiateZoomOAuthAuthorization = () => {
    const url = constructOAuthUrl();
    window.open(url, '_self');
  };

  return { me, isZoomAuthenticated, revokeAccess, initiateZoomOAuthAuthorization };
};
