import { useSelector } from 'react-redux';

export const useMicrosoftSelector = () => {
  const isAuthenticated = useSelector((state) => state.microsoft.isAuthenticated);
  const microsoftTeamsMeeting = useSelector((state) => state.microsoft.teamsMeeting);
  const communicationIdentity = useSelector((state) => state.microsoft.communicationIdentity);
  const outlookEvents = useSelector((state) => state.microsoft.outlookEvents);
  const outlookEventsDayWise = useSelector((state) => state.microsoft.outlookEventsDayWise);

  return {
    isAuthenticated,
    microsoftTeamsMeeting,
    communicationIdentity,
    outlookEvents,
    outlookEventsDayWise,
  };
};
