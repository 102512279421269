import React, { useEffect, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Popover,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Search as SearchIcon, Sort as SortIcon } from '@material-ui/icons';

import { sortOptions } from '.';
import { notebookIcon } from '../../../assets/icons';

const useStyles = makeStyles({
  notebookListHeaderRoot: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 8,
    width: '100%',
    marginBottom: 24,
    '& .i-actions-container': {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      '& *': {
        color: '#00000099',
      },
    },
    '& .i-notebook-icon': {
      width: 24,
      height: 24,
      mask: `url(${notebookIcon}) no-repeat center`,
      '-webkit-mask': `url(${notebookIcon}) no-repeat center`,
      backgroundColor: '#787878',
    },
    '& .i-notebooks-title': {
      fontSize: 16,
      color: '#767676',
      flex: 1,
    },
    '& .i-search-notebooks': {
      marginBottom: 0,
      '& .MuiInputBase-root': { height: 32 },
      '& fieldset': {
        borderRadius: 16,
      },
    },
  },
  sortDialogPaper: {
    '& .MuiListItem-root': {
      fontSize: 12,
      fontWeight: 500,
    },
  },
});

const NotebookListHeader = ({ searchValue, onSearch, sortBy, onSort, onCreate }) => {
  const classes = useStyles();
  const [sortAnchorEl, setSortAnchorEl] = useState(null);

  const handleSortClick = (event) => setSortAnchorEl(event.currentTarget);
  const handleSortClose = () => setSortAnchorEl(null);

  const handleSort = (sortOption) => {
    onSort(sortOption);
    handleSortClose();
  };

  useEffect(() => {
    const timer = setTimeout(() => onSearch && onSearch(searchValue), 500);
    return () => clearTimeout(timer);
  }, [onSearch, searchValue]);

  const handleSearch = (e) => onSearch(e.target.value);

  return (
    <div className={classes.notebookListHeaderRoot}>
      <span className="i-notebook-icon" />
      <Typography className="i-notebooks-title">My Notebooks</Typography>
      <TextField
        className="i-search-notebooks"
        variant="outlined"
        onChange={handleSearch}
        placeholder="Search in notebooks"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        size="small"
      />
      <div className="i-actions-container">
        <IconButton id="sort-by" size="small" onClick={handleSortClick} disableRipple>
          <SortIcon
            style={{
              transform: [sortOptions.LATEST, sortOptions.ASCENDING].includes(sortBy)
                ? 'none'
                : 'rotate(180deg)',
            }}
          />
        </IconButton>
        <label htmlFor="sort-by" style={{ cursor: 'pointer' }}>
          <Typography style={{ width: 85, fontWeight: 500 }}>{sortBy}</Typography>
        </label>
      </div>
      <Popover
        id="simple-menu"
        anchorEl={sortAnchorEl}
        open={!!sortAnchorEl}
        onClose={handleSortClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disablePortal
        classes={{ paper: classes.sortDialogPaper }}
      >
        <List>
          <ListItem button onClick={() => handleSort(sortOptions.ASCENDING)}>
            {sortOptions.ASCENDING}
          </ListItem>
          <ListItem button onClick={() => handleSort(sortOptions.DESCENDING)}>
            {sortOptions.DESCENDING}
          </ListItem>
          <ListItem button onClick={() => handleSort(sortOptions.LATEST)}>
            {sortOptions.LATEST}
          </ListItem>
          <ListItem button onClick={() => handleSort(sortOptions.OLDEST)}>
            {sortOptions.OLDEST}
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

export default NotebookListHeader;
