import moment from 'moment';
import React, { useMemo } from 'react';
import { getEndTime, getStartTime } from '../../../../Utils/microsoft/outlook-event';
import { EventTimeInfo } from './component';

export const EventTimeInfoContainer = (props) => {
  const { eventInfo } = props;
  const startDate = moment(getStartTime(eventInfo)).format('h:mm a');
  const endDate = moment(getEndTime(eventInfo)).format('h:mm a');

  const timeReminder = useMemo(() => {
    const dateNow = new Date();
    let todayDate = dateNow.toISOString();
    const scheduleZone = new Date(eventInfo.start.dateTime + 'Z');
    let dueDateOn = scheduleZone.toISOString();
    const m1 = moment(dueDateOn);
    const m2 = moment(todayDate);

    if (m1.diff(m2, 'minutes') <= 0) {
      return '';
    } else if (m1.diff(m2, 'minutes') <= 180) {
      return ` | starts in ${m1.diff(m2, 'minutes')} mins`;
    } else {
      return ` | starts in ${m1.diff(m2, 'hours')}hr`;
    }
  }, [eventInfo]);

  return <EventTimeInfo startDate={startDate} endDate={endDate} timeReminder={timeReminder} />;
};
