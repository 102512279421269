import React, { useEffect } from 'react';
import { ScreenshareDisplayContent } from './component';

export const ScreenshareDisplayContentContainer = (props) => {
  const { subscribedData, isViewOnly, localSubscribedData, showRemoteVideo } = props;
  const [screenshareCount, setScreenshareCount] = React.useState(0);

  const onlyVideoFilter = ({ kind }) => {
    return kind === 'video';
  };

  useEffect(() => {
    const count = subscribedData.filter(onlyVideoFilter).length;
    setScreenshareCount(count);
  }, [subscribedData]);

  return (
    <ScreenshareDisplayContent
      isViewOnly={isViewOnly}
      localSubscribedData={localSubscribedData}
      onlyVideoFilter={onlyVideoFilter}
      screenshareCount={screenshareCount}
      showRemoteVideo={showRemoteVideo}
      subscribedData={subscribedData}
    />
  );
};
