import ACTION_TYPES from '../actions/type.action';

const initialState = {
  deviceId: '',
};

const deviceReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPES.PAIRED_DEVICE_ID:
      return {
        deviceId: payload,
      };
    default:
      return state;
  }
};

export default deviceReducer;
