import { getWorkspaceId } from '../../Utils/authentication-access';
import boardsService from '../../services/boards.service';
import licenseService from '../../services/licence.service';
import ACTION_TYPES from './type.action';
import _ from 'lodash';

export const getDeviceLicenseDetails = (instituteId) => async (dispatch) => {
  const licenseDetails = await licenseService.getDeviceLicenseDetails(instituteId);

  dispatch({
    type: ACTION_TYPES.GET_LICENSE_DETAILS,
    payload: _.cloneDeep(licenseDetails),
  });
};

export const getCloudLicenseDetails = (workspacId) => async (dispatch) => {
  let workspaceId = getWorkspaceId();

  if (!workspaceId) {
    const response = await boardsService.getBoards();
    const classInfo = response.data.data;

    workspacId = classInfo.workspacId;
  }
  const licenseDetails = await licenseService.getCloudLicenseDetails(workspacId);

  dispatch({
    type: ACTION_TYPES.GET_CLOUD_LICENSE_DETAILS,
    payload: _.cloneDeep(licenseDetails.data),
  });
};
