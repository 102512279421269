import React, { useState, useEffect } from 'react';
import { useHuddleDispatcher } from '../../../store/dispatcher/useHuddleDispatcher';
import { useHuddleSelector } from '../../../store/selector/useHuddleSelector';
import { BBBViewer } from './component';

export const BBBViewerContainer = (props) => {
  const { updateToolAreaOnHuddleWindowClosed, updateToolAreaOnHuddleWindowOpen, bbbWindowWidth } =
    props;

  const defaultIsFullScreen = false;
  const defaultIsMinimalizedUI = false;
  const defaultIsContentUIDisplayed = true;

  const [isFullScreen, setIsFullScreen] = useState(defaultIsFullScreen);
  const [isMinimalizedUI, setIsMinimalizedUI] = useState(defaultIsMinimalizedUI);
  const [isContentUIDisplayed, setContentIsUIDisplayed] = useState(defaultIsContentUIDisplayed);
  const { isViewOpen, user } = useHuddleSelector();
  const { dispatchShowBBBViewer, dispatchHideBBBViewer } = useHuddleDispatcher();

  const defaultViewState = () => {
    setIsFullScreen(defaultIsFullScreen);
    setIsMinimalizedUI(defaultIsMinimalizedUI);
    setContentIsUIDisplayed(defaultIsContentUIDisplayed);
  };

  useEffect(() => {
    defaultViewState();
  }, [user.isOnline]);

  const displayMinimalUI = () => {
    setIsMinimalizedUI(true);
  };

  const displayMediumUI = () => {
    setIsMinimalizedUI(false);
  };

  const displayContentUI = () => {
    setContentIsUIDisplayed(true);
  };

  const hideContentUI = () => {
    setContentIsUIDisplayed(false);
  };

  useEffect(() => {
    if (!user.isOnline) {
      hideFullScreen();
    }
  }, [user]);

  const closeBBBViewer = () => {
    updateToolAreaOnHuddleWindowClosed();
    dispatchHideBBBViewer();
  };

  const draggableConfig = {
    handle: 'strong',
    defaultPosition: { x: 0, y: 0 },
    cancel: '.huddle-control',
  };

  const openBBBViewer = () => {
    updateToolAreaOnHuddleWindowOpen();
    dispatchShowBBBViewer();
  };

  const showFullScreen = () => {
    setIsFullScreen(true);
  };

  const hideFullScreen = () => {
    setIsFullScreen(false);
  };

  const onChangeFullScreen = (_isFullScreen) => {
    if (_isFullScreen) {
      return showFullScreen();
    }
    return hideFullScreen();
  };

  return (
    <BBBViewer
      bbbWindowWidth={bbbWindowWidth}
      closeBBBViewer={closeBBBViewer}
      draggableConfig={draggableConfig}
      isFullScreen={isFullScreen}
      isViewOpen={isViewOpen}
      onChangeFullScreen={onChangeFullScreen}
      openBBBViewer={openBBBViewer}
      showFullScreen={showFullScreen}
      isUserOnline={user.isOnline}
      isMinimalizedUI={isMinimalizedUI}
      isContentUIDisplayed={isContentUIDisplayed}
      displayMediumUI={displayMediumUI}
      displayMinimalUI={displayMinimalUI}
      displayContentUI={displayContentUI}
      hideContentUI={hideContentUI}
    />
  );
};
