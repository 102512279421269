import { CircularProgress, Drawer } from '@material-ui/core';
import React from 'react';
import NoContentIcon from '../../../assets/icons/NoContentIcon';
import { ImportOutlookEventDialogContainer } from './import-outlook-event-dialog/container';
import { useVideoCallSidebarStyle } from './component.style';
import { Meetings } from './meetings/container';
import { ScheduleMeetingDialogContainer } from './schedule-meeting-dialog-container/container';
import { SetupZoomDialogContainer } from './setup-zoom-dialog/container';
import { CalendarSelectionContainer } from './calendar-selection/container';
import { PersonalCalendarContentContainer } from './display-content/personal-calendar-content/container';
import { CALENDAR_TABS } from './calendar-selection/use-calendar-selection';

export const VideoCallSidebar = (props) => {
  const {
    checkIsAllScheduleFilter,
    closeDialog,
    closeImportOutlookEventDialog,
    closeSidebar,
    closeSetupZoomAccountDialog,
    enableEditSchedule,
    formik,
    getSchedulesHandler,
    isOpenDialog,
    isOpenImportOutlookEventDialog,
    isSidebarOpen,
    isZoomSetupDialogOpen,
    loading,
    onSubmit,
    openDialog,
    openImportOutlookCalendarEventDialog,
    openSetupZoomAccountDialog,
    scheduleFilter,
    schedules,
    setEndTime,
    setStartTime,
    setTodaysScheduleFilter,
    setUpcomingScheduleFilter,
    handleSelectedCalendarTab,
    selectedCalendarTab,
  } = props;
  const classes = useVideoCallSidebarStyle();

  const displaySchedules =
    schedules && schedules.length ? (
      <Meetings
        schedules={schedules}
        getSchedulesHandler={getSchedulesHandler}
        enableEditSchedule={enableEditSchedule}
        checkIsAllScheduleFilter={checkIsAllScheduleFilter}
        closeSidebar={closeSidebar}
      />
    ) : (
      <div className={classes.emptyContainer}>
        <NoContentIcon />
        <div className={classes.noMeetingText}>
          No Meetings
          <br /> Scheduled
        </div>
      </div>
    );

  const displayLoading = (
    <div className={classes.spinnerContainer}>
      <CircularProgress />
    </div>
  );

  const calendarSelectionContainer = (
    <CalendarSelectionContainer
      scheduleFilter={scheduleFilter}
      setTodaysScheduleFilter={setTodaysScheduleFilter}
      setUpcomingScheduleFilter={setUpcomingScheduleFilter}
      openDialog={openDialog}
      openImportOutlookCalendarEventDialog={openImportOutlookCalendarEventDialog}
      openSetupZoomAccountDialog={openSetupZoomAccountDialog}
      handleSelectedCalendarTab={handleSelectedCalendarTab}
      selectedCalendarTab={selectedCalendarTab}
    />
  );

  const dialogs = (
    <>
      <ScheduleMeetingDialogContainer
        closeDialog={closeDialog}
        formik={formik}
        isOpenDialog={isOpenDialog}
        onSubmit={onSubmit}
        setEndTime={setEndTime}
        setStartTime={setStartTime}
      />
      <ImportOutlookEventDialogContainer
        closeDialog={closeImportOutlookEventDialog}
        isOpenDialog={isOpenImportOutlookEventDialog}
      />
      <SetupZoomDialogContainer
        isOpenDialog={isZoomSetupDialogOpen}
        closeDialog={closeSetupZoomAccountDialog}
      />
    </>
  );

  const displaySpaceCalendarContent = loading ? displayLoading : displaySchedules;
  const displayPersonalCalendarContent = (
    <PersonalCalendarContentContainer closeSidebar={closeSidebar} />
  );

  const displayContent =
    selectedCalendarTab === CALENDAR_TABS.SPACE
      ? displaySpaceCalendarContent
      : displayPersonalCalendarContent;

  return (
    <div>
      <Drawer anchor="right" open={isSidebarOpen} onClose={() => closeSidebar()}>
        {calendarSelectionContainer}
        {displayContent}
      </Drawer>
      {dialogs}
    </div>
  );
};
