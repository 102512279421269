import { makeStyles } from '@material-ui/core';

export const useCopyLinkStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '0.25rem',
    alignItems: 'center',
    height: '3.5rem',
    paddingRight: '0.5rem',
    '&:hover': {
      border: `1px solid ${theme.palette.common.black}`,
    },
    position: 'relative',
  },
  linkButton: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    gap: '0.5rem',
    height: '3.5rem',
    padding: '0 1.5rem',
    paddingLeft: '0.5rem',
  },
  copyLink: {
    height: '2.375rem',
  },
  labelContainer: {
    width: 0,
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingLeft: '2rem',
    paddingRight: '3rem',
  },
}));
