import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

import ChatBox from './chat-box';
import QueryInput from './query-input';

const useStyles = makeStyles((theme) => ({
  messageListRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100dvh - 92px)',
    [theme.breakpoints.up('md')]: {
      paddingTop: 66,
      width: 'calc(50% - 8px)',
      height: '100%',
    },
  },
}));

const MessageList = ({ onAsk, chatList, disabledQueryInput, loading }) => {
  const classes = useStyles();

  return (
    <Box className={classes.messageListRoot}>
      <ChatBox chatList={chatList} />
      <QueryInput onSend={onAsk} disabled={disabledQueryInput} loading={loading} />
    </Box>
  );
};

export default MessageList;
