import ACTION_TYPES from '../actions/type.action';

const initialState = {
  screenshare: {
    isShared: false,
  },
};

const mediaServerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPES.SET_IS_SCREENSHARED:
      return {
        ...state,
        screenshare: {
          ...state.screenshare,
          isShared: payload,
        },
      };
    default:
      return state;
  }
};

export default mediaServerReducer;
