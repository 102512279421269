export const getParameters = (urlString) => {
  let paramString = urlString.split('?')[1];
  let queryString = new URLSearchParams(paramString);
  const params = {};
  for (let pair of queryString.entries()) {
    params[pair[0]] = pair[1];
  }
  return params;
};

/**
 *
 * @param {*} urlString
 * @returns {{ meetingNumber: string, pwd: string }}
 */
export const getZoomMeetingUrlParameters = (urlString) => {
  if (!urlString) {
    console.error(`urlString should not be empty`);
    
    return {
      meetingNumber: 0,
      pwd: 'meeting url is not valid',
    };
  }
  const meetingNumber = urlString.split('/j/')[1].split('?')[0];
  const params = getParameters(urlString);
  return {
    ...params,
    meetingNumber,
  };
};
