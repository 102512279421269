import inviteService from '../../services/invites.service';
import ACTION_TYPES from './type.action';

export const fetchPendingRequests = (instituteKneuraId) => async (dispatch) => {
  const response = await inviteService.getAllPendingRequests(instituteKneuraId);
  dispatch({
    type: ACTION_TYPES.SET_PENDING_REQUESTS,
    payload: response.data.data.pendingJoinRequests,
  });
};

export const rejectInvite = (requestId) => async (dispatch) => {
  await inviteService.reject(requestId);
  dispatch({
    type: ACTION_TYPES.REMOVE_INVITE,
    payload: requestId,
  });
};

export const acceptInvite = (requestId) => async (dispatch) => {
  await inviteService.accept(requestId);
  dispatch({
    type: ACTION_TYPES.REMOVE_INVITE,
    payload: requestId,
  });
};

export const acceptAllInvites = (workspaceId) => async (dispatch) => {
  let response = await inviteService.acceptAll(workspaceId);
  dispatch({
    type: ACTION_TYPES.REMOVE_INVITE_BY_IDS,
    payload: response,
  });
};

export const addInvite = (response) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ADD_INVITE,
    payload: response,
  });
};

export const showJoinWorkspaceInviteDialog = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SHOW_JOIN_WORKSPACE_INVITE_DIALOG,
  });
};

export const hideJoinWorkspaceInviteDialog = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.HIDE_JOIN_WORKSPACE_INVITE_DIALOG,
  });
};

export const showJoinSpaceInviteDialog = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SHOW_JOIN_SPACE_INVITE_DIALOG,
  });
};

export const hideJoinSpaceInviteDialog = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.HIDE_JOIN_SPACE_INVITE_DIALOG,
  });
};
