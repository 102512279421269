import { useState } from 'react';
import { useHistory } from 'react-router';
import PubSub from 'pubsub-js';
import deviceService from '../../services/device.service';
import { spacesService } from '../../services/spaces.service';
import workspaceService from '../../services/workspaces.services';
import { useNotificationDispatcher } from '../../store/dispatcher/useNotificationDispatcher';
import { useMediaServerSelector } from '../../store/selector/useMediaServerSelector';
import { get } from '../api';
import {
  clearUserData,
  getWorkspaceId,
  setBoardInfo,
  setSpaceId,
  setSpaceName,
  setToken,
  setUserName,
  setWorkspaceId,
  setWorkspaceImg,
  setWorkspaceName,
} from '../authentication-access';
import { WHITEBOARD } from '../url-builder';
import { EVENTS } from '../../constants/events';
import { useSelector } from 'react-redux';

export const useAuth = () => {
  const { dispatchShowSpinner, dispatchHideSpinner } = useNotificationDispatcher();
  const { dispatchSetSnackbar } = useNotificationDispatcher();
  const history = useHistory();
  const [workSpacesLength, setWorkspacesLength] = useState(0);
  const { isScreenshared } = useMediaServerSelector();
  const deviceConnectedStatus = useSelector((state) => state.deviceStatusReducer.deviceConnected);

  /**
   * Re-using the existing code used to hydrate data after login
   * TODO:
   * refactor the code
   *
   *
   * @param {{ accessToken: string, userName: string, statusCode: string, isDeviceConnected: boolean }} data
   */
  const hydrateLogin = async (data) => {
    const { accessToken, userName, statusCode, isDeviceConnected } = data;
    let token = accessToken;
    let workSpaceLength = 0;
    setToken(token);

    setUserName(userName);

    let info = null;
    let classID = null;
    let fetchSpacesResponse = 0;

    if (statusCode === 201 || 200) {
      const fetchWorkspacesResponse = await workspaceService.getWorkspaces();

      let workspaceID = fetchWorkspacesResponse.data.data.workspaces[0].id;
      let workspaceName = fetchWorkspacesResponse.data.data.workspaces[0].name;
      let workspaceDp = fetchWorkspacesResponse.data.data.workspaces[0].imageUrl;

      workSpaceLength = fetchWorkspacesResponse.data.data.workspaces.length;

      setWorkspacesLength(workSpaceLength);

      setWorkspaceId(workspaceID);
      setWorkspaceName(workspaceName);
      setWorkspaceImg(workspaceDp);
      let getworkspaceID = getWorkspaceId();
      fetchSpacesResponse = await spacesService.getSpaces(getworkspaceID);

      info = fetchSpacesResponse.data.data.spaces;
    }
    let infoData;
    if (info[0] && info[0].childrens[0]) {
      infoData = info[0].childrens[0].meta;
      setSpaceId(info[0].id);
      setSpaceName(info[0].name);
    }
    classID = infoData.classId;
    if (fetchSpacesResponse.status == 201 || 200) {
      const url = WHITEBOARD.getWhiteBoard(classID);
      const response = await get(url);
      const classInfo = response.data.data;

      const { email, hardwareId, name, flowId, classId } = classInfo;
      let canvasInfo = {
        email,
        hardwareId,
        name,
        flowId,
        classId,
      };

      setBoardInfo(canvasInfo);
    }

    dispatchHideSpinner();
    if (isDeviceConnected) await disconnectDeviceHandler();

    if (workSpaceLength <= 1) {
      history.push('/controller/launcher');
    } else {
      // history.push('/switchWorkspace');
      history.push('/controller/launcher');
    }
  };

  const disconnectDeviceHandler = async () => {
    try {
      const response = await deviceService.unPairDevice();
      dispatchSetSnackbar({ message: 'Device disconnected' });
    } catch (error) {
      const message = error?.response?.data?.message || error.message;
      console.log('deviceId error', message);
      dispatchSetSnackbar({ message });
    }
    return;
  };

  const handleLogout = async () => {
    PubSub.publish(EVENTS.LOGOUT);
    if (deviceConnectedStatus) await disconnectDeviceHandler();
    history.push('/');
    clearUserData();
  };

  return {
    handleLogout,
    hydrateLogin,
  };
};
