import { makeStyles } from '@material-ui/core';

export const usePdfPagesGalleryFooterStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    gap: '0.5rem',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  pageRangeInputContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  fullscreenSwitchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  importDocButtonLabel: {
    textTransform: 'none',
    fontSize: '0.75rem',
  },
  pageRangeInput: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
}));
