import { makeStyles } from '@material-ui/core';

export const useEventInfoStyles = makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
    display: 'flex',
    gap: '1rem',
    justifyContent: 'space-between',
  },
  groupContainer: {
    display: 'flex',
    gap: '1rem',
  },
  time: {
    display: 'flex',
  },
  recurringDateTime: {
    display: 'flex',
    gap: '0.5rem',
  },
}));
