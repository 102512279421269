import React from 'react';

const SelectTool = (props) => (
  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.54882 20.7178C9.04486 21.2219 8.19036 21.0519 7.91768 20.3933L0.601104 2.72241C0.26034 1.8994 1.08458 1.07516 1.90759 1.41592L19.5782 8.73233C20.2367 9.00499 20.4067 9.85941 19.9027 10.3634L16.5091 13.7569C16.1185 14.1474 16.1185 14.7805 16.509 15.1711L20.9708 19.6332C21.3613 20.0238 21.3613 20.657 20.9707 21.0475L20.2322 21.7858C19.8417 22.1762 19.2086 22.1762 18.8181 21.7857L14.3563 17.3236C13.9658 16.933 13.3325 16.933 12.942 17.3236L9.54882 20.7178Z"
      fill={props.fill}
      stroke="black"
    />
  </svg>
);

export default SelectTool;
