import React, { useEffect } from 'react';
import { makeStyles, IconButton, useMediaQuery } from '@material-ui/core';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  getWorkspaceId,
  setWorkspaceId,
  setWorkspaceImg,
  setWorkspaceName,
  getWorkspaceImg,
  getWorkspaceName,
} from '../../Utils/authentication-access';
import { useTheme } from '@material-ui/core/styles';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import workspaceService from '../../services/workspaces.services';
import classescss from './controllerTopbar.module.css';
import ScreenSwitcher from '../../components/sideBar/ScreenSwitcher';
import gradientBackground from '../../assets/svg/gradient-lines-blur.svg';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: '100',
  },
  secondContainer: {
    display: 'flex',
    height: '100%',
  },
  contentDev: {
    backgroundColor: '#EDEFF1',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    height: 'calc(100% - 5rem)',
  },
  loadingGrid: {
    height: '100%',
    width: '100%',
    display: 'grid',
    gridRowGap: '10px',
    gridTemplateRows: 'auto auto',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
  },
  rounded: {
    borderRadius: '6px',
  },
  workspaceContainer: {
    display: 'flex',
    gap: '18px',
    marginLeft: '20px',
  },
}));

const ControllerTopbar = ({ handleMobileSideMenuOpen, pageTitle }) => {
  const theme = useTheme();
  const location = useLocation();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const isLauncherScreen = window.location.href.includes('launcher');
  const isLearnAIPage = location.pathname.startsWith('/controller/learn-ai');
  const isSmartNotesPage = location.pathname.startsWith('/controller/smart-notes');

  //TODO:required for feature implementation
  // useEffect(() => {
  //   getListOfWorkspaces();
  //   const workspaceName = getWorkspaceName();
  //   const workspaceLogo = getWorkspaceImg();
  // }, []);

  // const getListOfWorkspaces = async () => {
  //   const response = await workspaceService.getWorkspaces();
  //   setListOfWorkspaces(response?.data.data.workspaces);
  //   const workspaceCheck = response?.data?.data?.workspaces.find(
  //     (element) => element.id === getWorkspaceId(),
  //   );
  //   if (!workspaceCheck) {
  //     setWorkspaceId(response?.data?.data?.workspaces[0].id);
  //     setWorkspaceName(response?.data?.data?.workspaces[0].name);
  //     setWorkspaceImg(response?.data?.data?.workspaces[0].imageUrl);
  //   }
  // };

  return (
    <>
      <div
        style={{
          height: '60px',
        }}
      >
        <div
          className={classescss.topbarSubContainer}
          style={{
            background:
              isLauncherScreen ? undefined : '#fff',
            backgroundSize: 'cover',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '20px',
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              style={{
                display: `${matches ? 'none' : 'black'}`,
                marginLeft: '0px',
              }}
              onClick={handleMobileSideMenuOpen}
            >
              <MenuRoundedIcon />
            </IconButton>

            <div style={{ width: '380px' }}>
              <ScreenSwitcher pageTitle={pageTitle} />
            </div>
          </div>
          <div
            style={{
              marginRight: '19px',
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            {/* //TODO: feature implementation
            <div style={{ display: 'flex', opacity: '0.4' }}>
              <div>
                <StyledBadge
                  color="primary"
                  style={{ cursor: 'not-allowed' }}
                  badgeContent={1}

                  // onClick={openNoticeBarClick}
                >
                  <Notification />
                </StyledBadge>
              </div>
               <Drawer open={openNoticeBar} onClose={closeNotification}>
                  <div style={{ minWidth: '450px' }}>hello</div>
                </Drawer>
            </div>
             //TODO: feature implementation */}
            <div>&nbsp;</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ControllerTopbar);
