import React, { useState, useEffect } from 'react';
import { PairBoard } from './component';
import { spacesService } from '../../services/spaces.service';
import { getCanvasCode } from '../../Utils/authentication-access';
import { GREEN_COLOR, WHITE } from '../../theme/base-theme';
import { DeviceCodeDialogContainer } from './device-code-dialog/container';
import { stubFalse } from 'lodash';
import { EVENTS } from '../../constants/events';
import PubSub from 'pubsub-js';
import Airdock from '../../assets/icons/Airdock';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useMediaQuery } from '@material-ui/core';

export const PairBoardContainer = ({ canvasBoard }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [isPaired, setIsPaired] = useState(false);
  const [isDeviceCodeDialogOpen, setIsDeviceCodeDialogOpen] = useState(stubFalse);

  const icon = isPaired ? <Airdock /> : <Airdock />;
  const label = isPaired ? 'Disconnect' : 'Connect Room';
  const color = isPaired ? 'black' : 'black';
  const backgroundColor = isPaired ? 'rgba(51, 90, 251, 0.4)' : 'white';
  const height = '31px';
  const boxShadow = 'none';
  const border = isPaired ? '1px solid #335AFB' : '1px solid #DADADA';
  const fontSize = '12px';

  const getAndSetIsPaired = async () => {
    const classId = getCanvasCode();

    const pairedStatusData = await spacesService.getPairedStatus(classId);
    const {
      data: { pairedStatus },
    } = pairedStatusData;
    setIsPaired(pairedStatus);
  };

  useEffect(() => {
    getAndSetIsPaired();
  }, []);

  useEffect(() => {
    const unpairClassSubscriber = (msg, data) => {
      console.log(`setting paired class status to false, message from socket: ${msg}`);
      setIsPaired(false);
    };
    const pairClassSubscriber = (msg, data) => {
      console.log(`setting paired class status to true, message from socket: ${msg}`);
      setIsPaired(true);
    };
    const unpairClassListenerToken = PubSub.subscribe(EVENTS.UNPAIR_CLASS, unpairClassSubscriber);
    const pairClassListenerToken = PubSub.subscribe(EVENTS.PAIR_CLASS, pairClassSubscriber);
    return () => {
      PubSub.unsubscribe(unpairClassListenerToken);
      PubSub.unsubscribe(pairClassListenerToken);
      console.log('clearing PAIR_CLASS and UNPAIR_CLASS event listener');
    };
  }, []);

  useEffect(() => {
    const subscriberToken = PubSub.subscribe(EVENTS.BOARD_CHANGED, getAndSetIsPaired);
    return () => {
      PubSub.unsubscribe(subscriberToken);
    };
  }, []);

  const unpairedHandler = async () => {
    try {
      const data = {
        classId: getCanvasCode(),
      };
      await spacesService.unpairClass(data);
      setIsPaired(false);
    } catch (error) {
      console.error(`error: ${error.message}`);
    }
  };

  const showDeviceCodeDialog = async () => {
    setIsDeviceCodeDialogOpen(true);
  };

  const hideDeviceCodeDialog = async () => {
    setIsDeviceCodeDialogOpen(false);
  };

  const onClick = () => {
    isPaired ? unpairedHandler() : showDeviceCodeDialog();
  };

  const deviceCodeDialogPopupState = usePopupState({
    variant: 'popover',
    popupId: 'deviceCodeDialog',
  });

  return (
    <>
      <PairBoard
        icon={icon}
        label={label}
        color={color}
        isPaired={isPaired}
        backgroundColor={backgroundColor}
        height={height}
        boxShadow={boxShadow}
        border={border}
        fontSize={fontSize}
        onClick={onClick}
        deviceCodeDialogPopupState={deviceCodeDialogPopupState}
        isMobile={isMobile}
      />
      <DeviceCodeDialogContainer
        isOpen={isDeviceCodeDialogOpen}
        canvasBoard={canvasBoard}
        setIsPaired={setIsPaired}
        hideDeviceCodeDialog={hideDeviceCodeDialog}
        onClose={hideDeviceCodeDialog}
        deviceCodeDialogPopupState={deviceCodeDialogPopupState}
      />
    </>
  );
};
