import React, { useMemo } from 'react';
import { Button, Typography, makeStyles } from '@material-ui/core';

const useStyles = ({ disabled }) =>
  makeStyles({
    root: {
      pointerEvents: disabled ? 'none' : 'all',
      backgroundColor: '#EEEEEE',
      padding: '4px 8px',
      border: 'none',
      textTransform: 'none',
      '& .MuiButton-startIcon': {
        opacity: disabled ? 0.4 : 1,
        marginRight: 4,
        width: 12,
        height: 12,
      },
      '& .MuiTypography-root': {
        opacity: disabled ? 0.4 : 1,
        fontSize: 10,
        color: '#00000099',
      },
    },
  });

const ActionButton = ({
  inputId,
  type,
  accept,
  onChange,
  onClick,
  iconSrc,
  alt,
  title,
  disabled,
}) => {
  const classes = useStyles({ disabled })();

  const button = useMemo(
    () => (
      <Button
        component={type === 'fileInput' ? 'span' : 'button'}
        onClick={onClick}
        className={classes.root}
        variant="outlined"
        startIcon={<img src={iconSrc} alt={alt} />}
        disableRipple
      >
        <Typography>{title}</Typography>
      </Button>
    ),
    [alt, classes.root, iconSrc, onClick, title, type],
  );

  return (
    <>
      {type === 'fileInput' ? (
        <label htmlFor={disabled ? undefined : inputId}>{button}</label>
      ) : (
        button
      )}
      {type === 'fileInput' && (
        <input
          id={inputId}
          type="file"
          accept={accept}
          style={{ display: 'none' }}
          onChange={onChange}
        />
      )}
    </>
  );
};

export default ActionButton;
