import React from 'react';
import { Grid } from '@material-ui/core';
import { Document, Page, pdfjs } from 'react-pdf';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { usePdfPagesGalleryStyles } from './component.style';
import { RenderPdfContainer } from './render-pdf/container';
import { StyledSpinner } from '../../common/spinner/styled-spinner';

export const PdfPagesGallery = (props) => {
  const {
    allPdfPagesNos,
    currentGalleryPage,
    displayPagesNos,
    goToNextGalleryPage,
    goToPreviousGalleryPage,
    hasNextGalleryPage,
    hasPrevGalleryPage,
    onDocumentLoadSuccess,
    onLoadError,
    shouldBeFullscreen,
    totalGalleryPage,
    url,
    docInfo,
  } = props;
  const classes = usePdfPagesGalleryStyles();
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const previousButton = hasPrevGalleryPage && (
    <ArrowBackIcon onClick={goToPreviousGalleryPage} className={classes.prevButton} />
  );
  const nextButton = hasNextGalleryPage && (
    <ArrowForwardIcon onClick={goToNextGalleryPage} className={classes.nextButton} />
  );

  const galleryNavigator = totalGalleryPage > 0 && docInfo && (
    <div className={classes.navigatorContainer}>
      <div className={classes.navigatorControls}>
        {previousButton}
        {currentGalleryPage}/{totalGalleryPage}
        {nextButton}
      </div>
    </div>
  );

  /**
   * Pdf pages are rendered in original dimensions
   * This section is hidden in the UI (OR not displayed to the users)
   * @returns
   */
  const originalPdfPageCanvasRender = (
    <div className={classes.originalSizeContainer}>
      {allPdfPagesNos.map((pageNumber) => (
        <Page
          key={`page_${pageNumber}-a`}
          className={`import-pdf-page-${pageNumber}`}
          pageNumber={pageNumber}
        />
      ))}
    </div>
  );

  const loading = (
    <div className={classes.loading}>
      <StyledSpinner />
    </div>
  );

  const gallery = (
    <Document
      file={url}
      onLoadSuccess={onDocumentLoadSuccess}
      onLoadError={onLoadError}
      loading={loading}
    >
      <Grid container spacing={3}>
        {displayPagesNos.map((pageNumber) => (
          <Grid item xs={4} key={`page_${pageNumber}`}>
            <RenderPdfContainer
              key={`page_${pageNumber}`}
              index={pageNumber}
              pageNumber={pageNumber}
              shouldBeFullscreen={shouldBeFullscreen}
            />
          </Grid>
        ))}
      </Grid>
      {originalPdfPageCanvasRender}
    </Document>
  );

  return (
    <div className={classes.container}>
      {gallery}
      {galleryNavigator}
    </div>
  );
};
