import React, { useState } from 'react';
import { IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import SingleContent from './SingleContent';
import { spacesService } from '../../../../services/spaces.service';
import { getSpaceId } from '../../../../Utils/authentication-access';

const useStyles = makeStyles(() => ({
  searchBar: {
    display: 'grid',
    gridTemplateColumns: '83% 17%',
    alignItems: 'center',
    marginTop: '1vh',
    marginBottom: '2vh',
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '15px 13px 0 17px',
  },
  contentDataList: {
    marginTop: '1vh',
    overflowY: 'scroll',
  },
}));

const MembersList = (props) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [removeMemberInfo, setRemoveMemberInfo] = useState('');
  const [removeMemberData, setRemoveMemberData] = useState({});
  const [boardMenu, setBoardMenu] = useState(0);
  let removeUserInfo = '';

  const removeUserFromSpace = (userInfo) => {
    removeUserInfo = userInfo;
    setRemoveMemberInfo(userInfo);
    setRemoveMemberData(userInfo);

    removeMember(removeMemberInfo);
  };

  const removeMember = async (removedUserInfo) => {
    let spaceId = getSpaceId();
    await spacesService.removeMemberFromSpace([removeUserInfo.user.id], spaceId);
    props.callfunction();
    setBoardMenu(boardMenu + 1);
  };

  return (
    <>
      <TextField
        fullWidth
        id="usernamesearch"
        variant="outlined"
        style={{ background: 'rgba(0, 0, 0, 0.12)', borderRadius: '5px' }}
        InputProps={{
          autoComplete: 'off',
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: 'rgba(0, 0, 0, 0.6)', paddingRight: 5 }} />
            </InputAdornment>
          ),
          style: { height: 40 },
        }}
        placeholder={'Search members'}
        className={classes.SearchField}
        value={searchText}
        onChange={(event) => {
          setSearchText(event.target.value);
          props.onEnterSearchText(event.target.value);
        }}
      />
      <div className={classes.contentDataList}>
        {props.suggestions != undefined &&
          props.suggestions.map((contentItem) => (
            <SingleContent
              contentItem={contentItem}
              removeMemberFromSpace={removeUserFromSpace}
              menuOptionsClose={boardMenu}
              membersCount={props.membersCount}
            />
          ))}
      </div>
    </>
  );
};

export default MembersList;
