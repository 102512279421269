import { useLocation } from 'react-router';

export const useURL = () => {
  const location = useLocation();

  const getParam = (key) => {
    return new URLSearchParams(location.search).get(key);
  };

  return {
    getParam,
  };
};
