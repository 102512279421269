import ACTION_TYPES from '../actions/type.action';

/**
 * isOnline: flag to check if huddle is online
 * user.isOnline: flag to check if user is online in the huddle
 */
const initialState = {
  fileInfo: undefined,
  docInfo: undefined,
};

const documentImportReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPES.SET_FILE_INFO:
      return {
        ...state,
        fileInfo: payload.fileInfo,
      };
    case ACTION_TYPES.RESET_FILE_INFO:
      return {
        ...state,
        fileInfo: undefined,
      };
    case ACTION_TYPES.SET_DOC_INFO:
      return {
        ...state,
        docInfo: payload,
      };
    case ACTION_TYPES.RESET_DOC_INFO:
      return {
        ...state,
        docInfo: undefined,
      };
    default:
      return { ...state };
  }
};

export default documentImportReducer;
