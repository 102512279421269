import React from 'react';
import classes from './TransparentFrame.module.css';
import { useMediaQuery } from '@material-ui/core';

const TransparentFrame = ({ children, label, frameWidth, frameHeight, rightBtn }) => {
  const isSmallScreen = useMediaQuery('(max-width: 800px)');

  return (
    <div
      className={classes.rightBoxContainer}
      style={{
        width: isSmallScreen ? '90%' : frameWidth,
        height: frameHeight ? frameHeight : '422px',
      }}
    >
      <div className={classes.calendarLabel}>
        <div>{label}</div>
        <div>{rightBtn}</div>
      </div>
      {children}
    </div>
  );
};

export default TransparentFrame;
