import React, { useEffect } from 'react';
import { checkIsAuthUser } from '../../../../Utils/authentication-access';

export const CALENDAR_TABS = {
  PERSONAL: 0,
  SPACE: 1,
};

export const useCalendarSelection = () => {
  const isAuthUser = checkIsAuthUser();
  const [selectedCalendarTab, setSelectedCalendarTab] = React.useState(CALENDAR_TABS.PERSONAL);

  useEffect(() => {
    if (!isAuthUser) {
      setSelectedCalendarTab(CALENDAR_TABS.SPACE)
    }
  }, [])

  const handleSelectedCalendarTab = (event, newValue) => {
    setSelectedCalendarTab(newValue);
  };

  return { selectedCalendarTab, handleSelectedCalendarTab };
};
