import * as React from 'react';
import { usePopupState, bindMenu, bindTrigger } from 'material-ui-popup-state/hooks';
import { Menu, MenuItem } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const MeetingOptions = (props) => {
  const { openDeleteDialog, enableEditSchedule, schedule } = props;
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });
  return (
    <div>
      <MoreHorizIcon
        fontSize="medium"
        style={{
          cursor: 'pointer',
          marginLeft: '10px',
          top: '20rem',
        }}
        {...bindTrigger(popupState)}
      />
      <Menu {...bindMenu(popupState)}>
        <MenuItem
          onClick={() => {
            enableEditSchedule({ schedule });
            popupState.close();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            openDeleteDialog();
            popupState.close();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

export default MeetingOptions;
