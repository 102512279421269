import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  summaryRoot: {
    '& .i-summary-title': {
      fontSize: 14,
      fontWeight: 700,
      marginBottom: 16,
    },
    '& .i-summary': {
      fontSize: 14,
    },
  },
});

const Summary = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.summaryRoot}>
      <Typography className="i-summary-title">Summary:</Typography>
      <Typography className="i-summary">{children}</Typography>
    </div>
  );
};

export default Summary;
