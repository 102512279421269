import ACTION_TYPES from '../actions/type.action';

const initialState = {
  count: 1,
};

const spaceReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPES.FETCH_SPACELIST:
      return {
        count: state.count + 1,
      };
    default:
      return state;
  }
};

export default spaceReducer;
