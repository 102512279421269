import React from 'react';
import { PersonalCalendarListing } from './component';
import { useMicrosoftSelector } from '../../../../../../store/selector/useMicrosoftSelector';

export const PersonalCalendarListingContainer = (props) => {
  const { closeSidebar, isLoading } = props;
  const { outlookEventsDayWise } = useMicrosoftSelector();
  const noMeetingsLabel = 'No meetings';

  return (
    <PersonalCalendarListing
      isLoading={isLoading}
      noMeetingsLabel={noMeetingsLabel}
      closeSidebar={closeSidebar}
      outlookEventsDayWise={outlookEventsDayWise}
    />
  );
};
