import React, { useCallback } from 'react';
import { Typography } from '@material-ui/core';

import { StyledDialog } from './index';

export const DeleteDialog = ({ dialogTitle, name, onClose, onDelete }) => {
  const handleDelete = useCallback(() => {
    onDelete();
    onClose();
  }, [onDelete, onClose]);

  return (
    <StyledDialog
      open={true}
      onClose={onClose}
      onSubmit={handleDelete}
      title={dialogTitle}
      submitText="DELETE"
    >
      <Typography>Are you sure that you want to delete {name}?</Typography>
      <Typography style={{ color: 'red' }}>(You can’t undo this action once deleted)</Typography>
    </StyledDialog>
  );
};
