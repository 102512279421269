import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { throttle } from 'lodash';
import moment from 'moment';
import { Hidden, Typography, makeStyles } from '@material-ui/core';

import { useNotificationDispatcher } from '../../store/dispatcher/useNotificationDispatcher';
import { getUserName } from '../../Utils/authentication-access';
import {
  useAsk,
  useUploadPdf,
  useQueryWelcomeMessage,
  useCreateYoutubeChat,
} from '../../services/self-learn.service';
import {
  useAddThread,
  useDeleteThread,
  useGetNotebook,
  useGetNotebookThreads,
  useGetNotebooks,
  useGetThreadDetails,
  useMoveThread,
  useMoveThreadToNewNotebook,
  useRenameThread,
} from '../../services/notebooks.service';
import { CHAT_TYPES, PERSONA_TYPES, QUERY_ACTION_TYPES, teacherTypes } from '../../constants';
import { setSelfLearnLanguage } from '../../store/actions/self-learn.action';
import { useLayoutDetails } from '../../context/layout-context';
import BrightAILayout from '../../hoc/bright-ai-layout';
import { useQueryParams } from '../../hooks/use-query-params';
import {
  Header,
  QueryInput,
  TeacherList,
  ChatBox,
  ThreadList,
  AgentList,
  LanguageSelect,
  YoutubeButton,
  PdfButton,
} from '../../components/self-learn';
import ControllerSidebar from '../../Kneura-Web-Whiteboard/Controller/ControllerSidebar';
import ControllerTopbar from '../../Kneura-Web-Whiteboard/Controller/ControllerTopbar';

const useStyles = ({ chatExpanded }) =>
  makeStyles((theme) => ({
    root: {
      display: 'flex',
      gap: 64,
      height: '100%',
      backgroundColor: '#FFF',
      [theme.breakpoints.down('md')]: { flexDirection: 'column' },
      boxSizing: 'border-box',
      '& *, *::after, *::before': {
        boxSizing: 'border-box',
      },
    },
    mainContent: {
      display: 'flex',
      flexDirection: 'column',
      width: chatExpanded ? 'calc(75% - 64px)' : '100%',
      maxWidth: theme.breakpoints.values.md,
      margin: '0px auto',
      minHeight: '100%',
      padding: 24,
      [theme.breakpoints.down('md')]: { width: '100%' },
      '& .i-agent-list-description': {
        fontSize: 12,
        color: '#767676',
        marginBottom: 16,
      },
    },
    actionsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      gap: 16,
      marginBottom: 100,
      [theme.breakpoints.down('md')]: {
        marginBottom: 24,
      },
      '& .primary-action-button': {
        gap: 8,
        height: 36,
        borderRadius: 16,
        border: `1px solid ${theme.palette.primary.main}`,
        fontSize: 14,
        fontWeight: 400,
        color: '#767676',
      },
      '& .MuiButton-root:hover': {
        backgroundColor: '#EAEEFF',
      },
    },
  }));

const SelfLearnPage = ({ newThread, handleMobileSideMenuOpen }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { notebookId, threadId } = useParams();
  const { agent } = useQueryParams();
  const { state: locationState } = useLocation();
  const { setLayoutDetails } = useLayoutDetails();
  const { dispatchShowSpinner, dispatchHideSpinner, dispatchSetSnackbar } =
    useNotificationDispatcher();
  const [teacherType, setTeacherType] = useState(teacherTypes.GENERAL);
  const [chatExpanded, setChatExpanded] = useState(newThread || !!notebookId);
  const classes = useStyles({ chatExpanded })();
  const userName = useMemo(() => getUserName(), []);
  const [chatList, setChatList] = useState([]);
  const [persona, setPersona] = useState(agent || '');
  const language = useSelector((state) => state.selfLearn.language);

  const loadingStateHandlers = {
    onMutate: () => {
      dispatchShowSpinner();
    },
    onSettled: () => {
      dispatchHideSpinner();
    },
  };

  const { data: notebooks, isFetching: isGetNotebooksLoading } = useGetNotebooks({
    enabled: false && !threadId,
  });

  const { data: notebookDetails, isFetching: isGetNotebookDetailsLoading } =
    useGetNotebook(notebookId);

  const defaultNotebookId = useMemo(
    () => notebooks && notebooks.find(({ isDefaultNotebook }) => isDefaultNotebook)?.id,
    [notebooks],
  );

  const { data: threads, isFetching: isGetThreadsLoading } = useGetNotebookThreads(notebookId);

  const threadList = useMemo(
    () => threads?.map((details) => ({ ...details, selected: details.id === threadId })),
    [threads, threadId],
  );

  const {
    data: threadDetails,
    isFetching: isGetThreadDetailsLoading,
    refetch: refetchThreadDetails,
  } = useGetThreadDetails(threadId);

  useEffect(() => {
    const notebookName = notebookDetails?.name;
    const threadName = threadDetails?.name;
    const headerTitle = notebookName && `${notebookName}  \u25B8 ${threadName || 'Untitled'}`;

    setLayoutDetails({
      sidebarChildren: (
        <TeacherList teacherType={teacherType} onTeacherTypeChange={setTeacherType} />
      ),
      ...(headerTitle ? { headerTitle } : {}),
    });
  }, [setLayoutDetails, teacherType, notebookDetails, threadDetails, notebookId]);

  const { queryWelcomeMessage, isLoading: isQueryWelcomeMessageLoading } = useQueryWelcomeMessage();

  useEffect(() => {
    if (!threadDetails) return;
    setPersona(threadDetails.persona);
    const messages =
      threadDetails?.messages
        .sort(({ createdAt: createdAtA }, { createdAt: createdAtB }) =>
          moment(createdAtA) < moment(createdAtB) ? -1 : 1,
        )
        .map(({ type, pdfDetails, videoDetails, question, completion, createdAt }) => {
          const timestamp = moment(createdAt).format('h:mm a, D MMM');
          return type === CHAT_TYPES.PDF
            ? [{ type: CHAT_TYPES.PDF, ...pdfDetails }]
            : type === CHAT_TYPES.YOUTUBE
            ? [{ type: CHAT_TYPES.YOUTUBE, ...videoDetails }]
            : [
                ...(question ? [{ type: 'user', timestamp, content: question }] : []),
                { type: 'ai', timestamp, content: completion },
              ];
        })
        .flat() || [];
    setChatList(messages);
  }, [userName, threadDetails]);

  const { mutateAsync: addThread, isLoading: isAddThreadLoading } = useAddThread({
    OnMutate: () => {
      if (!persona) setPersona(PERSONA_TYPES.GENERAL);
    },
    ...loadingStateHandlers,
  });

  const { mutateAsync: renameThread, isLoading: isRenameThreadLoading } =
    useRenameThread(loadingStateHandlers);

  const { mutateAsync: deleteThread, isLoading: isDeleteThreadLoading } =
    useDeleteThread(loadingStateHandlers);

  const { mutateAsync: moveThread, isLoading: isMoveThreadLoading } =
    useMoveThread(loadingStateHandlers);

  const moveThreadHandler = useCallback(
    async ({ threadId: selectedThreadId, notebookId: selectedNotebookId }) => {
      await moveThread({
        threadId: selectedThreadId,
        notebookId: selectedNotebookId,
      });
      if (selectedThreadId === threadId)
        history.replace(`/controller/learn-ai/chats/${selectedNotebookId}/${threadId}`);
    },
    [moveThread, history, threadId],
  );

  const { mutateAsync: moveThreadToNewNotebook, isLoading: isMoveThreadToNewNotebookLoading } =
    useMoveThreadToNewNotebook(loadingStateHandlers);

  const moveThreadToNewNotebookHandler = useCallback(
    async ({ threadId: selectedThreadId, notebookName }) => {
      const { id: newNotebookId } = await moveThreadToNewNotebook({
        threadId: selectedThreadId,
        notebookName,
      });
      if (selectedThreadId === threadId)
        history.replace(`/controller/learn-ai/chats/${newNotebookId}/${threadId}`);
    },
    [moveThreadToNewNotebook, history, threadId],
  );

  const { ask, isLoading: isAskLoading } = useAsk();

  const { mutateAsync: uploadPdf, isLoading: isUploadPdfLoading } =
    useUploadPdf(loadingStateHandlers);
  const { mutateAsync: createYoutubeChat, isLoading: isCreateYoutubeChatLoading } =
    useCreateYoutubeChat(loadingStateHandlers);

  const isLoading =
    isGetNotebooksLoading ||
    isGetNotebookDetailsLoading ||
    isGetThreadsLoading ||
    isGetThreadDetailsLoading ||
    isUploadPdfLoading ||
    isCreateYoutubeChatLoading ||
    isMoveThreadToNewNotebookLoading ||
    isMoveThreadLoading ||
    isDeleteThreadLoading ||
    isRenameThreadLoading ||
    isAddThreadLoading;

  useEffect(() => {
    isLoading ? dispatchShowSpinner() : dispatchHideSpinner();
    return () => dispatchHideSpinner();
  }, [isLoading, dispatchShowSpinner, dispatchHideSpinner]);

  const isPdfThread = useMemo(() => chatList.some(({ type }) => type === 'PDF'), [chatList]);

  const uploadPdfEnabled = useMemo(() => newThread || isPdfThread, [newThread, isPdfThread]);

  const isQueryInProgress = isAskLoading || isQueryWelcomeMessageLoading;

  const chatStarted = useMemo(() => chatList.some(({ type }) => type === 'user'), [chatList]);

  const actionsEnabled = useMemo(
    () => !isQueryInProgress && chatStarted,
    [chatStarted, isQueryInProgress],
  );

  const lastResponse = useMemo(
    () => chatList.findLast(({ type }) => type === 'ai')?.content,
    [chatList],
  );

  const updateChat = useCallback(
    throttle(({ chatIndex, chatMessage }) => {
      setChatList((prevChatList) => {
        const updatedChatList = [...prevChatList];
        const updatedChat = updatedChatList[chatIndex];
        updatedChat.content = chatMessage;
        updatedChatList[chatIndex] = updatedChat;
        return updatedChatList;
      });
    }, 200),
    [],
  );

  useEffect(() => {
    if (!newThread || !persona) return;

    let chatMessage = '';
    const chatIndex = 0;

    const handlers = {
      onStart: () => {
        const responseTimestamp = moment(new Date()).format('h:mm a, D MMM');
        setChatList((prevChatList) => [
          ...prevChatList,
          { type: 'ai', timestamp: responseTimestamp, content: '' },
        ]);
      },
      onMessage: (message) => {
        chatMessage += message;
        updateChat({ chatMessage, chatIndex });
      },
      onError: (err) => {
        dispatchSetSnackbar({ message: err.message || 'Failed to receive the response' });
      },
    };

    queryWelcomeMessage({ ...handlers, persona, language });
  }, [newThread, updateChat, queryWelcomeMessage, persona, language]);

  const deleteThreadHandler = useCallback(
    async (id) => {
      await deleteThread(id);
      if (id === threadId) {
        setPersona('');
        history.replace(`/controller/learn-ai/chats/new-thread/${notebookId}`);
      }
    },
    [history, deleteThread, notebookId, threadId],
  );

  const welcomeMessage = useMemo(() => chatList[0]?.content, [chatList]);

  const askHandler = useCallback(
    async ({ query, action }) => {
      if (isQueryInProgress || isGetThreadDetailsLoading) return;
      let notebookIdOfThread = notebookId || defaultNotebookId;
      let threadIdOfQuery = threadId;

      if (!threadIdOfQuery) {
        const threadDetails = await addThread({
          name: query,
          notebookId: notebookIdOfThread,
          welcomeMessage,
          persona: persona || PERSONA_TYPES.GENERAL,
          language,
        });
        notebookIdOfThread = threadDetails.notebookId;
        threadIdOfQuery = threadDetails.id;
        return history.replace(
          `/controller/learn-ai/chats/${notebookIdOfThread}/${threadIdOfQuery}`,
          {
            query,
          },
        );
      }
      const queryTimestamp = moment(new Date()).format('h:mm a, D MMM');
      setChatExpanded(true);
      let chatIndex = 0;
      setChatList((prevChatList) => {
        chatIndex = prevChatList.length + 1;
        return [
          ...prevChatList,
          {
            type: 'user',
            timestamp: queryTimestamp,
            content: QUERY_ACTION_TYPES[action]?.title || query,
          },
        ];
      });
      let chatMessage = '';

      const handlers = {
        onStart: () => {
          const responseTimestamp = moment(new Date()).format('h:mm a, D MMM');
          setChatList((prevChatList) => [
            ...prevChatList,
            { type: 'ai', timestamp: responseTimestamp, content: '' },
          ]);
        },
        onMessage: (message) => {
          chatMessage += message;
          updateChat({ chatMessage, chatIndex });
        },
        onError: (err) => {
          dispatchSetSnackbar({ message: err.message || 'Failed to receive the response' });
        },
      };

      ask({
        query,
        threadId: threadIdOfQuery,
        action,
        teacher: teacherType,
        ...handlers,
      });
    },
    [
      ask,
      isQueryInProgress,
      isGetThreadDetailsLoading,
      teacherType,
      updateChat,
      defaultNotebookId,
      notebookId,
      threadId,
      addThread,
      history,
      welcomeMessage,
      persona,
      language,
    ],
  );

  const actionHandler = useCallback(
    (action) => {
      askHandler({ query: lastResponse, action });
    },
    [askHandler, lastResponse],
  );

  const shouldRefresh = useMemo(() => locationState?.refresh, [locationState]);

  useEffect(() => {
    if (shouldRefresh) history.replace();
  }, [history, shouldRefresh]);

  const lastQuery = useMemo(() => locationState?.query, [locationState]);

  useEffect(() => {
    if (isGetThreadDetailsLoading) return;
    if (lastQuery) {
      askHandler({ query: lastQuery });
      history.replace();
    }
  }, [lastQuery, isGetThreadDetailsLoading, history, askHandler]);

  const pdfUploadHandler = useCallback(
    async ({
      target: {
        files: [file],
      },
    }) => {
      if (!file || file.type !== 'application/pdf') return;
      const data = await uploadPdf({ file, notebookId, threadId });
      if (!threadId) {
        return history.replace(`/controller/learn-ai/chats/${data.notebookId}/${data.threadId}`);
      }
      refetchThreadDetails();
    },
    [history, notebookId, threadId, uploadPdf, refetchThreadDetails],
  );

  const newThreadHandler = useCallback(() => {
    history.replace(`/controller/learn-ai/chats/new-thread${notebookId ? '/' + notebookId : ''}`);
    setPersona('');
    setChatList([]);
  }, [history, notebookId]);

  const questionClickHandler = (question) => askHandler({ query: question });

  const breadCrumbItems = useMemo(() => {
    const items = ['Bright AI'];
    if (notebookDetails) items.push(notebookDetails.name);
    return items;
  }, [notebookDetails]);

  const agentClickHandler = (agent) =>
    history.push(
      `/controller/learn-ai/chats/new-thread${
        notebookId ? '/' + notebookId : defaultNotebookId ? '/' + defaultNotebookId : ''
      }?agent=${agent}`,
    );

  const setLanguage = (language) => dispatch(setSelfLearnLanguage(language));

  const createYoutubeChatHandler = async (youtubeVideoLink) => {
    const response = await createYoutubeChat({ youtubeVideoLink, notebookId });
    history.replace(`/controller/learn-ai/chats/${response.notebookId}/${response.threadId}`);
  };

  return (
    <>
      <Hidden smDown>
        <ControllerSidebar />
      </Hidden>
      <ControllerTopbar handleMobileSideMenuOpen={handleMobileSideMenuOpen} />
      <div>
        <BrightAILayout showTopbar={newThread || threadId}>
          <div className={classes.root}>
            {chatExpanded && (
              <ThreadList
                loading={isQueryInProgress}
                onNewThread={newThreadHandler}
                onRename={renameThread}
                onMove={moveThreadHandler}
                onMoveToNew={moveThreadToNewNotebookHandler}
                onDelete={deleteThreadHandler}
                list={threadList}
              />
            )}
            <div className={classes.mainContent}>
              {!chatExpanded && <Header />}
              {chatExpanded && (
                <ChatBox
                  chatList={chatList}
                  onQuestionClick={questionClickHandler}
                  persona={persona}
                  onPersonaChange={setPersona}
                  isPdfThread={isPdfThread}
                  language={language}
                  onLanguageChange={setLanguage}
                  onYoutubeSubmit={createYoutubeChatHandler}
                />
              )}
              <QueryInput
                loading={isQueryInProgress}
                actionsEnabled={actionsEnabled}
                onSend={askHandler}
                onAction={actionHandler}
                pdfUploadHandler={pdfUploadHandler}
                userName={userName}
                chatExpanded={chatExpanded}
                chatStarted={chatStarted}
                uploadPdfEnabled={uploadPdfEnabled}
                language={language}
              />
              {!chatExpanded && (
                <div className={classes.actionsContainer}>
                  <LanguageSelect language={language} onChange={setLanguage} />
                  <PdfButton />
                  <YoutubeButton onSubmit={createYoutubeChatHandler} />
                </div>
              )}
              {!chatExpanded && (
                <div>
                  <Typography className="i-agent-list-title">
                    Chat with teaching assistant
                  </Typography>
                  <Typography className="i-agent-list-description">
                    Let your creativity soar with your learning companion.
                  </Typography>
                  <AgentList onClick={agentClickHandler} />
                </div>
              )}
            </div>
          </div>
        </BrightAILayout>
      </div>
    </>
  );
};

export default React.memo(SelfLearnPage);
