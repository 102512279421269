import React from 'react';
import { makeStyles, Button } from '@material-ui/core';

import { AddCircleOutline as PlusIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  newThreadRoot: {
    width: '100%',
    padding: 16,
    gap: 12,
    borderRadius: 8,
    backgroundColor: '#EAEEFF',
    fontSize: 12,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const NewThread = ({ onClick, loading }) => {
  const classes = useStyles();
  return (
    <Button
      onClick={onClick}
      variant="text"
      className={classes.newThreadRoot}
      startIcon={<PlusIcon fontSize="large" />}
      disabled={loading}
    >
      New Chat
    </Button>
  );
};

export default NewThread;
