import { useSelector } from 'react-redux';

export const useDialogSelector = () => {
  const isOpenImportOutlookEventsDialog = useSelector(
    (state) => state.dialog.importOutlookEventDialog.isOpen,
  );
  const isOpenVideoCallSidebarDialog = useSelector(
    (state) => state.dialog.videoCallSidebarDialog.isOpen,
  );
  const isOpenNavigationSidebarDialog = useSelector(
    (state) => state.dialog.navigationSidebarDialog.isOpen,
  );
  const isOpenJoinAsHostDialog = useSelector((state) => state.dialog.joinAsHostDialog.isOpen);
  const isOpenJoinRequestSentDialog = useSelector(
    (state) => state.dialog.joinRequestSentDialog.isOpen,
  );
  const remoteControlDialog = useSelector((state) => state.dialog.remoteControlDialog);

  return {
    isOpenImportOutlookEventsDialog,
    isOpenJoinAsHostDialog,
    isOpenNavigationSidebarDialog,
    isOpenVideoCallSidebarDialog,
    isOpenJoinRequestSentDialog,
    remoteControlDialog,
  };
};
