import React, { useCallback, useEffect } from 'react';
import SideBar from '../../../components/sideBar/SideBar';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import ZoomDownArrow from '../../../assets/icons/ZoomDownArrow';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkIsAuthUser,
  getBoardInfo,
  getSpaceId,
  getSpaceName,
  getWorkspaceId,
  setBoardInfo,
} from '../../../Utils/authentication-access';
import boardsService from '../../../services/boards.service';
import { useState } from 'react';
import RenameSpace from '../../../components/sideBar/Dialogs/RenameSpace';
import { activeSpaceInfo } from '../../../store/actions/spaces.action';
import './publishclass.css';
import DeleteSpaceDialog from '../../../components/sideBar/Dialogs/DeleteSpaceDialog';
import { getTeamsInfo } from '../../../store/actions/teams.action';
import NewCursorIcon from '../../../assets/icons/NewCursorIcon';
import NewPointerIcon from '../../../assets/icons/NewPointerIcon';
import { spacesService } from '../../../services/spaces.service';
import { useNotificationDispatcher } from '../../../store/dispatcher/useNotificationDispatcher';
import { useHistory } from 'react-router-dom';
import SideBarControle from '../../../assets/img/SideBarControle.svg';

const PublishclassNew = (props) => {
  const isAuthUser = checkIsAuthUser();
  const activeSpaceReducer = useSelector((state) => state.activeSpaceReducer.activeSpace);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [renameBoardInput, setRenameBoardInput] = useState('');
  const [boardRenameErrorTextMessage, setBoardRenameErrorTextMessage] = useState('');
  const dispatch = useDispatch();
  const teamsInfo = useSelector((state) => state.teamsReducer.teamsInfo);
  const [spacesLength, setSpacesLength] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const boardOptionOpen = Boolean(anchorEl);
  const [deleteSpaceDialogOpen, setDeleteSpaceDialogOpen] = useState(false);
  const [deleteBoardInput, setDeleteBoardInput] = useState('');
  const [activeTeamInfo, setActiveTeamInfo] = useState([]);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { dispatchShowLicenseExhaustedDialog } = useNotificationDispatcher();
  const cloudLicenseInfo = useSelector((state) => state.licenseReducer.cloudLicenseDetails);
  const history = useHistory();

  useEffect(() => {
    const info = teamsInfo.find((team) => team.id === getSpaceId());
    setActiveTeamInfo(info);
    setSpacesLength(info?.childrens.length);
  }, [teamsInfo]);

  const handleCloseboardOptions = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const duplicateSpace = async () => {
    console.log('cloudLicenseInfo', cloudLicenseInfo);
    // if (cloudLicenseInfo.spaces.isUnlimited || spacesLength < cloudLicenseInfo.spaces.totalLimit) {
    //   const spaceID = getSpaceId();
    //   const { classId, flowId } = getBoardInfo();
    //   await boardsService.duplicateBoard(flowId, classId, spaceID);
    // } else {
    //   dispatchShowLicenseExhaustedDialog();
    // }
  };

  const renameSpace = () => {
    setRenameBoardInput(activeSpaceReducer.name);
    setRenameDialogOpen(true);
  };

  const reNameBoardClick = async () => {
    const { classId, flowId } = getBoardInfo();
    if (renameBoardInput !== '') {
      setBoardRenameErrorTextMessage('');
      await boardsService.renameBoard(flowId, classId, renameBoardInput);
      handleClose();
      const boardInfo = getBoardInfo();
      boardInfo.name = renameBoardInput;
      dispatch(activeSpaceInfo(boardInfo));
      setBoardInfo(boardInfo);
      setRenameBoardInput(boardInfo.name);
    } else {
      setBoardRenameErrorTextMessage('*Required');
    }
  };

  const handleClose = () => {
    setRenameDialogOpen(false);
    setDeleteSpaceDialogOpen(false);
  };

  const handleRenameBoardInput = (event) => {
    setRenameBoardInput(event.target.value);
  };

  const onEnterKeyPressRenameBoard = (e) => {
    if (e.key === 'Enter') {
    }
  };

  const deleteSpace = () => {
    setDeleteSpaceDialogOpen(true);
    setDeleteBoardInput(activeSpaceReducer.name);
  };

  const deleteBoardClick = async () => {
    const { classId, flowId } = getBoardInfo();
    const res = await boardsService.deleteBoard(flowId, classId);
    setDeleteSpaceDialogOpen(false);

    if (res.status === 200 || 201) {
      try {
        let workspaceID = getWorkspaceId();
        const response = await spacesService.getSpaces(workspaceID);
        dispatch(getTeamsInfo(response.data.data.spaces));

        const teamsData = response.data.data.spaces;
        const info = teamsData.find((team) => team.id === getSpaceId());
        const { name } = info.childrens[0];
        const classId = info.childrens[0].meta.classId;
        const flowId = info.childrens[0].meta.flowId;
        const boardInfo = getBoardInfo();
        boardInfo.name = name;
        boardInfo.classId = classId;
        boardInfo.flowId = flowId;
        setBoardInfo(boardInfo);
        window.location.reload();
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        flex: 1,
        padding: '4px 10px',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '12px',
        }}
      >
        {isAuthUser && (
          <div>
            {/* <SideBar open={true} /> */}
            <Tooltip title="Back">
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                aria-label="logo"
                onClick={() => history.push('/controller/spaces')}
                style={{
                  zIndex: 99999,
                  width: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingLeft: '3px',
                  borderRadius: '4px',
                }}
              >
                <img src={SideBarControle} alt="img" />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>

      <div>
        <div style={{ display: 'flex' }}>
          {/* {!isMobile && (
            <span
              style={{
                color: '#999999',
                paddingLeft: '8.5px',
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: isMobile ? '14px' : '12px',
              }}
            >
              {getSpaceName()} /
            </span>
          )} */}
          <span
            style={{
              cursor: 'pointer',
              paddingLeft: '8.5px',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '14px',
              display: 'flex',
              gap: '10px',
            }}
            // onClick={handleClick}
          >
            <span
              style={{
                maxWidth: isMobile ? '170px' : 'auto',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: isMobile ? 'block' : 'inline',
              }}
            >
              {/* {activeSpaceReducer?.name} */}
              {getSpaceName()}
            </span>
            {/* <span>
              {' '}
              <ZoomDownArrow />
            </span> */}
          </span>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={boardOptionOpen}
            onClose={handleCloseboardOptions}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: '183px',
                boxShadow: '0px 0px 4px rgb(34 34 34 / 20%)',
              },
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            getContentAnchorEl={null}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem className="menuItemStyle" onClick={duplicateSpace}>
              Duplicate
            </MenuItem>
            {/* TODO : not required in current implementation
            <MenuItem className="menuItemStyle"> {props.exportPdf()}</MenuItem> */}

            <MenuItem className="menuItemStyle" onClick={renameSpace}>
              Rename
            </MenuItem>

            <MenuItem className="menuItemStyle" onClick={deleteSpace} disabled={spacesLength <= 1}>
              Delete
            </MenuItem>
          </Menu>
        </div>
      </div>

      {/** TODO: future implementation
      <div
        style={{
          marginLeft: '17px',
          border: '1px solid #DADADA',
          borderRadius: '4px',
          display: 'flex',
        }}
      >
        <div
          style={{
            borderRight: '1px solid #DADADA',
            padding: '4px 10px',
          }}
        >
          <NewCursorIcon />
        </div>
        <div
          style={{
            padding: '4px 10px',
          }}
        >
          <NewPointerIcon />
        </div>
      </div>
      */}
      <RenameSpace
        renameDialogOpen={renameDialogOpen}
        handleClose={handleClose}
        renameBoardInput={renameBoardInput}
        handleRenameBoardInput={handleRenameBoardInput}
        onEnterKeyPressRenameBoard={onEnterKeyPressRenameBoard}
        boardRenameErrorTextMessage={boardRenameErrorTextMessage}
        parentName={getSpaceName()}
        reNameBoardClick={reNameBoardClick}
      />

      <DeleteSpaceDialog
        deleteDialogOpen={deleteSpaceDialogOpen}
        handleClose={handleClose}
        deleteBoardInput={deleteBoardInput}
        deleteBoardClick={deleteBoardClick}
      />
    </div>
  );
};

export default PublishclassNew;
