import { makeStyles } from '@material-ui/core';

export const usePromptRevokeAccessDialogStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: '0.5rem',
  },
  button: {
    borderColor: 'rgba(0, 0, 0, 0.8)',
    color: 'rgba(0, 0, 0, 0.8)',
  },
}));
