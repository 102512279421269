const { makeStyles } = require('@material-ui/core');

export const useStyledFooterStyles = makeStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    padding: '1rem',
    display: 'flex',
  },
  actions: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
}));
