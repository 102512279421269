import React from 'react';
import { useMediaClient } from './useMediaClient';
import { ScreenshareDisplayContentContainer } from './display-content-container/container';
import { ScreenshareDisplayControlsContainer } from './display-controls/container';

/**
 * Screenshare on huddle page,
 * The same is reused on the whiteboard page
 * @param {{ roomId }} props
 * @returns
 */
export const ScreenshareContainer = (props) => {
  const {
    getUserMedia,
    isMuted,
    isScreenShared,
    localSubscribedData,
    mute,
    onClickMute,
    onClickUnmute,
    publish,
    showRemoteVideo,
    stopScreenshare,
    subscribedData,
    isViewOnly,
    checkIsAllowedToStream,
  } = useMediaClient({ roomId: props.roomId });

  const displayControlsContainer = (
    <ScreenshareDisplayControlsContainer
      getUserMedia={getUserMedia}
      isMuted={isMuted}
      isScreenShared={isScreenShared}
      isViewOnly={isViewOnly}
      mute={mute}
      onClickMute={onClickMute}
      onClickUnmute={onClickUnmute}
      publish={publish}
      stopScreenshare={stopScreenshare}
      checkIsAllowedToStream={checkIsAllowedToStream}
    />
  );

  const displayContentContainer = (
    <ScreenshareDisplayContentContainer
      isViewOnly={isViewOnly}
      localSubscribedData={localSubscribedData}
      showRemoteVideo={showRemoteVideo}
      subscribedData={subscribedData}
    />
  );

  return (
    <div>
      {displayControlsContainer}
      {displayContentContainer}
    </div>
  );
};

export default ScreenshareContainer;
