import React from 'react';
import share from '../svg/sharePdfIcon.svg';

const SharePdfIcon = () => {
  return (
    <div>
      <img src={share} alt="share" />
    </div>
  );
};

export default SharePdfIcon;
