import React, { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import { Typography, makeStyles } from '@material-ui/core';

import { useQueryParams } from '../../../hooks/use-query-params';
import NewThread from './new-thread';
import Thread from './thread';

const useStyles = ({ open }) =>
  makeStyles((theme) => ({
    threadListRoot: {
      '--scroll-bar-color': 'rgba(0, 0, 0, 0)',
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      width: '30%',
      padding: 16,
      borderRight: '2px solid #E4E4E5',
      overflow: 'auto',
      transition: '--scroll-bar-color .5s',
      zIndex: 2,
      '&::-webkit-scrollbar-thumb': {
        background: 'var(--scroll-bar-color)',
      },
      '&:hover, &:focus, &:active': {
        '--scroll-bar-color': 'rgba(0, 0, 0, 0.3)',
      },
      '& .i-back-button-container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 4,
        marginTop: 4,
        marginBottom: 12,
        '& .MuiTypography-root': {
          fontSize: 14,
          fontWeight: 500,
        },
      },
      '& .i-list-title': {
        fontSize: 10,
        color: '#00000061',
        marginTop: 12,
      },
      [theme.breakpoints.down('md')]: {
        position: 'absolute',
        left: 0,
        width: '100%',
        height: 'inherit',
        margin: 0,
        marginTop: -24,
        zIndex: 2,
        transform: `translate3d(${open ? '0%' : '-100%'},0,0)`,
        transition: 'transform .5s',
        background: '#FFF',
        '--scroll-bar-color': 'rgba(0, 0, 0, 0.3)',
      },
    },
  }));

const Label = ({ children }) => <Typography className="i-list-title">{children}</Typography>;

const ThreadList = ({
  list = [],
  onNewThread,
  onRename,
  onDelete,
  onMove,
  onMoveToNew,
  loading,
}) => {
  const { showThreads } = useQueryParams();
  const [open, setOpen] = useState(!!showThreads);
  const classes = useStyles({ open })();

  useEffect(() => setOpen(!!showThreads), [showThreads]);

  const toggleOpen = () => setOpen(!open);

  const newThreadHandler = () => {
    toggleOpen();
    onNewThread();
  };

  const sortedByDateList = useMemo(
    () =>
      list
        .sort(({ updatedAt: updatedAtA }, { updatedAt: updatedAtB }) =>
          moment(updatedAtA) > moment(updatedAtB) ? -1 : 1,
        )
        .sort(({ selected: selectedA }, { selected: selectedB }) =>
          selectedB >= selectedA ? 1 : -1,
        ),
    [list],
  );

  const todayList = useMemo(() => {
    const today = moment().startOf('day');
    const todayThreads = sortedByDateList.filter(({ updatedAt }) => {
      const updatedAtDate = moment(updatedAt);
      return updatedAtDate >= today;
    });
    return todayThreads;
  }, [sortedByDateList]);

  const yesterdayList = useMemo(() => {
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const today = yesterday.clone().add(1, 'days');
    const yesterdayThreads = sortedByDateList.filter(({ updatedAt }) => {
      const updatedAtDate = moment(updatedAt);
      return updatedAtDate >= yesterday && updatedAtDate < today;
    });
    return yesterdayThreads;
  }, [sortedByDateList]);

  const last30DaysList = useMemo(() => {
    const start = moment().subtract(30, 'days').startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const lastMonthThreads = sortedByDateList.filter(({ updatedAt }) => {
      const updatedAtDate = moment(updatedAt);
      return updatedAtDate >= start && updatedAtDate < yesterday;
    });
    return lastMonthThreads;
  }, [sortedByDateList]);

  const olderList = useMemo(() => {
    const end = moment().subtract(30, 'days').startOf('day');
    const otherThreads = sortedByDateList.filter(({ updatedAt }) => {
      const updatedAtDate = moment(updatedAt);
      return updatedAtDate < end;
    });
    return otherThreads;
  }, [sortedByDateList]);

  const generateThreads = (list, label) =>
    !!list.length && (
      <>
        <Label>{label}</Label>
        {list.map(({ id, ...props }) => (
          <Thread
            key={id}
            id={id}
            onRename={onRename}
            onDelete={onDelete}
            onMoveToNew={onMoveToNew}
            onMove={onMove}
            onClick={toggleOpen}
            {...props}
            loading={loading}
          />
        ))}
      </>
    );

  const todayThreads = generateThreads(todayList, 'Today');

  const yesterdayThreads = generateThreads(yesterdayList, 'Yesterday');

  const last30DaysThreads = generateThreads(last30DaysList, 'Previous 30 Days');

  const olderThreads = generateThreads(olderList, 'Older');

  return (
    <div className={classes.threadListRoot}>
      <NewThread onClick={newThreadHandler} loading={loading} />
      {todayThreads}
      {yesterdayThreads}
      {last30DaysThreads}
      {olderThreads}
    </div>
  );
};

export default React.memo(ThreadList);
