import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PubSub from 'pubsub-js';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { DocumentDialog } from './component';
import config from '../../../config';
import { shouldConvertFileTypes } from '../../../constants/canvas';
import { EVENTS } from '../../../constants/events';
import { useDocumentImportDispatcher } from '../../../store/dispatcher/useDocumentImportDispatcher';

const pagesPerGalleryPage = 6;

export const DocumentDialogContainer = (props) => {
  const { dispatchSetDocInfo } = useDocumentImportDispatcher();
  const [totalPages, setTotalPages] = useState(1);
  const [currentGalleryPage, setCurrentGalleryPage] = useState(1);
  const [shouldBeFullscreen, setShouldBeFullscreen] = useState(true);
  const [pageRange, setPageRange] = useState({
    firstPage: 1,
    lastPage: 1,
  });
  const fileInfo = useSelector((state) => state.documentImport.fileInfo);
  const docInfo = useSelector((state) => state.documentImport.docInfo);
  const { isOpen, closeDialog, documentS3Url } = props;
  const [pdfUrl, setPdfUrl] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const resetState = () => {
    setTotalPages(1);
    setCurrentGalleryPage(1);
    setShouldBeFullscreen(true);
    setPageRange({ firstPage: 1, lastPage: 1 });
    setPdfUrl('');
  };

  useEffect(() => {
    !isOpen && resetState();
  }, [isOpen]);

  useEffect(() => {
    if (!documentS3Url) return;
    if (
      fileInfo &&
      shouldConvertFileTypes.includes(fileInfo.filetype) &&
      !documentS3Url.startsWith(config.MEDIA_CONVERTER_SERVER_URL)
    ) {
      const encodedFileUrl = encodeURIComponent(documentS3Url);
      const pptToPdfUrl = `${config.MEDIA_CONVERTER_SERVER_URL}/ppt-to-pdf?fileUrl=${encodedFileUrl}`;
      setPdfUrl(pptToPdfUrl);
    } else {
      setPdfUrl(documentS3Url);
    }
  }, [fileInfo, documentS3Url, isOpen]);

  const setPageFullRange = useCallback(
    (numPages) => setPageRange({ firstPage: 1, lastPage: numPages }),
    [setPageRange],
  );

  const toggleShouldBeFullscreen = (e) => setShouldBeFullscreen(e.target.checked);

  const totalGalleryPage = useMemo(
    () => (totalPages ? Math.ceil(totalPages / pagesPerGalleryPage) : 0),
    [totalPages],
  );

  const displayPagesNos = useMemo(() => {
    const startingPageNo = (currentGalleryPage - 1) * pagesPerGalleryPage + 1;
    let endingPageNo = Math.min(currentGalleryPage * pagesPerGalleryPage, totalPages);

    const list = [];
    for (let i = startingPageNo; i <= endingPageNo; i++) {
      list.push(i);
    }
    return list;
  }, [currentGalleryPage, totalPages]);

  const calcSeriesNos = (startPageNo, endPageNo) => {
    const list = [];
    for (let i = +startPageNo; i <= +endPageNo; i++) {
      list.push(i);
    }
    return list;
  };

  const selectedPageRange = useMemo(
    () => calcSeriesNos(pageRange.firstPage, pageRange.lastPage),
    [pageRange],
  );

  const allPdfPagesNos = useMemo(() => calcSeriesNos(1, totalPages), [totalPages]);

  const hasNextGalleryPage = currentGalleryPage < totalGalleryPage;
  const hasPrevGalleryPage = currentGalleryPage > 1;

  const setInsertRange = (key, value, isBlur) => {
    if (isBlur && value === '') value = 1;
    if (value === '0') return;
    if (value < 0 || value > totalPages) return;
    setPageRange({ ...pageRange, [key]: value });
  };
  const setFirstPageRange = (value, isBlur) => setInsertRange('firstPage', value, isBlur);
  const setLastPageRange = (value, isBlur) => setInsertRange('lastPage', value, isBlur);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
    setCurrentGalleryPage(1);
    setPageFullRange(numPages);
    dispatchSetDocInfo({ numPages });
  };

  const onLoadError = (data) => {
    console.log('onLoadError', data);
  };

  const goToNextGalleryPage = () => {
    if (hasNextGalleryPage) {
      setCurrentGalleryPage((val) => val + 1);
    }
  };

  const goToPreviousGalleryPage = () => {
    if (hasPrevGalleryPage) {
      setCurrentGalleryPage((val) => val - 1);
    }
  };

  const importDocs = () => {
    const { firstPage, lastPage } = pageRange;
    if (+firstPage > +lastPage) {
      setErrorMessage('Selected start page is greater than selected end page');
      setShowError(true);
      return;
    }
    const dataUrls = [];
    selectedPageRange.forEach((selectedPageNo) => {
      const importPDFCanvas = document.querySelector(`.import-pdf-page-${selectedPageNo} canvas`);
      const pdfDataUrl = importPDFCanvas.toDataURL();
      dataUrls.push(pdfDataUrl);
    });

    const options = { isDocument: true };
    if (shouldBeFullscreen) {
      options.scale = 'MAX';
    }
    PubSub.publish(EVENTS.IMAGES_TO_CANVAS, { data: { dataUrls, options } });
    closeDialog();
  };

  const hideError = () => setShowError(false);

  const constructTitle = () => {
    const pages = docInfo && docInfo.numPages ? docInfo.numPages : 0;
    const titleArr = [fileInfo?.fileName];
    if (pages) {
      titleArr.push(`${pages} pages`);
    }
    return titleArr.join(' - ');
  };

  const title = constructTitle();

  if (!pdfUrl) {
    return <></>;
  }

  return (
    <div>
      <Snackbar
        open={showError}
        onClose={hideError}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <DocumentDialog
        allPdfPagesNos={allPdfPagesNos}
        closeDialog={closeDialog}
        currentGalleryPage={currentGalleryPage}
        displayPagesNos={displayPagesNos}
        docInfo={docInfo}
        goToNextGalleryPage={goToNextGalleryPage}
        goToPreviousGalleryPage={goToPreviousGalleryPage}
        hasNextGalleryPage={hasNextGalleryPage}
        hasPrevGalleryPage={hasPrevGalleryPage}
        importDocs={importDocs}
        isOpen={isOpen}
        onDocumentLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onLoadError}
        pageRange={pageRange}
        pagesPerGalleryPage={pagesPerGalleryPage}
        setCurrentGalleryPage={setCurrentGalleryPage}
        setFirstPageRange={setFirstPageRange}
        setLastPageRange={setLastPageRange}
        shouldBeFullscreen={shouldBeFullscreen}
        title={title}
        toggleShouldBeFullscreen={toggleShouldBeFullscreen}
        totalPages={totalPages}
        totalGalleryPage={totalGalleryPage}
        url={pdfUrl}
      />
    </div>
  );
};
